import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';

export interface ClientMetaReviewQuestionnairesGetResponse {
  action_required_count: number;
}

export function clientMetaReviewQuestionnairesApi(path: string) {
  return {
    path,
    GET: (config?: RequestConfig) =>
      api.get<ClientMetaReviewQuestionnairesGetResponse>(path, config),
  };
}
