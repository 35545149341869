import { Root, route } from '../utils';

const messageSummaryPath = `${Root}messages-summary`;

const messageMultipleClientsPath = `${messageSummaryPath}/message-multiple-clients`;

export default {
  messagesSummary: {
    ...route(messageSummaryPath),
    messageMultipleClients: route(messageMultipleClientsPath),
  },
};
