import { Root, route } from '../utils';

const usersPath = `${Root}users`;
const passwordPath = `${usersPath}/password`;

export default {
  users: {
    ...route(usersPath),
    confirmation: route(`${usersPath}/confirmation`),
    password: {
      ...route(passwordPath),
      new: route(`${passwordPath}/new`),
    },
    signIn: route(`${usersPath}/sign_in`),
  },
};
