import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { FilterOptions, GetOptions, PaginationOptions, RequestConfig } from 'api/types';
import type { ClientAdviceRoomFilters } from 'constants/adviceRoomFilters';

export type ClientAdviceRoomsGetOptions = GetOptions &
  PaginationOptions &
  FilterOptions<ClientAdviceRoomFilters>;

export function clientAdviceRoomsApi(path: string) {
  return {
    path,
    GET: ({ pageSize, pageNumber, ...rest }: ClientAdviceRoomsGetOptions, config?: RequestConfig) =>
      api.get<JsonApiResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...rest,
        },
        ...config,
      }),
  };
}
