import React from 'react';

import { useStyledTheme } from 'hooks/useStyledTheme';

import SvgIcon from './SvgIcon';

const IconAvatarPlaceholder = (props: React.SVGAttributes<SVGSVGElement>) => {
  const theme = useStyledTheme();

  return (
    <SvgIcon viewBox="0 0 400 400" {...props}>
      <path
        d="M191 39c-46 3-80 51-77 105 3 40 15 76 33 95l2 3 1 4c3 17 0 31-8 39-17 16-33 25-58 31-43 11-60 27-70 63a479 479 0 0 1-7 21h386l-2-4-3-10c-6-22-9-29-15-37-11-15-27-24-57-31-15-4-26-7-33-11-8-4-12-7-24-17-13-11-16-16-16-30 0-12 2-18 6-23 15-18 25-44 30-80 8-63-25-114-76-118h-12"
        fill={theme.colors.accent}
      />
    </SvgIcon>
  );
};

export default IconAvatarPlaceholder;
