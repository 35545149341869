import React from 'react';

import SvgIcon from './SvgIcon';

const IconArrowRightsm = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M10 7L15 12L10 17"
      stroke="#000000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default IconArrowRightsm;
