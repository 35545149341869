import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';
import type { DocumentSetModel } from 'types/entities/documentSet';

interface AdviceRoomDocumentSetItemGetOptions {
  documentSetModel: DocumentSetModel;
  documentSetId: number | undefined;
}

export function adviceRoomDocumentSetItemApi(path: string) {
  return {
    path,
    GET: (params: AdviceRoomDocumentSetItemGetOptions) =>
      api.get<JsonApiResponse>(path, { params: mapFormData(params) }),
  };
}
