import React, { useMemo } from 'react';
import { find, map } from 'lodash';

import type { Currency } from 'types/entities/currency';
import {
  BaseDropdown,
  DropdownWidth,
  Props as BaseDropdownProps,
} from 'components/Dropdown/BaseDropdown';

export type Props = Omit<BaseDropdownProps, 'options' | 'onChange' | 'value'> & {
  onChange?: (currency: string) => void;
  value?: string;
  currencies: Currency[];
};

export function CurrencyDropdown({ onChange, value, currencies, ...props }: Props) {
  const options = useMemo(() => {
    const isCurrentValueInList = find(currencies, currency => currency.code === value);
    const mappedOptions = map(currencies, currency => ({
      label: currency.code,
      value: currency.code,
    }));

    return value && !isCurrentValueInList
      ? [
          {
            label: value,
            value,
          },
          ...mappedOptions,
        ]
      : mappedOptions;
  }, [currencies, value]);

  return (
    <BaseDropdown
      dropdownWidth={DropdownWidth.ExtraSmall}
      onChange={selectedValue => {
        if (selectedValue) {
          onChange?.(selectedValue);
        }
      }}
      value={value}
      options={options}
      isSearchable
      {...props}
    />
  );
}
