import { useCallback, useEffect, useState } from 'react';
import { stripUnit } from 'polished';

import { getLocalStorageValue, setLocalStorageValue } from 'helpers/localStorage';

const FONT_SIZE_STORAGE_NAME = 'font_size';
const STEP_COUNT = 1;
const STEP = 2;
const MIN_FONT_SIZE = Number(
  stripUnit(window.getComputedStyle(document.body, null).getPropertyValue('font-size'))
);
const MAX_FONT_SIZE = MIN_FONT_SIZE + STEP_COUNT * STEP;

export interface RenderProps {
  increaseFontSize: () => void;
  decreaseFontSize: () => void;
  isIncreasingDisabled: boolean;
  isDecreasingDisabled: boolean;
}

function BaseFontSizeChangerContainer({ render }: ContainerProps<RenderProps>) {
  const [baseFontSize, setBaseFontSize] = useState(
    getLocalStorageValue(FONT_SIZE_STORAGE_NAME) || MIN_FONT_SIZE
  );

  const decreaseFontSize = useCallback(() => {
    if (baseFontSize > MIN_FONT_SIZE) {
      setBaseFontSize(baseFontSize - STEP);
    }
  }, [baseFontSize]);

  const increaseFontSize = useCallback(() => {
    if (baseFontSize < MAX_FONT_SIZE) {
      setBaseFontSize(baseFontSize + STEP);
    }
  }, [baseFontSize]);

  useEffect(() => {
    document.documentElement.style.fontSize = `${baseFontSize}px`;
    setLocalStorageValue(FONT_SIZE_STORAGE_NAME, baseFontSize);
  }, [baseFontSize]);

  return render({
    increaseFontSize,
    decreaseFontSize,
    isIncreasingDisabled: baseFontSize >= MAX_FONT_SIZE,
    isDecreasingDisabled: baseFontSize <= MIN_FONT_SIZE,
  });
}

export default BaseFontSizeChangerContainer;
