import type { createInstance } from '@datapunt/matomo-tracker-react';

import { Env } from 'helpers/env';
import { getRailsContextEnv } from 'helpers/railsContext';

type Options = Parameters<typeof createInstance>[0];

enum Region {
  HongKong = 'ap-east-1',
  London = 'eu-west-2',
}

const baseUrl: Record<string, string> = {
  [Region.HongKong]: 'https://matomo.advice.asia',
  [Region.London]: 'https://matomo.advice.online',
};

const siteId: Record<string, Record<string, number>> = {
  [Region.HongKong]: {
    [Env.Production]: 2,
  },
  [Region.London]: {
    [Env.Production]: 4,
    [Env.Beta]: 1,
    [Env.Staging]: 3,
  },
};
interface MatomoConfigProps {
  disabled: boolean;
  env: string;
}

const defaultConfig = {
  urlBase: Region.London,
  siteId: 1,
  disabled: true,
};

const matomoConfig = ({ disabled = true, env }: MatomoConfigProps): Record<string, Options> => {
  return {
    [Region.HongKong]: {
      siteId: siteId[Region.HongKong][env] || 1,
      urlBase: baseUrl[Region.HongKong],
      disabled,
    },
    [Region.London]: {
      siteId: siteId[Region.London][env] || 1,
      urlBase: baseUrl[Region.London],
      disabled,
    },
  };
};

const trackerSettings: Record<string, Record<string, Options>> = {
  [Env.Production]: { ...matomoConfig({ env: Env.Production, disabled: false }) },
  [Env.Beta]: { ...matomoConfig({ env: Env.Beta, disabled: true }) },
  [Env.Staging]: { ...matomoConfig({ env: Env.Staging, disabled: true }) },
  [Env.Test]: { ...matomoConfig({ env: Env.Test, disabled: true }) },
  [Env.Development]: { ...matomoConfig({ env: Env.Development, disabled: true }) },
};

export function getTrackerSettings(region: string): Options | undefined {
  const currentEnv: string = getRailsContextEnv() as string;

  return currentEnv ? trackerSettings[currentEnv][region] : defaultConfig;
}
