import api from 'api/apiInstance';
import type { FactFindData } from 'components/FactFind/types';

import { clientFactFindsMostRecentlySubmittedApi } from './mostRecentlySubmitted';

export function clientFactFindsApi(path: string) {
  return {
    path,
    mostRecentlySubmitted: () =>
      clientFactFindsMostRecentlySubmittedApi(`${path}/most-recently-submitted`),
    GET: () => api.get<FactFindData>(path),
  };
}
