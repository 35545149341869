import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { browsersDevicesPermissionsUrls, systemPermissionHelpUrls } from 'constants/browser';
import { useBrowser } from 'components/BrowserWarning/useBrowser';

export const Link = styled.a`
  text-decoration: underline;
`;

interface Props {
  systemDenied?: 'microphone' | 'camera' | 'both';
  browserDenied?: 'microphone' | 'camera' | 'both';
}

export function PermissionsHelpLink({ systemDenied, browserDenied }: Props) {
  const currentBrowser = useBrowser();

  const helpLinkId = useMemo(() => {
    if (systemDenied && browserDenied) {
      if (systemDenied === 'microphone') return 'mixedSystemMicrophonePermissionLink';

      return 'mixedSystemCameraPermissionLink';
    }

    if (systemDenied) {
      if (systemDenied === 'camera') return 'systemCameraLink';
      if (systemDenied === 'microphone') return 'systemMicrophoneLink';

      return 'systemPermissionsLink';
    }

    return 'browserPermissionsLink';
  }, [systemDenied, browserDenied]);

  return (
    <FormattedMessage
      id={`adviceRooms.testYourSetup.${helpLinkId}`}
      tagName="p"
      values={{
        browserPermission: browserDenied === 'camera' ? 'webcam' : browserDenied,
        a: (chunks: React.ReactNode) => (
          <Link
            href={browsersDevicesPermissionsUrls[currentBrowser.name]}
            target="_blank"
            rel="noopener noreferrer"
          >
            {chunks}
          </Link>
        ),
        webcam: (chunks: React.ReactNode) => (
          <Link
            href={
              currentBrowser.os === 'Mac OS'
                ? systemPermissionHelpUrls.MacWebCam
                : systemPermissionHelpUrls.WindowsWebCam
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {chunks}
          </Link>
        ),
        microphone: (chunks: React.ReactNode) => (
          <Link
            href={
              currentBrowser.os === 'Mac OS'
                ? systemPermissionHelpUrls.MacMicrophone
                : systemPermissionHelpUrls.WindowsMicrophone
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {chunks}
          </Link>
        ),
      }}
    />
  );
}
