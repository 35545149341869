import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, PaginationOptions, RequestConfig } from 'api/types';

import { practiceAdminsExportListApi } from './exportList';
import { practiceAdminApi } from './practiceAdmin';

export type PracticeAdminsGetOptions = GetOptions & PaginationOptions;
export type PracticeAdminsGetResponse = JsonApiResponse;

const apiPath = '/api/v1/practice-admins';

function practiceAdminsApi(path: string) {
  return {
    path,
    exportList: () => practiceAdminsExportListApi(`${apiPath}/export-report`),
    GET: (params: PracticeAdminsGetOptions = {}, config?: RequestConfig) => {
      const { pageSize, pageNumber, ...rest } = params;

      return api.get<PracticeAdminsGetResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...rest,
        },
        ...config,
      });
    },
  };
}

type PracticeAdminApi = ReturnType<typeof practiceAdminApi>;
type PracticeAdminsApi = ReturnType<typeof practiceAdminsApi>;

function practiceAdmins(): PracticeAdminsApi;
function practiceAdmins(practiceAdminId: number): PracticeAdminApi;
function practiceAdmins(practiceAdminId?: number): PracticeAdminsApi | PracticeAdminApi {
  return practiceAdminId
    ? practiceAdminApi(`${apiPath}/${practiceAdminId}`)
    : practiceAdminsApi(apiPath);
}

export { practiceAdmins };
