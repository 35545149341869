import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, PaginationOptions, RequestConfig } from 'api/types';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

export type FinancialStrategiesGetOptions = GetOptions & PaginationOptions;
export interface FinancialStrategiesPostOptions {
  title: string;
  file?: File;
  image?: File;
  existingImageId?: number;
}

export function clientFinancialStrategiesApi(path: string) {
  return {
    path,
    GET: (params: FinancialStrategiesGetOptions = {}, config?: RequestConfig) => {
      const { pageSize, pageNumber, ...rest } = params;

      return api.get<JsonApiResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...rest,
        },
        ...config,
      });
    },
    POST: (data: FinancialStrategiesPostOptions) =>
      api.post<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(data),
          },
        })
      ),
  };
}
