export default {
  title: `Blog`,
  description: `Here you can read blog posts`,
  tileDescription: `View blog posts.`,
  publishedBy: `Published by`,
  publishedAt: `Published at`,
  notPublished: `Not published`,
  createdAt: `Created at`,
  loadOlder: `Load older posts`,
  noPosts: `No posts were added yet`,
  posts: `Posts`,
  createPost: `Create post`,
  editPost: `Edit post`,
  removePost: `Remove post`,
  removeConfirmation: `Are you sure you want to remove post: {title}?`,
  create: `Create`,
  createAndPublish: `Create and publish`,
  save: `Save`,
  saveAndPublish: `Save and publish`,
  unpublish: `Unpublish`,
  unpublishPost: `Unpublish post`,
  unpublishConfirmation: `Are you sure you want to unpublish post: {title}?`,
  emailClients: `Email all clients`,
  emailClientsConfirmation: `Are you sure you email all clients regarding the blog post: {title}?`,
  moreActions: `More actions`,
  fields: {
    title: `Title`,
    content: `Text`,
    author: `Author`,
    file: `Pdf file`,
  },
  content: `Content`,
  contentDescription: `Please add the blog post content here. The blog may take the form of text-based content and/or a PDF.`,
  contentError: `You must add blog content in text form and/or a PDF.`,
  tileImageDescription: `Below is a preview of what your client will see on their dashboard for this post. You can optionally upload or choose a suitable image that will help to ‘personalise’ the post.`,
  blogEnabled: `Blog is enabled. Clients will be able to view published blog posts.`,
  blogDisabled: `Blog is disabled. Client will not be able to view any blog posts.`,
};
