import { Root, route } from '../utils';

const estateSummaryPath = `${Root}estate-summary`;

export default {
  estateSummary: {
    ...route(estateSummaryPath),
    auditTrail: route(`${estateSummaryPath}/audit-trail`),
  },
};
