import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import normalize, { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';
import { bindActionCreators } from 'redux';

import { FETCH_DATA } from './actionTypes';

export const mergePayloadWithEntities = (
  payload: JsonApiResponse | JsonApiResponseSingle,
  pathToOverwrite?: string
) => ({
  type: FETCH_DATA,
  payload: {
    data: normalize(payload),
    pathToOverwrite,
  },
});

export const useMergePayloadWithEntities = () => {
  const dispatch = useDispatch();

  return useMemo(() => bindActionCreators(mergePayloadWithEntities, dispatch), [dispatch]);
};

export type MergePayloadWithEntitiesReturnType = void;
export type MergePayloadWithEntitiesType = (
  ...args: Parameters<typeof mergePayloadWithEntities>
) => MergePayloadWithEntitiesReturnType;
