import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { LocalVideoTrackPublication, RemoteVideoTrackPublication } from 'twilio-video';

import IconCamera from 'components/Icons/Chat/IconVideoCamera';

import { DeviceDisabledIndicator } from './DeviceDisabledIndicator';

interface Props {
  videoTrack: LocalVideoTrackPublication | RemoteVideoTrackPublication | undefined;
}

export function CameraDisabledIndicator({ videoTrack }: Props) {
  const isEnabled = videoTrack?.track?.isEnabled;

  if (isEnabled) {
    return null;
  }

  return (
    <DeviceDisabledIndicator
      tooltipMessage={<FormattedMessage id="adviceRooms.cameraDisabled" />}
      icon={IconCamera}
    />
  );
}
