import type { AnyAction } from 'redux';

import { CHANGE_SHARED_STATE } from './actionTypes';
import type { DocumentSharingActionTypes, DocumentSharingState } from './types';

export const initialState: DocumentSharingState = {
  sharedState: {
    sharedAdviceRoomDocument: null,
    sharedOtherDocument: null,
    documentData: {
      pageNumber: 1,
      rotation: 0,
    },
    initiatorId: null,
    isSketchpadOpen: false,
  },
};

export default (state = initialState, incomingAction: AnyAction): DocumentSharingState => {
  const action = incomingAction as DocumentSharingActionTypes;

  switch (action.type) {
    case CHANGE_SHARED_STATE:
      return {
        ...state,
        sharedState: {
          ...state.sharedState,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
