import React from 'react';

import SvgIcon from './SvgIcon';

const IconExclamationMark = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 2 10" {...props}>
    <path d="M1 1.7v4.09" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
    <path d="M1 8.04A.98.98 0 1 1 1 10a.98.98 0 0 1 0-1.96" fill="currentColor" />
  </SvgIcon>
);

export default IconExclamationMark;
