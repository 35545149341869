import { position, rgba } from 'polished';
import { createGlobalStyle } from 'styled-components';

import { misc } from 'helpers/styles/constants';

const GenericModalGlobalStyles = createGlobalStyle`
  .modal__overlay {
    ${position('fixed', 0, 0, 0, 0)};
    z-index: ${misc.modalZIndex};
    background-color: ${({ theme }) => rgba(theme.colors.transparentBox, 0.9)};
  }
  
  .ReactModal__Body--open {
      overflow: hidden;
  }
`;

export default GenericModalGlobalStyles;
