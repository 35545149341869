import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions } from 'api/types';

export interface GuestClientMeetingRoomsDocumentGetOptions extends GetOptions {
  authToken: string;
}

export function guestsAdviceRoomsDocumentApi(path: string) {
  return {
    path,
    GET: ({ authToken, ...rest }: GuestClientMeetingRoomsDocumentGetOptions) =>
      api.get<JsonApiResponseSingle>(path, {
        params: {
          '[data][attributes][auth_token]': authToken,
          ...rest,
        },
      }),
  };
}
