import { Root, route } from '../utils';

const advisersPath = `${Root}advisers`;
const adviserPath = `${advisersPath}/:adviserId`;

type AdviserParams = [adviserId: number];

export default {
  advisers: {
    ...route(advisersPath),
    new: route(`${advisersPath}/new`),
  },
  adviser: {
    ...route<AdviserParams>(adviserPath),
    edit: route<AdviserParams>(`${adviserPath}/edit`),
    support: route<AdviserParams>(`${adviserPath}/support`),
    accountSettings: route(`${Root}adviser#/account-settings`),
  },
};
