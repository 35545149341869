import { FinancialPlanningTopic } from 'constants/financialPlanningTopics';

const topics: Record<FinancialPlanningTopic, { title: string; tooltip: string }> = {
  [FinancialPlanningTopic.InvestmentPlanning]: {
    title: `Investment planning`,
    tooltip: `Creating and maintaining the right investment strategy plays a vital role in helping to secure your financial future. Whether you are looking to invest for income or growth, we can provide the quality advice, comprehensive investment solutions and ongoing service to help you achieve your financial goals.`,
  },
  [FinancialPlanningTopic.RetirementPlanning]: {
    title: `Retirement planning`,
    tooltip: `Regardless of your age, it is important to seek advice on your retirement objectives. Whether you are now beginning to invest for your retirement or you need to review your existing plans, we can help.`,
  },
  [FinancialPlanningTopic.TrustAndEstatePlanning]: {
    title: `Trust & estate planning`,
    tooltip: `There are many things to consider when looking to preserve and enhance your wealth for your heirs. Your Adviser may support you with various ways to protect your estate - from foundational planning, including creating a Will, to lifetime gifting and the creation of tax efficient funds.`,
  },
  [FinancialPlanningTopic.LifeAssurance]: {
    title: `Life assurance`,
    tooltip: `Protection is fundamental to manage life’s shocks. We can advise you on various forms of protection.`,
  },
  [FinancialPlanningTopic.LongTermCare]: {
    title: `Long-term care`,
    tooltip: `Arranging care for yourself or for a loved one can not only be emotional, but also financially complicated. Our specialist later life Advisers can support you and your family.`,
  },
  [FinancialPlanningTopic.HealthInsurance]: {
    title: `Health insurance`,
    tooltip: `While resident of some countries are entitled to public healthcare, many of our clients opt for private medical insurance to allow them to seek private treatment, if necessary.`,
  },
  [FinancialPlanningTopic.Mortgages]: {
    title: `Mortgages`,
    tooltip: `Specialist Advisers can support you in securing a new mortgage.`,
  },
  [FinancialPlanningTopic.SpecialistLending]: {
    title: `Specialist lending`,
    tooltip: `Our Advisers can potentially support you, where eligible, with your borrowing needs.`,
  },
  [FinancialPlanningTopic.CorporateFinancialPlanning]: {
    title: `Corporate financial planning`,
    tooltip: `Our Advisers support corporate clients in ensuring the financial security of company Directors, employees and shareholders.`,
  },
  [FinancialPlanningTopic.CashSavings]: {
    title: `Cash savings`,
    tooltip: `We understand that holding adequate cash reserves is important, and offers reassurance for meeting those unexpected expenses. Whether you are simply looking to hold some cash for a ‘rainy day fund’, need a temporary home for a much larger sum or need a simple solution to help maximise the interest rate you receive, we can access an appropriate solution.`,
  },
  [FinancialPlanningTopic.GeneralInsurance]: {
    title: `General insurance`,
    tooltip: `Insurance contracts that do not fall under the life assurance classification are called general insurance. There are different forms of general insurance, such as motor, home, accident, and marine.`,
  },
  [FinancialPlanningTopic.DebtManagement]: {
    title: `Debt management`,
    tooltip: `Debt management concerns advising you in the organisation of your financial affairs to optimally pay off all or some of your debts.`,
  },
  [FinancialPlanningTopic.EducationPlanning]: {
    title: `Education planning`,
    tooltip: `This involves advising you on supporting a child’s or young adult’s education as part of your financial planning.`,
  },
  [FinancialPlanningTopic.DisabilityInsurance]: {
    title: `Life, critical illness & disability insurance`,
    tooltip: `We can support clients, and their dependants, in protecting themselves from the financial implications of death, and serious illnesses or conditions.`,
  },
  [FinancialPlanningTopic.RefinancingMortgages]: {
    title: `Refinancing mortgages`,
    tooltip: `Refinancing a mortgage involves replacing an existing mortgage with a new mortgage, often with a change in terms and a review of the ongoing suitability.`,
  },
  [FinancialPlanningTopic.GroupCorporateSchemes]: {
    title: `Group corporate schemes`,
    tooltip: `This typically involves supporting employers to put in place appropriate employee pension and benefit schemes.`,
  },
  [FinancialPlanningTopic.PensionReview]: {
    title: `Pension review`,
    tooltip: `A pension review is where an individual’s current pension scheme(s) is examined to better understand its performance, charges, features and relevance to the individual’s current needs and future plans.`,
  },
  [FinancialPlanningTopic.Repatriation]: {
    title: `Repatriation`,
    tooltip: `We support individuals in either returning to the United Kingdom after a period of time working abroad, or moving to the United Kingdom for the first time. The service involves managing the tax implications of such moves and preserving the individual’s wealth.`,
  },
  [FinancialPlanningTopic.IntergenerationalWealthPlanning]: {
    title: `Intergenerational wealth planning`,
    tooltip: `Intergenerational wealth planning involves bringing family members, of different generations, appropriately together such that their individual financial situations can be planned for and managed within the broader context of their family.`,
  },
  [FinancialPlanningTopic.ExpatAndRepatriation]: {
    title: `Expat and UK repatriation`,
    tooltip: `If you are planning to return to the UK, it is important to make sure that your financial affairs are in order, and any changes to your investments are dealt with efficiently and in good time. Financial advice will help ensure that you don’t face unexpected tax liabilities when you return home or lose out financially if you need to sell investments at the last minute. `,
  },
};

export default {
  topics,
};
