import { useSelector } from 'react-redux';
import { get } from 'lodash';

import EntityPath from 'constants/entitiesPaths';
import type { Client } from 'types/entities/client';

import { createEntitySelectors } from '../entities/selectors';
import type { AppState } from '../types';

const { getById } = createEntitySelectors<Client>(EntityPath.Clients);

export const getCurrentClientId = (state: AppState) => state.currentClientId;

export const getCurrentClient = (state: AppState) => getById(state, getCurrentClientId(state));

export const getIsCurrentClientDeactivated = (state: AppState): boolean =>
  get(getCurrentClient(state), 'deleted', false);

export const useGetCurrentClientId = () => useSelector(getCurrentClientId);

export const useGetCurrentClient = () => useSelector(getCurrentClient);

export const useGetClient = (clientId?: number) =>
  useSelector((state: AppState) => getById(state, clientId));

export const useGetIsCurrentClientDeactivated = () => useSelector(getIsCurrentClientDeactivated);
