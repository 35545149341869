import React, { Suspense } from 'react';

import { CenteredLoader, DocumentWrapper } from '../Wrappers';
import type { Props as PdfProps, RenderProps as PdfRenderProps } from './Pdf';

const Pdf = React.lazy(() => import('./Pdf'));

export type RenderProps = PdfRenderProps;
export type Props = PdfProps;

const PdfLazy = (props: PdfProps) => (
  <Suspense
    fallback={
      <DocumentWrapper>
        <CenteredLoader isVisible isInlineFlex />
      </DocumentWrapper>
    }
  >
    <Pdf {...props} />
  </Suspense>
);

export default PdfLazy;
