import type { AxiosResponse } from 'axios';
import normalize, { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';
import { first, isArray, map, sortBy } from 'lodash';
import build from 'redux-object';

import type EntityPath from 'constants/entitiesPaths';

import { parseResponseHeaders } from './utils';

function normalizeData<T>(response: JsonApiResponse, entityPath: EntityPath): T[];
function normalizeData<T>(response: JsonApiResponseSingle, entityPath: EntityPath): T;
function normalizeData<T>(
  response: JsonApiResponse | JsonApiResponseSingle,
  entityPath: EntityPath
) {
  const normalizedData = build<T>(normalize(response), entityPath);

  return isArray(response.data) ? normalizedData : first(normalizedData);
}

type Entity = { id: number | string };

function normalizeAndSortData<T extends Entity>(response: JsonApiResponse, entityPath: EntityPath) {
  const normalizedData = build<T>(normalize(response), entityPath);
  const ids = map(response?.data, item => item.id);

  return sortBy(normalizedData, [item => ids.indexOf(item.id.toString())]);
}

export type NormalizedPaginatedData<T> = ReturnType<typeof parseResponseHeaders> & { items: T[] };

function normalizePaginatedData<T extends Entity>(
  response: AxiosResponse<JsonApiResponse>,
  entityPath: EntityPath
): NormalizedPaginatedData<T> {
  return {
    items: normalizeAndSortData<T>(response.data, entityPath),
    ...parseResponseHeaders(response),
  };
}

export { normalizeData, normalizeAndSortData, normalizePaginatedData };
