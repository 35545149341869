export enum FinancialAuditQuestionName {
  CountryOfResidence = 'countryOfResidence',
  CountryOfResidencePartner = 'countryOfResidencePartner',
  TaxableIncome = 'taxableIncome',
  TaxableIncomePartner = 'taxableIncomePartner',
  IsaInvested = 'isaInvested',
  IsaInvestedPartner = 'isaInvestedPartner',
  ReceiveInInterest = 'receiveInInterest',
  ReceiveInInterestPartner = 'receiveInInterestPartner',
  ReceiveInDividends = 'receiveInDividends',
  ReceiveInDividendsPartner = 'receiveInDividendsPartner',
  ExpectCapitalGains = 'expectCapitalGains',
  ExpectCapitalGainsPartner = 'expectCapitalGainsPartner',
  CapitalGainExcess = 'capitalGainExcess',
  CapitalGainExcessPartner = 'capitalGainExcessPartner',
  ContributeToPension = 'contributeToPension',
  ContributeToPensionPartner = 'contributeToPensionPartner',
  PensionBenefits = 'pensionBenefits',
  PensionBenefitsPartner = 'pensionBenefitsPartner',
  BiggerAssets = 'biggerAssets',
  BiggerAssetsPartner = 'biggerAssetsPartner',
  MadeGifts = 'madeGifts',
  MadeGiftsPartner = 'madeGiftsPartner',
  MadeWill = 'madeWill',
  Inheritances = 'inheritances',
  InheritancesPartner = 'inheritancesPartner',
  CertainAfterDeath = 'certainAfterDeath',
  LooseMentalCapacity = 'looseMentalCapacity',
}

export enum FinancialPlanningHealthCheckQuestionName {
  RealisticallyIdeal = 'realisticallyIdeal',
  AmountRepresenting = 'amountRepresenting',
  HasGrabbedTaxSavingOpportunities = 'hasGrabbedTaxSavingOpportunities',
  IsHappyAboutAssetPassingAfterDeath = 'isHappyAboutAssetPassingAfterDeath',
  MadeProvisionsForAffairsToBeLooked = 'madeProvisionsForAffairsToBeLooked',
  FinanciallyDependentsHaveEnoughAssets = 'financiallyDependentsHaveEnoughAssets',
  WordBestDescribesAboutFinancialObjectives = 'wordBestDescribesAboutFinancialObjectives',
  ConfidenceOnFinancialPlan = 'confidenceOnFinancialPlan',
}

export enum RealisticallyIdealSubQuestionName {
  RealisticallyIdealInRelationToIncome = 'realisticallyIdealInRelationToIncome',
  RealisticallyIdealInRelationToCapital = 'realisticallyIdealInRelationToCapital',
  RealisticallyIdealInRelationToWork = 'realisticallyIdealInRelationToWork',
  RealisticallyIdealInRelationToMoneyExpend = 'realisticallyIdealInRelationToMoneyExpend',
  RealisticallyIdealInRelationToTimeExpend = 'realisticallyIdealInRelationToTimeExpend',
  RealisticallyIdealInRelationToRetirement = 'realisticallyIdealInRelationToRetirement',
}

export enum AmountRepresentingSubQuestionName {
  AmountRepresentingCapitalIndependence = 'amountRepresentingCapitalIndependence',
  AmountRepresentingIncomeIndependence = 'amountRepresentingIncomeIndependence',
}

export interface FinancialAuditValues {
  [index: string]: string | boolean | null | undefined;
}

export interface FinancialPlanningHealthCheckValues {
  [index: string]: string | boolean | null | undefined;
}

export interface FinancialAuditAnswer {
  value: string;
  text: string;
  hint: string;
  description: string;
}

export interface FinancialPlanningHealthCheckAnswer {
  value: string;
  text: string;
  description: string;
}

export interface FinancialAuditQuestion {
  name: FinancialAuditQuestionName;
  text: string;
  answers: FinancialAuditAnswer[];
  definitions: string;
  isMultiple: boolean;
  subText?: string;
}

export interface Input {
  field: 'input';
  id: string;
  maxLength?: number;
  type: 'text' | 'number' | 'currency';
}

export interface Radio {
  field: 'radio';
  optionsCount: number;
}

export interface FinancialPlanningHealthCheckQuestion {
  name:
    | FinancialPlanningHealthCheckQuestionName
    | AmountRepresentingSubQuestionName
    | RealisticallyIdealSubQuestionName;
  text: string;
  answers: FinancialPlanningHealthCheckAnswer[] | null;
  subQuestions: FinancialPlanningHealthCheckQuestion[] | null;
  definitions: string;
  isMultiple: boolean;
  commonDescription?: string;
  fieldType?: Input | Radio;
}

export enum FinancialAuditVersion {
  Current = 'current',
  New = 'new',
  Old = 'old',
}

export enum FinancialPlanningHealthCheckVersion {
  Current = 'current',
}

export interface FinancialAudit {
  createdAt: string;
  updatedAt: string;
  title: string;
  answers: FinancialAuditValues;
}

export interface FinancialPlanningHealthCheck {
  createdAt: string;
  updatedAt: string;
  title: string;
  answers: FinancialPlanningHealthCheckValues;
}
