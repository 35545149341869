import type { AdviceRoomGuest } from './adviceRoomGuest';
import type { Client } from './client';
import type { Document } from './document';
import type { User } from './user';

export enum AdviceRoomState {
  Scheduled = 'scheduled',
  Completed = 'completed',
  Cancelled = 'cancelled',
}

export enum MediaRegion {
  GlobalLowLatency = 'gll',
  Australia = 'au1',
  Brazil = 'br1',
  Germany = 'de1',
  Ireland = 'ie1',
  India = 'in1',
  Japan = 'jp1',
  Singapore = 'sg1',
  UsEastCoast = 'us1',
  UsWestCoast = 'us2',
}

export const mediaRegions = [
  MediaRegion.GlobalLowLatency,
  MediaRegion.Australia,
  MediaRegion.Brazil,
  MediaRegion.Germany,
  MediaRegion.India,
  MediaRegion.Ireland,
  MediaRegion.Japan,
  MediaRegion.Singapore,
  MediaRegion.UsEastCoast,
  MediaRegion.UsWestCoast,
];

export interface AdviceRoom {
  id: number;
  name: string;
  agenda: string;
  state: AdviceRoomState;
  startDate: string;
  startTime: string;
  documents: Document[];
  clientId: number;
  isTwilioRoomCreated: boolean;
  mediaRegion: MediaRegion;
  createdAt: string;
  updatedAt: string;
  duration?: number;
  image?: string;
  client?: Client;
  attendees: User[];
  guests: AdviceRoomGuest[];
  privateNote?: string;
  lastSentPrivateNote?: string;
  canJoin: boolean;
  outlookEventCreated?: boolean;
  outlookEventId?: boolean;
}
