import { CaseState } from 'types/entities/clientCase';

export default {
  descriptionRequested1: `On {value} you requested a meeting.`,
  descriptionRequested2: `Your Adviser will contact you soon to organise the meeting.`,
  description: {
    [CaseState.Completed]: `Your meeting has been marked as ‘complete’.`,
    [CaseState.Cancelled]: `Please see the case audit trail for further details.`,
  },
  prepareFactFind: `Prepare your Fact Find`,
  prepareFactFindDescription: `Ahead of your meeting, you can optionally prepare a Fact Find for discussion with your Adviser.`,
};
