import React from 'react';
import { FormattedMessage } from 'react-intl';

import GenericLabel, { Props as GenericLabelProps } from 'components/GenericLabel/GenericLabel';

export interface Props
  extends Omit<
    GenericLabelProps,
    'isUpperCase' | 'isSmall' | 'isMedium' | 'isInlineBlock' | 'text'
  > {
  text?: string;
  translatedText?: React.ReactNode;
}

export function Label({ text, translatedText, ...props }: Props) {
  return (
    <GenericLabel
      {...props}
      text={translatedText || <FormattedMessage id={text as string} />}
      isUpperCase
      isSmall
      isInlineBlock
    />
  );
}
