import React, { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import Draggable, { DraggableEventHandler, DraggableProps } from 'react-draggable';

import { boundryName } from 'components/DraggableBoundry/DraggableBoundry';
import ModalContext from 'components/GenericModal/GenericModalContext';

interface RenderProps {
  windowZIndex: number;
}

interface Props extends Omit<Partial<DraggableProps>, 'bounds'> {
  children: (renderProps: RenderProps) => React.ReactChild;
}

let sharedWindowZIndex = 1;
const contextValue = { isInsideModal: true };

const DraggableBase = ({ children, onStart, ...rest }: Props) => {
  const [windowZIndex, setWindowZIndex] = useState(sharedWindowZIndex);

  const handleStart = useCallback<DraggableEventHandler>(
    (...args) => {
      sharedWindowZIndex += 1;
      setWindowZIndex(sharedWindowZIndex);

      if (onStart) {
        return onStart(...args);
      }
    },
    [onStart]
  );

  const parent = document.getElementById(boundryName);

  return parent
    ? ReactDOM.createPortal(
        <ModalContext.Provider value={contextValue}>
          <Draggable bounds="parent" onStart={handleStart} {...rest}>
            {children({ windowZIndex })}
          </Draggable>
        </ModalContext.Provider>,
        parent
      )
    : null;
};

export default DraggableBase;
