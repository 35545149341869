import { useCallback, useState } from 'react';

const useModalState = (defaultState = false): [boolean, () => void, () => void] => {
  const [isOpen, setIsOpen] = useState(Boolean(defaultState));

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  return [isOpen, openModal, closeModal];
};

export default useModalState;
