import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';
import type { Tag } from 'types/entities/clientCase';

interface ClientCaseUpdateTagsPatchOptions {
  tags?: Tag[];
}

export function clientCaseUpdateTagsApi(path: string) {
  return {
    path,
    PATCH: (params: ClientCaseUpdateTagsPatchOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
