import React from 'react';
import { useHistory } from 'react-router';

import routes from 'routes';
import { useGetAdviceRoomById } from 'store/adviceRooms/selectors';
import { useGetIsCurrentUserAClient } from 'store/users/selectors';
import { useGetJoinedAdviceRoomId } from 'store/videoChat/selectors';
import BackButton from 'components/BackButton/BackButton';

export default function ChatBackButton() {
  const history = useHistory();
  const joinedAdviceRoomId = useGetJoinedAdviceRoomId();

  const isCurrentUserAClient = useGetIsCurrentUserAClient();
  const joinedAdviceRoom = useGetAdviceRoomById(joinedAdviceRoomId);
  const clientId = joinedAdviceRoom?.clientId;

  return clientId && joinedAdviceRoomId ? (
    <BackButton
      text="shared.navigation.back"
      onClick={() => {
        history.push(
          isCurrentUserAClient
            ? routes.meetings.clientMeetingRoom.meeting.path(joinedAdviceRoomId)
            : routes.client.clientMeetingRoom.meeting.path(clientId, joinedAdviceRoomId)
        );
      }}
    />
  ) : null;
}
