import { format } from 'date-fns';

import { DATE_CALENDAR_FORMAT_WITHOUT_TIME } from 'constants/dates';
import { Step } from 'components/OnboardingForm/OnboardingSteps';

export default {
  sections: {
    legalAgreements: `Data consent`,
    personalDetails: `Personal details`,
    planningDetails: `Planning details`,
    ourAdviceService: `Our advice service`,
    account: `Account`,
  },
  progress: {
    [Step.Consents]: `Consents`,
    [Step.FirstName]: `First Name`,
    [Step.LastName]: `Last Name`,
    [Step.Age]: `Age`,
    [Step.OtherFinancialNeeds]: `Financial needs`,
    [Step.Contribution]: `Contribution`,
    initialInvestment: `{value} initial contribution`,
    monthlyContribution: `{value} monthly contribution`,
    [Step.HighInterestDebt]: `Debt`,
    [Step.CashReserves]: `Cash reserves`,
    [Step.PreferredAdviser]: `Preferred adviser`,
    [Step.Disclosure]: `Disclosure`,
    [Step.Contact]: `Account details`,
  },
  shared: {
    privacyPolicy: `Privacy Policy`,
    beginButton: `Begin`,
    continueButton: `Continue`,
    continueButtonLabel: `or press ENTER`,
    sendButton: `Send`,
    debtCheckboxInfo: `In this case, your initial investment will be`,
    thankYou: `Thank you, {clientName}.`,
    no: `NO, I don’t`,
    yes: `YES, I do`,
    createPassword: `Create Password`,
    login: `Login`,
  },
  validation: {
    min18age: `Unfortunately, we cannot necessarily advise individuals who are under 18 years of age. Should you require financial advice, we would suggest that you do so by involving a parent, a guardian, a trustee, a donor or a nominee. `,
  },
  stepBegin: {
    summary: `By following this process, you can request a meeting with an adviser. It only takes about 5 minutes for you to provide the necessary information. After our office receives your request for advice, we will contact you.`,
    welcomeKnownClient: `Welcome`,
    welcomeKnownClientName: `Welcome {firstName} {lastName}.`,
    description: `
      <p>As part of this process, we may need details of the following:</p>
      <ul>
        <li><span>✓</span> Your name, date of birth, telephone number, email address, and post code</li>
        <li><span>✓</span> Your financial planning needs and objectives</li>
        <li><span>✓</span> Any investment need that you may have</li>
      </ul>
    `,
    footer: `Your personal information will be managed securely. <policy>See our Privacy Policy.</policy>`,
    invitationHasExpiredDescription: `This invitation link has now expired. If you wish to meet with an Adviser, please click ‘Request a meeting’ below or contact us directly.`,
    invitationAlreadyAcceptedDescription: `This invitation link has already been used and accepted. If you wish to meet with an Adviser, please click ‘Request a meeting’ below or contact us directly.`,
  },
  stepConsents: {
    title: `Legal agreements`,
    personalData: {
      title: `How we may use your personal data`,
      description: `
        <p>As part of our service to you, {authorizedRepresentativeName} may use your personal data for the following purposes:
        </p>
        <ul>
          <li><span>•</span> To arrange initial contact between you and a financial adviser</li>
          <li><span>•</span> To provide you with financial advice and other financial planning services</li>
          <li><span>•</span> To provide you with an ongoing service</li>
          <li><span>•</span> To contact you regarding your investments and financial plans</li>
        </ul>
        <p>
          Please note that this website does not oblige you to provide sensitive personal data
          (e.g. data relating to your health, ethnicity, religion, and sexuality). However, some
          clients do feel it necessary to optionally provide such sensitive data in relation to
          their financial situation and plans.
        </p>
      `,
      consentModaldescription: `
      <p>As part of our service to you, {authorizedRepresentativeName} may use your personal data on this website for the following purposes:
      </p>
      <ul>
        <li><span>•</span> To provide you with financial advice and other financial planning services</li>
        <li><span>•</span> To provide you with an ongoing service</li>
        <li><span>•</span> To contact you regarding your investments and financial plans</li>
      </ul>
      <p>
        Please note that this website does not oblige you to provide sensitive personal data
        (e.g. data relating to your health, ethnicity, religion, and sexuality). However, some
        clients do feel it necessary to optionally provide such sensitive data in relation to
        their financial situation and plans.
      </p>
    `,
      privacyPolicy: `
        <p>
          For further information, please see our <privacypolicylink>Privacy Policy</privacypolicylink>.
        </p>
      `,
      checkboxLabel: `Yes, I agree to this potential use of my personal data.`,
      required: `You must agree to continue to use this service.`,
    },
  },
  stepFirstName: {
    title: `What is your first name?`,
    placeholder: `e.g. Jane`,
  },
  stepLastName: {
    title: `What is your last name?`,
    placeholder: `e.g. Smith`,
  },
  stepAge: {
    title: `When were you born, {value}?`,
    tooltip: `We are legally required to ask, to be confident that we can advise you.`,
    placeholder: `e.g. ${format(new Date(1984, 3, 5), DATE_CALENDAR_FORMAT_WITHOUT_TIME)}`,
  },
  stepContribution: {
    title: `Do you have an investment need that you wish to disclose at this point?`,
    summary: `Our clients often plan and invest with a specific objective in mind. The investment tool below may optionally help you to consider your own objectives, and to understand the role of risk and inflation on potential investment returns.`,
  },
  stepHighInterestDebt: {
    title: `Do you hold significant levels of high-interest debt?`,
    titleTooltip: `Forms of high-interest debt include payday loans, significant credit card
      balances, significant store card balances, and any other loans on which you must pay
      a lot of interest. Note that a mortgage would not be considered as a form of
      high-interest debt.`,
    summary: `In order to advise you appropriately, it is important that we understand if you have any significant amount of high-interest debt.`,
    howBigIsDebt: `How much is the debt?`,
    howBigIsDebtTooltip: `Estimate the total amount of money that you currently owe to payday
      lenders, credit card companies, and other providers of expensive loans.`,
    checkboxLabel: `Take the debt value from initial investment amount`,
    debtCheckboxInfo: `In this case, your initial investment will be`,
  },
  stepCashReserve: {
    title: `Do you have enough cash to cover three months’ worth of household expenditure?`,
    titleTooltip: `
      A 3-month cash reserve might be required to cover scenarios such as elevated
      household expenses, a medical emergency, the unexpected purchase of a big ticket item,
      or unexpected unemployment.`,
    summary: `We always recommend that you have a cash reserve to deal with life’s smaller emergencies.`,
    takeExpenditureTitle: `Do you want to take three months of expenditure from your initial investment of {price}?`,
    takeExpenditureTitleTooltip: `We will reduce your initial investment amount to support you in establishing a three-month cash reserve.`,
    expenditureTitle: `How much is your monthly expenditure?`,
    expenditureTitleTooltip: `
      This is the amount of money you typically spend on regular and irregular expenses
      such as mortgage payments, rent, groceries, Council tax, gas, electricity,
      water, television, insurance, transport, and leisure activities.
    `,
    btnsError: `Unfortunately, we cannot support your investment unless you have a sufficient cash reserve.`,
  },
  stepPreferredAdviser: {
    title: `Do you have a preferred Adviser?`,
    summary: `If you have a preferred Adviser, please select them below. If possible, we will allocate your preferred Adviser to you.`,
    checkboxLabel: `I have no preference`,
    noAvailableAdvisers: `No available Advisers`,
  },
  stepDisclosure: {
    title: `Disclosure: Our services and charges`,
    summary1: `We are required to provide this document to you as it gives an example of the scope of our advice service and the costs of these services. For your own benefit and protection, you should read these example terms carefully before acknowledging them.`,
    summary2: `Later in this advice process, and dependent on your personal requirements, we will provide you with specific details of the scope of our advice service and the costs of these services.`,
    checkboxLabel: `I acknowledge the terms of this service`,
  },
  stepOtherFinancialNeeds: {
    titleAdvice: `Please provide details as to what you would like to discuss during the meeting.`,
    title: `If relevant, please describe any specific financial planning topics that you like to discuss.`,
  },
  stepContact: {
    title: `Next steps and account creation`,
    summary: `Thank you for providing information about yourself and your planning needs. After you submit this information, we will review your request. Then we will contact you about organising a meeting with an Adviser. This meeting may take place in person or via a ‘Client Meeting Room’, which is a video conferencing service within your new account.

Please note that in some circumstances, it may not be possible to advise you.`,
    accountTitle: `Account details`,
    accountSummary: `To create an account, please enter your email address and password.`,
    cityLabel: `City`,
    cityDescription: `Should it be best to meet in person, please provide your city of residence.`,
  },
  stepEditEmail: {
    title: `Thank you. We will soon be in contact.`,
    emailPlaceholder: `e.g. janesmith@exampleemail.com`,
    passwordPlaceholder: `Your new password`,
    passwordConfirmationPlaceholder: `Your new password confirmation`,
    emailLabel: `Send email again to:`,
  },
  stepResendEmail: {
    summary: `We will shortly send you a verification email, so you can confirm your new online account.`,
    emailLabel: `We will send the verification email to:`,
    resendEmail: `Resend email`,
    editEmail: `Edit email`,
  },
  stepConfirmation: {
    title: `Welcome, {firstName}. Create your password.`,
    loginLabel: `Login with: {login}`,
    btnLabel: `By continuing you agree to our <a>Privacy Policy</a>.`,
    confirmationHasExpiredDescription: `This email confirmation link has now expired. If you wish to become a client, please click ‘Request a meeting’ below or contact us directly.`,
  },
};
