import type { InvitationsClientsPostOptions } from 'api/invitations/clients';

export type FormValues = InvitationsClientsPostOptions;

export enum EmailTemplate {
  ProspectiveClient = 'prospectiveClient',
}

export enum OnboardingTemplate {
  Welcome = 'welcome',
}
