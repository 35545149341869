import React from 'react';

import { useGetAvailableCurrencies } from '../hooks/useGetAvailableCurrencies';
import { CurrencyInput, Props } from './CurrencyInput';

export function AvailableCurrencyInput(props: Omit<Props, 'currencies'>) {
  const currencies = useGetAvailableCurrencies();

  return <CurrencyInput currencies={currencies} {...props} />;
}
