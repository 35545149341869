export const TOUR_GUIDE_CARD_AVATAR = 'floating-card-avatar';
export const TOUR_GUIDE_CLIENT_DASHBOARD = 'client-dashboard-tiles-container';
export const TOUR_GUIDE_ADVISER_DASHBOARD = 'adviser-dashboard-tiles-container';
export const TOUR_GUIDE_PRACTICE_ADMIN_DASHBOARD = `practice-admin-dashboard-tiles-container`;
export const TOUR_GUIDE_HELP_GUIDE_BUTTON = `help-guide-button`;

export const TOUR_GUIDE_HELP_CENTRE_TILE = 'help-centre-tile';
export const TOUR_GUIDE_ADVISER_CLIENTS_TILE = 'adviser-clients-tile';
export const TOUR_GUIDE_ADMIN_CLIENTS_TILE = 'admin-clients-tile';
export const TOUR_GUIDE_MY_SUPPORT_TILE = `my-support-tile`;

export const TOUR_GUIDE_CLIENT_PAGE_BUTTON = 'client-page-button';
export const TOUR_GUIDE_HELP_CENTRE_BUTTON = 'help-centre-button';
export const TOUR_GUIDE_REQUEST_TECHNICAL_SUPPORT_BUTTON = 'request-technical-support-button';

export const TOUR_GUIDE_APP_SIDEBAR_CONTAINER = 'app-sidebar';
export const TOUR_GUIDE_APP_SIDEBAR = 'app-sidebar-items';
export const TOUR_GUIDE_APP_SIDEBAR_USER_NAV_AVATAR = 'app-sidebar-user-nav-avatar';
export const TOUR_GUIDE_APP_SIDEBAR_PRACTICE_SETTINGS_LINK = 'app-sidebar-practice-settings-link';
export const TOUR_GUIDE_APP_SIDEBAR_PRACTICE_ADMINS_LINK = 'app-sidebar-practice-admins-link';
export const TOUR_GUIDE_APP_SIDEBAR_ADVISERS_LINK = 'app-sidebar-advisers-link';
export const TOUR_GUIDE_APP_SIDEBAR_CLIENTS_LINK = 'app-sidebar-clients-link';

export const TOUR_GUIDE_APP_BREADCRUMB = 'app-breadcrumb';
export const TOUR_GUIDE_NEW_INVESTMENT_BUTTON = 'new-investment-button';
export const TOUR_GUIDE_SIGN_OUT_BUTTON = 'sign-out-button';

export const CMR_GUIDE_JOIN_BUTTON = 'cmr-join-button';
export const CMR_GUIDE_TEST_BUTTON = 'cmr-test-button';
export const CMR_GUIDE_AGENDA = 'cmr-agenda';
export const CMR_GUIDE_DOCUMENTS = 'cmr-documents';
