import React, { ElementType, HTMLAttributes, useEffect, useRef, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { rem, size } from 'polished';
import styled from 'styled-components';

import type { UpdateRecordingStateParams } from 'api/adviceRooms/adviceRoom/recordings/updateRecordingState';
import GenericLoader from 'components/GenericLoader/GenericLoader';
import { BasicTooltip } from 'components/GenericTooltip';
import { RecordingType, useRecordingContext } from 'components/VideoChat/RecordingContext';

import { Button } from '../VideoChatToolbarButton/VideoChatToolbarButton';
import RecordingSwitcherPopup from './RecordingSwitcherPopup';

interface ButtonProps {
  readonly iconColor?: string;
  readonly pressedIconColor?: string;
}

const Container = styled.div`
  position: relative;
`;

const iconSize = rem(30);

const ButtonIcon = styled.i<{ disabled?: boolean }>`
  ${size(iconSize)};
  display: block;
  color: red;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

interface Props extends HTMLAttributes<HTMLButtonElement>, ButtonProps {
  tooltipText?: React.ReactNode;
  updateRecordingState: (params: UpdateRecordingStateParams) => void;
  icon: ElementType;
  isRecording: boolean | undefined;
}

function RecordingSwitcherButton({
  tooltipText,
  icon,
  isRecording,
  updateRecordingState,
  ...rest
}: Props) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { recordingType, loading } = useRecordingContext();

  const closePopup = () => setOpen(false);
  const togglePopup = () => setOpen(prev => !prev);

  const stopRecording = () => {
    if (!recordingType) return;

    updateRecordingState({ state: 'stop', type: recordingType });
  };

  const switchRecordingState = (type: RecordingType) => {
    updateRecordingState({ state: 'start', type });
    closePopup();
  };

  useEffect(() => closePopup(), [loading]);

  useEffect(() => {
    document.addEventListener('scroll', closePopup);
    return () => document.removeEventListener('scroll', closePopup);
  }, []);

  useOnclickOutside(closePopup, { refs: [buttonRef, popupRef] });

  return (
    <Container>
      <BasicTooltip tooltipMessage={tooltipText} showDelay={600} disabled={open || loading}>
        <Button
          ref={buttonRef}
          onClick={isRecording ? stopRecording : togglePopup}
          aria-pressed={isRecording}
          disabled={loading}
          {...rest}
        >
          {loading ? <GenericLoader size={iconSize} isVisible /> : <ButtonIcon as={icon} />}
        </Button>
      </BasicTooltip>

      {open && (
        <RecordingSwitcherPopup
          ref={popupRef}
          buttonRef={buttonRef}
          switchRecordingState={switchRecordingState}
          recordingType={recordingType}
        />
      )}
    </Container>
  );
}

export default RecordingSwitcherButton;
