import api from 'api/apiInstance';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

export interface SendMessageToMultipleClientsOptions {
  content?: string;
  files?: File[];
  sendEmail?: boolean;
  adviserId?: number;
  clientIds?: number[];
}

export function sendMessageToMultipleClientsApi(path: string) {
  return {
    path,
    POST: (params: SendMessageToMultipleClientsOptions) => {
      return api.post(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      );
    },
  };
}
