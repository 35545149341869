import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { downloadFile } from 'helpers/files';
import { misc } from 'helpers/styles/constants';
import { BasicTooltip } from 'components/GenericTooltip';
import IconDownload from 'components/Icons/IconDownload';

import PdfCircleButton from './components/PdfCircleButton';
import { IconStyled } from './components/styled';

interface Props extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'onClick'> {
  url: string;
}

export function FileDownloadButton({ url, ...rest }: Props) {
  const handleClick = useCallback(() => downloadFile(url), [url]);

  return (
    <BasicTooltip
      zIndex={misc.modalZIndex + 1}
      tooltipMessage={<FormattedMessage id="documentViewer.tooltips.download" />}
    >
      <PdfCircleButton {...rest} onClick={handleClick}>
        <IconStyled iconSize={18} as={IconDownload} />
      </PdfCircleButton>
    </BasicTooltip>
  );
}
