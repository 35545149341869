import React from 'react';

import SvgIcon from './SvgIcon';

const IconArrowLeftsm = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M15 7L10 12L15 17"
      stroke="#000000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default IconArrowLeftsm;
