import React, { useContext } from 'react';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { breakpoints } from 'helpers/styles/constants';

import { GridContext } from './Grid';

export const RowContext = React.createContext({ noGutter: false });

export enum RowAlign {
  Stretch = 'stretch',
  Start = 'start',
  Center = 'center',
  End = 'end',
  Baseline = 'baseline',
  Initial = 'initial',
  Inherit = 'inherit',
}

export enum RowJustify {
  Start = 'start',
  Center = 'center',
  End = 'end',
  Between = 'between',
  Around = 'around',
  Initial = 'initial',
  Inherit = 'inherit',
}

const getJustifyContent = (justify?: RowJustify) => {
  const valueMap = {
    [RowJustify.Start]: 'flex-start',
    [RowJustify.End]: 'flex-end',
    [RowJustify.Between]: 'space-between',
    [RowJustify.Around]: 'space-around',
    [RowJustify.Center]: 'center',
    [RowJustify.Initial]: 'initial',
    [RowJustify.Inherit]: 'inherit',
  };

  return justify && valueMap[justify]
    ? css`
        justify-content: ${valueMap[justify]};
      `
    : null;
};

const getAlignItems = (alignItems?: RowAlign) => {
  const valueMap = {
    [RowAlign.Start]: 'flex-start',
    [RowAlign.End]: 'flex-end',
    [RowAlign.Center]: 'center',
    [RowAlign.Stretch]: 'stretch',
    [RowAlign.Baseline]: 'baseline',
    [RowAlign.Initial]: 'initial',
    [RowAlign.Inherit]: 'inherit',
  };

  return alignItems && valueMap[alignItems]
    ? css`
        align-items: ${valueMap[alignItems]};
      `
    : null;
};

interface WrapperProps {
  align?: RowAlign;
  justify?: RowJustify;
  gutterWidth: number;
  mobileStack?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-wrap: wrap;
  ${({ justify }) => getJustifyContent(justify)};
  ${({ align }) => getAlignItems(align)};
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: ${({ gutterWidth }) => rem(-gutterWidth / 2)};
  margin-right: ${({ gutterWidth }) => rem(-gutterWidth / 2)};

  ${({ mobileStack }) =>
    mobileStack &&
    css`
      flex-direction: column;
      @media ${breakpoints.smUp} {
        flex-direction: row;
      }
    `}
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  align?: RowAlign;
  justify?: RowJustify;
  noGutter?: boolean;
  mobileStack?: boolean;
}

export function Row({
  children,
  align = RowAlign.Stretch,
  justify = RowJustify.Start,
  noGutter = false,
  mobileStack = false,
  ...props
}: Props) {
  const { gutterWidth } = useContext(GridContext);

  return (
    <Wrapper
      gutterWidth={noGutter ? 0 : gutterWidth}
      align={align}
      justify={justify}
      mobileStack={mobileStack}
      {...props}
    >
      <RowContext.Provider value={{ noGutter }}>{children}</RowContext.Provider>
    </Wrapper>
  );
}
