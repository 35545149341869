import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';

import { adviceRoomRecordingShareApi } from './share';

export function adviceRoomRecordingApi(path: string) {
  return {
    path,
    share: () => adviceRoomRecordingShareApi(`${path}/share`),
    fetchTranscription: () => api.get<JsonApiResponse>(`${path}/fetch-transcription`),
    startTranscription: () => api.get<JsonApiResponse>(`${path}/start-transcription`),
    GET: () => api.get(path),
    DELETE: () => api.delete(path),
  };
}
