import React from 'react';
import { isEmpty, mapValues } from 'lodash';

import type { BreakpointValues } from 'helpers/responsiveness';
import Margin from 'components/Margin/Margin';

export type Props = BreakpointValues<boolean>;

export const MARGIN_VALUE = 10;

export function FieldSpacer(props: Props) {
  return (
    <Margin
      top={
        isEmpty(props)
          ? MARGIN_VALUE
          : mapValues(props, isEnabled => (isEnabled ? MARGIN_VALUE : undefined))
      }
    />
  );
}
