export default {
  pageTitle: `Cookies`,
  pageSubtitle: `How this site uses cookies to improve your browsing experience.`,
  description: `Cookies are pieces of information stored by your web browser on behalf of a website. The purpose of the computer cookie is to help that website to keep track of your visits and activity.`,
  purposesHeader: `We use cookies on our website for a number of purposes, which can be broken down into the following categories:`,
  necessaryCookies: {
    title: `Necessary Cookies`,
    description: {
      short: `These cookies are essential for the proper functioning of the website.`,
      long: `These cookies are required to use certain features of our website. One example would be a cookie that records if you have accepted this policy.`,
    },
  },
  analyticsCookies: {
    title: `Analytics Cookies`,
    description: {
      short: `These cookies allow us to create the anonymous statistics and analysis of the visitors.`,
      long: `These cookies allow us to anonymously analyse how many users visit our site, how they navigate etc. This analysis helps us to continually improve this site and your experience.`,
    },
  },
  tableHeader: `Cookies we collect`,
  manage: `You can manage cookies <button>here</button>.`,
  note: `Please note that disabling cookies might negatively affect your user experience and restrict your access to some features of our website.`,
  consent: {
    description: `We use cookies to provide the best possible experience for users. By continuing to use our site we will take that as your consent to allow us to use cookies. However, you can disable cookies at any time if you wish. Read more <link>about our cookies</link>.`,
    allowSelected: `Allow selected`,
    allowAll: `Allow all`,
  },
  cookie: `Cookie`,
  purpose: `Purpose`,
  consentCookiesDescription: `These cookies store whether you have acknowledged the usage of cookies on our website.`,
  matomoCookiesDescription: `These cookies anonymously track how visitors use our website, recording interactions to help us improve your user experience.`,
  newRelicCookiesDescription: `These cookies help to log any possible performance issues to help us improve your user experience.`,
  rollbarCookiesDescription: `These cookies help to log any possible errors to help us improve your user experience.`,
};
