import jsonFormData from 'json-form-data';
import { mapKeys, snakeCase } from 'lodash';

import { mapKeysDeep } from 'helpers/utils';

export const convertJsonToFormData = (data: any) =>
  jsonFormData(data, { showLeafArrayIndexes: false });

export const mapKeyToSnakeCase = (value: unknown, key: string) => snakeCase(key);

export const mapFormData = (data: any, iterator = mapKeyToSnakeCase) => mapKeys(data, iterator);
export const mapFormDataDeep = (data: any, iterator = mapKeyToSnakeCase) =>
  mapKeysDeep(data, iterator);

export const convertToBool = (value: any) => [true, 1, '1', 'true'].includes(value);
