export interface BreakpointValues<T> {
  usDown?: T;
  usOnly?: T;
  usUp?: T;
  xsDown?: T;
  xsOnly?: T;
  xsUp?: T;
  smDown?: T;
  smOnly?: T;
  smUp?: T;
  mdDown?: T;
  mdOnly?: T;
  mdUp?: T;
  lgDown?: T;
  lgOnly?: T;
  lgUp?: T;
  xlUp?: T;
}

export type BreakpointName = keyof BreakpointValues<unknown>;

export const breakpointNameArray = [
  'usDown',
  'usOnly',
  'usUp',
  'xsDown',
  'xsOnly',
  'xsUp',
  'smDown',
  'smOnly',
  'smUp',
  'mdDown',
  'mdOnly',
  'mdUp',
  'lgDown',
  'lgOnly',
  'lgUp',
  'xlUp',
] as BreakpointName[];
