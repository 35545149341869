import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useGetOnlineAdviceMinDuration } from 'store/settings/selectors';
import { CaseType } from 'types/entities/clientCase';
import FormActionResponsiveButtons from 'components/FormActionButtons/FormActionResponsiveButtons';
import GenericHint from 'components/GenericHint/GenericHint';
import { GenericTitle, TitleMargin } from 'components/GenericTitle/GenericTitle';
import GenericTitleSummary from 'components/GenericTitleSummary/GenericTitleSummary';
import InvestmentDetailsFieldGroup from 'components/InvestmentDetailsForm/InvestmentDetailsFieldGroup/InvestmentDetailsFieldGroup';

import { Fields, StepProps } from '../../types';

const StepContribution: FC<StepProps> = ({ values }) => {
  const minOnlineAdviceDuration = useGetOnlineAdviceMinDuration();

  const isOnlineAdviceRequest = values[Fields.CaseType] === CaseType.OnlineAdviceRequest;

  return (
    <>
      <GenericTitle marginBottom={TitleMargin.Sm} isMedium isBlock>
        <FormattedMessage id="onboarding.stepContribution.title" tagName="span" />
        {!isOnlineAdviceRequest && <GenericHint text="shared.optional" />}
      </GenericTitle>

      <GenericTitleSummary hasMarginBottomMd>
        <FormattedMessage id="onboarding.stepContribution.summary" />
      </GenericTitleSummary>

      <InvestmentDetailsFieldGroup
        minDuration={isOnlineAdviceRequest ? minOnlineAdviceDuration : undefined}
        initialInvestmentFieldName={Fields.InitialInvestment}
        monthlyContributionFieldName={Fields.MonthlyContribution}
        investmentDurationFieldName={Fields.InvestmentDuration}
        currencyFieldName={Fields.Currency}
        projectionTypeFieldName={Fields.ProjectionType}
      />

      <FormActionResponsiveButtons hasUnderline />
    </>
  );
};

export default StepContribution;
