import 'react-datepicker/dist/react-datepicker.css';

import React, { useCallback } from 'react';
import DatePicker from 'react-datepicker';
import { isValid, parseISO } from 'date-fns';
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';

import {
  DATE_CALENDAR_FORMAT_WITH_ONLY_TIME,
  DATE_CALENDAR_FORMAT_WITH_TIME,
  DATE_CALENDAR_FORMAT_WITHOUT_TIME,
} from 'constants/dates';
import { ensureDate } from 'helpers/dates';
import { misc } from 'helpers/styles/constants';
import { circle } from 'helpers/styles/mixins';
import GenericInput, { Props as GenericInputProps } from 'components/GenericInput/GenericInput';
import { useGenericModalContext } from 'components/GenericModal/GenericModalContext';

interface WrapperProps {
  hasFullWidth?: boolean;
  className?: string;
  isInsideModal?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: block;

  /* important statements needed because we're overriding lib's styles */
  .react-datepicker-popper {
    z-index: ${({ isInsideModal }) => (isInsideModal ? misc.modalZIndex + 1 : 2)};
  }

  .react-datepicker {
    display: inline-flex;
    font-family: ${({ theme }) => theme.fontFamilies.main};
    border-radius: 0;
    border: none;
    box-shadow: 0 ${rem(10)} ${rem(10)} 0 ${rgba(0, 0, 0, 0.06)};

    ${({ hasFullWidth }) =>
      hasFullWidth &&
      css`
        &-wrapper,
        &__input-container {
          width: 100%;
        }
      `};

    &__header {
      background-color: transparent;
      border: none;
    }

    &__triangle {
      display: none;
    }

    &__navigation {
      outline: none;

      &--next--with-time {
        right: 90px !important;
      }
    }

    &__current-month {
      margin: 2px 0 10px 0;
      font-weight: ${({ theme }) => theme.fontWeights.mainNormal};
    }

    &__day,
    &__day-name {
      width: 35px;
    }

    &__day {
      color: ${({ theme }) => theme.colors.textDark};
      outline: none;

      &:hover {
        border-radius: 0;
      }

      &-name {
        width: 35px;
        font-size: ${({ theme }) => theme.fontSizes.dbSmallSm};
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.textLight};
      }

      &--selected,
      &--keyboard-selected {
        position: relative;
        z-index: 0;
        border-radius: 0;
        background-color: transparent !important;
        color: ${({ theme }) => theme.colors.white};

        &::before {
          ${circle('2em')};
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: -1;
          background-color: ${({ theme }) => theme.colors.accent};
          transform: translate(-50%, -50%);
        }
      }

      &--outside-month,
      &--disabled {
        color: ${({ theme }) => theme.colors.mischka};
      }
    }

    &__time-container,
    &__time-box {
      width: auto !important;
    }

    &__time-list-item {
      height: auto !important;
      padding: 10px !important;

      &--selected {
        background-color: ${({ theme }) => theme.colors.accent} !important;
      }
    }

    &__month-dropdown-container,
    &__year-dropdown-container {
      padding: ${rem(10)};
      font-size: ${({ theme }) => theme.fontSizes.small};
    }

    &__month-dropdown,
    &__year-dropdown {
      border-radius: 0;
      border: 1px solid ${({ theme }) => theme.colors.panelBorder};
      box-shadow: 0 ${rem(10)} ${rem(10)} 0 ${rgba(0, 0, 0, 0.06)};
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const CustonInputWrapper = styled.div`
  position: relative;
`;

export const Button = styled.button.attrs({ type: 'button' })`
  position: absolute;
  top: 50%;
  right: ${rem(10)};
  padding: ${rem(6)} ${rem(8)};
  font-size: ${({ theme }) => theme.fontSizes.dbNormalSm};
  background-color: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.buttonText};
  transform: translateY(-50%);
`;

type CustomInputProps = GenericInputProps &
  Pick<Props, 'renderButton'> &
  Pick<RenderProps, 'changeDate'>;

export const CustomInput = React.forwardRef<HTMLInputElement, CustomInputProps>(
  ({ renderButton, changeDate, ...inputProps }, ref) => {
    return (
      <CustonInputWrapper>
        <GenericInput {...inputProps} ref={ref} />
        {renderButton && renderButton({ changeDate })}
      </CustonInputWrapper>
    );
  }
);

export type DateValue = string | null;
export type onDateChange = (data: DateValue) => void;
interface RenderProps {
  changeDate: (data: Date) => void;
}

export interface Props extends Omit<WrapperProps, 'isInsideModal'> {
  id?: string;
  value?: DateValue;
  onChange: onDateChange;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  hasError?: boolean;
  minDate?: string | Date;
  maxDate?: string | Date;
  disabled?: boolean;
  hasNoTimeSelect?: boolean;
  showYearDropdown?: boolean;
  showMonthDropdown?: boolean;
  showDisabledAsText?: boolean;
  showTimeSelectOnly?: boolean;
  hasNoBackground?: boolean;
  renderButton?: (renderProps: RenderProps) => React.ReactElement;
}

export function GenericDatepicker({
  value = null,
  className,
  hasFullWidth,
  minDate,
  maxDate,
  id,
  onChange,
  onFocus,
  onBlur,
  hasNoTimeSelect,
  showYearDropdown,
  showMonthDropdown,
  showTimeSelectOnly,
  placeholder,
  disabled,
  hasError,
  showDisabledAsText,
  hasNoBackground,
  renderButton,
}: Props) {
  const { isInsideModal } = useGenericModalContext();

  const changeDate = useCallback(
    (date: Date) => {
      if (onChange) {
        // we need it due to the js behavior
        // more here: https://github.com/Hacker0x01/react-datepicker/issues/1018
        if (hasNoTimeSelect && date) {
          date.setHours(12);
        }

        onChange(date ? date.toISOString() : date);
      }
    },
    [onChange, hasNoTimeSelect]
  );

  let selectedValue = null;
  if (value) {
    const parsedValue = parseISO(value);
    selectedValue = isValid(parsedValue) ? parsedValue : null;
  }

  const parseDate = (date: Date | string | undefined) => {
    if (!date) {
      // value below must be undefined and not null because of internal lib implementation
      return undefined;
    }

    return ensureDate(date);
  };

  const dateFormat = () => {
    if (hasNoTimeSelect) {
      return DATE_CALENDAR_FORMAT_WITHOUT_TIME;
    }

    if (showTimeSelectOnly) {
      return DATE_CALENDAR_FORMAT_WITH_ONLY_TIME;
    }

    return DATE_CALENDAR_FORMAT_WITH_TIME;
  };

  return (
    <Wrapper className={className} hasFullWidth={hasFullWidth} isInsideModal={isInsideModal}>
      <DatePicker
        id={id}
        customInput={
          <CustomInput
            renderButton={disabled ? undefined : renderButton}
            changeDate={changeDate}
            hasFullWidth={hasFullWidth}
            hasError={hasError}
            showDisabledAsText={showDisabledAsText}
            hasNoBackground={hasNoBackground}
          />
        }
        disabled={disabled}
        selected={selectedValue}
        onChange={changeDate}
        onFocus={onFocus}
        onBlur={onBlur}
        timeIntervals={15}
        dateFormat={dateFormat()}
        minDate={parseDate(minDate)}
        maxDate={parseDate(maxDate)}
        showTimeSelect={!hasNoTimeSelect}
        showYearDropdown={showYearDropdown}
        showMonthDropdown={showMonthDropdown}
        showTimeSelectOnly={showTimeSelectOnly}
        placeholderText={placeholder}
        yearDropdownItemNumber={80}
        scrollableYearDropdown
        useWeekdaysShort
      />
    </Wrapper>
  );
}

GenericDatepicker.Button = Button;
