import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

import { breakpoints } from './constants';

export const useRwdQuery = () => {
  const isDesktop = useMediaQuery({ query: breakpoints.smUp });
  const isTablet = useMediaQuery({ query: breakpoints.smOnly });

  return useMemo(() => ({ isDesktop, isMobile: !isDesktop, isTablet }), [isDesktop, isTablet]);
};
