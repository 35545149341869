import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';
import type { AccountType } from 'components/TopUps/types';

import { clientTopUpAuditTrailApi } from './auditTrail';
import { clientTopUpCancelApi } from './cancel';
import { clientTopUpCompleteApi } from './complete';
import { clientTopUpUpdatePrivateNoteApi } from './updatePrivateNote';

export interface ClientTopUpPatchOptions {
  accountType?: AccountType;
  accountNumber?: string;
  amount?: number;
  amountCurrency?: string;
  title?: string;
  clientComment?: string;
  image?: File;
  existingImageId?: number;
}

export function clientTopUpApi(path: string) {
  return {
    path,
    auditTrail: () => clientTopUpAuditTrailApi(`${path}/audit-trail`),
    cancel: () => clientTopUpCancelApi(`${path}/cancel`),
    complete: () => clientTopUpCompleteApi(`${path}/complete`),
    updatePrivateNote: () => clientTopUpUpdatePrivateNoteApi(`${path}/update-private-note`),
    GET: (config?: RequestConfig) => api.get<JsonApiResponseSingle>(path, config),
    PATCH: (params: ClientTopUpPatchOptions) =>
      api.patch<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      ),
  };
}
