import React, { LabelHTMLAttributes } from 'react';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { isBlank } from 'helpers/utils';

interface LabelProps {
  readonly isTruncated?: boolean;
  readonly hasMarginTopSm?: boolean;
  readonly hasMarginTopMd?: boolean;
  readonly hasMarginRightSm?: boolean;
  readonly hasNoMarginBottom?: boolean;
  readonly noWrap?: boolean;
  readonly isInlineBlock?: boolean;
  readonly infoText?: string | React.ReactNode;
}

const Label = styled.label<LabelProps>`
  display: block;
  margin-bottom: ${rem(10)};

  ${({ isTruncated }) =>
    isTruncated &&
    css`
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 1.2;
    `};

  ${({ hasMarginTopSm }) => hasMarginTopSm && `margin-top: ${rem(20)}`};
  ${({ hasMarginTopMd }) => hasMarginTopMd && `margin-top: ${rem(40)}`};

  ${({ hasMarginRightSm }) => hasMarginRightSm && `margin-right: ${rem(20)}`};

  ${({ hasNoMarginBottom }) => hasNoMarginBottom && 'margin-bottom: 0'};

  ${({ noWrap }) => noWrap && 'white-space: nowrap'};

  ${({ isInlineBlock }) => isInlineBlock && 'display: inline-block'};
`;

interface TextProps {
  readonly isUpperCase?: boolean;
  readonly isSmall?: boolean;
  readonly isMedium?: boolean;
  readonly isActive?: boolean;
}

const Text = styled.span<TextProps>`
  line-height: 2;
  color: ${({ isActive, theme }) => (isActive ? theme.colors.accent : theme.colors.textLight)};

  ${({ isUpperCase }) => isUpperCase && 'text-transform: uppercase'};

  ${({ isSmall, theme }) =>
    isSmall &&
    css`
      font-size: ${theme.fontSizes.dbSmallSm};
      letter-spacing: 0.12em;
    `};

  ${({ isMedium, theme }) => isMedium && `font-size: ${theme.fontSizes.dbSmallMd}`};
`;

const InfoText = styled.div<TextProps>`
  line-height: 1.3;
  font-size: 12px;
  color: ${({ isActive, theme }) => (isActive ? theme.colors.accent : theme.colors.textLight)};
`;

export interface Props extends LabelProps, TextProps {
  readonly id?: string;
  readonly elementId?: string;
  readonly isRequired?: boolean;
  readonly text: React.ReactNode;
  readonly tooltip?: React.ReactNode;
}

const GenericLabel = ({
  hasMarginTopSm,
  hasMarginTopMd,
  hasMarginRightSm,
  hasNoMarginBottom,
  isInlineBlock,
  infoText,
  isUpperCase,
  isSmall,
  isMedium,
  isRequired,
  noWrap,
  isTruncated,
  isActive,
  text,
  elementId = '',
  tooltip,
  id,
}: Props) => {
  let labelProps: LabelHTMLAttributes<HTMLLabelElement> = {};
  if (!isBlank(elementId)) {
    labelProps = {
      htmlFor: elementId,
    };
  }

  return (
    <Label
      id={id}
      isTruncated={isTruncated}
      hasMarginTopSm={hasMarginTopSm}
      hasMarginTopMd={hasMarginTopMd}
      hasMarginRightSm={hasMarginRightSm}
      hasNoMarginBottom={hasNoMarginBottom}
      noWrap={noWrap}
      isInlineBlock={isInlineBlock}
      {...labelProps}
    >
      <Text isUpperCase={isUpperCase} isSmall={isSmall} isMedium={isMedium} isActive={isActive}>
        {text}
        {isRequired && '*'}
        {tooltip}
      </Text>
      {infoText && <InfoText>{infoText}</InfoText>}
    </Label>
  );
};

export default GenericLabel;
