import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, PaginationOptions, RequestConfig } from 'api/types';
import { convertJsonToFormData, mapFormDataDeep } from 'helpers/formData';
import type { DocumentVaultDocumentType } from 'types/entities/documentVaultItem';

import { clientDocumentVaultItemsAuditTrailApi } from './auditTrail';

export type DocumentVaultItemsGetOptions = GetOptions &
  PaginationOptions & {
    filter?: {
      parentFolder?: number | null;
      documentType?: DocumentVaultDocumentType;
    };
  };

export interface DocumentVaultItemsPostOptions {
  file: File;
  title: string;
  documentType: DocumentVaultDocumentType;
  sharedWithAdviser: boolean;
  image?: File;
  existingImageId?: number;
  parentFolderId?: number;
}

export function clientDocumentVaultItemsApi(path: string) {
  return {
    path,
    auditTrail: () => clientDocumentVaultItemsAuditTrailApi(`${path}/audit-trail`),
    GET: (params: DocumentVaultItemsGetOptions = {}, config?: RequestConfig) => {
      const { pageSize, pageNumber, filter, ...rest } = params;

      return api.get<JsonApiResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...(filter?.parentFolder !== undefined && {
            'filter[parent_folder]': filter?.parentFolder || 'null',
          }),
          ...(filter?.documentType && { 'filter[document_type]': filter?.documentType }),
          ...rest,
        },
        ...config,
      });
    },
    POST: (params: DocumentVaultItemsPostOptions) =>
      api.post<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormDataDeep(params),
          },
        })
      ),
  };
}
