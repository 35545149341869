import api from 'api/apiInstance';

export interface ClientRemovePostOptions {
  password: string;
}

export function clientRemoveApi(path: string) {
  return {
    path,
    POST: ({ password }: ClientRemovePostOptions) =>
      api.post(path, {
        data: {
          attributes: {
            current_password: password,
          },
        },
      }),
  };
}
