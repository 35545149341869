import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import FormActionResponsiveButtons from 'components/FormActionButtons/FormActionResponsiveButtons';
import { GenericTitle, TitleMargin } from 'components/GenericTitle/GenericTitle';
import GenericTitleSummary from 'components/GenericTitleSummary/GenericTitleSummary';

import { Fields, StepProps } from '../../types';
import { PreferredAdvisersList } from '../shared/PreferredAdvisersList';

const StepPreferredAdviser: FC<StepProps> = ({ values }) => (
  <>
    <GenericTitle marginBottom={TitleMargin.Sm} isMedium>
      <FormattedMessage id="onboarding.stepPreferredAdviser.title" />
    </GenericTitle>
    <GenericTitleSummary hasMarginBottomLg>
      <FormattedMessage id="onboarding.stepPreferredAdviser.summary" />
    </GenericTitleSummary>

    <PreferredAdvisersList />

    <FormActionResponsiveButtons
      submitDisabled={values[Fields.PreferredAdviserId] === undefined}
      hasUnderline
    />
  </>
);

export default StepPreferredAdviser;
