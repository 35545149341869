import type { AccountStatus } from 'constants/accountStatus';

import type { Adviser } from './adviser';
import type { AdviserPaManifest } from './adviserPaManifest';
import type { User } from './user';

export enum PermissionLevel {
  Restricted = 'restricted',
  Normal = 'normal',
}

export interface PracticeAdmin {
  id: number;
  firstName: string;
  fullName: string;
  lastName: string;
  userId: number;
  email: string;
  accountStatus: AccountStatus;
  advisers?: Adviser[];
  avatar?: string;
  clientsNum?: number;
  deleted?: boolean;
  isConfirmed?: boolean;
  lastActivity?: string;
  phone?: string;
  title?: string;
  user?: User;
  visible?: never;
  permissionLevel: PermissionLevel;
  isBlocked: boolean;
  otpEnabled?: boolean;
  followNewClients?: boolean;
  adviserPaManifests?: AdviserPaManifest[];
}
