import React from 'react';
import { FormattedMessage } from 'react-intl';
import { times } from 'lodash';
import { rem, rgba } from 'polished';
import styled from 'styled-components';
import type { Participant } from 'twilio-video';

import { BasicTooltip } from 'components/GenericTooltip';
import { useUpdateOnEvents } from 'components/VideoChat/useUpdateOnEvents';

import { useChatWindowContext } from '../../ChatWindow/ChatWindowContext';
import StatusBackground from '../../StatusBackground/StatusBackground';

const MAX_QUALITY_LEVEL = 5;

const Bar = styled.div<{
  isFilled: boolean;
  barHeight: number;
  barWidth: number;
  qualityLevel: number;
}>`
  width: ${({ barWidth }) => rem(barWidth)};
  height: ${({ barHeight }) => barHeight}%;
  background-color: ${({ theme, isFilled, qualityLevel }) => {
    const getColor = () => {
      let color = theme.colors.greenSuccess;

      if (qualityLevel < 4) {
        color = theme.colors.orangeWarning;
      }

      if (qualityLevel < 2) {
        color = theme.colors.redDanger;
      }

      return color;
    };

    return rgba(isFilled ? getColor() : theme.colors.grey, 0.8);
  }};

  & + & {
    margin-left: 1px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
`;

const EVENTS = ['networkQualityLevelChanged'];

interface Props {
  participant: Participant;
  isLocal?: boolean;
}

export function NetworkQuality({ participant, isLocal }: Props) {
  useUpdateOnEvents(participant, EVENTS);

  const { isUISmall } = useChatWindowContext();

  const qualityLevel = participant.networkQualityLevel;

  if (qualityLevel == null) {
    return null;
  }

  return (
    <BasicTooltip
      tooltipMessage={
        <span>
          <b>
            <FormattedMessage
              id="adviceRooms.networkQuality.title"
              values={{
                isLocal: Boolean(isLocal),
                qualityLevel,
              }}
            />
          </b>{' '}
          <FormattedMessage
            id={`adviceRooms.networkQuality.descriptions.${qualityLevel}`}
            values={{
              isLocal: Boolean(isLocal),
            }}
          />
        </span>
      }
    >
      <StatusBackground isSmall={isUISmall}>
        <Wrapper>
          {times(MAX_QUALITY_LEVEL, index => (
            <Bar
              key={index}
              isFilled={qualityLevel > index}
              qualityLevel={qualityLevel}
              barWidth={isUISmall ? 2 : 4}
              barHeight={(100 / MAX_QUALITY_LEVEL) * (index + 1)}
            />
          ))}
        </Wrapper>
      </StatusBackground>
    </BasicTooltip>
  );
}
