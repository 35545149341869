import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions } from 'api/types';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

import { adviceRoomAuditTrailApi } from './auditTrail';
import { adviceRoomCalendarEventsApi } from './calendarEvents';
import { adviceRoomCancelApi } from './cancel';
import { adviceRoomCanUseApi } from './canUse';
import { adviceRoomCompleteApi } from './complete';
import { adviceRoomDocumentsApi } from './documents';
import { adviceRoomDocumentApi } from './documents/document';
import { adviceRoomDocumentSetsApi } from './documentSets';
import { adviceRoomExistingImagesApi } from './existingImages';
import { adviceRoomGenerateTestSetupTokenApi } from './generateTestSetupToken';
import { adviceRoomGenerateTokenApi } from './generateToken';
import { adviceRoomGuestsApi } from './guests';
import { adviceRoomGuestApi } from './guests/guest';
import { adviceRoomPossibleParticipantsApi } from './possibleParticipants';
import { adviceRoomRecordingsApi } from './recordings';
import { adviceRoomRecordingApi } from './recordings/recording';
import { adviceRoomResendInvitationstApi } from './resendInvitations';
import { adviceRoomTasksApi } from './tasks';
import { adviceRoomTaskApi } from './tasks/task';
import { adviceRoomUpdatePrivateNoteApi } from './updatePrivateNote';

export type AdviceRoomGetResponse = JsonApiResponseSingle;

export interface AdviceRoomPatchOptions {
  name?: string;
  agenda?: string;
  startDate?: string;
  startTime?: string;
  state?: string;
  mediaRegion?: string;
  image?: File;
  existingImageId?: number;
  userIds?: number[];
  duration?: string;
}

export function adviceRoomApi(path: string) {
  type AdviceRoomRecordingApi = ReturnType<typeof adviceRoomRecordingApi>;
  type AdviceRoomRecordingsApi = ReturnType<typeof adviceRoomRecordingsApi>;

  function recordings(): AdviceRoomRecordingsApi;
  function recordings(compositionId: string): AdviceRoomRecordingApi;
  function recordings(compositionId?: string): AdviceRoomRecordingApi | AdviceRoomRecordingsApi {
    const recordingsPath = `${path}/recordings`;

    return compositionId
      ? adviceRoomRecordingApi(`${recordingsPath}/${compositionId}`)
      : adviceRoomRecordingsApi(recordingsPath);
  }

  type AdviceRoomDocumentApi = ReturnType<typeof adviceRoomDocumentApi>;
  type AdviceRoomDocumentsApi = ReturnType<typeof adviceRoomDocumentsApi>;

  function documents(): AdviceRoomDocumentsApi;
  function documents(documentId: number): AdviceRoomDocumentApi;
  function documents(documentId?: number): AdviceRoomDocumentApi | AdviceRoomDocumentsApi {
    const documentsPath = `${path}/documents`;

    return documentId
      ? adviceRoomDocumentApi(`${documentsPath}/${documentId}`)
      : adviceRoomDocumentsApi(documentsPath);
  }

  type AdviceRoomGuestApi = ReturnType<typeof adviceRoomGuestApi>;
  type AdviceRoomGuestsApi = ReturnType<typeof adviceRoomGuestsApi>;

  function guests(): AdviceRoomGuestsApi;
  function guests(guestId: number): AdviceRoomGuestApi;
  function guests(guestId?: number): AdviceRoomGuestApi | AdviceRoomGuestsApi {
    const guestsPath = `${path}/guests`;

    return guestId
      ? adviceRoomGuestApi(`${guestsPath}/${guestId}`)
      : adviceRoomGuestsApi(guestsPath);
  }

  type AdviceRoomTaskApi = ReturnType<typeof adviceRoomTaskApi>;
  type AdviceRoomTasksApi = ReturnType<typeof adviceRoomTasksApi>;

  function tasks(): AdviceRoomTasksApi;
  function tasks(taskId: number): AdviceRoomTaskApi;
  function tasks(taskId?: number): AdviceRoomTaskApi | AdviceRoomTasksApi {
    const tasksPath = `${path}/tasks`;

    return taskId ? adviceRoomTaskApi(`${tasksPath}/${taskId}`) : adviceRoomTasksApi(tasksPath);
  }

  return {
    path,
    auditTrail: () => adviceRoomAuditTrailApi(`${path}/audit-trail`),
    calendarEvents: () => adviceRoomCalendarEventsApi(`${path}/calendar-events`),
    cancel: () => adviceRoomCancelApi(`${path}/cancel`),
    canUse: () => adviceRoomCanUseApi(`${path}/can-use`),
    complete: () => adviceRoomCompleteApi(`${path}/complete`),
    documents,
    documentSets: () => adviceRoomDocumentSetsApi(`${path}/document-sets`),
    existingImages: () => adviceRoomExistingImagesApi(`${path}/existing-images`),
    generateTestSetupToken: () =>
      adviceRoomGenerateTestSetupTokenApi(`${path}/generate-test-setup-token`),
    generateToken: () => adviceRoomGenerateTokenApi(`${path}/generate-token`),
    guests,
    possibleParticipants: () => adviceRoomPossibleParticipantsApi(`${path}/possible-participants`),
    recordings,
    currentRecordingState: () => ({
      GET: () => api.get<JsonApiResponseSingle>(`${path}/current-recording-state`),
    }),
    resendInvitations: () => adviceRoomResendInvitationstApi(`${path}/resend-invitations`),
    tasks,
    updatePrivateNote: () => adviceRoomUpdatePrivateNoteApi(`${path}/update-private-note`),
    GET: (params?: GetOptions) => api.get<AdviceRoomGetResponse>(path, { params }),
    PATCH: (params: AdviceRoomPatchOptions) => {
      return api.patch<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData({
              ...params,
              startDate:
                params.startDate &&
                params.startTime &&
                `${params.startDate.split('T')[0]}T${params.startTime.split('T')[1]}`,
            }),
          },
        })
      );
    },
  };
}
