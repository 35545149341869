import React from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { pickBy } from 'lodash';

export interface Props extends React.SVGAttributes<SVGSVGElement> {
  children: React.ReactNode;
}

function SvgIcon({ children, ...rest }: Props) {
  const validProps = pickBy(rest, (value, key) => isPropValid(key));

  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...validProps}>
      {children}
    </svg>
  );
}

export default SvgIcon;
