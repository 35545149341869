import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

export interface UserUpdateAsPdPatchOptions {
  firstName?: string;
  lastName?: string;
  visible?: boolean;
  title?: string;
  phone?: string;
}

export function userUpdateAsPdApi(path: string) {
  return {
    path,
    PATCH: ({ visible, ...data }: UserUpdateAsPdPatchOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(data),
          relationships: {
            adviser: {
              data: {
                visible,
              },
            },
          },
        },
      }),
  };
}
