import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { PaginationOptions, RequestConfig } from 'api/types';

import { clientAuditTrailPrintApi } from './print';

export type ClientAuditTrailGetOptions = PaginationOptions;

export function clientAuditTrailApi(path: string) {
  return {
    path,
    print: () => clientAuditTrailPrintApi(`${path}/print`),
    GET: (params: ClientAuditTrailGetOptions, config?: RequestConfig) => {
      const { pageSize, pageNumber, ...rest } = params;

      return api.get<JsonApiResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...rest,
        },
        ...config,
      });
    },
  };
}
