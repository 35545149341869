import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

import { serviceCharterApi } from './serviceCharter';

export interface ServiceChartersPostOptions {
  title: string;
  availableUntil: string;
  file: File;
  image?: File;
  existingImageId?: number;
}

const apiPath = '/api/v1/service-charters';

function serviceChartersApi(path: string) {
  return {
    path,
    GET: (config?: RequestConfig) => {
      return api.get<JsonApiResponse>(path, config);
    },
    POST: (params: ServiceChartersPostOptions) =>
      api.post<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      ),
  };
}

type ServiceCharterApi = ReturnType<typeof serviceCharterApi>;
type ServiceChartersApi = ReturnType<typeof serviceChartersApi>;

function serviceCharters(): ServiceChartersApi;
function serviceCharters(charterId: number): ServiceCharterApi;
function serviceCharters(charterId?: number): ServiceChartersApi | ServiceCharterApi {
  return charterId ? serviceCharterApi(`${apiPath}/${charterId}`) : serviceChartersApi(apiPath);
}

export { serviceCharters };
