export const SAVE_FINANCIAL_AUDIT = 'SAVE_FINANCIAL_AUDIT';
export const SAVE_FINANCIAL_AUDIT_SUCCESS = 'SAVE_FINANCIAL_AUDIT_SUCCESS';
export const SAVE_FINANCIAL_AUDIT_FAIL = 'SAVE_FINANCIAL_AUDIT_FAIL';

export const GET_FINANCIAL_AUDIT = 'GET_FINANCIAL_AUDIT';
export const GET_FINANCIAL_AUDIT_SUCCESS = 'GET_FINANCIAL_AUDIT_SUCCESS';
export const GET_FINANCIAL_AUDIT_FAIL = 'GET_FINANCIAL_AUDIT_FAIL';

export const SET_FINANCIAL_AUDIT = 'SET_FINANCIAL_AUDIT';
export const UNSET_FINANCIAL_AUDIT = 'UNSET_FINANCIAL_AUDIT';
