import { rem } from 'polished';
import styled from 'styled-components';

import { misc } from 'helpers/styles/constants';

export interface Props extends React.FormHTMLAttributes<HTMLFormElement> {
  isFullWidth?: boolean;
}

export const GenericForm = styled.form<Props>`
  max-width: ${({ isFullWidth }) => (isFullWidth ? 'auto' : rem(misc.genericFormWidth))};
`;
