import { UserRoleName } from 'types/entities/user';

export default {
  title: `Messages`,
  description: `{userRole, select,
    ${UserRoleName.Client} {Here you can read and post messages relating to your cases and financial plan.}
    other {Here you can read and post messages relating to your clients’ cases and financial plan.}
  }`,
  tileDescription: `{userRole, select,
    ${UserRoleName.Client} {Read and post messages relating to your cases, meetings and financial plan.}
    other {View messages from, and send messages to, your clients.}
  }`,
  interactionReports: {
    generateDescription: `Generate a report that contains all messages sent between this Practice and {clientName} during a date range that you can specify below.`,
  },
  reportForm: {
    startDate: `Start date`,
    endDate: `End date`,
  },
  markedAsSeen: `Messages marked as seen`,
  unreadMessages: `Unread messages`,
  markAsSeen: `Mark as seen`,
  markAllAsSeen: `Mark all as seen`,
  emptyState: `There are no messages. Send a first one.`,
  messageFromClient: `Message from client!`,
  writeMessage: `Write a message`,
  sendEmail: `Send an auto-email to inform {clientName} of the message.`,
  sendEmailMultipleClients: `Send an auto-email to inform all relevant clients of the message`,
  notifications: {
    messageSent: `Message has been successfully sent`,
    messageDeleted: `Message has been successfully deleted`,
  },
  summary: {
    title: `Messages summary`,
    description: `{userRole, select,
      ${UserRoleName.PracticeAdmin} {Below is a summary of unread messages relating to the clients that you follow.}
      other {Below is a summary of unread messages.}
    }`,
  },
  messageMultipleClients: {
    title: `Message multiple clients`,
    header: `Message multiple clients`,
    description: `Send a message to multiple clients at a time.`,
    formData: {
      subjectLabel: `Subject`,
      subjectPlaceholder: `Write the message subject`,
      template: `Select Template`,
      messageLabel: `Enter Message`,
      messagePlaceholder: `Write your message here`,
      uploadFilesTitle: `Attach files`,
      uploadFilesPlaceHolder: `Click here to upload files`,
      clientTooltip: `Please select the adviser first. The client list will get updated by default`,
    },
    modal: {
      title: `Message multiple clients`,
      description: `Are you sure you want to send this message to all selected clients?`,
      cancel: `Cancel`,
      confirm: `Send`,
    },
    success: `Message has been successfully sent to all selected clients.`,
    validation: {
      body: `Please provide message body or attach any file.`,
    },
  },
  clientMessages: {
    title: `Client messages`,
    markAllAsSeen: `Mark all client messages as seen`,
    warning: `Caution: These messages are shared with the client.`,
  },
  practiceMessages: {
    title: `Practice messages`,
    markAllAsSeen: `Mark all practice messages as seen`,
    warning: `These messages are not shared with the client.`,
  },
  clientWarning: `Messages are sent to {value} and their support staff.`,
};
