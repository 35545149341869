import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

import { pulse } from 'helpers/styles/animations';
import { circle, flexCenter } from 'helpers/styles/mixins';
import { intl } from 'i18n';
import { RecordingType } from 'components/VideoChat/RecordingContext';

const Wrapper = styled.div`
  ${flexCenter()};
  font-size: ${({ theme }) => theme.fontSizes.dbSmallMd};
  color: ${({ theme }) => theme.colors.redDanger};

  &:after {
    ${circle(rem(6))};
    content: '';
    display: block;
    margin-left: ${rem(8)};
    background-color: ${({ theme }) => theme.colors.redDanger};
    animation: ${pulse} 2s infinite linear;
  }
`;

const RecordingState = styled.span`
  width: 150px;
`;

export const RecordingIndicator = ({ recordingType }: { recordingType?: RecordingType }) => {
  if (!recordingType) {
    return <Wrapper>Recording</Wrapper>;
  }
  return (
    <Wrapper>
      <RecordingState>
        {`${intl.formatMessage({ id: 'adviceRooms.adviceRoom.recording' })} ${
          recordingType === RecordingType.Audio ? '(Audio only)' : '(Audio & Video)'
        }`}
      </RecordingState>
    </Wrapper>
  );
};
