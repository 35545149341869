import React from 'react';
import { FormattedMessage } from 'react-intl';
import { rem, size } from 'polished';
import styled from 'styled-components';

import GenericLink, { Props as LinkProps } from 'components/GenericLink/GenericLink';
import IconArrowRightSec from 'components/Icons/IconArrowRightSec';

const BackIcon = styled(IconArrowRightSec)`
  ${size(rem(11), rem(10))};
  display: inline-block;
  margin-right: ${rem(7)};
  transform: rotate(180deg) translateY(-1px);
`;

const Button = styled(GenericLink)`
  display: block;
  padding: 0;
  font-size: ${({ theme }) => theme.fontSizes.dbSmall};
  line-height: 1;
  color: ${({ theme }) => theme.colors.textLight};
`;

export interface Props extends Pick<LinkProps, 'path' | 'onClick'> {
  text: string;
}

const BackButton = ({ text, onClick, path }: Props) => (
  <Button path={path} onClick={onClick}>
    <BackIcon />
    <FormattedMessage id={text} />
  </Button>
);

export default BackButton;
