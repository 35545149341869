import React from 'react';
import styled from 'styled-components';

const defaultColumnCount = 12;
const defaultGutterWidth = 40;

export const GridContext = React.createContext({
  columns: defaultColumnCount,
  gutterWidth: defaultGutterWidth,
});

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  columns?: number;
  gutterWidth?: number;
  children: React.ReactNode;
}

export function Grid({
  columns = defaultColumnCount,
  gutterWidth = defaultGutterWidth,
  ...props
}: Props) {
  return (
    <GridContext.Provider value={{ columns, gutterWidth }}>
      <Wrapper {...props} />
    </GridContext.Provider>
  );
}
