import { Root, route } from '../utils';

const practiceBrochuresPath = `${Root}practice-library-content`;

type PracticeBrochureParams = [brochureId: number];

export default {
  practiceBrochure: route<PracticeBrochureParams>(`${practiceBrochuresPath}/:itemId`),
  practiceBrochures: route(practiceBrochuresPath),
};
