import React from 'react';
import { Field } from 'react-final-form';
import { rem } from 'polished';
import styled from 'styled-components';

import PasswordRules from 'components/PasswordRules/PasswordRules';

const Wrapper = styled.div`
  margin-top: ${rem(40)};
`;

interface Props {
  fieldName: string;
}

const OnboardingPasswordRules = ({ fieldName }: Props) => (
  <Wrapper>
    <Field name={fieldName}>{({ input }) => <PasswordRules password={input.value} />}</Field>
  </Wrapper>
);

export default OnboardingPasswordRules;
