import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';

import { flexCenter } from 'helpers/styles/mixins';

const StatusBackground = styled.div<{ isSmall: boolean }>`
  ${({ isSmall }) => {
    const size = isSmall ? 16 : 24;

    return css`
      ${flexCenter()};
      position: relative;
      height: ${rem(size)};
      min-width: ${rem(size)};
      padding: ${rem(isSmall ? 2 : 3)};
      background-color: ${({ theme }) => rgba(theme.colors.greyLighten, 0.7)};
      border-radius: 2px;
      opacity: 0.7;
      transition: opacity 0.3s ease-in-out;

      & + & {
        margin-left: 2px;
      }

      &:focus,
      &:hover,
      &:active {
        opacity: 1;
      }
    `;
  }};
`;

export default StatusBackground;
