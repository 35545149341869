import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { isArray, mergeWith } from 'lodash';
import type { Entities } from 'redux-object';

import { StoreProvider } from 'store/StoreProvider';

const queryClient = new QueryClient();

export function wrapWithStore<T>(WrappedComponent: React.ComponentType<T>) {
  return (props: T) => {
    return (
      <StoreProvider>
        <QueryClientProvider client={queryClient}>
          <WrappedComponent {...props} />
        </QueryClientProvider>
      </StoreProvider>
    );
  };
}

export function mergeEntities(
  oldState: Entities,
  data: Record<string, Record<string, unknown>>,
  pathToOverwrite?: string
) {
  return mergeWith(
    {},
    oldState,
    data,
    (
      objValue: Record<string, unknown>,
      srcValue: Record<string, unknown>,
      key: string,
      object: Record<string, unknown>,
      source: Record<string, unknown>,
      stack: { size: number }
    ) => {
      // do not merge individual records, use new values instead
      if (stack.size === 2 && key === 'attributes') {
        return srcValue;
      }

      // overwrite entire subtree of single type
      if (pathToOverwrite && pathToOverwrite === key && stack.size === 0) {
        return data[pathToOverwrite] || {};
      }

      // we want to replace the relationship arrays
      if (isArray(objValue)) {
        return srcValue;
      }

      return undefined;
    }
  );
}
