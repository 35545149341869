import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

export interface AdviceRoomTasksPostOptions {
  content: string;
}

export function adviceRoomTasksApi(path: string) {
  return {
    path,
    GET: () => api.get<JsonApiResponse>(path),
    POST: (params: AdviceRoomTasksPostOptions) =>
      api.post<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
