import type { AccountType } from 'components/TopUps/types';

import type { Client } from './client';
import type { CurrencyValue } from './currency';

export enum TopUpState {
  Submitted = 'submitted',
  Completed = 'completed',
  Cancelled = 'cancelled',
}

export interface TopUp {
  id: number;
  accountNumber: string;
  amount: CurrencyValue;
  title: string;
  accountType: AccountType;
  clientComment?: string;
  state: TopUpState;
  createdAt: string;
  updatedAt: string;
  clientId: number;
  privateNote?: string;
  completedAt?: string;
  cancelledAt?: string;
  client?: Client;
  image?: string;
  lastSentPrivateNote?: string;
}
