import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';

export function clientMessagesSummaryApi(path: string) {
  return {
    path,
    GET: (config?: RequestConfig) => api.get<JsonApiResponse>(path, config),
  };
}
