import { useCallback } from 'react';
import type { AnyObject } from 'react-final-form';
import { get, toNumber } from 'lodash';

import { ProjectionType } from 'constants/projectionTypes';

const getNumberValue = (formValues: AnyObject, fieldName: string) =>
  toNumber(get(formValues, fieldName)) || 0;

interface Params {
  initialInvestmentFieldName: string;
  monthlyContributionFieldName: string;
  investmentDurationFieldName: string;
  currencyFieldName: string;
  projectionTypeFieldName: string;
}

const useInvestmentFormDataGetters = ({
  initialInvestmentFieldName,
  monthlyContributionFieldName,
  investmentDurationFieldName,
  currencyFieldName,
  projectionTypeFieldName,
}: Params) => {
  const getInitialInvestment = useCallback(
    (formValues: AnyObject) => getNumberValue(formValues, initialInvestmentFieldName),
    [initialInvestmentFieldName]
  );

  const getInvestmentDuration = useCallback(
    (formValues: AnyObject) => getNumberValue(formValues, investmentDurationFieldName),
    [investmentDurationFieldName]
  );

  const getMonthlyContribution = useCallback(
    (formValues: AnyObject) => getNumberValue(formValues, monthlyContributionFieldName),
    [monthlyContributionFieldName]
  );

  const getCurrency = useCallback(
    (formValues: AnyObject) => get(formValues, currencyFieldName),
    [currencyFieldName]
  );

  const getProjectionType = useCallback(
    (formValues: AnyObject) =>
      get(formValues, projectionTypeFieldName, ProjectionType.Investments) as ProjectionType,
    [projectionTypeFieldName]
  );

  return {
    getInitialInvestment,
    getInvestmentDuration,
    getMonthlyContribution,
    getCurrency,
    getProjectionType,
  };
};

export default useInvestmentFormDataGetters;
