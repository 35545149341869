import React from 'react';
import { FormattedMessage } from 'react-intl';
import { darken, rem } from 'polished';
import styled, { css } from 'styled-components';

import { breakpoints } from 'helpers/styles/constants';
import type { ReactPath } from 'routes/types';
import GenericButton from 'components/GenericButton/GenericButton';
import GenericLink, { OnClickProp } from 'components/GenericLink/GenericLink';
import GenericLoader from 'components/GenericLoader/GenericLoader';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LabelButton = styled(({ isSubmitBtnHidden, hasNoMarginTop = false, ...props }) => (
  <GenericLink {...props} />
))<{ isSubmitBtnHidden?: boolean }>`
  margin-top: ${({ hasNoMarginTop }) => (hasNoMarginTop ? 0 : rem(10))};
  margin-left: ${rem(17)};
  white-space: pre-wrap;
  color: ${({ theme }) => theme.colors.textLight};
  transition: 0.3s ease opacity, 0.3s ease color;

  @media ${breakpoints.smUp} {
    margin-top: 0;
  }
`;

const disabledOpacity = 0.4;

interface WrapperProps {
  hasNoMarginTop?: boolean;
  hasLabelDisabled?: boolean;
  hasLabelHovered?: boolean;
  hasUnderline?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: ${({ hasNoMarginTop }) => (hasNoMarginTop ? 0 : rem(55))};

  ${({ hasLabelDisabled }) =>
    hasLabelDisabled &&
    css`
      ${LabelButton} {
        opacity: ${disabledOpacity};
      }
    `};

  ${({ hasLabelHovered }) =>
    hasLabelHovered
      ? css`
          ${LabelButton} {
            color: ${({ theme }) => theme.colors.textDark};
            transition: 0.3s ease color;

            &:hover {
              text-decoration: underline;
              color: ${({ theme }) => darken(0.2, theme.colors.main)};
            }
          }
        `
      : css`
          button:disabled + ${LabelButton} {
            opacity: ${disabledOpacity};
          }
        `};

  ${({ hasUnderline }) =>
    hasUnderline &&
    css`
      button:not(:disabled):hover + ${LabelButton} {
        text-decoration: underline;
      }
    `};
`;

export interface FormActionButtonsProps {
  hasNoMarginTop?: boolean;
  hasUnderline?: boolean;
  isHalfWidth?: boolean;
  isSubmitBtnHidden?: boolean;
  isSubmitBtnBlock?: boolean;
  isLabelHidden?: boolean;
  isLabelDisabled?: boolean;
  labelBtnContent?: React.ReactNode;
  submitDisabled?: boolean;
  isLoading?: boolean;
  labelBtnPath?: ReactPath;
  submitBtnContent?: React.ReactNode;
  labelBtnDataTest?: string;
  submitBtnDataTest?: string;
  onSubmitBtnClick?: OnClickProp;
  onLabelBtnClick?: OnClickProp;
  className?: string;
}

export default function FormActionButtons({
  hasNoMarginTop = false,
  hasUnderline = false,
  isHalfWidth = false,
  isSubmitBtnHidden = false,
  isSubmitBtnBlock = false,
  isLabelHidden = false,
  isLabelDisabled = false,
  isLoading = false,
  labelBtnContent,
  labelBtnPath,
  submitBtnContent,
  submitDisabled,
  labelBtnDataTest,
  submitBtnDataTest,
  onSubmitBtnClick,
  onLabelBtnClick,
  className,
}: FormActionButtonsProps) {
  const btnContent = () => {
    if (isLoading) return <GenericLoader color="white" size={30} isVisible isInlineFlex />;
    if (submitBtnContent) return submitBtnContent;

    return <FormattedMessage id="onboarding.shared.continueButton" />;
  };

  return (
    <Wrapper
      className={className}
      hasUnderline={hasUnderline}
      hasLabelDisabled={isLabelDisabled}
      hasLabelHovered={Boolean(labelBtnPath || onLabelBtnClick)}
      hasNoMarginTop={hasNoMarginTop}
    >
      {!isSubmitBtnHidden && (
        <GenericButton
          data-test={submitBtnDataTest}
          type={onSubmitBtnClick ? 'button' : 'submit'}
          disabled={submitDisabled || isLoading}
          onClick={onSubmitBtnClick}
          isHalfWidth={isHalfWidth}
          isBlock={isSubmitBtnBlock}
          isHigh={!isLoading}
          isMedium={isLoading}
        >
          {btnContent()}
        </GenericButton>
      )}

      {!isLabelHidden && (
        <LabelButton
          data-test={labelBtnDataTest}
          path={labelBtnPath}
          disabled={isLabelDisabled}
          onClick={onLabelBtnClick}
          isSubmitBtnHidden={isSubmitBtnHidden}
          hasNoMarginTop
        >
          {labelBtnContent || <FormattedMessage id="onboarding.shared.continueButtonLabel" />}
        </LabelButton>
      )}
    </Wrapper>
  );
}
