import React, { useEffect, useLayoutEffect, useRef } from 'react';
import type { LocalAudioTrack, RemoteAudioTrack } from 'twilio-video';

import { useSelectedAudioOutputDevice } from 'components/VideoChat/useDevices';

interface Props {
  track: LocalAudioTrack | RemoteAudioTrack | null | undefined;
}

export default function AudioTrack({ track }: Props) {
  const audioMediaElement = useRef<HTMLAudioElement>(null);
  const { selectedAudioOutputDeviceId } = useSelectedAudioOutputDevice();

  useLayoutEffect(() => {
    if (track && 'attach' in track && audioMediaElement.current) {
      track.attach(audioMediaElement.current);
    }
  }, [track]);

  useEffect(() => {
    if (
      audioMediaElement.current &&
      audioMediaElement.current.setSinkId &&
      selectedAudioOutputDeviceId
    ) {
      audioMediaElement.current.setSinkId(selectedAudioOutputDeviceId);
    }
  }, [selectedAudioOutputDeviceId]);

  if (!track) {
    return null;
  }

  // eslint-disable-next-line jsx-a11y/media-has-caption
  return <audio autoPlay ref={audioMediaElement} data-name={track.name} />;
}
