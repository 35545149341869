import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { position, rem, size } from 'polished';
import styled from 'styled-components';

import { KeyCode } from 'constants/keys';
import { flexCenter } from 'helpers/styles/mixins';
import { isNotBlank } from 'helpers/utils';
import { required } from 'helpers/validators';
import { Field } from 'components/Forms';
import GenericInput from 'components/GenericInput/GenericInput';
import { GenericTitle, TitleMargin } from 'components/GenericTitle/GenericTitle';
import GenericTitleSummary from 'components/GenericTitleSummary/GenericTitleSummary';
import IconCheckmark from 'components/Icons/IconCheckmarkSec';
import IconEdit from 'components/Icons/IconEdit';
import IconQuote from 'components/Icons/IconQuote';

import { Fields, StepProps } from '../../types';
import StepBeginBase from './StepBeginBase';

const iconEditSize = size(rem(15));

const IconQuoteStyled = styled(IconQuote)`
  ${size(rem(47), rem(60))};
`;

const IconEditStyled = styled(IconEdit)`
  ${iconEditSize};
`;

const StyledGenericInput = styled(GenericInput)`
  padding-bottom: 0;
  font-family: ${({ theme }) => theme.fontFamilies.sec};
`;

const ClientNameButtons = styled.div`
  display: flex;
  margin-top: ${rem(5)};
`;

const OnboardingMessage = styled.div`
  display: flex;
  white-space: pre-line;
  font-style: italic;
  font-size: ${({ theme }) => theme.fontSizes.smallLg};

  svg {
    margin-right: ${rem(15)};
  }
`;

const UnderlineText = styled.span`
  position: relative;

  &::before {
    ${position('absolute', 0, 0, 0, 0)};
    content: '';
    background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.bermudaGray} 75%,
      transparent 75%
    );
    background-position: 0 0.93em;
    background-repeat: repeat-x;
    background-size: ${rem(4)} ${rem(1)};
    opacity: 0.5;
  }
`;

const TitleEditButton = styled.button.attrs({
  type: 'button',
})`
  display: inline-flex;
  ${iconEditSize};
  padding: 0;
`;

const TitleActionButton = styled.button.attrs({
  type: 'button',
})`
  ${flexCenter};
  font-family: ${({ theme }) => theme.fontFamilies.main};
  font-size: ${({ theme }) => theme.fontSizes.dbNormal};
  font-weight: ${({ theme }) => theme.fontWeights.mainMedium};
`;

const StyledIconCheckmark = styled(IconCheckmark)`
  ${size(rem(8))};
  margin-right: ${rem(4)};
`;

const CancelActionButton = styled(TitleActionButton)`
  margin-left: ${rem(20)};
  font-weight: ${({ theme }) => theme.fontWeights.mainNormal};
  color: ${({ theme }) => theme.colors.textLight};
`;

const StepBeginKnownClient: FC<StepProps> = props => {
  const { values, form } = props;
  const [isEditMode, setIsEditMode] = useState(false);

  const firstName = values[Fields.FirstName];
  const lastName = values[Fields.LastName];
  const invitationMessage = values[Fields.InvitationMessage];

  const handleShowEditMode = () => {
    setIsEditMode(true);
  };

  const handleCancelEditMode = () => {
    const { initialValues } = form.getState();

    form.change(Fields.FirstName, initialValues[Fields.FirstName]);
    form.change(Fields.LastName, initialValues[Fields.LastName]);

    setIsEditMode(false);
  };

  const handleSaveEditMode = () => {
    const { initialValues, invalid } = form.getState();

    if (invalid) {
      return;
    }

    form.reset({ ...initialValues, firstName, lastName });
    setIsEditMode(false);
  };

  const handleEditInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === KeyCode.Enter) {
      handleSaveEditMode();
    }
  };

  return (
    <StepBeginBase {...props}>
      <GenericTitle marginBottom={TitleMargin.Sm}>
        {isEditMode ? (
          <>
            <FormattedMessage tagName="span" id="onboarding.stepBegin.welcomeKnownClient" />

            <Field
              name={Fields.FirstName}
              validate={required}
              renderError={null}
              renderControl={({ input, meta }) => (
                <StyledGenericInput
                  {...input}
                  placeholder="onboarding.stepFirstName.placeholder"
                  onKeyPress={handleEditInputKeyPress}
                  hasError={meta.showError}
                  autoFocus
                  hasNoBackground
                  hasFullWidth
                />
              )}
            />

            <Field
              name={Fields.LastName}
              validate={required}
              renderError={null}
              renderControl={({ input, meta }) => (
                <StyledGenericInput
                  {...input}
                  placeholder="onboarding.stepLastName.placeholder"
                  onKeyPress={handleEditInputKeyPress}
                  hasError={meta.showError}
                  hasNoBackground
                  hasFullWidth
                />
              )}
            />

            <ClientNameButtons>
              <TitleActionButton onClick={handleSaveEditMode}>
                <StyledIconCheckmark />
                <FormattedMessage tagName="span" id="shared.save" />
              </TitleActionButton>

              <CancelActionButton onClick={handleCancelEditMode}>
                <FormattedMessage tagName="span" id="shared.cancel" />
              </CancelActionButton>
            </ClientNameButtons>
          </>
        ) : (
          <>
            <FormattedMessage
              tagName="span"
              id="onboarding.stepBegin.welcomeKnownClientName"
              values={{
                firstName: <UnderlineText>{firstName}</UnderlineText>,
                lastName: <UnderlineText>{lastName}</UnderlineText>,
              }}
            />

            <TitleEditButton onClick={handleShowEditMode}>
              <IconEditStyled />
            </TitleEditButton>
          </>
        )}
      </GenericTitle>

      {isNotBlank(invitationMessage) && (
        <GenericTitleSummary>
          <OnboardingMessage>
            <IconQuoteStyled />
            <p>{invitationMessage}</p>
          </OnboardingMessage>
        </GenericTitleSummary>
      )}
    </StepBeginBase>
  );
};

export default StepBeginKnownClient;
