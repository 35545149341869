import { Root, route } from '../utils';

const vaultPath = `${Root}vault`;
const vaultItemPath = `${vaultPath}/:itemId`;

type VaultItemParams = [itemId: number];

export default {
  vault: {
    ...route(vaultPath),
    auditTrail: route(`${vaultPath}/audit-trail`),
    new: route(`${vaultPath}/new`),
    newFolder: route(`${vaultPath}/new-folder`),
    item: {
      ...route<VaultItemParams>(vaultItemPath),
      edit: route<VaultItemParams>(`${vaultItemPath}/edit`),
    },
  },
};
