import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, RequestConfig } from 'api/types';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

import { clientFinancialStrategyAuditTrailApi } from './auditTrail';
import { clientFinancialStrategiesExistingImagesApi } from './existingImages';
import { clientFinancialStrategyMarkAsSeenApi } from './markAsSeen';
import { clientFinancialStrategyRemoveDocumentApi } from './removeDocument';
import { clientFinancialStrategiesShareApi } from './share';
import { clientFinancialStrategiesViewApi } from './view';

export type FinancialStrategyGetOptions = GetOptions;

export interface FinancialStrategyPatchOptions {
  title?: string;
  file?: File;
  image?: File;
  existingImageId?: number;
  coverNote?: string;
}

export function clientFinancialStrategyApi(path: string) {
  return {
    path,
    auditTrail: () => clientFinancialStrategyAuditTrailApi(`${path}/audit-trail`),
    existingImages: () => clientFinancialStrategiesExistingImagesApi(`${path}/existing-images`),
    share: () => clientFinancialStrategiesShareApi(`${path}/share`),
    view: () => clientFinancialStrategiesViewApi(`${path}/view`),
    removeDocument: () => clientFinancialStrategyRemoveDocumentApi(`${path}/remove-document`),
    markAsSeen: () => clientFinancialStrategyMarkAsSeenApi(`${path}/mark-as-seen`),
    GET: (params?: FinancialStrategyGetOptions, config?: RequestConfig) =>
      api.get<JsonApiResponseSingle>(path, { params, ...config }),
    PATCH: (data: FinancialStrategyPatchOptions) =>
      api.patch<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(data),
          },
        })
      ),
    DELETE: () => api.delete(path),
  };
}
