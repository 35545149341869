import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';

const apiPath = '/api/v1/feature-helps';

function featureHelpsApi(path: string) {
  return {
    path,
    GET: () => api.get<JsonApiResponse>(path),
  };
}

function featureHelps() {
  return featureHelpsApi(apiPath);
}

export { featureHelps };
