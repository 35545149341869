import type { User } from './user';

export enum DocumentVaultDocumentType {
  Will = 'will',
  TrustDocument = 'trust_document',
  InsurancePolicy = 'insurance_policy',
  BankStatements = 'bank_statements',
  Other = 'other_document',
  Folder = 'folder',
}

export const documentVaultDocumentTypes = [
  DocumentVaultDocumentType.Will,
  DocumentVaultDocumentType.TrustDocument,
  DocumentVaultDocumentType.InsurancePolicy,
  DocumentVaultDocumentType.BankStatements,
  DocumentVaultDocumentType.Other,
];

export interface DocumentVaultItem {
  id: number;
  createdAt: string;
  updatedAt: string;
  documentType: DocumentVaultDocumentType;
  file?: string;
  image?: string;
  clientId: number;
  title?: string;
  sharedWithAdviser?: boolean;
  parentFolderId?: number;
  creatorId: number;
  creator?: User;
  seen?: boolean;
}
