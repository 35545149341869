import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';
import type { TodoListTaskState } from 'types/entities/todoList';

export interface ClientTodoListTasksTaskCompleteApiOptions {
  state: TodoListTaskState;
}

export function clientTodoListTasksTaskUpdateStateApi(path: string) {
  return {
    path,
    PATCH: (params: ClientTodoListTasksTaskCompleteApiOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
