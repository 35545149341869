import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { PaginationOptions, RequestConfig } from 'api/types';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';
import type { BlogPostState } from 'types/entities/blogPost';

import { blogPostsAuthorsApi } from './authors';
import { blogPostApi } from './blogPost';

export type BlogPostsGetOptions = PaginationOptions;
export interface BlogPostsPostOptions {
  title: string;
  creatorId: number;
  content?: string;
  state?: BlogPostState;
  image?: File;
  file?: File;
  existingImageId?: number;
}

const apiPath = '/api/v1/blog-posts';

function blogPostsApi(path: string) {
  return {
    path,
    authors: () => blogPostsAuthorsApi(`${path}/authors`),
    GET: (params: BlogPostsGetOptions = {}, config?: RequestConfig) => {
      const { pageSize, pageNumber, ...rest } = params;

      return api.get<JsonApiResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...rest,
        },
        ...config,
      });
    },
    POST: (params: BlogPostsPostOptions) =>
      api.post<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      ),
  };
}

type BlogPostApi = ReturnType<typeof blogPostApi>;
type BlogPostsApi = ReturnType<typeof blogPostsApi>;

function blogPosts(): BlogPostsApi;
function blogPosts(postId: number): BlogPostApi;
function blogPosts(postId?: number): BlogPostsApi | BlogPostApi {
  return postId ? blogPostApi(`${apiPath}/${postId}`) : blogPostsApi(apiPath);
}

export { blogPosts };
