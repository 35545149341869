import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

export const MaxWidthWrapper = styled(({ width, ...props }) => <div {...props} />)<{
  width?: string;
}>`
  width: ${({ width }) => width || rem(490)};
  max-width: 100%;
`;
