import styled from 'styled-components';

const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  min-height: 0;
`;

export default ModalForm;
