/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FormatDateOptions } from 'react-intl';
import { isBefore, isFuture, isPast, isToday } from 'date-fns';
import { EditorState } from 'draft-js';
import {
  endsWith,
  isEmpty,
  isNumber as lodashIsNumber,
  isString,
  isUndefined,
  toNumber,
} from 'lodash';

import { TextType } from 'constants/textType';
import { formatPrice } from 'helpers/numbers';
import { isInstanceOfFile, isNotBlank } from 'helpers/utils';
import { intl } from 'i18n';
import { toHTML } from 'components/GenericTextarea/utils';
import { rules } from 'components/PasswordRules/PasswordRules';
import { maxFileSize10MB } from 'components/TechnicalSupport/constants';

import { ensureDate, formatDate } from './dates';
import { stripHtmlTags } from './strings';
import { showErrorToast } from './toasts';

export const isNumber = (value: any) => {
  const number = toNumber(value);

  if (isUndefined(value) || (!Number.isNaN(number) && lodashIsNumber(number))) {
    return true;
  }

  return false;
};

export const isPositiveNumber = (value: any) => isNumber(value) && value > 0;

export const isInteger = (value: any) => isNumber(value) && Number.isInteger(toNumber(value));

export const getEmptyError = () => intl.formatMessage({ id: 'errors.emptyMsg' });
export const getRequiredError = () => intl.formatMessage({ id: 'errors.required' });
export const required = (value: any) => {
  if (value || value === 0) {
    return isString(value) && !value.trim() ? getRequiredError() : undefined;
  }

  return getRequiredError();
};

export const requiredHtml = (value: EditorState | string | null | undefined) => {
  const valueToCheck = value instanceof EditorState ? toHTML(value) : value;

  if (valueToCheck) {
    return !stripHtmlTags(String(valueToCheck)).trim() ? getRequiredError() : undefined;
  }

  return getRequiredError();
};

export const requiredArray = <T>(value: T[]) => {
  return isEmpty(value) ? getRequiredError() : undefined;
};

export const maxFileSizeSumCheck = (values: any) => {
  if (!values) return;
  const sizeSum = values.map((v: { size: any }) => v.size).reduce((a: any, b: any) => a + b, 0);

  if (sizeSum > maxFileSize10MB) {
    values.pop();
    showErrorToast(intl.formatMessage({ id: 'errors.greaterThan10MB' }), {
      isTranslated: true,
    });
  }

  return undefined;
};

export const passwordConfirmation = (password: string, confirmation: string) =>
  password === confirmation ? undefined : intl.formatMessage({ id: 'errors.passwordConfirmation' });

export const maxLength =
  (max: number, textType = TextType.Plain) =>
  (value: any) => {
    const error = intl.formatMessage({ id: 'errors.maxLength' }, { max });

    const valueToCheck = value instanceof EditorState ? toHTML(value) : value;

    return valueToCheck &&
      (textType === TextType.Html ? stripHtmlTags(String(valueToCheck)) : String(valueToCheck))
        .length > max
      ? error
      : undefined;
  };

export const requiredTextPattern = '.*\\S+.*';

export const mustBeNumber = (value: any) =>
  !isNumber(value) ? intl.formatMessage({ id: 'errors.number' }) : undefined;
export const requiredNumber = (value: any) => required(value) || mustBeNumber(value);

export const mustBePositiveNumber = (value: any) =>
  !isPositiveNumber(value) ? intl.formatMessage({ id: 'errors.positiveNumber' }) : undefined;

export const mustBeInteger = (value: any) =>
  !isInteger(value) ? intl.formatMessage({ id: 'errors.integer' }) : undefined;

export const minValue =
  (min: number, customText?: string, translationValues?: Record<string, string>) =>
  (value: any) => {
    const conditional = isNumber(value) && isNumber(min) && toNumber(value) < toNumber(min);

    const text = isNotBlank(customText)
      ? intl.formatMessage({ id: customText }, translationValues)
      : intl.formatMessage({ id: 'errors.greaterEqualThan' }, { min: formatPrice(min) });

    return conditional ? text : undefined;
  };

export const minValueOrZero = (min: number, customText?: string) => (value: any) => {
  const conditional =
    toNumber(value) !== 0 && isNumber(value) && isNumber(min) && toNumber(value) < toNumber(min);
  const formattedMin = formatPrice(min);

  const text = intl.formatMessage(
    { id: isNotBlank(customText) ? customText : 'errors.greaterEqualThan' },
    { min: formattedMin }
  );

  return conditional ? text : undefined;
};

export const maxValue = (max: number, customText?: string) => (value: any) => {
  const conditional = isNumber(value) && isNumber(max) && toNumber(value) > toNumber(max);
  const text = intl.formatMessage(
    { id: isNotBlank(customText) ? customText : 'errors.lessEqualThan' },
    { max }
  );

  return conditional ? text : undefined;
};

export const lessOrEqualDigits =
  (max: number) =>
  (value: number | null | undefined): string | undefined => {
    const error = intl.formatMessage({ id: 'errors.maxDigits' }, { max });

    return (value?.toString().length || 0) > max ? error : undefined;
  };

export const betweenValues = (min: number, max: number, customText?: string) => (value: any) => {
  const conditional =
    isNumber(value) &&
    isNumber(max) &&
    (toNumber(value) > toNumber(max) || toNumber(value) < toNumber(min));

  const text = intl.formatMessage(
    { id: isNotBlank(customText) ? customText : 'errors.between' },
    { min, max }
  );

  return conditional ? text : undefined;
};

export const optionalMin = (min: number, customText?: string) => (value: any) => {
  const formattedMin = formatPrice(min);

  if (isNumber(value) && Number(value) !== 0 && value < min) {
    return intl.formatMessage(
      {
        id: isNotBlank(customText) ? customText : 'errors.greaterThanIfReq',
      },
      { min: formattedMin }
    );
  }

  return null;
};

const checkIsUrl = (url: string): boolean => {
  // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
  const urlPattern =
    // eslint-disable-next-line max-len
    /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i;

  return urlPattern.test(url);
};

export const isUrl = (url: string) => {
  return url != null && !checkIsUrl(url)
    ? intl.formatMessage({ id: 'errors.mustBeUrl' })
    : undefined;
};

export const isPdfUrl = (url: string) => {
  const isValid = checkIsUrl(url) && endsWith(url, '.pdf');

  return !isValid ? intl.formatMessage({ id: 'errors.mustBePdfUrl' }) : undefined;
};

export const isPastDate = (value: string | number | Date | undefined): string | undefined => {
  if (value == null) {
    return undefined;
  }

  return !isPast(ensureDate(value))
    ? intl.formatMessage({ id: 'errors.mustBePastDate' })
    : undefined;
};

export const isTodayOrPastDate = (
  value: string | number | Date | undefined
): string | undefined => {
  if (value == null) {
    return undefined;
  }

  return !isToday(ensureDate(value)) && !isPast(ensureDate(value))
    ? intl.formatMessage({ id: 'errors.mustBeTodayOrPastDate' })
    : undefined;
};

export const isTodayOrFuture = (value: string | number | Date | undefined): string | undefined => {
  if (value == null) {
    return undefined;
  }

  return !isToday(ensureDate(value)) && !isFuture(ensureDate(value))
    ? intl.formatMessage({ id: 'errors.mustBeTodayOrFutureDate' })
    : undefined;
};

export const isFutureDate = (value: string | number | Date | undefined): string | undefined => {
  if (value == null) {
    return undefined;
  }

  return !isFuture(ensureDate(value))
    ? intl.formatMessage({ id: 'errors.mustBeFutureDate' })
    : undefined;
};

export const isValidEmail = (email: string) => {
  const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return regex.test(email);
};

export const isAfterOrEqualDate =
  (
    minDate: string | number | Date,
    dateErrorFormat: FormatDateOptions = { dateStyle: 'medium', timeStyle: 'short' }
  ) =>
  (value: string | number | Date | undefined): string | undefined => {
    if (value == null) {
      return undefined;
    }

    return isBefore(ensureDate(value), ensureDate(minDate))
      ? intl.formatMessage(
          {
            id: 'errors.mustBeAfterDate',
          },
          { minDate: formatDate(intl, minDate, dateErrorFormat) }
        )
      : undefined;
  };

export const isAfterOrEqualTime =
  (
    minDate: string | number | Date,
    dateErrorFormat: FormatDateOptions = { dateStyle: 'medium', timeStyle: 'short' }
  ) =>
  (value: string | number | Date | undefined): string | undefined => {
    if (value == null) {
      return undefined;
    }

    return isBefore(ensureDate(value), ensureDate(minDate))
      ? intl.formatMessage(
          {
            id: 'errors.mustBeAfterTime',
          },
          { minDate: formatDate(intl, minDate, dateErrorFormat) }
        )
      : undefined;
  };

export type Validator = (...args: any[]) => string | undefined;

export const composeValidators =
  (...validators: Validator[]): Validator =>
  (...args) => {
    for (let index = 0; index < validators.length; index += 1) {
      const validator = validators[index];
      const result = validator(...args);

      if (result) {
        return result;
      }
    }

    return undefined;
  };

export const isImageTooHigh = (height: number, maxHeight: number) =>
  height > maxHeight
    ? intl.formatMessage({ id: 'errors.imageHeightMustBeSmaller' }, { value: maxHeight })
    : undefined;

export const isImageTooWide = (width: number, maxWidth: number) =>
  width > maxWidth
    ? intl.formatMessage({ id: 'errors.imageWidthMustBeSmaller' }, { value: maxWidth })
    : undefined;

export const isFileTooBig = (maxSizeInKB: number) => (value: unknown) =>
  isInstanceOfFile(value) && value.size / 1024 > maxSizeInKB
    ? intl.formatMessage(
        { id: 'errors.fileMustBeSmaller' },
        {
          value: intl.formatNumber(maxSizeInKB, {
            style: 'unit',
            unit: 'kilobyte',
            unitDisplay: 'narrow',
          }),
        }
      )
    : undefined;

export const hasPasswordMatchRules = (password: string | undefined) => {
  if (!password) {
    return false;
  }

  let isValid = true;

  rules.forEach(rule => {
    if (!rule.check(password)) {
      isValid = false;
    }
  });

  return isValid;
};
