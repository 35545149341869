import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { flexCenter } from 'helpers/styles/mixins';
import { useIdleTimerContext } from 'components/VideoChat/IdleTimerContext';

const InactivityMessage = styled.div`
  ${flexCenter()};
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: ${({ theme }) => theme.colors.white};
`;

export function InactivityChecker() {
  const timerRef = useIdleTimerContext();

  const [secondsToDisconnect, setSecondsToDisconnect] = useState<number>();

  useEffect(() => {
    const sessionIntervalTimer = setInterval(() => {
      const remainingMs = timerRef.current?.getRemainingTime();
      setSecondsToDisconnect(remainingMs ? Math.floor(remainingMs / 1000) : undefined);
    }, 1000);

    return () => {
      clearInterval(sessionIntervalTimer);
    };
  }, []);

  return secondsToDisconnect && secondsToDisconnect < 60 ? (
    <InactivityMessage>
      <FormattedMessage
        id="adviceRooms.adviceRoom.autoDisconnectMessage"
        values={{ value: secondsToDisconnect }}
      />
    </InactivityMessage>
  ) : null;
}
