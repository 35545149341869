import type { SectionCommon } from './utils';

export enum HealthType {
  Smoker = 'smoker',
  GoodHealth = 'good_health',
  MedicalConditionSuffer = 'medical_condition_suffer',
  HazardousOccupationHobby = 'hazardous_occupation_hobby',
}

interface HealthItem {
  yes?: boolean;
  comment?: string;
}

interface HealthGroup {
  [HealthType.Smoker]: HealthItem;
  [HealthType.GoodHealth]: HealthItem;
  [HealthType.HazardousOccupationHobby]: HealthItem;
  [HealthType.MedicalConditionSuffer]: HealthItem;
}

export interface Health extends SectionCommon {
  you: HealthGroup;
  partner: HealthGroup;
}
