import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';
import type { CaseType } from 'types/entities/clientCase';

import { clientCaseApi } from './clientCase';

export interface ClientCasesPostOptions {
  clientCaseType: CaseType;
  clientId: number;
  name?: string;
  coverNote?: string;
  image?: File;
  existingImageId?: number;
  topics?: string[];
  metadata?: Record<string, unknown>;
  initialInvestment?: number;
  initialInvestmentCurrency?: string;
  investmentDuration?: number;
  monthlyContribution?: number;
  monthlyContributionCurrency?: string;
}

const apiPath = '/api/v1/client-cases';

function clientCasesApi(path: string) {
  return {
    path,
    POST: (attributes: ClientCasesPostOptions) =>
      api.post<JsonApiResponseSingle>(
        path,
        // parsing to form data will break metadata. At this moment, we don't need to form data
        // here as there is no files send when case is created.
        // convertJsonToFormData({
        {
          data: {
            attributes: mapFormData(attributes),
          },
        }
      ),
  };
}

type ClientCaseApi = ReturnType<typeof clientCaseApi>;
type ClientCasesApi = ReturnType<typeof clientCasesApi>;

function clientCases(): ClientCasesApi;
function clientCases(caseId: number): ClientCaseApi;
function clientCases(caseId?: number): ClientCasesApi | ClientCaseApi {
  return caseId ? clientCaseApi(`${apiPath}/${caseId}`) : clientCasesApi(apiPath);
}

export { clientCases };
