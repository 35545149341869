import { rem } from 'polished';
import styled from 'styled-components';

const StatusBar = styled.div`
  z-index: 1;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: ${rem(3)};
`;

export const TopStatusBar = styled(StatusBar)`
  top: 0;
`;

export const BottomStatusBar = styled(StatusBar)`
  bottom: 0;
`;
