import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { required } from 'helpers/validators';
import FormActionResponsiveButtons from 'components/FormActionButtons/FormActionResponsiveButtons';
import { Field } from 'components/Forms';
import GenericHint from 'components/GenericHint/GenericHint';
import GenericTextarea from 'components/GenericTextarea/GenericTextarea';
import { GenericTitle, TitleMargin } from 'components/GenericTitle/GenericTitle';

import { Fields, StepProps } from '../../types';
import FieldTopics from '../shared/FieldTopics';

const StepOtherFinancialNeeds: FC<StepProps> = () => (
  <>
    <GenericTitle isMedium marginBottom={TitleMargin.Sm}>
      <FormattedMessage id="onboarding.stepOtherFinancialNeeds.titleAdvice" tagName="span" />
    </GenericTitle>

    <Field<string>
      name={Fields.ClientAdvice}
      validate={required}
      renderControl={({ input: { type, ...restInputProps }, meta }) => (
        <GenericTextarea
          {...restInputProps}
          hasError={meta.showError}
          hasVerticalResize
          hasBoldBorderTop
        />
      )}
    />

    <GenericTitle as="h2" marginBottom={TitleMargin.Md} marginTop={TitleMargin.Md} isMedium isBlock>
      <FormattedMessage id="onboarding.stepOtherFinancialNeeds.title" tagName="span" />
      <GenericHint text="shared.optional" />
    </GenericTitle>

    <FieldTopics name={Fields.Topics} />

    <FormActionResponsiveButtons hasUnderline />
  </>
);

export default StepOtherFinancialNeeds;
