import React, { ReactNode } from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

import { breakpoints } from 'helpers/styles/constants';
import ScrollShadowPanel from 'components/ScrollShadowPanel/ScrollShadowPanel';

import { modalPadding, modalPaddingSm } from './shared';

const innerClassName = 'scroll-shadow-panel-inner';

const StyledScrollShadowPanel = styled(ScrollShadowPanel).attrs({ innerClassName })`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  min-height: 0;

  .${innerClassName} {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    padding: ${rem(30)} ${rem(modalPaddingSm)} ${rem(modalPaddingSm)};

    & > * {
      flex-shrink: 0;
    }

    @media ${breakpoints.smUp} {
      padding: ${rem(modalPadding / 2)} ${rem(modalPadding)};
    }
  }
`;

interface Props {
  children?: ReactNode;
}

const ModalContent = ({ children }: Props) => (
  <StyledScrollShadowPanel>{children}</StyledScrollShadowPanel>
);

export default ModalContent;
