import { get, merge } from 'lodash';
import type { AnyAction } from 'redux';

import { STEP_SUMMARY } from 'constants/riskAssessment';

import {
  GET_RISK_ASSESSMENT,
  GET_RISK_ASSESSMENT_FAIL,
  GET_RISK_ASSESSMENT_SUCCESS,
  POST_RISK_ASSESSMENT,
  POST_RISK_ASSESSMENT_FAIL,
  POST_RISK_ASSESSMENT_SUCCESS,
  RISK_ASSESSMENT_GO_TO_PREVIOUS_QUESTION,
  RISK_ASSESSMENT_GO_TO_STEP,
  SET_RISK_ASSESSMENT,
  SET_RISK_ASSESSMENTS,
  UPDATE_QUESTION,
} from './actionTypes';
import type { RiskAssessmentsActionTypes, RiskAssessmentState } from './types';

const getDuration = (state: RiskAssessmentState, riskAssessmentId: string, refId = ''): number =>
  get(state, `payload.${riskAssessmentId}.${refId}.duration`, 0);

export const initialState: RiskAssessmentState = {
  currentQuestionNumber: 1,
  payload: {},
  id: '',
  allIds: [],
  isLoading: false,
};

export default (state = initialState, incomingAction: AnyAction) => {
  const action = incomingAction as RiskAssessmentsActionTypes;

  switch (action.type) {
    case SET_RISK_ASSESSMENT:
      return {
        ...state,
        id: action.id,
      };
    case SET_RISK_ASSESSMENTS:
      return {
        ...state,
        allIds: action.ids,
      };
    case UPDATE_QUESTION: {
      return merge({}, state, {
        [state.id]: {
          currentQuestionNumber: action.nextQuestionNumber,
          payload: {
            [action.refId]: {
              value: action.value,
              duration: getDuration(state, state.id, action.refId) + action.duration,
            },
          },
        },
      });
    }
    case RISK_ASSESSMENT_GO_TO_PREVIOUS_QUESTION: {
      return merge({}, state, {
        [state.id]: {
          currentQuestionNumber: state[state.id].currentQuestionNumber - 1,
          payload: {
            [action.refId]: {
              duration: getDuration(state, action.refId) + action.duration,
            },
          },
        },
      });
    }
    case RISK_ASSESSMENT_GO_TO_STEP: {
      return merge({}, state, {
        [state.id]: {
          currentStep: action.step,
        },
      });
    }
    case POST_RISK_ASSESSMENT:
    case POST_RISK_ASSESSMENT_FAIL:
      return {
        ...state,
      };
    case POST_RISK_ASSESSMENT_SUCCESS:
      return merge({}, state, {
        [state.id]: {
          currentStep: STEP_SUMMARY,
        },
      });
    case GET_RISK_ASSESSMENT:
      return {
        ...state,
        isLoading: true,
      };
    case GET_RISK_ASSESSMENT_SUCCESS:
    case GET_RISK_ASSESSMENT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
