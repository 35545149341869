export const SAVE_FINANCIAL_PLANNING_HEALTH_CHECK = 'SAVE_FINANCIAL_PLANNING_HEALTH_CHECK';
export const SAVE_FINANCIAL_PLANNING_HEALTH_CHECK_SUCCESS =
  'SAVE_FINANCIAL_PLANNING_HEALTH_CHECK_SUCCESS';
export const SAVE_FINANCIAL_PLANNING_HEALTH_CHECK_FAIL =
  'SAVE_FINANCIAL_PLANNING_HEALTH_CHECK_FAIL';

export const GET_FINANCIAL_PLANNING_HEALTH_CHECK = 'GET_FINANCIAL_PLANNING_HEALTH_CHECK';
export const GET_FINANCIAL_PLANNING_HEALTH_CHECK_SUCCESS =
  'GET_FINANCIAL_PLANNING_HEALTH_CHECK_SUCCESS';
export const GET_FINANCIAL_PLANNING_HEALTH_CHECK_FAIL = 'GET_FINANCIAL_PLANNING_HEALTH_CHECK_FAIL';

export const SET_FINANCIAL_PLANNING_HEALTH_CHECK = 'SET_FINANCIAL_PLANNING_HEALTH_CHECK';
export const UNSET_FINANCIAL_PLANNING_HEALTH_CHECK = 'UNSET_FINANCIAL_PLANNING_HEALTH_CHECK';
