export interface Values {
  caseName?: string;
  coverNote?: string;
  includeFinancialPlanningGoals?: boolean;
  includePersonalFinancialAudit?: boolean;
  includePersonalFinancialPlanningHealthCheck?: boolean;
  includeFactFindRevision?: boolean;
}

export enum CoverNoteTemplate {
  ReviewMeetingPreparation = 'reviewMeetingPreparation',
  IntroductionMeetingPreparation = 'introductionMeetingPreparation',
  General = 'general',
}
