import React from 'react';
import { FormattedTime as FormattedTimeBase, useIntl } from 'react-intl';

import { getHourCycle } from 'helpers/dates';

type Props = React.ComponentProps<typeof FormattedTimeBase>;

export function FormattedTime(props: Props) {
  const intl = useIntl();

  return <FormattedTimeBase hourCycle={getHourCycle(intl)} {...props} />;
}
