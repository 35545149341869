import React from 'react';
import { rem, size } from 'polished';
import styled from 'styled-components';

import HiddenText from 'components/HiddenText/HiddenText';
import IconMenu from 'components/Icons/IconHamburger';

const NavTrigger = styled.button`
  ${size(rem(36))};
  margin: auto 0 auto auto;
  padding: 0;
  outline: none;

  svg {
    height: ${size(rem(36))};
  }
`;

const HamburgerMenuButton = (props: React.HTMLAttributes<HTMLButtonElement>) => (
  <NavTrigger {...props}>
    <IconMenu />
    <HiddenText text="shared.header.menu" />
  </NavTrigger>
);

export default HamburgerMenuButton;
