import type { SectionCommon, Source } from './utils';

export enum IncomeSourceType {
  Employment = 'employment',
  EmploymentBonus = 'employmentBonus',
  SelfEmployment = 'selfEmployment',
  PrivatePension = 'privatePension',
  StatePension = 'statePension',
  Investment = 'investment',
  RentalIncome = 'rentalIncome',
  OtherStateBenefits = 'otherStateBenefits',
  OtherIncome = 'otherIncome',
}

export interface AnnualIncomeGrossItem {
  type: IncomeSourceType;
  amount: string;
  amount_currency: string;
  source: Source;
  own_share: number;
  transfer: boolean;
}

export enum IncomeType {
  Income = 'income',
  Expenditure = 'expenditure',
}

export interface DisposableMonthlyIncome {
  type: IncomeType;
  amount: string;
  amount_currency: string;
  source: Source;
  own_share: number;
}

export interface Occupation {
  name: string;
  source: Source;
}

export interface OccupationEarnings extends SectionCommon {
  occupations: Occupation[];
  gross_annual_incomes: AnnualIncomeGrossItem[];
  disposable_monthly_incomes: DisposableMonthlyIncome[];
}
