export default {
  title: `How we (securely) communicate with you`,
  summary: `We provide a range of convenient ways for you to meet your Adviser, and to communicate with them and with our office. The security of this communication and of your personal information is of paramount importance.`,
  section1: {
    title: `In-person meetings`,
    subtitle: `Our Advisers are both trained and diligent in recording, managing and storing personal information that you provide during face-to-face meetings.`,
  },
  section2: {
    title: `Telephone meetings`,
    subtitle: `Similarly, our Advisers are equally adept at managing your personal data that is provided during telephone meetings.`,
  },
  section3: {
    title: `Email`,
    subtitle: `We use encrypted emails for correspondence regarding sensitive topics and information. However, we use non-encrypted emails for general correspondence.`,
  },
  section4: {
    title: `Online forms`,
    subtitle: `Using this website, you can submit online forms to us relating to your financial situation and plans. Your use of this site is encrypted using the HTTPS protocol. Our website is hosted in secure data centres. Our database and file system are both encrypted.`,
  },
  section5: {
    title: `Online video and audio meetings`,
    subtitle: `On this site, we provide integrated video and audio services. These streams are encrypted. Any optional recordings of the streams are securely stored.`,
  },
  section6: {
    title: `Post`,
    subtitle: `We will occasionally send you information by post.`,
  },
};
