export enum KeyCode {
  Enter = 13,
  Escape = 27,
  Space = 32,
  ArrowLeft = 37,
  ArrowRight = 39,
}

export enum Key {
  Escape = 'Escape',
}
