import type { Item } from 'components/Dropdown/Dropdown';

export const userRoleOptions: Item[] = [
  {
    label: 'Practice Director',
    value: 'director',
  },
  {
    label: 'Adviser',
    value: 'adviser',
  },
  {
    label: 'Practice Admin',
    value: 'admin',
  },
  {
    label: 'Client',
    value: 'client',
  },
];

export const browserOptions: Item[] = [
  {
    label: 'Google Chrome',
    value: 'Google Chrome',
  },
  {
    label: 'Microsoft Edge',
    value: 'Microsoft Edge',
  },
  {
    label: 'Firefox',
    value: 'Firefox',
  },
  {
    label: 'Safari',
    value: 'Safari',
  },
  {
    label: 'Other',
    value: 'Other',
  },
  {
    label: 'Unsure',
    value: 'Unsure',
  },
];

export const deviceOptions: Item[] = [
  {
    label: 'iPad',
    value: 'iPad',
  },
  {
    label: 'iPhone',
    value: 'iPhone',
  },
  {
    label: 'Android Phone',
    value: 'Android Phone',
  },
  {
    label: 'Apple Mac Laptop / Desktop',
    value: 'Apple Mac Laptop / Desktop',
  },
  {
    label: 'Microsoft Windows',
    value: 'Microsoft Windows',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const dwsAreaOptions: Item[] = [
  {
    label: 'Account Settings',
    value: 'Account Settings',
  },
  {
    label: 'Document or Recommendation Pack',
    value: 'Document or Recommendation Pack',
  },
  {
    label: 'Client Meeting Rooms',
    value: 'Client Meeting Rooms',
  },
  {
    label: 'Messages',
    value: 'Messages',
  },
  {
    label: 'Action Required',
    value: 'Action Required',
  },
  {
    label: 'Questionnaire',
    value: 'Questionnaire',
  },
  {
    label: 'Reports',
    value: 'Reports',
  },
  {
    label: 'Practice Settings',
    value: 'Practice Settings',
  },
  {
    label: 'Financial Strategy',
    value: 'Financial Strategy',
  },
  {
    label: 'Client Vault',
    value: 'Client Vault',
  },
  {
    label: 'Password Reset',
    value: 'Password Reset',
  },
];

export const windowsLink =
  'https://support.microsoft.com/en-us/windows/zip-and-unzip-files-8d28fa72-f2f9-712f-67df-f80cf89fd4e5';

export const macLink = 'https://support.apple.com/en-in/guide/mac-help/mchlp2528/10.14/mac/10.14';

export const maxFileSize10MB = 10485760;
