export { YesNo } from 'constants/formElementsOptions';

export enum PersonType {
  You = 'you',
  Partner = 'partner',
}

export interface SumItem {
  title: string;
  value: string;
  isTranslated: boolean;
}

export enum Person {
  Client = 'client',
  Partner = 'partner',
}

export enum Source {
  You = 'you',
  Partner = 'partner',
  Joint = 'joint',
}

export interface SectionCommon {
  section_saved: boolean;
}

export enum FactFindStepName {
  PersonalDetails = 'personalDetails',
  OccupationEarnings = 'occupationEarnings',
  AssetsLiabilities = 'assetsLiabilities',
  TaxResidence = 'taxResidence',
  InvestmentTaxPlan = 'investmentTaxPlan',
  Health = 'health',
  Protection = 'protection',
  PlanningObjectives = 'planningObjectives',
  RecentLifeEvents = 'recentLifeEvents',
  AdditionalComments = 'additionalComments',
}

export enum FactFindStepLink {
  PersonalDetails = 'personal-details',
  OccupationEarnings = 'occupation-earnings',
  AssetsLiabilities = 'assets-liabilities',
  TaxResidence = 'tax-residence',
  InvestmentTaxPlan = 'investment-tax-planning',
  Health = 'health',
  Protection = 'protection',
  PlanningObjectives = 'planning-objectives',
  RecentLifeEvents = 'recent-life-events',
  AdditionalComments = 'additional-comments',
}

export interface InitialProgress {
  additional_comments_done: boolean;
  annual_investment_done: boolean;
  assets_liabilities_done: boolean;
  occupations_earnings_done: boolean;
  personal_details_done: boolean;
  health_done: boolean;
  protection_done: boolean;
  planning_objectives: boolean;
  recent_life_events_done: boolean;
  tax_residence_done: boolean;
  [key: string]: boolean | undefined;
}
