import api from 'api/apiInstance';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

import { docusignDocumentsApi } from './docusignDocument';

export interface DocuSignPostOptions {
  clientId: number;
  documentId?: number;
  addendaId?: number;
}

export interface CorrectViewOptions {
  addendaId?: number;
  documentId?: number;
}

const apiPath = '/api/v1/docusign/envelopes';
const documentPath = '/api/v1/docusign/documents';

function docuSignCreateClientViewApi(path: string) {
  return {
    path,
    POST: (data: DocuSignPostOptions) =>
      api.post(
        `${path}/create_recipient_view`,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(data),
          },
        })
      ),
    CORRECT_VIEW: (data: CorrectViewOptions) =>
      api.post(
        `${path}/create_correct_view`,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(data),
          },
        })
      ),
  };
}

type DocusignApi = ReturnType<typeof docuSignCreateClientViewApi>;
type DocusignDocumentApi = ReturnType<typeof docusignDocumentsApi>;

function docuSign(): DocusignApi;
function docuSign(documentId: number): DocusignDocumentApi;
function docuSign(documentId?: number): DocusignDocumentApi | DocusignApi {
  return documentId
    ? docusignDocumentsApi(documentPath, documentId)
    : docuSignCreateClientViewApi(apiPath);
}

export { docuSign };
