import type { JsonApiResponseSingle } from 'json-api-normalizer';
import { isString, omitBy } from 'lodash';

import api from 'api/apiInstance';
import type { DisabledPages, LandingType } from 'constants/landingPages';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

import { practiceSettingsFeatureTilesApi } from './featureTiles';
import { practiceSettingsPreviewPdfApi } from './previewPdf';

export interface PracticeSettingsPatchOptions {
  file?: File;
  autoDisclosureEnabled?: boolean;
  ourServicesAndChargesEnabled?: boolean;
  secondaryLogo?: File | '';
  logo?: File;
  favicon?: File;
  mobileIcon?: File | '';
  authorizedRepresentativeName?: string;
  tradingName?: string;
  minInvestmentForOnlineAdvice?: number;
  registeredOfficeAddress?: string;
  mailingAddress?: string;
  footerText?: string;
  colourSchemes?: {
    colorAccent: string;
    colorTextDark: string;
    colorTextLight: string;
    colorBackground: string;
    colorSubHeader: string;
    colorTransparentBox: string;
  };
  emailFooter?: string;
  sendingEmail?: string;
  riskProfiling?: boolean;
  shortName?: string;
  metaDescription?: string;
  manifestBackgroundColor?: string;
  manifestThemeColor?: string;
  metaThemeColor?: string;
  secondaryHomepageImageEnabled?: boolean;
  disabledPages?: DisabledPages;
  landingPageType?: LandingType;
  onboardingDisabled?: boolean;
  minMonthlyContributionForOnlineAdvice?: number;
  brochureLibraryEnabled?: boolean;
  multiTenantBrochuresEnabled?: boolean;
  privacyPolicyUrl?: string;
  selectedPdfFont?: string;
  ourAdvisersPageTitle?: string;
  ourAdvisersPrimaryHeading?: string;
  ourAdvisersPrimaryBodyText?: string;
  ourAdvisersSecondaryBodyText?: string;
  primaryCopyHeading?: string;
  primaryCopyText?: string;
  secondaryCopyHeading?: string;
  secondaryCopyText?: string;
  practiceTeamCopyHeading?: string;
  practiceTeamCopyText?: string;
  practiceTeamButtonText?: string;
  primaryCopyAdditionalText?: string;
  disclaimerText?: string;
  onlineAdviceRequestPromotionText?: string;
  topUpRequestPromotionText?: string;
  investmentProjectionsPromotionText?: string;
  mainWebsiteUrl?: string;
  blogEnabled?: boolean;
}

const apiPath = '/api/v1/practice-settings';

function practiceSettingsApi(path: string) {
  return {
    path,
    featureTiles: () => practiceSettingsFeatureTilesApi(`${path}/feature-tiles`),
    previewPdf: () => practiceSettingsPreviewPdfApi(`${path}/preview-pdf`),
    GET: () => api.get<JsonApiResponseSingle>(path),
    PATCH: (params: PracticeSettingsPatchOptions) => {
      const filteredData = omitBy(
        params,
        (value, key) => ['logo', 'favicon', 'pdfFonts'].includes(key) && isString(value)
      );

      return api.patch<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(filteredData),
          },
        })
      );
    },
  };
}

function practiceSettings() {
  return practiceSettingsApi(apiPath);
}

export { practiceSettings };
