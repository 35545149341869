import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { htmlElements } from 'helpers/translations';
import DashboardSecurityLabel from 'components/DashboardSecurityLabel/DashboardSecurityLabel';

import type { StepProps } from '../../types';
import BeginButton from '../shared/BeginButton';
import { Description } from '../shared/Description';

const StepBeginBase: FC<StepProps> = ({ children }) => (
  <>
    {children}

    <BeginButton />

    <Description>
      <FormattedMessage id="onboarding.stepBegin.description" values={htmlElements} />
    </Description>

    <DashboardSecurityLabel />
  </>
);

export default StepBeginBase;
