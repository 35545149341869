import { showWarningToast } from './toasts';

let sessionStorageAvailable = true;

function handleError(error: Error) {
  if (error.name === 'SecurityError' && sessionStorageAvailable) {
    sessionStorageAvailable = false;
    showWarningToast('errors.sessionStorageNotAvailable');
    window.Rollbar?.warning(error);
  }
}

export function setSessionStorageValue<T>(key: string, value: T): void {
  try {
    const preparedValue = JSON.stringify(value);

    sessionStorage?.setItem(key, preparedValue);
  } catch (error) {
    handleError(error);
  }
}

function getSessionStorageValueUnsafe<T>(key: string): T | null {
  const value = sessionStorage?.getItem(key);

  return value ? JSON.parse(value) : null;
}

export function getSessionStorageValue<T>(key: string): T | null {
  try {
    return getSessionStorageValueUnsafe<T>(key);
  } catch (error) {
    handleError(error);
  }

  return null;
}

function removeSessionStorageValueUnsafe(key: string): void {
  sessionStorage?.removeItem(key);
}

export function removeSessionStorageValue(key: string): void {
  try {
    removeSessionStorageValueUnsafe(key);
  } catch (error) {
    handleError(error);
  }
}

export function getAndRemoveSessionStorageValue<T>(key: string): T | null {
  try {
    const value = getSessionStorageValueUnsafe<T>(key);
    removeSessionStorageValueUnsafe(key);

    return value;
  } catch (error) {
    handleError(error);
  }

  return null;
}
