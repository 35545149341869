import React from 'react';

import { useGetAllCurrencies } from '../hooks/useGetAllCurrencies';
import { CurrencyDropdown, Props } from './CurrencyDropdown';

export function AllCurrencyDropdown(props: Omit<Props, 'currencies'>) {
  const currencies = useGetAllCurrencies();

  return <CurrencyDropdown currencies={currencies} {...props} />;
}
