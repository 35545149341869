import { useMemo } from 'react';

import { parseParticipantData } from 'helpers/adviceRooms';
import { useGetParticipant } from 'store/adviceRoomParticipants/selectors';

export function useParticipantData(participantIdentity: string) {
  const participantData = useMemo(
    () => parseParticipantData(participantIdentity),
    [participantIdentity]
  );

  return useGetParticipant(participantData.id, participantData.isGuest);
}
