import type { EditorState } from 'draft-js';
import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, RequestConfig } from 'api/types';
import { mapFormData } from 'helpers/formData';

import { adviserClientsApi } from './clients';
import { adviserMetaApi } from './meta';
import { adviserPermissionSettingsApi } from './permissionSettings';
import { adviserPracticeAdminsApi } from './practiceAdmins';
import { adviserPracticeAdminApi } from './practiceAdmins/practiceAdmin';
import { adviserReorderApi } from './reorder';
import { adviserResendApi } from './resend';
import { adviserSharedClientsApi } from './sharedClients';
import { adviserUpdateAsPdApi } from './updateAsPd';
import { adviserUpdatePreferencesApi } from './updatePreferences';

export type AdviserGetOptions = GetOptions | undefined;

export interface AdviserPatchOptions {
  contactLine1: string;
  contactLine2: string;
  biography?: EditorState;
  postCode: string;
  city: string;
  title: string;
  phone?: string;
  linkedinUrl?: string;
  facebookUrl?: string;
  twitterUrl?: string;
}

export function adviserApi(path: string) {
  type AdviserPracticeAdminApi = ReturnType<typeof adviserPracticeAdminApi>;
  type AdviserPracticeAdminsApi = ReturnType<typeof adviserPracticeAdminsApi>;

  function practiceAdmins(): AdviserPracticeAdminsApi;
  function practiceAdmins(practiceAdminId: number): AdviserPracticeAdminApi;
  function practiceAdmins(
    practiceAdminId?: number
  ): AdviserPracticeAdminsApi | AdviserPracticeAdminApi {
    const practiceAdminsApiPath = `${path}/practice-admins`;

    return practiceAdminId
      ? adviserPracticeAdminApi(`${practiceAdminsApiPath}/${practiceAdminId}`)
      : adviserPracticeAdminsApi(practiceAdminsApiPath);
  }

  return {
    path,
    clients: () => adviserClientsApi(`${path}/clients`),
    meta: () => adviserMetaApi(`${path}/meta`),
    permissionSettings: () => adviserPermissionSettingsApi(`${path}/permission-settings`),
    practiceAdmins,
    reorder: () => adviserReorderApi(`${path}/reorder`),
    resend: () => adviserResendApi(`${path}/resend`),
    sharedClients: () => adviserSharedClientsApi(`${path}/shared-clients`),
    updateAsPd: () => adviserUpdateAsPdApi(`${path}/update-as-pd`),
    updatePreferences: () => adviserUpdatePreferencesApi(`${path}/update-preferences`),
    GET: (params?: AdviserGetOptions, config?: RequestConfig) =>
      api.get<JsonApiResponseSingle>(path, { params, ...config }),
    PATCH: (params: AdviserPatchOptions, config?: GetOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(params),
        },
        ...config,
      }),
  };
}
