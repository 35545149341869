import { useEffect, useRef } from 'react';
import type { EventEmitter } from 'events';
import useForceUpdate from 'use-force-update';

export type EventEmitterType = EventEmitter | undefined | null;
export type EventTypes = string[];

export function useUpdateOnEvents(eventEmitterObject: EventEmitterType, eventTypes: EventTypes) {
  const forceUpdate = useForceUpdate();
  const handlerRef = useRef(forceUpdate);

  useEffect(() => {
    handlerRef.current = forceUpdate;
  }, [forceUpdate]);

  useEffect(() => {
    if (!eventEmitterObject) {
      return;
    }

    const eventHandler = handlerRef.current;

    eventTypes.forEach(event => eventEmitterObject.addListener(event, eventHandler));

    return () => {
      eventTypes.forEach(event => eventEmitterObject.removeListener(event, eventHandler));
    };
  }, [eventEmitterObject, eventTypes]);
}
