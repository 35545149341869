import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { FilterOptions, GetOptions, PaginationOptions, RequestConfig } from 'api/types';
import type { AdviceRoomFilters } from 'constants/adviceRoomFilters';

import { adviceRoomApi } from './adviceRoom';

export type AdviceRoomsGetOptions = GetOptions &
  PaginationOptions &
  FilterOptions<AdviceRoomFilters>;

export interface AdviceRoomsPostOptions {
  clientId: number;
  name: string;
  agenda: string;
  startDate: string;
  duration: string;
  mediaRegion?: string;
  outlookEventCreated: boolean;
  userIds: number[];
}

const apiPath = '/api/v1/client-meeting-rooms';

function adviceRoomsApi(path: string) {
  return {
    path,
    GET: ({ pageSize, pageNumber, ...rest }: AdviceRoomsGetOptions, config?: RequestConfig) =>
      api.get<JsonApiResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...rest,
        },
        ...config,
      }),
    POST: ({
      clientId,
      name,
      agenda,
      startDate,
      mediaRegion,
      outlookEventCreated,
      userIds,
      duration,
    }: AdviceRoomsPostOptions) =>
      api.post<JsonApiResponseSingle>(path, {
        data: {
          attributes: {
            client_id: clientId,
            name,
            agenda,
            duration,
            start_date: startDate,
            media_region: mediaRegion,
            user_ids: userIds,
            outlook_event_created: outlookEventCreated,
          },
        },
      }),
  };
}

type AdviceRoomApi = ReturnType<typeof adviceRoomApi>;
type AdviceRoomsApi = ReturnType<typeof adviceRoomsApi>;

function adviceRooms(): AdviceRoomsApi;
function adviceRooms(adviceRoomId: number): AdviceRoomApi;
function adviceRooms(adviceRoomId?: number): AdviceRoomsApi | AdviceRoomApi {
  return adviceRoomId ? adviceRoomApi(`${apiPath}/${adviceRoomId}`) : adviceRoomsApi(apiPath);
}

export { adviceRooms };
