export default {
  required: `Required`,
  requiredMissing: `Some required fields are missing`,
  number: `Must be a number.`,
  positiveNumber: `Must be a positive number.`,
  integer: `Must be an integer.`,
  formInvalid: `Please complete all required fields before progressing.`,
  greaterEqualThan: `Should be equal or greater than {min}`,
  greaterThan10MB: `Maximum screenshot(s) upload limit is 10 MB.`,
  greaterThan: `Should be greater than`,
  markAtLeastOneAnswer: `Please, mark at least one answer`,
  markAtLeastOneOption: `Please, mark at least one option`,
  mustBeUrl: `Must be a valid url (prefixed with ‘http://’ or ‘https://’)`,
  mustBePdfUrl: `Must be a valid url pointing to pdf file (prefixed with ‘http://’ or ‘https://’)`,
  specifyUrlOrFile: `Specify a pdf url or a file, not both`,
  specifyPdfOrLink: `Specify either pdf or a link`,
  specifyUrlLinkOrFile: `Specify either pdf url, pdf file or link`,
  mustBeLinkedinProfile: `Must be linkedin profile link`,
  passwordConfirmation: `Passwords must match`,
  // this translation is needed to have id and scroll to it
  emptyMsg: ` `,
  // this translation is needed to render hidden message to preserve spacing

  greaterThanIfReq: `Should be greater than {min}, if required`,
  between: `Should be between {min} and {max}`,
  lessEqualThan: `Should be equal or less than {max}`,
  maxLength: `Should contain less than {max} characters`,
  maxDigits: `Should be equal or less than {max} digits`,

  mustBeFutureDate: `Date must be in the future`,
  mustBeFutureTime: `Time must be in the future`,
  mustBePastDate: `Date must be in the past`,
  mustBeTodayOrPastDate: `Date must be today or in the past`,
  mustBeAfterDate: `Date must be after {minDate}`,
  mustBeAfterTime: `Time must be after {minDate}`,
  mustBeTodayOrFutureDate: `Date must be after today or in the future`,

  betweenDuration: `Duration of {min} to {max} years required.`,

  formNotLoaded: `Something went wrong, form data was not loaded.`,
  formNotSaved: `Something went wrong, form data was not saved.`,

  fileNotRemoved: `Something went wrong, file was not removed.`,
  fileNotSaved: `Something went wrong, file was not saved.`,
  fileNotUpdated: `Something went wrong, file was not updated.`,
  fileForbiddenName: `Forbidden file's name.`,

  image: `Something went wrong with the image.`,
  imageBroken: `The image file is unreadable or corrupted.`,

  notAllowedFileType: `File type of ‘{name}’ is not allowed.`,
  fileSizeIsTooBig: `File size of ‘{name}’ must be less than {value}.`,
  caseNotReady: `Please complete all sections before submitting the case.`,
  chosenPhotoNotSaved: `Chosen photo has not been saved`,

  deprecatedDocument: `You are trying to edit deprecated document with name ‘{documentName}’. Please, click here to get the newest one`,

  imageHeightMustBeSmaller: `Image height must be smaller than {value}px`,
  imageWidthMustBeSmaller: `Image width must be smaller than {value}px`,
  fileMustBeSmaller: `File must be smaller than {value}`,

  404: {
    title: `404 - Page not found`,
    text: `The page that you’re looking for does not exist.`,
    goBack: `Go to homepage`,
  },

  sessionStorageNotAvailable: `Session storage is not available. Please make sure that your browser do not block cookies.`,
  localStorageNotAvailable: `Local storage is not available.  Please make sure that your browser do not block cookies.`,
};
