import { last, reduce } from 'lodash';
import striptags from 'striptags';

/**
 * Returns an array of strings made from the input. Each array represents separate line which
 * should be shorter than maxWidth.
 * @param input
 * @param maxWidth
 */
export function wordWrap(input: string, maxWidth: number) {
  return reduce(
    input.split(' '),
    (lines, currentWord) => {
      const currentLine = last(lines);
      const currentLineLength = currentLine ? currentLine.length : 0;

      if (currentLineLength >= maxWidth) {
        return [...lines, currentWord];
      }

      const previousLines = lines.slice(0, -1);

      return [...previousLines, currentLine ? `${currentLine} ${currentWord}` : currentWord];
    },
    [] as string[]
  );
}

export function stripHtmlTags(input: string): string {
  return striptags(input.replace(/&nbsp;/g, ' '));
}

export function isHtmlString(input: string): boolean {
  return input.trim().length !== stripHtmlTags(input).trim().length;
}

// inlined https://www.npmjs.com/package/escape-string-regexp due to lack of transpiled src
export function escapeStringRegexp(value: string) {
  // Escape characters with special meaning either inside or outside character sets.
  // Use a simple backslash escape when it’s always valid, and a `\xnn` escape when the simpler
  // form would be disallowed by Unicode patterns’ stricter grammar.
  return value.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d');
}
