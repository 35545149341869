import { Root, route } from '../utils';

const meetingsPath = `${Root}meetings`;
const clientMeetingRoomPath = `${meetingsPath}/client-meeting-rooms/:adviceRoomId`;

type ClientMeetingRoomParams = [clientMeetingRoomId: number];

export default {
  meetings: {
    ...route(meetingsPath),
    clientMeetingRoom: {
      ...route<ClientMeetingRoomParams>(clientMeetingRoomPath),
      auditTrail: route<ClientMeetingRoomParams>(`${clientMeetingRoomPath}/audit-trail`),
      meeting: route<ClientMeetingRoomParams>(`${clientMeetingRoomPath}/meeting`),
      testSetup: route<ClientMeetingRoomParams>(`${clientMeetingRoomPath}/test-setup`),
    },
  },
};
