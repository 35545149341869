import { usersConfirmationApi } from './confirmation';
import { usersEmailSearchApi } from './emailSearch';
import { usersMeApi } from './me';
import { usersNotificationsApi } from './notifications';
import { usersPasswordApi } from './password';
import { usersSearchApi } from './search';
import { usersSignInApi } from './signIn';
import { usersSignOutApi } from './signOut';
import { userApi } from './user';

const apiPath = '/api/v1/users';

function usersApi(path: string) {
  return {
    path,
    confirmation: () => usersConfirmationApi('/users/confirmation'),
    me: () => usersMeApi(`${path}/me`),
    notifications: () => usersNotificationsApi(`${path}/notifications`),
    password: () => usersPasswordApi('/users/password'),
    search: () => usersSearchApi(`${path}/search`),
    emailSearch: () => usersEmailSearchApi(`${path}/email_search`),
    signIn: () => usersSignInApi('/users/sign_in'),
    signOut: () => usersSignOutApi('/users/sign_out'),
  };
}

type UserApi = ReturnType<typeof userApi>;
type UsersApi = ReturnType<typeof usersApi>;

function users(): UsersApi;
function users(userId: number): UserApi;
function users(userId?: number): UsersApi | UserApi {
  return userId ? userApi(`${apiPath}/${userId}`) : usersApi(apiPath);
}

export { users };
