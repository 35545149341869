import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

import { disclosureFormReorderApi } from './reorder';

export interface DisclosureFormPatchOptions {
  title?: string;
  image?: File;
  file?: File;
}

export function disclosureFormApi(path: string) {
  return {
    path,
    reorder: () => disclosureFormReorderApi(`${path}/reorder`),
    GET: () => api.get<JsonApiResponseSingle>(path),
    PATCH: (values: DisclosureFormPatchOptions) =>
      api.patch<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(values),
          },
        })
      ),
    DELETE: () => api.delete<JsonApiResponse>(path),
  };
}
