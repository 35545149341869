import type { FormRenderProps } from 'react-final-form';
import { differenceInYears } from 'date-fns';
import { forEach } from 'lodash';

import { ensureDate } from 'helpers/dates';
import { isNotBlank } from 'helpers/utils';

import { Fields, Values } from './types';

export const isKnownClient = (values: Values) => isNotBlank(values[Fields.InvitationUuid]);

export const getAge = (values: Values) =>
  differenceInYears(new Date(), ensureDate(values[Fields.DateOfBirth]));

export const revertToInitialValue = (
  form: FormRenderProps<Values>['form'],
  ...fields: Fields[]
) => {
  const { initialValues } = form.getState();

  forEach(fields, field => form.change(field, initialValues[field]));
};
