import type { PreflightTestProgress } from 'twilio-video';

export enum RoomType {
  AdviceRoom = 'adviceRoom',
  AdviceRoomGuest = 'adviceRoomGuest',
}

interface AdviceRoomData {
  roomType: RoomType.AdviceRoom;
  roomId: number;
}

interface AdviceRoomGuestData {
  roomType: RoomType.AdviceRoomGuest;
}

export enum VideoLayout {
  Grid,
  DominantSpeaker,
}

export interface Message {
  id: string;
  text: string;
  date: string;
  senderEmail: string;
  senderName?: string;
  senderAvatar?: string;
}

export type RoomData = AdviceRoomData | AdviceRoomGuestData;

export interface JoinOptions {
  onDisconnected?: () => void;
}

export enum VerificationStatus {
  Error,
  PermissionDenied,
  SystemPermissionDenied,
  Failed,
  Warning,
  Pending,
  Succeeded,
}

export type ConnectionTestDetails = Record<
  PreflightTestProgress,
  | VerificationStatus.Pending
  | VerificationStatus.Succeeded
  | VerificationStatus.Failed
  | VerificationStatus.Warning
>;

export type TestResult = {
  status: VerificationStatus.Pending | VerificationStatus.Succeeded | VerificationStatus.Failed;
  details?: ConnectionTestDetails;
};

export type JoinRoom = (token: string, data: RoomData, options?: JoinOptions) => Promise<void>;
export type LeaveRoom = () => void;
export type SendMessage = (messageText: string) => void;

export type Toggle = (shouldEnable?: boolean) => void;
