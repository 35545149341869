import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';

export interface ClientMetaDocumentsPacksGetResponse {
  unseen_documents_packs_count: number;
}

export function clientMetaDocumentsPacksApi(path: string) {
  return {
    path,
    GET: (config?: RequestConfig) => api.get<ClientMetaDocumentsPacksGetResponse>(path, config),
  };
}
