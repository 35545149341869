import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

export interface MotivationCentreItemReorderPatchOptions {
  position: number;
}

export function clientMotivationCentreItemReorderApi(path: string) {
  return {
    path,
    PATCH: (params: MotivationCentreItemReorderPatchOptions) =>
      api.patch<JsonApiResponse>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
