import React from 'react';

import SvgIcon from './SvgIcon';

const IconArrowLeft = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 12 8" {...props}>
    <path
      d="M11 4H1m4-4L1 4l4 4"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default IconArrowLeft;
