import React, { ButtonHTMLAttributes, MouseEvent } from 'react';
import { darken, rem } from 'polished';
import styled, { css } from 'styled-components';

import { breakpoints } from 'helpers/styles/constants';
import type { ReactPath } from 'routes/types';
import GenericLink from 'components/GenericLink/GenericLink';

export type OnClickEvent = MouseEvent<HTMLButtonElement>;
export type OnClickProp = (event: OnClickEvent) => void;

interface StyledProps {
  disabled?: boolean;
  hasBorder?: boolean;
  isBold?: boolean;
  isBlock?: boolean;
  isFlex?: boolean;
  isHalfWidth?: boolean;
  isTransparent?: boolean;
  isHigh?: boolean;
  isMedium?: boolean;
  hasMarginTopMd?: boolean;
  hasMarginBottomMd?: boolean;
  hasNoWidth?: boolean;
  isGrey?: boolean;
  hasNoFocus?: boolean;
}

export const StyledButton = styled(GenericLink).withConfig<StyledProps>({
  shouldForwardProp: prop =>
    ![
      'theme',
      'hasBorder',
      'isBold',
      'isBlock',
      'isHalfWidth',
      'isTransparent',
      'isHigh',
      'isMedium',
      'hasMarginTopMd',
      'hasMarginBottomMd',
      'hasNoWidth',
      'text',
      'isGrey',
      'isFlex',
      'hasNoFocus',
    ].includes(prop),
})`
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
  padding: ${rem(16)} ${rem(30)};
  vertical-align: top;
  font-family: ${({ theme }) => theme.fontFamilies.main};
  font-size: ${({ theme }) => theme.fontSizes.dbNormal};
  text-align: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.buttonText};
  transition: background-color 0.3s ease, opacity 0.3s ease;

  ${({ disabled, theme }) =>
    disabled
      ? css`
          opacity: 0.4;
          cursor: default;
          pointer-events: none;
        `
      : css`
          &:hover {
            background-color: ${darken(0.05, theme.colors.accent)};
          }
        `};

  ${({ hasMarginTopMd }) => hasMarginTopMd && `margin-top: ${rem(30)}`};
  ${({ hasMarginBottomMd }) => hasMarginBottomMd && `margin-bottom: ${rem(30)}`};
  ${({ isBold, theme }) => isBold && `font-weight: ${theme.fontWeights.mainBold}`};
  ${({ isBlock }) =>
    isBlock &&
    css`
      display: block;
      width: 100%;
    `};
  ${({ isFlex }) =>
    isFlex &&
    css`
      display: inline-flex;
      justify-content: center;
    `};
  ${({ hasBorder, theme }) => hasBorder && `border: 1px solid ${theme.colors.bermudaGray}`};
  ${({ isHigh }) =>
    isHigh &&
    css`
      padding-top: ${rem(18)};
      padding-bottom: ${rem(18)};
    `};
  ${({ isMedium }) =>
    isMedium &&
    css`
      padding-top: ${rem(10)};
      padding-bottom: ${rem(10)};
    `};
  ${({ isTransparent, theme }) =>
    isTransparent &&
    css`
      background-color: transparent;
      color: ${theme.colors.textDark};

      &:hover,
      &:focus {
        text-decoration: underline;
        background-color: transparent;
        outline: none;
      }
    `};
  ${({ isTransparent, isBlock }) =>
    isTransparent &&
    !isBlock &&
    css`
      padding: 0;
      width: auto;
    `};
  ${({ isGrey, theme }) => isGrey && `color: ${theme.colors.textLight}`};
  ${({ isHalfWidth }) =>
    isHalfWidth &&
    css`
      width: auto;
      min-width: calc(50% - ${rem(10)});
    `};
  ${({ hasNoWidth }) => hasNoWidth && 'width: auto'};

  ${({ isBlock, isTransparent, isHalfWidth, hasNoWidth }) =>
    !(isBlock || (isTransparent && !isBlock) || isHalfWidth || hasNoWidth) &&
    css`
      @media ${breakpoints.smUp} {
        width: ${rem(300)};
      }
    `};
  ${({ hasNoFocus }) =>
    hasNoFocus &&
    css`
      &:hover,
      &:focus {
        outline: none;
      }
    `};
`;

export interface ButtonProps extends StyledProps, ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: OnClickProp;
  path?: ReactPath | string;
  rel?: string;
  target?: string;
  children: React.ReactNode;
}

function GenericButton(props: ButtonProps) {
  return <StyledButton defaultTag="button" {...props} />;
}

export default GenericButton;
