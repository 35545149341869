import ReactOnRails from 'react-on-rails';
import axios from 'axios';

import { checkAndHandleUnauthorizedError } from 'helpers/errorsHandlers';
import { ensureIsArray, getAppStore } from 'helpers/utils';
import { resetExpirationSessionTimer } from 'store/sessionExpiration/actions';
import type { ThunkDispatch } from 'store/types';
import { getCurrentUser } from 'store/users/selectors';

const api = axios.create({
  responseType: 'json',
  // Use transformRequest to force getting recent token on each request
  // It fixes some issues with turbolinks, that caused it to be outdated
  transformRequest: ensureIsArray(axios.defaults.transformRequest).concat((data, headers) => {
    // eslint-disable-next-line no-param-reassign
    headers['X-CSRF-Token'] = ReactOnRails.authenticityToken();

    return data;
  }),
});

api.interceptors.response.use(
  response => response,
  error => {
    checkAndHandleUnauthorizedError(getAppStore(), error);
    return Promise.reject(error);
  }
);

api.interceptors.request.use(request => {
  const store = getAppStore();
  const currentUser = getCurrentUser(store.getState());
  if (currentUser != null) {
    (store.dispatch as ThunkDispatch)(resetExpirationSessionTimer());
  }

  return request;
});

export default api;
