import React from 'react';
import { FormattedMessage } from 'react-intl';

import Banner from 'components/Banner/Banner';

import SessionExpirationBannerContainer, { RenderProps } from './SessionExpirationBannerContainer';

const SessionExpirationBanner = ({ onClick }: RenderProps) => (
  <Banner onClick={onClick}>
    <FormattedMessage id="shared.sessionWillExpire" />
  </Banner>
);

const render = (renderProps: RenderProps) => <SessionExpirationBanner {...renderProps} />;
export default () => <SessionExpirationBannerContainer render={render} />;
