import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions } from 'api/types';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

export type DocumentPackDocumentsGetOptions = GetOptions;

export interface DocumentPackDocumentsPostOptions {
  name: string;
  file: File;
  note?: string;
  signatureRequired: boolean;
  docusignSignatureRequired: boolean;
  shouldSendSignatureReminder?: boolean;
  autoEmailEnabled?: boolean;
  recipientIdentityVerificationType?: string;
  recipientIdentityVerificationName?: string;
}

export function documentPackDocumentsApi(path: string) {
  return {
    path,
    GET: (params?: DocumentPackDocumentsGetOptions) => api.get<JsonApiResponse>(path, { params }),
    POST: ({ note, ...rest }: DocumentPackDocumentsPostOptions) =>
      api.post<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData({
              note_for_client: note,
              ...mapFormData(rest),
            }),
          },
        })
      ),
  };
}
