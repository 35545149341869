import type { AnyAction } from 'redux';

import {
  HIDE_EXPIRATION_SESSION_BANNER,
  SAVE_LAST_REQUEST_TIMESTAMP,
  SHOW_EXPIRATION_SESSION_BANNER,
} from './actionTypes';
import type { SessionExpirationActionTypes, SessionExpirationState } from './types';

export const initialState: SessionExpirationState = {
  isBannerVisible: false,
  lastRequestTimestamp: Date.now(),
};

export default (state = initialState, incomingAction: AnyAction): SessionExpirationState => {
  const action = incomingAction as SessionExpirationActionTypes;

  switch (action.type) {
    case SHOW_EXPIRATION_SESSION_BANNER:
      return {
        ...state,
        isBannerVisible: true,
      };
    case HIDE_EXPIRATION_SESSION_BANNER:
      return {
        ...state,
        isBannerVisible: false,
      };
    case SAVE_LAST_REQUEST_TIMESTAMP:
      return {
        ...state,
        lastRequestTimestamp: action.payload,
      };
    default:
      return state;
  }
};
