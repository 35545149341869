import { useSelector } from 'react-redux';

import { RoomConnectionState } from 'components/VideoChat/RoomConnectionState';

import type { AppState } from '../types';
import { initialState } from './reducer';
import type { VideoChatState } from './types';

const getStateSubtree = (state: AppState): VideoChatState => state.videoChat || initialState;

export const getIsConnectedToRoom = (state: AppState) =>
  getStateSubtree(state).isConnectedToRoom || false;

export const useGetIsConnectedToRoom = () => useSelector(getIsConnectedToRoom);

export const getAdviceRoomConnectionState = (state: AppState) =>
  getStateSubtree(state).adviceRoomConnectionState || RoomConnectionState.Disconnected;

export const useGetAdviceRoomConnectionState = () => useSelector(getAdviceRoomConnectionState);

export const getJoinedAdviceRoomId = (state: AppState) => getStateSubtree(state).joinedAdviceRoomId;

export const useGetJoinedAdviceRoomId = () => useSelector(getJoinedAdviceRoomId);
