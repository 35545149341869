import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { PaginationOptions } from 'api/types';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

export type ClientTagsGetOptions = PaginationOptions;
export interface ClientTagsPostOptions {
  name?: string;
}

const apiPath = '/api/v1/client-tags';

function clientTagsApi(path: string) {
  return {
    path,
    GET: () => api.get<JsonApiResponse>(path),
    POST: (params: ClientTagsPostOptions) =>
      api.post<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      ),
  };
}

type ClientTagsApi = ReturnType<typeof clientTagsApi>;

function clientTags(): ClientTagsApi {
  return clientTagsApi(apiPath);
}

export { clientTags };
