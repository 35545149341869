import React, { ChangeEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { rem } from 'polished';
import styled from 'styled-components';

import { KeyCode } from 'constants/keys';
import GenericButton from 'components/GenericButton/GenericButton';
import GenericErrorMsg from 'components/GenericErrorMsg/GenericErrorMsg';
import GenericInput from 'components/GenericInput/GenericInput';
import GenericLabel from 'components/GenericLabel/GenericLabel';
import Margin from 'components/Margin/Margin';

const Modal = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  min-width: 300px;
  padding: 2em;
  background: white;
  transform: translate(-50%, -50%);

  @media (max-width: 375px) {
    min-width: 95%;
    padding: 1em;
  }
`;

const StyledSpan = styled.span`
  display: block;
  margin-bottom: 20px;
  font-size: ${rem(13)};
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.greyDarken};
`;

interface Props {
  updatePassword: (password: string) => void;
  error?: string | boolean;
}

export const PasswordModal = ({ updatePassword, error }: Props) => {
  const [password, setPassword] = useState<string>('');

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    setPassword(event.currentTarget.value);

  return (
    <Modal>
      <GenericLabel hasNoMarginBottom text={<FormattedMessage id="shared.fields.password" />} />

      <StyledSpan>
        This document is password protected.
        <br />
        Please enter the password to open it.
      </StyledSpan>

      <GenericInput
        onChange={changeHandler}
        placeholder="shared.fields.password"
        hasFullWidth
        type="password"
        onKeyDown={e => {
          if (e.keyCode === KeyCode.Enter) {
            updatePassword(password);
          }
        }}
      />
      {error && typeof error === 'string' && <GenericErrorMsg message={error} hasFullWidth />}

      <Margin top={16}>
        <GenericButton style={{ width: '100%' }} onClick={() => updatePassword(password)}>
          Submit
        </GenericButton>
      </Margin>
    </Modal>
  );
};

export default PasswordModal;
