import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions } from 'api/types';
import { mapFormDataDeep } from 'helpers/formData';
import type { FaqSection } from 'types/entities/faq';

export interface FaqPatchOptions {
  faqSections?: FaqSection[];
}

const apiPath = '/api/v1/faq';

function faqApi(path: string) {
  return {
    path,
    GET: (params?: GetOptions) => api.get<JsonApiResponseSingle>(path, { params }),
    PATCH: (params: FaqPatchOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormDataDeep(params),
        },
      }),
  };
}

function faq() {
  return faqApi(apiPath);
}

export { faq };
