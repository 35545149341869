import React, { useCallback, useEffect, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { useMediaQuery } from 'react-responsive';
import Sticky from 'react-stickynode';

import { requestDevicePermissions } from 'api/twilio/twilioVideo';
import { breakpoints, misc } from 'helpers/styles/constants';
import { useRwdQuery } from 'helpers/styles/useRwdQuery';
import DraggableBase from 'components/DraggableWindow/DraggableBase';
import {
  useCameraPermissions,
  useMicrophonePermissions,
} from 'components/TestSetupTwilio/components/useDevicesPermissions';

import { ChatWindow } from './components/ChatWindow/ChatWindow';
import VideoChatPermissionMessage from './components/VideoChatPermissionMessage';
import { VideoLayout } from './types';
import { useSessionExtender } from './useSessionExtender';

export interface Props {
  onJoin?: () => void;
  onLeave: () => void;
  isFloating?: boolean;
}

export default function VideoChat(props: Props) {
  const { isFloating } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSticky, setIsSticky] = useState(true);
  const [layout, setLayout] = useState(VideoLayout.DominantSpeaker);

  const { isMobile, isTablet } = useRwdQuery();
  const isStickable = useMediaQuery({ query: breakpoints.mdUp });
  const shouldStick = isStickable && isSticky;

  useSessionExtender();

  useEffect(() => {
    requestDevicePermissions();
  }, []);

  useEffect(() => {
    const closeFullscreen = () => setIsExpanded(false);

    window.addEventListener('shareddocumentopened', closeFullscreen);

    return () => {
      window.removeEventListener('shareddocumentopened', closeFullscreen);
    };
  }, []);

  const toggleFullscreen = useCallback((shouldBeExpanded?: boolean) => {
    setIsExpanded(prevState => (shouldBeExpanded != null ? shouldBeExpanded : !prevState));
  }, []);

  const toggleSticky = useCallback(() => {
    setIsSticky(prevState => !prevState);
  }, []);

  const toggleLayout = useCallback(() => {
    setLayout(prevState =>
      prevState === VideoLayout.Grid ? VideoLayout.DominantSpeaker : VideoLayout.Grid
    );
  }, []);

  const { microphonePermissions } = useMicrophonePermissions();
  const { cameraPermissions } = useCameraPermissions();

  const finalProps = {
    ...props,
    isExpanded,
    isSticky,
    isStickable,
    onToggleFullscreen: toggleFullscreen,
    onToggleSticky: toggleSticky,
    onToggleLayout: toggleLayout,
    layout,
  };

  return isFloating ? (
    <DraggableBase>
      {({ windowZIndex }) => (
        <ChatWindow
          windowZIndex={windowZIndex}
          isUISmall={!isExpanded || isMobile || isTablet}
          {...finalProps}
        />
      )}
    </DraggableBase>
  ) : (
    <>
      <VideoChatPermissionMessage microphone={microphonePermissions} camera={cameraPermissions} />

      {/* used to refresh children of Sticky on resize, fixes SOV-3045 */}
      <ReactResizeDetector
        handleWidth
        render={({ width }) => (
          <Sticky
            innerZ={isExpanded ? 'auto' : misc.videoChatZIndex}
            top={30}
            enabled={!isExpanded && shouldStick}
          >
            {status => (
              <ChatWindow
                {...finalProps}
                hasShadow={status.status === Sticky.STATUS_FIXED}
                isUISmall={Boolean(isMobile || isTablet || (!isExpanded && width && width < 560))}
              />
            )}
          </Sticky>
        )}
      />
    </>
  );
}
