import { zendeskSignInApi } from './signIn';

const apiPath = '/api/v1/zendesk';

export function zendeskApi(path: string) {
  return {
    path,
    signIn: () => zendeskSignInApi(`${path}/sign-in`),
  };
}

export function zendesk() {
  return zendeskApi(apiPath);
}
