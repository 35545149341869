import { useSelector } from 'react-redux';
import { first } from 'lodash';

import EntityPath from 'constants/entitiesPaths';
import type { Practice } from 'types/entities/practice';

import { createEntitySelectors } from '../entities/selectors';
import type { AppState } from '../types';

const { getAll: getAllPractices } = createEntitySelectors<Practice>(EntityPath.Practices);

export { getAllPractices };

export const getCurrentPractice = (state: AppState) => {
  const currentPractice = first(getAllPractices(state));

  if (!currentPractice) {
    throw new Error('Current practice cannot be determined');
  }

  return currentPractice;
};

export const useGetCurrentPractice = () => useSelector(getCurrentPractice);
