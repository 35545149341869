import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

export interface GuestSyncTokenPostOptions {
  authToken: string;
}

export function guestSyncTokenApi(path: string) {
  return {
    path,
    POST: (params: GuestSyncTokenPostOptions) =>
      api.post<{ token: string }>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
