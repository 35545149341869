import React, { useState } from 'react';
import { find, first, map } from 'lodash';
import { rem, rgba } from 'polished';
import styled from 'styled-components';
import type { LocalParticipant, RemoteParticipant, Room } from 'twilio-video';

import { isScreenSharingTrack, isVideoTrack } from 'api/twilio/twilioVideo';
import { AvatarSize } from 'components/Avatar';

import { useChatWindowContext } from '../ChatWindow/ChatWindowContext';
import {
  getParticipantAudioTrack,
  getParticipantVideoTrack,
  getVideoRatio,
} from '../Participant/helpers';
import { CameraDisabledIndicator } from '../Participant/Indicators/CameraDisabledIndicator';
import { MicrophoneDisabledIndicator } from '../Participant/Indicators/MicrophoneDisabledIndicator';
import { NetworkQuality } from '../Participant/Indicators/NetworkQuality';
import { LocalParticipantComponent } from '../Participant/LocalParticipant';
import { ParticipantWrapper } from '../Participant/ParticipantWrapper';
import { RemoteParticipantComponent } from '../Participant/RemoteParticipant';
import { BottomStatusBar, TopStatusBar } from '../Participant/StatusBar';
import AudioTrack from '../Track/AudioTrack';
import VideoTrack from '../Track/VideoTrack';
import { ParticipantBase } from './ParticipantBase';
import { RemoteParticipantWrapper } from './RemoteParticipantWrapper';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const LocalParticipantWrapper = styled(ParticipantBase)<{ isHidden: boolean; isUISmall: boolean }>`
  position: absolute;
  bottom: ${({ isUISmall }) => rem(isUISmall ? 4 : 10)};
  right: ${({ isUISmall }) => rem(isUISmall ? 4 : 10)};
  box-shadow: ${rem(3)} ${rem(3)} ${rem(15)} ${rgba(0, 0, 0, 0.5)};
  opacity: ${({ isHidden }) => (isHidden ? 0.5 : 1)};
  transition: ease-in-out 0.2s opacity;
`;

const RemoteParticipantsWrapper = styled.div<{ isHidden: boolean; isUISmall: boolean }>`
  position: absolute;
  top: ${({ isUISmall }) => rem(isUISmall ? 30 : 40)};
  left: 0;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 ${({ isUISmall }) => rem(isUISmall ? 2 : 6)};
  opacity: ${({ isHidden }) => (isHidden ? 0.5 : 1)};
  transition: ease-in-out 0.2s opacity;
`;

const DominantSpeakerWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

interface Props {
  room: Room;
  screenSharingParticipant: LocalParticipant | RemoteParticipant | undefined;
}

export function DominantSpeakerLayout({ room, screenSharingParticipant }: Props) {
  const { isUISmall, isUIHidden } = useChatWindowContext();

  const [selectedParticipantSid, setSelectedParticipantSid] = useState<string>();
  const remoteParticipants = Array.from(room.participants.values());

  const firstRemoteParticipant = first(remoteParticipants);

  const mainParticipant =
    screenSharingParticipant ||
    (remoteParticipants.length > 1
      ? find(remoteParticipants, participant => participant.sid === selectedParticipantSid) ||
        room.dominantSpeaker ||
        firstRemoteParticipant
      : firstRemoteParticipant);

  const localParticipantVideoTrack = getParticipantVideoTrack(room.localParticipant);
  const localParticipantVideoRatio = getVideoRatio(localParticipantVideoTrack);
  const localParticipantHeight = isUISmall ? 75 : 120;
  const localParticipantWidth = isUISmall ? 100 : 160;

  return (
    <Wrapper>
      {mainParticipant && (
        <DominantSpeakerWrapper>
          <ParticipantWrapper>
            {remoteParticipants.length === 1 &&
              mainParticipant.identity !== room.localParticipant.identity && (
                <>
                  <TopStatusBar>
                    <NetworkQuality participant={mainParticipant} />
                  </TopStatusBar>

                  <BottomStatusBar>
                    <CameraDisabledIndicator
                      videoTrack={getParticipantVideoTrack(mainParticipant)}
                    />
                    <MicrophoneDisabledIndicator
                      audioTrack={getParticipantAudioTrack(mainParticipant)}
                    />
                  </BottomStatusBar>
                </>
              )}

            <VideoTrack
              participantIdentity={mainParticipant.identity}
              track={
                getParticipantVideoTrack(
                  mainParticipant,
                  screenSharingParticipant ? isScreenSharingTrack : isVideoTrack
                )?.track
              }
              avatarSize={AvatarSize.L}
            />
          </ParticipantWrapper>
        </DominantSpeakerWrapper>
      )}

      {(remoteParticipants.length > 1 || Boolean(screenSharingParticipant)) && (
        <RemoteParticipantsWrapper isHidden={isUIHidden} isUISmall={isUISmall}>
          {map(remoteParticipants, participant => (
            <RemoteParticipantWrapper
              key={participant.identity}
              participant={participant}
              isSelected={!screenSharingParticipant && participant.sid === selectedParticipantSid}
              isUISmall={isUISmall}
              onSelect={
                screenSharingParticipant
                  ? undefined
                  : () =>
                      setSelectedParticipantSid(prevSelectedParticipantSid =>
                        prevSelectedParticipantSid === participant.sid ? undefined : participant.sid
                      )
              }
            >
              <RemoteParticipantComponent participant={participant} />
            </RemoteParticipantWrapper>
          ))}
        </RemoteParticipantsWrapper>
      )}

      {map(remoteParticipants, participant => {
        const audioTrack = getParticipantAudioTrack(participant);

        return <AudioTrack key={participant.identity} track={audioTrack?.track} />;
      })}

      <LocalParticipantWrapper
        height={localParticipantHeight}
        width={
          localParticipantVideoRatio
            ? localParticipantHeight * localParticipantVideoRatio
            : localParticipantWidth
        }
        isHidden={isUIHidden}
        isUISmall={isUISmall}
      >
        <LocalParticipantComponent participant={room.localParticipant} />
      </LocalParticipantWrapper>
    </Wrapper>
  );
}
