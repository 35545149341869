import React from 'react';
import { useField } from 'react-final-form';
import type { PDFAnnotationData } from 'pdfjs-dist/types/src/display/api';
import { rem } from 'polished';
import styled from 'styled-components';

import { commonWidgetStyles } from '../styled';
import { getFixedFieldName } from './helpers';
import { useReinitializeOnChange } from './useReinitializeOnChange';

const lineWidth = 2;
const boxPadding = 4;

const Control = styled.input`
  ${commonWidgetStyles};

  &:checked {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: calc(50% - ${rem(lineWidth / 2)});
      left: ${rem(boxPadding)};
      height: ${rem(lineWidth)};
      width: calc(100% - ${rem(boxPadding * 2)});
      background-color: currentColor;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
`;

export interface Props {
  data: PDFAnnotationData;
  isReadOnly?: boolean;
}

export function CheckBoxAnnotationWidget({ data, isReadOnly }: Props) {
  const { readOnly, fieldName, fieldValue, exportValue } = data;
  const normalizedFieldName = getFixedFieldName(fieldName);

  const normalizedFieldExportValue = exportValue || '';

  const {
    input: { value, checked, onChange, type, ...rest },
    meta,
  } = useField<string>(`pdfData.${normalizedFieldName}`, {
    initialValue: fieldValue,
    format: v => v, // https://final-form.org/docs/react-final-form/types/FieldProps#format
    parse: v => v, // https://final-form.org/docs/react-final-form/types/FieldProps#parse
  });

  useReinitializeOnChange(normalizedFieldName, fieldValue);

  const isChecked = meta.pristine
    ? fieldValue === normalizedFieldExportValue
    : value === normalizedFieldExportValue;

  const handleChange = () => {
    onChange(isChecked ? 'Off' : normalizedFieldExportValue);
  };

  return (
    <Control
      type="checkbox"
      disabled={readOnly || isReadOnly}
      checked={isChecked}
      onChange={handleChange}
      {...rest}
    />
  );
}
