import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { PaginationOptions } from 'api/types';
import { mapFormData } from 'helpers/formData';

export interface ClientTodoListTasksTaskPostOptions {
  content?: string;
}

export function clientTodoListTasksApi(path: string) {
  return {
    path,
    GET: (params: PaginationOptions = {}) => {
      const { pageSize, pageNumber, ...rest } = params;

      return api.get<JsonApiResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...rest,
        },
      });
    },
    POST: (params: ClientTodoListTasksTaskPostOptions) =>
      api.post<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
