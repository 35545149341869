import { guestClientMeetingRoomsApi } from './clientMeetingRooms';
import { guestSyncTokenApi } from './guestSyncToken';
import { guestTwilioTokenApi } from './guestTwilioToken';
import { guestSignApi } from './sign';

const apiPath = '/api/v1/guests';

function guestsApi(path: string) {
  return {
    path,
    clientMeetingRooms: () => guestClientMeetingRoomsApi(`${path}/client-meeting-rooms`),
    guestSyncToken: () => guestSyncTokenApi(`${path}/guest-sync-token`),
    guestTwilioToken: () => guestTwilioTokenApi(`${path}/guest-twilio-token`),
    sign: () => guestSignApi(`${path}/sign`),
  };
}

function guests() {
  return guestsApi(apiPath);
}

export { guests };
