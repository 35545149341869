import React, { useLayoutEffect, useRef } from 'react';
import { lighten, rem } from 'polished';
import styled, { css } from 'styled-components';
import type { LocalVideoTrack, RemoteVideoTrack } from 'twilio-video';

import { VIDEO_TRACK_NAME } from 'api/twilio/twilioVideo';
import { flexCenter } from 'helpers/styles/mixins';
import { Avatar, AvatarSize } from 'components/Avatar';
import GenericLoader from 'components/GenericLoader/GenericLoader';
import { useParticipantData } from 'components/VideoChat/useParticipantData';

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const RelativeWrapper = styled.div`
  position: relative;
`;

const AvatarWrapper = styled.div<{ isDimmed: boolean }>`
  width: 100%;
  padding: ${rem(10)};
  ${({ isDimmed }) => isDimmed && 'opacity: 0.5'};
`;

const AvatarName = styled.div<{ avatarSize: AvatarSize }>`
  ${({ avatarSize, theme }) => {
    const isAvatarSmall = [AvatarSize.S, AvatarSize.M].includes(avatarSize);

    return css`
      overflow: hidden;
      margin-top: ${rem(isAvatarSmall ? 8 : 16)};
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: ${isAvatarSmall ? theme.fontSizes.dbSmallMd : theme.fontSizes.smallMd};
      color: ${theme.colors.textLight};
    `;
  }};
`;

const LoaderWrapper = styled.div`
  ${flexCenter()};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const PlaceholderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => lighten(0.15, theme.colors.black)};
`;

const Video = styled.video`
  position: relative;
  height: 100%;
  width: 100%;

  &[data-name='${VIDEO_TRACK_NAME}'] {
    display: block;
    object-fit: contain;
    margin: auto;
  }
`;

interface Props {
  participantIdentity: string;
  track: LocalVideoTrack | RemoteVideoTrack | null | undefined;
  avatarSize?: AvatarSize;
}

export default function VideoTrack({
  participantIdentity,
  track,
  avatarSize = AvatarSize.M,
}: Props) {
  const participant = useParticipantData(participantIdentity);
  const videoMediaElement = useRef<HTMLVideoElement>(null);

  useLayoutEffect(() => {
    if (track?.attach && videoMediaElement.current) {
      track.attach(videoMediaElement.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track, track?.mediaStreamTrack.getSettings().deviceId]);

  return (
    <Wrapper>
      {track ? (
        <Video
          key={track?.mediaStreamTrack.id}
          autoPlay
          muted
          ref={videoMediaElement}
          data-name={track.name}
        />
      ) : null}
      {(!track || !track.isEnabled || !track.isStarted) && (
        <PlaceholderWrapper>
          <AvatarWrapper isDimmed={Boolean(!track?.isStarted || !track?.isEnabled)}>
            <RelativeWrapper>
              <Avatar size={avatarSize} src={participant?.avatar} alt={participant?.fullName} />

              <LoaderWrapper>
                <GenericLoader
                  isVisible={Boolean(!track?.isStarted && track?.isEnabled)}
                  size={avatarSize}
                />
              </LoaderWrapper>
            </RelativeWrapper>

            {participant && <AvatarName avatarSize={avatarSize}>{participant.fullName}</AvatarName>}
          </AvatarWrapper>
        </PlaceholderWrapper>
      )}
    </Wrapper>
  );
}
