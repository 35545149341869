import React, { HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { rem, size } from 'polished';
import styled from 'styled-components';

import { useGetCurrentUser } from 'store/users/selectors';
import { BasicTooltip } from 'components/GenericTooltip';
import IconBlurbackground from 'components/Icons/IconBlurbackground';

import { Button } from '../VideoChatToolbarButton/VideoChatToolbarButton';
import BackgroundFilterPopup from './BackgroundFilterPopup';

interface ButtonProps {
  readonly iconColor?: string;
  readonly pressedIconColor?: string;
}

export enum Filter {
  VirtualBackground = 'virtualBackground',
  BackgroundBlur = 'blurBackground',
  None = 'noEffect',
}

const Container = styled.div`
  position: relative;
`;

const iconSize = rem(30);

const ButtonIcon = styled.i`
  ${size(iconSize)};
  display: block;
`;

interface Props extends HTMLAttributes<HTMLButtonElement>, ButtonProps {
  tooltipText?: React.ReactNode;
  isVirtualBackgroundEnabled: boolean;
  isBackgroundBlurEnabled: boolean;
  removeAllVideoEffect: () => void;
  enableBackgroundBlur: () => void;
  enableVirtualBackground: () => void;
}

function BackgroundFiltersButton({
  tooltipText,
  enableBackgroundBlur,
  enableVirtualBackground,
  removeAllVideoEffect,
  isVirtualBackgroundEnabled,
  isBackgroundBlurEnabled,
  ...rest
}: Props) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const currentUser = useGetCurrentUser();

  const closePopup = () => setOpen(false);
  const togglePopup = () => setOpen(prev => !prev);

  const selectedFilter = useMemo((): Filter => {
    if (isVirtualBackgroundEnabled) return Filter.VirtualBackground;
    if (isBackgroundBlurEnabled) return Filter.BackgroundBlur;
    return Filter.None;
  }, [isBackgroundBlurEnabled, isVirtualBackgroundEnabled]);

  const filterSelectHandler = (filter: Filter): void => {
    switch (filter) {
      case Filter.BackgroundBlur:
        return enableBackgroundBlur();
      case Filter.VirtualBackground:
        return enableVirtualBackground();
      case Filter.None:
        return removeAllVideoEffect();
      default:
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', closePopup);
    return () => document.removeEventListener('scroll', closePopup);
  }, []);

  useEffect(() => {
    if (selectedFilter === Filter.VirtualBackground && !currentUser?.virtualBackground) {
      removeAllVideoEffect();
    }
  }, [currentUser?.virtualBackground, selectedFilter, removeAllVideoEffect]);

  useOnclickOutside(closePopup, { refs: [buttonRef, popupRef] });

  return (
    <Container>
      <BasicTooltip tooltipMessage={tooltipText} showDelay={600} disabled={open}>
        <Button
          ref={buttonRef}
          onClick={togglePopup}
          aria-pressed={!isVirtualBackgroundEnabled && !isBackgroundBlurEnabled}
          {...rest}
        >
          <ButtonIcon as={IconBlurbackground} />
        </Button>
      </BasicTooltip>

      {open && (
        <BackgroundFilterPopup
          ref={popupRef}
          buttonRef={buttonRef}
          selectHandler={filterSelectHandler}
          selectedFilter={selectedFilter}
        />
      )}
    </Container>
  );
}

export default BackgroundFiltersButton;
