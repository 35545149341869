import styled from 'styled-components';

export const bannersWrapperId = 'banners-wrapper';

const BannersWrapper = styled.div.attrs({ id: bannersWrapperId })`
  position: fixed;
  top: 0;
  left: 0;
  /* It has to be bigger value than in .introjs-showElement intro.css and AdviserFloatingCard  */
  z-index: 99999999;
  width: 100%;
`;

export default BannersWrapper;
