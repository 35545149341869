import React, { useMemo, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { FormattedMessage } from 'react-intl';
import { rem, rgba, size } from 'polished';
import styled, { css } from 'styled-components';

import { misc } from 'helpers/styles/constants';
import { flexCenter } from 'helpers/styles/mixins';
import { isHttps } from 'helpers/utils';
import { useGetPracticeSettings } from 'store/settings/selectors';
import GenericLoader from 'components/GenericLoader/GenericLoader';
import { useMessagesContext } from 'components/VideoChat/MessagesContext';
import { RoomConnectionState } from 'components/VideoChat/RoomConnectionState';
import type { VideoLayout } from 'components/VideoChat/types';
import { useUpdateOnEvents } from 'components/VideoChat/useUpdateOnEvents';
import { isVideoSupported } from 'components/VideoChat/utils';
import { useVideoContext } from 'components/VideoChat/VideoContext';

import { RoomComponent } from '../Room/Room';
import { VideoChatOverlay } from '../VideoChatOverlay/VideoChatOverlay';
import VideoChatToolbar from '../VideoChatToolbar/VideoChatToolbar';
import ChatWindowContext from './ChatWindowContext';
import { InactivityChecker } from './InactivityChecker';

const CenteredPanel = styled.div`
  ${flexCenter()};
  height: 100%;
`;

const MediaWrapper = styled.div<{
  isExpanded?: boolean;
  isFloating?: boolean;
  hasShadow?: boolean;
}>`
  position: relative;
  margin: auto;
  background-color: ${({ theme }) => theme.colors.greyLighten};
  transition: box-shadow 0.3s ease-in-out;

  ${({ isFloating }) => isFloating && size(rem(225), rem(300))};
  ${({ isExpanded }) => isExpanded && size('100%')};
  ${({ isExpanded, isFloating }) =>
    !isExpanded &&
    !isFloating &&
    css`
      padding-top: 75%;
    `};
  ${({ hasShadow }) => hasShadow && `box-shadow: 0 0 ${rem(15)} ${rgba(0, 0, 0, 0.3)};`};
`;

const MediaWrapperInner = styled.div<{ backgroundImage?: string; isExpanded?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  ${size('100%')};

  ${({ backgroundImage, isExpanded }) =>
    backgroundImage &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: ${isExpanded ? '25vh 25vw' : '25%'};
        background-image: url('${backgroundImage}');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-origin: content-box;
        opacity: 0.2;
      }
    `};
`;

const Wrapper = styled.div<{
  isExpanded?: boolean;
  isFloating?: boolean;
  windowZIndex?: number;
}>`
  ${({ isFloating, windowZIndex }) =>
    isFloating &&
    css`
      position: fixed;
      top: ${rem(70)};
      right: ${rem(70)};
      padding-top: ${rem(30)};
      z-index: ${windowZIndex};
    `};

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      position: fixed;
      left: 0;
      top: 0;
      ${size('100%')};
      z-index: ${misc.videoChatZIndex};
      transform: none !important; // needed to override draggable styles
    `};

  ${({ isExpanded, isFloating }) =>
    !isExpanded &&
    !isFloating &&
    css`
      max-width: ${rem(900)};
      margin: auto;
    `};
`;

const EVENTS = ['recordingStarted', 'recordingStopped'];

interface ChatWindowProps {
  onJoin?: () => void;
  onLeave: () => void;
  onToggleFullscreen: () => void;
  onToggleSticky: () => void;
  onToggleLayout: () => void;
  layout: VideoLayout;
  windowZIndex?: number;
  isUISmall: boolean;
  hasShadow?: boolean;
  isExpanded?: boolean;
  isSticky?: boolean;
  isStickable?: boolean;
  isFloating?: boolean;
}

export function ChatWindow({
  windowZIndex,
  isExpanded,
  isSticky,
  isStickable,
  isFloating,
  hasShadow,
  isUISmall,
  layout,
  onJoin,
  onLeave,
  onToggleFullscreen,
  onToggleSticky,
  onToggleLayout,
  ...rest // needed for draggable
}: ChatWindowProps) {
  const { logo } = useGetPracticeSettings();
  const isFloatingNotExpanded = Boolean(isFloating && !isExpanded);
  const { room, connectionState } = useVideoContext();
  const { areMessagesVisible } = useMessagesContext();
  const [isIdle, setIsIdle] = useState(false);
  const isUIHidden = Boolean(isIdle && room && connectionState === RoomConnectionState.Connected);

  const windowContextValue = useMemo(() => ({ isUISmall, isUIHidden }), [isUISmall, isUIHidden]);

  useUpdateOnEvents(room, EVENTS);

  return (
    <ChatWindowContext.Provider value={windowContextValue}>
      <Wrapper
        isFloating={isFloatingNotExpanded}
        isExpanded={isExpanded}
        windowZIndex={windowZIndex}
        {...rest}
      >
        <MediaWrapper
          isFloating={isFloatingNotExpanded}
          isExpanded={isExpanded}
          hasShadow={isFloatingNotExpanded || hasShadow}
        >
          <MediaWrapperInner backgroundImage={logo} isExpanded={isExpanded}>
            {connectionState === RoomConnectionState.Connected && room ? (
              <>
                <RoomComponent room={room} layout={layout} />

                <IdleTimer
                  element={document}
                  onIdle={() => setIsIdle(true)}
                  onActive={() => setIsIdle(false)}
                  debounce={250}
                  timeout={3 * 1000}
                />
              </>
            ) : (
              <CenteredPanel>
                {isVideoSupported() ? (
                  <GenericLoader isVisible={connectionState === RoomConnectionState.Connecting} />
                ) : (
                  <FormattedMessage
                    id={
                      isHttps()
                        ? 'adviceRooms.adviceRoom.notSupportedBrowser'
                        : 'adviceRooms.adviceRoom.secureConnectionNeeded'
                    }
                  />
                )}
              </CenteredPanel>
            )}

            <VideoChatOverlay
              onToggleFullscreen={onToggleFullscreen}
              onToggleSticky={onToggleSticky}
              onToggleLayout={onToggleLayout}
              layout={layout}
              isUISmall={isUISmall}
              isFloating={isFloating}
              isRecording={room?.isRecording}
              isSticky={isSticky}
              isStickable={isStickable}
              isExpanded={isExpanded}
              areMessagesVisible={areMessagesVisible}
            />

            <InactivityChecker />
          </MediaWrapperInner>
        </MediaWrapper>

        {isVideoSupported() && (
          <VideoChatToolbar
            isHidden={isUIHidden && isExpanded}
            isFloating={isFloating}
            isExpanded={isExpanded}
            hasShadow={hasShadow}
            isSmall={isUISmall}
            onJoin={onJoin}
            onLeave={onLeave}
          />
        )}
      </Wrapper>
    </ChatWindowContext.Provider>
  );
}
