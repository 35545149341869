import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { rem } from 'polished';

import { required } from 'helpers/validators';
import { useGetPracticeSettings } from 'store/settings/selectors';
import FieldCheckbox from 'components/FieldCheckbox/FieldCheckbox';
import GenericPdf from 'components/GenericPdf/GenericPdf';
import { GenericTitle, TitleMargin } from 'components/GenericTitle/GenericTitle';
import GenericTitleSummary from 'components/GenericTitleSummary/GenericTitleSummary';

interface Props {
  checkboxName: string;
}

const Disclosure: FC<Props> = ({ checkboxName }) => {
  const [isPdfLoaded, setIsPdfLoaded] = useState(false);
  const practiceSettings = useGetPracticeSettings();
  const checkboxRef = useRef<HTMLInputElement>(null);

  const handlePdfLoad = useCallback(() => setIsPdfLoaded(true), []);

  useEffect(() => {
    if (isPdfLoaded && checkboxRef.current) {
      checkboxRef.current.focus();
    }
  }, [isPdfLoaded]);

  return (
    <>
      <GenericTitle marginBottom={TitleMargin.Sm} isMedium>
        <FormattedMessage id="onboarding.stepDisclosure.title" />
      </GenericTitle>

      <GenericTitleSummary hasMarginBottomLg>
        <p>
          <FormattedMessage id="onboarding.stepDisclosure.summary1" />
        </p>
        <p>
          <FormattedMessage id="onboarding.stepDisclosure.summary2" />
        </p>
      </GenericTitleSummary>

      {practiceSettings.url && (
        <GenericPdf url={practiceSettings.url} onPdfLoad={handlePdfLoad} maxHeight={rem(440)} />
      )}

      <FieldCheckbox
        isBig
        validate={required}
        isDisabled={!isPdfLoaded}
        checkboxRef={checkboxRef}
        name={checkboxName}
        label={<FormattedMessage id="onboarding.stepDisclosure.checkboxLabel" />}
      />
    </>
  );
};

export default Disclosure;
