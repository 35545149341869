import React, { GetDerivedStateFromProps, PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactResizeDetector from 'react-resize-detector';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { isFunction, merge, throttle } from 'lodash';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { KeyCode } from 'constants/keys';
import { misc } from 'helpers/styles/constants';
import { BasicTooltip } from 'components/GenericTooltip';
import IconArrows from 'components/Icons/Chat/IconDiagonalArrows';
import IconArrowsInner from 'components/Icons/Chat/IconDiagonalArrowsInner';
import IconArrowLeftsm from 'components/Icons/IconArrowLeftsm';
import IconArrowRightsm from 'components/Icons/IconArrowRightsm';
import IconPrint from 'components/Icons/IconPrint';
import IconRotateDocument from 'components/Icons/IconRotateDocument';
import { Desktop } from 'components/Responsive/Responsive';

import AnnotationLayer from './AnnotationLayer';
import { PasswordModal } from './components/PasswordModal';
import PdfCircleButton from './components/PdfCircleButton';
import Pdf, { Props as PdfProps, RenderProps as PdfRenderProps } from './components/PdfLazy';
import { IconStyled } from './components/styled';
import { FileDownloadButton } from './FileDownloadButton';
import GenericPdfContextComponent, { GenericPdfContext } from './GenericPdfContext';
import Pagination from './Pagination';
import ToolbarSection from './ToolbarSection';
import {
  CenteredLoader,
  DocumentWrapper,
  DocumentWrapperProps,
  PaginationWrapper,
  PaginationWrapperRow,
} from './Wrappers';
import ZoomUtils from './ZoomUtils';

interface WrapperProps {
  hasMaxHeight?: boolean;
  isFullScreenEnabled: boolean;
}

const IconRotateCounterClockwise = styled(IconRotateDocument)`
  transform: scaleX(-1);
`;

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  outline: none;
  ${({ hasMaxHeight }) => hasMaxHeight && 'max-height: 100%'};
  ${({ isFullScreenEnabled }) =>
    isFullScreenEnabled &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      z-index: ${misc.modalZIndex};
      height: 100%;
      width: 100%;
    `};
`;

const DocumentInnerWrapper = styled.div`
  position: relative;
  overflow: auto;
`;

const StyledPdf = styled(Pdf)<{ zoom: number }>`
  display: block;
  margin: auto;
  width: ${({ zoom }) => zoom}%;
`;

const ChildrenWrapper = styled.div<{ zoom: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ zoom }) => zoom}%;
  margin-left: ${({ zoom }) => (zoom < 100 ? (100 - zoom) / 2 : 0)}%;
  height: 100%;
`;

const DownloadLink = styled(FileDownloadButton)`
  margin-left: auto;
`;

const Error = styled.div`
  margin: ${rem(30)};
  text-align: center;
`;

const ProgressBar = styled.div<{ progress: number }>`
  position: absolute;
  top: 0;
  left: 0;
  display: ${({ progress }) => (progress <= 0 || progress >= 100 ? 'none' : 'block')};
  width: 100%;
  height: ${rem(3)};

  &::after {
    content: '';
    display: block;
    height: 100%;
    width: ${({ progress }) => progress}%;
    background-color: ${({ theme }) => theme.colors.main};
    transition: width 0.1s ease;
  }
`;

interface DocumentParams {
  pageNumber: number;
  rotation: number;
}

interface RenderProps
  extends Omit<PdfRenderProps, 'canvas' | 'progress'>,
    Pick<State, 'isChangingPage'>,
    GenericPdfContext {
  isLoading: boolean;
}

export interface Props extends DocumentWrapperProps {
  pdfElementId?: string;
  url: PdfProps['file'];
  page?: PdfProps['page'];
  rotation?: PdfProps['rotation'];
  documentId?: number;
  onPdfLoad?: (page: number) => void;
  onDocumentFetchFail?: PdfProps['onDocumentFetchFail'];
  onGetPdfPassword?: (password: string) => void;
  onChangeParams?: (params: DocumentParams) => void;
  isPaginationDisabled?: boolean;
  isRotationDisabled?: boolean;
  className?: string;
  children?: ((renderProps: RenderProps) => React.ReactNode) | React.ReactNode;
  additionalControls?: React.ReactNode;
  hasMaxHeight?: boolean;
  isDownloadLinkHidden?: boolean;
  isReadOnly?: boolean;
  isEditable?: boolean;
  bottomPaginationHidden?: boolean;
}

interface State {
  page: number;
  pageCount: number;
  zoom: number;
  rotation: number;
  isChangingPage: boolean;
  pdfWidth: number;
  pdfHeight: number;
  pdfScrollTop: number;
  isFullScreenEnabled: boolean;
  errorOccurred: boolean | string;
  showLeftArrow: boolean;
  showRightArrow: boolean;
  password: string | undefined;
  isPasswordProtected: boolean;
}

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  color: #808080;
  transform: translateY(-50%);
`;

const LeftArrow = styled(ArrowButton)`
  left: 9px;
`;

const RightArrow = styled(ArrowButton)`
  right: 9px;
`;

class GenericPdf extends PureComponent<Props, State> {
  private documentWrapperRef = React.createRef<HTMLDivElement>();

  private paginationWrapperRowRef = React.createRef<HTMLDivElement>();

  private childrenWrapperRef = React.createRef<HTMLDivElement>();

  public state: State = {
    page: 1,
    pageCount: 0,
    zoom: 100,
    rotation: 0,
    isChangingPage: false,
    pdfWidth: 0,
    pdfHeight: 0,
    pdfScrollTop: 0,
    isFullScreenEnabled: false,
    password: undefined,
    isPasswordProtected: false,
    errorOccurred: false,
    showLeftArrow: false,
    showRightArrow: false,
  };

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.url !== this.props.url) {
      this.updatePasswordComponent(prevProps);
    }
  }

  private updatePasswordComponent = (prevProps: Props) => {
    this.setState(prevState => {
      const isSameDocument =
        this.props.documentId && prevProps.documentId === this.props.documentId;

      return {
        ...prevState,
        errorOccurred: false,
        isPasswordProtected: false,
        page: isSameDocument ? prevState.page : 1,
        password: isSameDocument ? prevState.password : undefined,
      };
    });
  };

  private handleLeftArrowClick = () => {
    this.scrollPaginationRow(-1);
  };

  private handleRightArrowClick = () => {
    this.scrollPaginationRow(1);
  };

  private scrollPaginationRow = (direction: number) => {
    const wrapperRowRef = this.paginationWrapperRowRef.current;
    if (wrapperRowRef) {
      const scrollAmount = direction * 50;
      wrapperRowRef.scrollBy({ left: scrollAmount, behavior: 'smooth' });

      const isOverflowing = wrapperRowRef.scrollWidth > wrapperRowRef.clientWidth;
      const newScrollPosition = wrapperRowRef.scrollLeft + scrollAmount;

      this.setState({
        showLeftArrow: isOverflowing && newScrollPosition > 0,
        showRightArrow:
          isOverflowing &&
          newScrollPosition < wrapperRowRef.scrollWidth - wrapperRowRef.clientWidth,
      });
    }
  };

  private setScrollTopThrottled = throttle((value: number) => {
    this.setState({ pdfScrollTop: value });
  }, 100);

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (
    nextProps,
    prevState
  ) => {
    const { page, rotation } = nextProps;

    let result = null;

    if (page !== undefined && page !== prevState.page) {
      result = merge({}, result, { page });
    }

    if (rotation !== undefined && rotation !== prevState.rotation) {
      result = merge({}, result, { rotation });
    }

    return result;
  };

  private handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const { page } = this.state;
    const { isPaginationDisabled } = this.props;

    if (event.keyCode === KeyCode.Escape) {
      this.setState({ isFullScreenEnabled: false });
    }

    if (!isPaginationDisabled) {
      if (event.keyCode === KeyCode.ArrowLeft) {
        this.handleChangePage(page - 1);
      }

      if (event.keyCode === KeyCode.ArrowRight) {
        this.handleChangePage(page + 1);
      }
    }
  };

  private handleDocumentLoad = (pageCount: number, page: number) => {
    const { onPdfLoad } = this.props;

    this.setState({
      page,
      pageCount,
      isChangingPage: false,
      errorOccurred: false,
    });

    if (onPdfLoad) {
      onPdfLoad(page);
    }
  };

  private handleDocumentError = (reason: { name: string; message: string }) => {
    const { onDocumentFetchFail } = this.props;

    this.setState({ errorOccurred: true });
    if (
      reason.name === 'PasswordException' ||
      reason.message === 'No password given' ||
      reason.message === 'Incorrect Password'
    ) {
      if (this.state.isPasswordProtected && this.state.password !== undefined) {
        this.setState({ errorOccurred: reason.message });
      } else {
        this.setState({ isPasswordProtected: true });
      }
    }

    if (onDocumentFetchFail) {
      onDocumentFetchFail(reason);
    }
  };

  private handleParamsChange = () => {
    const { onChangeParams } = this.props;
    const { page, rotation } = this.state;

    if (onChangeParams) {
      onChangeParams({ pageNumber: page, rotation });
    }
  };

  private handleChangePage = (page: number) => {
    const { pageCount } = this.state;

    if (page > pageCount || page < 1) {
      return;
    }

    this.setState({ page, isChangingPage: true }, this.handleParamsChange);
  };

  private handleChangeZoom = (zoom: number) => this.setState({ zoom });

  private rotateCounterClockwise = () => {
    this.setState(
      prevState => ({
        rotation: prevState.rotation === 0 ? 270 : prevState.rotation - 90,
      }),
      this.handleParamsChange
    );
  };

  private rotateClockwise = () => {
    this.setState(
      prevState => ({
        rotation: prevState.rotation < 270 ? prevState.rotation + 90 : 0,
      }),
      this.handleParamsChange
    );
  };

  private handleResize = (width: number, height: number) => {
    this.setState({ pdfWidth: width, pdfHeight: height });
    this.scrollPaginationRow(0);
  };

  private setScrollTop = (event: React.UIEvent<HTMLDivElement>) => {
    this.setScrollTopThrottled(event.currentTarget.scrollTop);
    this.scrollPaginationRow(0);
  };

  private toggleFullScreen = () =>
    this.setState(prevState => ({ isFullScreenEnabled: !prevState.isFullScreenEnabled }));

  private updatePassword = (value: string) => {
    if (value === '') {
      this.setState({ errorOccurred: 'No password given' });
      return;
    }

    if (this.state.password === value) {
      this.setState({ errorOccurred: 'Incorrect Password' });
      return;
    }

    const { onGetPdfPassword } = this.props;

    if (onGetPdfPassword) {
      onGetPdfPassword(value);
    }

    this.setState({ password: value, errorOccurred: false });
  };

  public render() {
    const {
      pageCount,
      page,
      rotation,
      zoom,
      pdfHeight,
      pdfWidth,
      pdfScrollTop,
      isChangingPage,
      isFullScreenEnabled,
      password,
      isPasswordProtected,
      errorOccurred,
      showLeftArrow,
      showRightArrow,
    } = this.state;

    const {
      pdfElementId,
      url,
      maxHeight,
      className,
      children,
      additionalControls,
      isDownloadLinkHidden,
      isReadOnly,
      isEditable = false,
      isPaginationDisabled = false,
      isRotationDisabled = false,
      hasMaxHeight = true,
      bottomPaginationHidden = false,
    } = this.props;

    return (
      <Wrapper
        tabIndex={-1}
        className={className}
        hasMaxHeight={hasMaxHeight}
        isFullScreenEnabled={isFullScreenEnabled}
        onKeyDown={this.handleKeyDown}
      >
        <StyledPdf
          file={url}
          rotation={rotation}
          scale={3}
          password={password}
          onPageRenderSuccess={this.handleDocumentLoad}
          onDocumentFetchFail={this.handleDocumentError}
          page={page}
          zoom={zoom}
          isEditable={isEditable}
          render={({ canvas, progress, ...pageRenderProps }) => {
            const { pdfPage } = pageRenderProps;

            const isLoading = progress < 100;

            const contextData = {
              isEditable,
              rotation: pdfPage ? pdfPage.rotate + rotation : 0,
              zoom,
              pageNumber: page,
              pdfHeight,
              pdfWidth,
              pdfScrollTop,
            };

            return (
              <GenericPdfContextComponent.Provider value={contextData}>
                {!isLoading && !errorOccurred && !isPaginationDisabled && (
                  <PaginationWrapper>
                    <PaginationWrapperRow ref={this.paginationWrapperRowRef}>
                      {showLeftArrow && (
                        <LeftArrow onClick={this.handleLeftArrowClick}>
                          {<IconStyled iconSize={25} as={IconArrowLeftsm} />}
                        </LeftArrow>
                      )}

                      <Pagination
                        onChange={this.handleChangePage}
                        pageNumber={page}
                        totalPages={pageCount}
                        isDisabled={isPaginationDisabled || isChangingPage}
                      />

                      <ZoomUtils
                        zoom={zoom}
                        onChange={this.handleChangeZoom}
                        wrapperRef={this.documentWrapperRef.current}
                        documentRef={this.childrenWrapperRef.current}
                      />

                      <ToolbarSection
                        bottomRow={
                          <BasicTooltip
                            zIndex={misc.modalZIndex + 1}
                            tooltipMessage={
                              <FormattedMessage
                                id={
                                  isFullScreenEnabled
                                    ? 'documentViewer.tooltips.exitFullScreen'
                                    : 'documentViewer.tooltips.fullScreen'
                                }
                              />
                            }
                          >
                            <PdfCircleButton onClick={this.toggleFullScreen}>
                              <IconStyled
                                iconSize={20}
                                as={isFullScreenEnabled ? IconArrowsInner : IconArrows}
                              />
                            </PdfCircleButton>
                          </BasicTooltip>
                        }
                      />

                      <Desktop>
                        <ToolbarSection
                          bottomRow={
                            <>
                              <BasicTooltip
                                tooltipMessage={
                                  <FormattedMessage id="documentViewer.tooltips.rotateLeft" />
                                }
                                zIndex={misc.modalZIndex + 1}
                              >
                                <PdfCircleButton
                                  onClick={this.rotateCounterClockwise}
                                  disabled={isRotationDisabled}
                                >
                                  <IconStyled iconSize={24} as={IconRotateCounterClockwise} />
                                </PdfCircleButton>
                              </BasicTooltip>
                              <BasicTooltip
                                tooltipMessage={
                                  <FormattedMessage id="documentViewer.tooltips.rotateRight" />
                                }
                                zIndex={misc.modalZIndex + 1}
                              >
                                <PdfCircleButton
                                  onClick={this.rotateClockwise}
                                  disabled={isRotationDisabled}
                                >
                                  <IconStyled iconSize={24} as={IconRotateDocument} />
                                </PdfCircleButton>
                              </BasicTooltip>
                              <ReactToPrint content={() => this.documentWrapperRef.current}>
                                <PrintContextConsumer>
                                  {({ handlePrint }) => (
                                    <BasicTooltip
                                      tooltipMessage={
                                        // eslint-disable-next-line max-len
                                        <FormattedMessage id="documentViewer.tooltips.printScreen" />
                                      }
                                      zIndex={misc.modalZIndex + 1}
                                    >
                                      <PdfCircleButton onClick={handlePrint}>
                                        <IconStyled iconSize={24} as={IconPrint} />
                                      </PdfCircleButton>
                                    </BasicTooltip>
                                  )}
                                </PrintContextConsumer>
                              </ReactToPrint>
                            </>
                          }
                        />
                      </Desktop>

                      {!isDownloadLinkHidden && <DownloadLink url={url} />}
                      {showRightArrow && (
                        <RightArrow onClick={this.handleRightArrowClick}>
                          {<IconStyled iconSize={25} as={IconArrowRightsm} />}
                        </RightArrow>
                      )}
                    </PaginationWrapperRow>
                  </PaginationWrapper>
                )}

                {!isLoading && !errorOccurred && additionalControls && (
                  <PaginationWrapper>{additionalControls}</PaginationWrapper>
                )}

                <DocumentWrapper
                  minHeight={isPasswordProtected && errorOccurred !== false ? rem(400) : undefined}
                  maxHeight={maxHeight}
                  onScroll={this.setScrollTop}
                  ref={this.documentWrapperRef}
                >
                  {!isLoading && isPasswordProtected && errorOccurred !== false && (
                    <PasswordModal
                      updatePassword={this.updatePassword}
                      error={this.state.errorOccurred}
                    />
                  )}

                  {!isLoading && !errorOccurred && (
                    <DocumentInnerWrapper id={pdfElementId}>
                      <ChildrenWrapper zoom={zoom} ref={this.childrenWrapperRef}>
                        <ReactResizeDetector
                          handleWidth
                          handleHeight
                          onResize={this.handleResize}
                        />

                        {pdfPage && !isChangingPage && (
                          <AnnotationLayer pdfPage={pdfPage} isReadOnly={isReadOnly} />
                        )}

                        {/* eslint-disable-next-line no-nested-ternary */}
                        {!isChangingPage && children
                          ? isFunction(children)
                            ? children({
                                ...pageRenderProps,
                                ...contextData,
                                isLoading,
                                isChangingPage,
                              })
                            : children
                          : null}
                      </ChildrenWrapper>

                      {canvas}
                    </DocumentInnerWrapper>
                  )}

                  {errorOccurred && !isPasswordProtected && (
                    <Error>
                      <FormattedMessage id="shared.pdfError" />
                    </Error>
                  )}

                  <ProgressBar progress={progress} />

                  <CenteredLoader isVisible={isLoading && !errorOccurred} isInlineFlex />
                </DocumentWrapper>

                {!isLoading && !errorOccurred && !bottomPaginationHidden && (
                  <PaginationWrapper>
                    <PaginationWrapperRow>
                      <Pagination
                        onChange={this.handleChangePage}
                        pageNumber={page}
                        totalPages={pageCount}
                        isDisabled={isPaginationDisabled || isChangingPage}
                      />
                    </PaginationWrapperRow>
                  </PaginationWrapper>
                )}
              </GenericPdfContextComponent.Provider>
            );
          }}
        />
      </Wrapper>
    );
  }
}

export default GenericPdf;
