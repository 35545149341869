import api from 'api/apiInstance';

export interface ClientMetaClientIdentificationsGetResponse {
  client_identification_locked?: boolean;
}

export function clientMetaClientIdentificationsApi(path: string) {
  return {
    path,
    GET: () => api.get<ClientMetaClientIdentificationsGetResponse>(path),
  };
}
