import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

export interface UserUpdateTourGuidePatchOptions {
  isTourGuideCompleted: boolean;
}

export function userUpdateTourGuideApi(path: string) {
  return {
    path,
    PATCH: ({ isTourGuideCompleted }: UserUpdateTourGuidePatchOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData({ isTourGuideCompleted }),
        },
      }),
  };
}
