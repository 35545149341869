import { ProofType } from 'types/entities/proofType';

export default {
  client: {
    title: `My Identification`,
    subtitle: `In order to provide advice to you, we may be legally required to periodically verify your identity. We may request that you provide us with important identity verification information below. In addition to providing us with digital copies of your identification documents, we may also require you to provide our office with physical copies.`,
    tileDescription: `Manage your important personal identification information.`,
  },
  adviser: {
    title: `Client Identification`,
    subtitle: `Below are {clientName}’s provided identification document. If necessary, you can add documents that the client provided directly. Please also note that you can request your client to also provide you with physical copies of all documentation.`,
  },
  practiceDirector: {
    subtitle: `Below are {clientName}’s provided identification document.`,
  },
  details: {
    title: `Personal details`,
    subtitle: `Please confirm your personal details.`,
    nationality: `Nationality {number}`,
    residency: `Residency {number}`,
    changesSavedMessage: `Details have been successfully saved`,
  },
  identityDocuments: {
    title: `Documents`,
    description: `Please provide documents as described below.`,
    governmentIssuedTitle: `Government-issued document with a photograph`,
    governmentIssuedDescription: `One document from the following, or equivalent:
      <ul>
        <li>Valid passport</li>
        <li>Valid driving license</li>
        <li>National identity card</li>
      </ul>`,
    otherTitle: `Other identity documents`,
    otherDescription: `Documents from the following, or equivalent:
      <ul>
        <li>Recent utility bill</li>
        <li>Recent mortgage statement</li>
        <li>Recent bank statement</li>
        <li>Recent tax statement or demand</li>
        <li>Letter from your employer confirming residential address</li>
        <li>Letter from a nursing / care home confirming residence</li>
      </ul>`,
    updated: `Updated: {date} by {user}`,
    documentRemovedMessage: `Document has been successfully removed`,
    documentUploadedMessage: `Document has been successfully uploaded`,
  },
  lock: {
    lock: `Lock`,
    unlock: `Unlock`,
    sectionLocked: `This section is locked.`,
    sectionUnlocked: `This section is unlocked.`,
    sectionLockedAdviser: `This section is currently locked to the client and cannot be edited.`,
    sectionUnlockedAdviser: `This section is currently unlocked to the client and can be edited.`,
    sectionLockedMessage: `Section has been successfully locked`,
    sectionUnlockedMessage: `Section has been successfully unlocked`,
  },
  documents: {
    [ProofType.GovernmentDocument]: `Government-issued document with a photograph`,
    [ProofType.OtherDocument]: `Other identity document`,
  },
};
