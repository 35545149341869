import React from 'react';
import { useField, UseFieldConfig } from 'react-final-form';

import { getEmptyError } from 'helpers/validators';
import GenericErrorMsg from 'components/GenericErrorMsg/GenericErrorMsg';
import Margin from 'components/Margin/Margin';

import type { RenderFunction } from './types';

export interface Props<FieldValue, ElementType extends HTMLElement = HTMLElement>
  extends UseFieldConfig<FieldValue> {
  name: string;
  renderLabel?: RenderFunction<FieldValue, ElementType> | null;
  renderControl: RenderFunction<FieldValue, ElementType>;
  renderError?: RenderFunction<FieldValue, ElementType> | null;
}

export function Field<FieldValue, ElementType extends HTMLElement = HTMLElement>({
  name,
  renderLabel,
  renderControl,
  renderError = ({ meta }) => (
    <GenericErrorMsg
      message={meta.error || meta.submitError || getEmptyError()}
      isVisible={meta.showError}
      hasFullWidth
      hasNoMarginTop
    />
  ),
  ...fieldConfig
}: Props<FieldValue, ElementType>) {
  const { input, meta } = useField<FieldValue>(name, fieldConfig);

  const renderProps = {
    input: { ...input, id: name },
    meta: {
      ...meta,
      showError: Boolean((meta.error || meta.submitError) && (meta.touched || meta.modified)),
    },
    label: { id: `${name}-label` },
  };

  return (
    <div data-test={`field-${name}`}>
      {renderLabel && renderLabel(renderProps)}
      <div>{renderControl(renderProps)}</div>
      {renderError && <Margin top={4}>{renderError(renderProps)}</Margin>}
    </div>
  );
}
