import { practiceReportsMonthlyActivityApi } from './monthlyActivity';

const apiPath = '/api/v1/practice-reports';

function practiceReportsApi(path: string) {
  return {
    path,
    monthlyActivity: () => practiceReportsMonthlyActivityApi(`${path}/monthly-activity`),
  };
}

function practiceReports() {
  return practiceReportsApi(apiPath);
}

export { practiceReports };
