import { ProjectionType } from 'constants/projectionTypes';

const projectionTypes: Record<ProjectionType, { title: string; description: string }> = {
  [ProjectionType.Investments]: {
    title: `Investments`,
    description: `Growth scenarios of {lowGrowthPercentage}% (low growth), {mediumGrowthPercentage}% (medium growth) and {highGrowthPercentage}% (high growth). And growth scenarios of {lowGrowthPercentageWithInflation}% (low growth), {mediumGrowthPercentageWithInflation}% (medium growth) and {highGrowthPercentageWithInflation}% (high growth) allowing for {inflationPercentage}% inflation where relevant.`,
  },
  [ProjectionType.Pensions]: {
    title: `Pensions`,
    description: `Growth scenarios of {lowGrowthPercentage}% (low growth), {mediumGrowthPercentage}% (medium growth) and {highGrowthPercentage}% (high growth) and inflation-adjusted growth scenarios of {lowGrowthPercentageWithInflation}% (low growth), {mediumGrowthPercentageWithInflation}% (medium growth) and {highGrowthPercentageWithInflation}% (high growth), allowing for {inflationPercentage}% inflation where relevant.`,
  },
};

export default {
  summaryHeader: `Your investment summary`,
  editHeader: `Edit your investment details`,
  initialInvestment: {
    title: `Initial investment`,
    placeholder: `0`,
    tooltip: `How much can you invest immediately? Your investment can include cash or, potentially, existing investments that you would like to transfer.`,
  },
  duration: {
    title: `Duration`,
    placeholder: `20`,
    tooltip: `When would you envisage withdrawing your wealth? We encourage our clients to invest for a minimum of five years. Many younger clients invest for their retirement in 30+ years.`,
  },
  monthlyContribution: {
    title: `Monthly contribution`,
    placeholder: `0`,
    tooltip: `Many of our clients invest monthly, because regular investments can sometimes be advantageous in helping achieve financial objectives.`,
  },
  currency: {
    title: `Currency`,
  },
  projections: {
    title: `Illustrative returns`,
    intro: `Depending on performance, in <span>{duration} years</span>, an initial investment of {investment} could be worth:`,
    highGrowth: `High growth ({value}%)`,
    mediumGrowth: `Medium growth ({value}%)`,
    lowGrowth: `Low growth ({value}%)`,
    cash: `Cash ({value}%)`,
    highGrowthProjection: `High growth projection`,
    mediumGrowthProjection: `Medium growth projection`,
    lowGrowthProjection: `Low growth projection`,
    cashProjection: `Cash projection`,
    projectionTypes,
    projectionTypeDropdownLabel: `Display illustrative returns for:`,
    description: `
      <p>To generate these investment projections, we have assumed:</p>
      <ul>
        <li>{typeSpecificDescription}</li>
        <li>Annual fees of {annualFeePercentage}% *</li>
        <li>Income is reinvested</li>
        <li>No deductions for taxes, if applicable</li>
        <li>Interest on cash of {cashGrowthPercentage}%</li>
        <li>Annual inflation of {inflationPercentage}% (where applicable)</li>
      </ul>
      <p>* Fees are dependent on your chosen service and your investment type.</p>
      `,
    includeInflation: `Include impact of {inflationPercentage}% inflation`,
    inflationTooltip: `Inflation, or the trend of increasing prices, erodes the value of money over time by reducing money’s purchasing power. Ticking this box shows the effect this has on the potential investment.`,
  },
};
