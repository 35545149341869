import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

export interface ClientChangeClientAdviserPatchOptions {
  adviserId: number;
  postCode?: string;
}

export function clientChangeClientAdviserApi(path: string) {
  return {
    path,
    PATCH: (params: ClientChangeClientAdviserPatchOptions) =>
      api.patch<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      ),
  };
}
