import { practiceSettingsFeatureTilesReorderApi } from './reorder';
import { practiceSettingsFeatureTilesResetApi } from './reset';
import { practiceSettingsFeatureTilesUpdateApi } from './update';

export function practiceSettingsFeatureTilesApi(path: string) {
  return {
    path,
    reorder: () => practiceSettingsFeatureTilesReorderApi(`${path}/reorder`),
    reset: () => practiceSettingsFeatureTilesResetApi(`${path}/reset`),
    update: () => practiceSettingsFeatureTilesUpdateApi(`${path}/update`),
  };
}
