import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { find } from 'lodash';

import Dropdown, { DropdownWidth, Props as DropdownProps } from './Dropdown';

export { DropdownWidth };

export interface Props
  extends Pick<
    DropdownProps,
    | 'options'
    | 'dropdownWidth'
    | 'isDisabled'
    | 'isSearchable'
    | 'placeholder'
    | 'hasError'
    | 'onBlur'
    | 'onFocus'
  > {
  name?: string;
  onChange: (value: string | undefined) => void;
  value: string | undefined;
}

export function BaseDropdown({ name, placeholder, options, onChange, value, ...restProps }: Props) {
  const intl = useIntl();

  const placeholderTranslated = placeholder
    ? intl.formatMessage({
        id: placeholder,
      })
    : '';

  const selectedOption = useMemo(
    () => find(options, item => item.value === String(value)) || null,
    [options, value]
  );

  const filteredItems = useMemo(() => options?.filter(item => !item.deactivated), [options]);

  return (
    <Dropdown
      selectedOption={selectedOption}
      options={filteredItems}
      placeholder={placeholderTranslated}
      onChange={selectedItem => onChange(selectedItem ? selectedItem.value : undefined)}
      inputId={name}
      {...restProps}
    />
  );
}
