import type { SectionCommon, Source } from './utils';

export enum ProtectionType {
  LifeInsurance = 'lifeInsurance',
  IncomeProtection = 'incomeProtection',
  CriticalIllnessProtection = 'criticalIllnessProtection',
  PrivateMedicalInsurance = 'privateMedicalInsurance',
  PermanentHealthInsurance = 'permanentHealthInsurance',
}

export interface ExistingProtection {
  type: ProtectionType;
  owner: Source;
  proivder?: string;
  policy_number?: string;
  cover_amount: number;
  regular_premium: number;
  description: string;
}

interface DeathItem {
  capital?: number;
  income_amount?: number;
  income_time?: number;
}

interface IllnessDisability {
  capital?: number;
  supplementary_short_term?: number;
  supplementary_long_term?: number;
}

export interface Protection extends SectionCommon {
  existing_protection: ExistingProtection[];
  protection_priorities?: string;
  death?: {
    you?: DeathItem;
    partner?: DeathItem;
  };
  illness_disability?: {
    you?: IllnessDisability;
    partner?: IllnessDisability;
  };
}
