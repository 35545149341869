import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { isString } from 'lodash';

import { isBlank } from 'helpers/utils';

export function toHTML(editorState: EditorState | undefined): string {
  return editorState
    ? stateToHTML(editorState.getCurrentContent(), {
        inlineStyles: {
          SUPERSCRIPT: { element: 'sup' },
          SUBSCRIPT: { element: 'sub' },
          'fontfamily-Lato': { element: 'span', attributes: { class: 'Lato' } },
          'fontfamily-Roboto': { element: 'span', attributes: { class: 'Roboto' } },
          'fontfamily-Poppins': { element: 'span', attributes: { class: 'Poppins' } },
          'fontfamily-OpenSans': { element: 'span', attributes: { class: 'OpenSans' } },
          'fontfamily-Montserrat': { element: 'span', attributes: { class: 'Montserrat' } },
        },
        blockStyleFn(block) {
          const textAlign = block.getData().get('text-align');

          if (isString(textAlign)) {
            return {
              style: {
                textAlign,
              },
            };
          }

          return undefined;
        },
        entityStyleFn: entity => {
          const entityType = entity.getType();
          if (entityType === 'SYMBOL') {
            return {
              element: 'span',
              attributes: {
                data: entityType,
              },
            };
          }

          return undefined;
        },
      }).trim()
    : '';
}

export function fromHTML(html: string | undefined): EditorState {
  const content = html
    ? stateFromHTML(html, {
        customBlockFn(element) {
          // element has type 'Element', we need to cast it to use 'style' property
          const { style } = element as unknown as ElementCSSInlineStyle;

          if (style.textAlign) {
            return { data: { 'text-align': style.textAlign } };
          }

          return undefined;
        },
        customInlineFn(element, { Style, Entity }) {
          const { className } = element;

          if (element.getAttribute('data') === 'SYMBOL') {
            // @ts-ignore
            return Entity('SYMBOL', {}, 'SEGMENTED');
          }

          if (element.tagName === 'SPAN' && className) {
            return Style(`fontfamily-${className}`);
          }

          return undefined;
        },
        elementStyles: {
          sup: 'SUPERSCRIPT',
          sub: 'SUBSCRIPT',
        },
      })
    : EditorState.createEmpty().getCurrentContent();

  return EditorState.createWithContent(content);
}

export function isRichTextContentEqual(
  a: EditorState | undefined,
  b: EditorState | undefined
): boolean {
  return a && b ? a.getCurrentContent().equals(b.getCurrentContent()) : a === b;
}

export function isRichTextContentBlank(state: EditorState): boolean {
  return isBlank(state.getCurrentContent().getPlainText());
}
