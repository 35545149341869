import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';

export interface ClientMetaMotivationCentreGetResponse {
  unread_comments_count: number;
}

export function clientMetaMotivationCentreApi(path: string) {
  return {
    path,
    GET: (config?: RequestConfig) => api.get<ClientMetaMotivationCentreGetResponse>(path, config),
  };
}
