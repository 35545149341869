import React from 'react';
import { rem, size as cssSize } from 'polished';
import styled from 'styled-components';

import { circle, flexCenter } from 'helpers/styles/mixins';
import GenericLoader from 'components/GenericLoader/GenericLoader';
import IconCheckmarkSec from 'components/Icons/IconCheckmarkSec';
import IconExclamationMark from 'components/Icons/IconExclamationMark';
import { VerificationStatus } from 'components/VideoChat/types';

const IconWrapper = styled.div<{ $status: VerificationStatus; $size: number }>`
  ${({ $size }) => circle(rem($size))};
  ${flexCenter()};
  background-color: ${({ $status, theme }) => {
    if ($status === VerificationStatus.Warning) {
      return theme.colors.orangeWarning;
    }

    if (
      [
        VerificationStatus.Failed,
        VerificationStatus.Error,
        VerificationStatus.PermissionDenied,
        VerificationStatus.SystemPermissionDenied,
      ].includes($status)
    ) {
      return theme.colors.badgeErrorBg;
    }

    return theme.colors.badgeSuccessBg;
  }};
`;

const Icon = styled.i`
  ${cssSize('50%')};
  color: ${({ theme }) => theme.colors.white};
`;

const Loader = styled(GenericLoader)`
  width: auto;
`;

export interface Props {
  status: VerificationStatus;
  size: number;
}

export function StatusIcon({ status, size }: Props) {
  return status === VerificationStatus.Pending ? (
    <Loader size={size} isVisible isInlineFlex />
  ) : (
    <IconWrapper $status={status} $size={size}>
      <Icon as={status === VerificationStatus.Succeeded ? IconCheckmarkSec : IconExclamationMark} />
    </IconWrapper>
  );
}
