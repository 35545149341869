import React, { ElementType, HTMLAttributes, useCallback, useState } from 'react';
import { darken, rem, size } from 'polished';
import styled, { css } from 'styled-components';

import { circle, flexCenter } from 'helpers/styles/mixins';
import GenericLoader from 'components/GenericLoader/GenericLoader';
import { BasicTooltip } from 'components/GenericTooltip';
import IconExclamationMark from 'components/Icons/IconExclamationMark';

interface ButtonProps {
  readonly iconColor?: string;
  readonly pressedIconColor?: string;
}

export const Button = styled.button<ButtonProps>`
  ${({ iconColor, pressedIconColor, theme, disabled }) => {
    const finalColor = iconColor || theme.colors.white;
    const finalPressedColor = pressedIconColor || theme.colors.bermudaGray;

    return css`
      padding: ${rem(20)};
      color: ${finalColor};
      outline: none;
      transition: color linear 0.3s;

      cursor: ${disabled ? 'default' : 'pointer'};

      &:hover {
        color: ${darken(0.1, finalColor)};
      }

      &[aria-pressed='true'] {
        color: ${finalPressedColor};
      }

      &:disabled {
        color: ${finalPressedColor};
      }
    `;
  }}
`;

const Wrapper = styled.div`
  position: relative;
`;

const iconSize = rem(30);

const ButtonIcon = styled.i`
  ${size(iconSize)};
  display: block;
`;

const IconWrapper = styled.div`
  ${circle(rem(20))};
  ${flexCenter()};
  position: absolute;
  top: -7px;
  right: -5px;
  background-color: ${({ theme }) => {
    return theme.colors.badgeErrorBg;
  }};
`;

const Icon = styled.i`
  ${size('50%')};
  color: ${({ theme }) => theme.colors.white};
`;

interface Props extends HTMLAttributes<HTMLButtonElement>, ButtonProps {
  tooltipText?: React.ReactNode;
  icon: ElementType;
  isDisabled?: boolean;
  onClick: () => void | Promise<void>;
}

function VideoChatToolbarButton({ icon, tooltipText, isDisabled, onClick, ...rest }: Props) {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleClick = useCallback(() => {
    setIsProcessing(true);

    Promise.resolve(onClick()).then(() => {
      setIsProcessing(false);
    });
  }, [onClick]);

  return (
    <BasicTooltip tooltipMessage={tooltipText} showDelay={600}>
      <Button disabled={isDisabled} onClick={handleClick} {...rest}>
        {isProcessing ? (
          <GenericLoader size={iconSize} isVisible />
        ) : (
          <Wrapper>
            <ButtonIcon as={icon} />
            {isDisabled && (
              <IconWrapper>
                <Icon as={IconExclamationMark} />
              </IconWrapper>
            )}
          </Wrapper>
        )}
      </Button>
    </BasicTooltip>
  );
}

export default VideoChatToolbarButton;
