import React from 'react';

import SvgIcon from './SvgIcon';

const IconArrowThird = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 6 9" {...props}>
    <path d="M.5.5l4.528 4.528M.5 8.5l4.528-4.528" />
  </SvgIcon>
);

export default IconArrowThird;
