import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { getMergedColourSchemes } from 'store/settings/selectors';
import type { AppState } from 'store/types';

const mapStateToProps = (state: AppState) => ({
  theme: getMergedColourSchemes(state),
});

export default connect(mapStateToProps)(ThemeProvider);
