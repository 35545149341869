import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';

export interface ClientMetaUnreadFinancialStrategiesGetResponse {
  has_unread_financial_strategies: number;
}

export function ClientMetaUnreadFinancialStrategiesApi(path: string) {
  return {
    path,
    GET: (config?: RequestConfig) =>
      api.get<ClientMetaUnreadFinancialStrategiesGetResponse>(path, config),
  };
}
