import api from 'api/apiInstance';
import type { FinancialPlanningTopic } from 'constants/financialPlanningTopics';
import { convertJsonToFormData, mapFormDataDeep } from 'helpers/formData';
import type { CaseType } from 'types/entities/clientCase';

export interface RegistrationsPostOptions {
  user: {
    email: string;
    password: string;
    passwordConfirmation: string;
    firstName: string;
    lastName: string;
    personalDataConsent: boolean;
    phone?: string;
  };
  client: {
    dateOfBirth: string;
    city: string;
    preferredAdviserId?: number;
  };
  case: {
    type: CaseType;
    topics?: FinancialPlanningTopic[];
    initialInvestment: number | null;
    initialInvestmentCurrency: string | null;
    monthlyContribution: number | null;
    monthlyContributionCurrency: string | null;
    investmentDuration: number | null;
  };
  invitation?: {
    uuid?: string;
  };
  metadata?: {
    recaptcha?: string;
    clientAdvice?: string;
    highInterestDebt?: boolean;
    cashReserve?: boolean;
  };
}

const apiPath = '/api/v1/registrations';

function registrationsApi(path: string) {
  return {
    path,
    POST: (params: RegistrationsPostOptions) =>
      api.post(path, convertJsonToFormData(mapFormDataDeep(params))),
  };
}

function registrations() {
  return registrationsApi(apiPath);
}

export { registrations };
