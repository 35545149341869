import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions } from 'api/types';

import { clientTodoListTasksTaskUpdateStateApi } from './updateState';

export function clientTodoListTasksTaskApi(path: string) {
  return {
    path,
    updateState: () => clientTodoListTasksTaskUpdateStateApi(`${path}/update-state`),
    GET: (params?: GetOptions) => api.get<JsonApiResponseSingle>(path, { params }),
    DELETE: () => api.delete(path),
  };
}
