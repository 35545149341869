export default {
  title: `Agenda Template`,
  description: `Here you can read agenda templates`,
  tileDescription: `View agenda templates.`,
  createdAt: `Created at`,
  noTemplates: `No templates were added yet`,
  templates: `Templates`,
  createTemplate: `Create Template`,
  editTemplate: `Edit Template`,
  preview: `Preview Template`,
  removeTemplate: `Remove Template`,
  removeConfirmation: `Are you sure you want to remove template: {title}?`,
  create: `Create`,
  save: `Save`,
  previewTitle: `Agenda Title`,
  moreActions: `More actions`,
  fields: {
    title: `Agenda Title`,
    template: `Agenda Template`,
    active: `Active`,
  },
  content: `Content`,
  contentDescription: `Please add the template content here. The template may take the form of text-based content.`,
  contentError: `You must add agenda content in text form.`,
  activeTooltip: `Template marked as active will be available while creating Client meetings`,
};
