import { find, first } from 'lodash';
import type {
  LocalAudioTrackPublication,
  LocalParticipant,
  LocalVideoTrackPublication,
  RemoteAudioTrackPublication,
  RemoteParticipant,
  RemoteVideoTrackPublication,
  VideoTrackPublication,
} from 'twilio-video';

import { isVideoTrack } from 'api/twilio/twilioVideo';

type AudioTrack<T extends LocalParticipant | RemoteParticipant> = T extends LocalParticipant
  ? LocalAudioTrackPublication
  : RemoteAudioTrackPublication;

type VideoTrack<T extends LocalParticipant | RemoteParticipant> = T extends LocalParticipant
  ? LocalVideoTrackPublication
  : RemoteVideoTrackPublication;

export function getParticipantAudioTrack<T extends LocalParticipant | RemoteParticipant>(
  participant: T
): AudioTrack<T> | undefined {
  return first(Array.from(participant.audioTracks.values() as IterableIterator<AudioTrack<T>>));
}

export function getParticipantVideoTrack<T extends LocalParticipant | RemoteParticipant>(
  participant: T,
  finder = isVideoTrack
): VideoTrack<T> | undefined {
  return find(
    Array.from(participant.videoTracks.values() as IterableIterator<VideoTrack<T>>),
    track => finder(track)
  );
}

interface Result<T extends LocalParticipant | RemoteParticipant> {
  videoTrack: VideoTrack<T> | undefined;
  audioTrack: AudioTrack<T> | undefined;
}

export function getParticipantTracks<T extends LocalParticipant | RemoteParticipant>(
  participant: T
): Result<T> {
  return {
    videoTrack: getParticipantVideoTrack(participant),
    audioTrack: getParticipantAudioTrack(participant),
  };
}

export function getVideoRatio(videoTrack: VideoTrackPublication | undefined): number | undefined {
  const videoDimensions = videoTrack?.track?.dimensions;

  return videoDimensions?.height && videoDimensions?.width
    ? videoDimensions.width / videoDimensions.height
    : undefined;
}
