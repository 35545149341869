import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions } from 'api/types';
import { mapFormData } from 'helpers/formData';
import type { DocumentSetModel } from 'types/entities/documentSet';

interface GuestAdviceRoomDocumentSetItemGetOptions extends GetOptions {
  documentSetModel: DocumentSetModel;
  documentSetId?: number;
  authToken: string;
}

export function guestAdviceRoomDocumentSetItemApi(path: string) {
  return {
    path,
    GET: (params: GuestAdviceRoomDocumentSetItemGetOptions) =>
      api.get<JsonApiResponse>(path, {
        params: mapFormData(params),
      }),
  };
}
