import type { AnyAction } from 'redux';

import type { PermissionsState } from './types';

export const initialState: PermissionsState = {
  canRemoveClients: false,
};

export default (state = initialState, action: AnyAction): PermissionsState => {
  switch (action.type) {
    default:
      return state;
  }
};
