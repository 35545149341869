export default {
  title: `Our Service Charters`,
  tileDescription: `Find out about our commitment to you.`,
  settingsSubtitle: `You can provide clients with a description of your Service Charter as PDFs.`,
  availableUntil: `Available until`,
  create: `Create Service Charter`,
  edit: `Edit Service Charter`,
  remove: {
    title: `Remove Service Charter`,
    confirmation: `Are you sure you want to remove Service Charter: {title}?`,
  },
  noItems: `No Services Charters to display.`,
  tileImageDescription: `Below is a preview of what your client will see on their dashboard for this Service Charter. You can optionally upload or choose more suitable image.`,
  notifications: {
    charterCreated: `Service Charter has been created successfully.`,
    charterUpdated: `Service Charter has been updated successfully.`,
    charterRemoved: `Service Charter has been removed successfully.`,
  },
};
