import { Root, route } from '../utils';

const identityVerificationPath = `${Root}identity-verifications`;

const idvPath = `${identityVerificationPath}/:identityVerificationId`;

type IdVParams = [identityVerificationId: number];

export default {
  identityVerification: {
    ...route(identityVerificationPath),
    show: {
      ...route<IdVParams>(idvPath),
      auditTrail: route<IdVParams>(`${idvPath}/audit-trail`),
    },
  },
};
