import React from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import { toast, ToastContent, ToastOptions } from 'react-toastify';
import { get, isArray, isEmpty, isObject, isString, join, map, reduce } from 'lodash';
import { rem, size } from 'polished';
import styled from 'styled-components';

import { isBlank, pascalCase } from 'helpers/utils';
import IconCheckmarkSec from 'components/Icons/IconCheckmarkSec';
import { toast as toastClassName, toastText } from 'components/ToastContainer/classNames';
import ToastMessage from 'components/ToastMessage/ToastMessage';

const defaultErrorText = 'notification.messages.default';

type Message = string | string[] | { [key: string]: string };
type ToastType = ValueOf<typeof toast.TYPE>;

export interface CustomToastOptions {
  closeToast?: () => void;
}

type FormattedMessageValues = Parameters<IntlShape['formatMessage']>[1];

const prepareMessage = (
  message: Message,
  isTranslated?: boolean,
  values?: FormattedMessageValues
) => {
  if (isString(message) && !isBlank(message)) {
    return isTranslated ? message : <FormattedMessage id={message} values={values} />;
  }

  if (isArray(message)) {
    return message;
  }

  if (isObject(message)) {
    return map(message, (value, key) => [`${pascalCase(key)} ${join(value, ', ')}`]).join('. ');
  }

  return '';
};

interface ExtendedToastOptions extends ToastOptions {
  isTranslated?: boolean;
  values?: FormattedMessageValues;
}

const getToastOptions = (type: ToastType, options: ExtendedToastOptions): ExtendedToastOptions => ({
  autoClose: type === toast.TYPE.SUCCESS ? 10000 : 6000,
  closeButton: false,
  hideProgressBar: true,
  type,
  className: `${toastClassName} ${toastClassName}--${type}`,
  bodyClassName: toastText,
  ...options,
});

export const showCustomToast = (
  component: ToastContent,
  type: ToastType = toast.TYPE.DEFAULT,
  options: ExtendedToastOptions = {}
) => {
  toast(component, getToastOptions(type, options));
};

const MessageIcon = styled.i`
  ${size(rem(8))};
`;

export const showToast = (
  text: Message,
  type: ToastType = toast.TYPE.DEFAULT,
  options: ExtendedToastOptions = {}
) => {
  const { isTranslated, values, ...toastOptions } = options;

  const icons: { [key: string]: React.ReactElement } = {
    [toast.TYPE.SUCCESS]: <MessageIcon as={IconCheckmarkSec} />,
    [toast.TYPE.ERROR]: <b>!</b>,
  };

  toast(
    <ToastMessage text={prepareMessage(text, isTranslated, values)} icon={icons[type]} />,
    getToastOptions(type, toastOptions)
  );
};

export const showInfoToast = (text: Message, options: ExtendedToastOptions = {}) => {
  showToast(text, toast.TYPE.INFO, options);
};

export const showSuccessToast = (text: Message, options: ExtendedToastOptions = {}) => {
  showToast(text, toast.TYPE.SUCCESS, options);
};

export const showWarningToast = (text: Message, options: ExtendedToastOptions = {}) => {
  showToast(text, toast.TYPE.WARNING, options);
};

export const showErrorToast = (
  text: Message = defaultErrorText,
  options: ExtendedToastOptions = {}
) => {
  showToast(text, toast.TYPE.ERROR, {
    ...options,
    isTranslated: text === defaultErrorText ? false : options.isTranslated,
  });
};

export const showApiErrorToast = (error: Error, options: ExtendedToastOptions = {}) => {
  const errors = reduce<{ title?: string; detail?: string }, string[]>(
    get(error, 'response.data.errors'),
    (result, { title, detail }) => {
      if (detail) {
        return [...result, detail];
      }

      if (title) {
        return [...result, title];
      }

      return result;
    },
    []
  );

  showErrorToast(isEmpty(errors) ? defaultErrorText : errors, { ...options, isTranslated: true });
};

export const TOAST_TYPES = toast.TYPE;
