import React from 'react';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';

import { misc } from 'helpers/styles/constants';
import { requiredTextPattern } from 'helpers/validators';
import GenericLengthCounter from 'components/GenericLengthCounter/GenericLengthCounter';

const Wrapper = styled.div<{ hasFullWidth?: boolean }>`
  position: relative;
  display: inline-block;
  ${({ hasFullWidth }) => hasFullWidth && 'width: 100%'};
`;

interface InputStyleProps {
  isDefault?: boolean;
  hasError?: boolean;
  hasNoBackground?: boolean;
  isSmall?: boolean;
  isMedium?: boolean;
  hasMediumWidth?: boolean;
  hasFullWidth?: boolean;
  showDisabledAsText?: boolean;
}

const StyledInput = styled.input<InputStyleProps>`
  display: inline;
  line-height: 1.3;
  transition: border-color 0.3s ease;

  ${({ isDefault, hasError, showDisabledAsText, theme }) =>
    isDefault &&
    css`
      padding: ${rem(16)} ${rem(15)};
      font-size: ${theme.fontSizes.dbNormal};
      outline: none;
      border: 1px solid ${theme.colors.mischka};
      border-top-width: ${rem(2)};

      ${hasError
        ? css`
            &,
            &:focus,
            &:hover {
              border-color: ${theme.colors.crail};
            }
          `
        : css`
            &:focus,
            &:hover {
              border-color: ${theme.colors.main};
            }
          `};

      ${showDisabledAsText
        ? css`
            &:disabled {
              padding-left: 0;
              padding-top: 0;
              border: 0;
              color: ${theme.colors.textDark};
              background-color: transparent;

              &::placeholder {
                color: transparent;
              }
            }
          `
        : css`
            &:disabled {
              &,
              &:focus,
              &:hover {
                border-color: ${theme.colors.mischka};
              }
            }
          `}
    `};

  ${({ hasNoBackground, hasError, theme }) =>
    hasNoBackground &&
    css`
      width: 100%;
      height: ${rem(33)};
      padding-bottom: ${rem(10)};
      background-color: transparent;
      border-bottom: 1px solid ${rgba(theme.colors.bermudaGray, 0.5)};

      ${hasError
        ? css`
            &,
            &:focus,
            &:hover {
              border-bottom-color: ${theme.colors.crail};
            }
          `
        : css`
            &:focus,
            &:hover {
              border-bottom-color: ${theme.colors.bermudaGray};
            }
          `};
    `};

  ${({ isSmall, theme }) =>
    isSmall &&
    css`
      padding-bottom: 0;
      font-size: ${theme.fontSizes.dbNormal};
    `};

  ${({ isMedium }) =>
    isMedium &&
    css`
      padding-top: ${rem(10)};
      padding-bottom: ${rem(10)};
    `};

  ${({ hasMediumWidth }) => hasMediumWidth && `width: ${rem(misc.dashboardBtnWidthLg)}`};
  ${({ hasFullWidth }) => hasFullWidth && 'width: 100%'};

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-appearance: none;
    /* stylelint-enable property-no-vendor-prefix */
    margin: 0;
  }
`;

export interface Props extends React.InputHTMLAttributes<HTMLInputElement>, InputStyleProps {
  maxLength?: number;
  hasFullWidth?: boolean;
}

const GenericInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      placeholder,
      maxLength,
      isSmall,
      isMedium,
      hasError,
      hasNoBackground,
      hasMediumWidth,
      hasFullWidth,
      showDisabledAsText = true,
      ...inputProps
    },
    ref
  ) => {
    const intl = useIntl();
    const styleProps = {
      isSmall,
      isMedium,
      isDefault: !hasNoBackground,
      hasNoBackground,
      hasError,
      hasMediumWidth,
      hasFullWidth,
      showDisabledAsText,
    };

    const { type, required, value, defaultValue, ...restInputProps } = inputProps;

    const translatedPlaceholder = placeholder ? intl.formatMessage({ id: placeholder }) : undefined;
    const requiredProps = required
      ? {
          required,
          pattern: requiredTextPattern,
          title: intl.formatMessage({ id: 'errors.required' }),
        }
      : {};

    const commonProps = {
      placeholder: translatedPlaceholder,
      ...restInputProps,
      ...requiredProps,
      ...styleProps,
      value: value != null ? String(value) : undefined,
      defaultValue: defaultValue != null ? String(defaultValue) : undefined,
    };

    return (
      <Wrapper hasFullWidth={hasFullWidth} data-test="generic-input">
        <StyledInput {...commonProps} type={type} ref={ref} />

        {maxLength && !inputProps.disabled && (
          <GenericLengthCounter
            currentLength={get(inputProps, 'value.length')}
            maxLength={maxLength}
          />
        )}
      </Wrapper>
    );
  }
);

export default GenericInput;
