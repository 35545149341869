import { connect } from 'react-redux';

import { users } from 'api';
import { getIsExpirationSessionBannerVisible } from 'store/sessionExpiration/selectors';
import type { AppState } from 'store/types';
import { getCurrentUser } from 'store/users/selectors';

export interface RenderProps {
  onClick: () => void;
}

const SessionExpirationBanner = ({
  render,
  isVisible,
  currentUser,
}: ContainerProps<RenderProps> & MappedProps) =>
  isVisible && currentUser
    ? render({
        onClick: users().me().GET,
      })
    : null;

const mapStateToProps = (state: AppState) => ({
  currentUser: getCurrentUser(state),
  isVisible: getIsExpirationSessionBannerVisible(state),
});

type MappedProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(SessionExpirationBanner);
