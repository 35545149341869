import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';

export interface ClientMetaCasesRecommendationsGetResponse {
  action_required_count: number;
}

export function clientMetaCasesRecommendationsApi(path: string) {
  return {
    path,
    GET: (config?: RequestConfig) =>
      api.get<ClientMetaCasesRecommendationsGetResponse>(path, config),
  };
}
