import { Root, route } from '../utils';

const practiceAdminsPath = `${Root}practice-admins`;
const practiceAdminPath = `${practiceAdminsPath}/:practiceAdminId`;

type PracticeAdminParams = [practiceAdminId: number];

export default {
  practiceAdmins: {
    ...route(practiceAdminsPath),
    new: route(`${practiceAdminsPath}/new`),
  },
  practiceAdmin: {
    ...route<PracticeAdminParams>(practiceAdminPath),
    edit: route<PracticeAdminParams>(`${practiceAdminPath}/edit`),
    accountSettings: route(`${Root}admin#/account-settings`),
  },
};
