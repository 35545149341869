import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';

export function clientCaseConfirmApi(path: string) {
  return {
    path,
    POST: () => api.post<JsonApiResponseSingle>(path),
  };
}
