import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { BrowserInfo, detect } from 'detect-browser';
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';

import { misc } from 'helpers/styles/constants';
import { flexCenter } from 'helpers/styles/mixins';
import { useStyledTheme } from 'hooks/useStyledTheme';
import { useGetCurrentClientId } from 'store/currentClient/selectors';
import { useGetIsCurrentUserAClient } from 'store/users/selectors';
import IconMicrophone from 'components/Icons/Chat/IconMicrophone';
import IconPhone from 'components/Icons/Chat/IconPhoneReceiver';
import IconScreenSharing from 'components/Icons/Chat/IconScreenSharing';
import IconStartRecording from 'components/Icons/Chat/IconStartRecording';
import IconStopRecording from 'components/Icons/Chat/IconStopRecording';
import IconCamera from 'components/Icons/Chat/IconVideoCamera';
import IconRoomMonitor from 'components/Icons/IconRoomMonitor';
import {
  useCameraPermissions,
  useMicrophonePermissions,
} from 'components/TestSetupTwilio/components/useDevicesPermissions';
import { DevicePermissions } from 'components/TestSetupTwilio/types';
import { useMessagesContext } from 'components/VideoChat/MessagesContext';
import { useRecordingContext } from 'components/VideoChat/RecordingContext';
import { RoomConnectionState } from 'components/VideoChat/RoomConnectionState';
import { areDevicesSupported, isScreensharingSupported } from 'components/VideoChat/utils';
import { useVideoContext } from 'components/VideoChat/VideoContext';

import BackgroundFiltersButton from '../BackgroundFilters/BackgroundFiltersButton';
import RecordingSwitcherButton from '../RecordingButton/RecordingSwitcherButton';
import VideoChatToolbarButton from '../VideoChatToolbarButton/VideoChatToolbarButton';
import MessagesButton from './MessagesButton';
import SettingsButton from './SettingsButton';

const Wrapper = styled.div<{
  isExpanded?: boolean;
  isSmall?: boolean;
  isHidden?: boolean;
}>`
  ${flexCenter()};
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  transition: ease-in-out 0.2s opacity;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: ${misc.zIndex9};
      width: 100%;
    `};

  ${({ isSmall }) =>
    isSmall &&
    css`
      transform: scale(0.6);
      margin: ${rem(-16)} 0;
    `};
`;

const ButtonsWrapper = styled.div<{ isFloating?: boolean; hasShadow?: boolean }>`
  display: inline-flex;
  margin: ${rem(20)} 0;
  padding: ${rem(5)};
  background-color: ${({ theme }) => theme.colors.main};
  border-radius: ${rem(5)};
  transition: box-shadow 0.3s ease-in-out;

  ${({ isFloating }) =>
    isFloating &&
    css`
      box-shadow: ${rem(3)} ${rem(3)} ${rem(15)} ${rgba(0, 0, 0, 0.5)};
    `};

  ${({ hasShadow }) => hasShadow && `box-shadow: 0 0 ${rem(15)} ${rgba(0, 0, 0, 0.3)};`};
`;

export interface Props {
  isHidden?: boolean;
  isSmall?: boolean;
  isExpanded?: boolean;
  isFloating?: boolean;
  hasShadow?: boolean;
  onJoin?: () => void;
  onLeave: () => void;
}

function VideoChatToolbar({
  isHidden,
  isSmall,
  isExpanded,
  isFloating,
  hasShadow,
  onJoin,
  onLeave,
}: Props) {
  const theme = useStyledTheme();
  const { areMessagesEnabled } = useMessagesContext();
  const {
    room,
    toggleAudioTracks,
    toggleVideoTracks,
    toggleScreenTrack,
    roomMonitorConsole,
    enableBackgroundBlur,
    enableVirtualBackground,
    removeAllVideoEffect,
    isRoomMonitorConsoleEnabled,
    isAudioEnabled,
    isVideoEnabled,
    isScreensharingEnabled,
    connectionState,
    isBackgroundBlurEnabled,
    isVirtualBackgroundEnabled,
  } = useVideoContext();

  const { updateRecordingState } = useRecordingContext();
  const browser = useMemo(() => detect() as BrowserInfo, []);

  const isMeetingInProgress = connectionState === RoomConnectionState.Connected;
  const isCurrentUserAClient = useGetIsCurrentUserAClient();
  const currentClientId = useGetCurrentClientId();

  const { microphonePermissions } = useMicrophonePermissions();
  const { cameraPermissions } = useCameraPermissions();

  const isButtonDisabled = (buttonName: string) => {
    if (
      buttonName === 'camera' &&
      (cameraPermissions === DevicePermissions.Denied ||
        cameraPermissions === DevicePermissions.SystemDenied)
    ) {
      return true;
    }

    if (
      buttonName === 'microphone' &&
      (microphonePermissions === DevicePermissions.Denied ||
        microphonePermissions === DevicePermissions.SystemDenied)
    ) {
      return true;
    }

    return false;
  };

  return (
    <Wrapper isExpanded={isExpanded} isSmall={isSmall} isHidden={isHidden}>
      <ButtonsWrapper isFloating={isFloating} hasShadow={hasShadow}>
        {areDevicesSupported() && <SettingsButton />}
        <VideoChatToolbarButton
          onClick={toggleVideoTracks}
          icon={IconCamera}
          data-test="camera-button"
          aria-pressed={!isVideoEnabled}
          isDisabled={isButtonDisabled('camera')}
          tooltipText={
            isVideoEnabled ? (
              <FormattedMessage id="video.tooltips.disableWebcam" />
            ) : (
              <FormattedMessage id="video.tooltips.enableWebcam" />
            )
          }
        />

        <VideoChatToolbarButton
          onClick={toggleAudioTracks}
          icon={IconMicrophone}
          data-test="microphone-button"
          aria-pressed={!isAudioEnabled}
          isDisabled={isButtonDisabled('microphone')}
          tooltipText={
            isAudioEnabled ? (
              <FormattedMessage id="video.tooltips.muteMicrophone" />
            ) : (
              <FormattedMessage id="video.tooltips.unmuteMicrophone" />
            )
          }
        />

        {!isCurrentUserAClient &&
          currentClientId &&
          isMeetingInProgress &&
          updateRecordingState && (
            <RecordingSwitcherButton
              icon={room?.isRecording ? IconStopRecording : IconStartRecording}
              data-test="record-button"
              updateRecordingState={updateRecordingState}
              isRecording={room?.isRecording}
              tooltipText={
                room?.isRecording ? (
                  <FormattedMessage id="video.tooltips.stopRecord" />
                ) : (
                  <FormattedMessage id="video.tooltips.startRecord" />
                )
              }
            />
          )}

        {!['ios', 'safari', 'firefox'].includes(browser.name) && (
          <BackgroundFiltersButton
            data-test="background-filters-button"
            removeAllVideoEffect={removeAllVideoEffect}
            enableVirtualBackground={enableVirtualBackground}
            enableBackgroundBlur={enableBackgroundBlur}
            isVirtualBackgroundEnabled={isVirtualBackgroundEnabled}
            isBackgroundBlurEnabled={isBackgroundBlurEnabled}
            tooltipText={<FormattedMessage id="video.tooltips.backgroundFilters" />}
          />
        )}

        {isScreensharingSupported() && isMeetingInProgress && (
          <VideoChatToolbarButton
            data-test="screen-share-button"
            onClick={toggleScreenTrack}
            icon={IconScreenSharing}
            aria-pressed={!isScreensharingEnabled}
            tooltipText={<FormattedMessage id="video.tooltips.shareScreen" />}
          />
        )}

        {isMeetingInProgress && areMessagesEnabled && <MessagesButton />}

        {!isCurrentUserAClient && currentClientId && isMeetingInProgress && (
          <VideoChatToolbarButton
            onClick={roomMonitorConsole}
            icon={IconRoomMonitor}
            data-test="cmr-room-monitor"
            aria-pressed={!isRoomMonitorConsoleEnabled}
            tooltipText={
              isRoomMonitorConsoleEnabled ? (
                <FormattedMessage id="video.tooltips.closeRoomMonitor" />
              ) : (
                <FormattedMessage id="video.tooltips.openRoomMonitor" />
              )
            }
          />
        )}

        {isMeetingInProgress ? (
          <VideoChatToolbarButton
            data-test="leave-button"
            iconColor={theme.colors.crail}
            onClick={onLeave}
            icon={IconPhone}
            tooltipText={<FormattedMessage id="video.tooltips.endCall" />}
          />
        ) : (
          onJoin && (
            <VideoChatToolbarButton
              data-test="join-button"
              iconColor={theme.colors.mountainMeadow}
              onClick={onJoin}
              icon={IconPhone}
              tooltipText={<FormattedMessage id="video.tooltips.joinCall" />}
            />
          )
        )}
      </ButtonsWrapper>
    </Wrapper>
  );
}

export default VideoChatToolbar;
