export default {
  title: `Frequently asked questions`,
  disabled: `Editing is currently disabled.`,
  defaultContent: {
    1: {
      question: `What does the client portal do?`,
      answer: `'<p>Our portal includes many features and potential uses. However, it fundamentally provides our clients with access to their wealth management records. It also allows clients to conveniently request financial advice, and to ‘meet’ with our team using secure video conferencing services.</p>`,
    },
    2: {
      question: `How do I get access?`,
      answer: `'<p>When you become a client, your Adviser will offer you a personal account on this portal. Once your account is created, you can use it to manage your wealth management records and to interact with your Adviser and our office staff.</p>`,
    },
    3: {
      question: `Can I seek financial advice using this portal?`,
      answer: `'<p>Yes. If you are considering becoming a client, this portal allows you to request advice. Our existing clients can also use this portal to seek advice from their Adviser.</p>`,
    },
    4: {
      question: `Is my personal information safe and are my communications secure?`,
      answer: `
        '<p>We provide a range of convenient ways for you to communicate with your Adviser and with our office. The security of these communications and of your personal information are of paramount importance.</p>
        <ul>
          <li>
            <b>In-person meetings:</b> Our Advisers are both trained and diligent in the recording, managing and storing personal information that you provide during face-to-face meetings.
          </li>
          <li>
            <b>Telephone meetings:</b> Similarly, our Advisers are equally adept at managing your personal data that is provided during telephone meetings.
          </li>
          <li>
            <b>Email:</b> We use encrypted emails for correspondence regarding sensitive topics and information. However, we use non-encrypted emails for general correspondence.
          </li>
          <li>
            <b>Online forms:</b> Using this website, you can submit online forms to us relating to your financial situation and plans. Your use of this site is encrypted using the HTTPS protocol. Our website is hosted in secure data centres. Our database and file system are both encrypted.
          </li>
          <li>
            <b>Client Meeting Rooms:</b> On this site, we provide integrated video and audio services. These streams are encrypted. Any optional recordings of the streams are securely stored.
          </li>
          <li>
            <b>Post:</b> Our office may occasionally send you information by post.
          </li>
        </ul>
        <p>For further information, please see our <a target="_blank" rel="noopener noreferrer" href="{privacyPolicyUrl}">privacy policy</a>.</p>
        `,
    },
  },
};
