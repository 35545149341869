import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

export interface UsersPasswordPostOptions {
  email: string;
}

export interface UsersPasswordPatchOptions {
  resetPasswordToken: string;
  password: string;
  passwordConfirmation: string;
}

export function usersPasswordApi(path: string) {
  return {
    path,
    POST: (params: UsersPasswordPostOptions) => api.post(path, { user: mapFormData(params) }),
    PATCH: (params: UsersPasswordPatchOptions) => api.patch(path, { user: mapFormData(params) }),
  };
}
