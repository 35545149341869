import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

import { clientNotificationApi } from './clientNotification';

export interface ClientNotificationsPostOptions {
  startAt: string;
  endAt: string;
  file: File;
}

const apiPath = '/api/v1/client-notifications';

function clientNotificationsApi(path: string) {
  return {
    path,
    GET: () => api.get<JsonApiResponseSingle>(path),
    POST: (params: ClientNotificationsPostOptions) =>
      api.post<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      ),
  };
}

type ClientNotificationApi = ReturnType<typeof clientNotificationApi>;
type ClientNotificationsApi = ReturnType<typeof clientNotificationsApi>;

function clientNotifications(): ClientNotificationsApi;
function clientNotifications(notificationId: number): ClientNotificationApi;
function clientNotifications(
  notificationId?: number
): ClientNotificationsApi | ClientNotificationApi {
  return notificationId
    ? clientNotificationApi(`${apiPath}/${notificationId}`)
    : clientNotificationsApi(apiPath);
}

export { clientNotifications };
