import React from 'react';
import { rem } from 'polished';
import styled, { PolymorphicComponent } from 'styled-components';

interface TitleProps {
  hasMarginBottomSm?: boolean;
  hasMarginBottomMd?: boolean;
  hasMarginBottomLg?: boolean;
  hasMarginTopSm?: boolean;
  hasMarginTopMd?: boolean;
  hasMarginTopLg?: boolean;
  isSmall?: boolean;
  isBold?: boolean;
}

const Title = styled.h2<TitleProps>`
  margin-top: 0;
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSizes.dbNormal};
  font-weight: ${({ theme }) => theme.fontWeights.mainNormal};
  line-height: 1.67;
  color: ${({ theme }) => theme.colors.textLight};

  ${({ hasMarginBottomSm }) => hasMarginBottomSm && `margin-bottom: ${rem(10)}`};
  ${({ hasMarginBottomMd }) => hasMarginBottomMd && `margin-bottom: ${rem(30)}`};
  ${({ hasMarginBottomLg }) => hasMarginBottomLg && `margin-bottom: ${rem(40)}`};

  ${({ hasMarginTopSm }) => hasMarginTopSm && `margin-top: ${rem(10)}`};
  ${({ hasMarginTopMd }) => hasMarginTopMd && `margin-top: ${rem(30)}`};
  ${({ hasMarginTopLg }) => hasMarginTopLg && `margin-top: ${rem(40)}`};

  ${({ isSmall, theme }) => isSmall && `font-size: ${theme.fontSizes.dbSmallMd}`};
  ${({ isBold, theme }) => isBold && `font-weight: ${theme.fontWeights.mainBold}`};
`;

interface Props extends TitleProps {
  children: React.ReactNode;
  tag?: PolymorphicComponent;
}

const GenericTitleSummary = ({ tag = 'h2', ...rest }: Props) => <Title as={tag} {...rest} />;

export default GenericTitleSummary;
