import React from 'react';

import SvgIcon from './SvgIcon';

const IconArrowRight = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 33 26" {...props}>
    <path
      d="M32.6 12L21 0l-2.1 2.2 9 9.3H1.5C.7 11.5 0 12 0 13s.7 1.5 1.5 1.5h26.4l-9 9.3L21 26l11.6-12a1.6 1.6 0 0 0 0-2z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default IconArrowRight;
