import type { AnyAction } from 'redux';

import { SET_CURRENT_CLIENT } from './actionTypes';
import type { CurrentClientActionTypes, CurrentClientState } from './types';

export default (
  state: CurrentClientState = null,
  incomingAction: AnyAction
): CurrentClientState => {
  const action = incomingAction as CurrentClientActionTypes;

  switch (action.type) {
    case SET_CURRENT_CLIENT:
      return action.payload;
    default:
      return state;
  }
};
