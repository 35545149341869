import { delay } from 'lodash';
import { clearInterval, Interval, setInterval } from 'safe-timers';

import { users } from 'api';
import { convertMinToMs } from 'helpers/dates';
import { getSessionTimeout } from 'helpers/sessionTimeout';

import type { ThunkAction } from '../types';
import {
  HIDE_EXPIRATION_SESSION_BANNER,
  SAVE_LAST_REQUEST_TIMESTAMP,
  SHOW_EXPIRATION_SESSION_BANNER,
} from './actionTypes';
import type { HideSessionExpirationBannerAction, ShowSessionExpirationBannerAction } from './types';

let sessionExpirationTimer: number;
let sessionCheckerInterval: Interval;

export const hideExpirationSessionBanner = (): HideSessionExpirationBannerAction => ({
  type: HIDE_EXPIRATION_SESSION_BANNER,
});

export const showExpirationBanner = (): ShowSessionExpirationBannerAction => ({
  type: SHOW_EXPIRATION_SESSION_BANNER,
});

export const saveLastRequestTimestamp = (timestamp: number) => ({
  type: SAVE_LAST_REQUEST_TIMESTAMP,
  payload: timestamp,
});

const safetyDelayMs = 1000;

const setSessionChecker = (lastReqTimestamp: number) => {
  const currentDateTimestamp = Date.now();

  if (
    currentDateTimestamp - lastReqTimestamp >
    convertMinToMs(getSessionTimeout()) + safetyDelayMs
  ) {
    users().me().GET();
  }
};

export const resetExpirationSessionTimer = (): ThunkAction => dispatch => {
  const lastReqTimestamp = Date.now();

  dispatch(saveLastRequestTimestamp(lastReqTimestamp));
  dispatch(hideExpirationSessionBanner());
  clearTimeout(sessionExpirationTimer);
  clearInterval(sessionCheckerInterval);
  sessionCheckerInterval = setInterval(() => setSessionChecker(lastReqTimestamp), 2000);

  // session timeout from backend is written in minutes.
  const sessionRefreshTimeout = convertMinToMs(getSessionTimeout() - 1);

  sessionExpirationTimer = delay(() => dispatch(showExpirationBanner()), sessionRefreshTimeout);
};
