import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';

export function usersEmailSearchApi(path: string) {
  return {
    path,
    GET: (email: string) =>
      api.get<JsonApiResponse>(path, {
        params: {
          email,
        },
      }),
  };
}
