import { createGlobalStyle } from 'styled-components';

const GenericCollapseGlobalStyles = createGlobalStyle`
.rc-collapse-anim-active {
  transition: height 0.3s ease;
}

.rc-collapse-content {
  overflow: hidden;
}

.rc-collapse-content-inactive {
  display: none;
}
`;

export default GenericCollapseGlobalStyles;
