import type { Action } from 'redux';

import type { CHANGE_MESSAGES_WINDOW_STATE } from './actionTypes';

export enum MessagesStates {
  ClientMessagesOpen = 'ClientMessagesOpen',
  ClientPracticeMessagesOpen = 'ClientPracticeMessagesOpen',
  MessagesClosed = 'MessagesClosed',
}

export interface MessagesState {
  messagesState: MessagesStates;
}

export type ChangeMessagesStateAction = Action<typeof CHANGE_MESSAGES_WINDOW_STATE> & {
  messagesState: MessagesStates;
};

export type MessagesActionTypes = ChangeMessagesStateAction;
