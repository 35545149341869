import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import routes from 'routes';
import type { AdviceRoom } from 'types/entities/adviceRoom';

import RecordingProvider from './RecordingProvider';
import { RoomConnectionState } from './RoomConnectionState';
import { RoomType } from './types';
import VideoChat from './VideoChat';
import { useVideoContext } from './VideoContext';

export function VideoChatFloatingWindow() {
  const { connectionState, leaveRoom, room, roomData } = useVideoContext();
  const match = useRouteMatch({
    path: [
      routes.client.clientMeetingRoom.testSetup.routerPath(),
      routes.meetings.clientMeetingRoom.testSetup.routerPath(),
      routes.meetings.clientMeetingRoom.meeting.routerPath(),
      routes.client.clientMeetingRoom.meeting.routerPath(),
    ],
  });

  return !match && connectionState !== RoomConnectionState.Disconnected ? (
    <>
      {roomData?.roomType === RoomType.AdviceRoom && roomData.roomId ? (
        <RecordingProvider adviceRoom={{ id: roomData.roomId } as AdviceRoom} room={room}>
          <VideoChat onLeave={leaveRoom} isFloating />
        </RecordingProvider>
      ) : (
        <VideoChat onLeave={leaveRoom} isFloating />
      )}
    </>
  ) : null;
}
