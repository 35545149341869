import React from 'react';
import { useField } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { isEmpty, map } from 'lodash';
import { rem } from 'polished';
import styled from 'styled-components';

import { usePreferredAdvisersQuery } from 'hooks/queries/usePreferredAdvisersQuery';
import { Avatar, AvatarSize } from 'components/Avatar';
import EmptyListMsg from 'components/EmptyListMsg/EmptyListMsg';
import GenericCheckbox from 'components/GenericCheckbox/GenericCheckbox';
import GenericLoader from 'components/GenericLoader/GenericLoader';
import Margin from 'components/Margin/Margin';

import { Fields } from '../../types';

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: start;
`;

const Button = styled.button`
  width: ${rem(160)};
  padding: ${rem(10)};
  outline: none;
  word-break: break-word;
  word-wrap: break-word;

  &:focus,
  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.colors.catshillWhiteLighter};
  }
`;

const RoleName = styled.div`
  margin-top: ${rem(10)};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizes.dbSmallSm};
  letter-spacing: 0.12em;
  color: ${({ theme }) => theme.colors.textLight};
`;

export function PreferredAdvisersList() {
  const { data: preferredAdvisers, isFetching } = usePreferredAdvisersQuery({
    staleTime: Infinity,
  });

  // https://final-form.org/docs/react-final-form/types/FieldProps#format
  const { input } = useField<undefined | null | number>(Fields.PreferredAdviserId, {
    format: v => v,
    allowNull: true,
  });

  const renderList = () =>
    isEmpty(preferredAdvisers) ? (
      <EmptyListMsg
        text={<FormattedMessage id="onboarding.stepPreferredAdviser.noAvailableAdvisers" />}
      />
    ) : (
      <List role="radiogroup">
        {map(preferredAdvisers, adviser => {
          const isChecked = input.value === adviser.id;

          return (
            <Button
              key={adviser.id}
              type="button"
              role="radio"
              aria-checked={isChecked}
              onClick={() => {
                input.onChange(adviser.id);
              }}
            >
              <Avatar size={AvatarSize.L} src={adviser.avatar} isActive={isChecked} />
              <Margin top={10}>{adviser.fullName}</Margin>
              {adviser.title && <RoleName>{adviser.title}</RoleName>}
            </Button>
          );
        })}
      </List>
    );

  return (
    <div data-test="preferred-adviser-list">
      <GenericCheckbox
        label={<FormattedMessage id="onboarding.stepPreferredAdviser.checkboxLabel" />}
        onChange={isChecked => {
          // we're using null to indicate that user does not have preferred adviser and
          // undefined to indicate that no selection has beed made yet
          input.onChange(isChecked ? null : undefined);
        }}
        checked={input.value === null}
        isBig
      />

      {isFetching ? <GenericLoader isVisible /> : renderList()}
    </div>
  );
}
