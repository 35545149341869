import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';
import type { SignatureData } from 'types/entities/signatureData';

export interface GuestSignPatchOptions {
  authToken: string;
  recordClass: 'Addendum' | 'Document';
  recordId: number;
  signatures: SignatureData[];
  publishAcknowledgement?: boolean;
}

export function guestSignApi(path: string) {
  return {
    path,
    PATCH: ({ signatures, ...data }: GuestSignPatchOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: {
            ...mapFormData(data),
            signatures: signatures.map(signature => mapFormData(signature)),
          },
        },
      }),
  };
}
