import { Root, route } from '../utils';

const practiceSettingsPath = `${Root}practice-settings`;

export default {
  practiceSettings: {
    ...route(practiceSettingsPath),
    general: route(`${practiceSettingsPath}/general`),
    clientMeetingRooms: route(`${practiceSettingsPath}/client-meeting-rooms`),
    clientMedia: route(`${practiceSettingsPath}/client-media`),
    requestingAdvice: route(`${practiceSettingsPath}/requesting-advice`),
    prospectOnboarding: route(`${practiceSettingsPath}/prospect-onboarding`),
    landingPages: route(`${practiceSettingsPath}/landing-pages`),
    faq: route(`${practiceSettingsPath}/faq`),
  },
};
