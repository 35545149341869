import api from 'api/apiInstance';

export type UsersNotificationsMarkAsSeenPatchOptions = { notificationsIds: number[] };

export function usersNotificationsMarkAsSeenApi(path: string) {
  return {
    path,
    PATCH: ({ notificationsIds }: UsersNotificationsMarkAsSeenPatchOptions) =>
      api.patch(path, {
        data: {
          attributes: {
            notifications_ids: notificationsIds,
          },
        },
      }),
  };
}
