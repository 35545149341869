import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';

export function clientCaseAddendumViewApi(path: string) {
  return {
    path,
    PATCH: () => api.patch<JsonApiResponseSingle>(path),
  };
}
