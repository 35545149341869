import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';

export type UserNotificationsMetaGetResponse = { unseen_notifications_count: number };

export function usersNotificationsMetaApi(path: string) {
  return {
    path,
    GET: (config?: RequestConfig) => api.get<UserNotificationsMetaGetResponse>(path, config),
  };
}
