import React from 'react';
import { useField, UseFieldConfig } from 'react-final-form';

import GenericCheckbox from 'components/GenericCheckbox/GenericCheckbox';
import { TooltipPlacement } from 'components/GenericTooltip';

export interface Props extends Omit<UseFieldConfig<boolean>, 'type'> {
  name: string;
  label: React.ReactNode;
  checkboxRef?: React.RefObject<HTMLInputElement>;
  isLight?: boolean;
  isChecked?: boolean;
  isDark?: boolean;
  isLabelContentCentered?: boolean;
  isSmall?: boolean;
  isBig?: boolean;
  isFlex?: boolean;
  hasMarginBottomMd?: boolean;
  hasNoMargin?: boolean;
  tooltipText?: string;
  isDisabled?: boolean;
  tooltipPosition?: TooltipPlacement;
}

export default function FieldCheckbox({
  name,
  label,
  checkboxRef,
  isLight,
  isDark,
  isLabelContentCentered,
  isSmall,
  isBig,
  isFlex,
  hasMarginBottomMd,
  hasNoMargin,
  tooltipText,
  isDisabled,
  isChecked,
  tooltipPosition = TooltipPlacement.Top,
  ...fieldProps
}: Props) {
  const { input, meta } = useField(name, { ...fieldProps, type: 'checkbox' });

  return (
    <div data-test={`checkbox-${name}`}>
      <GenericCheckbox
        checkboxRef={checkboxRef}
        tooltipText={tooltipText}
        isDisabled={isDisabled}
        tooltipPosition={tooltipPosition}
        onChange={input.onChange}
        label={label}
        isLight={isLight}
        isDark={isDark}
        isLabelContentCentered={isLabelContentCentered}
        isSmall={isSmall}
        isBig={isBig}
        isFlex={isFlex}
        hasMarginBottomMd={hasMarginBottomMd}
        hasNoMargin={hasNoMargin}
        error={meta.touched && meta.error ? meta.error : undefined}
        checked={input.checked || isChecked}
      />
    </div>
  );
}
