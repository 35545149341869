import React from 'react';

import SvgIcon from '../SvgIcon';

const IconPhoneReceiver = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 27 12" {...props}>
    <path
      d="M.46 11.66c-.3-.23-.45-.54-.45-.93l.02-4.56c-.01-.22.02-.45.1-.7a2.36 2.36 0 0 1 .65-.98A11.19 11.19 0 0 1 4.3 2.3c1-.4 2.24-.77 3.72-1.08a25.3 25.3 0 0 1 5.26-.44c1.6-.01 3.02.08 4.27.28 1.25.2 2.34.44 3.28.73.93.3 1.73.61 2.39.95.66.34 1.2.66 1.62.97a6.04 6.04 0 0 1 1.24 1.14 2.37 2.37 0 0 1 .45 1.34v4.58c0 .32-.1.59-.3.8a.9.9 0 0 1-.53.26c-.2.03-.4.02-.6-.03l-5.34-1.65a1.19 1.19 0 0 1-.7-.52 1.52 1.52 0 0 1-.25-.84v-1.7a.4.4 0 0 0-.05-.16.6.6 0 0 0-.1-.16 4.4 4.4 0 0 0-1.14-.52c-.42-.13-.96-.26-1.65-.37a15.8 15.8 0 0 0-2.59-.14c-1.04-.02-1.9.03-2.6.14-.7.1-1.25.23-1.65.36-.41.13-.7.25-.87.37l-.26.17a.58.58 0 0 0-.09.14.4.4 0 0 0-.05.17l.02 1.98c-.02.39-.17.72-.44 1-.2.2-.39.31-.57.36l-.02.01-5.28 1.36c-.39.09-.72.04-1-.14z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default IconPhoneReceiver;
