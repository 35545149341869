import { getAdviceRoomSyncStream } from 'api/twilio/twilioSync';
import {
  ADVICE_ROOM_RECORDING_STATE_UPDATE_STARTED,
  ADVICE_ROOM_RECORDING_STATE_UPDATED,
} from 'constants/twilio';
import type { ThunkAction } from 'store/types';
import type { RecordingType } from 'components/VideoChat/RecordingContext';
import type { RoomConnectionState } from 'components/VideoChat/RoomConnectionState';

import { SYNC_ADVICE_ROOM_CONNECTION_STATE, SYNC_CONNECTION_STATE } from './actionTypes';
import { getIsConnectedToRoom } from './selectors';
import type { SyncAdviceRoomConnectionState, SyncConnectionState } from './types';

export const syncConnectionState = (isConnectedToRoom: boolean): SyncConnectionState => ({
  type: SYNC_CONNECTION_STATE,
  payload: isConnectedToRoom,
});

export const syncAdviceRoomConnectionState = (
  adviceRoomConnectionState: RoomConnectionState,
  joinedAdviceRoomId?: number
): SyncAdviceRoomConnectionState => ({
  type: SYNC_ADVICE_ROOM_CONNECTION_STATE,
  payload: {
    adviceRoomConnectionState,
    joinedAdviceRoomId,
  },
});

export interface AdviceRoomRecordingStateSyncMessage {
  type: typeof ADVICE_ROOM_RECORDING_STATE_UPDATED;
  payload: { recordingType: RecordingType; recording: boolean };
}

export interface AdviceRoomRecordingStateUpdateStartedMessage {
  type: typeof ADVICE_ROOM_RECORDING_STATE_UPDATE_STARTED;
  payload: { isLoading: boolean };
}

type SyncMessage =
  | AdviceRoomRecordingStateSyncMessage
  | AdviceRoomRecordingStateUpdateStartedMessage;

const sendSyncMessage =
  (adviceRoomId: number, message: SyncMessage): ThunkAction =>
  (dispatch, getState) => {
    if (getIsConnectedToRoom(getState())) {
      getAdviceRoomSyncStream(adviceRoomId).then(stream => stream.publishMessage(message));
    }
  };

export const sendAdviceRoomRecordingStateUpdatedMessage = (
  adviceRoomId: number,
  payload: {
    recordingType: RecordingType;
    recording: boolean;
  }
) => {
  return sendSyncMessage(adviceRoomId, {
    type: ADVICE_ROOM_RECORDING_STATE_UPDATED,
    payload,
  });
};

export const sendAdviceRoomRecordingUpdateStartedMessage = (
  adviceRoomId: number,
  payload: {
    isLoading: boolean;
  }
) => {
  return sendSyncMessage(adviceRoomId, {
    type: ADVICE_ROOM_RECORDING_STATE_UPDATE_STARTED,
    payload,
  });
};
