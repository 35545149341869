import React from 'react';
import { FormattedMessage } from 'react-intl';
import { rem } from 'polished';
import styled from 'styled-components';

import Margin from 'components/Margin/Margin';
import { PermissionsHelpLink } from 'components/TestSetupTwilio/components/PermissionsHelpLink';
import { StatusIcon } from 'components/TestSetupTwilio/components/StatusIcon';
import { DevicePermissions } from 'components/TestSetupTwilio/types';

import { VerificationStatus } from '../types';

const PermissionsDeniedInfo = styled.div<{ isTestSetup?: boolean }>`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${rem(20)};

  p {
    margin: 0;
    ${({ isTestSetup }) => isTestSetup && `font-size: 12px;`};

    &:first-child {
      margin-bottom: ${({ isTestSetup }) => (isTestSetup ? rem(2) : rem(6))};
    }
  }
`;

interface Props {
  microphone?: DevicePermissions;
  camera?: DevicePermissions;
  isTestSetup?: boolean;
}
const VideoChatPermissionMessage = ({ microphone, camera, isTestSetup = false }: Props) => {
  const renderMessage = () => {
    let deniedPermission = 'noPermissions';
    let permissionFor: 'both' | 'camera' | 'microphone' = 'both';

    const browserPermissionDenied =
      microphone === DevicePermissions.Denied || camera === DevicePermissions.Denied;
    const systemPermissionDenied =
      microphone === DevicePermissions.SystemDenied || camera === DevicePermissions.SystemDenied;

    if (browserPermissionDenied && systemPermissionDenied) {
      const permissionSystem =
        microphone === DevicePermissions.SystemDenied ? 'microphone' : 'camera';
      const permissionBrowser = microphone === DevicePermissions.Denied ? 'microphone' : 'camera';

      return (
        <>
          <FormattedMessage
            id="adviceRooms.adviceRoom.noMixedPermissions"
            values={{
              permissionBrowser: permissionBrowser === 'microphone' ? permissionBrowser : 'webcam',
              permissionSystem: permissionSystem === 'microphone' ? permissionSystem : 'webcam',
            }}
            tagName="p"
          />

          <PermissionsHelpLink browserDenied={permissionBrowser} systemDenied={permissionSystem} />
        </>
      );
    }

    if (browserPermissionDenied) {
      if (microphone === DevicePermissions.Denied && camera === DevicePermissions.Denied) {
        deniedPermission = 'noPermissions';
        permissionFor = 'both';
      } else if (microphone === DevicePermissions.Denied) {
        deniedPermission = 'noMicPermission';
        permissionFor = 'microphone';
      } else if (camera === DevicePermissions.Denied) {
        deniedPermission = 'noWebcamPermission';
        permissionFor = 'camera';
      }

      return !deniedPermission ? null : (
        <>
          <FormattedMessage
            id={`adviceRooms.adviceRoom.${deniedPermission}`}
            values={{ permissionComingFrom: 'browser' }}
            tagName="p"
          />
          <PermissionsHelpLink browserDenied={permissionFor} />
        </>
      );
    }

    if (
      microphone === DevicePermissions.SystemDenied &&
      camera === DevicePermissions.SystemDenied
    ) {
      deniedPermission = 'noPermissions';
      permissionFor = 'both';
    } else if (microphone === DevicePermissions.SystemDenied) {
      deniedPermission = 'noMicPermission';
      permissionFor = 'microphone';
    } else if (camera === DevicePermissions.SystemDenied) {
      deniedPermission = 'noWebcamPermission';
      permissionFor = 'camera';
    }

    return !deniedPermission ? null : (
      <>
        <FormattedMessage
          id={`adviceRooms.adviceRoom.${deniedPermission}`}
          values={{ permissionComingFrom: 'system' }}
          tagName="p"
        />
        <PermissionsHelpLink systemDenied={permissionFor} />
      </>
    );
  };

  if (
    camera !== DevicePermissions.SystemDenied &&
    microphone !== DevicePermissions.SystemDenied &&
    camera !== DevicePermissions.Denied &&
    microphone !== DevicePermissions.Denied
  )
    return null;

  return (
    <PermissionsDeniedInfo isTestSetup={isTestSetup}>
      <Margin right={isTestSetup ? 5 : 20}>
        <StatusIcon status={VerificationStatus.Error} size={isTestSetup ? 13 : 40} />
      </Margin>

      <div>{renderMessage()}</div>
    </PermissionsDeniedInfo>
  );
};

export default VideoChatPermissionMessage;
