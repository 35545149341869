import React from 'react';

import SvgIcon from './SvgIcon';

const IconArrowRightSec = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 512 512" {...props}>
    <path
      d="M487.292 235.408H20.59c-11.372 0-20.59 9.224-20.59 20.59s9.217 20.59 20.59
        20.59h466.702c11.372 0 20.59-9.217 20.59-20.59s-9.217-20.59-20.59-20.59z"
      fill="currentColor"
    />
    <path
      d="M505.96 241.434L304.187 39.653c-8.044-8.037-21.07-8.037-29.114 0-8.044 8.044-8.044 21.084
        0 29.121l187.216 187.223-187.216 187.224c-8.044 8.037-8.044 21.077 0 29.114 4.022 4.022
        9.286 6.033 14.557 6.033s10.535-2.011 14.557-6.033l201.773-201.78c8.044-8.044 8.044-21.085
        0-29.121z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default IconArrowRightSec;
