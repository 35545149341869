import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, RequestConfig } from 'api/types';

import { clientCaseFinancialPlanningGoalsPrintApi } from './print';

export type ClientCaseFinancialPlanningGoalsGetOptions = GetOptions | undefined;
export type ClientCaseFinancialPlanningGoalsGetResponse = JsonApiResponse;

export interface ClientCaseFinancialPlanningGoalsPostOptions {
  name: string;
  amount: number;
  amountCurrency: string;
  duration: number;
}
export type ClientCaseFinancialPlanningGoalsPostResponse = JsonApiResponseSingle;

export function clientCaseFinancialPlanningGoalsApi(path: string) {
  return {
    path,
    print: () => clientCaseFinancialPlanningGoalsPrintApi(`${path}/print`),
    GET: (params?: ClientCaseFinancialPlanningGoalsGetOptions, config?: RequestConfig) =>
      api.get<ClientCaseFinancialPlanningGoalsGetResponse>(path, { params, ...config }),
    POST: ({
      name,
      amount,
      amountCurrency,
      duration,
    }: ClientCaseFinancialPlanningGoalsPostOptions) =>
      api.post<ClientCaseFinancialPlanningGoalsPostResponse>(path, {
        data: {
          attributes: {
            name,
            amount: Number(amount),
            amount_currency: amountCurrency,
            duration: Number(duration),
          },
        },
      }),
  };
}
