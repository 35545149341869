import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

import { clientDashboardLinkApi } from './clientDashboardLink';

export interface ClientDashboardLinksPostOptions {
  title: string;
  description: string;
  link: string;
  image: File;
}

const apiPath = '/api/v1/client-dashboard-links';

export function clientDashboardLinksApi(path: string) {
  return {
    path,
    GET: () => api.get<JsonApiResponse>(path),
    POST: (values: ClientDashboardLinksPostOptions) =>
      api.post<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(values),
          },
        })
      ),
  };
}

type ClientDashboardLinkApi = ReturnType<typeof clientDashboardLinkApi>;
type ClientDashboardLinksApi = ReturnType<typeof clientDashboardLinksApi>;

function clientDashboardLinks(): ClientDashboardLinksApi;
function clientDashboardLinks(clientId: number): ClientDashboardLinkApi;
function clientDashboardLinks(clientId?: number): ClientDashboardLinksApi | ClientDashboardLinkApi {
  return clientId
    ? clientDashboardLinkApi(`${apiPath}/${clientId}`)
    : clientDashboardLinksApi(apiPath);
}

export { clientDashboardLinks };
