import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions } from 'api/types';
import { mapFormData } from 'helpers/formData';
import { objectToURLParams } from 'helpers/utils';

export type RecordParams = {
  recordId: number;
  recordClass: string;
};

export function docusignDocumentsApi(path: string, documentId: number) {
  const certificatePath = `${path}/certificate_of_completion`;
  const combinedDocumentPath = `${path}/combined_document`;

  return {
    path,
    DOWNLOAD: (params?: RecordParams) =>
      `${path}/download?${objectToURLParams(mapFormData(params))}`,
    COMBINED_DOWNLOAD: (params?: RecordParams) =>
      `${combinedDocumentPath}?${objectToURLParams(mapFormData(params))}`,
    SHOW: (params?: GetOptions) =>
      api.get<JsonApiResponseSingle>(`${path}/${documentId}`, { params }),
    CERTIFICATE: (params: RecordParams) =>
      `${certificatePath}?${objectToURLParams(mapFormData(params))}`,
    OPEN: (params: RecordParams) => `${path}?${objectToURLParams(mapFormData(params))}`,
  };
}
