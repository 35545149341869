import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const pulse = keyframes`
0% {
  opacity: 0;
}

50% {
  opacity: 1;
}

100% {
  opacity: 0;
}
`;
