import api from 'api/apiInstance';
import { convertJsonToFormData, mapFormDataDeep } from 'helpers/formData';
import type { Tag } from 'types/entities/clientCase';

export interface DocumentPacksBulkCreateFile {
  file: File;
  name: string;
  signatureRequired: boolean;
  docusignSignatureRequired: boolean;
  shouldSendSignatureReminder?: boolean;
  autoEmailEnabled?: boolean;
  noteForClient?: string;
}

export interface DocumentPacksBulkCreateOptions {
  coverNote: string;
  documents?: DocumentPacksBulkCreateFile[];
  name: string;
  image?: File;
  existingImageId?: number;
  tags?: Tag[];
  adviserId?: number;
}

export function documentPacksBulkCreateApi(path: string) {
  return {
    path,
    POST: ({ documents, coverNote, ...restParams }: DocumentPacksBulkCreateOptions) =>
      api.post(
        path,
        convertJsonToFormData({
          data: mapFormDataDeep({
            attributes: restParams,
            relationships: {
              documentsPack: {
                data: {
                  coverNote,
                },
              },
              documents: {
                data: documents,
              },
            },
          }),
        })
      ),
  };
}
