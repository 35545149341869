import React from 'react';

import SvgIcon from './SvgIcon';

const IconCheckmarkSec = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 342 342" {...props}>
    <path fill="currentColor" d="M290 33.3L119 204.4 52.3 138 0 190.2l118.9 118.9L342.4 85.6z" />
  </SvgIcon>
);

export default IconCheckmarkSec;
