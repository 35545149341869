import React from 'react';

import SvgIcon from './SvgIcon';

const IconEdit = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 12 12" {...props}>
    <path
      d="M11.405 6.595L7.28 2.47a.75.75 0 0 0-1.06 0L.97 7.72a.752.752 0 0 0-.198.348l-.75 3a.752.752 0 0 0 .91.91l3-.75a.749.749 0 0 0 .348-.198L9 6.31l.815.815-2.47 2.47a.75.75 0 1 0 1.06 1.06l3-3a.75.75 0 0 0 0-1.06zM8.34 1.41l2.25 2.25.944-.944a1.592 1.592 0 0 0-2.25-2.25l-.943.943z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default IconEdit;
