export default {
  title: `Library`,
  description: `Here you can upload media content for your advisers, practice admins and clients to view. This can be done in PDF format or link.`,
  tileDescription: `Browse a selection of potentially useful brochures, articles and other media content.`,
  notAvailable: `Library content is not available`,
  enabled: `Library content is enabled`,
  disabled: `Library content  is disabled`,
  globalBrochures: {
    title: `Pre-approved contents`,
    enabled: `Pre-approved content will be displayed on clients’ dashboards`,
    disabled: `Pre-approved content will NOT be displayed on clients’ dashboards`,
    cannotBeEnabled: `Pre-approved content cannot be enabled because no content is currently available`,
    availableBrochures: `Available contents`,
  },
  practiceBrochures: {
    title: `Practice-specific content`,
    created: `Library content has been successfully added`,
    updated: `Library content has been successfully updated`,
    removed: `Library content has been successfully removed`,
    create: `Add Library content`,
    maxItems: `There can be max {value} items.`,
    sortingMessage: `To change the order of practice content on clients’ dashboards, enable sorting and then rearrange the rows to set the correct order.`,
    visibleUntil: `Display this library content until`,
    removeWarning: `Are you sure you want to remove this practice content?`,
    expirationWarning: `Please note that approximately one month after a content’s validity period passes, the content will be automatically removed.`,
    edit: `Edit Library content`,
    labels: {
      file: `I would like to add a PDF`,
      externalUrl: `I would like to add a link`,
    },
  },
  imagePreview: `Library content's tile preview:`,
  file: `File: {fileName}`,
};
