import type { EventLinks, EventType } from './event';

export enum AuditTrailModel {
  Case = 'client-case',
  ClientAccount = 'client',
  AdviceRoom = 'advice-room',
  FinancialStrategy = 'financial-strategy',
  TopUp = 'top-up',
  DocumentVault = 'document-vault',
}

export interface AuditTrailEntry {
  eventType?: EventType;
  id: string;
  date: string;
  userName: string;
  userRole: string;
  eventMsg: string;
  links: EventLinks;
}

export interface AuditTrail {
  date: string;
  events: AuditTrailEntry[];
}
