import React from 'react';
import { map, size, times } from 'lodash';
import { darken, rgba } from 'polished';
import styled, { css } from 'styled-components';
import type { Room } from 'twilio-video';

import { getParticipantAudioTrack } from '../Participant/helpers';
import { LocalParticipantComponent } from '../Participant/LocalParticipant';
import { RemoteParticipantComponent } from '../Participant/RemoteParticipant';
import AudioTrack from '../Track/AudioTrack';

const Wrapper = styled.div<{ gridSize: number }>`
  position: relative;
  display: grid;
  width: 100%;
  height: 100%;

  ${({ theme, gridSize }) => {
    const background = rgba(darken(0.05, theme.colors.greyLighten), 0.5);

    if (gridSize > 6) {
      return css`
        grid-template-columns: 33.33% 33.33% 33.33%;
        grid-template-rows: 33.33% 33.33% 33.33%;

        & > * {
          &:nth-child(odd) {
            background-color: ${background};
          }
        }
      `;
    }

    if (gridSize > 4) {
      return css`
        grid-template-columns: 33.33% 33.33% 33.33%;
        grid-template-rows: 50% 50%;

        & > * {
          &:nth-child(odd) {
            background-color: ${background};
          }
        }
      `;
    }

    return css`
      grid-template-columns: 50% 50%;
      grid-template-rows: 50% 50%;

      & > * {
        &:nth-child(1),
        &:nth-child(4) {
          background-color: ${background};
        }
      }
    `;
  }}
`;

const LocalParticipantWrapper = styled.div<{ columnCount: number; rowCount: number }>`
  grid-column: ${({ columnCount }) => columnCount};
  grid-row: ${({ rowCount }) => rowCount};
`;

interface Props {
  room: Room;
}

export function GridLayout({ room }: Props) {
  const remoteParticipants = Array.from(room.participants.values());
  const participantsTotal = remoteParticipants.length + 1;
  const columnCount = participantsTotal > 4 ? 3 : 2;
  const rowCount = participantsTotal > 6 ? 3 : 2;
  const gridSize = columnCount * rowCount;

  return (
    <Wrapper gridSize={gridSize}>
      {map(remoteParticipants, participant => {
        const audioTrack = getParticipantAudioTrack(participant);

        return (
          <div key={participant.identity}>
            <RemoteParticipantComponent participant={participant} />
            <AudioTrack track={audioTrack?.track} />
          </div>
        );
      })}

      {times(gridSize - 1 - size(remoteParticipants), emptyCellIndex => {
        return <div key={emptyCellIndex} />;
      })}

      <LocalParticipantWrapper columnCount={columnCount} rowCount={rowCount}>
        <LocalParticipantComponent participant={room.localParticipant} />
      </LocalParticipantWrapper>
    </Wrapper>
  );
}
