import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, PaginationOptions, RequestConfig } from 'api/types';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';
import type { MotivationCentreItemType } from 'types/entities/motivationCentreItem';

export type MotivationCentreItemsGetOptions = GetOptions & PaginationOptions;
export interface MotivationCentreItemsPostOptions {
  title?: string;
  description?: string;
  link?: string;
  file?: File;
  backgroundImage?: File;
  itemType: MotivationCentreItemType;
}

export function clientMotivationCentreItemsApi(path: string) {
  return {
    path,
    GET: (
      { pageSize, pageNumber, ...rest }: MotivationCentreItemsGetOptions,
      config?: RequestConfig
    ) =>
      api.get<JsonApiResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...rest,
        },
        ...config,
      }),
    POST: (params: MotivationCentreItemsPostOptions) =>
      api.post<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      ),
  };
}
