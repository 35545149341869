import React from 'react';

import SvgIcon from '../SvgIcon';

const IconSettings = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 17 16" {...props}>
    <path
      d="M1 4h3m5 0h6M4 7h3V1H4zm5 8h3V9H9zm-8-3h6m6 0h3"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default IconSettings;
