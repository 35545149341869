import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

export interface AdviceRoomResendInvitationsPostOptions {
  guestIds: number[];
  attendeeIds: number[];
}

export function adviceRoomResendInvitationstApi(path: string) {
  return {
    path,
    POST: (values: AdviceRoomResendInvitationsPostOptions) =>
      api.post(path, {
        data: {
          attributes: mapFormData(values),
        },
      }),
  };
}
