import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';

import type { ClientsMetaDataGetOptions } from '..';

export interface ClientsMetaDashboardGetResponse {
  action_required_number: number;
  created_clients_number: number;
  all_clients_number: number;
  awaiting_clients_number: number;
  shared_clients_number: number;
}

export function clientsMetaDashboardApi(path: string) {
  return {
    path,
    GET: ({ clientTagIds, ...rest }: ClientsMetaDataGetOptions, config?: RequestConfig) =>
      api.get<ClientsMetaDashboardGetResponse>(path, {
        params: {
          client_tag_ids: clientTagIds,
          ...rest,
        },
        ...config,
      }),
  };
}
