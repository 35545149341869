import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions } from 'api/types';
import { mapFormData } from 'helpers/formData';

import { clientsAdditionalAdvisersApi } from './additionalAdvisers';
import { clientAdviceRoomAttendeesApi } from './adviceRoomAttendees';
import { clientAdviceRoomsApi } from './adviceRooms';
import { clientAuditTrailApi } from './auditTrail';
import { clientAvatarApi } from './avatar';
import { clientChangeClientAdviserApi } from './changeClientAdviser';
import { clientClientCasesApi } from './clientCases';
import { clientClientCaseApi } from './clientCases/clientCase';
import { clientClientIdentificationsApi } from './clientIdentifications';
import { clientClientMessagesApi } from './clientMessages';
import { clientClientMessageApi } from './clientMessages/clientMessage';
import { clientClientTagsApi } from './clientTags';
import { clientDocumentPacksApi } from './documentPacks';
import { clientDocumentVaultItemsApi } from './documentVaultItems';
import { clientDocumentVaultItemApi } from './documentVaultItems/documentVaultItem';
import { clientEstateSummaryApi } from './estateSummary';
import { clientFactFindsApi } from './factFinds';
import { clientFinancialStrategiesApi } from './financialStrategies';
import { clientFinancialStrategyApi } from './financialStrategies/financialStrategy';
import { clientFollowsApi } from './follows';
import { clientIdentityVerificationsApi } from './identityVerifications';
import { clientIdentityVerificationApi } from './identityVerifications/identityVerification';
import { clientInteractionReportsApi } from './interactionReports';
import { clientInteractionReportApi } from './interactionReports/interactionReport';
import { clientMailingListApi } from './mailingList';
import { clientMeetingRequestsApi } from './meetingRequests';
import { clientMessagesInteractionReportsApi } from './messagesInteractionReports';
import { clientMessagesInteractionReportApi } from './messagesInteractionReports/messagesInteractionReport';
import { clientMetaApi } from './meta';
import { clientMotivationCentreItemsApi } from './motivationCentreItems';
import { clientMotivationCentreItemApi } from './motivationCentreItems/motivationCentreItem';
import { clientOnlineAdviceRequestsApi } from './onlineAdviceRequests';
import { clientPracticeMessagesApi } from './practiceMessages';
import { clientPracticeMessageApi } from './practiceMessages/practiceMessage';
import { clientRecommendationsApi } from './recommendations';
import { clientRemoveApi } from './remove';
import { clientResendApi } from './resend';
import { clientReviewQuestionnairesApi } from './reviewQuestionnaires';
import { clientSummaryInteractionReportsApi } from './summaryInteractionReports';
import { clientSummaryInteractionReportApi } from './summaryInteractionReports/summaryInteractionReport';
import { clientTodoListApi } from './todoList';
import { clientTopUpsApi } from './topUps';
import { clientTopUpApi } from './topUps/topUp';
import { clientUnblockApi } from './unblock';

export type ClientGetOptions = GetOptions;

export interface ClientPatchOptions {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  dateOfBirth?: string;
  country?: string;
  line1?: string;
  line2?: string;
  city?: string;
  postCode?: string;
  currentPassword: string;
}

export function clientApi(path: string) {
  type ClientsAdditionalAdvisersApi = ReturnType<typeof clientsAdditionalAdvisersApi>;

  function additionalAdvisers(): ClientsAdditionalAdvisersApi {
    const additionalAdvisersApiPath = `${path}/additional-advisers`;

    return clientsAdditionalAdvisersApi(additionalAdvisersApiPath);
  }

  type ClientClientCaseApi = ReturnType<typeof clientClientCaseApi>;
  type ClientClientCasesApi = ReturnType<typeof clientClientCasesApi>;

  function clientCases(): ClientClientCasesApi;
  function clientCases(clientCaseId: number): ClientClientCaseApi;
  function clientCases(clientCaseId?: number): ClientClientCaseApi | ClientClientCasesApi {
    const clientCasesPath = `${path}/client-cases`;

    return clientCaseId
      ? clientClientCaseApi(`${clientCasesPath}/${clientCaseId}`)
      : clientClientCasesApi(clientCasesPath);
  }

  type ClientIdentityVerificationsApi = ReturnType<typeof clientIdentityVerificationsApi>;
  type ClientIdentityVerificationApi = ReturnType<typeof clientIdentityVerificationApi>;

  function identityVerifications(): ClientIdentityVerificationsApi;
  function identityVerifications(identityVerificationId: number): ClientIdentityVerificationApi;
  function identityVerifications(
    identityVerificationId?: number
  ): ClientIdentityVerificationApi | ClientIdentityVerificationsApi {
    const identityVerificationPath = `${path}/client-identity-verifications`;

    return identityVerificationId
      ? clientIdentityVerificationApi(`${identityVerificationPath}/${identityVerificationId}`)
      : clientIdentityVerificationsApi(identityVerificationPath);
  }

  type ClientClientMessageApi = ReturnType<typeof clientClientMessageApi>;
  type ClientClientMessagesApi = ReturnType<typeof clientClientMessagesApi>;

  function clientMessages(): ClientClientMessagesApi;
  function clientMessages(messageId: number): ClientClientMessageApi;
  function clientMessages(messageId?: number): ClientClientMessagesApi | ClientClientMessageApi {
    const clientClientMessagesPath = `${path}/client-messages`;

    return messageId
      ? clientClientMessageApi(`${clientClientMessagesPath}/${messageId}`)
      : clientClientMessagesApi(clientClientMessagesPath);
  }

  type ClientDocumentVaultItemApi = ReturnType<typeof clientDocumentVaultItemApi>;
  type ClientDocumentVaultItemsApi = ReturnType<typeof clientDocumentVaultItemsApi>;

  function documentVaultItems(): ClientDocumentVaultItemsApi;
  function documentVaultItems(itemId: number): ClientDocumentVaultItemApi;
  function documentVaultItems(
    itemId?: number
  ): ClientDocumentVaultItemsApi | ClientDocumentVaultItemApi {
    const documentVaultItemsApiPath = `${path}/document-vault-items`;

    return itemId
      ? clientDocumentVaultItemApi(`${documentVaultItemsApiPath}/${itemId}`)
      : clientDocumentVaultItemsApi(documentVaultItemsApiPath);
  }

  type ClientFinancialStrategyApi = ReturnType<typeof clientFinancialStrategyApi>;
  type ClientFinancialStrategiesApi = ReturnType<typeof clientFinancialStrategiesApi>;

  function financialStrategies(): ClientFinancialStrategiesApi;
  function financialStrategies(id: number): ClientFinancialStrategyApi;
  function financialStrategies(
    id?: number
  ): ClientFinancialStrategiesApi | ClientFinancialStrategyApi {
    const documentVaultItemsApiPath = `${path}/financial-strategies`;

    return id
      ? clientFinancialStrategyApi(`${documentVaultItemsApiPath}/${id}`)
      : clientFinancialStrategiesApi(documentVaultItemsApiPath);
  }

  type ClientMessagesInteractionReportApi = ReturnType<typeof clientMessagesInteractionReportApi>;
  type ClientMessagesInteractionReportsApi = ReturnType<typeof clientMessagesInteractionReportsApi>;

  function messagesInteractionReports(): ClientMessagesInteractionReportsApi;
  function messagesInteractionReports(reportId: number): ClientMessagesInteractionReportApi;
  function messagesInteractionReports(
    reportId?: number
  ): ClientMessagesInteractionReportsApi | ClientMessagesInteractionReportApi {
    const messagesInteractionReportsApiPath = `${path}/messages-interaction-reports`;

    return reportId
      ? clientMessagesInteractionReportApi(`${messagesInteractionReportsApiPath}/${reportId}`)
      : clientMessagesInteractionReportsApi(messagesInteractionReportsApiPath);
  }

  type ClientSummaryInteractionReportApi = ReturnType<typeof clientSummaryInteractionReportApi>;
  type ClientSummaryInteractionReportsApi = ReturnType<typeof clientSummaryInteractionReportsApi>;

  function summaryInteractionReports(): ClientSummaryInteractionReportsApi;
  function summaryInteractionReports(reportId: number): ClientSummaryInteractionReportApi;
  function summaryInteractionReports(
    reportId?: number
  ): ClientSummaryInteractionReportsApi | ClientSummaryInteractionReportApi {
    const summaryInteractionReportsApiPath = `${path}/summary-interaction-reports`;

    return reportId
      ? clientSummaryInteractionReportApi(`${summaryInteractionReportsApiPath}/${reportId}`)
      : clientSummaryInteractionReportsApi(summaryInteractionReportsApiPath);
  }

  type ClientInteractionReportApi = ReturnType<typeof clientInteractionReportApi>;
  type ClientInteractionReportsApi = ReturnType<typeof clientInteractionReportsApi>;

  function interactionReports(): ClientInteractionReportsApi;
  function interactionReports(reportId: number): ClientInteractionReportApi;
  function interactionReports(
    reportId?: number
  ): ClientInteractionReportsApi | ClientInteractionReportApi {
    const interactionReportsApiPath = `${path}/interaction-reports`;

    return reportId
      ? clientInteractionReportApi(`${interactionReportsApiPath}/${reportId}`)
      : clientInteractionReportsApi(interactionReportsApiPath);
  }

  type ClientMotivationCentreItemApi = ReturnType<typeof clientMotivationCentreItemApi>;
  type ClientMotivationCentreItemsApi = ReturnType<typeof clientMotivationCentreItemsApi>;

  function motivationCentreItems(): ClientMotivationCentreItemsApi;
  function motivationCentreItems(itemId: number): ClientMotivationCentreItemApi;
  function motivationCentreItems(
    itemId?: number
  ): ClientMotivationCentreItemsApi | ClientMotivationCentreItemApi {
    const motivationCentreItemsApiPath = `${path}/motivation-centre-items`;

    return itemId
      ? clientMotivationCentreItemApi(`${motivationCentreItemsApiPath}/${itemId}`)
      : clientMotivationCentreItemsApi(motivationCentreItemsApiPath);
  }

  type ClientPracticeMessageApi = ReturnType<typeof clientPracticeMessageApi>;
  type ClientPracticeMessagesApi = ReturnType<typeof clientPracticeMessagesApi>;

  function practiceMessages(): ClientPracticeMessagesApi;
  function practiceMessages(messageId: number): ClientPracticeMessageApi;
  function practiceMessages(
    messageId?: number
  ): ClientPracticeMessagesApi | ClientPracticeMessageApi {
    const clientPracticeMessagesPath = `${path}/practice-messages`;

    return messageId
      ? clientPracticeMessageApi(`${clientPracticeMessagesPath}/${messageId}`)
      : clientPracticeMessagesApi(clientPracticeMessagesPath);
  }

  type ClientTopUpApi = ReturnType<typeof clientTopUpApi>;
  type ClientTopUpsApi = ReturnType<typeof clientTopUpsApi>;

  function topUps(): ClientTopUpsApi;
  function topUps(topUpId: number): ClientTopUpApi;
  function topUps(topUpId?: number): ClientTopUpsApi | ClientTopUpApi {
    const topUpApiPath = `${path}/top-ups`;

    return topUpId ? clientTopUpApi(`${topUpApiPath}/${topUpId}`) : clientTopUpsApi(topUpApiPath);
  }

  return {
    path,
    additionalAdvisers,
    adviceRoomAttendees: () =>
      clientAdviceRoomAttendeesApi(`${path}/client-meeting-room-attendees`),
    adviceRooms: () => clientAdviceRoomsApi(`${path}/client-meeting-rooms`),
    auditTrail: () => clientAuditTrailApi(`${path}/audit-trail`),
    avatar: () => clientAvatarApi(`${path}/avatar`),
    clientCases,
    clientMessages,
    clientIdentifications: () => clientClientIdentificationsApi(`${path}/client-identifications`),
    clientTags: () => clientClientTagsApi(`${path}/client-taggables`),
    documentPacks: () => clientDocumentPacksApi(`${path}/document-packs`),
    documentVaultItems,
    estateSummary: () => clientEstateSummaryApi(`${path}/estate-summary`),
    factFinds: () => clientFactFindsApi(`${path}/fact-finds`),
    financialStrategies,
    follows: () => clientFollowsApi(`${path}/follows`),
    mailingList: () => clientMailingListApi(`${path}/mailing-list`),
    meetingRequests: () => clientMeetingRequestsApi(`${path}/meeting-requests`),
    messagesInteractionReports,
    summaryInteractionReports,
    interactionReports,
    identityVerifications,
    meta: () => clientMetaApi(`${path}/meta`),
    motivationCentreItems,
    onlineAdviceRequests: () => clientOnlineAdviceRequestsApi(`${path}/online-advice-requests`),
    practiceMessages,
    recommendations: () => clientRecommendationsApi(`${path}/recommendations`),
    remove: () => clientRemoveApi(`${path}/remove`),
    resend: () => clientResendApi(`${path}/resend`),
    reviewQuestionnaires: () => clientReviewQuestionnairesApi(`${path}/review-questionnaires`),
    todoList: () => clientTodoListApi(`${path}/todo-list`),
    topUps,
    unblock: () => clientUnblockApi(`${path}/unblock`),
    changeClientAdviser: () => clientChangeClientAdviserApi(`${path}/change-client-adviser`),
    GET: (params?: ClientGetOptions) => api.get<JsonApiResponseSingle>(path, { params }),
    PATCH: ({ line1, line2, ...params }: ClientPatchOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: {
            ...mapFormData(params),
            line1,
            line2,
          },
        },
      }),
  };
}
