import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';

export interface PracticeReportsMonthlyActivityGetOptions {
  date: string;
}

export function practiceReportsMonthlyActivityApi(path: string) {
  return {
    path,
    GET: ({ date }: PracticeReportsMonthlyActivityGetOptions) =>
      api.get<JsonApiResponse>(path, {
        params: {
          'data[attributes][date]': date,
        },
      }),
  };
}
