import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';
import { mapFormData } from 'helpers/formData';

export interface AdviserPermissionSettingsPatchOptions {
  assignedAdminsIds: number[];
  adminForNotificationIds: number[];
  adminForRecommendationIds: number[];
}

export function adviserPermissionSettingsApi(path: string) {
  return {
    path,
    GET: (config?: RequestConfig) => api.get<JsonApiResponseSingle>(path, config),
    PATCH: (params: AdviserPermissionSettingsPatchOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
