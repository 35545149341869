import React from 'react';
import NumberFormat from 'react-number-format';
import { rem } from 'polished';
import styled from 'styled-components';

const StyledInput = styled.input`
  width: 100%;
  padding: ${rem(16)} ${rem(15)};
  line-height: 1.3;
  font-size: ${({ theme }) => theme.fontSizes.dbNormal};
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.mischka};
  border-top-width: ${rem(2)};
  transition: border-color 0.3s ease;

  &[aria-invalid='true'] {
    &,
    &:focus,
    &:hover {
      border-color: ${({ theme }) => theme.colors.crail};
    }
  }

  &[aria-invalid='false'] {
    &:not(:disabled) {
      &:focus,
      &:hover {
        border-color: ${({ theme }) => theme.colors.main};
      }
    }
  }
`;

export type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'type'> & {
  hasError?: boolean;
  onChange?: (value: number | undefined) => void;
  value?: number;
};

export function CurrencyNumberInput({
  placeholder,
  value,
  defaultValue,
  onChange,
  hasError,
  ...inputProps
}: Props) {
  return (
    <NumberFormat
      thousandSeparator
      allowNegative={false}
      decimalScale={0}
      customInput={StyledInput}
      onValueChange={values => {
        onChange?.(values.floatValue);
      }}
      placeholder={placeholder}
      isAllowed={({ floatValue }) => !floatValue || floatValue < Number.MAX_SAFE_INTEGER}
      {...inputProps}
      value={value != null ? String(value) : undefined}
      defaultValue={defaultValue != null ? String(defaultValue) : undefined}
      aria-invalid={Boolean(hasError)}
    />
  );
}
