import api from 'api/apiInstance';

export interface PracticeAdminMetaAdviceRoomsGetResponse {
  meeting_room_today: boolean;
}

export function practiceAdminMetaAdviceRoomsApi(path: string) {
  return {
    path,
    GET: () => api.get<PracticeAdminMetaAdviceRoomsGetResponse>(path),
  };
}
