import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions } from 'api/types';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

export type ClientCaseDocumentsGetOptions = GetOptions;

export interface ClientCaseDocumentsPostOptions {
  name: string;
  file: File;
}

export function clientCaseDocumentsApi(path: string) {
  return {
    path,
    GET: (params?: ClientCaseDocumentsGetOptions) => api.get<JsonApiResponse>(path, { params }),
    POST: (params: ClientCaseDocumentsPostOptions) =>
      api.post<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      ),
  };
}
