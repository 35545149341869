import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

import { serviceCharterViewApi } from './view';

export interface ServiceCharterPatchOptions {
  title?: string;
  availableUntil?: string;
  file?: File;
  image?: File;
  existingImageId?: number;
}

export function serviceCharterApi(path: string) {
  return {
    path,
    view: () => serviceCharterViewApi(`${path}/view`),
    GET: (config?: RequestConfig) => api.get<JsonApiResponseSingle>(path, config),
    PATCH: (params: ServiceCharterPatchOptions) =>
      api.patch<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      ),
    DELETE: () => api.delete(path),
  };
}
