import { convertFromRaw, convertToRaw, EditorState, RawDraftContentState } from 'draft-js';
import { mapValues, omit } from 'lodash';

import { isStorageAvailable } from 'helpers/storage';

import type { MemorizedMessage, MessageType } from './types';

type MessageMemory = Record<string, MemorizedMessage>;
type MessagesSessionMemory = Record<string, RawDraftContentState | undefined>;

let memory: MessageMemory = {};

function serializeMemory(messageMemory: MessageMemory): string {
  return JSON.stringify(
    mapValues(messageMemory, ({ content }) =>
      content ? convertToRaw(content.getCurrentContent()) : undefined
    )
  );
}

function deserializeMemory(serializedMemory: string): MessageMemory {
  const parsedValue = JSON.parse(serializedMemory) as MessagesSessionMemory | undefined;

  return parsedValue
    ? mapValues(parsedValue, content =>
        content ? { content: EditorState.createWithContent(convertFromRaw(content)) } : {}
      )
    : {};
}

export class MessageMemoizer {
  private messageType: MessageType;

  private static isSessionStorageAvailable = isStorageAvailable('sessionStorage');

  private static storageKey = 'memorized_messages';

  constructor(messageType: MessageType) {
    this.messageType = messageType;

    if (MessageMemoizer.isSessionStorageAvailable) {
      const savedValue = sessionStorage.getItem(MessageMemoizer.storageKey);
      const deserializedValue = savedValue ? deserializeMemory(savedValue) : {};

      memory = deserializedValue;
    }
  }

  private createMessageId(clientId: number) {
    return `${this.messageType}_${clientId}`;
  }

  public static clearSessionStorage() {
    if (this.isSessionStorageAvailable) {
      sessionStorage.removeItem(this.storageKey);
    }
  }

  public setMemorizedMessage(clientId: number, message: MemorizedMessage): void {
    const messageId = this.createMessageId(clientId);

    memory[messageId] = {
      ...memory[messageId],
      ...message,
    };

    if (MessageMemoizer.isSessionStorageAvailable) {
      sessionStorage.setItem(MessageMemoizer.storageKey, serializeMemory(memory));
    }
  }

  public resetMemorizedMessage(clientId: number): void {
    const messageId = this.createMessageId(clientId);

    memory = omit(memory, messageId);

    if (MessageMemoizer.isSessionStorageAvailable) {
      sessionStorage.setItem(MessageMemoizer.storageKey, serializeMemory(memory));
    }
  }

  public getMemorizedMessage(clientId: number): MemorizedMessage | undefined {
    const messageId = this.createMessageId(clientId);

    const memoryMessage = memory[messageId];
    let sessionMessage: Pick<MemorizedMessage, 'content'> | undefined;

    if (MessageMemoizer.isSessionStorageAvailable) {
      const savedValue = sessionStorage.getItem(MessageMemoizer.storageKey);
      const deserializedValue = savedValue ? deserializeMemory(savedValue) : undefined;

      sessionMessage = deserializedValue ? deserializedValue[messageId] : undefined;
    }

    return memoryMessage || sessionMessage
      ? {
          ...memoryMessage,
          ...sessionMessage,
        }
      : undefined;
  }
}
