import { useEffect, useRef, useState } from 'react';
import { filter, find, first } from 'lodash';

import type { DeviceChangedEvent } from 'api/twilio/deviceEvents';
import {
  getDevices,
  getSelectedAudioDevice,
  getSelectedAudioOutputDevice,
  getSelectedVideoDevice,
} from 'api/twilio/twilioVideo';
import {
  useCameraPermissions,
  useMicrophonePermissions,
} from 'components/TestSetupTwilio/components/useDevicesPermissions';
import { DevicePermissions } from 'components/TestSetupTwilio/types';

import { areDevicesEventsSupported, areDevicesSupported } from './utils';

function getExistingDeviceId(
  savedDeviceId: string | undefined,
  devices: MediaDeviceInfo[]
): string | undefined {
  return savedDeviceId
    ? find(devices, ({ deviceId }) => deviceId === savedDeviceId)?.deviceId
    : first(devices)?.deviceId;
}

const useDevices = () => {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [isFetching, setIsFetching] = useState(true);

  const fetchRef = useRef(async () => {
    setDevices(await getDevices());
    setIsFetching(false);
  });

  useEffect(() => {
    const fetch = fetchRef.current;

    if (areDevicesSupported() && areDevicesEventsSupported()) {
      fetchRef.current();
      navigator.mediaDevices.addEventListener('devicechange', fetch);
    }

    return () => {
      if (areDevicesSupported() && areDevicesEventsSupported()) {
        navigator.mediaDevices.removeEventListener('devicechange', fetch);
      }
    };
  }, []);

  return { devices, isFetching, refetch: fetchRef.current };
};

export const useVideoDevices = () => {
  const { devices, isFetching, refetch } = useDevices();
  const { cameraPermissions } = useCameraPermissions();

  useEffect(() => {
    if (cameraPermissions !== DevicePermissions.Unknown) {
      refetch();
    }
  }, [cameraPermissions, refetch]);

  return {
    videoDevices: filter(devices, device => device.kind === 'videoinput'),
    isFetchingVideoDevices: isFetching,
    cameraPermissions,
  };
};

export const useSelectedVideoDevice = () => {
  const { videoDevices } = useVideoDevices();
  const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useState<string | undefined>(() =>
    getExistingDeviceId(getSelectedVideoDevice(), videoDevices)
  );

  useEffect(() => {
    const setExistingDeviceId = (savedDeviceId: string | undefined) => {
      setSelectedVideoDeviceId(getExistingDeviceId(savedDeviceId, videoDevices));
    };

    const savedDeviceId = getSelectedVideoDevice();
    setExistingDeviceId(savedDeviceId);

    const setSelectedValue = (e: Event) => {
      setExistingDeviceId((e as DeviceChangedEvent).detail.newDeviceId);
    };

    window.addEventListener('videodevicechanged', setSelectedValue);

    return () => {
      window.removeEventListener('videodevicechanged', setSelectedValue);
    };
  }, [videoDevices]);

  return {
    selectedVideoDeviceId,
  };
};

export const useAudioDevices = () => {
  const { devices, isFetching, refetch } = useDevices();
  const { microphonePermissions } = useMicrophonePermissions();

  useEffect(() => {
    if (microphonePermissions !== DevicePermissions.Unknown) {
      refetch();
    }
  }, [microphonePermissions, refetch]);

  return {
    audioDevices: filter(devices, device => device.kind === 'audioinput'),
    isFetchingAudioDevices: isFetching,
    microphonePermissions,
  };
};

export const useSelectedAudioDevice = () => {
  const { audioDevices } = useAudioDevices();
  const [selectedAudioDeviceId, setSelectedAudioDeviceId] = useState<string | undefined>(() =>
    getExistingDeviceId(getSelectedAudioDevice(), audioDevices)
  );

  useEffect(() => {
    const setExistingDeviceId = (savedDeviceId: string | undefined) => {
      setSelectedAudioDeviceId(getExistingDeviceId(savedDeviceId, audioDevices));
    };

    const savedDeviceId = getSelectedAudioDevice();
    setExistingDeviceId(savedDeviceId);

    const setSelectedValue = (e: Event) => {
      setExistingDeviceId((e as DeviceChangedEvent).detail.newDeviceId);
    };

    window.addEventListener('audiodevicechanged', setSelectedValue);

    return () => {
      window.removeEventListener('audiodevicechanged', setSelectedValue);
    };
  }, [audioDevices]);

  return {
    selectedAudioDeviceId,
  };
};

export const useAudioOutputDevices = () => {
  const { devices, isFetching } = useDevices();

  return {
    audioOutputDevices: filter(devices, device => device.kind === 'audiooutput'),
    isFetchingAudioOutputDevices: isFetching,
  };
};

export const useSelectedAudioOutputDevice = () => {
  const { audioOutputDevices } = useAudioOutputDevices();
  const [selectedAudioOutputDeviceId, setSelectedAudioOutputDeviceId] = useState<
    string | undefined
  >(() => getExistingDeviceId(getSelectedAudioOutputDevice(), audioOutputDevices));

  useEffect(() => {
    const setExistingDeviceId = (savedDeviceId: string | undefined) => {
      setSelectedAudioOutputDeviceId(getExistingDeviceId(savedDeviceId, audioOutputDevices));
    };

    const savedDeviceId = getSelectedAudioOutputDevice();
    setExistingDeviceId(savedDeviceId);

    const setSelectedValue = (e: Event) => {
      setExistingDeviceId((e as DeviceChangedEvent).detail.newDeviceId);
    };

    window.addEventListener('audiooutputdevicechanged', setSelectedValue);

    return () => {
      window.removeEventListener('audiooutputdevicechanged', setSelectedValue);
    };
  }, [audioOutputDevices]);

  return {
    selectedAudioOutputDeviceId,
  };
};
