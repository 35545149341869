import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, RequestConfig } from 'api/types';
import { mapFormData } from 'helpers/formData';

import { practiceAdminAdvisersApi } from './advisers';
import { practiceAdminAssignApi } from './assign';
import { practiceAdminChangePermissionLevelApi } from './changePermissionLevel';
import { practiceAdminClientsApi } from './clients';
import { practiceAdminMetaApi } from './meta';
import { practiceAdminResendApi } from './resend';
import { practiceAdminSharedClientsApi } from './sharedClients';
import { practiceAdminTeamApi } from './team';
import { practiceAdminUnassignApi } from './unassign';

export type PracticeAdminGetOptions = GetOptions | undefined;

export type PracticeAdminPatchOptions = {
  followNewClients: boolean;
};

export function practiceAdminApi(path: string) {
  return {
    path,
    advisers: () => practiceAdminAdvisersApi(`${path}/advisers`),
    assign: () => practiceAdminAssignApi(`${path}/assign`),
    changePermissionLevel: () =>
      practiceAdminChangePermissionLevelApi(`${path}/change-permission-level`),
    clients: () => practiceAdminClientsApi(`${path}/clients`),
    meta: () => practiceAdminMetaApi(`${path}/meta`),
    resend: () => practiceAdminResendApi(`${path}/resend`),
    sharedClients: () => practiceAdminSharedClientsApi(`${path}/shared-clients`),
    team: () => practiceAdminTeamApi(`${path}/team`),
    unassign: () => practiceAdminUnassignApi(`${path}/unassign`),
    GET: (params?: PracticeAdminGetOptions, config?: RequestConfig) =>
      api.get<JsonApiResponseSingle>(path, { params, ...config }),
    PATCH: (params: PracticeAdminPatchOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
