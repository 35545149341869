import { CaseType } from 'types/entities/clientCase';

export const FINANCIAL_STRATEGY = 'financial_strategy';

export default [
  CaseType.OnlineAdviceRequest,
  CaseType.RecommendationPackCase,
  CaseType.MeetingRequestCase,
  CaseType.ReviewQuestionnaireCase,
  CaseType.DocumentPackCase,
];
