import React from 'react';
import { useFormState } from 'react-final-form';

import { useRwdQuery } from 'helpers/styles/useRwdQuery';
import FormActionButtons, {
  FormActionButtonsProps,
} from 'components/FormActionButtons/FormActionButtons';

function FormActionResponsiveButtons({ submitDisabled, ...rest }: FormActionButtonsProps) {
  const { submitting } = useFormState();
  const { isMobile } = useRwdQuery();

  return (
    <FormActionButtons
      isLabelHidden={isMobile}
      isSubmitBtnBlock={isMobile}
      submitBtnDataTest="continue-btn"
      labelBtnDataTest="press-enter-btn"
      {...rest}
      submitDisabled={submitDisabled || submitting}
    />
  );
}

export default FormActionResponsiveButtons;
