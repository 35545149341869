import api from 'api/apiInstance';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

export interface TechnicalSupportSendRequestPostOptions {
  email?: string;
  subject: string;
  details?: string;
  message: string;
  screenshot?: string;
}

export function technicalSupportSendRequestApi(path: string) {
  return {
    path,
    POST: (values: TechnicalSupportSendRequestPostOptions) =>
      api.post(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(values),
          },
        })
      ),
  };
}
