import React from 'react';
import { connect } from 'react-redux';

import type { AppState } from 'store/types';
import { getCurrentUser } from 'store/users/selectors';

import { VideoChatFloatingWindow } from './VideoChatFloatingWindow';
import { VideoChatWatcher } from './VideoChatWatcher';
import VideoProvider from './VideoProvider';

interface Props extends MappedProps {
  children: React.ReactNode;
}

function LoggedInUserVideoProvider({ children, userEmail, userName, userAvatar }: Props) {
  return userEmail && userName ? (
    <VideoProvider userEmail={userEmail} userName={userName} userAvatar={userAvatar}>
      {children}

      <VideoChatFloatingWindow />

      <VideoChatWatcher />
    </VideoProvider>
  ) : (
    <>{children}</>
  );
}

const mapStateToProps = (state: AppState) => {
  const currentUser = getCurrentUser(state);

  return {
    userName: currentUser?.fullName,
    userEmail: currentUser?.email,
    userAvatar: currentUser?.avatar,
  };
};

type MappedProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(LoggedInUserVideoProvider);
