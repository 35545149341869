export default {
  stepBegin: {
    title: `Risk tolerance assessment`,
    summary: `This assessment consists of {questionsQty} questions. Please respond on a 5-step scale
      - from "Strongly agree" to "Strongly disagree".`,
    bottomSummary: `Choose the answer that best describes your approach to risk.
      You can retake the test as many times as you like.`,
    button: `I’m ready`,
    error: `An error has occured. Please try again later or contact platform admin.`,
  },
  stepSummary: {
    title: `Risk tolerance assessment`,
    summary: `Thank you. When you submit your {caseType},
      your Adviser will analyse your results and discuss
      the results with you. The goal is to recommend investment(s)
      that are appropriate for you.`,
    labelButton: `Retake the test`,
  },
  options: {
    stronglyDisagree: `Strongly disagree`,
    disagree: `Disagree`,
    neitherAgreeNorDisagree: `Neither agree nor disagree`,
    agree: `Agree`,
    stronglyAgree: `Strongly agree`,
  },
  score: {
    title: `Final score is:`,
    noScore: `There is no score yet.`,
    generate: `Generate summary report`,
    newTest: `The client has taken a new test.`,
    result: `{result} risk`,
    zero: `Zero`,
    low: `Low`,
    mediumLow: `Medium-Low`,
    medium: `Medium`,
    mediumHigh: `Medium-High`,
    high: `High`,
    reportConfirmationText: `Are you sure you wish to generate the report?`,
    reportIsGenerating: `The report is being generated. It might take a while. Please wait...`,
  },
};
