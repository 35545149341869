import React, { useCallback } from 'react';
import { useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import type { FieldValidator } from 'final-form';

import { formatPrice } from 'helpers/numbers';
import {
  betweenValues,
  composeValidators,
  lessOrEqualDigits,
  mustBeInteger,
  mustBeNumber,
  required,
} from 'helpers/validators';
import { AvailableCurrencyDropdown, CurrencyNumberInput } from 'components/CurrencyInputs';
import { Field, Label } from 'components/Forms';
import GenericInput from 'components/GenericInput/GenericInput';
import { GenericTooltip, TooltipPlacement } from 'components/GenericTooltip';
import { Column, FieldSpacer, Grid, Row } from 'components/Layout';

import useInvestmentFormDataGetters from '../useInvestmentFormDataGetters';

const durationMaxValue = 100;

interface Props {
  initialInvestmentFieldName: string;
  monthlyContributionFieldName: string;
  investmentDurationFieldName: string;
  currencyFieldName: string;
  projectionTypeFieldName: string;
  minDuration?: number;
}

const InvestmentDetailsFieldGroup = ({
  initialInvestmentFieldName,
  monthlyContributionFieldName,
  investmentDurationFieldName,
  currencyFieldName,
  projectionTypeFieldName,
  minDuration,
}: Props) => {
  // const [isInflationIncluded, setIsInflationIncluded] = useState(false);
  const { values } = useFormState();
  const { getInitialInvestment, getMonthlyContribution, getCurrency } =
    useInvestmentFormDataGetters({
      initialInvestmentFieldName,
      monthlyContributionFieldName,
      investmentDurationFieldName,
      currencyFieldName,
      projectionTypeFieldName,
    });

  const initialInvestmentValidators = useCallback<FieldValidator<number>>((value, allValues) => {
    return composeValidators(mustBeNumber, lessOrEqualDigits(15))(value || 0, allValues);
  }, []);

  const investmentDurationValidators = useCallback<FieldValidator<string>>(
    (...args) => {
      return composeValidators(
        ...(minDuration
          ? [
              required,
              mustBeInteger,
              betweenValues(minDuration, durationMaxValue, 'errors.betweenDuration'),
            ]
          : [
              (value: any) => (value != null ? mustBeInteger(value) : undefined),
              betweenValues(5, durationMaxValue, 'errors.betweenDuration'),
            ])
      )(...args);
    },
    [minDuration]
  );

  const monthlyContributionValidators = useCallback<FieldValidator<number>>((value, allValues) => {
    return composeValidators(mustBeNumber, lessOrEqualDigits(8))(value || 0, allValues);
  }, []);

  const currencyValidators = useCallback<FieldValidator<string>>(
    (value, allValues) => {
      return getInitialInvestment(allValues) || getMonthlyContribution(allValues)
        ? required(value)
        : undefined;
    },
    [getInitialInvestment, getMonthlyContribution]
  );

  return (
    <div>
      <Grid>
        <Row>
          <Column xsUp={12} mdUp={4}>
            <Field<number>
              name={initialInvestmentFieldName}
              validate={initialInvestmentValidators}
              renderLabel={({ input }) => (
                <Label
                  text="investment.initialInvestment.title"
                  tooltip={
                    <GenericTooltip
                      text={<FormattedMessage id="investment.initialInvestment.tooltip" />}
                      position={TooltipPlacement.Bottom}
                    />
                  }
                  elementId={input.id}
                />
              )}
              renderControl={({ input, meta }) => (
                <CurrencyNumberInput
                  {...input}
                  placeholder={formatPrice(0, getCurrency(values))}
                  hasError={meta.showError}
                  autoFocus
                />
              )}
            />

            <FieldSpacer />
          </Column>

          <Column xsUp={12} mdUp={4}>
            <Field<number>
              name={monthlyContributionFieldName}
              validate={monthlyContributionValidators}
              renderLabel={({ input }) => (
                <Label
                  text="investment.monthlyContribution.title"
                  tooltip={
                    <GenericTooltip
                      text={<FormattedMessage id="investment.monthlyContribution.tooltip" />}
                      position={TooltipPlacement.Left}
                    />
                  }
                  elementId={input.id}
                />
              )}
              renderControl={({ input, meta }) => (
                <CurrencyNumberInput
                  {...input}
                  placeholder={formatPrice(0, getCurrency(values))}
                  hasError={meta.showError}
                />
              )}
            />

            <FieldSpacer />
          </Column>

          <Column xsUp={12} mdUp={4}>
            <Field<string>
              name={currencyFieldName}
              validate={currencyValidators}
              renderLabel={({ input }) => (
                <Label text="investment.currency.title" elementId={input.id} />
              )}
              renderControl={({ input: { onChange, ...inputRest }, meta }) => (
                <AvailableCurrencyDropdown
                  {...inputRest}
                  onChange={onChange}
                  hasError={meta.showError}
                />
              )}
            />

            <FieldSpacer />
          </Column>
        </Row>

        <Row>
          <Column xsUp={12} mdUp={4}>
            <Field
              name={investmentDurationFieldName}
              validate={investmentDurationValidators}
              renderLabel={({ input }) => (
                <Label
                  text="investment.duration.title"
                  tooltip={
                    <GenericTooltip
                      text={<FormattedMessage id="investment.duration.tooltip" />}
                      position={TooltipPlacement.Bottom}
                    />
                  }
                  elementId={input.id}
                />
              )}
              renderControl={({ input, meta }) => (
                <GenericInput
                  {...input}
                  placeholder="investment.duration.placeholder"
                  hasError={meta.showError}
                  type="number"
                  min="0"
                  hasFullWidth
                />
              )}
            />

            <FieldSpacer />
          </Column>
        </Row>
      </Grid>

      {/* {valid &&
        Boolean(getInvestmentDuration(values)) &&
        Boolean(getInitialInvestment(values) || getMonthlyContribution(values)) && (
          <div>
            <InvestmentProjections
              onCheckboxChange={value => setIsInflationIncluded(value)}
              includeInflation={isInflationIncluded}
              initialInvestmentFieldName={initialInvestmentFieldName}
              monthlyContributionFieldName={monthlyContributionFieldName}
              investmentDurationFieldName={investmentDurationFieldName}
              currencyFieldName={currencyFieldName}
              projectionTypeFieldName={projectionTypeFieldName}
            />
          </div>
        )} */}
    </div>
  );
};

export default InvestmentDetailsFieldGroup;
