import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';
import { mapFormData } from 'helpers/formData';
import type { FinancialPlanningValues } from 'types/entities/financialPlanning';

const apiPath = '/api/v1/financial-plannings';

function financialPlanningsApi(path: string) {
  return {
    path,
    GET: (config?: RequestConfig) => api.get<JsonApiResponseSingle>(path, config),
    PATCH: (params: FinancialPlanningValues) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}

function financialPlannings() {
  return financialPlanningsApi(apiPath);
}

export { financialPlannings };
