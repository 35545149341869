import React from 'react';

import SvgIcon from '../SvgIcon';

export default function IconGridLayout(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 0 30 30" {...props}>
      <g stroke="currentColor" strokeWidth="2">
        <rect x="2" y="2" rx="2" width="26" height="26" fill="none" />
        <line x1="15" y1="1" x2="15" y2="29" />
        <line x1="1" y1="15" x2="29" y2="15" />
      </g>
    </SvgIcon>
  );
}
