import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, RequestConfig } from 'api/types';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';
import type { ProofType } from 'types/entities/proofType';

import { clientClientIdentificationsIdentificationDocumentsApi } from './identificationDocuments';
import { clientClientIdentificationsIdentificationDocumentApi } from './identificationDocuments/identificationDocument';
import { clientClientIdentificationsToggleLockApi } from './toggleLock';

export type NewDocument = { proofType: ProofType; document: File };
export type ExistingDocument = { id: number };

export interface ClientIdentificationPatchOptions {
  firstName?: string;
  lastName?: string;
  nationalities?: string[];
  residencies?: string[];
  dateOfBirth?: string;
}

export function clientClientIdentificationsApi(path: string) {
  type ClientClientIdentificationsIdentificationDocumentApi = ReturnType<
    typeof clientClientIdentificationsIdentificationDocumentApi
  >;
  type ClientClientIdentificationsIdentificationDocumentsApi = ReturnType<
    typeof clientClientIdentificationsIdentificationDocumentsApi
  >;

  function identificationDocuments(): ClientClientIdentificationsIdentificationDocumentsApi;
  function identificationDocuments(
    documentId: number
  ): ClientClientIdentificationsIdentificationDocumentApi;
  function identificationDocuments(
    documentId?: number
  ):
    | ClientClientIdentificationsIdentificationDocumentsApi
    | ClientClientIdentificationsIdentificationDocumentApi {
    const documentsApiPath = `${path}/identification-documents`;

    return documentId
      ? clientClientIdentificationsIdentificationDocumentApi(`${documentsApiPath}/${documentId}`)
      : clientClientIdentificationsIdentificationDocumentsApi(documentsApiPath);
  }

  return {
    path,
    identificationDocuments,
    toggleLock: () => clientClientIdentificationsToggleLockApi(`${path}/toggle-lock`),
    GET: (params?: GetOptions, config?: RequestConfig) =>
      api.get<JsonApiResponseSingle>(path, { params, ...config }),
    PATCH: (params: ClientIdentificationPatchOptions) =>
      api.patch<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      ),
  };
}
