import React from 'react';

import SvgIcon from '../SvgIcon';

const IconDiagonalArrowsInner = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 29 29" {...props}>
    <path
      fill="currentColor"
      d="M10.27 18.08h-6.5c-.3 0-.6.3-.6.7v1.8c0 .4.3.7.6.7h1.8l-5.2 5.3c-.3.2-.3.7 0 1l1.3 1.2a.7.7 0 001 0l5.1-5.2v1.7c0 .4.3.7.7.7h1.8c.4 0 .7-.3.7-.7v-6.5c0-.4-.3-.7-.7-.7zM27.29.24a.7.7 0 00-1 0l-5.2 5.2v-1.7c0-.4-.3-.7-.7-.7h-1.8c-.4 0-.7.3-.7.7v6.5c0 .4.3.7.7.7h6.5c.4 0 .7-.3.7-.7v-1.8c0-.4-.3-.7-.7-.7h-1.8l5.3-5.2c.2-.3.2-.7 0-1z"
    />
  </SvgIcon>
);

export default IconDiagonalArrowsInner;
