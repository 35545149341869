import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

export interface UsersConfirmationPostOptions {
  email: string;
  newEmail?: string;
}

export function usersConfirmationApi(path: string) {
  return {
    path,
    POST: (params: UsersConfirmationPostOptions) => api.post(path, { user: mapFormData(params) }),
  };
}
