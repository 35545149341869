import React from 'react';

export interface GenericPdfContext {
  zoom: number;
  rotation: number;
  pageNumber: number;
  pdfScrollTop: number;
  pdfWidth: number;
  pdfHeight: number;
  isEditable: boolean;
}

export default React.createContext<GenericPdfContext>({
  zoom: 100,
  rotation: 0,
  pageNumber: 0,
  pdfScrollTop: 0,
  pdfWidth: 0,
  pdfHeight: 0,
  isEditable: false,
});
