import { useState } from 'react';
import { BrowserInfo as BrowserInfoBase, detect } from 'detect-browser';

import type { Browser } from 'constants/browser';

export interface BrowserInfo {
  name: Browser;
  version: string;
  os: string;
}

export const useBrowser = (): BrowserInfo => {
  const [currentBrowser] = useState(() => detect() as BrowserInfoBase);

  return {
    name: currentBrowser.name as Browser,
    version: currentBrowser.version,
    os: currentBrowser.os || '',
  };
};
