import { sendMessageToMultipleClientsApi } from './bulkSend';

const apiPath = '/api/v1/messages';

function messagesApi(path: string) {
  return {
    path,
    bulkCreate: () => sendMessageToMultipleClientsApi(`${path}/bulk-create`),
  };
}

function messages() {
  return messagesApi(apiPath);
}

export { messages };
