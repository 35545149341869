import { invitationFollowsFollowApi } from './follow';
import { invitationFollowsUnfollowApi } from './unfollow';

export function invitationFollowsApi(path: string) {
  return {
    path,
    follow: () => invitationFollowsFollowApi(`${path}/follow`),
    unfollow: () => invitationFollowsUnfollowApi(`${path}/unfollow`),
  };
}
