import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';
import { objectToURLParams } from 'helpers/utils';

export interface UsageReportsMeetingReportGetOptions {
  startDate: string;
  endDate: string;
  adviserId?: number;
}

export function usageReportsMeetingReportApi(path: string) {
  const generatePath = (params?: UsageReportsMeetingReportGetOptions) =>
    params ? `${path}?${objectToURLParams(mapFormData(params))}` : path;

  return {
    path,
    GET: (params?: UsageReportsMeetingReportGetOptions) => api.get(generatePath(params)),
    GETPath: (params?: UsageReportsMeetingReportGetOptions) => generatePath(params),
  };
}
