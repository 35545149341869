import { rem, size } from 'polished';
import styled, { css } from 'styled-components';

import { breakpoints } from 'helpers/styles/constants';

export enum TitleMargin {
  Sm,
  Md,
  Lg,
}

interface TitleProps {
  marginBottom?: TitleMargin;
  marginTop?: TitleMargin;
  hasBorderBottom?: boolean;
  hasBorderBottomLg?: boolean;
  hasBottomLine?: boolean;
  isRelative?: boolean;
  isBig?: boolean;
  isMedium?: boolean;
  isSmall?: boolean;
  isMediumLg?: boolean;
  isSubtitle?: boolean;
  isUppercase?: boolean;
  isColoredMain?: boolean;
  hasSubheaderColor?: boolean;
  isBold?: boolean;
  isBlock?: boolean;
}

type MarginMap = { [key in TitleMargin]: number };

const marginTopMap: MarginMap = {
  [TitleMargin.Sm]: 26,
  [TitleMargin.Md]: 40,
  [TitleMargin.Lg]: 50,
};

const marginBottomMap: MarginMap = {
  [TitleMargin.Sm]: 20,
  [TitleMargin.Md]: 30,
  [TitleMargin.Lg]: 40,
};

function getMarginValue(valuesMap: MarginMap, value: TitleMargin | undefined) {
  return value != null ? `${rem(valuesMap[value])}` : 0;
}

export const GenericTitle = styled.h1<TitleProps>`
  ${({ isBlock }) =>
    isBlock
      ? css`
          display: block;
        `
      : css`
          display: flex;
          align-items: center;
        `};

  margin: ${({ marginTop }) => getMarginValue(marginTopMap, marginTop)} 0
    ${({ marginBottom }) => getMarginValue(marginBottomMap, marginBottom)} 0;
  font-size: ${({ theme }) => theme.fontSizes.dbBigSm};
  font-family: ${({ theme }) => theme.fontFamilies.sec};
  font-weight: ${({ theme }) => theme.fontWeights.secNormal};

  @media ${breakpoints.lgUp} {
    font-size: ${({ theme }) => theme.fontSizes.dbBig};
  }

  ${({ isBig, theme }) => isBig && `font-size: ${theme.fontSizes.big}`};
  ${({ isMediumLg, theme }) => isMediumLg && `font-size: ${theme.fontSizes.mediumLg}`};

  ${({ isMedium, theme }) =>
    isMedium &&
    css`
      font-size: ${theme.fontSizes.mediumSm};
      font-family: ${theme.fontFamilies.main};
      font-weight: ${theme.fontWeights.mainNormal};
      line-height: 1.3;

      @media ${breakpoints.lgUp} {
        font-size: ${theme.fontSizes.dbMedium};
      }
    `};

  ${({ isSmall, theme }) =>
    isSmall &&
    css`
      font-size: ${theme.fontSizes.smallLg};
      font-family: ${theme.fontFamilies.main};
      font-weight: ${theme.fontWeights.mainNormal};
      line-height: 1.3;

      @media ${breakpoints.smUp} {
        font-size: ${theme.fontSizes.smallLg};
      }
    `};

  ${({ isSubtitle, theme }) =>
    isSubtitle &&
    css`
      font-family: ${theme.fontFamilies.main};
      font-size: ${theme.fontSizes.dbSmallMd};
      line-height: 1.6;
      color: ${theme.colors.textLight};

      @media ${breakpoints.lgUp} {
        font-size: ${theme.fontSizes.dbNormalSm};
      }
    `};

  ${({ isUppercase }) => isUppercase && 'text-transform: uppercase'};
  ${({ isColoredMain, theme }) => isColoredMain && `color: ${theme.colors.textDark}`};
  ${({ isBold, theme }) => isBold && `font-weight: ${theme.fontWeights.mainBold}`};
  ${({ isRelative }) => isRelative && 'position: relative'};
  ${({ hasSubheaderColor, theme }) => hasSubheaderColor && `color: ${theme.colors.subTitle}}`};
  ${({ hasBorderBottom, theme }) =>
    hasBorderBottom &&
    css`
      padding-bottom: ${rem(11)};
      border-bottom: 1px solid ${theme.colors.greyLighten};
    `};

  ${({ hasBorderBottomLg, theme }) =>
    hasBorderBottomLg &&
    css`
      padding-bottom: ${rem(11)};
      border-bottom: 3px solid ${theme.colors.main};
    `};

  ${({ hasBottomLine, theme }) =>
    hasBottomLine &&
    css`
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: ${rem(-14)};
        left: 0;
        ${size('2px', rem(70))};
        background-color: ${theme.colors.accent};
      }
    `};

  > * {
    &:not(:last-child) {
      margin-right: ${rem(8)};
    }
  }
`;
