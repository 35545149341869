enum TooltipPlacement {
  Top = 'top',
  TopStart = 'top-start',
  TopEnd = 'top-end',

  Right = 'right',
  RightStart = 'right-start',
  RightEnd = 'right-end',

  Bottom = 'bottom',
  BottomStart = 'bottom-start',
  BottomEnd = 'bottom-end',

  Left = 'left',
  LeftStart = 'left-start',
  LeftEnd = 'left-end',

  Auto = 'auto',
  AutoStart = 'auto-start',
  AutoEnd = 'auto-end',
}

export default TooltipPlacement;
