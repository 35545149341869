import React, { PureComponent } from 'react';
import onClickOutside from 'react-onclickoutside';
import { isArray, isEmpty, some } from 'lodash';
import { rem, rgba, triangle } from 'polished';
import styled from 'styled-components';

import { misc } from 'helpers/styles/constants';
import { DotsButton } from 'components/DotsButton';

import GenericExpandableMenuContext from './GenericExpandableMenuContext';

const MenuWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Menu = styled.div<{
  isExpanded: boolean;
}>`
  position: absolute;
  top: 50%;
  left: ${rem(-8)};
  z-index: ${misc.zIndex1};
  display: none;
  padding: ${rem(3)} ${rem(15)} ${rem(10)};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.bermudaGray};
  box-shadow: ${rem(3)} ${rem(3)} ${rem(15)} ${rgba(0, 0, 0, 0.15)};
  transform: translate(-100%, -50%);

  ${({ isExpanded }) => isExpanded && 'display: block'};

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: ${rem(-8)};
    display: block;
    transform: translateY(-50%);

    ${({ theme }) =>
      triangle({
        pointingDirection: 'right',
        width: '8px',
        height: '10px',
        foregroundColor: theme.colors.main,
      })};
  }
`;

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

interface State {
  isExpanded: boolean;
}

class GenericExpandableMenu extends PureComponent<Props, State> {
  public state = {
    isExpanded: false,
  };

  // this method is required by react-onclickoutside package
  public handleClickOutside = () => this.setState({ isExpanded: false });

  private toggleMenu = () => this.setState(({ isExpanded }) => ({ isExpanded: !isExpanded }));

  public render() {
    const { isExpanded } = this.state;
    const { children } = this.props;

    if (isEmpty(children) || (isArray(children) && !some(children, Boolean))) return null;

    return (
      <MenuWrapper>
        <GenericExpandableMenuContext.Provider value={{ closeMenu: this.handleClickOutside }}>
          <Menu isExpanded={isExpanded}>{children}</Menu>
        </GenericExpandableMenuContext.Provider>

        <DotsButton onClick={this.toggleMenu} />
      </MenuWrapper>
    );
  }
}

export default onClickOutside(GenericExpandableMenu);
