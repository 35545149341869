import type { AnyAction } from 'redux';

import { TODO_LIST_VISIBILITY } from './actionTypes';
import type { TodoListActionTypes, TodoListState } from './types';

export const initialState: TodoListState = {
  isVisible: false,
};

export default (state = initialState, incomingAction: AnyAction): TodoListState => {
  const action = incomingAction as TodoListActionTypes;

  switch (action.type) {
    case TODO_LIST_VISIBILITY:
      return {
        ...state,
        isVisible: action.payload.value,
      };
    default:
      return state;
  }
};
