import React from 'react';

import { useGetAvailableCurrencies } from '../hooks/useGetAvailableCurrencies';
import { CurrencyDropdown, Props } from './CurrencyDropdown';

export function AvailableCurrencyDropdown(props: Omit<Props, 'currencies'>) {
  const currencies = useGetAvailableCurrencies();

  return <CurrencyDropdown currencies={currencies} {...props} />;
}
