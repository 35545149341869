import React from 'react';

import { useGetPracticeSettings } from 'store/settings/selectors';

export const PrivacyPolicyLink: React.FC = ({ children }) => {
  const { privacyPolicyUrl } = useGetPracticeSettings();

  return (
    <a target="_blank" rel="noopener noreferrer" href={privacyPolicyUrl}>
      {children}
    </a>
  );
};
