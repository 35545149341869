import { Root, route } from '../utils';

const clientCasesPath = `${Root}client-cases`;
const clientCasePath = `${clientCasesPath}/:caseId`;

type ClientCaseParams = [caseId: number];

export default {
  clientCases: {
    ...route(clientCasesPath),
  },
  clientCase: {
    ...route<ClientCaseParams>(clientCasePath),
    auditTrail: route<ClientCaseParams>(`${clientCasePath}/audit-trail`),
    editInvestment: route<ClientCaseParams>(`${clientCasePath}/edit-investment`),
    factFind: route<ClientCaseParams>(`${clientCasePath}/fact-find`),
    financialAudit: route<ClientCaseParams>(`${clientCasePath}/financial-audit`),
    financialPlanningHealthCheck: route<ClientCaseParams>(
      `${clientCasePath}/financial-planning-health-check`
    ),
    financialPlanningGoals: route<ClientCaseParams>(`${clientCasePath}/financial-planning-goals`),
    riskAssessment: route<ClientCaseParams>(`${clientCasePath}/risk-assessment`),
    submit: route<ClientCaseParams>(`${clientCasePath}/submit`),
  },
};
