import type { AnyAction } from 'redux';

import type { IdentityVerification } from 'types/entities/clientIdentityVerifications';

import { GET_IDENTITY_VERIFICATION_REQUEST } from './actionTypes';
import type { IdentityVerificationActionTypes } from './types';

export const initialState: IdentityVerification = {} as IdentityVerification;

export default (state = initialState, incomingAction: AnyAction): IdentityVerification => {
  const action = incomingAction as IdentityVerificationActionTypes;

  switch (action.type) {
    case GET_IDENTITY_VERIFICATION_REQUEST:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
