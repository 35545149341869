import type { RailsContext } from 'react-on-rails';

import { Env } from 'helpers/env';

let railsContext: RailsContext | null = null;

export const setContext = (context: RailsContext) => {
  railsContext = {
    ...context,
  };
};

export const getContext = () => railsContext;
export const getRailsContextCookies = () =>
  railsContext && railsContext.cookies ? railsContext.cookies : {};
export const getRailsContextEnv = () =>
  railsContext && railsContext.railsEnv ? railsContext.railsEnv : '';
export const getIsProdEnv = () => getRailsContextEnv() === Env.Production;
export const getIsDevEnv = () => getRailsContextEnv() === Env.Development;
export const getIsStagingEnv = () => getRailsContextEnv() === Env.Staging;
export const getIsTestEnv = () => getRailsContextEnv() === Env.Test;
