import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';

export interface ClientMetaFinancialStrategiesGetResponse {
  has_financial_strategies: boolean;
}

export function clientMetaFinancialStrategiesApi(path: string) {
  return {
    path,
    GET: (config?: RequestConfig) =>
      api.get<ClientMetaFinancialStrategiesGetResponse>(path, config),
  };
}
