import React, { useCallback, useContext } from 'react';
import { darken, rem } from 'polished';
import styled from 'styled-components';

import GenericExpandableMenuContext from './GenericExpandableMenuContext';

const StyledButton = styled.button`
  display: block;
  padding: ${rem(8)} 0 0;
  min-width: ${rem(150)};
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizes.dbNormal};
  color: ${({ theme }) => theme.colors.textLight};
  outline: none;
  transition: color 0.3s ease;

  &:not(:last-child) {
    padding-bottom: ${rem(8)};
    border-bottom: 1px solid ${({ theme }) => theme.colors.greyLighten};
  }

  &:focus,
  &:hover,
  &:active {
    ${({ theme }) => `color: ${darken(0.15, theme.colors.bermudaGray)}`};
  }
`;

export type Props = React.ButtonHTMLAttributes<HTMLButtonElement> &
  Required<Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>>;

export default function GenericExpandableMenuItem({ onClick, ...rest }: Props) {
  const { closeMenu } = useContext(GenericExpandableMenuContext);
  const handleOnClick = useCallback<Props['onClick']>(
    event => {
      if (closeMenu) {
        closeMenu();
      }

      if (onClick) {
        return onClick(event);
      }
    },
    [closeMenu, onClick]
  );

  return <StyledButton type="button" onClick={handleOnClick} {...rest} />;
}
