import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

import { clientAdditionalAdvisersAuditTrailApi } from './auditTrail';

export interface ClientsAdditionalAdvisersPostOptions {
  adviserIds: number[];
}

export function clientsAdditionalAdvisersApi(path: string) {
  return {
    path,
    auditTrail: () => clientAdditionalAdvisersAuditTrailApi(`${path}/audit-trail`),
    POST: (params: ClientsAdditionalAdvisersPostOptions) =>
      api.post(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
