import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';

import { adviceRoomUpdateRecordingsStateApi } from './updateRecordingState';

export function adviceRoomRecordingsApi(path: string) {
  return {
    path,
    updateRecordingState: () =>
      adviceRoomUpdateRecordingsStateApi(`${path}/update-recording-state`),
    GET: (config?: RequestConfig) => api.get<JsonApiResponse>(path, config),
  };
}
