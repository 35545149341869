import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

import { clientNotificationMarkAsReadApi } from './markAsRead';

export interface ClientNotificationPatchOptions {
  startAt: string;
  endAt: string;
  file?: File;
}

export function clientNotificationApi(path: string) {
  return {
    path,
    markAsRead: () => clientNotificationMarkAsReadApi(`${path}/mark-as-read`),
    PATCH: (params: ClientNotificationPatchOptions) =>
      api.patch<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      ),
    DELETE: () => api.delete(path),
  };
}
