import React from 'react';
import { useIntl } from 'react-intl';

import { useGetPracticeSettings } from 'store/settings/selectors';

import swLogo from '../../../../assets/images/sw-logo.svg';

const LogoIcon = () => {
  const intl = useIntl();
  const { logo } = useGetPracticeSettings();

  return (
    <img
      data-test="img-icon"
      src={logo || swLogo}
      alt={intl.formatMessage({ id: 'shared.logo' })}
    />
  );
};

export default LogoIcon;
