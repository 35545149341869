import { useSelector } from 'react-redux';

import EntityPath from 'constants/entitiesPaths';
import type { AdviceRoom } from 'types/entities/adviceRoom';

import { createEntitySelectors } from '../entities/selectors';
import type { AppState } from '../types';

const { getById } = createEntitySelectors<AdviceRoom>(EntityPath.AdviceRooms);

export { getById as getAdviceRoom };

export const useGetAdviceRoomById = (id: Parameters<typeof getById>[1]) =>
  useSelector((state: AppState) => getById(state, id));
