import {
  AmountRepresentingSubQuestionName,
  FinancialPlanningHealthCheckQuestionName,
  FinancialPlanningHealthCheckVersion,
  RealisticallyIdealSubQuestionName,
} from 'types/entities/financialAudit';

const commonDescriptions = {
  [FinancialPlanningHealthCheckQuestionName.ConfidenceOnFinancialPlan]: `
    <p>
      The answer here “Decreased”, “Remained the same”, “Increased” in relation to confidence in their
      finances will give an valuable insight into their financial state of mind. This will be
      essential to focus on when thinking about any financial plan that may emerge for them to
      consider, once you have carried out further discovery e.g. through a full CFR. It will also
      enable you to more carefully nuance your conversations with your client, having this simply
      expressed understanding of how they are feeling.
    </p>
  `,
};

const currentFormTranslations = {
  [RealisticallyIdealSubQuestionName.RealisticallyIdealInRelationToIncome]: {
    definitions: ``,
    A: {
      text: `Yes`,
    },
    B: {
      text: `No`,
    },
  },
  [RealisticallyIdealSubQuestionName.RealisticallyIdealInRelationToCapital]: {
    definitions: ``,
    A: {
      text: `Yes`,
    },
    B: {
      text: `No`,
    },
  },
  [RealisticallyIdealSubQuestionName.RealisticallyIdealInRelationToWork]: {
    definitions: ``,
    A: {
      text: `Yes`,
    },
    B: {
      text: `No`,
    },
  },
  [RealisticallyIdealSubQuestionName.RealisticallyIdealInRelationToMoneyExpend]: {
    definitions: ``,
    A: {
      text: `Yes`,
    },
    B: {
      text: `No`,
    },
  },
  [RealisticallyIdealSubQuestionName.RealisticallyIdealInRelationToTimeExpend]: {
    definitions: ``,
    A: {
      text: `Yes`,
    },
    B: {
      text: `No`,
    },
  },
  [RealisticallyIdealSubQuestionName.RealisticallyIdealInRelationToRetirement]: {
    definitions: ``,
    A: {
      text: `Yes`,
    },
    B: {
      text: `No`,
    },
  },
  [AmountRepresentingSubQuestionName.AmountRepresentingCapitalIndependence]: {
    definitions: ``,
    text: `Capital`,
  },
  [AmountRepresentingSubQuestionName.AmountRepresentingIncomeIndependence]: {
    definitions: ``,
    text: `Income`,
  },
  [FinancialPlanningHealthCheckQuestionName.HasGrabbedTaxSavingOpportunities]: {
    definitions: ``,
    A: {
      text: `Yes`,
      description: `
        <p>
          A great opportunity to explain and suggest a Tax Health Check.
        </p>
      `,
    },
    B: {
      text: `No`,
      description: `
        <p>
          A great opportunity to explain and suggest a Tax Health Check.
        </p>
      `,
    },
  },
  [FinancialPlanningHealthCheckQuestionName.IsHappyAboutAssetPassingAfterDeath]: {
    definitions: ``,
    A: {
      text: `Yes`,
      description: `
        <p>
          An opportunity to ask whether it reflects their current wishes in relation to the
          distribution of their estate and the importance of regular review.
        </p>
      `,
    },
    B: {
      text: `No`,
      description: `
        <p>
          An opportunity to simply explain the importance of having an up to date will. It will also
          give the opportunity to explain the intestacy rules, see Techlink and to test whether they
          are happy with what they would mean for your client/prospect.
        </p>
        <p>
          Action from either answer could include a reference to one of the SJP legal panel or to
          the client’s own legal advisers or another connection of the Partner or Adviser.
        </p>
      `,
    },
  },
  [FinancialPlanningHealthCheckQuestionName.MadeProvisionsForAffairsToBeLooked]: {
    definitions: ``,
    A: {
      text: `Yes`,
      description: `
        <p>
          An opportunity to find out in detail and to discuss the merits of an Lasting Power of
          Attorney (LPA) and the difference between a LPA and an Enduring Power of Attorney (EPA).
          See Techlink for detail.
        </p>
      `,
    },
    B: {
      text: `No`,
      description: `
        <p>
          An opportunity to discuss the importance and merits/benefits of a LPA and the potential
          consequences of not having one, with appropriate easy to understand and appropriate examples.
        </p>
      `,
    },
  },
  [FinancialPlanningHealthCheckQuestionName.FinanciallyDependentsHaveEnoughAssets]: {
    definitions: ``,
    A: {
      text: `Yes`,
      description: `
        <p>
          An opportunity to test the amount provided against the likely financial need to ascertain
          any gap or lack of appreciation of the amount of capital required to deliver desired
          lifestyle. An opportunity also to discuss the role of appropriate protection in trust.
        </p>
      `,
    },
    B: {
      text: `No`,
      description: `
        <p>
          An opportunity to discuss, in principle, the role of appropriate protection plans in trust.
        </p>
      `,
    },
    C: {
      text: `N/A`,
    },
  },
  [FinancialPlanningHealthCheckQuestionName.WordBestDescribesAboutFinancialObjectives]: {
    definition: ``,
    text: `FinancialObjective`,
  },
  [FinancialPlanningHealthCheckQuestionName.ConfidenceOnFinancialPlan]: {
    definitions: ``,
    A: {
      text: `Decreased`,
      description:
        commonDescriptions[FinancialPlanningHealthCheckQuestionName.ConfidenceOnFinancialPlan],
    },
    B: {
      text: `Remained the same`,
      description:
        commonDescriptions[FinancialPlanningHealthCheckQuestionName.ConfidenceOnFinancialPlan],
    },
    C: {
      text: `Increased`,
      description:
        commonDescriptions[FinancialPlanningHealthCheckQuestionName.ConfidenceOnFinancialPlan],
    },
  },
};

export default {
  maxInputLimit: `Maximum 100 characters are allowed`,
  emptyForm: `There are no answers yet.`,
  clientAnswer: `Client’s answer was:`,
  previousAnswer: `Previous answer was:`,
  answersChanged: `Save changes?`,
  answersChangedDescription: `You have changed the client’s answers. Please confirm and save or cancel changes.`,
  title: `Personal Financial Planning Health Check (UK v1)`,
  blocked: `This Personal Financial Planning Health Check relates to a previous tax year, the client did not complete it before the tax year ended. However, it is now marked as completed.`,
  notEditableText: `This Personal Financial Planning Health Check was created in a previous tax year. As such, it is no longer editable.`,
  notDoneOldVersion: `This Personal Financial Planning Health Check relates to a previous tax year, and as such is no longer relevant and cannot be completed. However, you can mark this audit as complete to progress this questionnaire.`,
  markedOldVersion: `This Personal Financial Planning Health Check relates to a previous tax year. You didn't complete it before the tax year ended. However, you have marked it as completed.`,
  [FinancialPlanningHealthCheckVersion.Current]: {
    stepBegin: {
      description0: `Please answer the following eight simple questions to help us to understand your financial concerns and aspirations.`,
      description1: `Note: This Personal Financial Planning Health Check is aimed at residents of the United Kingdom.`,
    },
    [FinancialPlanningHealthCheckQuestionName.RealisticallyIdeal]: {
      question: `Can you envision what the 'realistically ideal' scenario would be for you in relation to:`,
      commonDescription: `
        <p>Yes:</p>
        <p>
          Look to get the detail of what “ideal” is for each of the items in this question. This will
          give you something specific to discuss with your client in order to secure a greater
          understanding of their aspirations and what’s important to them.
        </p>
        <p>No:</p>
        <p>
          An opportunity for you to help your client to clarify what’s important to them and to start
          the process towards building a financial plan for them that is designed to achieve their
          objectives, founded on what’s important to them in relation to the key aspects of their life.
          A full CFR will of course be essential before any detailed plans can be formulated. However
          clarifying goals comes before a full CFR, and this discovery will be a great starting point.
        </p>
      `,
      subQuestion: {
        [RealisticallyIdealSubQuestionName.RealisticallyIdealInRelationToIncome]: {
          question: `Your income?`,
          ...currentFormTranslations[
            RealisticallyIdealSubQuestionName.RealisticallyIdealInRelationToIncome
          ],
        },
        [RealisticallyIdealSubQuestionName.RealisticallyIdealInRelationToCapital]: {
          question: `Your capital?`,
          ...currentFormTranslations[
            RealisticallyIdealSubQuestionName.RealisticallyIdealInRelationToCapital
          ],
        },
        [RealisticallyIdealSubQuestionName.RealisticallyIdealInRelationToWork]: {
          question: `Your work?`,
          ...currentFormTranslations[
            RealisticallyIdealSubQuestionName.RealisticallyIdealInRelationToWork
          ],
        },
        [RealisticallyIdealSubQuestionName.RealisticallyIdealInRelationToMoneyExpend]: {
          question: `How you spend your money?`,
          ...currentFormTranslations[
            RealisticallyIdealSubQuestionName.RealisticallyIdealInRelationToMoneyExpend
          ],
        },
        [RealisticallyIdealSubQuestionName.RealisticallyIdealInRelationToTimeExpend]: {
          question: `How you spend your time?`,
          ...currentFormTranslations[
            RealisticallyIdealSubQuestionName.RealisticallyIdealInRelationToTimeExpend
          ],
        },
        [RealisticallyIdealSubQuestionName.RealisticallyIdealInRelationToRetirement]: {
          question: `Your retirement?`,
          ...currentFormTranslations[
            RealisticallyIdealSubQuestionName.RealisticallyIdealInRelationToRetirement
          ],
        },
      },
    },
    [FinancialPlanningHealthCheckQuestionName.AmountRepresenting]: {
      question: `What amount of (a)capital and (b)income represents “financial independence” for you?`,
      commonDescription: `
        <p>
          Whatever is stated here will give you an insight into the thinking and aspirations of your
          existing or potential client. It will provide an initial starting point or “anchor” for an
          informed conversation about capital, income produced, reasonable returns and expectations.
          It may require you to test the reality of the number stated and very possibly to educate
          on returns to reasonably expect and the assumptions on which they are founded.
        </p>
      `,
      subQuestion: {
        [AmountRepresentingSubQuestionName.AmountRepresentingCapitalIndependence]: {
          question: `Capital`,
          ...currentFormTranslations[
            AmountRepresentingSubQuestionName.AmountRepresentingCapitalIndependence
          ],
        },
        [AmountRepresentingSubQuestionName.AmountRepresentingIncomeIndependence]: {
          question: `Income`,
          ...currentFormTranslations[
            AmountRepresentingSubQuestionName.AmountRepresentingIncomeIndependence
          ],
        },
      },
    },
    [FinancialPlanningHealthCheckQuestionName.HasGrabbedTaxSavingOpportunities]: {
      question: `Do you (at least once a year) check that you are making the most of the tax allowances, exemptions and tax saving opportunities available to you?`,
      ...currentFormTranslations[
        FinancialPlanningHealthCheckQuestionName.HasGrabbedTaxSavingOpportunities
      ],
    },
    [FinancialPlanningHealthCheckQuestionName.IsHappyAboutAssetPassingAfterDeath]: {
      question: `Are you happy that your assets would pass to those you want them to pass to on your death?`,
      ...currentFormTranslations[
        FinancialPlanningHealthCheckQuestionName.IsHappyAboutAssetPassingAfterDeath
      ],
    },
    [FinancialPlanningHealthCheckQuestionName.MadeProvisionsForAffairsToBeLooked]: {
      question: `Have you made provision for your affairs to be looked after should you lose the capacity to do so yourself?`,
      ...currentFormTranslations[
        FinancialPlanningHealthCheckQuestionName.MadeProvisionsForAffairsToBeLooked
      ],
    },
    [FinancialPlanningHealthCheckQuestionName.FinanciallyDependentsHaveEnoughAssets]: {
      question: `Do you feel that those financially dependent on you (if relevant) would have enough to enjoy the life that you would wish for them in the event of your death?`,
      ...currentFormTranslations[
        FinancialPlanningHealthCheckQuestionName.FinanciallyDependentsHaveEnoughAssets
      ],
    },
    [FinancialPlanningHealthCheckQuestionName.WordBestDescribesAboutFinancialObjectives]: {
      question: `What one word best describes the way you feel about your finances and your financial objectives?`,
      commonDescription: `
        <p>
          This could give you a great insight into how your client really feels about money and finance.
          How they feel e.g. “anxious”, “confident”, “unsure”, is likely to strongly influence their
          feelings about various strategies that may seem appropriate for them, and any ideas you may
          have for improving their financial well-being once you have discovered more – likely through
          a full CFR. It’s really important to listen to the answer.
        </p>
      `,
      ...currentFormTranslations[
        FinancialPlanningHealthCheckQuestionName.WordBestDescribesAboutFinancialObjectives
      ],
    },
    [FinancialPlanningHealthCheckQuestionName.ConfidenceOnFinancialPlan]: {
      question: `In the past 12 months, would you say your confidence in your finances and financial plan has:`,
      ...currentFormTranslations[
        FinancialPlanningHealthCheckQuestionName.ConfidenceOnFinancialPlan
      ],
    },
  },
};
