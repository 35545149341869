import { combineReducers } from 'redux';

import clientCases from './clientCases/reducer';
import currentCase from './currentCase/reducer';
import currentClientId from './currentClient/reducer';
import currentUser from './currentUser/reducer';
import documents from './documents/reducer';
import documentSharing from './documentSharing/reducer';
import entities from './entities/reducer';
import financialAudit from './financialAudit/reducer';
import financialPlanningHealthCheck from './financialPlanningHealthCheck/reducer';
import guests from './guests/reducer';
import identityVerification from './identityVerifications/reducer';
import layout from './layout/reducer';
import messages from './messages/reducer';
import permissions from './permissions/reducer';
import riskAssessments from './riskAssessments/reducer';
import sessionExpiration from './sessionExpiration/reducer';
import todoList from './todoList/reducer';
import videoChat from './videoChat/reducer';
import website from './website/reducer';

export default combineReducers({
  clientCases,
  currentCase,
  currentClientId,
  currentUser,
  documents,
  documentSharing,
  entities,
  financialAudit,
  financialPlanningHealthCheck,
  guests,
  identityVerification,
  layout,
  messages,
  permissions,
  riskAssessments,
  sessionExpiration,
  todoList,
  videoChat,
  website,
});
