import { omit } from 'lodash';
import type { AnyAction } from 'redux';

import {
  GET_CLIENT_CASE_DATA,
  GET_CLIENT_CASE_DATA_FAIL,
  GET_CLIENT_CASE_DATA_SUCCESS,
} from './actionTypes';
import type { ClientCasesActionTypes, ClientCasesState } from './types';

export const initialState: ClientCasesState = {
  isLoadingById: {},
};

export default (state = initialState, incomingAction: AnyAction): ClientCasesState => {
  const action = incomingAction as ClientCasesActionTypes;

  switch (action.type) {
    case GET_CLIENT_CASE_DATA:
      return {
        ...state,
        isLoadingById: {
          ...state.isLoadingById,
          [action.payload.caseId]: true,
        },
      };
    case GET_CLIENT_CASE_DATA_SUCCESS:
    case GET_CLIENT_CASE_DATA_FAIL:
      return {
        ...state,
        isLoadingById: omit(state.isLoadingById, action.payload.caseId),
      };
    default:
      return state;
  }
};
