import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';
import { mapFormData } from 'helpers/formData';

export interface AdvisersPreferredPatchOptions {
  advisersIds: number[];
}

export function advisersPreferredApi(path: string) {
  return {
    path,
    GET: (config?: RequestConfig) => api.get<JsonApiResponse>(path, config),
    PATCH: (params: AdvisersPreferredPatchOptions) =>
      api.patch<JsonApiResponse>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
