export const MESSAGE_PUBLISHED = 'messagePublished';
export const TOKEN_ABOUT_TO_EXPIRE = 'tokenAboutToExpire';
export const UPDATED = 'updated';
export const ADVICE_ROOM_DOCUMENTS_UPDATED = 'adviceRoomDocumentsUpdated';
export const ADVICE_ROOM_RECORDING_STATE_UPDATED = 'adviceRoomRecordingStateUpdated';
export const ADVICE_ROOM_RECORDING_STATE_UPDATE_STARTED = 'adviceRoomRecordingStateUpdateStarted';
export const OTHER_DOCUMENT_SIGNED = 'otherDocumentSigned';
export const ADVICE_ROOM_JOINED = 'adviceRoomJoined';
export const CLIENT_MESSAGE_DELETED = 'clientMessageDeleted';
export const CLIENT_MESSAGE_NEW = 'clientMessageNew';
export const PRACTICE_MESSAGE_DELETED = 'practiceMessageDeleted';
export const PRACTICE_MESSAGE_NEW = 'practiceMessageNew';
