import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, PaginationOptions, RequestConfig } from 'api/types';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

import { clientPracticeMessagesMarkAllAsSeenApi } from './markAllAsSeen';
import { clientPracticeMessagesMarkAsSeenApi } from './markAsSeen';

export interface ClientPracticeMessagesPostOptions {
  content?: string;
  files?: File[] | null;
}

export type ClientPracticeMessagesGetOptions = GetOptions & PaginationOptions;
export type ClientPracticeMessagesGetResponse = JsonApiResponse & {
  meta: { unread_client_notes_count: number; unread_client_practice_notes_count: number };
};

export function clientPracticeMessagesApi(path: string) {
  return {
    path,
    markAllAsSeen: () => clientPracticeMessagesMarkAllAsSeenApi(`${path}/mark-all-as-seen`),
    markAsSeen: () => clientPracticeMessagesMarkAsSeenApi(`${path}/mark-as-seen`),
    GET: (
      { pageSize, pageNumber, ...rest }: ClientPracticeMessagesGetOptions,
      config?: RequestConfig
    ) =>
      api.get<ClientPracticeMessagesGetResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...rest,
        },
        ...config,
      }),
    POST: (params: ClientPracticeMessagesPostOptions) =>
      api.post<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        }),
        {
          params: {
            include: 'creator',
          },
        }
      ),
  };
}
