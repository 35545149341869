import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';
import { mapFormDataDeep } from 'helpers/formData';
import type { EstateSummary } from 'types/entities/estateSummary';

import { clientEstateSummaryAuditTrailApi } from './auditTrail';
import { clientEstateSummaryPrintApi } from './print';
import { clientEstateSummaryViewApi } from './view';

export type ClientEstateSummaryPatchOptions = EstateSummary['body'];

export function clientEstateSummaryApi(path: string) {
  return {
    path,
    auditTrail: () => clientEstateSummaryAuditTrailApi(`${path}/audit-trail`),
    print: () => clientEstateSummaryPrintApi(`${path}/print`),
    view: () => clientEstateSummaryViewApi(`${path}/view`),
    GET: (config?: RequestConfig) => api.get<JsonApiResponseSingle>(path, config),
    PATCH: (params: ClientEstateSummaryPatchOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormDataDeep(params),
        },
      }),
  };
}
