export enum IdentityVerificationStatuses {
  InProgress = 'In-Progress',
  Submitted = 'Submitted',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
}

export enum IdentityVerificationAuthorities {
  iAMSmart = 'iAM-Smart',
  singpass = 'Singpass',
}

export interface IdentityVerification {
  id: number;
  authority: IdentityVerificationAuthorities;
  coverNote: string;
  clientId: number;
  createdAt: string;
  practiceId: number;
  name?: string;
  nationality?: string;
  dateOfBirth?: string;
  address?: string;
  addressType?: string;
  aliasName?: string;
  hanyuPinyinName?: string;
  hanyuPinyinAliasName?: string;
  marriedName?: string;
  sex?: string;
  birthCountry?: string;
  passType?: string;
  passStatus?: string;
  passExpiryDate?: string;
  status: IdentityVerificationStatuses;
  requestDate: string;
  updatedAt: string;
  uniqueIdentifier?: string;
}
export interface MyInfoResponse {
  uniqueIdentifier: string;
  name: string;
  nationality: string;
  dateOfBirth: string;
  aliasName: string;
  hanyuPinyinName: string;
  hanyuPinyinAliasName: string;
  marriedName: string;
  sex: string;
  birthCountry: string;
  passType: string;
  passStatus: string;
  passExpiryDate: string;
  address: string;
  addressType: string;
  file?: string;
  errorPresent?: boolean;
  chineseName?: string;
  errorResponse?: string;
}
