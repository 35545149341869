import React, { Attributes, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { some } from 'lodash';
import { rem, size } from 'polished';
import { clearTimeout, setTimeout, Timeout } from 'safe-timers';
import styled from 'styled-components';
import type { RemoteVideoTrackPublication } from 'twilio-video';

import { isScreenSharingTrack } from 'api/twilio/twilioVideo';
import { TooltipPlacement } from 'components/GenericTooltip';
import BasicTooltip, { Props as BasicTooltipProps } from 'components/GenericTooltip/BasicTooltip';
import IconArrows from 'components/Icons/Chat/IconDiagonalArrows';
import IconDiagonalArrowsInner from 'components/Icons/Chat/IconDiagonalArrowsInner';
import IconDominantSpeakerLayout from 'components/Icons/Chat/IconDominantSpeakerLayout';
import IconGridLayout from 'components/Icons/Chat/IconGridLayout';
import { PinIcon } from 'components/Icons/Chat/PinIcon';
import { UnpinIcon } from 'components/Icons/Chat/UnpinIcon';
import { useUpdateOnEvents } from 'components/VideoChat/useUpdateOnEvents';
import { useVideoContext } from 'components/VideoChat/VideoContext';

import { useChatWindowContext } from '../ChatWindow/ChatWindowContext';

const Button = styled.button<{ isSmall?: boolean }>`
  ${({ isSmall }) => size(rem(isSmall ? 24 : 30))};
  padding: 0;
  pointer-events: all;
  outline: none;
  color: ${({ theme }) => theme.colors.white};
  transition: transform ease-in-out 0.3s;

  &:hover {
    transform: scale(1.1);
  }

  svg {
    ${size('calc(100% - 2px)')};
    stroke: ${({ theme }) => theme.colors.greyLighten};
    stroke-width: 1px;
  }

  & + & {
    margin-left: ${rem(8)};
  }
`;

const EVENTS = [
  'disconnected',
  'participantConnected',
  'participantDisconnected',
  'participantReconnected',
  'participantReconnecting',
  'reconnected',
  'trackPublished',
  'trackStarted',
  'trackSubscribed',
  'trackSwitchedOff',
  'trackSwitchedOn',
  'trackUnpublished',
  'trackUnsubscribed',
];

interface BaseButtonProps {
  onClick: () => void;
  isEnabled?: boolean;
}

export function FullscreenButton({ onClick, isEnabled }: BaseButtonProps) {
  const { room } = useVideoContext();
  const { isUISmall } = useChatWindowContext();
  const timerRef = useRef<Timeout>();

  useUpdateOnEvents(room, EVENTS);

  const [screenshareTooltipVisible, setScreenshareTooltipVisible] = useState(false);

  const remoteParticipants = room ? Array.from(room.participants.values()) : [];

  const isRemoteParticipantScreenSharing = some(remoteParticipants, participant => {
    const tracks = Array.from(
      participant.videoTracks.values() as IterableIterator<RemoteVideoTrackPublication>
    );

    return some(tracks, isScreenSharingTrack);
  });

  useEffect(() => {
    setScreenshareTooltipVisible(isRemoteParticipantScreenSharing);

    if (isRemoteParticipantScreenSharing) {
      timerRef.current = setTimeout(() => {
        setScreenshareTooltipVisible(false);
      }, 5000);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isRemoteParticipantScreenSharing]);

  const tooltipProps: Omit<BasicTooltipProps, 'children'> & Attributes =
    isRemoteParticipantScreenSharing && screenshareTooltipVisible
      ? {
          key: 1,
          tooltipMessage: <FormattedMessage id="adviceRooms.adviceRoom.toggleFullscreenLong" />,
          position: TooltipPlacement.Top,
          visible: !isEnabled,
        }
      : {
          key: 2,
          tooltipMessage: <FormattedMessage id="adviceRooms.adviceRoom.toggleFullscreen" />,
          position: TooltipPlacement.Bottom,
        };

  return (
    <BasicTooltip {...tooltipProps}>
      <Button isSmall={isUISmall} onClick={() => onClick()}>
        {isEnabled ? <IconDiagonalArrowsInner /> : <IconArrows />}
      </Button>
    </BasicTooltip>
  );
}

export function LayoutButton({ onClick, isEnabled }: BaseButtonProps) {
  const { isUISmall } = useChatWindowContext();

  return (
    <BasicTooltip
      tooltipMessage={<FormattedMessage id="adviceRooms.adviceRoom.changeLayout" />}
      position={TooltipPlacement.Bottom}
    >
      <Button isSmall={isUISmall} onClick={() => onClick()}>
        {isEnabled ? <IconDominantSpeakerLayout /> : <IconGridLayout />}
      </Button>
    </BasicTooltip>
  );
}

export function PinButton({ onClick, isEnabled }: BaseButtonProps) {
  const { isUISmall } = useChatWindowContext();

  return (
    <BasicTooltip
      tooltipMessage={<FormattedMessage id="adviceRooms.adviceRoom.toggleSticky" />}
      position={TooltipPlacement.Bottom}
    >
      <Button isSmall={isUISmall} onClick={() => onClick()}>
        {isEnabled ? <UnpinIcon /> : <PinIcon />}
      </Button>
    </BasicTooltip>
  );
}
