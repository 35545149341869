import type { CancelToken } from 'axios';
import axios from 'axios';

export function createCancelablePromise<T>(
  promiseCreator: (cancelToken: CancelToken) => Promise<T>
) {
  const source = axios.CancelToken.source();
  const promise = promiseCreator(source.token);

  promise.cancel = () => {
    source.cancel();
  };

  return promise;
}
