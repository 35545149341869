import { useEffect } from 'react';
import { useForm } from 'react-final-form';

export function useReinitializeOnChange<T>(id: string, value: T) {
  const form = useForm();

  useEffect(() => {
    const { initialValues } = form.getState();

    form.initialize({
      ...initialValues,
      pdfData: {
        ...initialValues.pdfData,
        [id]: value,
      },
    });
  }, [value, form, id]);
}
