import { each, filter } from 'lodash';
import TwilioVideo, {
  CreateLocalTrackOptions,
  LocalAudioTrack,
  LocalVideoTrack,
} from 'twilio-video';

import { getLocalStorageValue, setLocalStorageValue } from 'helpers/localStorage';
import { isNotBlank } from 'helpers/utils';
import { areDevicesSupported } from 'components/VideoChat/utils';

import {
  dispatchAudioDeviceChangedEvent,
  dispatchAudioOutputDeviceChangedEvent,
  dispatchVideoDeviceChangedEvent,
} from './deviceEvents';

const selectedAudioOutputDeviceKey = 'selectedAudioOutputDevice';
const selectedAudioDeviceKey = 'selectedAudioDevice';
const selectedVideoDeviceKey = 'selectedVideoDevice';

// Filters out devices without deviceId (not accessible at the moment)
const filterOutInvalidDevices = (devices: MediaDeviceInfo[]) =>
  filter(devices, device => isNotBlank(device.deviceId));

export const getDevices = async () =>
  areDevicesSupported()
    ? filterOutInvalidDevices(await navigator.mediaDevices.enumerateDevices())
    : Promise.resolve([] as MediaDeviceInfo[]);

export const getSelectedAudioOutputDevice = () => {
  return getLocalStorageValue<string>(selectedAudioOutputDeviceKey) || undefined;
};

export const getSelectedAudioDevice = () => {
  return getLocalStorageValue<string>(selectedAudioDeviceKey) || undefined;
};

export const getSelectedVideoDevice = () => {
  return getLocalStorageValue<string>(selectedVideoDeviceKey) || undefined;
};

export const setSelectedAudioOutputDevice = (deviceId: string | undefined) => {
  if (deviceId === getSelectedAudioOutputDevice()) {
    return;
  }

  setLocalStorageValue(selectedAudioOutputDeviceKey, deviceId);
  dispatchAudioOutputDeviceChangedEvent(deviceId);
};

export const setSelectedAudioDevice = (deviceId: string | undefined) => {
  if (deviceId === getSelectedAudioDevice()) {
    return;
  }

  setLocalStorageValue(selectedAudioDeviceKey, deviceId);
  dispatchAudioDeviceChangedEvent(deviceId);
};

export const setSelectedVideoDevice = (deviceId: string | undefined) => {
  if (deviceId === getSelectedVideoDevice()) {
    return;
  }

  setLocalStorageValue(selectedVideoDeviceKey, deviceId);
  dispatchVideoDeviceChangedEvent(deviceId);
};

// storage events allow to detect if value in the storage was changed in other tab/window
window.addEventListener('storage', async (e: StorageEvent) => {
  if (e.key === selectedAudioOutputDeviceKey) {
    dispatchAudioOutputDeviceChangedEvent(e.newValue || undefined);
  }

  if (e.key === selectedAudioDeviceKey) {
    dispatchAudioDeviceChangedEvent(e.newValue || undefined);
  }

  if (e.key === selectedVideoDeviceKey) {
    dispatchVideoDeviceChangedEvent(e.newValue || undefined);
  }

  // storage was cleared
  if (e.key == null) {
    dispatchAudioOutputDeviceChangedEvent(undefined);
    dispatchAudioDeviceChangedEvent(undefined);
    dispatchVideoDeviceChangedEvent(undefined);
  }
});

export const SCREEN_TRACK_NAME = 'screen';
export const VIDEO_TRACK_NAME = 'camera';
export const AUDIO_TRACK_NAME = 'microphone';

export const isScreenSharingTrack = ({ trackName }: { trackName: string }) =>
  trackName === SCREEN_TRACK_NAME;

export const isVideoTrack = ({ trackName }: { trackName: string }) =>
  trackName === VIDEO_TRACK_NAME;

export const createVideoTrack = async (options?: CreateLocalTrackOptions) =>
  TwilioVideo.createLocalVideoTrack({
    name: VIDEO_TRACK_NAME,
    ...options,
  });

export const createAudioTrack = async (options?: CreateLocalTrackOptions) =>
  TwilioVideo.createLocalAudioTrack({
    name: AUDIO_TRACK_NAME,
    ...options,
  });

let devicePermissionsPromise: Promise<boolean> | null = null;

const requestDevicePermissionsInternal = async () => {
  try {
    const tracks = await TwilioVideo.createLocalTracks({
      audio: true,
      video: true,
    });
    each(tracks, track => (track as LocalAudioTrack | LocalVideoTrack).stop());
    return true;
  } catch {
    return false;
  }
};

export const requestDevicePermissions = async () => {
  if (!devicePermissionsPromise) {
    devicePermissionsPromise = requestDevicePermissionsInternal();
  }

  return devicePermissionsPromise;
};
