import React from 'react';

import SvgIcon from './SvgIcon';

const IconQuestionMark = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 44 44" {...props}>
    <path
      d="M22 .1A21.84 21.84 0 0 0 .1 22C.1 34.14 9.86 43.9 22 43.9c12.14 0 21.9-9.76
        21.9-21.9C43.9 9.86 34.14.1 22 .1zm2 33.85c0 .6-.4 1-1 1h-2c-.6 0-1-.4-1-1v-2c0-.6.4-1
        1-1h2c.6 0 1 .4 1 1zm2.68-8.86c-1.4 1.19-2.39 1.99-2.69 3.08-.1.5-.5.8-1
        .8H21c-.6 0-1.09-.5-1-1.1.4-2.88 2.5-4.48 4.19-5.87 1.8-1.4 2.79-2.29 2.79-3.98A4.93
        4.93 0 0 0 22 13.04a4.93 4.93 0 0 0-4.98 4.98v.6c.1.5-.2 1-.7 1.09l-1.89.6a1 1 0 0
        1-1.29-.8c-.1-.5-.1-1-.1-1.5A8.92 8.92 0 0 1 22 9.07a8.92 8.92 0 0 1 8.96 8.96c0
        3.68-2.39 5.57-4.28 7.07z"
    />
  </SvgIcon>
);

export default IconQuestionMark;
