import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { rem } from 'polished';
import styled from 'styled-components';

import { breakpoints, misc } from 'helpers/styles/constants';
import { passwordConfirmation, required } from 'helpers/validators';
import FieldRecaptcha from 'components/FieldRecaptcha/FieldRecaptcha';
import FormActionResponsiveButtons from 'components/FormActionButtons/FormActionResponsiveButtons';
import { Field, Label } from 'components/Forms';
import GenericInput from 'components/GenericInput/GenericInput';
import { GenericTitle, TitleMargin } from 'components/GenericTitle/GenericTitle';
import GenericTitleSummary from 'components/GenericTitleSummary/GenericTitleSummary';
import { FieldSpacer } from 'components/Layout';

import { Fields, StepProps } from '../../types';
import OnboardingPasswordRules from '../shared/OnboardingPasswordRules';

const InputWrapper = styled.div<{ isNarrow?: boolean }>`
  @media ${breakpoints.mdUp} {
    max-width: ${({ isNarrow }) => rem(misc.dashboardBtnWidthLg + (isNarrow ? 0 : 130))};
  }
`;

const StepContact: FC<StepProps> = ({ submitting }) => (
  <>
    <GenericTitle marginBottom={TitleMargin.Sm} isMedium>
      <FormattedMessage id="onboarding.stepContact.title" tagName="span" />
    </GenericTitle>

    <GenericTitleSummary hasMarginBottomMd>
      <FormattedMessage id="onboarding.stepContact.summary" tagName="span" />
    </GenericTitleSummary>

    <GenericTitle marginBottom={TitleMargin.Sm} isMedium>
      <FormattedMessage id="onboarding.stepContact.cityLabel" tagName="span" />
    </GenericTitle>

    <GenericTitleSummary hasMarginBottomMd>
      <FormattedMessage id="onboarding.stepContact.cityDescription" tagName="span" />
    </GenericTitleSummary>

    <InputWrapper isNarrow>
      <Field<string>
        name={Fields.City}
        renderLabel={({ input }) => (
          <Label text="onboarding.stepContact.cityLabel" elementId={input.id} />
        )}
        renderControl={({ input, meta }) => (
          <GenericInput {...input} disabled={submitting} hasError={meta.showError} hasFullWidth />
        )}
      />

      <FieldSpacer />
    </InputWrapper>

    <GenericTitle as="h3" marginBottom={TitleMargin.Sm} isMedium>
      <FormattedMessage id="onboarding.stepContact.accountTitle" />
    </GenericTitle>

    <GenericTitleSummary hasMarginBottomMd>
      <FormattedMessage id="onboarding.stepContact.accountSummary" />
    </GenericTitleSummary>

    <InputWrapper>
      <Field
        name={Fields.Email}
        validate={required}
        renderLabel={({ input }) => <Label text="shared.fields.email" elementId={input.id} />}
        renderControl={({ input, meta }) => (
          <GenericInput
            {...input}
            type="email"
            placeholder="shared.fields.emailPlaceholder"
            disabled={submitting}
            hasError={meta.showError}
            hasNoBackground
            isSmall
            hasFullWidth
          />
        )}
      />

      <FieldSpacer />
    </InputWrapper>

    <InputWrapper>
      <Field
        name={Fields.Password}
        validate={required}
        renderLabel={({ input }) => <Label text="shared.fields.password" elementId={input.id} />}
        renderControl={({ input, meta }) => (
          <GenericInput
            {...input}
            type="password"
            placeholder="onboarding.stepEditEmail.passwordPlaceholder"
            disabled={submitting}
            hasError={meta.showError}
            hasNoBackground
            isSmall
            hasFullWidth
          />
        )}
      />

      <FieldSpacer />
    </InputWrapper>

    <InputWrapper>
      <Field<string>
        name={Fields.PasswordConfirmation}
        validate={(value, allValues) =>
          passwordConfirmation(get(allValues, Fields.Password), value)
        }
        renderLabel={({ input }) => (
          <Label text="shared.fields.passwordConfirmation" elementId={input.id} />
        )}
        renderControl={({ input, meta }) => (
          <GenericInput
            {...input}
            type="password"
            placeholder="onboarding.stepEditEmail.passwordConfirmationPlaceholder"
            disabled={submitting}
            hasError={meta.showError}
            hasNoBackground
            isSmall
            hasFullWidth
          />
        )}
      />

      <FieldSpacer />
    </InputWrapper>

    <InputWrapper>
      <Field
        name={Fields.Phone}
        validate={required}
        renderLabel={({ input }) => <Label text="shared.fields.phone" elementId={input.id} />}
        renderControl={({ input, meta }) => (
          <GenericInput
            {...input}
            type="phone"
            placeholder="shared.fields.phonePlaceholder"
            disabled={submitting}
            hasError={meta.showError}
            hasNoBackground
            isSmall
            hasFullWidth
          />
        )}
      />

      <FieldSpacer />
    </InputWrapper>

    <FieldRecaptcha name={Fields.Recaptcha} />

    <OnboardingPasswordRules fieldName={Fields.Password} />

    <FormActionResponsiveButtons hasUnderline />
  </>
);

export default StepContact;
