import type { MotivationCentreComment } from './motivationCentreComment';
import type { User } from './user';

export interface Image {
  id: number;
  image: string;
}

export enum MotivationCentreItemType {
  Link = 'link',
  Pdf = 'pdf',
  ImageGallery = 'gallery',
}

export const motivationCentreItemTypes = [
  MotivationCentreItemType.Link,
  MotivationCentreItemType.Pdf,
  MotivationCentreItemType.ImageGallery,
];

export interface MotivationCentreItem {
  id: number;
  clientId: number;
  createdAt: string;
  updatedAt: string;
  backgroundImage?: string;
  description?: string;
  file?: string;
  itemType: MotivationCentreItemType;
  link?: string;
  position?: number;
  title?: string;
  creator?: User;
  unreadCommentsCount: number;
  motivationCentreImages?: Image[];
  motivationCentreComments?: MotivationCentreComment[];
}
