import { rem, rgba, size } from 'polished';
import styled, { css } from 'styled-components';

import { breakpoints } from 'helpers/styles/constants';
import { circle, flexCenter } from 'helpers/styles/mixins';

export const ButtonSize = 44;
const UsScale = 0.85;
const SmScale = 0.9;

export const buttonStyles = css`
  ${flexCenter()};
  ${circle(rem(ButtonSize * UsScale))};
  flex-shrink: 0;
  padding: 0;
  border: 2px solid ${({ theme }) => theme.colors.greyLighten};
  outline: none;
  transition: border-color 0.3s ease;

  & + & {
    margin-left: ${rem(10)};
  }

  &:disabled {
    cursor: default;
  }

  &:focus,
  &:hover,
  &:active {
    &:not(:disabled) {
      border-color: ${({ theme }) => theme.colors.grey};
    }
  }

  @media ${breakpoints.smUp} {
    ${circle(rem(ButtonSize * SmScale))};
  }

  @media ${breakpoints.mdUp} {
    ${circle(rem(ButtonSize))};
  }
`;

export const commonWidgetStyles = css`
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0 ${rem(3)};
  vertical-align: top;
  background-color: ${({ theme }) => rgba(theme.colors.fountainBlue, 0.25)};
  border: none;
  outline: none;

  &:not(:disabled) {
    &:focus,
    &:hover,
    &:active {
      background-color: ${({ theme }) => rgba(theme.colors.fountainBlue, 0.3)};
    }
  }
`;

export const IconStyled = styled.i<{ iconSize: number }>`
  ${({ iconSize }) => css`
    ${size(rem(iconSize * UsScale))};

    @media ${breakpoints.smUp} {
      ${size(rem(iconSize * SmScale))};
    }

    @media ${breakpoints.mdUp} {
      ${size(rem(iconSize))};
    }
  `}
`;
