import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { breakpoints } from 'helpers/styles/constants';

import type { Filter } from './BackgroundFiltersButton';

interface imageWrapperStyleProps {
  selected?: boolean;
}
export const ImageWrapper = styled.div<imageWrapperStyleProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  border-radius: 4px;
  cursor: pointer;
  border: ${({ selected }) => (selected ? '2px solid black' : '2px solid transparent')};
`;

const Image = styled.img`
  flex: 1;
  object-fit: cover;
  width: 100%;
  height: 160px;

  @media ${breakpoints.mdDown} {
    height: 110px;
  }
`;

export const Label = styled.div`
  margin-top: 9px;
  text-align: center;
  font-weight: bold;
  font-size: 0.9rem;

  @media ${breakpoints.mdDown} {
    font-size: 0.85rem;
  }

  @media ${breakpoints.smDown} {
    font-size: 0.75rem;
  }
`;

interface Props {
  src: string;
  alt: string;
  filter: Filter;
  selected: boolean;
  onSelect: (filter: Filter) => void;
}
const PreviewImage = ({ src, alt, filter, selected, onSelect }: Props) => {
  return (
    <ImageWrapper selected={selected} onClick={() => onSelect(filter)}>
      <Image src={src} alt={alt} />
      <Label>
        <FormattedMessage id={`video.settings.${filter}`} />
      </Label>
    </ImageWrapper>
  );
};

export default PreviewImage;
