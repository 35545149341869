import React from 'react';

import SvgIcon from './SvgIcon';

const IconMessage = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <path d="M15 4h-1v6a1 1 0 0 1-1 1H6.828L5 13h5l3 3v-3h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z" />
      <path d="M1 0h10a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H6l-3 3V9H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z" />
    </g>
  </SvgIcon>
);

export default IconMessage;
