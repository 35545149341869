import { Root, route } from '../utils';

const blogPath = `${Root}blog`;

type BlogPostParams = [postId: number];

export default {
  blog: {
    ...route(blogPath),
    post: route<BlogPostParams>(`${blogPath}/:postId`),
  },
};
