import type { AnyAction } from 'redux';

import { SET_CURRENT_USER } from './actionTypes';
import type { CurrentUserActionTypes, CurrentUserState } from './types';

export const initialState: CurrentUserState = {
  id: null,
};

export default (state = initialState, incomingAction: AnyAction): CurrentUserState => {
  const action = incomingAction as CurrentUserActionTypes;

  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        id: action.payload.id,
      };
    default:
      return state;
  }
};
