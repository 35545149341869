export enum Browser {
  IE = 'ie',
  Edge = 'edge',
  Chrome = 'chrome',
  Firefox = 'firefox',
  Safari = 'safari',
  EdgeChromium = 'edge-chromium',
  IOS = 'ios', // e.g. iPhone Safari
  AndroidChrome = 'chrome-android',
}

export const supportedBrowsersVersions: { [key in Browser]?: number } = {
  [Browser.Chrome]: 86,
  [Browser.Firefox]: 82,
  [Browser.Safari]: 14,
  [Browser.IOS]: 14,
  [Browser.EdgeChromium]: 86,
  [Browser.AndroidChrome]: 96,
};

export const browsersDownloadUrls: { [key in Browser]?: string } = {
  [Browser.Chrome]: 'https://www.google.com/intl/en/chrome',
  [Browser.Firefox]: 'https://www.mozilla.org/en-US/firefox/new',
  [Browser.Safari]: 'https://support.apple.com/en-us/HT204416',
  [Browser.EdgeChromium]: 'https://www.microsoft.com/en-us/edge',
  [Browser.AndroidChrome]: 'https://www.google.com/intl/en/chrome',
};

export const browsersDevicesPermissionsUrls: { [key in Browser]?: string } = {
  [Browser.Chrome]: 'https://support.google.com/chrome/answer/2693767',
  [Browser.Firefox]:
    'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions',
  [Browser.Safari]: 'https://support.apple.com/en-gb/guide/safari/ibrwe2159f50/mac',
  [Browser.EdgeChromium]:
    'https://www.windowscentral.com/how-manage-site-permissions-new-microsoft-edge',
  [Browser.AndroidChrome]: 'https://support.google.com/chrome/answer/2693767',
};

export const systemPermissionHelpUrls = {
  MacMicrophone: `https://support.apple.com/en-gb/guide/mac-help/mchla1b1e1fe/14.0/mac/14.0`,
  MacWebCam: `https://support.apple.com/en-gb/guide/mac-help/mchlf6d108da/14.0/mac/14.0`,
  WindowsMicrophone: `https://support.microsoft.com/en-us/windows/turn-on-app-permissions-for-your-microphone-in-windows-10-94991183-f69d-b4cf-4679-c98ca45f577a`,
  WindowsWebCam: `https://support.microsoft.com/en-us/windows/manage-app-permissions-for-your-camera-in-windows-87ebc757-1f87-7bbf-84b5-0686afb6ca6b`,
};
