import React from 'react';
import { rem, size } from 'polished';
import styled from 'styled-components';

import DotsGroup from './DotsGroup';

export const DOTS_BUTTON_WIDTH = '35px';

const Button = styled.button`
  ${size(DOTS_BUTTON_WIDTH)};
  padding: ${rem(5)} 0 ${rem(6)};
  vertical-align: top;
  outline: none;
  border: 1px solid transparent;
  transition: border-color 0.3s ease;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.bermudaGray};
  }
`;

interface Props {
  onClick: () => void;
}

const DotsButton = ({ onClick }: Props) => (
  <Button onClick={onClick} type="button" aria-haspopup="menu" data-test="dots-button">
    <DotsGroup />
  </Button>
);

export default DotsButton;
