import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

export interface ClientAvatarPostOptions {
  file: File;
}

export function clientAvatarApi(path: string) {
  return {
    path,
    POST: (data: ClientAvatarPostOptions) =>
      api.post<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(data),
          },
        })
      ),
    DELETE: () => api.delete<JsonApiResponseSingle>(path),
  };
}
