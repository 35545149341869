import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

const apiPath = '/api/v1/oauth_accounts';

export interface AccountPatchOptions {
  account_id?: string;
  id: number;
}

function multipleOauthAccountApi(path: string) {
  return {
    path,
    GET: (oauthAccountId: number) =>
      api.get<JsonApiResponse>(`${path}/${oauthAccountId}/account_list`),
    PATCH: (oauthAccountId: number, params: AccountPatchOptions) =>
      api.patch<JsonApiResponseSingle>(`${path}/${oauthAccountId}`, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}

function multipleOauthAccount() {
  return multipleOauthAccountApi(apiPath);
}

export { multipleOauthAccount };
