import { clientMetaAdviceRoomsApi } from './adviceRooms';
import { clientMetaCasesRecommendationsApi } from './casesRecommendations';
import { clientMetaClientIdentificationsApi } from './clientIdentifications';
import { clientMetaDocumentsPacksApi } from './documentsPacks';
import { clientMetaDocumentVaultItemsApi } from './documentVaultItems';
import { clientMetaFinancialStrategiesApi } from './financialStrategies';
import { clientMetaMotivationCentreApi } from './motivationCentre';
import { clientMetaReviewQuestionnairesApi } from './reviewQuestionnaires';
import { ClientMetaUnreadFinancialStrategiesApi } from './unreadFinancialStrategies';
import { clientMetaUnreadMessagesApi } from './unreadMessages';

export function clientMetaApi(path: string) {
  return {
    path,
    adviceRooms: () => clientMetaAdviceRoomsApi(`${path}/client-meeting-rooms`),
    casesRecommendations: () => clientMetaCasesRecommendationsApi(`${path}/cases-recommendations`),
    clientIdentifications: () =>
      clientMetaClientIdentificationsApi(`${path}/client-identifications`),
    documentsPacks: () => clientMetaDocumentsPacksApi(`${path}/documents-packs`),
    documentVaultItems: () => clientMetaDocumentVaultItemsApi(`${path}/document-vault-items`),
    reviewQuestionnaires: () => clientMetaReviewQuestionnairesApi(`${path}/review-questionnaires`),
    financialStrategies: () => clientMetaFinancialStrategiesApi(`${path}/financial-strategies`),
    motivationCentre: () => clientMetaMotivationCentreApi(`${path}/motivation-centre`),
    unreadFinancialStrategies: () =>
      ClientMetaUnreadFinancialStrategiesApi(`${path}/unread-financial-strategies`),
    unreadMessages: () => clientMetaUnreadMessagesApi(`${path}/unread-messages`),
  };
}
