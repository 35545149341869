import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';

export interface UsersSignInPostOptions {
  email: string;
  password: string;
  otp?: string;
  rememberMe?: boolean;
}

export function usersSignInApi(path: string) {
  return {
    path,
    POST: ({ email, password, otp, rememberMe }: UsersSignInPostOptions) =>
      api.post<JsonApiResponseSingle>(path, {
        user: {
          email,
          password,
          otp_code_token: otp,
          remember_me: rememberMe,
        },
      }),
  };
}
