/* eslint-disable max-classes-per-file */
export type DeviceChangedEvent = CustomEvent<{
  newDeviceId: string | undefined;
}>;

export function dispatchAudioOutputDeviceChangedEvent(newDeviceId: string | undefined) {
  const event = new CustomEvent('audiooutputdevicechanged', { detail: { newDeviceId } });
  window.dispatchEvent(event);
}

export function dispatchAudioDeviceChangedEvent(newDeviceId: string | undefined) {
  const event = new CustomEvent('audiodevicechanged', { detail: { newDeviceId } });
  window.dispatchEvent(event);
}

export function dispatchVideoDeviceChangedEvent(newDeviceId: string | undefined) {
  const event = new CustomEvent('videodevicechanged', { detail: { newDeviceId } });
  window.dispatchEvent(event);
}
