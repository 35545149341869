import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';
import { objectToURLParams } from 'helpers/utils';

export interface HubSpotSignInGetOptions {
  redirectUrl?: string;
}

export function hubSpotSignInApi(path: string) {
  const generatePath = (params?: HubSpotSignInGetOptions) =>
    params ? `${path}?${objectToURLParams(mapFormData(params))}` : path;

  return {
    path,
    GET: (params?: HubSpotSignInGetOptions) => api.get(generatePath(params)),
    GETPath: (params?: HubSpotSignInGetOptions) => generatePath(params),
  };
}
