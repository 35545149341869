import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, PaginationOptions, RequestConfig } from 'api/types';
import { mapFormData } from 'helpers/formData';

export type ClientClientTagsGetOptions = GetOptions & PaginationOptions;

export interface ClientClientTagsPostOptions {
  clientTagIds?: number[];
}

export interface ClientClientTagDeleteOptions {
  clientTagId?: number;
}

export function clientClientTagsApi(path: string) {
  return {
    path,
    GET: (params: ClientClientTagsGetOptions = {}, config?: RequestConfig) => {
      const { pageSize, pageNumber, ...rest } = params;

      return api.get<JsonApiResponse>(path, {
        params: mapFormData({
          per_page: pageSize,
          page: pageNumber,
          ...rest,
        }),
        ...config,
      });
    },
    POST: (params: ClientClientTagsPostOptions) =>
      api.post<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
    DELETE: (params: ClientClientTagDeleteOptions) =>
      api.delete<JsonApiResponseSingle>(path, {
        data: { data: { attributes: mapFormData(params) } },
      }),
  };
}
