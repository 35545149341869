import { MotivationCentreItemType } from 'types/entities/motivationCentreItem';

export default {
  shared: {
    commentsCountSingular: `{count} comment`,
    commentsCountPlural: `{count} comments`,
    writeComment: `Write a comment`,
    newComments: `{count} new comment(s)`,
    new: `new`,
  },
  itemTypes: {
    [MotivationCentreItemType.Link]: `Link`,
    [MotivationCentreItemType.Pdf]: `Pdf`,
    [MotivationCentreItemType.ImageGallery]: `Image or gallery`,
  },
  link: {
    openLink: `Open link`,
    settings: {
      title: `Link settings`,
      subtitle: `Here you can post a link that relates to your
        financial and life objectives.`,
      linkToWebsite: `Link to website`,
      linkTitle: `Title of link`,
    },
  },
  pdf: {
    settings: {
      title: `Document settings`,
      linkTitle: `Title`,
    },
    document: {
      title: `Document`,
    },
  },
  imageGallery: {
    settings: {
      title: `General settings`,
    },
    image: {
      upload: `Upload main image`,
    },
    gallery: {
      title: `Gallery`,
      add: `Add image`,
    },
  },
  removeModal: {
    title: `Delete`,
    defaultDescription: `Are you sure you want to delete this motivation resource?
      This cannot be undone.`,
    yes: `Yes, delete`,
    no: `No, don’t delete`,
    success: `Motivation Center item has been successfully cancelled`,
  },
};
