import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions } from 'api/types';

import { guestsAdviceRoomsDocumentsApi } from './documents';
import { guestsAdviceRoomsDocumentApi } from './documents/document';
import { guestClientMeetingRoomsDocumentSetsApi } from './documentSets';
import { guestClientMeetingRoomsPossibleParticipantsApi } from './possibleParticipants';

export interface GuestClientMeetingRoomsGetOptions extends GetOptions {
  authToken: string;
}

export function guestClientMeetingRoomsApi(path: string) {
  type GuestsAdviceRoomsDocumentApi = ReturnType<typeof guestsAdviceRoomsDocumentApi>;
  type GuestsAdviceRoomsDocumentsApi = ReturnType<typeof guestsAdviceRoomsDocumentsApi>;

  function documents(): GuestsAdviceRoomsDocumentsApi;
  function documents(documentId: number): GuestsAdviceRoomsDocumentApi;
  function documents(
    documentId?: number
  ): GuestsAdviceRoomsDocumentsApi | GuestsAdviceRoomsDocumentApi {
    const apiPath = `${path}/documents`;

    return documentId
      ? guestsAdviceRoomsDocumentApi(`${apiPath}/${documentId}`)
      : guestsAdviceRoomsDocumentsApi(apiPath);
  }

  return {
    path,
    documents,
    documentSets: () => guestClientMeetingRoomsDocumentSetsApi(`${path}/document-sets`),
    currentRecordingState: () => ({
      GET: ({ authToken }: { authToken: string }) =>
        api.get<JsonApiResponseSingle>(`${path}/current-recording-state`, {
          params: {
            '[data][attributes][auth_token]': authToken,
          },
        }),
    }),
    possibleParticipants: () =>
      guestClientMeetingRoomsPossibleParticipantsApi(`${path}/possible-participants`),
    GET: ({ authToken, include }: GuestClientMeetingRoomsGetOptions) =>
      api.get<JsonApiResponseSingle>(path, {
        params: {
          '[data][attributes][auth_token]': authToken,
          include,
        },
      }),
  };
}
