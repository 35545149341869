import React from 'react';
import { FormattedMessage } from 'react-intl';
import { lighten, rem } from 'polished';
import styled from 'styled-components';

interface HintProps {
  hasMarginLeftSm?: boolean;
}

const Hint = styled.span<HintProps>`
  max-height: ${rem(22)};
  margin-left: ${({ hasMarginLeftSm }) => rem(hasMarginLeftSm ? 10 : 2)};
  padding: 1px ${rem(4)} 1px ${rem(3)};
  font-size: ${({ theme }) => theme.fontSizes.dbSmall};
  background-color: ${({ theme }) => lighten(0.4, theme.colors.bermudaGray)};
  color: ${({ theme }) => theme.colors.textLight};
`;

interface Props extends HintProps {
  text: string;
}

const GenericHint = ({ text, hasMarginLeftSm = false }: Props) => (
  <Hint hasMarginLeftSm={hasMarginLeftSm}>
    <FormattedMessage id={text} />
  </Hint>
);

export default GenericHint;
