import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';

export function clientsExportListApi(path: string) {
  return {
    path,
    GET: () => api.get<JsonApiResponse>(path),
  };
}
