import { CaseType } from 'types/entities/clientCase';

export default {
  nav: {
    [CaseType.OnlineAdviceRequest]: `Online Advice request: {caseName}`,
    [CaseType.RecommendationPackCase]: `Recommendation Pack: {caseName}`,
    [CaseType.MeetingRequestCase]: `Face-to-face advice: {caseName}`,
    [CaseType.ReviewQuestionnaireCase]: `{caseName}`,
    [CaseType.DocumentPackCase]: `Document Pack: {caseName}`,
    dashboard: `Dashboard`,
    adviser: `My Adviser`,
    accountSettings: `Account settings`,
    manageAdditionalAdvisers: `Manage Additional Advisers`,
    accountAuditTrail: `Account audit trail`,
    clients: `Clients`,
    advisers: `Advisers`,
    practiceAdmins: `Practice Admins`,
    documents: `Case Documents`,
    auditTrail: `Audit trail`,
    factFind: `Fact Find`,
    financialAudit: `Personal Financial Audit`,
    taxHealthCheckAudit: `Tax Health Check`,
    financialPlanningHealthCheck: `Personal Financial Planning Health Check`,
    financialPlanningGoals: `Financial Planning Goals`,
    riskAssessment: `Risk tolerance assessment`,
    newDocumentPack: `Create Document Pack`,
    newRecommendationPack: `Create Recommendation Pack`,
    newReviewQuestionnaire: `Create Questionnaire`,
    myFinancialStrategy: `My Financial Strategy`,
    financialStrategy: `Financial Strategy: {title}`,
    newFinancialStrategy: `Create Financial Strategy`,
    adviceRooms: `Client Meeting Rooms`,
    adviceRoom: `Client Meeting Room: {roomName}`,
    newAdviceRoom: `Schedule new Client Meeting Room`,
    newInvitation: `New invitation`,
    newClientAccount: `Create client`,
    newClientAccounts: `Bulk create new clients`,
    newAdviserAccount: `Create Adviser account`,
    editAdviserProfile: `Edit adviser profile`,
    editPracticeAdminProfile: `Edit practice admin`,
    practiceStatistics: `DWS usage`,
    motivationCentre: `Motivation Centre`,
    identityVerification: `Identity Check`,
    newIdentityVerification: `Create Identity Check`,
    support: `Support`,
    summaryInteractionReport: `Summary Interaction Reports`,
    topUp: `Top-up: {value}`,
  },
};
