import React from 'react';

import { useStyledTheme } from 'hooks/useStyledTheme';

import SvgIcon from './SvgIcon';

const IconLetter = (props: React.SVGAttributes<SVGSVGElement>) => {
  const theme = useStyledTheme();

  return (
    <SvgIcon viewBox="0 0 48 48" {...props}>
      <g strokeWidth="2" fill="none">
        <g stroke={theme.colors.fountainBlue}>
          <path d="M8.67 16.33L1 20.78V47h46V20.78l-7.67-4.45" />
          <path d="M8.67 25.07V1h30.66v23.92M1 20.78L47 47m0-26.22L23.39 33.51" />
        </g>
        <path
          d="M16.33 10.2h15.34m-15.34 7.67h15.34"
          stroke={theme.colors.accent}
          strokeLinecap="square"
        />
      </g>
    </SvgIcon>
  );
};

export default IconLetter;
