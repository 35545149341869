import React from 'react';
import { FormattedMessage } from 'react-intl';
import { floor, min } from 'lodash';

import { misc } from 'helpers/styles/constants';
import { BasicTooltip } from 'components/GenericTooltip';
import IconZoomFit from 'components/Icons/IconZoomFit';
import IconZoomIn from 'components/Icons/IconZoomIn';
import IconZoomOut from 'components/Icons/IconZoomOut';
import { Desktop } from 'components/Responsive/Responsive';

import PdfCircleButton from './components/PdfCircleButton';
import { IconStyled } from './components/styled';
import ToolbarSection from './ToolbarSection';

const MIN_ZOOM = 20;
const MAX_ZOOM = 300;
const ZOOM_STEP = 20;

export interface Props {
  onChange: (zoom: number) => void;
  zoom: number;
  isDisabled?: boolean;
  wrapperRef: HTMLDivElement | null;
  documentRef: HTMLDivElement | null;
}

const ZoomUtils = ({ onChange, zoom, isDisabled, wrapperRef, documentRef }: Props) => {
  const isZoomInDisabled = (zoomValue: number) => zoomValue >= MAX_ZOOM;
  const isZoomOutDisabled = (zoomValue: number) => zoomValue <= MIN_ZOOM;

  const zoomIn = () => {
    if (isZoomInDisabled(zoom)) {
      return;
    }

    onChange(zoom - (zoom % ZOOM_STEP) + ZOOM_STEP);
  };

  const zoomOut = () => {
    if (isZoomOutDisabled(zoom)) {
      return;
    }

    onChange(zoom - (zoom % ZOOM_STEP || ZOOM_STEP));
  };

  const autoFit = () => {
    if (!wrapperRef || !documentRef) {
      return;
    }

    const calculationBase = min([
      wrapperRef.clientHeight / documentRef.clientHeight,
      wrapperRef.clientWidth / documentRef.clientWidth,
    ]);

    if (calculationBase) {
      onChange(floor(calculationBase * zoom));
    }
  };

  return (
    <ToolbarSection
      topRow={<>{zoom}%</>}
      bottomRow={
        <>
          <BasicTooltip
            zIndex={misc.modalZIndex + 1}
            tooltipMessage={<FormattedMessage id="documentViewer.tooltips.zoomin" />}
          >
            <PdfCircleButton onClick={zoomIn} disabled={isZoomInDisabled(zoom) || isDisabled}>
              <IconStyled iconSize={22} as={IconZoomIn} />
            </PdfCircleButton>
          </BasicTooltip>
          <BasicTooltip
            zIndex={misc.modalZIndex + 1}
            tooltipMessage={<FormattedMessage id="documentViewer.tooltips.zoomout" />}
          >
            <PdfCircleButton onClick={zoomOut} disabled={isZoomOutDisabled(zoom) || isDisabled}>
              <IconStyled iconSize={22} as={IconZoomOut} />
            </PdfCircleButton>
          </BasicTooltip>
          <Desktop>
            <BasicTooltip
              zIndex={misc.modalZIndex + 1}
              tooltipMessage={<FormattedMessage id="documentViewer.tooltips.reset" />}
            >
              <PdfCircleButton onClick={autoFit} disabled={isDisabled}>
                <IconStyled iconSize={22} as={IconZoomFit} />
              </PdfCircleButton>
            </BasicTooltip>
          </Desktop>
        </>
      }
    />
  );
};

export default ZoomUtils;
