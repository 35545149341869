import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, RequestConfig } from 'api/types';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';
import type { BlogPostState } from 'types/entities/blogPost';

import { blogPostEmailClientsApi } from './emailClients';

export type BlogPostGetOptions = GetOptions;

export interface BlogPostPatchOptions {
  title?: string;
  creatorId?: number;
  content?: string;
  state?: BlogPostState;
  image?: File;
  file?: File | '';
  existingImageId?: number;
}

export function blogPostApi(path: string) {
  return {
    path,
    emailClients: () => blogPostEmailClientsApi(`${path}/email-clients`),
    GET: (params?: BlogPostGetOptions, config?: RequestConfig) =>
      api.get<JsonApiResponseSingle>(path, { params, ...config }),
    PATCH: (params: BlogPostPatchOptions) =>
      api.patch<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      ),
    DELETE: () => api.delete(path),
  };
}
