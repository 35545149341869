import React, { useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { rem } from 'polished';

import FormActionButtons from 'components/FormActionButtons/FormActionButtons';
import BasicModal from 'components/GenericModal/BasicModal';
import GenericText from 'components/GenericText/GenericText';
import { GenericTitle } from 'components/GenericTitle/GenericTitle';
import useModalState from 'components/ModalHandler/useModalState';

import { RoomConnectionState } from './RoomConnectionState';
import { useVideoContext } from './VideoContext';

export function VideoChatWatcher() {
  const intl = useIntl();
  const { connectionState } = useVideoContext();
  const hasJoinedRoom = connectionState === RoomConnectionState.Connected;

  const [isModalOpen, openModal, closeModal] = useModalState();

  const getMessage = useCallback(
    () => intl.formatMessage({ id: 'adviceRooms.reloadModal.body' }),
    [intl]
  );

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (!hasJoinedRoom) {
        return;
      }

      e.returnValue = getMessage();
      return getMessage();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasJoinedRoom, getMessage, openModal]);

  return (
    <BasicModal
      width={rem(690)}
      isOpen={isModalOpen}
      shouldCloseOnOverlayClick={false}
      isCloseButtonHidden
    >
      <BasicModal.Title>
        <GenericTitle>
          <FormattedMessage id="adviceRooms.reloadModal.title" />
        </GenericTitle>
      </BasicModal.Title>

      <BasicModal.Content>
        <GenericText>
          <FormattedMessage id="adviceRooms.reloadModal.body" />
        </GenericText>
      </BasicModal.Content>

      <BasicModal.Footer>
        <FormActionButtons
          submitBtnContent={<FormattedMessage id="shared.ok" />}
          onSubmitBtnClick={closeModal}
          isLabelHidden
        />
      </BasicModal.Footer>
    </BasicModal>
  );
}
