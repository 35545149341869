import React from 'react';
import { FormattedDate as FormattedDateBase, useIntl } from 'react-intl';

import { getHourCycle } from 'helpers/dates';

type Props = React.ComponentProps<typeof FormattedDateBase>;

export function FormattedDate(props: Props) {
  const intl = useIntl();

  return <FormattedDateBase hourCycle={getHourCycle(intl)} {...props} />;
}
