import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, RequestConfig } from 'api/types';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';
import type { DocumentVaultDocumentType } from 'types/entities/documentVaultItem';

import { clientDocumentVaultItemMarkAsSeenApi } from './markAsSeen';

export type DocumentVaultItemGetOptions = GetOptions;

export interface DocumentVaultItemsPatchOptions {
  file?: File;
  title?: string;
  documentType?: DocumentVaultDocumentType;
  sharedWithAdviser?: boolean;
  image?: File;
  existingImageId?: number;
  parentFolderId?: number;
}

export function clientDocumentVaultItemApi(path: string) {
  return {
    path,
    markAsSeen: () => clientDocumentVaultItemMarkAsSeenApi(`${path}/mark-as-seen`),
    GET: (params?: DocumentVaultItemGetOptions, config?: RequestConfig) =>
      api.get<JsonApiResponseSingle>(path, { params, ...config }),
    PATCH: (params: DocumentVaultItemsPatchOptions) =>
      api.patch<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      ),
    DELETE: () => api.delete(path),
  };
}
