import api from 'api/apiInstance';

export interface ClientCaseRelationsUpdateGetResponse {
  risk_assessment_updated_at: string;
  financial_planning_goals_updated_at: string;
  fact_find_updated_at: string;
  financial_audit_updated_at: string;
  financial_planning_health_check_updated_at: string;
}

export function clientCaseRelationsUpdateApi(path: string) {
  return {
    path,
    GET: () => api.get<ClientCaseRelationsUpdateGetResponse>(path),
  };
}
