import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

export interface ClientClientMessagesMarkAsSeenApiOptions {
  messagesIds: number[];
}

export function clientClientMessagesMarkAsSeenApi(path: string) {
  return {
    path,
    PATCH: (params: ClientClientMessagesMarkAsSeenApiOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
