import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { PaginationOptions, RequestConfig } from 'api/types';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

import { agendaTemplateApi } from './agendaTemplate';

export type AgendaTemplatesGetOptions = PaginationOptions;
export interface AgendaTemplatesPostOptions {
  title?: string;
  practiceId: number;
  template?: string;
  default?: boolean;
  agendaType: string;
  active: boolean;
}

const apiPath = '/api/v1/agenda-templates';

function agendaTemplatesApi(path: string) {
  return {
    path,
    GET: (params: AgendaTemplatesGetOptions = {}, config?: RequestConfig) => {
      const { pageSize, pageNumber, ...rest } = params;

      return api.get<JsonApiResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...rest,
        },
        ...config,
      });
    },
    POST: (params: AgendaTemplatesPostOptions) =>
      api.post<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      ),
  };
}

type AgendaTemplateApi = ReturnType<typeof agendaTemplateApi>;
type AgendaTemplatesApi = ReturnType<typeof agendaTemplatesApi>;

function agendaTemplates(): AgendaTemplatesApi;
function agendaTemplates(agendaId: number): AgendaTemplateApi;
function agendaTemplates(agendaId?: number): AgendaTemplatesApi | AgendaTemplateApi {
  return agendaId ? agendaTemplateApi(`${apiPath}/${agendaId}`) : agendaTemplatesApi(apiPath);
}

export { agendaTemplates };
