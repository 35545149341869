import { useRwdQuery } from 'helpers/styles/useRwdQuery';

interface Props {
  children: React.ReactElement;
}

export const Desktop = ({ children }: Props) => {
  const { isDesktop } = useRwdQuery();

  return isDesktop && children ? children : null;
};

export const Mobile = ({ children }: Props) => {
  const { isMobile } = useRwdQuery();

  return isMobile && children ? children : null;
};
