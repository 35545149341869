import { hubSpotSignInApi } from './signIn';

const apiPath = '/api/v1/hubspot';

export function hubSpotApi(path: string) {
  return {
    path,
    signIn: () => hubSpotSignInApi(`${path}/sign-in`),
  };
}

export function hubSpot() {
  return hubSpotApi(apiPath);
}
