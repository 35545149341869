import { rem } from 'polished';
import styled from 'styled-components';

interface Props {
  isInlineBlock?: boolean;
  isWraping?: boolean;
  isTextLarge?: boolean;
  isTextMedium?: boolean;
  isTextSmall?: boolean;
  hasMarginTopLg?: boolean;
  hasMarginTopMd?: boolean;
  hasMarginTopSm?: boolean;
  hasMarginBottomLg?: boolean;
  hasMarginBottomMd?: boolean;
  hasMarginBottomSm?: boolean;
  hasMarginLeftMd?: boolean;
  hasPaddingRightSm?: boolean;
  hasMaxWidth?: boolean;
  isBold?: boolean;
}

const GenericText = styled.p<Props>`
  margin: 0;
  line-height: 1.33;
  color: ${({ theme }) => theme.colors.textLight};
  ${({ isBold }) => isBold && `font-weight: 500`};

  ${({ isInlineBlock }) => isInlineBlock && 'display: inline-block'};
  ${({ isWraping }) => isWraping && 'word-break: break-all'};

  ${({ isTextLarge, theme }) => isTextLarge && `font-size: ${theme.fontSizes.dbMedium}`};
  ${({ isTextMedium, theme }) => isTextMedium && `font-size: ${theme.fontSizes.dbNormalSm}`};
  ${({ isTextSmall, theme }) => isTextSmall && `font-size: ${theme.fontSizes.dbSmallMd}`};

  ${({ hasMarginTopLg }) => hasMarginTopLg && `margin-top: ${rem(40)}`};
  ${({ hasMarginTopMd }) => hasMarginTopMd && `margin-top: ${rem(20)}`};
  ${({ hasMarginTopSm }) => hasMarginTopSm && `margin-top: ${rem(5)}`};

  ${({ hasMarginBottomLg }) => hasMarginBottomLg && `margin-bottom: ${rem(40)}`};
  ${({ hasMarginBottomMd }) => hasMarginBottomMd && `margin-bottom: ${rem(20)}`};
  ${({ hasMarginBottomSm }) => hasMarginBottomSm && `margin-bottom: ${rem(10)}`};

  ${({ hasMarginLeftMd }) => hasMarginLeftMd && `margin-left: ${rem(10)}`};

  ${({ hasPaddingRightSm }) => hasPaddingRightSm && `padding-right: ${rem(10)}`};

  ${({ hasMaxWidth }) => hasMaxWidth && `max-width: ${rem(800)}`};

  ul,
  ol {
    margin: ${rem(16)} 0;
    padding: 0 0 0 ${rem(40)};
    list-style-type: initial;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export default GenericText;
