import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, PaginationOptions, RequestConfig } from 'api/types';
import { mapFormData } from 'helpers/formData';

export type ClientSummaryInteractionReportsGetOptions = GetOptions & PaginationOptions;

export interface ClientSummaryInteractionReportsPostOptions {
  startDate: string;
  endDate: string;
}

export function clientSummaryInteractionReportsApi(path: string) {
  return {
    path,
    GET: (
      { pageSize, pageNumber, ...rest }: ClientSummaryInteractionReportsGetOptions,
      config?: RequestConfig
    ) =>
      api.get<JsonApiResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...rest,
        },
        ...config,
      }),
    POST: (params: ClientSummaryInteractionReportsPostOptions) =>
      api.post(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
