import React from 'react';
import { rem, size } from 'polished';
import styled from 'styled-components';

import IconQuestionMark from 'components/Icons/IconQuestionMark';

import BasicTooltip, { Props as BasicTooltipProps } from './BasicTooltip';
import TooltipPlacement from './TooltipPlacement';

interface IconWrapperProps {
  isSmall?: boolean;
  hasAccentTheme?: boolean;
}

const IconWrapper = styled.span<IconWrapperProps>`
  display: inline-block;

  svg {
    ${({ isSmall }) => size(rem(isSmall ? 13 : 18))};
    fill: ${({ hasAccentTheme, theme }) =>
      hasAccentTheme ? theme.colors.accent : theme.colors.bermudaGray};
    transition: fill 0.3s ease;
  }
`;

interface WrapperProps {
  hasNoMarginLeft?: boolean;
  hasNoMarginRight?: boolean;
  hasNoMargin?: boolean;
  hasMarginTop?: boolean;
  hasBigBox?: boolean;
  isSmall?: boolean;
}

const TooltipWrapper = styled.span<WrapperProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-right: ${({ isSmall }) => rem(isSmall ? 4 : 8)};
  margin-left: ${({ isSmall }) => rem(isSmall ? 4 : 8)};
  letter-spacing: 0;
  text-transform: none;
  vertical-align: middle;
  ${size('100%', 'auto')};
  line-height: 1;
  font-size: ${({ theme }) => theme.fontSizes.smallMd};

  ${({ hasNoMarginLeft }) => hasNoMarginLeft && 'margin-left: 0'};
  ${({ hasNoMarginRight }) => hasNoMarginRight && 'margin-right: 0'};
  ${({ hasNoMargin }) => hasNoMargin && 'margin: 0'};
  ${({ hasMarginTop }) => hasMarginTop && 'margin-top: 2px'};
  ${({ hasBigBox }) => hasBigBox && 'vertical-align: baseline'};
`;

interface Props {
  text: BasicTooltipProps['tooltipMessage'];
  position?: TooltipPlacement;
  isSmall?: boolean;
  hasBigBox?: boolean;
  hasAccentTheme?: boolean;
  hasNoMarginLeft?: boolean;
  hasNoMarginRight?: boolean;
  hasNoMargin?: boolean;
  hasMarginTop?: boolean;
  icon?: React.ReactNode;
}

export function GenericTooltip({
  text,
  icon,
  position = TooltipPlacement.Top,
  isSmall = false,
  hasBigBox = false,
  hasAccentTheme = false,
  hasNoMarginLeft = false,
  hasNoMarginRight = false,
  hasNoMargin = false,
  hasMarginTop = false,
}: Props) {
  return (
    <TooltipWrapper
      data-test="tooltip"
      hasBigBox={hasBigBox}
      hasNoMarginLeft={hasNoMarginLeft}
      hasNoMarginRight={hasNoMarginRight}
      hasNoMargin={hasNoMargin}
      hasMarginTop={hasMarginTop}
      isSmall={isSmall}
    >
      <BasicTooltip
        tooltipMessage={text}
        hasBigBox={hasBigBox}
        hasAccentTheme={hasAccentTheme}
        position={position}
        trigger="mouseenter click"
      >
        <IconWrapper data-test="tooltip-icon" hasAccentTheme={hasAccentTheme} isSmall={isSmall}>
          {icon || <IconQuestionMark />}
        </IconWrapper>
      </BasicTooltip>
    </TooltipWrapper>
  );
}
