import { Root, route } from '../utils';

const servicesAndChargesPath = `${Root}services-and-charges`;

type ServicesAndChargesItemParams = [itemId: number];

export default {
  servicesAndCharges: {
    ...route(servicesAndChargesPath),
    item: route<ServicesAndChargesItemParams>(`${servicesAndChargesPath}/:itemId`),
  },
};
