import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import { useGetCurrentUserId } from 'store/users/selectors';

export function MatomoTracking() {
  const { trackPageView, pushInstruction } = useMatomo();

  const currentUserId = useGetCurrentUserId();

  const trackPageViewHandler = useRef(trackPageView);
  const pushInstructionHandler = useRef(pushInstruction);

  useEffect(() => {
    trackPageViewHandler.current = trackPageView;
  }, [trackPageView]);

  useEffect(() => {
    pushInstructionHandler.current = pushInstruction;
  }, [pushInstruction]);

  const location = useLocation();

  useEffect(() => {
    if (currentUserId) {
      pushInstructionHandler.current('setUserId', currentUserId);
    } else {
      pushInstructionHandler.current('resetUserId');
    }
  }, [currentUserId]);

  useEffect(() => {
    pushInstructionHandler.current('requireCookieConsent');
    trackPageViewHandler.current({});
  }, [location.pathname]);

  return null;
}
