export enum AccountType {
  RetirementAccount = 'retirementAccount',
  IndividualSavingsAccount = 'individualSavingsAccount',
  UnitTrust = 'unitTrust',
  InvestmentBond = 'investmentBond',
  TrusteeInvestmentAccount = 'trusteeInvestmentAccount',
  InternationalInvestmentBond = 'internationalInvestmentBond',
  InternationalRegularInvestmentBond = 'internationalRegularInvestmentBon',
  NotSure = 'notSure',
}
