import { clientCaseRiskAssessmentsLastApi } from './last';
import { clientCaseRiskAssessmentsPrintApi } from './print';

export function clientCaseRiskAssessmentsApi(path: string) {
  return {
    path,
    last: () => clientCaseRiskAssessmentsLastApi(`${path}/last`),
    print: () => clientCaseRiskAssessmentsPrintApi(`${path}/print`),
  };
}
