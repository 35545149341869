import { rem, rgba, size } from 'polished';
import styled from 'styled-components';

const ParticipantBase = styled.div<{
  width: number;
  height: number;
}>`
  ${({ width, height }) => size(rem(height), rem(width))};
  overflow: hidden;
  border-radius: ${rem(3)};
  box-shadow: ${rem(3)} ${rem(3)} ${rem(15)} ${rgba(0, 0, 0, 0.5)};
`;

export { ParticipantBase };
