import { useSelector } from 'react-redux';
import { first, get, mapValues } from 'lodash';
import build from 'redux-object';
import { createSelector } from 'reselect';

import EntityPath from 'constants/entitiesPaths';
import financialPlanningTopics from 'constants/financialPlanningTopics';
import type { DisabledPages } from 'constants/landingPages';
import { convertToBool } from 'helpers/formData';
import { defaultTheme, mergeThemeWithSettings } from 'helpers/styles/themes';
import { emptyArray } from 'helpers/utils';
import type { FinancialPlanning } from 'types/entities/financialPlanning';
import type { PracticeSetting } from 'types/entities/practiceSetting';

import { getEntities } from '../entities/selectors';
import type { AppState } from '../types';

export const getPracticeSettings = createSelector(
  (state: AppState) => getEntities(state).practiceSettings,
  practiceSettings =>
    first(
      build<PracticeSetting>({ practiceSettings }, EntityPath.PracticeSettings)
    ) as PracticeSetting // settings are provided by BE at start of the app, cannot be undefined
);

export const useGetPracticeSettings = () => useSelector(getPracticeSettings);

export const getFinancialPlannings = (state: AppState) =>
  first(build<FinancialPlanning>(getEntities(state), EntityPath.FinancialPlannings));

export const useGetFinancialPlannings = () => useSelector(getFinancialPlannings);

export const useGetAvailableFinancialPlanningTopics = () => {
  const financialPlannings = useGetFinancialPlannings();

  return financialPlannings
    ? financialPlanningTopics.filter(topic => financialPlannings[topic])
    : emptyArray;
};

export const getColourSchemes = createSelector(
  getPracticeSettings,
  practiceSettings => practiceSettings?.colourSchemes || {}
);

export const getMergedColourSchemes = createSelector(getColourSchemes, colourSchemes =>
  mergeThemeWithSettings(defaultTheme, colourSchemes)
);

export const useGetColourSchemes = () => useSelector(getColourSchemes);

export const getOnlineAdviceMinDuration = createSelector(getPracticeSettings, practiceSettings =>
  Number(get(practiceSettings, 'minDurationForOnlineAdvice') || 5)
);

export const useGetOnlineAdviceMinDuration = () => useSelector(getOnlineAdviceMinDuration);

export const getDisabledPages = createSelector(
  getPracticeSettings,
  practiceSettings =>
    mapValues(get(practiceSettings, 'disabledPages'), convertToBool) as DisabledPages
);

export const useGetDisabledPages = () => useSelector(getDisabledPages);
