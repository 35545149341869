import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

import { practiceBrochureReorderApi } from './reorder';

export interface PracticeBrochurePatchOptions {
  title?: string;
  image?: File;
  visibilityPeriodFinishedAt?: string;
  file?: File;
  externalPdfFileUrl?: string;
}

export function practiceBrochureApi(path: string) {
  return {
    path,
    reorder: () => practiceBrochureReorderApi(`${path}/reorder`),
    GET: () => api.get<JsonApiResponseSingle>(path),
    PATCH: (values: PracticeBrochurePatchOptions) =>
      api.patch<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(values),
          },
        })
      ),
    DELETE: () => api.delete<JsonApiResponse>(path),
  };
}
