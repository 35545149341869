import {
  IdentityVerificationAuthorities,
  IdentityVerificationStatuses,
} from 'types/entities/clientIdentityVerifications';

export default {
  detailHeaderWithAuthority: 'Personal information provided by {authority}',
  consent: 'I agree to share the above personal information with {practiceName} via {authority}',
  client: {
    title: `Identity Check`,
    subtitle: `In order to provide advice to you, we may be legally required to periodically verify your identity. We may request that you provide us with important Identity Check information below. In addition to providing us with digital copies of your identification documents, we may also require you to provide our office with physical copies.`,
    tileDescription: `View details of Identity Checks that were facilitated via this online service.`,
    beginVerificationMessageWithAuthoritySingpass:
      'To progress this Identity Check for account onboarding and identity verification purposes, please click on the button below. You will be guided through {article} {authority} controlled authorisation process, and then prompted to grant consent to share personal information with us.',
    beginVerificationMessageWithAuthorityIamSmart:
      'To support your SJP account opening using the {authority} authentication function, you are required to authorise {authority} to provide your personal data to {practiceName}.  Data provided by {authority} and used by us to facilitate the account opening process may include, your:-',
    detailsExplanationWithAuthority:
      'Thank you. The following personal information was provided to us by {authority}. If this information is correct, please submit it. Alternatively, if this information is incorrect, please cancel and contact your adviser to discuss the issue.',
    successSubmitMessage: 'Successfully Submitted!!!',
    nextStep: 'Next Steps:',
    verificationConfirmPopupMessage1: `Your identity is authenticated with information provided by <b>"iAM Smart"</b>.`,
    verificationConfirmPopupMessage2:
      'If you have any questions about <b>SJP</b>’s account opening process, please contact your <b>SJP</b> partner who will be able to address any concerns you may have or alternatively, direct your enquiry appropriately as required.',
    verificationConfirmPopupMessage3: 'Your adviser will review the submitted information.',
    verificationConfirmPopupMessage4:
      'You will receive an email notification about acceptance or rejection of the provided information.',
    verificationConfirmPopupMessage5:
      'As per the provided information, the onboarding process will continue and your adviser will contact you.',
    detail: {
      uid: 'NRIC/FIN',
      name: 'Name',
      nationality: 'Nationality',
      alias: 'Alias Name',
      dob: 'Date of Birth',
      hanyupinyinname: 'Hanyu Pinyin Name',
      countryOfBirth: 'Country of Birth',
      hanyupinyinalias: 'Hanyu Pinyin Alias Name',
      passtype: 'Pass Type',
      marriedName: 'Married Name',
      passStatus: 'Pass Status',
      address: 'Registered Address',
      passExpiryDate: 'Pass Expiry Date',
      sex: 'Sex',
    },
  },
  adviser: {
    createIdentityVerificationButton: `Create Identity Check`,
    form: {
      title: 'Create Identity Check',
      subtitle:
        'Complete the form below to carry out an Identity Check on your client. The client will be requested to formally provide information about their identity using the authority chosen below.',
      authority: 'Authority',
      authorityPlaceholder: `Select an Authority`,
      authorityTip: 'Choose an authority that will be used to facilitate the Identity Check.',
      coverNote: 'Cover Note',
      coverNoteTip:
        'Please provide your client with a suitable explanation as to why their identity must be checked.',
      modalConfirmText: 'Are you sure you want to create this Identity Check?',
    },
    show: {
      title: 'Identity Check: {authority}',
      cancelButton: 'Cancel Identity Check',
    },
    detail: {
      title: 'Details',
      tooltip: 'Here is the client identity information returned by the authority.',
    },
    [IdentityVerificationAuthorities.singpass]: {
      detail: {
        uid: 'NRIC/FIN',
        name: 'Name',
        nationality: 'Nationality',
        alias: 'Alias Name',
        dob: 'Date of Birth',
        hanyupinyinname: 'Hanyu Pinyin Name',
        countryOfBirth: 'Country of Birth',
        hanyupinyinalias: 'Hanyu Pinyin Alias Name',
        passtype: 'Pass Type',
        marriedName: 'Married Name',
        passStatus: 'Pass Status',
        address: 'Registered Address',
        passExpiryDate: 'Pass Expiry Date',
        sex: 'Sex',
      },
    },
    [IdentityVerificationAuthorities.iAMSmart]: {
      detail: {
        uid: 'HKID',
        name: 'English Name',
        chinese_name: 'Chinese Name',
        dob: 'Date of Birth',
        sex: 'Gender',
        address: 'Correspondence Address',
        file: 'eBill',
      },
    },
    completeRequest: {
      buttonName: 'Complete',
      buttonDescription: 'Mark this Identity Check as complete',
      modalTitle: 'Complete Identity Check',
      modalBody: 'Are you sure you want to complete this check?',
      yes: 'Yes, proceed',
      no: 'Cancel',
    },
    cancelRequest: {
      title: 'This Identity Check has been cancelled.',
      description: 'Please see the audit trail for further details.',
    },
    cancelModal: {
      title: 'Cancel Identity Check',
      body: 'Are you sure you want to cancel this Identity Check? This cannot be undone.',
      yes: 'Yes, Cancel',
    },
    cancelledRequest: 'Identity Check cancelled successfully.',
    completedRequest: 'Identity Check completed successfully.',
  },
  verify: {
    submitSuccessMessage: 'Your data has been successfully submitted to the Adviser',
    cancelSuccessMessage: 'The process has been cancelled',
    error: 'Cannot process the verification at the moment! Please try again later',
  },
  deactivatedMessage: {
    singpass: `Identity check by Singpass is no longer active`,
    iamSmart: `Identity check by "iAM Smart" is no longer active`,
  },
  statusType: {
    [IdentityVerificationStatuses.InProgress]: `In progress`,
    [IdentityVerificationStatuses.Submitted]: `Submitted`,
    [IdentityVerificationStatuses.Completed]: `Completed`,
    [IdentityVerificationStatuses.Cancelled]: `Cancelled`,
  },
  authorityType: {
    [IdentityVerificationAuthorities.iAMSmart]: `"iAM Smart"`,
    [IdentityVerificationAuthorities.singpass]: `Singpass`,
  },
  mobileSupportErrorTitle: `Not Supported`,
  mobileSupportError: `We do not support "iAM Smart" authentication on mobile devices. PC is recommended to complete the process.`,
};
