import React from 'react';
import type { PDFAnnotationData } from 'pdfjs-dist/types/src/display/api';
import styled, { css } from 'styled-components';

import { isEnabled, ServerSideFeature } from 'helpers/features';

import useGenericPdfContext from '../useGenericPdfContext';
import { CheckBoxAnnotationWidget } from './widgets/CheckBoxAnnotationWidget';
import { LinkWidget } from './widgets/LinkWidget';
import { RadioButtonAnnotationWidget } from './widgets/RadioButtonAnnotationWidget';
import { TextAnnotationWidget } from './widgets/TextAnnotationWidget';

const Wrapper = styled.div<{ rotation: number }>`
  position: absolute;

  ${({ rotation }) =>
    rotation &&
    css`
      ${(rotation === 90 || rotation === 270) && 'transform-origin: top left'};
      transform: rotate(${rotation}deg)
        translate(${rotation === 270 ? '-100%' : '0'}, ${rotation === 90 ? '-100%' : '0'});
    `}
`;

export interface Props {
  data: PDFAnnotationData;
  width: number;
  height: number;
  left: number;
  top: number;
  rotation: number;
  isReadOnly?: boolean;
}

enum AnnotationType {
  Text = 1,
  Link = 2,
  Widget = 20,
}

enum FieldType {
  Text = 'Tx',
  Button = 'Btn',
  Choice = 'Ch',
}

export default function Annotation({
  data,
  width,
  height,
  left,
  top,
  rotation,
  isReadOnly,
}: Props) {
  const { isEditable } = useGenericPdfContext();
  const { id, fieldType, annotationType, checkBox, radioButton } = data;

  const getAnnotationElement = () => {
    if (annotationType === AnnotationType.Link) {
      return <LinkWidget data={data} />;
    }

    // do not render form widgets if editable pdf feature is disabled
    if (!isEnabled(ServerSideFeature.EditablePdf) || !isEditable) {
      return null;
    }

    if (annotationType === AnnotationType.Widget) {
      switch (fieldType) {
        case FieldType.Text:
          return (
            <TextAnnotationWidget
              data={data}
              width={width}
              height={height}
              isReadOnly={isReadOnly}
            />
          );
        case FieldType.Button:
          if (radioButton) {
            return <RadioButtonAnnotationWidget data={data} isReadOnly={isReadOnly} />;
          }

          if (checkBox) {
            return <CheckBoxAnnotationWidget data={data} isReadOnly={isReadOnly} />;
          }

          // TODO: Add proper button widget implementation
          return null;
        case FieldType.Choice:
          // TODO: Add proper choice widget implementation
          return null;
        default:
          return null;
      }
    }

    return null;
  };

  return (
    <Wrapper
      data-annotation-id={id}
      rotation={rotation}
      style={{
        width: `${width * 100}%`,
        height: `${height * 100}%`,
        left: `${left * 100}%`,
        top: `${top * 100}%`,
      }}
    >
      {getAnnotationElement()}
    </Wrapper>
  );
}
