import type { PropertyType } from 'types/entities/estateSummary';

const propertyTypes: Record<PropertyType, string> = {
  main_residence: `Main residence`,
  other: `Other`,
};

export default {
  title: `Estate Summary`,
  clientTitle: `My Estate Summary`,
  clientDescription: `Record summary details of your estate. Were you to pass, this summary may be helpful for your executors to handle your estate.`,
  auditTrailDescription: `Below are the major events that occurred within this Estate Summary.`,
  bankAccounts: {
    title: `Bank Accounts`,
    description: `Please provide key details about and bank (and building society etc.) accounts that you hold.`,
    fields: {
      bankNameAndAddress: `Bank Name and Address`,
      sortCode: `Sort Code`,
      accountNumber: `Account Number`,
      contact: `Contact`,
    },
  },
  beneficiaries: {
    title: `Beneficiaries`,
    description: `Beneficiaries, or a beneficiary, are the people or entities that you choose to receive your property after you pass away. Below you may record the details of any beneficiaries of your Will.`,
    fields: {
      name: `Name`,
      address: `Address`,
      contact: `Contact Information`,
    },
  },
  explanatoryNote: {
    title: `Explanatory note`,
    description1: `The death of a relative or friend may be a traumatic and stressful time for those left behind. Friends or family members who have been asked to be executors may face particular difficulties if they cannot easily find and access key documents and important information about the deceased’s estate and wishes.`,
    description2: `Without a relatively up-to-date and accessible record of the deceased’s assets, it is very possible that some assets go undetected and that those assets remain unclaimed for the benefit of beneficiaries.`,
    description3: `By regularly reviewing and completing this section, you may assist your relatives, friends and executors in managing your affairs after your passing.`,
    description4: `Upon your passing, we may assist in the administration of your estate by sharing this information with necessary individuals.`,
  },
  familyTree: {
    title: `Family Tree`,
    description: `Here you can record details of your family members and other other important relationships.`,
    fields: {
      name: `Name`,
      contact: `Contact`,
      partner: `Spouse / Partner`,
      parents: `Parents`,
      grandparents: `Grandparents`,
      children: `Children`,
      brothersAndSisters: `Brothers & Sisters`,
      notes: `Key notes / Other connections`,
    },
    noMembers: `No members added`,
  },
  financialAssets: {
    title: `Financial Assets`,
    description: `Please provide key details about any financial assets that you hold. Examples assets might include investment bonds, unit trusts, life assurance plans, stocks and shares, or other investment types.`,
    fields: {
      name: `Asset Name (e.g. Investment Bond, Deposit etc.)`,
      detail: `Asset Detail`,
      number: `Plan Number / Account Number`,
      contact: `Contact`,
    },
  },
  funeralArrangements: {
    title: `Funeral Arrangements`,
    description: `Here you may express your wishes about your funeral. This expression may be very helpful for your loved ones during a time of potential stress and distress.`,
    fields: {
      wishes: `Your wishes`,
    },
  },
  otherInformation: {
    title: `Other Information`,
    description: `Should you wish to record any other information regarding your estate, please do so here.`,
    fields: {
      info: `Other information`,
    },
  },
  lifetimeGifts: {
    title: `Lifetime Gifts`,
    description: `A ‘lifetime gift’ is where you give cash or assets away during your lifetime. Such gifts may reduce the amount of Inheritance Tax due after your death. However, in order for gifts to successfully reduce Inheritance Tax, certain criteria must be met. To assist your estate administrators to appropriately factor in lifetime gifts, please record the details of lifetime gifts below.`,
    fields: {
      date: `Date`,
      amountOrAssetGifted: `Amount or asset gifted`,
      recipient: `Recipient`,
    },
  },
  pensions: {
    title: `Pensions`,
    description: `Please provide key details about any pensions that you hold.`,
    fields: {
      providerNameAndAddress: `Provider Name and Address`,
      planValue: `Plan value`,
      planNumber: `Plan Number`,
      contact: `Contact`,
    },
  },
  personalDetailsAndContacts: {
    title: `Personal Details and Contacts`,
    description: `Please provide key information about yourself and any professional and close contacts.`,
    personalDetails: {
      title: `Personal Details`,
    },
    contacts: {
      title: `Contacts`,
    },
    fields: {
      name: `Name`,
      idNumber: `National ID Number`,
      dateOfBirth: `Date of Birth`,
      financialAdviser: `Financial Adviser`,
      solicitor: `Solicitor`,
      accountant: `Accountant`,
      contactPlaceholder: `Name, Address, and Contact Information`,
    },
  },
  powersOfAttorney: {
    title: `Powers of Attorney`,
    description: `A Power of Attorney is a legal document that allows someone to make decisions for you, or act on your behalf, if you are no longer able to do so or if you no longer wish to make your own decisions. Below you can record details of any Powers of Attorney that you have in place.`,
    fields: {
      date: `Date made`,
      attorneyNameAndAddress: `Attorney Name and Address`,
      registered: `Has it been registered?`,
      individualsToNotify: `Names and addresses of individuals to be notified of any application to register the Power of Attorney`,
    },
  },
  property: {
    title: `Property`,
    description: `Please provide key details about any property that you hold. You should include details of both your main residence and any other property.`,
    fields: {
      address: `Address`,
      type: `Type`,
      value: `Approximate value`,
      ownership: `Ownership (e.g. solely owned or jointly owned)`,
      outstandingLoan: `Outstanding loan`,
    },
    propertyTypes,
  },
  will: {
    title: `Will`,
    description: `Your Will lets you decide what happens to your money, property and possessions after you pass away. It is very important that your Will is safely stored, and is accessible to your trusted family and/or friends in the event of your death. This section allows you to record potentially useful information about your Will.`,
    fields: {
      storage: `My Will is stored here`,
      date: `My Will is dated`,
      drawnUp: `My Will was drawn up by`,
      executors: `My executors are`,
    },
  },
  shared: {
    noItems: `No items added.`,
    itemRemovalConfirmation: `Are you sure you want to remove selected item?`,
    itemRemovalTitle: `Confirm removal`,
  },
  generateSumary: `Generate summary`,
};
