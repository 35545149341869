import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

export interface AdviserPracticeAdminCanSendRecommendationPackGetOptions {
  clientId: number;
}

export function adviserPracticeAdminCanSendRecommendationPackApi(path: string) {
  return {
    path,
    GET: (params: AdviserPracticeAdminCanSendRecommendationPackGetOptions) =>
      api.get<boolean>(path, { params: mapFormData(params) }),
  };
}
