import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { FilterOptions, GetOptions, PaginationOptions, RequestConfig } from 'api/types';
import type { ClientFilters } from 'constants/clientFilters';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

import { clientApi } from './client';
import { clientsCreateInBulkApi } from './createInBulk';
import { clientsExportListApi } from './exportList';
import { clientsFollowAllApi } from './followAll';
import { clientsLetterOfAuthorityApi } from './letterOfAuthority';
import { clientMessagesSummaryApi } from './messagesSummary';
import { clientsMetaDashboardApi } from './metaDashboard';
import { clientsUnfollowAllApi } from './unfollowAll';

export type ClientsGetOptions = GetOptions &
  PaginationOptions &
  FilterOptions<ClientFilters> & {
    search?: string;
    clientTagIds?: number[];
  };

export type ClientsMetaDataGetOptions = {
  search?: string;
  clientTagIds?: number[];
};

export type ClientsGetResponse = JsonApiResponse & {
  meta: {
    action_required_number: number;
    all_clients_number: number;
    created_clients_number: number;
    awaiting_clients_number: number;
  };
};

export interface ClientsPostOptions {
  firstName: string;
  lastName: string;
  email: string;
  emailMessage: string;
  adviserId: number;
}

const apiPath = '/api/v1/clients';

function clientsApi(path: string) {
  return {
    path,
    createInBulk: () => clientsCreateInBulkApi(`${apiPath}/create-in-bulk`),
    exportList: () => clientsExportListApi(`${apiPath}/export-list`),
    followAll: () => clientsFollowAllApi(`${apiPath}/follow-all`),
    letterOfAuthority: () => clientsLetterOfAuthorityApi(`${path}/letter-of-authority`),
    metaDashboard: () => clientsMetaDashboardApi(`${path}/meta-dashboard`),
    messagesSummary: () => clientMessagesSummaryApi(`${path}/messages-summary`),
    unfollowAll: () => clientsUnfollowAllApi(`${apiPath}/unfollow-all`),
    GET: (
      { pageSize, pageNumber, clientTagIds, ...rest }: ClientsGetOptions,
      config?: RequestConfig
    ) =>
      api.get<ClientsGetResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          client_tag_ids: clientTagIds,
          ...rest,
        },
        ...config,
      }),
    POST: ({ emailMessage, ...rest }: ClientsPostOptions) =>
      api.post<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(rest),
            relationships: {
              invitation: {
                data: {
                  email_message: emailMessage,
                },
              },
            },
          },
        })
      ),
  };
}

type ClientApi = ReturnType<typeof clientApi>;
type ClientsApi = ReturnType<typeof clientsApi>;

function clients(): ClientsApi;
function clients(clientId: number): ClientApi;
function clients(clientId?: number): ClientsApi | ClientApi {
  return clientId ? clientApi(`${apiPath}/${clientId}`) : clientsApi(apiPath);
}

export { clients };
