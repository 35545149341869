import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { PaginationOptions, RequestConfig } from 'api/types';

export type ClientCaseAuditTrailGetOptions = PaginationOptions;

export function clientCaseAuditTrailApi(path: string) {
  return {
    path,
    GET: (params: ClientCaseAuditTrailGetOptions, config?: RequestConfig) => {
      const { pageSize, pageNumber, ...rest } = params;

      return api.get<JsonApiResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...rest,
        },
        ...config,
      });
    },
  };
}
