import React from 'react';
import styled from 'styled-components';

import { breakpoints } from 'helpers/styles/constants';
import CheckedImage from 'images/cmr/check_box.png';
import type { RecordingType } from 'components/VideoChat/RecordingContext';

const Image = styled.img`
  object-fit: contain;
  height: 6rem;

  @media ${breakpoints.mdDown} {
    height: 4rem;
  }
`;

interface WrapperProps {
  selected: boolean;
}
const Wrapper = styled.div<WrapperProps>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  border: 2px black solid;
  border-radius: 4px;
  cursor: pointer;
  aspect-ratio: 4/3;
`;

const SelectedImage = styled.img`
  position: absolute;
  left: 0.5em;
  bottom: 0.5em;
  width: 1.5rem;
  aspect-ratio: 1/1;

  @media ${breakpoints.mdDown} {
    width: 1rem;
  }
`;

const Title = styled.div`
  margin: 0.5em auto;
  font-weight: bold;
  font-size: 0.9rem;

  @media ${breakpoints.mdDown} {
    font-size: 0.7rem;
  }
`;

interface Props {
  src: string;
  alt: string;
  onSelect: (option: RecordingType) => void;
  selected: boolean;
  option: RecordingType;
  title: string;
}
const RecordingOption = ({ src, alt, onSelect, selected, option, title }: Props) => {
  return (
    <Wrapper selected={selected} onClick={() => onSelect(option)}>
      <Image src={src} alt={alt} />
      <Title>{title}</Title>
      {selected && <SelectedImage src={CheckedImage} alt="checkbox" />}
    </Wrapper>
  );
};

export default RecordingOption;
