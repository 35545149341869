import { clients, users } from 'api';
import { showErrorToast } from 'helpers/toasts';
import { goToUrl } from 'helpers/utils';
import { MessageMemoizer } from 'components/Messages/MessageMemoizer';
import type { clientIdObj } from 'components/TodoList/TodoListContainer';

import { getLocalStorageValue } from './localStorage';

export const logOut = (redirectUrl: string) => {
  // check if pending mail update to advisor
  const value = getLocalStorageValue<clientIdObj>('pendingMailUpdate');

  if (value?.client_id) {
    clients(value.client_id).todoList().sendUpdateMail().POST();
    localStorage.removeItem('pendingMailUpdate');
  }

  users()
    .signOut()
    .DELETE()
    .then(() => {
      MessageMemoizer.clearSessionStorage();

      if (redirectUrl) {
        goToUrl(redirectUrl);
      }
    })
    .catch(error => {
      showErrorToast(error.message, { isTranslated: true });
    });
};
