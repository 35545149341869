import { rem } from 'polished';
import { css } from 'styled-components';

import { breakpoints, misc } from 'helpers/styles/constants';

export const flexCenter = () => ({
  display: 'flex',
  'align-items': 'center',
  'justify-content': 'center',
});

export const circle = (dimension: string) => ({
  height: dimension,
  width: dimension,
  'border-radius': '50%',
});

export const clearVerticalMargin = () => css`
  > :first-child,
  * > :first-child {
    margin-top: 0;
  }

  > :last-child,
  * > :last-child {
    margin-bottom: 0;
  }
`;

export const setColumnsWidth = (...widths: string[]) => css`
  position: relative;
  padding: 0 ${rem(15)} 0 ${rem(30)};

  ${widths.map(
    (width, i) => css`
      &:nth-child(${i + 1}) {
        width: ${width};
      }
    `
  )};
`;

export const container = () => css`
  width: 100%;
  max-width: ${misc.containerWidth}px;
  margin: 0 auto;
  padding-left: ${rem(misc.gutter)};
  padding-right: ${rem(misc.gutter)};

  @media ${breakpoints.lgUp} {
    padding-left: ${rem(misc.gutterLg)};
    padding-right: ${rem(misc.gutterLg)};
  }
`;
