import React from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { rem, rgba } from 'polished';
import styled from 'styled-components';

import { misc } from 'helpers/styles/constants';
import routes from 'routes';
import { Field, Label } from 'components/Forms';
import { GenericToggle } from 'components/GenericToggle/GenericToggle';

import AppCookieInfoContainer, {
  Props as ContainerProps,
  RenderProps,
  Values,
} from './AppCookieInfoContainer';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${misc.zIndex9};
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
`;

const CookieContainer = styled.div`
  position: fixed;
  right: 6px;
  bottom: 6px;
  left: 6px;
  z-index: ${misc.zIndex9};
  padding: ${rem(16)};
  line-height: 1.1;
  background-color: ${({ theme }) => theme.colors.panelBg};
  box-shadow: 0 ${rem(-3)} ${rem(15)} ${rem(5)} ${({ theme }) => rgba(theme.colors.black, 0.1)};
  color: ${({ theme }) => theme.colors.textDark};
  border-radius: ${rem(3)};

  a {
    text-decoration: underline;
  }
`;

const Description = styled.div`
  margin-bottom: ${rem(20)};
  font-size: ${({ theme }) => theme.fontSizes.dbNormal};
  line-height: 1.6;
`;

const AcceptButton = styled.button<{ isMain?: boolean }>`
  margin-right: ${rem(8)};
  padding: ${rem(6)} ${rem(8)};
  font-size: ${({ theme }) => theme.fontSizes.dbNormal};
  background-color: ${({ theme, isMain }) =>
    isMain ? theme.colors.main : theme.colors.greyDarken};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${rem(3)};
`;

const AppCookieInfo = ({ onSubmit, initialValues }: RenderProps) => (
  <Overlay>
    <CookieContainer>
      <Form<Values>
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, form }) => (
          <>
            <Description>
              <FormattedMessage
                tagName="span"
                id="cookies.consent.description"
                values={{
                  link: (text: string) => <a href={routes.cookies.path()}>{text}</a>,
                }}
              />
            </Description>

            <Field
              name="necessaryCookiesConsent"
              type="checkbox"
              renderLabel={({ input }) => (
                <Label text="cookies.necessaryCookies.title" elementId={input.id} />
              )}
              renderControl={({ input, meta }) => (
                <GenericToggle
                  {...input}
                  onText={<FormattedMessage id="cookies.necessaryCookies.description.short" />}
                  offText={<FormattedMessage id="cookies.necessaryCookies.description.short" />}
                  hasError={meta.showError}
                  handleSize={16}
                  disabled
                />
              )}
            />

            <Field
              name="analyticsCookiesConsent"
              type="checkbox"
              renderLabel={({ input }) => (
                <Label text="cookies.analyticsCookies.title" elementId={input.id} />
              )}
              renderControl={({ input, meta }) => (
                <GenericToggle
                  {...input}
                  onText={<FormattedMessage id="cookies.analyticsCookies.description.short" />}
                  offText={<FormattedMessage id="cookies.analyticsCookies.description.short" />}
                  hasError={meta.showError}
                  handleSize={16}
                />
              )}
            />

            <AcceptButton
              type="button"
              disabled={submitting}
              onClick={() => {
                form.change('necessaryCookiesConsent', true);
                handleSubmit();
              }}
            >
              <FormattedMessage id="cookies.consent.allowSelected" />
            </AcceptButton>

            <AcceptButton
              type="button"
              disabled={submitting}
              onClick={() => {
                form.change('necessaryCookiesConsent', true);
                form.change('analyticsCookiesConsent', true);
                handleSubmit();
              }}
              isMain
            >
              <FormattedMessage id="cookies.consent.allowAll" />
            </AcceptButton>
          </>
        )}
      />
    </CookieContainer>
  </Overlay>
);

export { AppCookieInfo as AppCookieInfoUnwrapped };

const render = (renderProps: RenderProps) => <AppCookieInfo {...renderProps} />;
export default (props: ContainerProps) => <AppCookieInfoContainer render={render} {...props} />;
