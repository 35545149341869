import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';
import { objectToURLParams } from 'helpers/utils';

export interface ZendeskSignInGetOptions {
  returnTo?: string;
}

export function zendeskSignInApi(path: string) {
  const generatePath = (params?: ZendeskSignInGetOptions) =>
    params ? `${path}?${objectToURLParams(mapFormData(params))}` : path;

  return {
    path,
    GET: (params?: ZendeskSignInGetOptions) => api.get(generatePath(params)),
    GETPath: (params?: ZendeskSignInGetOptions) => generatePath(params),
  };
}
