import React, { FocusEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { usePopper } from 'react-popper';
import { useCombobox, UseComboboxProps } from 'downshift';
import { filter, isEmpty, map } from 'lodash';
import { position, rem, rgba, size } from 'polished';
import styled, { css } from 'styled-components';

import { misc } from 'helpers/styles/constants';
import { isNotBlank } from 'helpers/utils';
import IconArrowThird from 'components/Icons/IconArrowThird';

const arrowWidth = 9;
const arrowHeight = 6;
const inputVerticalPadding = 16;
const inputHorizontalPadding = 15;

export enum DropdownWidth {
  Full = 0,
  ExtraSmall = 90,
  Small = 150,
  Normal = 300,
}

const ArrowIcon = styled(({ theme, ...rest }) => <IconArrowThird {...rest} />)`
  ${position('absolute', '50%', rem(inputHorizontalPadding), null, null)};
  ${size(rem(arrowWidth), rem(arrowHeight))};
  display: inline-block;
  pointer-events: none;
  stroke: ${({ theme }) => theme.colors.grey};
  transform: translateY(-50%) rotateZ(90deg);
  transition: stroke 0.3s ease;
`;

const Wrapper = styled.div<{
  isOpen?: boolean;
  dropdownWidth?: DropdownWidth;
}>`
  position: relative;
  width: ${({ dropdownWidth }) => (dropdownWidth ? rem(dropdownWidth) : '100%')};
  outline: none;
  color: ${({ theme }) => theme.colors.textDark};

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${ArrowIcon} {
        transform: translateY(-50%) rotateZ(-90deg);
      }
    `};
`;

const InputWrapper = styled.div`
  position: relative;
`;

const DropdownInput = styled.input<{ hasError?: boolean }>`
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: ${rem(inputVerticalPadding)} ${rem(inputHorizontalPadding + arrowWidth)}
    ${rem(inputVerticalPadding)} ${rem(inputHorizontalPadding)};
  text-overflow: clip;
  line-height: 1.3;
  font-size: ${({ theme }) => theme.fontSizes.dbNormal};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.mischka};
  border-top-width: 2px;
  transition: border-color 0.3s ease;

  ${({ theme, hasError }) =>
    hasError &&
    css`
      &&& {
        border-color: ${theme.colors.crail};
      }
    `};

  &:focus,
  &:hover,
  &:active {
    border-color: ${({ theme }) => theme.colors.main};

    ${ArrowIcon} {
      stroke: ${({ theme }) => theme.colors.main};
    }
  }

  &:disabled {
    cursor: default;
    border-color: ${({ theme }) => theme.colors.mischka};
    background-color: transparent;
  }
`;

const DropdownMenu = styled.ul<{ isOpen?: boolean }>`
  ${({ isOpen }) =>
    !isOpen &&
    css`
      visibility: hidden;
      pointer-events: none;
    `};
  z-index: ${misc.zIndex4};
  overflow: auto;
  width: 100%;
  max-height: ${rem(300)};
  font-size: ${({ theme }) => theme.fontSizes.dbNormal};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.panelBorder};
  box-shadow: ${rem(3)} ${rem(3)} ${rem(15)} ${rgba(0, 0, 0, 0.1)};
`;

const DropdownItem = styled.li<{ isHighlighted?: boolean }>`
  padding: ${rem(inputVerticalPadding)} ${rem(inputHorizontalPadding)};
  line-height: 1.3;
  cursor: pointer;
  color: ${({ theme, isHighlighted }) => (isHighlighted ? theme.colors.main : theme.colors.grey)};
  transition: color 0.3s ease;
`;

const DropdownItemEmpty = styled.li`
  padding: ${rem(inputVerticalPadding)} ${rem(inputHorizontalPadding)};
  text-align: center;
  color: ${({ theme }) => theme.colors.grey};
`;

export interface Item {
  deactivated?: boolean;
  value: string;
  label: string;
}

type Reducer = Required<UseComboboxProps<Item>>['stateReducer'];

const stateReducer: Reducer = (state, actionAndChanges) => {
  switch (actionAndChanges.type) {
    case useCombobox.stateChangeTypes.InputBlur:
      if (!state.selectedItem) {
        return {
          ...actionAndChanges.changes,
          inputValue: '',
        };
      }

      if (state.selectedItem.label !== state.inputValue) {
        return {
          ...actionAndChanges.changes,
          inputValue: state.selectedItem.label,
        };
      }

      return actionAndChanges.changes;
    default:
      return actionAndChanges.changes;
  }
};

export interface Props {
  dropdownWidth?: DropdownWidth;
  isDisabled?: boolean;
  isSearchable?: boolean;
  hasError?: boolean;
  placeholder?: string;
  inputId?: string;
  options: Item[];
  selectedOption?: Item | null;
  initialSelectedOption?: Item;
  onChange?: (item: Item | null) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

function Dropdown({
  placeholder,
  isSearchable,
  hasError,
  isDisabled,
  inputId,
  options,
  selectedOption,
  initialSelectedOption,
  onChange,
  onFocus,
  onBlur,
  dropdownWidth = DropdownWidth.Normal,
}: Props) {
  const [filterQuery, setFilterQuery] = useState<string>();
  const filteredOptions = useMemo(() => {
    return isNotBlank(filterQuery)
      ? filter(options, item => item.label.toLowerCase().includes(filterQuery))
      : options;
  }, [options, filterQuery]);
  const referenceElementRef = useRef(null);
  const popperElementRef = useRef(null);

  const { styles, attributes } = usePopper(referenceElementRef.current, popperElementRef.current, {
    modifiers: [
      { name: 'preventOverflow', enabled: false },
      { name: 'hide', enabled: false },
    ],
    placement: 'bottom-start',
  });

  const {
    getItemProps,
    getInputProps,
    getMenuProps,
    getComboboxProps,
    isOpen,
    highlightedIndex,
    openMenu,
    inputValue: value,
    setInputValue,
  } = useCombobox<Item>({
    inputId,
    stateReducer,
    onInputValueChange: ({ inputValue, selectedItem }) => {
      setFilterQuery(
        !isSearchable || !inputValue || (selectedItem && inputValue === selectedItem.label)
          ? undefined
          : inputValue.toLowerCase()
      );
    },
    onSelectedItemChange: changes => {
      if (onChange) {
        onChange(changes.selectedItem || null);
      }
    },
    items: filteredOptions,
    itemToString: item => (item ? item.label : ''),
    ...(initialSelectedOption
      ? {
          initialSelectedItem: initialSelectedOption,
          initialInputValue: initialSelectedOption.label,
        }
      : {}),
    selectedItem: selectedOption,
  });

  const selectedOptionLabel = selectedOption?.label || '';

  const inputValueRef = useRef(value);
  useEffect(() => {
    inputValueRef.current = value;
  }, [value]);

  const setInputValueRef = useRef(setInputValue);
  useEffect(() => {
    setInputValueRef.current = setInputValue;
  }, [setInputValue]);

  useEffect(() => {
    if (selectedOptionLabel !== inputValueRef.current) {
      setInputValueRef.current(selectedOptionLabel);
    }
  }, [selectedOptionLabel]);

  return (
    <Wrapper
      isOpen={isOpen}
      dropdownWidth={dropdownWidth}
      {...getComboboxProps({ ref: referenceElementRef })}
    >
      <InputWrapper
        onClick={isDisabled ? undefined : () => openMenu()}
        data-test="dropdown-control"
      >
        {/* https://github.com/downshift-js/downshift/issues/718 */}
        {/*
        // @ts-ignore */}
        <DropdownInput
          {...getInputProps({
            disabled: isDisabled,
            readOnly: !isSearchable,
            placeholder,
            onFocus,
            onBlur,
            'aria-labelledby': undefined,
          })}
          data-test="dropdown-input"
          hasError={hasError}
          // "nope" due to https://github.com/downshift-js/downshift/issues/527
          autoComplete="nope"
        />

        <ArrowIcon />
      </InputWrapper>

      <DropdownMenu
        isOpen={isOpen}
        {...getMenuProps({ ref: popperElementRef, style: styles.popper, ...attributes.popper })}
      >
        {isEmpty(filteredOptions) ? (
          <DropdownItemEmpty>
            <FormattedMessage id="shared.noOptions" />
          </DropdownItemEmpty>
        ) : (
          map(filteredOptions, (item, index) => (
            <DropdownItem
              {...getItemProps({
                key: item.value,
                index,
                item,
              })}
              data-test="dropdown-item"
              isHighlighted={highlightedIndex === index}
            >
              {item.label}
            </DropdownItem>
          ))
        )}
      </DropdownMenu>
    </Wrapper>
  );
}

export default React.memo(Dropdown);
