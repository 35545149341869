import React, { useCallback } from 'react';
import { Form, FormProps } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { rem } from 'polished';

import { clients } from 'api';
import { isEnabled, ServerSideFeature } from 'helpers/features';
import { showApiErrorToast, showSuccessToast } from 'helpers/toasts';
import { required } from 'helpers/validators';
import { useGetIsCurrentUserAClient } from 'store/users/selectors';
import { Field, Label } from 'components/Forms';
import GenericButton from 'components/GenericButton/GenericButton';
import { GenericExpandableMenuItem } from 'components/GenericExpandableMenu';
import BasicModal from 'components/GenericModal/BasicModal';
import GenericTextarea from 'components/GenericTextarea/GenericTextarea';
import { GenericTitle } from 'components/GenericTitle/GenericTitle';
import useModalState from 'components/ModalHandler/useModalState';

interface Values {
  message: string;
}

export enum DocumentType {
  Addendum = 'Addendum',
  AdviceRoomDocument = 'AdviceRoomDocument',
  DocumentPackDocument = 'DocumentPackDocument',
  RecommendationPackDocument = 'RecommendationPackDocument',
}

interface Props {
  clientId: number;
  initialMessage: string;
}

export function MessageTeamAction({ clientId, initialMessage }: Props) {
  const [isModalOpen, openModal, closeModal] = useModalState();
  const isCurrentUserAClient = useGetIsCurrentUserAClient();

  const submitMessage = useCallback<FormProps<Values>['onSubmit']>(
    async values => {
      try {
        await clients(clientId).practiceMessages().POST({ content: values.message });
        showSuccessToast('adviser.documents.messageSent');
        closeModal();
      } catch (error) {
        showApiErrorToast(error);
      }
    },
    [clientId, closeModal]
  );

  if (isCurrentUserAClient || !isEnabled(ServerSideFeature.Messages)) {
    return null;
  }

  return (
    <>
      <GenericExpandableMenuItem data-test="message-team" onClick={openModal}>
        <FormattedMessage id="adviser.documents.messageTeam" />
      </GenericExpandableMenuItem>

      {isModalOpen && (
        <BasicModal
          onClose={closeModal}
          data-test="message-team-modal"
          shouldCloseOnOverlayClick={false}
          minWidth={rem(600)}
          isCloseButtonHidden
          isOpen
        >
          <Form<Values>
            onSubmit={submitMessage}
            initialValues={{
              message: initialMessage,
            }}
            render={({ handleSubmit, submitting }) => (
              <BasicModal.Form onSubmit={handleSubmit}>
                <BasicModal.Title>
                  <GenericTitle>
                    <FormattedMessage id="adviser.documents.messageTeam" />
                  </GenericTitle>
                </BasicModal.Title>

                <BasicModal.Content>
                  <Field<string>
                    name="message"
                    validate={required}
                    renderLabel={({ input }) => (
                      <Label text="adviser.documents.messageLabel" elementId={input.id} />
                    )}
                    renderControl={({ input: { type, ...inputRest }, meta }) => (
                      <GenericTextarea {...inputRest} hasError={meta.showError} />
                    )}
                  />
                </BasicModal.Content>

                <BasicModal.Footer>
                  <BasicModal.ModalButtons>
                    <GenericButton type="submit" disabled={submitting} hasNoWidth isHigh>
                      <FormattedMessage id="adviser.documents.messageSendButton" />
                    </GenericButton>

                    <GenericButton
                      type="button"
                      disabled={submitting}
                      onClick={closeModal}
                      isTransparent
                      hasNoWidth
                      isHigh
                    >
                      <FormattedMessage id="shared.cancel" />
                    </GenericButton>
                  </BasicModal.ModalButtons>
                </BasicModal.Footer>
              </BasicModal.Form>
            )}
          />
        </BasicModal>
      )}
    </>
  );
}
