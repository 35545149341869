import React, { useMemo } from 'react';
import { useField } from 'react-final-form';
import { BrowserInfo, detect } from 'detect-browser';
import type { PDFAnnotationData } from 'pdfjs-dist/types/src/display/api';
import styled from 'styled-components';

import { roundHalf } from 'helpers/numbers';

import useGenericPdfContext from '../../useGenericPdfContext';
import { commonWidgetStyles } from '../styled';
import { getFixedFieldName } from './helpers';
import { useReinitializeOnChange } from './useReinitializeOnChange';

const TEXT_ALIGNMENT: ['left', 'center', 'right'] = ['left', 'center', 'right'];

const Control = styled.input`
  ${commonWidgetStyles};
  resize: none;
`;

export interface Props {
  data: PDFAnnotationData;
  width: number;
  height: number;
  isReadOnly?: boolean;
}

export function TextAnnotationWidget({ data, width, height, isReadOnly }: Props) {
  const { multiLine, readOnly, maxLen, comb, textAlignment, fieldName } = data;
  const fieldId = getFixedFieldName(fieldName);
  const browser = useMemo(() => detect() as BrowserInfo, []);

  // https://final-form.org/docs/react-final-form/types/FieldProps#parse
  const { input } = useField(`pdfData.${fieldId}`, {
    initialValue: data.fieldValue,
    parse: v => v,
  });
  const { pdfWidth, pdfHeight, zoom } = useGenericPdfContext();

  useReinitializeOnChange(fieldId, data.fieldValue);

  const commonProps = {
    disabled: readOnly || isReadOnly,
    maxLength: maxLen,
    onKeyDown: (e: React.KeyboardEvent) => {
      e.stopPropagation(); // to prevent doc page changing when using arrow keys
    },
  };

  const widgetHeight = height * pdfHeight;
  const textVerticalPadding = 2 * widgetHeight * 0.2;

  let styleProps: React.CSSProperties = {
    textAlign: textAlignment != null ? TEXT_ALIGNMENT[textAlignment] : undefined,
    fontSize: multiLine ? `${zoom}%` : `${roundHalf(widgetHeight - textVerticalPadding)}px`,
  };

  if (comb && maxLen) {
    const widgetWidth = width * pdfWidth;
    const combWidth = widgetWidth / maxLen;

    styleProps = {
      ...styleProps,
      paddingRight: 0,
      fontFamily: 'Consolas, Monaco, Lucida Console, monospace',
      // Below values for Safari were chosen experimentally because of https://github.com/mozilla/pdf.js/issues/13726
      ...(['ios', 'safari'].includes(browser.name)
        ? {
            letterSpacing: `${combWidth * 0.55}px`,
            paddingLeft: `${combWidth / 4}px`,
          }
        : {
            letterSpacing: `calc(${combWidth}px - 1ch)`,
            paddingLeft: `calc((${combWidth}px - 1ch) / 2)`,
          }),
    };
  }

  return multiLine ? (
    <Control as="textarea" {...input} {...commonProps} style={styleProps} />
  ) : (
    <Control as="input" type="text" {...input} {...commonProps} style={styleProps} />
  );
}
