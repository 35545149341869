export enum LandingPage {
  HomePage = 'homepage',
  HowItWorks = 'howItWorks',
  OurAdvisers = 'ourAdvisers',
  Faq = 'faq',
  Contact = 'contact',
  HowWeCommunicate = 'howWeCommunicate',
  SecurityStatement = 'securityStatement',
}

export interface DisabledPages {
  [LandingPage.HomePage]?: boolean;
  [LandingPage.HowItWorks]?: boolean;
  [LandingPage.OurAdvisers]?: boolean;
  [LandingPage.Faq]?: boolean;
  [LandingPage.Contact]?: boolean;
  [LandingPage.HowWeCommunicate]?: boolean;
  [LandingPage.SecurityStatement]?: boolean;
}

export enum LandingType {
  Standard = 'standard',
  PortalLike = 'portal_like',
}
