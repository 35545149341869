import { MediaRegion } from 'types/entities/adviceRoom';

export function parseParticipantData(participantIdentity: string) {
  const splittedIdentity = participantIdentity.split('_');

  const isGuest = participantIdentity.startsWith('guest_');
  const id = Number(splittedIdentity[splittedIdentity.length - 1]);

  return { isGuest, id };
}

export function getDefaultMediaRegion(mediaRegion: MediaRegion | undefined) {
  return mediaRegion || MediaRegion.Ireland;
}
