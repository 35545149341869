import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, PaginationOptions, RequestConfig } from 'api/types';
import { mapFormData } from 'helpers/formData';

export interface OtherOptions {
  clientTagIds: number[];
}

export type AdviserClientsGetOptions = GetOptions & PaginationOptions & OtherOptions;

export function adviserClientsApi(path: string) {
  return {
    path,
    GET: (params: AdviserClientsGetOptions, config?: RequestConfig) => {
      const { pageSize, pageNumber, ...rest } = params;

      return api.get<JsonApiResponse>(path, {
        params: mapFormData({
          perPage: pageSize,
          page: pageNumber,
          ...rest,
        }),
        ...config,
      });
    },
  };
}
