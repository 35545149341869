import type { AnyAction } from 'redux';

import { SYNC_REGION } from './actionTypes';
import type { SyncRegionActionTypes, WebsiteState } from './types';

export const initialState = {
  awsRegion: '',
};

export default (state = initialState, incomingAction: AnyAction): WebsiteState => {
  const action = incomingAction as SyncRegionActionTypes;
  switch (action.type) {
    case SYNC_REGION:
      return {
        ...state,
        awsRegion: action.payload,
      };
    default:
      return state;
  }
};
