import { clientTodoListSendUpdateMailApi } from './sendUpdateMail';
import { clientTodoListTasksApi } from './tasks';
import { clientTodoListTasksTaskApi } from './tasks/task';

export function clientTodoListApi(path: string) {
  type ClientTodoListTasksApi = ReturnType<typeof clientTodoListTasksApi>;
  type ClientTodoListTasksTaskApi = ReturnType<typeof clientTodoListTasksTaskApi>;

  function tasks(): ClientTodoListTasksApi;
  function tasks(taskId: number): ClientTodoListTasksTaskApi;
  function tasks(taskId?: number): ClientTodoListTasksTaskApi | ClientTodoListTasksApi {
    const clientClientNotesMessagesPath = `${path}/tasks`;

    return taskId
      ? clientTodoListTasksTaskApi(`${clientClientNotesMessagesPath}/${taskId}`)
      : clientTodoListTasksApi(clientClientNotesMessagesPath);
  }

  return {
    path,
    tasks,
    sendUpdateMail: () => clientTodoListSendUpdateMailApi(`${path}/send-update-mail`),
  };
}
