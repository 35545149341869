import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormDataDeep, mapKeyToSnakeCase } from 'helpers/formData';

import { userActivateApi } from './activate';
import { userDeactivateApi } from './deactivate';
import { userMessagesApi } from './messages';
import { userOAuthAccounts } from './oauthAccounts';
import { userRemoveAvatarApi } from './removeAvatar';
import { userRemoveVirtualBackgroundApi } from './removeVirtualBackground';
import { userResendConfirmationApi } from './resendConfirmation';
import { userUpdateAsPdApi } from './updateAsPd';
import { userUpdateAvatarApi } from './updateAvatar';
import { userUpdateEmailApi } from './updateEmail';
import { userUpdateTourGuideApi } from './updateTourGuide';
import { userUpdateTwoFactorAuthApi } from './updateTwoFactorAuth';
import { userUpdateVirtualBackgroundApi } from './updateVirtualBackground';

export interface UserPatchOptions {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  currentPassword: string;
  passwordConfirmation?: string;
  phone?: string;
  postCode?: string;
  dateOfBirth?: string;
  country?: string;
  line1?: string;
  line2?: string;
  city?: string;
  primarySignature?: string;
  secondarySignature?: string;
}

export function userApi(path: string) {
  return {
    path,
    activate: () => userActivateApi(`${path}/activate`),
    deactivate: () => userDeactivateApi(`${path}/deactivate`),
    messages: () => userMessagesApi(`${path}/messages`),
    oauth_accounts: () => userOAuthAccounts(`${path}/oauth_accounts`),
    removeAvatar: () => userRemoveAvatarApi(`${path}/remove-avatar`),
    removeVirtualBackground: () =>
      userRemoveVirtualBackgroundApi(`${path}/remove-virtual-background`),
    resendConfirmation: () => userResendConfirmationApi(`${path}/resend-confirmation`),
    updateAsPd: () => userUpdateAsPdApi(`${path}/update-as-pd`),
    updateAvatar: () => userUpdateAvatarApi(`${path}/update-avatar`),
    updateEmail: () => userUpdateEmailApi(`${path}/update-email`),
    updateTourGuide: () => userUpdateTourGuideApi(`${path}/update-tour-guide`),
    updateTwoFactorAuth: () => userUpdateTwoFactorAuthApi(`${path}/update-two-factor-auth`),
    updateVirtualBackground: () =>
      userUpdateVirtualBackgroundApi(`${path}/update-virtual-background`),
    PATCH: ({
      firstName,
      lastName,
      email,
      currentPassword,
      password,
      passwordConfirmation,
      phone,
      postCode,
      dateOfBirth,
      country,
      line1,
      line2,
      city,
      primarySignature,
      secondarySignature,
    }: UserPatchOptions) =>
      api.patch<JsonApiResponseSingle>(
        path,
        mapFormDataDeep(
          {
            data: {
              attributes: {
                password,
                passwordConfirmation,
                currentPassword,
                email,
                firstName,
                lastName,
                phone,
                secondarySignature,
                signature: primarySignature,
              },
              relationships: {
                client: {
                  data: {
                    postCode,
                    dateOfBirth,
                    country,
                    line1,
                    line2,
                    city,
                  },
                },
              },
            },
          },
          (value, key) => (['line1', 'line2'].includes(key) ? key : mapKeyToSnakeCase(value, key))
        )
      ),
  };
}
