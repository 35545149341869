import { useCallback, useEffect, useState } from 'react';
import type { RemoteTrack, RemoteTrackPublication, Room } from 'twilio-video';

import { isScreenSharingTrack, SCREEN_TRACK_NAME } from 'api/twilio/twilioVideo';

import type { Toggle } from './types';

const startSharing = async (room: Room) => {
  const stream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: true });
  const [newScreenTrack] = stream.getVideoTracks();

  return room.localParticipant.publishTrack(newScreenTrack, {
    name: SCREEN_TRACK_NAME,
    priority: 'high',
  });
};

const stopSharing = (room: Room) => {
  room.localParticipant.videoTracks.forEach(({ track, trackName }) => {
    if (trackName === SCREEN_TRACK_NAME) {
      room.localParticipant.unpublishTrack(track);
      track.stop();
    }
  });
};

export default function useScreensharingTrack(room: Room | undefined) {
  const [isScreensharingEnabled, setIsScreensharingEnabled] = useState(false);

  useEffect(() => {
    if (!room) {
      return;
    }

    const handleTrackSubscribed = (track: RemoteTrack, publication: RemoteTrackPublication) => {
      if (isScreenSharingTrack(publication)) {
        stopSharing(room);
        setIsScreensharingEnabled(false);
      }
    };

    room.addListener('trackSubscribed', handleTrackSubscribed);

    return () => {
      room.removeListener('trackSubscribed', handleTrackSubscribed);
    };
  }, [room]);

  const toggleScreenTrack = useCallback<Toggle>(
    async shouldEnable => {
      if (!room) {
        return;
      }

      if (shouldEnable === true || (shouldEnable === undefined && !isScreensharingEnabled)) {
        try {
          const { track } = await startSharing(room);
          track.addListener('stopped', () => {
            stopSharing(room);
            setIsScreensharingEnabled(false);
          });

          setIsScreensharingEnabled(true);
        } catch (error) {
          setIsScreensharingEnabled(false);
        }
      } else {
        stopSharing(room);
        setIsScreensharingEnabled(false);
      }
    },
    [room, isScreensharingEnabled]
  );

  return { isScreensharingEnabled, toggleScreenTrack };
}
