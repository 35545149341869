import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { FilterOptions, GetOptions, PaginationOptions, RequestConfig } from 'api/types';
import type { TopUpsFilters } from 'constants/topUpsFilters';
import { mapFormData } from 'helpers/formData';
import type { AccountType } from 'components/TopUps/types';

interface ClientTopUpsPostOptions {
  accountType: AccountType;
  accountNumber: string;
  amount: number;
  amountCurrency: string;
  title?: string;
  clientComment?: string;
}

export type ClientTopUpsGetOptions = GetOptions & PaginationOptions & FilterOptions<TopUpsFilters>;

export function clientTopUpsApi(path: string) {
  return {
    path,
    GET: ({ pageSize, pageNumber, ...rest }: ClientTopUpsGetOptions, config?: RequestConfig) =>
      api.get<JsonApiResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...rest,
        },
        ...config,
      }),
    POST: (params: ClientTopUpsPostOptions) =>
      api.post<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
