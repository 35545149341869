import type { AnyAction } from 'redux';

import {
  GET_FINANCIAL_PLANNING_HEALTH_CHECK,
  GET_FINANCIAL_PLANNING_HEALTH_CHECK_FAIL,
  GET_FINANCIAL_PLANNING_HEALTH_CHECK_SUCCESS,
  SET_FINANCIAL_PLANNING_HEALTH_CHECK,
  UNSET_FINANCIAL_PLANNING_HEALTH_CHECK,
} from './actionTypes';
import type {
  FinancialPlanningHealthCheckActionTypes,
  FinancialPlanningHealthCheckState,
} from './types';

export const initialState: FinancialPlanningHealthCheckState = {
  id: null,
  isLoading: false,
  isInitiallyFetched: false,
};

export default (
  state = initialState,
  incomingAction: AnyAction
): FinancialPlanningHealthCheckState => {
  const action = incomingAction as FinancialPlanningHealthCheckActionTypes;

  switch (action.type) {
    case GET_FINANCIAL_PLANNING_HEALTH_CHECK:
      return {
        ...state,
        isLoading: true,
        isInitiallyFetched: false,
      };
    case GET_FINANCIAL_PLANNING_HEALTH_CHECK_SUCCESS:
    case GET_FINANCIAL_PLANNING_HEALTH_CHECK_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case SET_FINANCIAL_PLANNING_HEALTH_CHECK:
      return {
        ...state,
        id: action.payload.id,
        isInitiallyFetched: true,
      };
    case UNSET_FINANCIAL_PLANNING_HEALTH_CHECK:
      return {
        ...state,
        id: null,
      };
    default:
      return state;
  }
};
