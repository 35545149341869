import React, { useContext } from 'react';

interface ChatWindowContext {
  isUISmall: boolean;
  isUIHidden: boolean;
}

const Context = React.createContext<ChatWindowContext>({
  isUISmall: false,
  isUIHidden: false,
});

export default Context;

export const useChatWindowContext = () => useContext(Context);
