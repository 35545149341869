import { syncTokensApi } from './tokens';

const apiPath = '/api/v1/sync';

function syncApi(path: string) {
  return {
    path,
    tokens: () => syncTokensApi(`${path}/tokens`),
  };
}

function sync() {
  return syncApi(apiPath);
}

export { sync };
