import { Root, route } from '../utils';

const clientsPath = `${Root}clients`;
const clientPath = `${clientsPath}/:clientId`;
const financialStrategiesPath = `${clientPath}/financial-strategy`;
const financialStrategyPath = `${financialStrategiesPath}/:strategyId`;
const messagesPath = `${clientPath}/messages`;
const motivationCentrePath = `${clientPath}/motivation-centre`;
const vaultPath = `${clientPath}/vault`;
const topUpsPath = `${clientPath}/top-ups`;
const topUpPath = `${topUpsPath}/:topUpId`;
const casesPath = `${clientPath}/cases`;
const casePath = `${casesPath}/:caseId`;
const clientMeetingRoomsPath = `${clientPath}/client-meeting-rooms`;
const clientMeetingRoomPath = `${clientMeetingRoomsPath}/:adviceRoomId`;
const clientEstateSummaryPath = `${clientPath}/estate-summary`;
const identityVerificationsPath = `${clientPath}/identity-verifications`;
const identityVerificationPath = `${identityVerificationsPath}/:identityVerificationId`;
const summaryInteractionReportsPath = `${clientPath}/summary-interaction-reports`;

type ClientParams = [clientId: number];
type ClientMeetingRoomParams = [clientId: number, clientMeetingRoomId: number];
type VaultItemParams = [clientId: number, itemId: number];
type FinancialStrategyParams = [clientId: number, strategyId: number];
type TopUpParams = [clientId: number, topUpId: number];
type CaseParams = [clientId: number, caseId: number];
type MotivationCentreItemParams = [clientId: number, itemId: number];
type IdentityVerificationParams = [clientId: number, identityVerificationId: number];

export default {
  clients: {
    ...route(clientsPath),
    new: route(`${clientsPath}/new`),
    newDocumentPacks: route(`${clientsPath}/new-document-packs`),
  },
  client: {
    ...route<ClientParams>(clientPath),
    accountSettings: route<ClientParams>(`${clientPath}/account-settings`),
    manageAdditionalAdvisers: {
      ...route<ClientParams>(`${clientPath}/manage-additional-adviser`),
      auditTrail: route<ClientParams>(`${clientPath}/manage-additional-adviser/audit-trail`),
    },
    activityFeed: route<ClientParams>(`${clientPath}/activity-feed`),
    clientMeetingRooms: {
      ...route<ClientParams>(clientMeetingRoomsPath),
      new: route<ClientParams>(`${clientMeetingRoomsPath}/new`),
    },
    clientMeetingRoom: {
      ...route<ClientMeetingRoomParams>(clientMeetingRoomPath),
      meeting: route<ClientMeetingRoomParams>(`${clientMeetingRoomPath}/meeting`),
      auditTrail: route<ClientMeetingRoomParams>(`${clientMeetingRoomPath}/audit-trail`),
      recentFactFind: route<ClientMeetingRoomParams>(`${clientMeetingRoomPath}/recent-fact-find`),
      testSetup: route<ClientMeetingRoomParams>(`${clientMeetingRoomPath}/test-setup`),
    },
    estateSummary: {
      ...route<ClientParams>(clientEstateSummaryPath),
      auditTrail: route<ClientParams>(`${clientEstateSummaryPath}/audit-trail`),
    },
    financialStrategies: {
      ...route<ClientParams>(financialStrategiesPath),
      new: route<ClientParams>(`${financialStrategiesPath}/new`),
    },
    identification: route<ClientParams>(`${clientPath}/identification`),
    messages: {
      ...route<ClientParams>(messagesPath),
      interactionReports: route<ClientParams>(`${messagesPath}/interaction-reports`),
    },
    todos: route<ClientParams>(`${clientPath}/todos`),
    motivationCentre: {
      ...route<ClientParams>(motivationCentrePath),
      new: route<ClientParams>(`${motivationCentrePath}/new`),
      edit: route<MotivationCentreItemParams>(`${motivationCentrePath}/edit/:itemId`),
    },
    identityVerification: {
      new: route<ClientParams>(`${identityVerificationsPath}/new`),
      show: {
        ...route<IdentityVerificationParams>(identityVerificationPath),
        auditTrail: route<IdentityVerificationParams>(`${identityVerificationPath}/audit-trail`),
      },
    },
    vault: {
      ...route<ClientParams>(vaultPath),
      auditTrail: route<ClientParams>(`${vaultPath}/audit-trail`),
      item: {
        ...route<VaultItemParams>(`${vaultPath}/:itemId`),
        edit: route<VaultItemParams>(`${vaultPath}/:itemId/edit`),
      },
    },
    summaryInteractionReport: {
      ...route<ClientParams>(summaryInteractionReportsPath),
    },
    financialStrategy: {
      ...route<FinancialStrategyParams>(financialStrategyPath),
      auditTrail: route<FinancialStrategyParams>(`${financialStrategyPath}/audit-trail`),
    },
    topUp: {
      ...route<TopUpParams>(topUpPath),
      auditTrail: route<TopUpParams>(`${topUpPath}/audit-trail`),
    },
    cases: {
      new: route<ClientParams>(`${casesPath}/new`),
      newReviewQuestionnaire: route<ClientParams>(`${casesPath}/new-review-questionnaire`),
      newDocumentPack: route<ClientParams>(`${casesPath}/new-document-pack`),
    },
    case: {
      ...route<CaseParams>(casePath),
      auditTrail: route<CaseParams>(`${casePath}/audit-trail`),
      factFind: route<CaseParams>(`${casePath}/fact-find`),
      financialAudit: route<CaseParams>(`${casePath}/financial-audit`),
      financialPlanningHealthCheck: route<CaseParams>(
        `${casePath}/financial-planning-health-check`
      ),
      financialPlanningGoals: route<CaseParams>(`${casePath}/financial-planning-goals`),
      riskAssessment: route<CaseParams>(`${casePath}/risk-assessment`),
    },
  },
};
