import {
  TOUR_GUIDE_APP_SIDEBAR_ADVISERS_LINK,
  TOUR_GUIDE_APP_SIDEBAR_CLIENTS_LINK,
  TOUR_GUIDE_APP_SIDEBAR_PRACTICE_ADMINS_LINK,
  TOUR_GUIDE_APP_SIDEBAR_PRACTICE_SETTINGS_LINK,
  TOUR_GUIDE_CLIENT_PAGE_BUTTON,
  TOUR_GUIDE_HELP_CENTRE_BUTTON,
} from 'constants/tourGuide';
import { PermissionLevel } from 'types/entities/practiceAdmin';

export default {
  dashboard: {
    title: `Overview`,
    subtitle: `Here you can search all of the Practice’s users who use this online service.`,
    awaitingClients: `Awaiting clients`,
    clients: `Clients`,
    cases: `Cases`,
    email: `Email address`,
    phone: `Phone`,
  },
  practiceReports: {
    title: `Practice reports`,
    description: `Here you can download useful CSV reports containing information about your Practice’s use of this online service.`,
    clients: {
      title: `Clients`,
      description: `Details of all of the clients who use this site.`,
    },
    advisers: {
      title: `Adviser statistics`,
      description: `Summary statistics of all advisers’ use of this site.`,
    },
    practiceAdmins: {
      title: `Practice Admin Statistics`,
      description: `Summary statistics of all practice admins' use of this site.`,
    },
  },
  floatingCardNote: `The Practice Director configures this website, and manages settings and accounts on behalf of the Practice.`,
  practiceAdmins: {
    title: `Practice Admins`,
    subtitle: `Here you can review all Practice Admins, and a summary of the Advisers’ clients who they help support.`,
    list: {
      headers: {
        practiceAdmin: `Practice Admin`,
        lastLoggedIn: `Last logged in`,
      },
      noItems: `No Practice Admins to display.`,
    },
  },
  practiceSettings: {
    title: `Practice Settings`,
    general: `General`,
    clientMeetingRooms: `Client Meeting Rooms`,
    clientMedia: `Client Media`,
    requestingAdvice: `Requesting Advice`,
    prospectOnboarding: `Prospect Onboarding`,
    landingPages: `Landing Pages`,
    faq: `FAQ`,
    faqLong: `Frequently Asked Questions`,
    contact: `Contact`,
  },
  practiceStatistics: {
    title: `DWS usage`,
    subtitle: `Your Practice will be invoiced, or has been invoiced, for the corresponding usage fees.`,
    monthLabel: `Month:`,
    activatedAccountsCount: `{count} activated account(s)`,
    deactivatedAccountsCount: `{count} deactivated account(s)`,
    activatedNewAccountsCount: `{count} newly activated account(s)`,
    twilioUsageDescription: `Client Meeting Room and Chat usage fees can be viewed <twilio_link>here</twilio_link>.`,
  },
  advisers: {
    title: `Advisers`,
    subtitle: `Here you can review all Advisers, and a summary of their clients who use this online service.`,
    list: {
      headers: {
        adviser: `Adviser`,
        lastLoggedIn: `Last logged in`,
        totalCaseSize: `Est. total case size`,
        totalClientNetWorth: `Total client net worth`,
        casesCount: `Cases`,
        clientsCount: `Clients`,
      },
      noItems: `No Advisers to display.`,
    },
    assignModal: {
      assign: `Assign`,
    },
    assign: `Assign adviser`,
    clientsCount: `Clients: {count}`,
    editClientRemovalPermission: `Change permission to remove clients`,
    editClientRemovalPermissionDescription: `Only certain advisers (and their support staff) can remove clients. Allow {adviserFullName} to remove clients?`,
  },
  clients: {
    title: `Clients`,
    subtitle: `Here you can view all of the Practice’s clients who use this online service.
      Please assign any ‘awaiting prospective clients’ to an appropriate Adviser.`,
    accountActions: `Account actions`,
  },
  adviser: {
    clientSubtitle: `Here you can review details of each of the Adviser’s clients who use this online service.`,
    detailsDescription: `The contact information below is visible to clients. All fields are required, except for the phone number.`,
    detailsDescription2: `The biography will also be published on this website’s landing pages, and,
      hence, will be viewable by the public. Please ensure that the biography is appropriate and has been approved.`,
    deactivatedNote: `Until Adviser is deactivated, only general overview is visible.`,
  },
  practiceAdmin: {
    clientSubtitle: `Here you can review details of each of the clients who the Practice Admin helps to support.`,
    deactivatedNote: `Until Practice Admin is deactivated, only general overview is visible.`,
    permissionLevel: {
      fieldLabel: `Permission level`,
      title: `Change permission level`,
      description: `‘Prime’ Practice Admins may join Client Meeting Rooms. Whereas normal Practice Admins may not.`,
      note: `Note: Depending on your contractual arrangement with us, changing the permission level will likely result in a change of fees associated with this user.`,
      labels: {
        [PermissionLevel.Normal]: `Prime`,
        [PermissionLevel.Restricted]: `Normal`,
      },
    },
  },
  practiceDirectors: {
    title: `Practice Directors`,
    noItems: `No Practice Directors to display.`,
  },
  newClients: {
    title: `Bulk create new clients`,
    subTitle: `Upload the client creation spreadsheet (blank version below), to create new clients.`,
    failureMessage: `Accounts creation request failed.`,
    successMessage: `Accounts creation request successful.`,
    spreadsheetTitle: `Client creation spreadsheet`,
    creationNote: `Accounts creation request has been successfully processed. It might take some time to reflect in the "Clients" page.`,
    spreadsheetSubTitle1: `Please do not change <spreadsheet_link>this</spreadsheet_link> spreadsheet and please keep the headers.`,
    spreadsheetSubTitle2: `You can not add a client already existing in your portal.`,
    spreadsheetSubTitle3: `Email addresses for additional advisers should be separated by commas.`,
    spreadsheetSubTitle4: `For any queries, please visit your Help Centre for the guide “As a Practice Director, how do I create new clients in bulk?”.`,
    successCell: `Success: {value}`,
    failCell: `Fail: {value}`,
    totalCell: `Total: {value}`,
    log: `Log`,
  },
  newAdviser: {
    title: `Create Adviser account`,
    note: `Note: Depending on your contractual arrangement with us, creating an Adviser account will incur fees.`,
    modal: {
      message: `The Adviser account has been created successfully.
        You can now go back to your dashboard or create another Adviser account.`,
    },
  },
  newPracticeAdmin: {
    title: `Create Practice Admin account`,
    note: `Note: Depending on your contractual arrangement with us, creating a Practice Admin account will incur fees.`,
    modal: {
      message: `The Practice Admin account has been created successfully.
        You can now go back to your dashboard or create another Practice Admin account.`,
    },
  },
  deactivateModal: {
    title: `Confirm deactivation of {fullName}’s account?`,
    description: `The user will no longer be able to sign in until their account is reactivated by you.`,
    clientDescription: `Once deactivated, the client will not be able to sign in.`,
  },
  activateModal: {
    title: `Confirm activation of {fullName}’s account?`,
    description: `The user will be able to sign in and use their account again.`,
    clientDescription: `Once activated, the client will be able to sign in.`,
  },
  accountRemoveModal: {
    title: `Are you sure you wish to delete {fullName}’s client account?`,
    warning: `The deletion of clients is irreversible and must be used with caution. Please be certain that the deletion is required.`,
  },
  tourGuide: {
    [TOUR_GUIDE_APP_SIDEBAR_PRACTICE_SETTINGS_LINK]: `Click here to manage and customise your portal.`,
    [TOUR_GUIDE_APP_SIDEBAR_PRACTICE_ADMINS_LINK]: `Click here to invite practice support staff to your portal.`,
    [TOUR_GUIDE_APP_SIDEBAR_ADVISERS_LINK]: `Click here to invite advisers to your portal.`,
    [TOUR_GUIDE_APP_SIDEBAR_CLIENTS_LINK]: `Click here to view and manage client accounts for your portal.`,
    [TOUR_GUIDE_HELP_CENTRE_BUTTON]: `Click here to begin your essential training for a successful onboarding experience.`,
    [TOUR_GUIDE_CLIENT_PAGE_BUTTON]: `Click here to visit our dedicated client page.`,
  },
};
