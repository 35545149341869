import React, { useCallback, useEffect, useState } from 'react';
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';

import { flexCenter } from 'helpers/styles/mixins';
import { useGetCurrentClientId } from 'store/currentClient/selectors';
import { useGetIsCurrentUserAClient } from 'store/users/selectors';
import GenericLoader from 'components/GenericLoader/GenericLoader';
import { GenericToggle } from 'components/GenericToggle/GenericToggle';
import Margin from 'components/Margin/Margin';
import { RecordingIndicator } from 'components/RecordingIndicator/RecordingIndicator';
import { RecordingType, useRecordingContext } from 'components/VideoChat/RecordingContext';
import { VideoLayout } from 'components/VideoChat/types';

import ChatBackButton from './ChatBackButton';
import { FullscreenButton, LayoutButton, PinButton } from './OverlayButtons';
import RoomMessages from './RoomMessages';

const TopRow = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const MessagesWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  pointer-events: all;
`;

const Wrapper = styled.div<{
  isUISmall?: boolean;
  isExpanded?: boolean;
  areMessagesVisible: boolean;
}>`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: ${({ isUISmall }) => rem(isUISmall ? 10 : 16)};
  pointer-events: none;
  ${({ areMessagesVisible }) =>
    areMessagesVisible &&
    css`
      z-index: 1;
      background-color: ${({ theme }) => rgba(theme.colors.main, 0.75)};
    `}

  ${MessagesWrapper} {
    margin-top: ${({ isUISmall }) => rem(isUISmall ? 10 : 16)};
    ${({ isExpanded }) =>
      isExpanded &&
      css`
        margin-bottom: ${rem(110)};
      `}
  }
`;

const BackButtonWrapper = styled.div<{ isVisible?: boolean }>`
  ${flexCenter()};
  position: relative;
  top: ${rem(-40)};
  left: ${rem(-5)};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  padding: ${rem(4)} ${rem(8)};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.main};
  pointer-events: all;

  button {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const RecordingToggle = styled(GenericToggle)<{ isLoading: boolean }>`
  margin-right: 0.5em;
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'all')};
  border: ${({ theme, checked }) =>
    checked
      ? `${rem(2)} solid ${theme.colors.greenSuccess}`
      : `${rem(2)} solid ${theme.colors.greyMid}`};
  background: ${({ theme, checked }) =>
    checked ? `${theme.colors.greenSuccess}` : `${theme.colors.greyMid}`};

  ::after {
    background-color: white;
  }

  &:not(:disabled) {
    &:focus,
    &:hover,
    &:active {
      border-color: ${({ theme, checked }) =>
        checked ? `${theme.colors.greenSuccess}` : `${theme.colors.greyMid}`};
    }
  }

  &[aria-checked='true'] {
    &:after {
      background-color: white;
    }
  }
`;

const RecordingStatusContainer = styled.div`
  position: absolute;
  top: 0;
  left: 48%;
  transform: translateX(-50%);
`;

const RecordingStatusContent = styled.div`
  position: relative;
  display: flex;
`;

const Loader = styled(GenericLoader)`
  position: absolute;
  top: 50%;
  right: -1.8em;
  width: min-content;
  transform: translateY(-50%);
`;

interface Props {
  onToggleFullscreen: (shouldBeExtended?: boolean) => void;
  onToggleSticky: () => void;
  onToggleLayout: () => void;
  layout: VideoLayout;
  areMessagesVisible: boolean;
  isUISmall: boolean;
  isFloating?: boolean;
  isRecording?: boolean;
  isExpanded?: boolean;
  isSticky?: boolean;
  isStickable?: boolean;
}

export function VideoChatOverlay({
  onToggleFullscreen,
  onToggleSticky,
  onToggleLayout,
  layout,
  areMessagesVisible,
  isUISmall,
  isFloating,
  isRecording,
  isExpanded,
  isSticky,
  isStickable,
}: Props) {
  const { recordingType, loading, updateRecordingState } = useRecordingContext();
  const isCurrentUserAClient = useGetIsCurrentUserAClient();
  const currentClientId = useGetCurrentClientId();
  const [currentRecordingType, setCurrentRecordingType] = useState<RecordingType>();

  useEffect(() => {
    setCurrentRecordingType(recordingType);
  }, [recordingType]);

  const toggleRecordingState = useCallback(() => {
    if (loading || !updateRecordingState) return;

    const recordingState =
      recordingType === RecordingType.Audio ? RecordingType.Video : RecordingType.Audio;

    setCurrentRecordingType(recordingState);
    updateRecordingState({
      state: 'start',
      type: recordingState,
    }).then(type => setCurrentRecordingType(type));
  }, [recordingType, loading, updateRecordingState]);

  return (
    <Wrapper isUISmall={isUISmall} isExpanded={isExpanded} areMessagesVisible={areMessagesVisible}>
      <TopRow>
        <BackButtonWrapper isVisible={isFloating && !isExpanded}>
          <ChatBackButton />
        </BackButtonWrapper>

        {isRecording && (
          <RecordingStatusContainer>
            <RecordingStatusContent>
              {currentRecordingType === undefined ? (
                <Margin top={25}>
                  <Loader size={rem(20)} isVisible />
                </Margin>
              ) : (
                <>
                  {!isCurrentUserAClient && currentClientId && (
                    <RecordingToggle
                      handleSize={18}
                      checked={currentRecordingType === RecordingType.Video}
                      onChange={toggleRecordingState}
                      isLoading={loading}
                    />
                  )}

                  <RecordingIndicator recordingType={recordingType} />
                  {loading && <Loader size={rem(20)} isVisible />}
                </>
              )}
            </RecordingStatusContent>
          </RecordingStatusContainer>
        )}

        <div>
          {!isFloating && isStickable && (
            <PinButton isEnabled={isSticky} onClick={onToggleSticky} />
          )}

          <LayoutButton isEnabled={layout === VideoLayout.Grid} onClick={onToggleLayout} />

          <FullscreenButton isEnabled={isExpanded} onClick={onToggleFullscreen} />
        </div>
      </TopRow>

      {areMessagesVisible && (
        <MessagesWrapper>
          <RoomMessages />
        </MessagesWrapper>
      )}
    </Wrapper>
  );
}
