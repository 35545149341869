import React, { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { isFunction } from 'lodash';
import styled from 'styled-components';

import type { ReactPath } from 'routes/types';
import { isReactPath } from 'routes/utils';

export type OnClickEvent = MouseEvent<HTMLButtonElement>;
export type OnClickProp = (event: OnClickEvent) => void;

export interface Props {
  path?: ReactPath | string;
  onClick?: OnClickProp;
  className?: string;
  defaultTag?: string;
  disabled?: boolean;
  target?: string;
  title?: string;
  rel?: string;
  noPadding?: boolean;
  children: React.ReactNode | React.ReactNode[];
}

const StyledButton = styled.button<Props>`
  ${({ noPadding }) => noPadding && `padding: 0`};
`;

function GenericLink({ path, onClick, defaultTag = 'span', ...rest }: Props) {
  if (isFunction(onClick)) {
    return <StyledButton type="button" onClick={onClick} {...rest} />;
  }

  if (path) {
    return isReactPath(path) ? (
      <Link to={path.toString()} {...rest} />
    ) : (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a href={path.toString()} {...rest} />
    );
  }

  return React.createElement(defaultTag, rest);
}

export default GenericLink;
