import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';

export type GuestClientMeetingRoomsPossibleParticipantsGetOptions = {
  authToken: string;
};

export function guestClientMeetingRoomsPossibleParticipantsApi(path: string) {
  return {
    path,
    GET: ({ authToken }: GuestClientMeetingRoomsPossibleParticipantsGetOptions) =>
      api.get<JsonApiResponse>(path, {
        params: {
          '[data][attributes][auth_token]': authToken,
        },
      }),
  };
}
