import React from 'react';
import { uniqueId } from 'lodash';
import { lighten, rem } from 'polished';
import styled, { css } from 'styled-components';

import { circle } from 'helpers/styles/mixins';

const spaceSize = 2;
const borderWidth = 2;

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.dbNormalSm};
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.textLight};

  &:not(:empty) {
    margin-left: ${rem(12)};
  }
`;

interface SwitchProps {
  handleSize: number;
  hasError: boolean | undefined;
}

export const Switch = styled.button<SwitchProps>`
  ${({ handleSize, hasError, theme }) => {
    const height = handleSize + spaceSize * 2 + borderWidth * 2;
    const width = height + handleSize;

    return css`
      flex-shrink: 0;
      margin: 0;
      padding: 0;
      height: ${rem(height)};
      width: ${rem(width)};
      border: ${rem(2)} solid ${theme.colors.mischka};
      border-radius: ${rem(height / 2)};
      background-color: ${theme.colors.white};
      cursor: pointer;
      transition: all 0.3s;
      touch-action: none;
      user-select: none;
      outline: none;

      &:disabled {
        background-color: ${lighten(0.4, theme.colors.bermudaGray)};
        cursor: default;
      }

      &:not(:disabled) {
        ${hasError && `border-color: ${theme.colors.crail}`};

        &:focus,
        &:hover,
        &:active {
          border-color: ${theme.colors.main};
        }

        & + ${Label} {
          cursor: pointer;
        }
      }

      &:after {
        content: '';
        display: block;
        ${circle(rem(handleSize))};
        background-color: ${theme.colors.mischka};
        transform: ${`translate(${rem(spaceSize)}, 0)`};
        transition: all 0.3s;
      }

      &[aria-checked='true'] {
        &:after {
          background-color: ${theme.colors.greenSuccess};
          transform: ${`translate(${rem(handleSize + spaceSize)}, 0)`};
        }
      }
    `;
  }};
`;

function isControlled(props: Props) {
  return Object.prototype.hasOwnProperty.call(props, 'checked');
}

export interface Props
  extends Pick<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    'id' | 'onFocus' | 'onBlur' | 'tabIndex'
  > {
  onText?: React.ReactNode;
  offText?: React.ReactNode;
  handleSize?: number;
  disabled?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
  hasError?: boolean;
  dataTest?: React.ReactNode;
}

interface State {
  checked: boolean;
}

export class GenericToggle extends React.PureComponent<Props, State> {
  private uniqueId: string;

  public state: State = {
    checked: this.props.defaultChecked || false,
  };

  public constructor(props: Props) {
    super(props);
    this.uniqueId = uniqueId();
  }

  public static getDerivedStateFromProps: React.GetDerivedStateFromProps<Props, State> = (
    nextProps,
    prevState
  ) => {
    if (isControlled(nextProps) && nextProps.checked !== prevState.checked) {
      return {
        checked: nextProps.checked,
      };
    }

    return null;
  };

  private toggle = () => {
    const { onChange, disabled } = this.props;
    const { checked: checkedInState } = this.state;

    if (disabled) {
      return;
    }

    if (!isControlled(this.props)) {
      this.setState({
        checked: !checkedInState,
      });
    }

    if (onChange) {
      onChange(!checkedInState);
    }
  };

  public render() {
    const {
      id,
      onText,
      offText,
      hasError,
      handleSize = 22,
      onChange,
      checked,
      defaultChecked,
      children,
      dataTest,
      ...rest
    } = this.props;
    const { checked: checkedInState } = this.state;

    const finalId = id || this.uniqueId;

    return (
      <Wrapper>
        <Switch
          id={finalId}
          handleSize={handleSize}
          hasError={hasError}
          {...rest}
          role="switch"
          type="button"
          aria-checked={checkedInState}
          onClick={this.toggle}
          data-test={dataTest}
        />

        <Label htmlFor={finalId}>{checkedInState ? onText : offText}</Label>
      </Wrapper>
    );
  }
}
