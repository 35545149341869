import { endsWith, get, isEmpty, isFunction } from 'lodash';
import type { Store } from 'redux';

import { SESSION_INFO } from 'constants/session';
import { setSessionStorageValue } from 'helpers/sessionStorage';
import { showErrorToast } from 'helpers/toasts';
import { getPathName, goToUrl } from 'helpers/utils';
import routes from 'routes';
import { prefixRoute } from 'routes/utils';
import type { AppState } from 'store/types';
import { getCurrentUser } from 'store/users/selectors';
import GenericErrorMsg from 'components/GenericErrorMsg/GenericErrorMsg';

export const checkAndHandleUnauthorizedError = (store: Store<AppState>, err: unknown) => {
  if (isEmpty(err)) {
    return;
  }

  const statusCode: number = get(err, 'response.status');
  const pathName = getPathName();
  const state = store.getState();
  const currentUser = getCurrentUser(state);

  let redirectPath: string | null = null;

  // redirect user to login page if unauthorized
  if (statusCode === 401 && !endsWith(pathName, 'confirmation')) {
    setSessionStorageValue(SESSION_INFO, {
      pathName,
      userEmail: currentUser?.email,
    });

    redirectPath = routes.root.path();
  }

  // redirect user to the dashboard if forbidden
  if (statusCode === 403 && currentUser) {
    redirectPath = prefixRoute(currentUser.roleName, routes.root.path());
  }

  if (redirectPath && pathName !== redirectPath) {
    return goToUrl(redirectPath);
  }
};

export const showDetailErrors = (errorResponse: unknown) => {
  const errors = get(errorResponse, 'response.data.errors') || [];

  if (!errors.length) return showErrorToast();

  errors.forEach((item: unknown) => {
    const detail = get(item, 'detail');

    if (!isEmpty(detail)) {
      return showErrorToast(detail, { isTranslated: true });
    }

    showErrorToast();
  });
};

export const withScrollToErrorSubmit =
  <T extends unknown[]>(wrappedSubmit: (...args: T) => void) =>
  async (...args: T) => {
    if (!isFunction(wrappedSubmit)) {
      return;
    }

    await wrappedSubmit(...args);

    GenericErrorMsg.scrollIntoFirstError();
  };
