import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';

export interface DisclosureFormsMetaGetResponse {
  has_disclosure_forms: boolean;
}

export function disclosureFormsMetaApi(path: string) {
  return {
    path,
    GET: (config?: RequestConfig) => api.get<DisclosureFormsMetaGetResponse>(path, config),
  };
}
