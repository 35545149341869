import React from 'react';
import ReactDOM from 'react-dom';
import { lighten, rem } from 'polished';
import styled from 'styled-components';

import { fadeIn } from 'helpers/styles/animations';
import { flexCenter } from 'helpers/styles/mixins';
import { isBrowser } from 'helpers/utils';

import { bannersWrapperId } from './BannersWrapper';

const Banner = styled.div`
  ${flexCenter()};
  width: 100%;
  padding: ${rem(16)};
  min-height: ${rem(50)};
  background-color: ${({ theme }) => theme.colors.crail};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  animation: ${fadeIn} 1s;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => lighten(0.3, theme.colors.crail)};
  }
`;

const BannerComponent = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const wrapper = isBrowser() ? document.getElementById(bannersWrapperId) : null;

  return wrapper ? ReactDOM.createPortal(<Banner {...props} />, wrapper) : null;
};

export default BannerComponent;
