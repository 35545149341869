import type { FormRenderProps } from 'react-final-form';

import type { FinancialPlanningTopic } from 'constants/financialPlanningTopics';
import type { ProjectionType as ProjectionTypeEnum } from 'constants/projectionTypes';
import type { CaseType as CaseTypeEnum } from 'types/entities/clientCase';

export enum Fields {
  Email = 'email',
  NewEmail = 'newEmail',
  Password = 'password',
  PasswordConfirmation = 'passwordConfirmation',
  FirstName = 'firstName',
  LastName = 'lastName',
  PersonalDataConsent = 'personalDataConsent',
  Phone = 'phone',
  DateOfBirth = 'dateOfBirth',
  City = 'city',
  CaseType = 'caseType',
  Topics = 'topics',
  InitialInvestment = 'initialInvestment',
  MonthlyContribution = 'monthlyContribution',
  InvestmentDuration = 'investmentDuration',
  Currency = 'currency',
  ProjectionType = 'projectionType',
  CashReserve = 'cashReserve',
  HighInterestDebt = 'highInterestDebt',
  InvitationUuid = 'uuid',
  InvitationMessage = 'invitationMessage',
  Recaptcha = 'recaptcha',
  ClientAdvice = 'clientAdvice',
  DisclosureChecked = 'disclosureChecked',
  PreferredAdviserId = 'preferredAdviserId',
}

export type Values = {
  [Fields.Email]: string;
  [Fields.NewEmail]?: string;
  [Fields.Password]: string;
  [Fields.PasswordConfirmation]: string;
  [Fields.FirstName]: string;
  [Fields.LastName]: string;
  [Fields.PersonalDataConsent]: boolean;
  [Fields.Phone]: string;
  [Fields.DateOfBirth]: string;
  [Fields.City]: string;
  [Fields.CaseType]: CaseTypeEnum;
  [Fields.Topics]: FinancialPlanningTopic[];
  [Fields.InitialInvestment]: string;
  [Fields.MonthlyContribution]: string;
  [Fields.InvestmentDuration]: string;
  [Fields.Currency]: string;
  [Fields.ProjectionType]: ProjectionTypeEnum;
  [Fields.HighInterestDebt]?: boolean;
  [Fields.CashReserve]?: boolean;
  [Fields.InvitationUuid]?: string;
  [Fields.InvitationMessage]?: string;
  [Fields.Recaptcha]: string;
  [Fields.ClientAdvice]: string;
  [Fields.DisclosureChecked]: string;
  [Fields.PreferredAdviserId]?: number;
};

export type StepProps = FormRenderProps<Values>;
