import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

import { disclosureFormApi } from './disclosureForm';
import { disclosureFormsMetaApi } from './meta';

export interface DisclosureFormsPostOptions {
  title: string;
  image?: File;
  file: File;
}

const apiPath = '/api/v1/disclosure-forms';

export function disclosureFormsApi(path: string) {
  return {
    path,
    meta: () => disclosureFormsMetaApi(`${path}/meta`),
    GET: () => api.get<JsonApiResponse>(path),
    POST: (values: DisclosureFormsPostOptions) =>
      api.post<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(values),
          },
        })
      ),
  };
}

type DisclosureFormApi = ReturnType<typeof disclosureFormApi>;
type DisclosureFormsApi = ReturnType<typeof disclosureFormsApi>;

function disclosureForms(): DisclosureFormsApi;
function disclosureForms(disclosureFormId: number): DisclosureFormApi;
function disclosureForms(disclosureFormId?: number): DisclosureFormsApi | DisclosureFormApi {
  return disclosureFormId
    ? disclosureFormApi(`${apiPath}/${disclosureFormId}`)
    : disclosureFormsApi(apiPath);
}

export { disclosureForms };
