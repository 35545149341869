import React from 'react';
import { FormattedMessage } from 'react-intl';
import { lighten, position, rem, size } from 'polished';
import styled, { css } from 'styled-components';

import { circle } from 'helpers/styles/mixins';
import GenericErrorMsg from 'components/GenericErrorMsg/GenericErrorMsg';
import { GenericTooltip, TooltipPlacement } from 'components/GenericTooltip';

interface GenericCheckboxLabelProps {
  isSmall?: boolean;
}

const GenericCheckboxLabel = styled.span<GenericCheckboxLabelProps>`
  display: inline-block;
  padding-left: ${rem(8)};
  font-size: ${({ theme }) => theme.fontSizes.dbNormal};
  line-height: 1.4;

  ${({ isSmall }) =>
    isSmall &&
    css`
      font-size: ${({ theme }) => theme.fontSizes.dbSmallMd};
    `};
`;

const GenericCheckboxError = styled.div`
  margin-left: ${rem(8)};
`;

interface CheckboxProps extends GenericCheckboxLabelProps {
  isBig?: boolean;
  checkboxBorderDark?: boolean;
}

const Checkbox = styled.div<CheckboxProps>`
  ${size(rem(18))};
  position: relative;
  flex-shrink: 0;

  ${({ isSmall }) => isSmall && size(rem(18))};
  ${({ isBig }) => isBig && size(rem(30))};
`;

const CheckboxIcon = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  ${circle('100%')};
  background-color: ${({ theme }) => theme.colors.white};
  border: ${rem(2)} solid ${({ theme }) => theme.colors.mischka};
  cursor: pointer;
  pointer-events: none;
  transition: border-color 0.3s ease;
`;

const CheckboxInput = styled.input.attrs({ 'data-test': 'generic-checkbox' })<CheckboxProps>`
  ${size('100%')};
  display: block;
  opacity: 0.01;

  &:checked {
    + ${CheckboxIcon} {
      ${({ checkboxBorderDark }) =>
        checkboxBorderDark &&
        css`
          border: ${rem(2)} solid ${({ theme }) => theme.colors.main};
        `};

      ::after {
        ${position('absolute', 0, 0, 0, 0)};
        ${size(rem(8), rem(5))};
        content: '';
        display: block;
        margin: auto;
        border: ${rem(2)} solid ${({ theme }) => theme.colors.main};
        border-top-width: 0;
        border-left-width: 0;
        transform: translateY(${rem(-1)}) rotate(45deg);

        ${({ isSmall }) =>
          isSmall &&
          css`
            ${size(rem(7), rem(4))};
            border-width: 0 ${rem(2)} ${rem(2)} 0;
          `};

        ${({ isBig }) =>
          isBig &&
          css`
            ${size(rem(13), rem(8))};
            border-width: 0 ${rem(3)} ${rem(3)} 0;
          `};
      }
    }
  }

  &:focus + ${CheckboxIcon} {
    border-color: ${({ theme }) => theme.colors.main};
  }

  &:disabled + ${CheckboxIcon} {
    background-color: ${({ theme }) => lighten(0.05, theme.colors.mischka)};
  }
`;

const GenericCheckboxLabelWrapper = styled.label<{ isLabelCentered?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  line-height: ${rem(25)};

  ${({ isLabelCentered }) =>
    isLabelCentered &&
    css`
      display: inline-flex;
      align-items: center;
    `};
`;

interface GenericCheckboxWrapperProps extends CheckboxProps {
  hasMarginBottomMd?: boolean;
  hasMarginLeftSm?: boolean;
  hasNoMargin?: boolean;
  isLight?: boolean;
  isDark?: boolean;
  isFlex?: boolean;
  hasError?: boolean;
}

const GenericCheckboxWrapper = styled.div<GenericCheckboxWrapperProps>`
  margin: ${rem(15)} 0;

  ${({ hasMarginBottomMd }) => hasMarginBottomMd && `margin-bottom: ${rem(30)}`};
  ${({ hasMarginLeftSm }) => hasMarginLeftSm && `margin-left: ${rem(15)}`};
  ${({ isLight, theme }) => isLight && `color: ${theme.colors.textLight}`};
  ${({ isDark, theme }) => isDark && `color: ${theme.colors.textDark}`};
  ${({ isSmall }) =>
    isSmall &&
    css`
      margin-top: ${rem(7)};
      margin-bottom: ${rem(7)};
    `};

  ${({ isBig, theme }) =>
    isBig &&
    css`
      + ${GenericCheckboxLabelWrapper} {
        color: ${theme.colors.textDark};
      }
    `};

  ${({ isFlex }) =>
    isFlex &&
    css`
      display: flex;
      white-space: nowrap;
    `};

  ${({ hasNoMargin }) => hasNoMargin && `margin: 0`};

  ${({ hasError }) =>
    hasError &&
    css`
      ${CheckboxIcon} {
        border-color: ${({ theme }) => theme.colors.crail};
      }
    `};

  ${GenericCheckboxError} {
    padding-left: ${rem(20)};
    ${({ isSmall }) => isSmall && `padding-left: ${rem(16)};`};
    ${({ isBig }) => isBig && `padding-left: ${rem(30)};`};
  }
`;

export interface Props extends GenericCheckboxWrapperProps {
  checkboxRef?: React.RefObject<HTMLInputElement>;
  onChange?: (e: boolean) => void;
  checked?: boolean;
  tooltipPosition?: TooltipPlacement;
  tooltipText?: string;
  label?: React.ReactNode;
  error?: string;
  checkboxBorderDark?: boolean;
  isLabelContentCentered?: boolean;
  isDisabled?: boolean;
  isLabelBold?: boolean;
}

export default function GenericCheckbox({
  checkboxRef,
  checked,
  onChange,
  label,
  tooltipText,
  error,
  checkboxBorderDark,
  tooltipPosition = TooltipPlacement.Right,
  hasMarginBottomMd = false,
  hasMarginLeftSm = false,
  hasNoMargin = false,
  isLabelContentCentered = false,
  isLight = false,
  isBig = false,
  isSmall = false,
  isFlex = false,
  isDisabled = false,
  hasError = false,
  isLabelBold = false,
}: Props) {
  return (
    <GenericCheckboxWrapper
      hasMarginBottomMd={hasMarginBottomMd}
      hasMarginLeftSm={hasMarginLeftSm}
      hasNoMargin={hasNoMargin}
      isLight={isLight}
      isBig={isBig}
      isSmall={isSmall}
      isFlex={isFlex}
      hasError={hasError || Boolean(error)}
    >
      <GenericCheckboxLabelWrapper isLabelCentered={isLabelContentCentered}>
        <Checkbox isBig={isBig} isSmall={isSmall}>
          <CheckboxInput
            ref={checkboxRef}
            checked={checked}
            onChange={e => onChange && onChange(e.target.checked)}
            type="checkbox"
            disabled={isDisabled}
            isBig={isBig}
            isSmall={isSmall}
            checkboxBorderDark={checkboxBorderDark}
          />
          <CheckboxIcon />
        </Checkbox>

        {label && (
          <GenericCheckboxLabel isSmall={isSmall}>
            <span
              style={{
                marginRight: tooltipText ? rem(isSmall ? 4 : 8) : 0,
                fontWeight: isLabelBold ? 'bold' : 'normal',
              }}
            >
              {label}
            </span>

            {tooltipText && (
              <GenericTooltip
                text={<FormattedMessage id={tooltipText} />}
                position={tooltipPosition}
                isSmall={isSmall}
                hasNoMargin
              />
            )}
          </GenericCheckboxLabel>
        )}
      </GenericCheckboxLabelWrapper>

      {error && (
        <GenericCheckboxError>
          <GenericErrorMsg message={error} hasFullWidth />
        </GenericCheckboxError>
      )}
    </GenericCheckboxWrapper>
  );
}
