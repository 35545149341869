import api from 'api/apiInstance';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

export interface AvatarsPostOptions {
  file: File;
}

const apiPath = '/api/v1/avatars';

function avatarsApi(path: string) {
  return {
    path,
    POST: (data: AvatarsPostOptions) =>
      api.post(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(data),
          },
        })
      ),
    DELETE: () => api.delete(path),
  };
}

function avatars() {
  return avatarsApi(apiPath);
}

export { avatars };
