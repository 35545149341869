import { rem } from 'polished';
import styled from 'styled-components';

import { clearVerticalMargin } from 'helpers/styles/mixins';

export const Description = styled.div`
  ${clearVerticalMargin()};
  font-size: ${({ theme }) => theme.fontSizes.dbSmall};
  line-height: 1.55;
  color: ${({ theme }) => theme.colors.textDark};

  a {
    color: ${({ theme }) => theme.colors.textDark};
  }

  ul {
    margin-top: ${rem(20)};
  }

  li {
    margin: ${rem(5)} 0;

    span {
      margin-right: ${rem(3)};
    }
  }

  strong {
    font-weight: ${({ theme }) => theme.fontWeights.mainMedium};
  }
`;
