import React from 'react';
import { useField } from 'react-final-form';
import type { PDFAnnotationData } from 'pdfjs-dist/types/src/display/api';
import styled from 'styled-components';

import { commonWidgetStyles } from '../styled';
import { getFixedFieldName } from './helpers';
import { useReinitializeOnChange } from './useReinitializeOnChange';

const boxPadding = 15;

const Control = styled.input`
  ${commonWidgetStyles};

  &:checked {
    &::before {
      content: '';
      position: absolute;
      top: ${boxPadding}%;
      left: ${boxPadding}%;
      height: calc(100% - ${boxPadding * 2}%);
      width: calc(100% - ${boxPadding * 2}%);
      border-radius: 50%;
      background-color: currentColor;
    }
  }
`;

export interface Props {
  data: PDFAnnotationData;
  isReadOnly?: boolean;
}

export function RadioButtonAnnotationWidget({ data, isReadOnly }: Props) {
  const { readOnly, fieldName, fieldValue, buttonValue } = data;
  const fieldId = getFixedFieldName(fieldName);

  const { input } = useField(`pdfData.${fieldId}`, {
    type: 'radio',
    value: buttonValue,
    initialValue: fieldValue,
  });

  useReinitializeOnChange(fieldId, fieldValue);

  return <Control type="radio" disabled={readOnly || isReadOnly} {...input} />;
}
