import { rem } from 'polished';
import styled from 'styled-components';

import { breakpoints } from 'helpers/styles/constants';

import { modalPadding, modalPaddingSm } from './shared';

const ModalFooter = styled.div`
  flex-shrink: 0;
  padding: ${rem(modalPaddingSm)} ${rem(modalPaddingSm)};

  @media ${breakpoints.smUp} {
    padding: ${rem(modalPadding / 2)} ${rem(modalPadding)};
  }
`;

export default ModalFooter;
