import { RequestType } from 'constants/requestType';
import { CaseType, Tag } from 'types/entities/clientCase';
import { PermissionLevel } from 'types/entities/practiceAdmin';

const tags: Record<Tag, string> = {
  [Tag.Banking]: `Banking`,
  [Tag.Charges]: `Charges`,
  [Tag.Communication]: `Communication`,
  [Tag.FinancialJourney]: `Financial journey`,
  [Tag.IdentificationAndVerification]: `Identification and verification`,
  [Tag.IncomeWithdrawal]: `Income / withdrawal`,
  [Tag.Investment]: `Investment`,
  [Tag.Meeting]: `Meeting`,
  [Tag.MeetingSummary]: `Meeting summary`,
  [Tag.Mortgages]: `Mortgages`,
  [Tag.Objectives]: `Objectives`,
  [Tag.Other]: `Other`,
  [Tag.Protection]: `Protection`,
  [Tag.Retirement]: `Retirement`,
  [Tag.ReviewMeeting]: `Review meeting`,
  [Tag.Risk]: `Risk`,
  [Tag.TrustsAndEstate]: `Trusts and Estate`,
};

const permissionLevels: Record<PermissionLevel, string> = {
  [PermissionLevel.Normal]: `Practice Admin (Prime)`,
  [PermissionLevel.Restricted]: `Practice Admin`,
};

export default {
  shared: {
    titles: {
      financialStrategy: `Financial Strategy`,
      cases: `Cases and Recommendations`,
      recommendations: `Recommendations`,
      onlineAdviceRequests: `Online Advice requests`,
      adviceRooms: `Client Meeting Rooms`,
      documents: `My Document Packs`,
      meetingRequests: `Face-to-face meeting requests`,
      reviewQuestionnaires: `Questionnaires`,
      documentPacks: `Document Packs`,
      identityVerifications: `Identity Check`,
      motivationCentreItems: `Motivation Centre items`,
      documentVaultItems: `Vault items`,
    },
    noAddenda: `There are no addenda documents at the moment.`,
    addenda: `Addenda`,
    confirmTodoListUpdateEmail: `Are you sure you want to send a notification e-mail to {value}?`,
    todoListUpdateEmail: `Inform {value} that the to-do list is updated`,
    signatureAcknowledgement: {
      title: `Electronic signing`,
      description1: `By electronically signing this document, you acknowledge that this is equivalent to providing a ‘wet signature’ on a physical document.`,
      listHeader: `If you proceed to electronically sign this document, we will record:`,
      item1: `Your added signature`,
      item2: `The date and time of the signature`,
      item3: `Your current IP address ({value})`,
      acknowledge: `I acknowledge`,
    },
    technicalSupport: {
      title: `Request Technical Support`,
      nameFieldLabel: `Full Name`,
      emailFieldLabel: `Email`,
      firmFieldLabel: `Advice Firm Name`,
      userAccountFieldLabel: `User Account`,
      deviceFieldLabel: `Device Used`,
      dateAroseLabel: `Date of occurrence`,
      browserFieldLabel: `Browser Used`,
      urlFieldLabel: `URL link of where the issue occurred`,
      fileFieldLabel: `Upload file causing the issue`,
      issueAreaFieldLabel: `DWS area of use`,
      titleFieldLabel: `Title of Issue`,
      description1: `In order for our support team to investigate the issue, please kindly complete the mandatory fields, and if possible, provide information in the additional fields to the best of your knowledge.`,
      description2: `Please note that our initial response time may take up to 48 hours. Rest assured, we are committed to addressing any raised concerns, and providing additional details will enhance our ability to resolve the matter efficiently. Your cooperation is highly appreciated.`,
      enableText: `Please be aware that our turnaround time is 48 hours for an initial reply.We can reassure you that we will look into any issues raised and by proiding more details we will be able to sole the issue more effectiely.`,
      formAccountDetails: `Account Details`,
      formIssueDetails: `Issue Details`,
      formAccountDetailsText: `Please fill in the account details of the user for which the issue has occurred.`,
      subjectFieldLabel: `Title of technical issue`,
      detailsFieldLabel: `Client email address or case reference`,
      messageFieldLabel: `Overview of Issue`,
      screenshotFieldLabel: `Screenshots of the issue`,
      upload: `Upload`,
      takeScreenshotButton: `Take screenshot`,
      instructions: `Select an area to take a screenshot of`,
      instructionsObfuscate: `Select an area(s) to hide sensitive data`,
      uploadInfoText: `Maximum file size allowed for upload is 10 MB and if there are multiple files, please use zip file (<win>Windows</win> / <mac>Mac</mac>)`,
    },
    browserWarning: {
      title: `Warning: You are using a sub-optimal browser`,
      noteOnDashboard: `This website is built using the latest internet technologies. Unfortunately your browser ({value}) does not support these technologies.
        To ensure that all features of this website work well for you, we highly recommend that you use one of the following browsers.
        If you determine your device’s operating system, you can choose a suitable browser.`,
      noteOnAdviceRoom: `We note that you are using ({value}), which does not support Client Meeting Rooms.
        We highly recommend that you use one of the following browsers. If you determine your device’s operating system, you can choose a suitable browser.`,
      understand: `I understand`,
      dontShowAgain: `Don't show this message again for 4 weeks.`,
    },
    cancelCaseModal: {
      title: `Cancel case`,
      yes: `Yes, cancel case`,
      no: `No, don’t cancel`,
      defaultDescription: `Are you sure you want to cancel case {caseName}? This cannot be undone.`,
      description: {
        [CaseType.OnlineAdviceRequest]: `Are you sure you want to cancel Online Advice request <b>{caseName}</b>? This cannot be undone.`,
        [CaseType.RecommendationPackCase]: `Are you sure you want to cancel Recommendation Pack <b>{caseName}</b>? This cannot be undone.`,
        [CaseType.ReviewQuestionnaireCase]: `Are you sure you want to cancel questionnaire <b>{caseName}</b>? This cannot be undone.`,
        [CaseType.DocumentPackCase]: `Are you sure you want to cancel Document Pack <b>{caseName}</b>? This cannot be undone.`,
        [CaseType.MeetingRequestCase]: `Are you sure you want to cancel Meeting Request <b>{caseName}</b>? This cannot be undone.`,
      },
      cancelDocumentPackAccept: `Yes, cancel Document Pack`,
      cancelDocumentPackTitle: `Cancel Document Pack`,
    },
    title: `Title`,
    authority: `Authority`,
    type: `Type`,
    status: `Status`,
    size: `Est. size`,
    investmentDuration: `Investment duration`,
    itemName: `Item name`,
    itemNameDefault: `Motivation Centre Item`,
    caseStatus: `Case status`,
    caseSize: `Est. case size`,
    createdAt: `Created at: {value}`,
    created: `Created`,
    updated: `Updated`,
    hello: `Hello {userName}`,
    welcome: `Welcome, {userName}`,
    notShowNotification: `Do not show this notification again`,
    clientTags: {
      title: `Client Tags`,
      clientTagUpdated: `Client Tags has been successfully updated.`,
      clientTagDeleted: `Client Tag has been successfully deleted.`,
      emptyClientTagsText: `There are no chosen client tags.`,
    },
    tags: {
      title: `Tags`,
      documentTagsTitle: `Document Tags`,
      emptyTagsText: `There are no chosen tags.`,
      emptyDocumentTagsText: `There are no chosen document tags.`,
      filterByTags: `Filter by tags`,
      filterByDocumentTags: `Filter by document tags`,
      filteredBy: `Filtered by:`,
      resetTags: `Reset tags`,
      select: `Select Document Tags`,
      selectDocumentTags: `Select Document Tags`,
      selectInstruction: `Select tags that best describe the key topics addressed in the Document Pack.`,
      filterSelectInstruction: `You can filter My Documents using tags. Please select the relevant tags.`,
      item: tags,
    },
  },
  cancelledCase: {
    [CaseType.OnlineAdviceRequest]: `This Online Advice request has been cancelled.`,
    [CaseType.RecommendationPackCase]: `This Recommendation Pack has been cancelled.`,
    [CaseType.ReviewQuestionnaireCase]: `This questionnaire has been cancelled.`,
    [CaseType.DocumentPackCase]: `This Document Pack has been cancelled.`,
    [CaseType.MeetingRequestCase]: `This Meeting Request has been cancelled.`,
    seeAuditTrail: `Please see the case audit trail for further details.`,
    seeAuditTrailWithoutCase: `Please see the audit trail for further details.`,
  },
  practiceDirector: {
    intro: `Here you can view all of the Practice’s clients who wait for your action.`,
  },
  practiceAdmin: {
    titles: permissionLevels,
    intro: `Here you can search for clients, assigned to your advisers, who use this digital wealth platform.`,
    clients: `Here you can view all clients, assigned to your advisers, who use this digital wealth platform.`,
    actionRequiredNote: `Here you can view details of clients, whom you follow, that require the Practice's support.`,
    cantFindClient: `Can't find the client you're looking for?`,
    missingClientInfo: `Speak to {value} (Practice Director). You might not be assigned to the correct adviser, and cannot find the client you are looking for.`,
    noAdvisersAssignedTo: `You are not assigned to advisers.`,
    permissionLevelWarning: `Only ‘Prime’ Practice Admins can join Client Meeting Rooms. If you need to join meetings, please contact your Practice Director ({fullName}).`,
    advisersAssignedTo: `You are currently assigned to the following advisers:`,
    sendRecommendationPackWarning: `<b>Need to send this Recommendation Pack?</b> You currently do not have permission to do so for this client. If you require permission, please contact {contactList}.`,
    acceptRecommendationPackWarning: `<b>Need to accept this Recommendation Pack?</b> You currently do not have permission to do so for this clients. If you require permission, please contact {contactList}.`,
  },
  adviser: {
    clientTiles: `Below is the list of tiles that your clients are able to see on their dashboards:`,
    meetingRooms: {
      title: `Upcoming Client Meeting Rooms`,
      tileDescription: `View today and future Client Meeting Rooms.`,
    },
    clientActivityFeed: {
      title: `Recent client activity`,
      description: `View all client activity`,
      emptyList: `There has not been any recent activity.`,
    },
    actionRequired: {
      description: `Here you can find clients that require your action.`,
      tileDescription: `View clients that require an action from your office.`,
    },
    clients: {
      description: `Here you can review your clients, and and prospective clients, who are registered on this online service. Choose a client account.`,
      tileDescription: `View all clients. Invite new clients and prospects.`,
    },
    helpCentre: {
      tileDescription: `Access your onboarding training and support content for this website.`,
    },
    settings: {
      tileDescription: `Configure your account settings.`,
    },
    intro: `Here you can search for clients, assigned to you, who use this digital wealth platform.`,
    summary: `Here you can see detailed information about the client, and manage the client’s account.`,
    myClients: `My clients`,
    createdClients: `Unconfirmed clients`,
    sharedClients: `Shared clients`,
    deactivatedClientNote: `This account is deactivated. The account is now read-only until it is reactivated.`,
    caseImageDescription: `Below is a preview of what your client will see on their dashboard for
      this case. You can optionally upload or choose a suitable image that will help to
      ‘personalise’ the case.`,
    todoList: `To-do List`,
    todoListWarning: `Caution: These tasks are shared with the client.`,
    clientMailingList: `Who gets auto emails about {clientFullName}?`,
  },
  client: {
    welcome: {
      title: `Welcome`,
      description: `View your personal welcome note.`,
    },
    financialStrategy: {
      title: `My Financial Strategy`,
      description: `Review strategy reports that detail your long-term financial plan.`,
    },
    recommendations: {
      title: `Recommendations`,
      description: `Here are details of personal recommendations that were facilitated by this online service.`,
    },
    adviceRequests: {
      title: `Advice requests`,
      description: `Here are details of advice requests and meetings that you have requested using this online service.`,
    },
    onlineAdviceRequests: {
      title: `Online Advice requests`,
    },
    ourServicesAndCharges: {
      title: `Our services and charges`,
      description: `Understand more about how our fees work for the different services that we offer.`,
    },
    cases: {
      title: `Recommendations and Advice Requests`,
      tileDescription: `View details of personal recommendations offered, and advice requests made, via this online service.`,
    },
    meetings: {
      title: `Client Meeting Rooms`,
      description: `Here are details of your Client Meeting Room that were facilitated via this online service.`,
      tileDescription: `Join Client Meeting Rooms with our team. Review old and upcoming meetings.`,
    },
    identityVerifications: {
      title: `Identity Check`,
      description: `Here you can view details of Identity Checks that were facilitated via this online service.`,
      titleWithAuthority: `Identity Check: {authority}`,
    },
    questionnaires: {
      title: `Questionnaires`,
      description: `Sometimes we may ask you to complete questionnaires relating to your personal situation. Details of these are shown below.`,
      tileDescription: `Answer questionnaires relating to your financial situation.`,
    },
    documents: {
      title: `My Documents`,
      description: `Below are documents, which don’t relate to specific advice, that our office has sent to you.`,
      tileDescription: `Documents that summarise your existing financial arrangements and our service.`,
    },
    documentVault: {
      title: `My Vault`,
      tileDescription: `Store and view your important, wealth-related documents (to be shared with your adviser, if you wish).`,
      shared: `Shared with{br}your Adviser`,
      notShared: `Not shared with{br}your Adviser`,
      notSharedTooltip: `Your document is currently not shared with your adviser. If you wish to share the document, go to edit page and change the settings.`,
    },
    motivationCentre: {
      title: `Motivation Centre`,
      description: `Whatever your reasons to financially plan, here you and your Adviser can post and share useful resources that may help you along the journey.`,
      tileDescription: `Add and view resources to encourage you to achieve your financial and life goals.`,
    },
    settings: {
      tileDescription: `View and change your account settings.`,
    },
    adviserInfo: {
      email: `Email address`,
      bio: `Adviser Bio`,
      name: `Name`,
      social: `Social Media Profiles`,
      linkedIn: `LinkedIn Profile`,
      facebook: `Facebook Profile`,
      twitter: `Twitter Profile`,
      address: `Address`,
      phone: `Phone number`,
      title: `Title`,
      noBio: `Biography for this adviser has not been created yet.`,
    },
    dashboardTitle: `{name}`,
    dashboardDescription: `This is your dashboard, which provides you to access to all of your wealth management resources on this site.`,
    todoList: `My To-do List`,
    todoListNoTasks: `No to-dos added.`,
    createTask: `Add to-do`,
    deleteTask: {
      title: `Remove To-do`,
      text: `Are you sure you wish to remove this to-do?`,
    },
    todoListWarning: `Note: This list is shared with your Adviser`,
    createTaskSuccess: `To-do has been successfully created`,
    updateTaskSuccess: `To-do has been successfully updated`,
    deleteTaskSuccess: `To-do has been successfully deleted`,
    newRequestButton: `Request`,
    request: {
      [RequestType.Meeting]: `Request a face-to-face meeting`,
      [RequestType.OnlineAdvice]: `Request advice on a new investment`,
      [RequestType.TopUpInvestment]: `Request a top-up investment`,
    },
    descriptionHeader: `description`,
    investment: `investment`,
    duration: `duration`,
    yourRequestStatus: `Status:`,
    viewButton: `View`,
    linkButton: `Open`,
    created: `Created`,
    cancelCase: `Cancel case`,
    cancelDocumentPack: `Cancel Document Pack`,
    caseDescription: {
      [CaseType.RecommendationPackCase]: `This case details a personal recommendation from your adviser.`,
      [CaseType.ReviewQuestionnaireCase]: `Questionnaire for completion ahead of your upcoming meeting.`,
    },
    modal: {
      newInvestmentTitle: `Online Advice request`,
      newTopUpTitle: `Request advice on a top-up investment`,
    },
    notification: {
      caseCancelSuccess: `Case has been successfully cancelled`,
      caseCancelError: `Case couldn’t be cancelled`,
    },
    emptyList: {
      activeOnlineAdviceRequests: `No active requests.{canRequest, select,
        true { You can <a>request advice</a>.}
        other {}
      }`,
      closedOnlineAdviceRequests: `No closed requests.`,
      activeRecommendations: `You have no active personal recommendations.`,
      closedRecommendations: `You have no closed personal recommendations.`,
      upcomingAdviceRooms: `There are no Client Meeting Rooms scheduled. However, you can <a>request a meeting</a>.`,
      allAdviceRooms: `No Client Meetings Rooms have been scheduled or taken place.`,
      activeMeetingRequests: `You have no active face-to-face meeting requests.{canRequest, select,
        true { You can <a>request a meeting</a>.}
        other {}
      }`,
      closedMeetingRequests: `You have no closed face-to-face meeting requests.`,
      documentPacks: `Our office has not yet sent you a Document Pack.`,
      documentPacksTagged: `Our office has not yet sent you a Document Pack described by selected tags.`,
      activeReviewQuestionnaire: `There are no active Questionnaires for you to complete.`,
      closedReviewQuestionnaire: `There are no closed Questionnaires.`,
      motivationCentre: `You have not yet added an item to your Motivation Centre.`,
      documentVault: `You have not yet added a document to this folder.`,
      financialStrategies: `You have no documents shared with you.`,
      activeIdentityVerification: `You have no active Identity Checks.`,
      closedIdentityVerification: `You have no closed Identity Checks.`,
    },
  },
};
