export default {
  title: `Following clients`,
  follow: `Follow`,
  followAll: `Follow all clients`,
  followAllConfirmation: `Are you sure you want to follow all clients?`,
  unfollow: `Unfollow`,
  unfollowAll: `Unfollow all clients`,
  unfollowAllConfirmation: `Are you sure you want to unfollow all clients?`,
  clientFollowed: `You are now following {value}`,
  clientUnfollowed: `You have stopped following {value}`,
  clientsFollowed: `You are now following all clients`,
  clientsUnfollowed: `You have stopped following all clients`,
  followingTooltip: `By following a client you will receive auto-emails relating to that client (subject to permissions granted to you by the relevant adviser), and also receive Messages regarding the client and from the client. Some staff may wish to follow all clients - so they can track all client activity on this site. Whereas other staff may only wish to follow a small number of clients, whose cases they are actively working on.`,
  followNewClientsOn: `Automatically follow newly created clients`,
  followNewClientsOff: `Do NOT automatically follow newly created clients`,
  following: `Following`,
  settingsChanges: `Following settings changed`,
};
