import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions } from 'api/types';
import { mapFormData } from 'helpers/formData';
import type { PermissionLevel } from 'types/entities/practiceAdmin';

export interface InvitationsPracticeAdminsPostOptions {
  firstName: string;
  lastName: string;
  email: string;
  permissionLevel: PermissionLevel;
}

export function invitationsPracticeAdminsApi(path: string) {
  return {
    path,
    GET: (params?: GetOptions) => api.get<JsonApiResponse>(path, { params }),
    POST: (params: InvitationsPracticeAdminsPostOptions) =>
      api.post<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
