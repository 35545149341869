import React from 'react';
import { FormattedMessage } from 'react-intl';
import { rem } from 'polished';

import FormActionButtons from 'components/FormActionButtons/FormActionButtons';
import BasicModal from 'components/GenericModal/BasicModal';
import GenericText from 'components/GenericText/GenericText';
import { GenericTitle } from 'components/GenericTitle/GenericTitle';

interface Props {
  onClose: () => void;
  onSubmit?: () => void;
  onLabelClick?: () => void;
  isOpen: boolean;
  text: React.ReactNode;
  hasNoButtons?: boolean;
  buttonContent?: React.ReactNode;
  titleText?: string;
  labelContent?: React.ReactNode;
}

export function OnboardingModal({
  onClose,
  isOpen,
  onSubmit,
  text,
  titleText,
  onLabelClick,
  hasNoButtons,
  buttonContent = <FormattedMessage id="shared.modal.accept" />,
  labelContent = <FormattedMessage id="shared.modal.back" />,
}: Props) {
  return (
    <BasicModal width={rem(580)} isOpen={isOpen} onClose={onClose}>
      {titleText && (
        <BasicModal.Title>
          <GenericTitle>
            <FormattedMessage id={titleText} />
          </GenericTitle>
        </BasicModal.Title>
      )}

      <BasicModal.Content>
        <GenericText as="div">{text}</GenericText>
      </BasicModal.Content>

      {!hasNoButtons && (
        <BasicModal.Footer>
          <FormActionButtons
            onSubmitBtnClick={onSubmit}
            submitBtnContent={buttonContent}
            labelBtnContent={labelContent}
            onLabelBtnClick={onLabelClick || onClose}
          />
        </BasicModal.Footer>
      )}
    </BasicModal>
  );
}
