import React from 'react';
import { position, rem } from 'polished';
import styled from 'styled-components';

const Wrapper = styled.span<{ hasError: boolean }>`
  ${position('absolute', null, rem(11), rem(7), null)};
  font-size: ${({ theme }) => theme.fontSizes.dbSmallMd};
  color: ${({ theme, hasError }) => (hasError ? theme.colors.crail : theme.colors.mischka)};
  transition: color 0.3s ease;
`;

interface Props {
  currentLength: number;
  maxLength: number;
}

const GenericLengthCounter = ({ currentLength, maxLength }: Props) => (
  <Wrapper hasError={currentLength > maxLength} data-test="generic-textarea-counter">
    {currentLength} / {maxLength}
  </Wrapper>
);

export default GenericLengthCounter;
