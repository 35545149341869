import React from 'react';
import { FormattedMessage } from 'react-intl';
import { rem, size } from 'polished';
import styled, { css } from 'styled-components';

import GenericText from 'components/GenericText/GenericText';
import { GenericTooltip } from 'components/GenericTooltip';
import IconCheckmarkSec from 'components/Icons/IconCheckmarkSec';

interface PasswordRule {
  translation: string;
  renderTooltip?: () => React.ReactNode;
  check: (password: string) => boolean;
}

export const rules: PasswordRule[] = [
  {
    translation: 'registration.validation.charactersMin',
    check: password => password.length >= 10,
  },
  {
    translation: 'registration.validation.charactersMax',
    check: password => password.length <= 32,
  },
  {
    translation: 'registration.validation.equalCharacters',
    check: password => !/(.)\1\1/.test(password),
  },
  {
    translation: 'registration.validation.smallLetter',
    check: password => /[a-z]/.test(password),
  },
  {
    translation: 'registration.validation.capitalLetter',
    check: password => /[A-Z]/.test(password),
  },
  {
    translation: 'registration.validation.number',
    check: password => /[0-9]/.test(password),
  },
  {
    translation: 'registration.validation.specialCharacter',
    check: password => /[-!$%^°&§@*()_+#|~=`{}\][\\:";'<>?,./]/.test(password),
    renderTooltip: () => (
      <FormattedMessage
        id="registration.validation.specialCharacterTooltip"
        values={{
          characters: (
            <span style={{ letterSpacing: '2px' }}>{'$%^°&§@*_+-#|~=(){}[]<>`\'"?!,.:;\\/'}</span>
          ),
        }}
      />
    ),
  },
  {
    translation: 'registration.validation.noWhitespace',
    check: password => /^\S+$/.test(password),
  },
];

const RulesList = styled.ul`
  margin-top: ${rem(12)};
`;

const getRuleColor = (isRuleAchieved: boolean) =>
  css`
    ${({ theme }) => {
      if (isRuleAchieved === true) {
        return theme.colors.mountainMeadow;
      }

      if (isRuleAchieved === false) {
        return theme.colors.crail;
      }

      return theme.colors.textLight;
    }}
  `;

const Rule = styled.li<{ isAchieved: boolean }>`
  display: flex;
  align-items: center;
  line-height: 1.4;
  font-size: ${({ theme }) => theme.fontSizes.dbSmallMd};
  color: ${({ isAchieved }) => getRuleColor(isAchieved)};

  &:not(:last-child) {
    margin-bottom: ${rem(5)};
  }
`;

const Icon = styled(IconCheckmarkSec)`
  flex-shrink: 0;
  ${size(rem(12), rem(8))};
  display: inline-block;
  margin-right: ${rem(4)};
`;

interface Props {
  password?: string;
}

export default function PasswordRules({ password }: Props) {
  return (
    <>
      <GenericText isTextMedium>
        <FormattedMessage id="registration.validation.headerPassword" />
      </GenericText>

      <RulesList>
        {rules.map(({ translation, check, renderTooltip }) => (
          <Rule key={translation} isAchieved={password ? check(password) : false}>
            <Icon />
            <FormattedMessage id={translation} tagName="span" />
            {renderTooltip && <GenericTooltip text={renderTooltip()} hasAccentTheme isSmall />}
          </Rule>
        ))}
      </RulesList>
    </>
  );
}
