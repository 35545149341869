import accountSettings from './accountSettings';
import adviceRooms from './adviceRooms';
import adviser from './adviser';
import agendaTemplate from './agendaTemplate';
import auditTrail from './auditTrail';
import blog from './blog';
import breadcrumb from './breadcrumb';
import brochureLibrary from './brochureLibrary';
import cases from './cases';
import client from './client';
import clientIdentification from './clientIdentification';
import clientMailingList from './clientMailingList';
import cookies from './cookies';
import dashboard from './dashboard';
import documentVault from './documentVault';
import documentViewer from './documentViewer';
import errors from './errors';
import estateSummary from './estateSummary';
import factFind from './fact-find';
import faq from './faq';
import financialAudit from './financialAudit';
import financialPlanningGoals from './financialPlanningGoals';
import financialPlanningHealthCheck from './financialPlanningHealthCheck';
import financialPlanningTopics from './financialPlanningTopics';
import followingClients from './followingClients';
import howItWorks from './howItWorks';
import howWeCommunicate from './howWeCommunicate';
import identityVerification from './identityVerification';
import interactionReports from './interactionReports';
import investment from './investment';
import landing from './landing';
import meetingRequest from './meetingRequest';
import messages from './messages';
import motivationCentre from './motivationCentre';
import nav from './nav';
import notification from './notification';
import notifications from './notifications';
import onboarding from './onboarding';
import onlineAdviceRequest from './onlineAdviceRequest';
import practiceDirector from './practiceDirector';
import practiceSettings from './practiceSettings';
import registration from './registration';
import riskAssessment from './riskAssessment';
import securityStatement from './securityStatement';
import serviceCharters from './serviceCharters';
import shared from './shared';
// import terms from './terms';
import topUp from './topUp';
import usageReports from './usageReports';
import video from './video';
import wysiwygEditor from './wysiwygEditor';

export default {
  accountSettings,
  adviceRooms,
  adviser,
  auditTrail,
  blog,
  agendaTemplate,
  breadcrumb,
  brochureLibrary,
  cases,
  client,
  clientIdentification,
  clientMailingList,
  cookies,
  dashboard,
  documentVault,
  errors,
  estateSummary,
  factFind,
  faq,
  financialAudit,
  financialPlanningHealthCheck,
  financialPlanningGoals,
  financialPlanningTopics,
  followingClients,
  howItWorks,
  howWeCommunicate,
  identityVerification,
  interactionReports,
  investment,
  landing,
  meetingRequest,
  messages,
  motivationCentre,
  nav,
  notification,
  notifications,
  onboarding,
  onlineAdviceRequest,
  practiceDirector,
  practiceSettings,
  registration,
  riskAssessment,
  securityStatement,
  serviceCharters,
  shared,
  // Terms page was disabled as a part of https://netguru.atlassian.net/browse/SOV-3629
  // terms,
  topUp,
  usageReports,
  video,
  wysiwygEditor,
  documentViewer,
};
