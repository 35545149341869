import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';

export interface ClientsLetterOfAuthorityApiOptions {
  clientLoaAgreement?: boolean;
  partnerLoaAgreement?: boolean;
  primarySignature?: string;
  secondarySignature?: string;
}

export function clientsLetterOfAuthorityApi(path: string) {
  return {
    path,
    PATCH: ({
      clientLoaAgreement,
      partnerLoaAgreement,
      primarySignature,
      secondarySignature,
    }: ClientsLetterOfAuthorityApiOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: {
            client_loa_agreement: clientLoaAgreement,
            partner_loa_agreement: partnerLoaAgreement,
          },
          relationships: {
            user: {
              data: {
                signature: primarySignature,
                secondary_signature: secondarySignature,
              },
            },
          },
        },
      }),
  };
}
