import type { AnyAction } from 'redux';

import { SIDEBAR_COLLAPSED_STORAGE_KEY } from 'constants/layout';
import { getSessionStorageValue } from 'helpers/sessionStorage';
import { isBrowser } from 'helpers/utils';

import { RESTORE_PREV_SIDEBAR_STATE, TOGGLE_SIDEBAR } from './actionTypes';
import type { LayoutActionTypes, LayoutState } from './types';

export const initialState: LayoutState = {
  isSidebarCollapsed: isBrowser()
    ? getSessionStorageValue(SIDEBAR_COLLAPSED_STORAGE_KEY) || false
    : false,
  prevState: undefined,
};

export default (state = initialState, incomingAction: AnyAction): LayoutState => {
  const action = incomingAction as LayoutActionTypes;

  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        isSidebarCollapsed: action.payload.isCollapsed,
        prevState: action.payload.prevState,
      };
    case RESTORE_PREV_SIDEBAR_STATE:
      return {
        ...state,
        isSidebarCollapsed:
          state.prevState !== undefined ? state.prevState : state.isSidebarCollapsed,
        prevState: undefined,
      };
    default:
      return state;
  }
};
