export default {
  title: `Usage reports`,
  tileDescription: `Download reports about usage of this site.`,
  pageDescription: `Download reports that contain high-level information on usage of this site. This information might be useful to give you an overview as to how the site was used over a given period.{br}{br}Note: More report types will be added in future.`,
  meetingReport: {
    title: `Meeting report`,
    description: `Summary report about Client Meeting Rooms hosted.`,
  },
  recommendationReport: {
    title: `Recommendation report`,
    description: `Summary report about Recommendation Packs created.`,
  },
};
