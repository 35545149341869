import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api';

import { clientClientMessageDestroyApi } from './destroy';

export function clientClientMessageApi(path: string) {
  return {
    path,
    GET: () =>
      api.get<JsonApiResponseSingle>(path, {
        params: {
          include: 'creator',
        },
      }),
    destroy: () => clientClientMessageDestroyApi(path),
  };
}
