import React from 'react';

import SvgIcon from './SvgIcon';

const IconDownload = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 9 12" {...props}>
    <path d="M4.5 0v10m-4-4l4 4 4-4" stroke="currentColor" fill="none" />
    <path d="M0 11h9v1H0z" fill="currentColor" />
  </SvgIcon>
);

export default IconDownload;
