import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

import { documentPackDocumentMetadataApi } from './metadata';
import { documentPackDocumentReorderApi } from './reorder';
import { documentPackDocumentRevertApi } from './revert';
import { documentPackDocumentViewApi } from './view';

export interface DocumentPackDocumentPatchOptions {
  name?: string;
  note?: string;
  signatureRequired?: boolean;
  docusignSignatureRequired?: boolean;
  shouldSendSignatureReminder?: boolean;
  autoEmailEnabled?: boolean;
  postCode?: string;
  phone?: string;
  recipientIdentityVerificationType?: string;
  recipientIdentityVerificationName?: string;
}

export function documentPackDocumentApi(path: string) {
  return {
    path,
    metadata: () => documentPackDocumentMetadataApi(`${path}/metadata`),
    reorder: () => documentPackDocumentReorderApi(`${path}/reorder`),
    revert: () => documentPackDocumentRevertApi(`${path}/revert`),
    view: () => documentPackDocumentViewApi(`${path}/view`),
    PATCH: ({ note, ...rest }: DocumentPackDocumentPatchOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: {
            note_for_client: note,
            ...mapFormData(rest),
          },
        },
      }),
    DELETE: () => api.delete(path),
  };
}
