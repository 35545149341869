export default {
  generic: {
    add: `Add`,
    save: `Save`,
    cancel: `Cancel`,
  },
  currency: {
    dollar: `Dollar`,
    euro: `Euro`,
    yen: `Yen`,
    pound: `Pound`,
  },
  symbols: {
    title: `Symbols`,
  },
  fontSize: {
    title: `Font Size`,
  },
  inline: {
    bold: `Bold`,
    italic: `Italic`,
    underline: `Underline`,
    strikethrough: `Strikethrough`,
    code: `Code`,
    superscript: `Superscript`,
    subscript: `Subscript`,
  },
  textAlign: {
    left: `Left`,
    center: `Center`,
    right: `Right`,
    justify: `Justify`,
  },
  list: {
    unordered: `Unordered`,
    ordered: `Ordered`,
    indent: `Indent`,
    outdent: `Outdent`,
  },
  link: {
    linkTitle: `Link Title`,
    linkTarget: `Link Target`,
    openInNewWindow: `Open link in new window`,
    link: `Link`,
    unlink: `Unlink`,
  },
  image: {
    title: `Image`,
    src: `Url`,
    width: `Width`,
    height: `Height`,
    autoPlaceholder: `(auto)`,
  },
  history: {
    undo: `Undo`,
    redo: `Redo`,
  },
  remove: `Remove`,
};
