import {
  Client as TwilioSyncClient,
  SyncDocument,
  SyncStream,
  SyncStreamMessage,
} from 'twilio-sync';

import { guests, sync } from 'api';
import { TOKEN_ABOUT_TO_EXPIRE } from 'constants/twilio';
import { getAppStore } from 'helpers/utils';
import type { SharedState } from 'store/documentSharing/types';
import { getGuestAuthToken } from 'store/guests/selectors';
import { getCurrentPractice } from 'store/practices/selectors';
import { getCurrentUser } from 'store/users/selectors';

let cachedClientPromise: Promise<TwilioSyncClient> | null = null;
let cachedDocuments: Record<string, Promise<SyncDocument> | undefined> = {};
let cachedStreams: Record<string, Promise<SyncStream> | undefined> = {};

const getToken = async () => {
  const isLoggedIn = getCurrentUser(getAppStore().getState());
  const guestAuthToken = getGuestAuthToken(getAppStore().getState());

  if (!isLoggedIn && guestAuthToken) {
    const {
      data: { token },
    } = await guests().guestSyncToken().POST({ authToken: guestAuthToken });

    return token;
  }

  const {
    data: { token },
  } = await sync().tokens().POST();

  return token;
};

const createClient = async () => new TwilioSyncClient(await getToken());

export const getTwilioSyncClient = () => {
  if (cachedClientPromise) {
    return cachedClientPromise;
  }

  cachedClientPromise = createClient();

  cachedClientPromise.then(syncClient => {
    syncClient.addListener(TOKEN_ABOUT_TO_EXPIRE, async () => {
      syncClient.updateToken(await getToken());
    });
  });

  return cachedClientPromise;
};

export const getTwilioSyncDocument = async <T extends SyncDocument>(documentId: string) => {
  const cachedDocument = cachedDocuments[documentId];
  if (cachedDocument) {
    return cachedDocument as Promise<T>;
  }

  const syncClient = await getTwilioSyncClient();

  const createdDocumentPromise = syncClient.document(documentId);
  cachedDocuments = {
    ...cachedDocuments,
    [documentId]: createdDocumentPromise,
  };

  return createdDocumentPromise as Promise<T>;
};

export const getTwilioSyncStream = async (streamId: string) => {
  const cachedStream = cachedStreams[streamId];
  if (cachedStream) {
    return cachedStream;
  }

  const syncClient = await getTwilioSyncClient();

  const createdStreamPromise = syncClient.stream(streamId);
  cachedStreams = {
    ...cachedStreams,
    [streamId]: createdStreamPromise,
  };

  return createdStreamPromise;
};

export interface SharedDocument extends SyncDocument {
  data: SharedState;
}

interface SketchpadState {
  data: string;
}

export interface SketchpadDocument extends SyncDocument {
  data: SketchpadState;
}

export type SharedDocumentMessage = TwilioDocumentMessage<SharedState>;

const getCurrentPracticeId = () => getCurrentPractice(getAppStore().getState()).id;

export const getAdviceRoomSyncDocument = (adviceRoomId: number) =>
  getTwilioSyncDocument<SharedDocument>(`AdviceRoom_${getCurrentPracticeId()}_${adviceRoomId}`);

export const getAdviceRoomSketchpadSyncListId = (adviceRoomId: number) =>
  `AdviceRoom_Sketchpad_${getCurrentPracticeId()}_${adviceRoomId}`;

export const getAdviceRoomSyncStream = (adviceRoomId: number) =>
  getTwilioSyncStream(`AdviceRoom_${getCurrentPracticeId()}_${adviceRoomId}`);

export const getUserSyncStream = (userId: number) =>
  getTwilioSyncStream(`User_${getCurrentPracticeId()}_${userId}`);

export const getDocumentHighlightSyncStream = (documentId: number | string) =>
  getTwilioSyncStream(`DocumentHighlight_${getCurrentPracticeId()}_${documentId}`);

export const getClientMessagesSyncStream = (clientId: number | string) =>
  getTwilioSyncStream(`ClientMessages_${getCurrentPracticeId()}_${clientId}`);

export const getPracticeMessagesSyncStream = (clientId: number | string) =>
  getTwilioSyncStream(`PracticeMessages_${getCurrentPracticeId()}_${clientId}`);

export interface TwilioStreamMessage<T> {
  isLocal: boolean;
  message: SyncStreamMessage & {
    data: T;
  };
}

export interface TwilioDocumentMessage<T> {
  isLocal: boolean;
  data: T;
}

export interface MessageData<TType extends string, TPayload> {
  type: TType;
  payload: TPayload;
}

export interface UserMessage<TType extends string, TPayload> extends MessageData<TType, TPayload> {
  senderId: number;
  senderName: string;
}

export interface AttendeeMessage<TType extends string, TPayload>
  extends UserMessage<TType, TPayload> {
  adviceRoomId: number;
  adviceRoomName: string;
}
