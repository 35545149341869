import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import FormActionResponsiveButtons from 'components/FormActionButtons/FormActionResponsiveButtons';
import { GenericTitle, TitleMargin } from 'components/GenericTitle/GenericTitle';
import GenericTitleSummary from 'components/GenericTitleSummary/GenericTitleSummary';
import { GenericTooltip, TooltipPlacement } from 'components/GenericTooltip';

import { Fields, StepProps } from '../../types';
import YesNoButtons from '../shared/YesNoButtons';

const StepHighInterestDebt: FC<StepProps> = ({ values }) => (
  <>
    <GenericTitle marginBottom={TitleMargin.Sm} isMedium isBlock>
      <FormattedMessage id="onboarding.stepHighInterestDebt.title" tagName="span" />
      <GenericTooltip
        text={<FormattedMessage id="onboarding.stepHighInterestDebt.titleTooltip" />}
        position={TooltipPlacement.Bottom}
        hasNoMargin
      />
    </GenericTitle>

    <GenericTitleSummary hasMarginBottomLg>
      <FormattedMessage id="onboarding.stepHighInterestDebt.summary" />
    </GenericTitleSummary>

    <YesNoButtons name={Fields.HighInterestDebt} />

    <FormActionResponsiveButtons
      submitDisabled={values[Fields.HighInterestDebt] === undefined}
      hasUnderline
    />
  </>
);

export default StepHighInterestDebt;
