import { useSelector } from 'react-redux';

import EntityPath from 'constants/entitiesPaths';
import type { AdviceRoomParticipant } from 'types/entities/adviceRoomParticipant';

import { createEntitySelectors } from '../entities/selectors';
import type { AppState } from '../types';

const { getById: getUserParticipant } = createEntitySelectors<AdviceRoomParticipant>(
  EntityPath.UserParticipants
);

const { getById: getGuestParticipant } = createEntitySelectors<AdviceRoomParticipant>(
  EntityPath.GuestParticipants
);

export const getParticipant = (state: AppState, id: number, isGuest: boolean) =>
  isGuest ? getGuestParticipant(state, id) : getUserParticipant(state, id);

export const useGetParticipant = (id: number, isGuest: boolean) =>
  useSelector((state: AppState) => getParticipant(state, id, isGuest));
