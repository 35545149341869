import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

export interface ClientCaseDocumentReorderPatchOptions {
  newPosition: number;
}

export function clientCaseDocumentReorderApi(path: string) {
  return {
    path,
    PATCH: (params: ClientCaseDocumentReorderPatchOptions) =>
      api.patch<JsonApiResponse>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
