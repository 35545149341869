import type { RailsContext } from 'react-on-rails';
import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';
import normalize from 'json-api-normalizer';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { setCurrencies } from 'helpers/currencies';
import { mergeEntities } from 'helpers/store';
import type { Currency } from 'types/entities/currency';

import { setBlurBackgroundAsset } from '../helpers/blurBackgroundAsset';
import { setIpAddress } from '../helpers/ipAddress';
import { setContext } from '../helpers/railsContext';
import { setSessionTimeout } from '../helpers/sessionTimeout';
import rootReducer from './rootReducer';
import type { AppState } from './types';

const middlewares = [thunk];

const enhancer = composeWithDevTools(applyMiddleware(...middlewares));

interface RailsProps {
  _adviserPayload: JsonApiResponseSingle;
  _advisersPayload: JsonApiResponse;
  _clientPayload: JsonApiResponseSingle;
  _currentUserPayload: JsonApiResponseSingle;
  _practiceAdminPayload: JsonApiResponseSingle;
  _practiceDirectorPayload: JsonApiResponseSingle;
  _practicePayload: JsonApiResponseSingle;
  _practiceSettingsPayload: JsonApiResponseSingle;
  _serviceChartersPayload: JsonApiResponse;
  _sessionTimeoutPayload: number;
  _guestAuthToken: string | undefined | null;
  _guestId: number | null;
  _ipAddress: string;
  _canRemoveClients: boolean;
  _currencies: Currency[];
  _oauthAccountsPayload: JsonApiResponse;
  _blurBackgroundAssetUrl: string;
  _awsRegion: string;
}

export default (railsProps: RailsProps, railsContext: RailsContext) => {
  setContext(railsContext);

  const {
    _adviserPayload,
    _advisersPayload,
    _clientPayload,
    _currentUserPayload,
    _practiceAdminPayload,
    _practiceDirectorPayload,
    _practicePayload,
    _practiceSettingsPayload,
    _serviceChartersPayload,
    _sessionTimeoutPayload,
    _guestAuthToken,
    _guestId,
    _ipAddress,
    _canRemoveClients,
    _currencies,
    _oauthAccountsPayload,
    _blurBackgroundAssetUrl,
    _awsRegion,
  } = railsProps;

  let preloadedState: Partial<AppState> & { entities: AppState['entities'] } = { entities: {} };

  function mergePreloadedEntities(...args: Parameters<typeof normalize>) {
    return {
      ...preloadedState,
      entities: mergeEntities(preloadedState.entities, normalize(...args)),
    };
  }

  if (_currentUserPayload && _currentUserPayload.data) {
    preloadedState = {
      ...mergePreloadedEntities(_currentUserPayload),
      currentUser: {
        ...preloadedState.currentUser,
        id: Number(_currentUserPayload.data.id),
      },
    };
  }

  if (_clientPayload) {
    preloadedState = {
      ...mergePreloadedEntities(_clientPayload),
      currentClientId: Number(_clientPayload.data.id),
    };
  }

  if (_sessionTimeoutPayload) {
    setSessionTimeout(_sessionTimeoutPayload);
  }

  [
    _adviserPayload,
    _advisersPayload,
    _practiceAdminPayload,
    _practiceDirectorPayload,
    _practicePayload,
    _practiceSettingsPayload,
    _serviceChartersPayload,
    _oauthAccountsPayload,
  ].forEach(payload => {
    if (payload) {
      preloadedState = mergePreloadedEntities(payload);
    }
  });

  if (_guestAuthToken || _guestId) {
    preloadedState = {
      ...preloadedState,
      guests: {
        ...preloadedState.guests,
        id: _guestId || null,
        authToken: _guestAuthToken || null,
      },
    };
  }

  if (_ipAddress) {
    setIpAddress(_ipAddress);
  }

  if (_currencies) {
    setCurrencies(_currencies);
  }

  if (_blurBackgroundAssetUrl) {
    setBlurBackgroundAsset(_blurBackgroundAssetUrl);
  }

  preloadedState = {
    ...preloadedState,
    website: {
      awsRegion: _awsRegion,
    },
    permissions: {
      ...preloadedState.permissions,
      canRemoveClients: _canRemoveClients,
    },
  };

  return createStore(rootReducer, preloadedState, enhancer);
};
