import React, { ReactElement } from 'react';
import { darken, position, rem, size as cssSize } from 'polished';
import styled, { css } from 'styled-components';

import { circle, flexCenter } from 'helpers/styles/mixins';
import { isNotBlank } from 'helpers/utils';
import IconAvatarPlaceholder from 'components/Icons/IconAvatarPlaceholder';

import { AvatarSize } from './avatarSize';

const Wrapper = styled.div`
  ${flexCenter()};
`;

const RelativeWrapper = styled.div`
  position: relative;
`;

const AvatarIcon = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
`;

const AvatarIconSecondary = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
`;

const AvatarImage = styled.img.attrs({
  'data-test': 'avatar',
})`
  ${cssSize('100%')};
  display: block;
  object-fit: cover;
`;

const ActiveIndicator = styled.div`
  ${circle('auto')};
  ${position('absolute', rem(-3), rem(-3), rem(-3), rem(-3))};
  border: 1px solid ${({ theme }) => darken(0.1, theme.colors.fountainBlue)};
`;

interface AvatarProps {
  avatarSize?: AvatarSize;
  hasImage?: boolean;
  className?: string;
}

const StyledAvatar = styled.div<AvatarProps>`
  ${({ avatarSize }) => circle(rem(avatarSize || AvatarSize.L))};
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ hasImage }) =>
    !hasImage &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.greyLighten};
    `};
`;

interface Props {
  id?: string;
  alt?: string;
  src?: string;
  isActive?: boolean;
  avatarIcon?: ReactElement | null;
  avatarIconSecondary?: ReactElement;
  size?: AvatarSize;
  className?: string;
}

const Avatar = ({
  alt,
  className,
  src,
  avatarIcon,
  avatarIconSecondary,
  id,
  size: avatarSize = AvatarSize.L,
  isActive = false,
}: Props) => {
  const hasImage = isNotBlank(src);

  const image = hasImage ? (
    <AvatarImage src={src} alt={alt} />
  ) : (
    <IconAvatarPlaceholder data-test="avatar-placeholder" />
  );

  return (
    <Wrapper id={id}>
      <RelativeWrapper>
        <StyledAvatar className={className} avatarSize={avatarSize} hasImage={hasImage}>
          {image}
        </StyledAvatar>

        {avatarIcon && <AvatarIcon>{avatarIcon}</AvatarIcon>}

        {isActive && <ActiveIndicator />}

        {avatarIconSecondary && <AvatarIconSecondary>{avatarIconSecondary}</AvatarIconSecondary>}
      </RelativeWrapper>
    </Wrapper>
  );
};

export default Avatar;
