import type { AnyAction } from 'redux';

import { CHANGE_MESSAGES_WINDOW_STATE } from './actionTypes';
import { MessagesActionTypes, MessagesState, MessagesStates } from './types';

export const initialState: MessagesState = {
  messagesState: MessagesStates.MessagesClosed,
};

export default (state = initialState, incomingAction: AnyAction): MessagesState => {
  const action = incomingAction as MessagesActionTypes;

  switch (action.type) {
    case CHANGE_MESSAGES_WINDOW_STATE:
      return {
        ...state,
        messagesState: action.messagesState,
      };

    default:
      return state;
  }
};
