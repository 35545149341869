import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

import { clientCaseAddendumMetadataApi } from './metadata';
import { clientCaseAddendumReorderApi } from './reorder';
import { clientCaseAddendumRevertApi } from './revert';
import { clientCaseAddendumViewApi } from './view';

export interface ClientCaseAddendumPatchOptions {
  name?: string;
  signatureRequired?: boolean;
  docusignSignatureRequired?: boolean;
  shouldSendSignatureReminder?: boolean;
  autoEmailEnabled?: boolean;
  noteForClient?: string;
  phone?: string;
  postCode?: string;
  recipientIdentityVerificationType?: string;
  recipientIdentityVerificationName?: string;
}

export function clientCaseAddendumApi(path: string) {
  return {
    path,
    metadata: () => clientCaseAddendumMetadataApi(`${path}/metadata`),
    reorder: () => clientCaseAddendumReorderApi(`${path}/reorder`),
    revert: () => clientCaseAddendumRevertApi(`${path}/revert`),
    view: () => clientCaseAddendumViewApi(`${path}/view`),
    PATCH: (params: ClientCaseAddendumPatchOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
    DELETE: () => api.delete<JsonApiResponseSingle>(path),
  };
}
