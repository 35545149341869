import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

import { clientCaseDocumentReorderApi } from './reorder';
import { clientCaseDocumentViewApi } from './view';

export interface ClientCaseDocumentPatchOptions {
  name?: string;
}

export function clientCaseDocumentApi(path: string) {
  return {
    path,
    reorder: () => clientCaseDocumentReorderApi(`${path}/reorder`),
    view: () => clientCaseDocumentViewApi(`${path}/view`),
    PATCH: (params: ClientCaseDocumentPatchOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
    DELETE: () => api.delete(path),
  };
}
