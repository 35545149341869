import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

import { clientDashboardLinkReorderApi } from './reorder';

export interface ClientDashboardLinksPatchOptions {
  title?: string;
  description?: string;
  link?: string;
  image?: File;
}

export function clientDashboardLinkApi(path: string) {
  return {
    path,
    reorder: () => clientDashboardLinkReorderApi(`${path}/reorder`),
    PATCH: (values: ClientDashboardLinksPatchOptions) =>
      api.patch<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(values),
          },
        })
      ),
    DELETE: () => api.delete<JsonApiResponse>(path),
  };
}
