import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';
import type { PermissionLevel } from 'types/entities/practiceAdmin';

export interface ChangePermissionLevelOptions {
  permissionLevel: PermissionLevel;
}

export function practiceAdminChangePermissionLevelApi(path: string) {
  return {
    path,
    PATCH: (params: ChangePermissionLevelOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
