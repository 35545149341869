import React, { useRef, useState } from 'react';
import Tippy, { TippyProps } from '@tippyjs/react/headless';
import { rem, triangle } from 'polished';
import { clearTimeout, setTimeout, Timeout } from 'safe-timers';
import styled, { css } from 'styled-components';

import { misc } from 'helpers/styles/constants';
import { useGenericModalContext } from 'components/GenericModal/GenericModalContext';

import TooltipPlacement from './TooltipPlacement';

const transitionTime = 300;
const delayTime = 150;

const Arrow = styled.div`
  z-index: -1;
  position: absolute;
`;

interface TooltipProps {
  hasBigBox: boolean;
  hasAccentTheme: boolean;
  opacity: number;
  arrowSize: number;
  borderRadius?: number;
  padding?: [number, number];
  maxWidth?: 'max-content' | number;
}

const Tooltip = styled.div<TooltipProps>`
  padding: ${({ padding }) =>
    padding ? `${rem(padding[0])} ${rem(padding[1])}` : `${rem(12)} ${rem(18)}`};
  font-family: ${({ theme }) => theme.fontFamilies.main};
  font-size: ${({ theme }) => theme.fontSizes.dbSmall};
  font-weight: ${({ theme }) => theme.fontWeights.mainNormal};
  line-height: 1.5;
  border-radius: ${({ borderRadius }) => (borderRadius ? rem(borderRadius) : 0)};
  color: ${({ theme }) => theme.colors.white};
  word-break: break-word;
  word-wrap: break-word;

  ${({ maxWidth }) => {
    if (maxWidth) {
      return css`
        max-width: ${typeof maxWidth === 'number' ? rem(maxWidth) : maxWidth};
      `;
    }

    return css`
      max-width: ${rem(310)};
    `;
  }};

  ${({ hasBigBox }) =>
    hasBigBox &&
    css`
      max-width: ${rem(400)};
      padding: ${rem(40)} ${rem(35)};
      font-size: ${rem(16)};
    `};

  ${({ hasAccentTheme, opacity, theme, arrowSize }) => {
    const color = hasAccentTheme ? theme.colors.accent : theme.colors.main;

    return css`
      background-color: ${color};

      &,
      ${Arrow} {
        opacity: ${opacity};
        transition: ${transitionTime}ms ease opacity;
      }

      &[data-placement^='top'] > ${Arrow} {
        bottom: ${rem(-arrowSize / 2)};
        ${triangle({
          pointingDirection: 'bottom',
          width: rem(arrowSize),
          height: rem(arrowSize / 2),
          foregroundColor: color,
        })}
      }

      &[data-placement^='bottom'] > ${Arrow} {
        top: ${rem(-arrowSize / 2)};
        ${triangle({
          pointingDirection: 'top',
          width: rem(arrowSize),
          height: rem(arrowSize / 2),
          foregroundColor: color,
        })}
      }

      &[data-placement^='left'] > ${Arrow} {
        right: ${rem(-arrowSize / 2)};
        ${triangle({
          pointingDirection: 'right',
          width: rem(arrowSize / 2),
          height: rem(arrowSize),
          foregroundColor: color,
        })}
      }

      &[data-placement^='right'] > ${Arrow} {
        left: ${rem(-arrowSize / 2)};
        ${triangle({
          pointingDirection: 'left',
          width: rem(arrowSize / 2),
          height: rem(arrowSize),
          foregroundColor: color,
        })}
      }
    `;
  }};

  ul {
    padding-left: ${rem(20)};
    list-style-type: disc;
  }
`;

export interface Props {
  tooltipMessage: React.ReactNode;
  tooltipArrowSize?: number;
  tooltipBorderRadius?: number;
  tooltipPadding?: [number, number];
  tooltipMaxWidth?: 'max-content' | number;
  position?: TooltipPlacement;
  hasBigBox?: boolean;
  hasAccentTheme?: boolean;
  disabled?: boolean;
  showDelay?: number;
  trigger?: TippyProps['trigger'];
  children: TippyProps['children'];
  appendTo?: TippyProps['appendTo'];
  visible?: TippyProps['visible'];
  zIndex?: number;
}

const BasicTooltip = ({
  hasBigBox = false,
  hasAccentTheme = false,
  trigger = 'mouseenter',
  showDelay,
  position,
  tooltipMessage,
  tooltipArrowSize,
  tooltipBorderRadius,
  tooltipMaxWidth,
  tooltipPadding,
  children,
  disabled,
  appendTo,
  visible,
  zIndex,
}: Props) => {
  const { isInsideModal } = useGenericModalContext();
  const [opacity, setOpacity] = useState(0);
  const timeoutRef = useRef<Timeout>();

  let finalZindex = zIndex;
  if (zIndex === undefined) {
    finalZindex = isInsideModal ? misc.modalZIndex + 1 : misc.tooltipZIndex;
  }

  return (
    <Tippy
      offset={tooltipArrowSize ? [0, tooltipArrowSize / 2] : [0, 10]}
      zIndex={finalZindex}
      disabled={disabled}
      placement={position || TooltipPlacement.Top}
      {...(visible != null ? { visible } : { trigger })}
      {...(appendTo ? { appendTo } : {})} // passing explicit undefined breaks some tooltips
      animation
      onMount={() => {
        setOpacity(1);
      }}
      onShow={() => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      }}
      onHide={({ unmount }) => {
        setOpacity(0);

        timeoutRef.current = setTimeout(() => {
          timeoutRef.current = undefined;
          unmount();
        }, transitionTime + delayTime);
      }}
      delay={[showDelay || null, delayTime]}
      render={attrs => (
        <Tooltip
          {...attrs}
          arrowSize={tooltipArrowSize || 16}
          borderRadius={tooltipBorderRadius}
          maxWidth={tooltipMaxWidth}
          padding={tooltipPadding}
          opacity={opacity}
          hasBigBox={hasBigBox}
          hasAccentTheme={hasAccentTheme}
        >
          {tooltipMessage}
          <Arrow data-popper-arrow />
        </Tooltip>
      )}
    >
      {children}
    </Tippy>
  );
};

export default BasicTooltip;
