import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import { breakpoints } from 'helpers/styles/constants';

import { ImageWrapper, Label } from './PreviewImage';

const InputLabel = styled.label`
  margin-top: 0.5em;
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.fountainBlue};
  cursor: pointer;

  @media ${breakpoints.mdDown} {
    margin-bottom: 0.5em;
    font-size: 0.7rem;
  }
`;

const FileInput = styled.input`
  display: none;
`;

const LoadWrapper = styled(ImageWrapper)`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  height: 170px;
  border-radius: 4px;
  border: 2px solid transparent;

  @media ${breakpoints.mdDown} {
    height: 140px;
  }
`;

export const LoadContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  text-align: center;
  background: ${({ theme }) => theme.colors.greyLighten};

  @media ${breakpoints.mdDown} {
    padding: 0.5rem;
  }
`;

const Text = styled.label`
  font-size: 0.8rem;

  @media ${breakpoints.smDown} {
    font-size: 0.65rem;
  }
`;

interface Props {
  name: string;
  uploadImage: (event: ChangeEvent<HTMLInputElement>) => void;
}
const VirtualBackgroundUploader = ({ name, uploadImage }: Props) => {
  return (
    <LoadWrapper>
      <LoadContainer>
        <Text>CMR background not added</Text>
        <FileInput type="file" id={name} onChange={uploadImage} />
        <InputLabel htmlFor={name}>Add an image now</InputLabel>
      </LoadContainer>
      <Label>CMR background</Label>
    </LoadWrapper>
  );
};

export default VirtualBackgroundUploader;
