import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, PaginationOptions, RequestConfig } from 'api/types';
import { mapFormData } from 'helpers/formData';

export type ClientMessagesInteractionReportsGetOptions = GetOptions & PaginationOptions;

export interface ClientMessagesInteractionReportsPostOptions {
  startDate: string;
  endDate: string;
}

export function clientMessagesInteractionReportsApi(path: string) {
  return {
    path,
    GET: (
      { pageSize, pageNumber, ...rest }: ClientMessagesInteractionReportsGetOptions,
      config?: RequestConfig
    ) =>
      api.get<JsonApiResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...rest,
        },
        ...config,
      }),
    POST: (params: ClientMessagesInteractionReportsPostOptions) =>
      api.post(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
