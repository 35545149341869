import React from 'react';

import SvgIcon from '../SvgIcon';

const IconMicrophone = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 14 22" {...props}>
    <g fill="currentColor">
      <path d="M7 15.2a3.6 3.6 0 0 0 2.75-1.24 4.25 4.25 0 0 0 1.14-2.98V4.22c0-1.16-.38-2.15-1.14-2.98A3.6 3.6 0 0 0 7 0a3.6 3.6 0 0 0-2.74 1.24A4.25 4.25 0 0 0 3.1 4.22v6.76c0 1.16.38 2.15 1.15 2.98A3.6 3.6 0 0 0 7 15.2z" />
      <path d="M13.77 8.7a.72.72 0 0 0-1.1 0 .85.85 0 0 0-.23.59v1.69c0 1.63-.53 3.02-1.6 4.18A5.04 5.04 0 0 1 7 16.89c-1.5 0-2.78-.58-3.84-1.73a5.95 5.95 0 0 1-1.6-4.18V9.29a.85.85 0 0 0-.23-.6.72.72 0 0 0-1.1 0 .85.85 0 0 0-.23.6v1.69c0 1.94.6 3.63 1.8 5.07a6.62 6.62 0 0 0 4.42 2.48v1.74h-3.1c-.22 0-.4.08-.55.25a.85.85 0 0 0-.23.6c0 .22.07.42.23.59.15.16.33.25.54.25h7.78c.21 0 .4-.09.55-.25a.85.85 0 0 0 .23-.6.85.85 0 0 0-.23-.6.72.72 0 0 0-.55-.24H7.78v-1.74a6.62 6.62 0 0 0 4.43-2.48A7.7 7.7 0 0 0 14 10.98V9.29a.85.85 0 0 0-.23-.6z" />
    </g>
  </SvgIcon>
);

export default IconMicrophone;
