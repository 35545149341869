import { rem } from 'polished';
import styled from 'styled-components';

import { flexCenter } from 'helpers/styles/mixins';

export const ModalButtons = styled.div`
  ${flexCenter()};

  & > *:not(:first-child) {
    margin-left: ${rem(20)};
  }
`;
