export default {
  homepage: `Homepage`,
  howItWorks: `How it works`,
  howWeCommunicate: `How we communicate`,
  securityStatement: `Digital Wealth Solutions Security Statement`,
  ourAdvisers: `Our Advisers`,
  privacyPolicy: `Privacy Policy`,
  faq: `FAQ`,
  contact: `Contact`,
  insights: `Insights`,
  stJamessPlace: `St James’s Place`,
  terms: `Terms`,
  signUp: `Request a meeting`,
  signIn: `Sign in`,
  signOut: `Sign out`,
  dashboard: `Dashboard`,
};
