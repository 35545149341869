import React, { FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { rem, size } from 'polished';
import styled from 'styled-components';

import { users } from 'api';
import { breakpoints } from 'helpers/styles/constants';
import { showApiErrorToast, showInfoToast } from 'helpers/toasts';
import { required } from 'helpers/validators';
import FormActionButtons from 'components/FormActionButtons/FormActionButtons';
import { Field } from 'components/Forms';
import GenericInput from 'components/GenericInput/GenericInput';
import { GenericTitle, TitleMargin } from 'components/GenericTitle/GenericTitle';
import GenericTitleSummary from 'components/GenericTitleSummary/GenericTitleSummary';
import IconLetter from 'components/Icons/IconLetter';

import { Fields, StepProps } from '../../types';

const EmailInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${rem(50)};
  margin-bottom: ${rem(45)};
`;

const EmailInfoIconWrapper = styled.div`
  ${size(rem(46))};
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: ${rem(3)};
`;

const EmailInfoContent = styled.div`
  margin-top: ${rem(15)};

  @media ${breakpoints.xsUp} {
    margin-top: 0;
    margin-left: ${rem(30)};
  }
`;

const EmailInfoTitle = styled.h5`
  margin-top: 0;
  margin-bottom: ${rem(10)};
  font-size: ${({ theme }) => theme.fontSizes.dbNormal};
  font-weight: ${({ theme }) => theme.fontWeights.mainNormal};
  color: ${({ theme }) => theme.colors.textLight};
`;

const EmailInfoLabel = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.fontSizes.dbMedium};
`;

const EmailInfoButton = styled.button.attrs({
  type: 'button',
})`
  color: ${({ theme }) => theme.colors.textLight};

  &:hover {
    text-decoration: underline;
  }

  &:not(:last-child) {
    margin-right: ${rem(20)};
  }
`;

const EmailInfoButtons = styled.div`
  margin-top: ${rem(50)};
`;

const StepResendEmail: FC<StepProps> = ({ form, values }) => {
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const email = values[Fields.Email];
  const newEmail = values[Fields.NewEmail];

  const onClickResend = useCallback(async () => {
    setIsSending(true);

    try {
      await users().confirmation().POST({ email, newEmail });

      showInfoToast('notification.messages.resendEmail');
    } catch (error) {
      showApiErrorToast(error);
    }

    setIsSending(false);
  }, [email, newEmail]);

  return (
    <>
      <GenericTitle marginBottom={TitleMargin.Sm}>
        <FormattedMessage id="onboarding.stepEditEmail.title" />
      </GenericTitle>

      {isInEditMode ? (
        <>
          <GenericTitleSummary hasMarginTopLg hasMarginBottomSm>
            <FormattedMessage id="onboarding.stepEditEmail.emailLabel" />
          </GenericTitleSummary>

          <Field
            name={Fields.NewEmail}
            initialValue={email}
            validate={required}
            renderControl={({ input, meta }) => (
              <GenericInput
                {...input}
                type="email"
                placeholder="onboarding.stepEditEmail.emailPlaceholder"
                disabled={isSending}
                hasError={meta.showError}
                hasNoBackground
                hasFullWidth
              />
            )}
          />

          <FormActionButtons
            labelBtnContent={<FormattedMessage id="shared.cancel" />}
            submitBtnContent={<FormattedMessage id="onboarding.shared.sendButton" />}
            submitDisabled={isSending}
            onSubmitBtnClick={onClickResend}
            onLabelBtnClick={() => {
              form.change(Fields.NewEmail, undefined);
              setIsInEditMode(false);
            }}
          />
        </>
      ) : (
        <>
          <GenericTitleSummary>
            <FormattedMessage id="onboarding.stepResendEmail.summary" />
          </GenericTitleSummary>

          <EmailInfo>
            <EmailInfoIconWrapper>
              <IconLetter />
            </EmailInfoIconWrapper>

            <EmailInfoContent>
              <EmailInfoTitle>
                <FormattedMessage id="onboarding.stepResendEmail.emailLabel" />
              </EmailInfoTitle>

              <EmailInfoLabel>{email}</EmailInfoLabel>

              <EmailInfoButtons>
                <EmailInfoButton onClick={onClickResend} disabled={isSending}>
                  <FormattedMessage id="onboarding.stepResendEmail.resendEmail" />
                </EmailInfoButton>

                <EmailInfoButton onClick={() => setIsInEditMode(true)} disabled={isSending}>
                  <FormattedMessage id="onboarding.stepResendEmail.editEmail" />
                </EmailInfoButton>
              </EmailInfoButtons>
            </EmailInfoContent>
          </EmailInfo>
        </>
      )}
    </>
  );
};

export default StepResendEmail;
