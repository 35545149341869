import React from 'react';

import SvgIcon from './SvgIcon';

const IconRotateDocument = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 400 400" {...props}>
    <path
      fill="currentColor"
      d="M165.4 128.7a78.7 78.7 0 0182.5 18l2-20.3 12.5 1.2-4.3 37.1-40 5.4-.6-11.9 21.6-2.7a66.5 66.5 0 00-109.2 70 66.5 66.5 0 00126.6-5.3l12.1 3.3a79 79 0 01-49.2 53.5 79 79 0 01-101.1-47.2 79 79 0 0147.1-101z"
    />
    <path
      stroke="currentColor"
      fill="none"
      strokeWidth="20"
      d="M85 20l249 21.8-29.6 338.7-249-21.8z"
    />
  </SvgIcon>
);

export default IconRotateDocument;
