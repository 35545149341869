import { invitationsAdvisersApi } from './advisers';
import { invitationsClientsApi } from './clients';
import { invitationApi } from './invitation';
import { invitationsPracticeAdminsApi } from './practiceAdmins';

const apiPath = '/api/v1/invitations';

function invitationsApi(path: string) {
  return {
    path,
    advisers: () => invitationsAdvisersApi(`${path}/advisers`),
    clients: () => invitationsClientsApi(`${path}/clients`),
    practiceAdmins: () => invitationsPracticeAdminsApi(`${path}/practice-admins`),
  };
}

type InvitationApi = ReturnType<typeof invitationApi>;
type InvitationsApi = ReturnType<typeof invitationsApi>;

function invitations(): InvitationsApi;
function invitations(invitationId: number): InvitationApi;
function invitations(invitationId?: number): InvitationsApi | InvitationApi {
  return invitationId ? invitationApi(`${apiPath}/${invitationId}`) : invitationsApi(apiPath);
}

export { invitations };
