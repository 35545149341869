import React from 'react';
import { rem, rgba } from 'polished';
import styled from 'styled-components';
import type { RemoteParticipant } from 'twilio-video';

import { KeyCode } from 'constants/keys';
import { useParticipantData } from 'components/VideoChat/useParticipantData';

import { getParticipantVideoTrack, getVideoRatio } from '../Participant/helpers';
import { ParticipantBase } from './ParticipantBase';

const Wrapper = styled(ParticipantBase)<{
  isSelected: boolean;
  isUISmall: boolean;
}>`
  box-sizing: content-box;
  margin: ${({ isUISmall }) => rem(isUISmall ? 4 : 6)};
  outline: none;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ isSelected, theme }) =>
    isSelected ? rgba(theme.colors.white, 0.75) : rgba(0, 0, 0, 0.14)};
  transition: linear 0.2s border-color;

  &[aria-disabled='false'] {
    cursor: pointer;

    &:hover {
      border-color: ${({ theme }) => theme.colors.white};
    }
  }

  & > * {
    box-sizing: border-box;
  }
`;

interface Props {
  participant: RemoteParticipant;
  isUISmall: boolean;
  isSelected: boolean;
  onSelect?: () => void;
  children: React.ReactNode;
}

export function RemoteParticipantWrapper({
  participant,
  isUISmall,
  isSelected,
  onSelect,
  children,
}: Props) {
  const participantData = useParticipantData(participant.identity);
  const videoTrack = getParticipantVideoTrack(participant);
  const ratio = getVideoRatio(videoTrack);
  const height = isUISmall ? 54 : 90;
  const width = isUISmall ? 72 : 120;

  return (
    <Wrapper
      height={height}
      width={ratio ? height * ratio : width}
      isSelected={isSelected}
      isUISmall={isUISmall}
      title={participantData?.fullName}
      onClick={onSelect}
      aria-disabled={!onSelect}
      tabIndex={0}
      role="button"
      onKeyDown={e => {
        if (onSelect && [KeyCode.Enter, KeyCode.Space].includes(e.keyCode)) {
          onSelect();
        }
      }}
    >
      {children}
    </Wrapper>
  );
}
