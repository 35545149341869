import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions } from 'api/types';

import { clientCaseFinancialAuditsMarkAsSeenApi } from './markAsCompleted';
import { clientCaseFinancialAuditsPrintApi } from './print';

export interface ClientCaseFinancialAuditsPostOptions {
  answers: Record<string, unknown>;
}

export interface ClientCaseFinancialAuditsPatchOptions {
  answers: Record<string, unknown>;
}

export function clientCaseFinancialAuditsApi(path: string) {
  return {
    path,
    print: () => clientCaseFinancialAuditsPrintApi(`${path}/print`),
    markAsCompleted: () => clientCaseFinancialAuditsMarkAsSeenApi(`${path}/mark-as-completed`),
    GET: (params?: GetOptions) => api.get<JsonApiResponseSingle>(path, { params }),
    POST: ({ answers }: ClientCaseFinancialAuditsPostOptions) =>
      api.post<JsonApiResponseSingle>(path, {
        data: {
          attributes: {
            answers,
          },
        },
      }),
    PATCH: ({ answers }: ClientCaseFinancialAuditsPatchOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: {
            answers,
          },
        },
      }),
  };
}
