import { API_RISK_ASSESSMENT_GENERATE } from 'constants/api';

import api from './apiInstance';

export { adviceRooms } from './adviceRooms';
export { advisers } from './advisers';
export { avatars } from './avatars';
export { blogPosts } from './blogPosts';
export { agendaTemplates } from './agendaTemplates';
export { brochures } from './brochures';
export { clientTags } from './clientTags';
export { clientCases } from './clientCases';
export { clientDashboardLinks } from './clientDashboardLinks';
export { clientNotifications } from './clientNotifications';
export { clients } from './clients';
export { disclosureForms } from './disclosureForms';
export { documentPacks } from './documentPacks';
export { documents } from './documents';
export { docuSign } from './docuSign';
export { employeeNotifications } from './employeeNotifications';
export { existingImages } from './existingImages';
export { faq } from './faq';
export { featureHelps } from './featureHelps';
export { financialPlannings } from './financialPlannings';
export { guests } from './guests';
export { invitations } from './invitations';
export { maintenances } from './maintenances';
export { messages } from './messages';
export { multipleOauthAccount } from './multipleOauthAccount';
export { oauthAccountSettings } from './oauthAccountSettings';
export { practiceAdmins } from './practiceAdmins';
export { practiceBrochures } from './practiceBrochures';
export { practiceReports } from './practiceReports';
export { practiceSettings } from './practiceSettings';
export { registrations } from './registrations';
export { serviceCharters } from './serviceCharters';
export { sign } from './sign';
export { sync } from './sync';
export { tags } from './tags';
export { technicalSupport } from './technicalSupport';
export { usageReports } from './usageReports';
export { users } from './users';
export { zendesk } from './zendesk';
export { hubSpot } from './hubSpot';

export const generateRiskAssessment = (caseId: number) =>
  api.post(API_RISK_ASSESSMENT_GENERATE(caseId));

export default api;
