import { isBrowser } from './utils';

export enum Env {
  Development = 'development', // also integration staging
  Staging = 'staging',
  Production = 'production',
  Beta = 'beta',
  Test = 'test',
}

export const TEST_CONTEXT = {
  cookies: {},
  host: '',
  href: '',
  httpAcceptLanguage: '',
  i18nDefaultLocale: '',
  i18nLocale: '',
  inMailer: false,
  location: '',
  pathname: '',
  port: 1234,
  railsEnv: Env.Test,
  rorPro: false,
  rorVersion: '',
  scheme: '',
  search: null,
  serverSide: false,
};

export function isIntegrationEnv(origin: string) {
  return origin.endsWith('integration.devguru.co');
}

export function normalizeEnvironment(env: string): Env {
  if (isBrowser() && isIntegrationEnv(window.location.origin)) {
    return Env.Development;
  }

  return env as Env;
}
