import { CaseState } from 'types/entities/clientCase';

export default {
  prepare: `Let’s prepare your Online Advice request:`,
  status: {
    [CaseState.Building]: {
      title: `Submit your Online Advice request`,
      summary: `After you submit, here is what will happen:`,
    },
    [CaseState.Submitted]: {
      title: `Your Online Advice request is being reviewed`,
      summary: `The Online Advice request was submitted, and is currently being reviewed`,
    },
    [CaseState.Completed]: {
      title: `Your request is complete, and has been confirmed by our office.`,
      summary: `If you wish to discuss this Case further, or another Case, please contact your adviser.`,
    },
    [CaseState.Cancelled]: {
      title: `This request has been cancelled.`,
      summary: `Please see the request audit trail for further details.`,
    },
  },
  caseReview: {
    title: `Request review`,
    text: `Your Adviser will review your request. Should they need to clarify something, they will contact you for further information and to discuss your needs and objectives.`,
    reviewHeader: `You can also review your answers:`,
  },
  casePack: {
    title: `Recommendation`,
    text: `Once ready, your Adviser will share a Recommendation Pack with you. This will contain your personal recommendation for your review. Your Adviser may also schedule a meeting to discuss the recommendation.`,
  },
  notifications: {
    submitCaseFail: `Please complete the entire Advice Case before submitting.`,
  },
};
