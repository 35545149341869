if (typeof window !== 'undefined') {
  const { location } = window;

  if (!location.origin) {
    const origin = `${location.protocol}//${location.hostname}${
      location.port ? `:${location.port}` : ''
    }`;

    try {
      Object.defineProperty(location, 'origin', {
        enumerable: true,
        value: origin,
      });
    } catch (e) {
      // @ts-ignore: needed for IE < 8
      location.origin = origin;
    }
  }
}
