import api from 'api/apiInstance';
import type { DocumentSet } from 'types/entities/documentSet';

import { guestAdviceRoomDocumentSetItemApi } from './item';

export interface GuestClientMeetingRoomsDocumentSetsGetApi {
  authToken: string;
}

export function guestClientMeetingRoomsDocumentSetsApi(path: string) {
  return {
    path,
    item: () => guestAdviceRoomDocumentSetItemApi(`${path}/item`),
    GET: ({ authToken }: GuestClientMeetingRoomsDocumentSetsGetApi) =>
      api.get<DocumentSet[]>(path, {
        params: {
          '[data][attributes][auth_token]': authToken,
        },
      }),
  };
}
