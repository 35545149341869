export const POST_RISK_ASSESSMENT = 'POST_RISK_ASSESSMENT';
export const POST_RISK_ASSESSMENT_SUCCESS = 'POST_RISK_ASSESSMENT_SUCCESS';
export const POST_RISK_ASSESSMENT_FAIL = 'POST_RISK_ASSESSMENT_FAIL';
export const GET_RISK_ASSESSMENT = 'GET_RISK_ASSESSMENT';
export const GET_RISK_ASSESSMENT_SUCCESS = 'GET_RISK_ASSESSMENT_SUCCESS';
export const GET_RISK_ASSESSMENT_FAIL = 'GET_RISK_ASSESSMENT_FAIL';
export const SET_RISK_ASSESSMENT = 'SET_RISK_ASSESSMENT';
export const SET_RISK_ASSESSMENTS = 'SET_RISK_ASSESSMENTS';
export const RISK_ASSESSMENT_GO_TO_STEP = 'RISK_ASSESSMENT_GO_TO_STEP';
export const RISK_ASSESSMENT_GO_TO_PREVIOUS_QUESTION = 'RISK_ASSESSMENT_GO_TO_PREVIOUS_QUESTION';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
