import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

import BaseFontSizeChangerContainer, { RenderProps } from './BaseFontSizeChangerContainer';

const Wrapper = styled.div`
  display: inline-block;
  white-space: nowrap;
`;

const Button = styled.button<{ isSmall?: boolean }>`
  padding: 0 ${rem(2)};
  font-size: ${({ isSmall, theme }) =>
    isSmall ? theme.fontSizes.dbSmall : theme.fontSizes.smallLg};
  color: ${({ theme }) => theme.colors.textDark};

  &:disabled {
    color: ${({ theme }) => theme.colors.bermudaGray};
    cursor: default;
  }
`;

function BaseFontSizeChanger({
  decreaseFontSize,
  increaseFontSize,
  isDecreasingDisabled,
  isIncreasingDisabled,
}: RenderProps) {
  return (
    <Wrapper>
      <Button onClick={decreaseFontSize} disabled={isDecreasingDisabled} isSmall>
        A
      </Button>

      <Button onClick={increaseFontSize} disabled={isIncreasingDisabled}>
        A
      </Button>
    </Wrapper>
  );
}

const render = (renderProps: RenderProps) => <BaseFontSizeChanger {...renderProps} />;
export default () => <BaseFontSizeChangerContainer render={render} />;
