import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';
import type { DocumentImage } from 'types/entities/documentImage';
import type { SignaturePlaceholder } from 'types/entities/signaturePlaceholder';

export interface DocumentPackDocumentMetadataPatchOptions {
  sectionMarks: DocumentImage[];
  signaturePlaceholders: SignaturePlaceholder[];
  textPlaceholders: SignaturePlaceholder[];
}

export function documentPackDocumentMetadataApi(path: string) {
  return {
    path,
    PATCH: (params: DocumentPackDocumentMetadataPatchOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: {
            metadata: mapFormData(params),
          },
        },
      }),
  };
}
