import React from 'react';
import { FormattedMessage } from 'react-intl';
import { rgba } from 'polished';
import styled, { DefaultTheme, keyframes, ThemeProps } from 'styled-components';

import IconMessage from 'components/Icons/IconMessage';
import { useMessagesContext } from 'components/VideoChat/MessagesContext';

import VideoChatToolbarButton from '../VideoChatToolbarButton/VideoChatToolbarButton';

const pulse = ({ theme }: ThemeProps<DefaultTheme>) => keyframes`
  0% {
    box-shadow: 0 0 0 0px ${rgba(theme.colors.crail, 0.7)};
  }

  100% {
    box-shadow: 0 0 0 10px ${rgba(theme.colors.crail, 0)};
  }
`;

const Counter = styled.div`
  position: absolute;
  right: -3px;
  bottom: -2px;
  padding: 2px 3px;
  font-size: ${({ theme }) => theme.fontSizes.dbSmallMd};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.crail};
  animation: ${pulse} 2s infinite;
`;

interface IconProps {
  className: string;
}

function MessagesIcon({ className }: IconProps) {
  const { unreadMessageCount } = useMessagesContext();

  return (
    <div className={className} style={{ position: 'relative' }}>
      <IconMessage />
      {Boolean(unreadMessageCount) && <Counter>{unreadMessageCount}</Counter>}
    </div>
  );
}

function MessagesButton() {
  const { toggleMessages, areMessagesVisible } = useMessagesContext();

  return (
    <VideoChatToolbarButton
      data-test="messages-button"
      onClick={() => {
        toggleMessages();
      }}
      icon={MessagesIcon}
      aria-pressed={!areMessagesVisible}
      tooltipText={<FormattedMessage id="video.tooltips.messages" />}
    />
  );
}

export default MessagesButton;
