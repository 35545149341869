import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';

import { employeeNotificationApi } from './employeeNotification';

const apiPath = '/api/v1/employee-notifications';

function employeeNotificationsApi(path: string) {
  return {
    path,
    GET: () => api.get<JsonApiResponse>(path),
  };
}

type EmployeeNotificationApi = ReturnType<typeof employeeNotificationApi>;
type EmployeeNotificationsApi = ReturnType<typeof employeeNotificationsApi>;

function employeeNotifications(): EmployeeNotificationsApi;
function employeeNotifications(notificationId: number): EmployeeNotificationApi;
function employeeNotifications(
  notificationId?: number
): EmployeeNotificationsApi | EmployeeNotificationApi {
  return notificationId
    ? employeeNotificationApi(`${apiPath}/${notificationId}`)
    : employeeNotificationsApi(apiPath);
}

export { employeeNotifications };
