import type { PreflightTestProgress } from 'twilio-video';

import { AdviceRoomState, MediaRegion } from 'types/entities/adviceRoom';
import { UserRoleName } from 'types/entities/user';
import { AgendaTemplate } from 'components/AdviceRooms/AdviceRoomForm/types';
import { VerificationStatus } from 'components/VideoChat/types';

const agendaTemplates: Record<AgendaTemplate, { title: string; text: string }> = {
  [AgendaTemplate.IntroductionMeeting]: {
    title: `Initial meeting`,
    text: `- Introductions

- Your circumstances

- Your objectives

- About {practiceName}

- Services & costs

- Next steps`,
  },
  [AgendaTemplate.ReviewMeeting]: {
    title: `Review meeting`,
    text: `- Personal update

- Changes to your circumstances (immediate, next 6 months, long term)

- Review of your portfolio

- Tax Year

- Protecting you and your family

- Any other business`,
  },
  [AgendaTemplate.ReviewMeetingV2]: {
    title: `Review meeting v2`,
    text: `- Personal update & review of your circumstances

- Review of your goals and objectives

- Suitability of your existing plans

- Review and update your attitude to risk

- Rebalancing your existing plans

- Review your existing protection and needs

- Any other business`,
  },
  [AgendaTemplate.ReviewMeetingWithReferralRequest]: {
    title: `Review meeting with referral request`,
    text: `- Personal update

- Changes to your circumstances (immediate, next 6 months, long term)

- Review of your portfolio

- Tax Year

- Protecting you and your family

- Any other business

- Who else can I assist; colleagues, friends & family`,
  },
  [AgendaTemplate.RecommendationMeeting]: {
    title: `Recommendation meeting`,
    text: `- Reflections following our previous meeting

- Recommendation letter

- Regulatory documents

- Questions

- Actions, next steps and timescales

- Any other business`,
  },
  [AgendaTemplate.FollowUpMeeting]: {
    title: `Follow-up meeting`,
    text: `- Personal update

- Reflections following our previous meeting

- The financial plan - the journey so far

- Actions and next steps

- Any other business`,
  },
};

const mediaRegions: Record<MediaRegion, string> = {
  [MediaRegion.GlobalLowLatency]: `Automatically assigned`,
  [MediaRegion.Australia]: `Australia`,
  [MediaRegion.Brazil]: `Brazil`,
  [MediaRegion.Germany]: `Germany`,
  [MediaRegion.India]: `India`,
  [MediaRegion.Ireland]: `Ireland`,
  [MediaRegion.Japan]: `Japan`,
  [MediaRegion.Singapore]: `Singapore`,
  [MediaRegion.UsEastCoast]: `US East Coast (Virginia)`,
  [MediaRegion.UsWestCoast]: `US West Coast (Oregon)`,
};

const preflightTestResults: Record<PreflightTestProgress, { title: string; description: string }> =
  {
    mediaAcquired: {
      title: `Media acquired`,
      description: `Test has successfully generated synthetic tracks.`,
    },
    connected: {
      title: `Connected`,
      description: `Test has successfully connected to server and obtained turn credentials.`,
    },
    mediaSubscribed: {
      title: `Media subscribed`,
      description: `Participant successfully subscribed to media tracks.`,
    },
    mediaStarted: {
      title: `Media started`,
      description: `Media flow was detected.`,
    },
    dtlsConnected: {
      title: `DTLS connected`,
      description: `Established DTLS connection. This is measured from RTCDtlsTransport connecting to connected state. On Safari, support for measuring this is missing.`,
    },
    peerConnectionConnected: {
      title: `PeerConnection connected`,
      description: `Established a PeerConnection, This is measured from PeerConnection connecting to connected state. On Firefox, support for measuring this is missing.`,
    },
    iceConnected: {
      title: `ICE connected`,
      description: `Established ICE connection. This is measured from ICE connection checking to connected state.`,
    },
  };

export default {
  name: `Client Meeting Room`,
  title: `Client Meeting Room title`,
  devicePermissionsWarning: `
      <strong>Important:</strong> If prompted by your browser, please grant permission for this site to access your webcam and microphone.
      <note>If you do not grant this permission, the other meeting attendees will not be able to see or hear you.</note>
    `,
  actions: {
    openMostRecentFactFind: {
      title: `Open most recent Fact Find`,
      description: `Click here to open the most recent fact find`,
    },
    openSketchpad: {
      title: `Open sketchpad`,
      description: `Click here to open the sketchpad`,
    },
  },
  testYourSetup: {
    title: `Test your setup`,
    description: `This utility determines if your current setup will likely suffice for your upcoming meeting.`,
    preflightTestResults,
    supportedBrowser: {
      title: `Supported browser`,
      [VerificationStatus.Succeeded]: `It seems you are using a supported browser.`,
      [VerificationStatus.Failed]: `It seems that you are not using a supported browser. We highly recommend that you use one of the following, depending on your operating system.`,
    },
    webcam: {
      title: `Webcam`,
      permissionsNote: `Did you give your browser permission to access your webcam? If not, you may need to restart your browser and ensure that you grant permission.`,
      [VerificationStatus.Succeeded]: `We detected connected video device(s). Please confirm that selected webcam works.`,
      [VerificationStatus.Warning]: `We did not detect any video devices. You will be able to join a meeting, but without a webcam.`,
      [VerificationStatus.PermissionDenied]: `You did not allow your browser to access your webcam.`,
      [VerificationStatus.SystemPermissionDenied]: `You did not allow your system to access your webcam.`,
      [VerificationStatus.Error]: `We cannot check if you have connected video devices.`,
    },
    microphone: {
      title: `Microphone`,
      permissionsNote: `Did you give your browser permission to access your microphone? If not, you may need to restart your browser and ensure that you grant permission.`,
      [VerificationStatus.Succeeded]: `We detected connected audio device(s). Please confirm that selected microphone works.`,
      [VerificationStatus.Warning]: `We did not detect any audio devices. You will be able to join a meeting, but without a microphone.`,
      [VerificationStatus.PermissionDenied]: `You did not allow your browser to access your microphone.`,
      [VerificationStatus.SystemPermissionDenied]: `You did not allow your system to access your microphone.`,
      [VerificationStatus.Error]: `We cannot check if you have connected audio devices.`,
    },
    connectionToServer: {
      title: `Connection to server`,
      [VerificationStatus.Succeeded]: `You have successfully connected to the server.`,
      [VerificationStatus.Failed]: `You have failed to connect to the server.`,
    },
    overall: {
      [VerificationStatus.Pending]: `Checking...`,
      [VerificationStatus.Succeeded]: `Good news. Based on your current setup, you should be able to join a meeting.`,
      [VerificationStatus.Warning]: `You should be able to join a meeting, but we detected some issues. Please refer to the checklist.`,
      [VerificationStatus.Failed]: `Unfortunately, you probably will not be able to connect to a meeting. Please refer to the checklist.`,
      [VerificationStatus.PermissionDenied]: `Unfortunately, you probably will not be able to connect to a meeting. Please refer to the checklist.`,
      [VerificationStatus.SystemPermissionDenied]: `Unfortunately, you probably will not be able to connect to a meeting. Please refer to the checklist.`,
      [VerificationStatus.Error]: `Unfortunately, you probably will not be able to connect to a meeting. Please refer to the checklist.`,
    },
    browserPermissionsLink: `<a>Here</a> you can read about managing your browser permissions.`,
    mixedSystemCameraPermissionLink: `Here you can read about managing your browser's <a>{browserPermission}</a> permission and system's <webcam>webcam</webcam> permission.`,
    mixedSystemMicrophonePermissionLink: `Here you can read about managing your browser's <a>{browserPermission}</a> permission and system's <microphone>microphone</microphone> permission.`,
    systemPermissionsLink: `Here you can read about managing your system's <webcam>webcam</webcam> and <microphone>microphone</microphone> permissions.`,
    systemMicrophoneLink: `Here you can read about managing your system's <microphone>microphone</microphone> permissions.`,
    systemCameraLink: `Here you can read about managing your system's <webcam>webcam</webcam> permissions.`,
    button: `Test my setup`,
  },
  editModalTitle: `Edit Client Meeting Room`,
  mediaRegions,
  defaultMediaRegion: `{region} (default)`,
  adviceRoom: {
    title: `My Client Meeting Room`,
    join: `Join Meeting`,
    notSupportedBrowser: `Your browser is not supported.`,
    secureConnectionNeeded: `Secure connection is needed to join the meeting.`,
    cannotConnectSecure: `Cannot connect to the meeting. You can join only by using secure connection.`,
    cannotConnect: `Cannot connect to the meeting.`,
    joiningMeetingWithoutAudioDevice: `Joining the meeting without an audio device.`,
    joiningMeetingWithoutVideoDevice: `Joining the meeting without a video device.`,
    [`cannotConnect${UserRoleName.Client}`]: `Cannot connect to the meeting. Please, contact your Adviser.`,
    [`cannotConnect${UserRoleName.Adviser}`]: `Cannot connect to the meeting. Please, check the settings.`,
    [`cannotConnect${UserRoleName.PracticeAdmin}`]: `Cannot connect to the meeting. Please, contact the Adviser.`,
    wasCompletedOrCancelled: `The meeting was completed or cancelled.`,
    cannotJoinInfo: `You can join up to {time} before the scheduled start time.{br}Please refresh this page or come back shortly before the scheduled start time.`,
    addToCalendar: `Add to calendar`,
    recording: `Recording`,
    toggleSticky: `Pin/unpin video window`,
    toggleFullscreen: `Toggle fullscreen`,
    toggleFullscreenLong: `You can use this button to toggle fullscreen`,
    changeLayout: `Change video layout`,
    autoDisconnectMessage: `Due to inactivity, you’ll be disconnected in: {value}s.`,
    noPermissions: `You did not give your {permissionComingFrom} permission to access your webcam and microphone.`,
    noMixedPermissions: `You did not give your browser permission to access your {permissionBrowser} and your system permission to access your {permissionSystem}.`,
    noWebcamPermission: `You did not give your {permissionComingFrom} permission to access your webcam.`,
    noMicPermission: `You did not give your {permissionComingFrom} permission to access your microphone.`,
    duplicatedIdentityWarning: `User with the same identity tries to connect the meeting. You will be disconnected.`,
  },
  resendInvitations: {
    title: `Resend invitation mails`,
    description: `Choose attendees and guests to whom e-mail should be resent`,
  },
  tasks: {
    title: `Preparation list`,
    tooltip: `Here you can record tasks that your team should carry out to prepare for the meeting. This preparation list will not be presented to your client.`,
    new: `New task`,
    empty: `No tasks added`,
  },
  meetingNotes: {
    title: `Meeting notes`,
  },
  meetingAgenda: {
    title: `Meeting agenda`,
    empty: `No agenda`,
    templates: agendaTemplates,
  },
  documents: {
    title: `Documents`,
    description: `To present a document to attendees, select 'Display and Share' from the document’s ‘3-dot’ menu.`,
    descriptionNote: `Note: You are not limited to presenting documents that were directly added to the Client Meeting Room. In ‘Other documents’ you can select your client’s other documents.`,
    supportedDocuments: `You can only Display and Share PDF, JPG, JPEG and PNG documents. To present other document types (e.g. Microsoft Office documents) and documents that have not been uploaded to the portal (e.g. local PowerPoint files, and 3rd party websites) please use screen sharing.`,
    meetingDocumentsTitle: `Meeting documents`,
    otherTitle: `Other documents`,
    noDocuments: `There are no documents.`,
    chooseDocumentSet: `Choose a document set`,
  },
  recordings: {
    title: `Recordings`,
    noRecordings: `There are no recordings.`,
    recordingsNotAvailable: `The recording will be available after the Client Meeting Room status is marked as ‘Completed’`,
    generating: `Recording is still being generated. Please, check back later...`,
  },
  transcription: {
    generation: `Generate a transcript of your recording.`,
    inProcess: `Transcription generation is in process which may take a while. Please check in some time.`,
    compositionDeletionRestriction: `File cannot be deleted while transcription is in process.`,
    noAudioTrack: `Cannot generate transcription as no audio track is present.`,
    success: `Transcription generated successfully.`,
    failed: `Transcription generation failed.`,
    compositionDeleted: `This recording has been deleted.`,
    restrictTranscribe: `Transcription can't be generated as recording has been deleted.`,
  },

  recording: {
    title: `Recording {number}`,
    duration: `Duration`,
    size: `Size`,
    type: `Type`,
    participants: `Participants`,
    removeModalTitle: `Please confirm that you wish to delete this recording. It will be permanently deleted.`,
    removeModalText: `It is recommended that you download and save a copy before deleting.`,
    shared: `Recording is shared with your Client`,
    notShared: `Recording is not shared with your Client`,
  },
  recordingsSize: `Recordings size`,
  noRecordings: `No recordings`,
  upcomingAdviceRooms: `Today and upcoming meetings`,
  noUpcomingAdviceRooms: `There are no upcoming meetings.`,
  noAdviceRooms: `There are no meetings.`,
  states: {
    [AdviceRoomState.Scheduled]: `Scheduled`,
    [AdviceRoomState.Completed]: `Completed`,
    [AdviceRoomState.Cancelled]: `Cancelled`,
  },
  stateMessages: {
    [AdviceRoomState.Completed]: `This meeting is now complete.`,
    [AdviceRoomState.Cancelled]: `This meeting is now cancelled.`,
  },
  upcoming: `Upcoming`,
  meetingStatus: `Meeting status`,
  reloadModal: {
    title: `An Client Meeting Room call is in progress.`,
    body: `Please end the call before leaving your account.`,
  },
  transcriptionDisclaimer: `The transcript feature is generated using artificial intelligence, and therefore, there may be discrepancies in the wording or attribution of speakers. Users are advised to verify the accuracy and suitability of the transcript before use.`,
  attendeeJoinedPrompt: `{senderName} has joined ‘{name}’ Client Meeting Room`,
  attendees: `Attendees`,
  scheduledAttendees: `Scheduled attendees`,
  attendeesMax: `Attendees (up to {max})`,
  clientMissing: `Client must be added as a attendee`,
  notEnoughAttendees: `There have to be at least {min} attendees`,
  tooMuchAttendees: `There cannot be more than {max} attendees`,
  typeToSearchAttendees: `Type to search for attendees`,
  noAttendeesAdded: `No attendees added`,
  noAttendeesFound: `No attendees found`,
  noGuestsAdded: `No 3rd party guests have been invited`,
  guests: {
    title: `Guests`,
    addModalTitle: `Add guest`,
    removeModalTitle: `Remove guest`,
    removeModalConfirmation: `Are you sure you want to remove guest: {name}?`,
    guestAddedMessage: `Guest has been successfully added`,
    guestRemovedMessage: `Guest has been successfully removed`,
    trustedLabel: `Trusted`,
    trustedOn: `The guest will have access to meeting’s documents, will be able to use ‘display and share’ feature, and can also join the meeting up to 30 mins before the scheduled start time.`,
    trustedOff: `The guest will not have access to meeting’s documents and will not be able to use ‘display and share’ feature.`,
  },
  guestMeetingRoom: {
    title: `Client Meeting Room - {name}`,
    summary: `Welcome {firstName} {lastName}`,
  },
  meetingToday: `Meeting today`,
  saveOrCancelUpdates: `Save or cancel updates before changing page`,
  networkQuality: {
    title: `{isLocal, select,
        true {Your}
        other {This attendee's}
      } network is {qualityLevel, select,
        0 {broken}
        1 {bad}
        2 {poor}
        3 {OK}
        4 {good}
        5 {very good}
        other {-}
      }.`,
    descriptions: {
      5: `Audio and video should be fine most of the time.`,
      4: `Audio and video should be fine, with some intermittent video degradations.`,
      3: `However, {isLocal, select,
          true {you}
          other {the meeting}
        } may experience some video problems in some situations like screen sharing.`,
      2: `{isLocal, select,
          true {You}
          other {The meeting}
        } will likely experience video and some audio problems.`,
      1: `{isLocal, select,
          true {You}
          other {The meeting}
        } will likely experience both video and audio problems. {isLocal, select,
          true {Consider turning off your webcam or reconnecting to the meeting.}
          other {The attendee should consider turning off their webcam or reconnecting to the meeting.}
        }`,
      0: `No video or audio flowing. {isLocal, select,
          true {Consider reconnecting to the meeting.}
          other {The attendee should attempt to reconnect to the meeting, if possible.}
        }`,
    },
  },
  microphoneDisabled: `This participant's microphone is disabled.`,
  cameraDisabled: `This participant's camera is disabled.`,
  consentModal: {
    title: `Consent to being recorded`,
    body: `This Client Meeting Room will be recorded. This website will record available audio and video tracks to create a composite recording that summarises the meeting. This recording may be used as follows:
      <ul>
        <li>To support the training and development of our staff;</li>
        <li>To support the provision of financial advice and/or a service to our client and any connected individuals;</li>
        <li>As a record of any financial advice and/or service provided to our client and any connected individuals;</li>
        <li>To monitor and assure the quality of any financial advice and/or service provided to our client and any connected individuals.</li>
      </ul>`,
    checkboxText: `I consent to being recorded.`,
  },
  sketchpadDrawingUploaded: `Sketchpad drawing has been uploaded to documents as {fileName}`,
  messageInputPlaceholder: `Type message...`,
  showHighlighterPanel: `Highlight content`,
  hideHighlighterPanel: `Exit highlight mode`,
  outlook: {
    calendarAdded: 'CMR event is added to your calendar',
    calendarNotAdded: 'CMR event is not added to your calendar',
    outlookAdded: 'CMR event is added to your Outlook calendar',
    outlookNotAdded: 'CMR event is not added to your Outlook calendar',
  },
};
