import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

export interface UserUpdateVirtualBackgroundPatchOptions {
  image: File;
}

export function userUpdateVirtualBackgroundApi(path: string) {
  return {
    path,
    PATCH: ({ image }: UserUpdateVirtualBackgroundPatchOptions) =>
      api.patch<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData({
              image,
            }),
          },
        })
      ),
  };
}
