import type { AnyAction } from 'redux';

import { mergeEntities } from 'helpers/store';

import { FETCH_DATA } from './actionTypes';
import type { EntitiesActionTypes, EntitiesState } from './types';

export const initialState: EntitiesState = {};

export default (state = initialState, incomingAction: AnyAction): EntitiesState => {
  const action = incomingAction as EntitiesActionTypes;

  switch (action.type) {
    case FETCH_DATA: {
      const { data, pathToOverwrite } = action.payload;

      return mergeEntities(state, data, pathToOverwrite);
    }
    default:
      return state;
  }
};
