import React from 'react';
import styled from 'styled-components';

import { DEFAULT_CURRENCY } from 'constants/currency';
import type { Currency, CurrencyValue } from 'types/entities/currency';

import { CurrencyDropdown } from '../dropdowns/CurrencyDropdown';
import { CurrencyNumberInput } from './CurrencyNumberInput';

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;

  & > *:nth-child(2) {
    flex-shrink: 0;
  }
`;

export type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'type'> & {
  hasError?: boolean;
  onChange?: (value: CurrencyValue) => void;
  onValueChange?: (value: number | undefined) => void;
  onCurrencyChange?: (currency: string) => void;
  currency?: string;
  currencies: Currency[];
  value?: number;
};

export function CurrencyInput({
  onChange,
  onValueChange,
  onCurrencyChange,
  currencies,
  currency = DEFAULT_CURRENCY,
  value,
  hasError,
  disabled,
  placeholder,
  ...inputProps
}: Props) {
  return (
    <Wrapper>
      <CurrencyNumberInput
        {...inputProps}
        onChange={newValue => {
          onValueChange?.(newValue);
          onChange?.({ currency, value: newValue });
        }}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        hasError={hasError}
      />

      <CurrencyDropdown
        currencies={currencies}
        onChange={newCurrency => {
          onCurrencyChange?.(newCurrency);
          onChange?.({ currency: newCurrency, value });
        }}
        isDisabled={disabled}
        value={currency}
        hasError={hasError}
      />
    </Wrapper>
  );
}
