export default {
  tooltips: {
    zoomin: 'Zoom in',
    zoomout: 'Zoom out',
    reset: 'Reset',
    rotateLeft: 'Rotate left',
    rotateRight: 'Rotate right',
    fullScreen: 'Full Screen',
    exitFullScreen: 'Exit Full Screen',
    next: 'Next',
    prev: 'Prev',
    download: 'Download',
    printScreen: 'Print',
  },
};
