import { AuditTrailModel } from 'types/entities/auditTrail';

export default {
  commonTitle: `Audit trail`,
  subtitle: `Below are the major events that occurred within this case.`,
  [AuditTrailModel.ClientAccount]: {
    title: `Account audit trail`,
    subtitleAdviser: `Here you can review significant changes to your client’s account.`,
  },
  [AuditTrailModel.AdviceRoom]: {
    description: `Below are the major events that occurred within this Client Meeting Room.`,
  },
  [AuditTrailModel.Case]: {
    subtitle: `Below are the major events that occurred within this case.`,
    subtitleDocumentPack: `Below are the major events that occurred within this Document Pack.`,
    subtitleReviewQuestionnaire: `Below are the major events that occurred within this questionnaire.`,
  },
  [AuditTrailModel.DocumentVault]: {
    description: `Below are the major events that occurred within this Vault.`,
  },
  // Uppercase for next fields are due to fact, that BE return name of the RoR Class.
  Client: `Client`,
  Adviser: `Adviser`,
  PracticeAdmin: `Practice Admin`,
  PracticeDirector: `Practice Director`,
  AdviceRoomGuest: `Advice Room Guest`,
  notActiveClient: `Client has not been active.`,
  emptyList: `There has not been any events.`,
};
