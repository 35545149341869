import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';

export interface ClientMetaUnreadMessagesGetResponse {
  has_unread_messages: boolean;
}

export function clientMetaUnreadMessagesApi(path: string) {
  return {
    path,
    GET: (config?: RequestConfig) => api.get<ClientMetaUnreadMessagesGetResponse>(path, config),
  };
}
