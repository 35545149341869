import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, PaginationOptions, RequestConfig } from 'api/types';
import { mapFormData } from 'helpers/formData';
import type { ReportableDataType } from 'types/entities/interactionReport';

export type ClientInteractionReportsGetOptions = GetOptions &
  PaginationOptions & {
    filter?: {
      reportDataType?: ReportableDataType;
      reportDataRecordId?: number | null;
    };
  };

export interface ClientInteractionReportsPostOptions {
  reportDataType: ReportableDataType;
  reportDataRecordId: number;
}

export function clientInteractionReportsApi(path: string) {
  return {
    path,
    GET: (params: ClientInteractionReportsGetOptions = {}, config?: RequestConfig) => {
      const { pageSize, pageNumber, filter, ...rest } = params;

      return api.get<JsonApiResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...(filter?.reportDataType !== undefined && {
            'filter[report_data_type]': filter?.reportDataType || 'null',
          }),
          ...(filter?.reportDataRecordId && {
            'filter[report_data_record_id]': filter?.reportDataRecordId,
          }),
          ...rest,
        },
        ...config,
      });
    },
    POST: (params: ClientInteractionReportsPostOptions) =>
      api.post(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
