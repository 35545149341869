import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

import { adviceRoomDocumentShareApi } from './documentShare';
import { adviceRoomDocumentMetadataApi } from './metadata';
import { adviceRoomDocumentReorderApi } from './reorder';
import { adviceRoomDocumentRevertApi } from './revert';
import { adviceRoomDocumentViewApi } from './view';

export interface AdviceRoomDocumentPatchOptions {
  name?: string;
  signatureRequired?: boolean;
  shouldSendSignatureReminder?: boolean;
  autoEmailEnabled?: boolean;
  concealedFromClient?: boolean;
  noteForClient?: string;
}

export function adviceRoomDocumentApi(path: string) {
  return {
    path,
    metadata: () => adviceRoomDocumentMetadataApi(`${path}/metadata`),
    revert: () => adviceRoomDocumentRevertApi(`${path}/revert`),
    reorder: () => adviceRoomDocumentReorderApi(`${path}/reorder`),
    view: () => adviceRoomDocumentViewApi(`${path}/view`),
    displayShare: () => adviceRoomDocumentShareApi(`${path}/display-and-share`),
    PATCH: (params: AdviceRoomDocumentPatchOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
    DELETE: () => api.delete(path),
  };
}
