import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';

export function clientClientMessageDestroyApi(path: string) {
  return {
    path,
    PATCH: () =>
      api.patch<JsonApiResponseSingle>(path, undefined, {
        params: {
          include: 'creator',
        },
      }),
  };
}
