import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { FilterOptions, GetOptions, PaginationOptions, RequestConfig } from 'api/types';
import type { SharedClientFilters } from 'constants/sharedClientFilters';

export type AdviserSharedClientsGetOptions = GetOptions &
  PaginationOptions &
  FilterOptions<SharedClientFilters> & {
    search?: string;
    clientTagIds?: number[];
  };

export function adviserSharedClientsApi(path: string) {
  return {
    path,
    GET: (params: AdviserSharedClientsGetOptions = {}, config?: RequestConfig) => {
      const { pageSize, pageNumber, clientTagIds, ...rest } = params;

      return api.get<JsonApiResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          client_tag_ids: clientTagIds,
          ...rest,
        },
        ...config,
      });
    },
  };
}
