import { CaseType } from 'types/entities/clientCase';

export default {
  messages: {
    default: `Something went wrong`,
    changedEmail: `Email has been changed`,
    resendEmail: `Email with activation link has been sent again`,
    resendEmailError: `Something went wrong - email was not sent`,
    registrationEmailSuccess: `Confirmation email has been sent`,
    couldNotFetchData: `Couldn’t fetch data, please try again later`,
    coverNoteSuccess: `Cover note has been successfully saved`,
    adviserNoteSuccess: `Note has been successfully saved`,
    agendaSuccess: `Agenda has been successfully saved`,
    commentSuccess: `Comment has been successfully sent`,
    commentError: `Something went wrong, comment has not been sent`,
    riskAssessmentQuestionnaireSuccess: `Risk assessment questionnaire has been successfully saved`,
    recommendationPackSuccess: `Recommendation Pack has been successfully sent`,
    recommendationPackRejected: `Recommendation Pack has been rejected`,
    recommendationPackAccepted: `Recommendation Pack has been accepted`,
    documentPackSuccess: `Document Pack has been successfully sent`,
    photoSavedSuccess: `Photo has been saved`,
    photoSavedError: `Something went wrong, photo has not been saved`,
    photoUploadError: `Something went wrong, photo has not been uploaded`,
    titleSavedSuccess: `Title has been saved`,
    financialStrategyShared: `The Financial Strategy is now shared with the Client`,
    financialStrategyNotShared: `The Financial Strategy is now not shared with the Client`,
    financialStrategyCannotShare: `The Financial Strategy cannot be shared. Please upload the document first.`,
    financialStrategyRemoved: `The Financial Strategy has been removed`,
    caseNameSavedSuccess: {
      [CaseType.OnlineAdviceRequest]: `Online Advice request name has been saved`,
      [CaseType.MeetingRequestCase]: `Meeting request name has been saved`,
      [CaseType.ReviewQuestionnaireCase]: `Questionnaire name has been saved`,
      [CaseType.RecommendationPackCase]: `Recommendation Pack name has been saved`,
      [CaseType.DocumentPackCase]: `Document Pack name has been saved`,
    },
    caseNameSavedFail: {
      [CaseType.OnlineAdviceRequest]: `Online Advice request name has not been saved`,
      [CaseType.MeetingRequestCase]: `Meeting request name has not been saved`,
      [CaseType.ReviewQuestionnaireCase]: `Questionnaire name has not been saved`,
      [CaseType.RecommendationPackCase]: `Recommendation Pack name has not been saved`,
      [CaseType.DocumentPackCase]: `Document Pack name has not been saved`,
    },
    caseStateSuccess: `Status has been successfully changed`,
    adviceRoomSuccess: `Client Meeting Room has been successfully changed`,
    adviceRoomStateSuccess: `Meeting status has been successfully changed`,
    documentSuccessfullyUpdated: `Document successfully updated`,
    documentSuccessfullyUploaded: `Document successfully uploaded`,
    documentSuccessfullyDeleted: `Document successfully deleted`,
    documentSuccessfullyReverted: `Document successfully reverted to original version`,
    signatureRequirementSuccessfullyUpdated: `Signature requirement successfully updated`,
    signatureReminderSuccessfullyUpdated: `Reminder options successfully updated`,
    signatureSignSuccess: `Signatures successfully saved`,
    signatureSignError: `Something went wrong, PDF file cannot be signed`,
    sectionMarkSuccess: `Section mark successfully saved`,
    sectionMarkError: `Something went wrong, section mark cannot be saved`,
    reassignedAdviserSuccess: `Adviser successfully assigned`,
    reassignedPASuccess: `Support staff successfully assigned`,
    reassignedPAError: `Support staff has not been assigned`,
    settingsSavedSuccess: `Settings saved successfully`,
    deactivateUserSuccess: `User has been successfully deactivated`,
    deactivateAdviserForbidden: `Cannot deactivate Adviser with clients assigned`,
    deactivatePracticeAdminForbidden: `Cannot deactivate Practice Admin with advisers assigned`,
    activateUserSuccess: `User has been successfully activated`,
    changesSavedSuccess: `Changes saved successfully`,
    newestFileFetched: `The newest file has been successfully fetched`,
    recordingDeleted: `Recording has been successfully deleted`,
    clientAccountRemoved: `Client account has been successfully removed`,
    notificationMarkedAsSeen: `Notification has been marked as read`,
    notificationsMarkedAsSeen: `Notifications has been marked as read`,
    clientSignInNotificationSaved: `Client sign-in notification has been successfully saved`,
    preferredAdvisersSaved: `Preferred advisers have been successfully saved`,
    technicalSupportRequestSent: `Technical support request has been successfully sent`,
    adviceRoomInvitationsResent: `Client meeting room invitations has been successfully resent`,
    todoListUpdateEmailSent: `Email about to-do list update has been successfully sent`,
    tagsSaved: `Tags has been successfully saved`,
    permissionLevelUpdated: `Permission level has been successfully updated`,
    documentsOrdered: `Documents have been successfully ordered`,
    autoEmailSuccessfullyUpdated: `Auto-emails settings successfully updated`,
  },
};
