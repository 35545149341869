import { filter, isArray, isEmpty, isNaN } from 'lodash';
import createCachedSelector from 're-reselect';
import build from 'redux-object';
import { createSelector } from 'reselect';

import type EntityPath from 'constants/entitiesPaths';
import { emptyArray } from 'helpers/utils';

import type { AppState } from '../types';
import { initialState } from './reducer';
import type { EntitiesState } from './types';

const buildEntity = <T>(
  entities: EntitiesState,
  entityPath: EntityPath,
  id: number | string
): T | undefined => {
  if (!id || isNaN(Number(id))) {
    return undefined;
  }

  return build<T>(entities, entityPath, id) || undefined;
};

const buildEntities = <T>(
  entities: EntitiesState,
  entityPath: EntityPath,
  ids: (number | string)[] | null
): T[] => {
  const result = build<T>(entities, entityPath, ids);

  const filteredCollection = filter(result, entity => Boolean(entity));
  return isEmpty(filteredCollection) ? emptyArray : filteredCollection;
};

export const getEntities = (state: AppState) => state.entities || initialState;

export const createEntitySelectors = <T>(entityPath: EntityPath) => {
  const getAllSelector = createSelector(getEntities, entities =>
    buildEntities<T>(entities, entityPath, null)
  );

  const getByIdsSelector: (state: AppState, ids: (string | number)[] | undefined) => T[] =
    createCachedSelector(
      getEntities,
      (state: AppState, ids?: (number | string)[]) => ids,
      (entities, ids) => {
        if (!ids) {
          return emptyArray;
        }

        const idsArray = isArray(ids) ? ids : [ids];

        return buildEntities<T>(entities, entityPath, ids ? idsArray : emptyArray);
      }
    )((state, ids: (string | number)[] | undefined) => (ids ? ids.join() : ''));

  const getByIdSelector: (
    state: AppState,
    ids: number | string | null | undefined
  ) => T | undefined = createCachedSelector(
    getEntities,
    (state: AppState, id?: number | string | null) => id,
    (entities, id) => (id ? buildEntity<T>(entities, entityPath, id) : undefined)
  )((state, id: number | string | null | undefined) => id || '');

  return {
    getAll: (state: AppState) => getAllSelector(state),
    getByIds: (state: AppState, ids?: (number | string)[]) => getByIdsSelector(state, ids),
    getById: (state: AppState, id?: number | string | null) => getByIdSelector(state, id),
  };
};
