import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';

import { getAppStore } from 'helpers/utils';

export class StoreProvider extends PureComponent {
  private store = getAppStore();

  public render() {
    return <Provider store={this.store}>{this.props.children}</Provider>;
  }
}
