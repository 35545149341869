import React, { ChangeEvent, FocusEvent } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { flexCenter } from 'helpers/styles/mixins';
import { isNotBlank } from 'helpers/utils';
import { requiredTextPattern } from 'helpers/validators';
import GenericLengthCounter from 'components/GenericLengthCounter/GenericLengthCounter';

import { textarea, TextareaProps } from './styled';
import { VerticalSize } from './VerticalSize';

const Wrapper = styled.div`
  ${flexCenter()};
  position: relative;
`;

const Textarea = styled.textarea`
  ${textarea};
`;

export interface Props extends TextareaProps {
  id?: string;
  required?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  value?: string;
  placeholder?: string;
  onChange?(e: ChangeEvent<HTMLTextAreaElement> | string): void;
  onBlur?(e: FocusEvent<HTMLTextAreaElement>): void;
}

export default function GenericTextarea({
  hasBoldBorderTop = false,
  hasError = false,
  hasVerticalResize = false,
  hasAccent = false,
  disabled = false,
  required = false,
  minVerticalSize = VerticalSize.Medium,
  maxLength,
  ...restProps
}: Props) {
  const intl = useIntl();

  const requiredProps =
    required && !disabled
      ? {
          required,
          pattern: requiredTextPattern,
          title: intl.formatMessage({ id: 'errors.required' }),
        }
      : {};

  return (
    <Wrapper>
      <Textarea
        hasBoldBorderTop={hasBoldBorderTop}
        hasVerticalResize={hasVerticalResize}
        hasAccent={hasAccent}
        hasError={hasError}
        minVerticalSize={minVerticalSize}
        maxLength={maxLength}
        disabled={disabled}
        {...restProps}
        {...requiredProps}
      />

      {maxLength && !disabled && (
        <GenericLengthCounter
          currentLength={isNotBlank(restProps.value) ? restProps.value.length : 0}
          maxLength={maxLength}
        />
      )}
    </Wrapper>
  );
}
