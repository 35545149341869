import React from 'react';
import ReactOnRails from 'react-on-rails';
import { matchPath } from 'react-router-dom';
import type { AxiosResponse } from 'axios';
import {
  camelCase,
  endsWith,
  filter,
  flow,
  forEach,
  get,
  has,
  isArray,
  isEmpty,
  isNil,
  isPlainObject,
  isString,
  mapKeys,
  mapValues,
  ObjectIterator,
  some,
  trim,
  upperFirst,
} from 'lodash';

export const emptyObject = {};

export const emptyArray: [] = [];

export const isBrowser = () => typeof window !== 'undefined';

export const isHttps = () => isBrowser() && window.location.protocol === 'https:';

export const isInstanceOfFile = (fileOrUrl: any): fileOrUrl is File =>
  isBrowser() && fileOrUrl instanceof File;

export const getFileNameFromPath = (path?: string) => {
  if (!path || !isString(path)) return '';

  return ((path.split('\\').pop() || '').split('/').pop() || '').split('?')[0];
};

export const getPathName = () => {
  if (!isBrowser()) return '';
  const hash = window.location.hash !== '#/' ? window.location.hash : '';
  return `${window.location.pathname}${hash}`;
};

export const goToUrl = (pathName: string, params = {}) => {
  if (!isBrowser()) return;

  const url = new URL(`${window.location.origin}${pathName}`);

  forEach(params, (value, key) => url.searchParams.append(key, value));

  window.location.assign(url.toString());
};

export const getUrlWithoutSearchPart = (inputUrl: string) =>
  inputUrl.substring(0, inputUrl.indexOf('?'));

export const getIsOnPage = (pathName: string, path: string, exact = false, strict = false) =>
  Boolean(
    matchPath(pathName, {
      path,
      exact,
      strict,
    })
  );

export const isBlank = (str: string | undefined | null) =>
  isString(str) ? !trim(str) : isEmpty(str);

export const isNotBlank = (str: string | undefined | null): str is string => !isBlank(str);

export const withPreventDefault =
  <T extends (e: Event, ...args: any[]) => void>(func: T) =>
  (...args: Parameters<T>) => {
    const [event, ...restArgs] = args;

    event.preventDefault();

    if (func) {
      func(event, ...restArgs);
    }
  };

export const getString = (key: any, path: string): string => {
  const value = get(key, path);

  if (isNil(value)) return '';

  return isString(value) ? value || '' : value;
};

export const getActualYear = () => {
  const date = new Date();
  const year = date.getFullYear();

  return year;
};

export const getActualTime = () => {
  const dateObject = new Date();

  return dateObject.getTime();
};

export const objectToArray = (obj: { [key: string]: any }) =>
  Object.keys(obj).map(key => ({ key, ...obj[key] }));

export const arrayToObject = <T extends { [key: string]: any }>(
  array: T[],
  keyField = 'id'
): Record<string, unknown> =>
  array.reduce((obj: { [key: string]: any }, item: T) => {
    if (!has(item, keyField)) return obj;

    return {
      ...obj,
      [`${item[keyField]}`]: {
        ...obj[item[keyField]],
        ...item,
      },
    };
  }, {});

export const asyncForEach = async <T>(
  array: T[],
  callback: (item: T, index: number, array: T[]) => Promise<any>
) => {
  for (let index = 0; index < array.length; index += 1) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array);
  }
};

export const pascalCase = flow([camelCase, upperFirst]);

export const isSvg = (url: string) => endsWith(url, '.svg');

export const getAppStore = () => ReactOnRails.getStore('appStore');

export const mapKeysDeep = (
  obj: any,
  iterator: ObjectIterator<any, any>,
  isRecursive?: boolean
): any => {
  if (!obj && !isRecursive) {
    return {};
  }

  if (!isRecursive) {
    if (typeof obj === 'string' || typeof obj === 'number' || typeof obj === 'boolean') {
      return {};
    }
  }

  if (Array.isArray(obj)) {
    return obj.map(item => mapKeysDeep(item, iterator, true));
  }

  if (!isPlainObject(obj)) {
    return obj;
  }

  return mapValues(mapKeys(obj, iterator), value => mapKeysDeep(value, iterator, true));
};

export const endsWithCaseInsensitive = (value: string, target: string) =>
  endsWith((value || '').toLowerCase(), target);

export const endsWithCollection = (value: string, items: string[]) =>
  !isEmpty(filter(items, target => endsWithCaseInsensitive(value, target)));

// TODO: After changing the way we get metadata in all cases this method should be removed
export const getMetaNumberValue = (action: Record<string, unknown>, name: string) =>
  Number(get(action, `payload.data.meta.${name}`, 0));

export const objectToURLParams = <T extends Record<string, unknown>>(obj: T | null | undefined) => {
  const params = new URLSearchParams();

  forEach(obj, (value, key) => {
    if (value !== undefined) {
      if (isArray(value)) {
        forEach(value, v => {
          params.append(`${key}[]`, String(v));
        });
      } else {
        params.set(key, String(value));
      }
    }
  });

  return params.toString();
};

export const ensureIsArray = <T>(value: T) => (isArray(value) ? value : [value]);

export const waitFor = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const parseResponseHeaders = (response: AxiosResponse) => ({
  totalItems: Number(response.headers['x-total']),
  totalPages: Number(response.headers['x-total-pages']),
  prevPage: Number(response.headers['x-prev-page']) || undefined,
  nextPage: Number(response.headers['x-next-page']) || undefined,
});

export function hasElements(nodes: React.ReactNode): boolean {
  return some(React.Children.toArray(nodes), Boolean as unknown as ExcludeFalsy);
}

export const getLocalTimeZone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone;
