import { TopUpState } from 'types/entities/topUp';
import { AccountType } from 'components/TopUps/types';

export default {
  title: `Top-up request`,
  titleTooltip: `A service in which you request advice regarding a top-up on an existing investment.`,
  listTitle: `Top-up requests`,
  description: `Please provide us with information on your existing account, and the top-up investment that you wish to make. If necessary, we will contact you to clarify your request.`,
  createdTitle: `Your Top-up request has been created.`,
  createdDescription: `For your reference, a record has been added in the ‘Recommendations and Advice Requests’ section in your dashboard.`,
  details: `Top-up investment details`,
  saved: `Top-up has been successfully updated`,
  auditTrailSummary: `Below are the major events that occurred within this top-up.`,
  topUpImage: `Top-up image`,
  summary: {
    tooltip: `This is a summary of the client’s top-up need.`,
  },
  client: {
    heading: `You requested advice on a top-up to an existing investment.`,
    description: `On {value} you requested advice on a top-up investment. We will review your request and be in contact with you. If necessary, you can update the details that you provided to us.`,
    noClosedTopUps: `You have no closed top-up requests.`,
    noActiveTopUps: `You have no active top-up requests.{canRequest, select,
      true { You can <a>request a top-up</a>.}
      other {}
    }`,
  },
  timeline: {
    submitted: `Request submitted`,
    completed: `Request completed`,
  },
  cancel: {
    title: `Cancel top-up`,
    description: `Are you sure you want to cancel top-up {value}? This cannot be undone.`,
    confirm: `Yes, cancel top-up`,
    success: `Top-up has been successfully cancelled`,
  },
  changeState: {
    success: `Top-up status has been successfully changed`,
  },
  subtitles: {
    existingAccount: `Existing account`,
    topUpInvestment: `Top-up investment`,
    comment: `Comment`,
    clientComment: `Client's comment`,
  },
  states: {
    [TopUpState.Submitted]: `Submitted`,
    [TopUpState.Cancelled]: `Cancelled`,
    [TopUpState.Completed]: `Complete`,
  },
  explanations: {
    [TopUpState.Submitted]: `Your client submitted a top-up request to you.`,
    [TopUpState.Cancelled]: `Your client requested a top-up investment. The request was cancelled.`,
    [TopUpState.Completed]: `Your client requested a top-up investment. The request is complete.`,
  },
  adviserActions: {
    submitted: `
      <ul>
        <li>(Optional) Contact your client to clarify the top-up request.</li>
        <li>Send the client a reference number and payment instructions.</li>
        <li>Send the client a Recommendation Pack.</li>
      </ul>
    `,
  },
  labels: {
    accountType: `Account type`,
    accountTypeTooltip: `The account number should be available in policy documents that we have provided to you.`,
    accountNumber: `Account number`,
    amount: `Amount`,
  },
  accountTypes: {
    [AccountType.IndividualSavingsAccount]: `Individual Savings Account`,
    [AccountType.InternationalInvestmentBond]: `International Investment Bond`,
    [AccountType.InternationalRegularInvestmentBond]: `International Regular Investment Bond`,
    [AccountType.InvestmentBond]: `Investment Bond`,
    [AccountType.NotSure]: `I'm not sure`,
    [AccountType.RetirementAccount]: `Retirement Account`,
    [AccountType.TrusteeInvestmentAccount]: `Trustee Investment Account`,
    [AccountType.UnitTrust]: `Unit Trust`,
  },
  statusActions: {
    [`${TopUpState.Submitted}_${TopUpState.Completed}`]: {
      title: `Complete`,
      description: `When you have sufficient information to carry out the top-up, click here to change the status to ‘{to}’.`,
    },
  },
};
