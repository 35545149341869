import React, { FC } from 'react';

import Disclosure from 'components/Disclosure/Disclosure';
import FormActionResponsiveButtons from 'components/FormActionButtons/FormActionResponsiveButtons';

import { Fields, StepProps } from '../../types';

const StepDisclosure: FC<StepProps> = () => (
  <>
    <Disclosure checkboxName={Fields.DisclosureChecked} />
    <FormActionResponsiveButtons hasUnderline />
  </>
);

export default StepDisclosure;
