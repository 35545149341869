import api from 'api/apiInstance';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';
import type { ClientCreationLogEntry } from 'types/entities/clientCreationLogEntry';

export interface ClientsCreateInBulkPostOptions {
  file: File;
}

export interface ClientsCreateInBulkPostResponse {
  errors?: ClientCreationLogEntry[];
}

export function clientsCreateInBulkApi(path: string) {
  return {
    path,
    POST: (params: ClientsCreateInBulkPostOptions) =>
      api.post<ClientsCreateInBulkPostResponse>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      ),
  };
}
