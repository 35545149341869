import React from 'react';

import SvgIcon from '../SvgIcon';

const IconStopRecording = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <circle cx="10" cy="10" r="8" fill="transparent" stroke="currentColor" />
    <rect x="7" y="7" width="6" height="6" rx="1" fill="currentColor" />
  </SvgIcon>
);

export default IconStopRecording;
