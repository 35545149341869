export default {
  title: `Financial planning goals`,
  summary: `By declaring your reasons to invest, your financial adviser will be able to
      recommend investments that are tailored to your life objectives. In addition to such
      objectives, please also specify constraints that you have; for example, you may need
      access to capital or an income at some point in the future.`,
  reviewQuestionnaireSummary: `Please describe any new or changed financial planning goals that you’d like to discuss with your Adviser.`,
  adviserNoGoalsSummary: `Client has no goals`,
  clientNoGoalsSummary: `You have no goals`,
  button: `Add your first goal`,
  adviserButton: `Add first goal`,
  saveOverview: `Save overview`,
  overviewChanged: `Overview is not saved`,
  list: {
    addNewGoal: `Add new goal`,
    button: `Continue`,
    save: `Save`,
    discard: `Discard`,
    edit: `Edit`,
    delete: `Delete`,
    goalName: `Goal name`,
    goalNamePlaceholder: `Retirement`,
    goalValue: `Goal value`,
    goalValueTooltip: `How much money do you think that need in the future to support your goal?
      For example, you may need £50,000 for a deposit for a house. Or you may need £750,000 to
      retire.`,
    timeframe: `Timeframe`,
    timeframePlaceholder: `30`,
    timeframeTooltip: `When do you need your invested money and potential returns to achieve your
      goal? For example, you may wish to buy a house in 8 years. Or you may wish to retire
      in 30 years. `,
    modalTitle: `Delete goal`,
    modalDescription: `Are you sure you want to delete goal <b>{goal}</b>?`,
    modalSubmitButton: `Yes, delete goal`,
    modalDefaultButton: `No, don’t delete`,
    yearsSuffix: `years`,
  },
  note: {
    header: `Overview of your financial goals`,
    headerAdviser: `Overview of {clientName}’s financial goals`,
    tooltip: `Include a note for your adviser regarding your financials goals. Your goals could be
      anything from wanting to retire in a number of years, paying for a child’s future education,
      existing a business, to reducing a potential Inheritance Tax liability. Where relevant, also
      specify if you likely need access to a lump sum or to an income in the future.`,
    successMessage: `Note has been saved`,
  },
};
