import {
  CMR_GUIDE_AGENDA,
  CMR_GUIDE_DOCUMENTS,
  CMR_GUIDE_JOIN_BUTTON,
  CMR_GUIDE_TEST_BUTTON,
  TOUR_GUIDE_APP_BREADCRUMB,
  TOUR_GUIDE_APP_SIDEBAR,
  TOUR_GUIDE_CARD_AVATAR,
  TOUR_GUIDE_CLIENT_DASHBOARD,
  TOUR_GUIDE_NEW_INVESTMENT_BUTTON,
  TOUR_GUIDE_SIGN_OUT_BUTTON,
} from 'constants/tourGuide';
import { CaseState, CaseType } from 'types/entities/clientCase';

export default {
  backToDashboard: `Back to my dashboard`,
  label: {
    [CaseType.OnlineAdviceRequest]: `Online Advice title`,
    [CaseType.RecommendationPackCase]: `Recommendation Pack title`,
    [CaseType.ReviewQuestionnaireCase]: `Questionnaire title`,
    [CaseType.MeetingRequestCase]: `Meeting title`,
    requestStatus: `Status`,
  },
  submitCase: {
    buttonText: `I’m ready to submit`,
    labelText: `I’d like to review my answers`,
    modalTitle: `Your {caseName} was submitted.`,
    modalText: `Your Adviser will contact you in due course.`,
    modalBtn: `Go back to Dashboard`,
  },
  caseItems: {
    financialPlanning: {
      title: `Financial Planning Goals`,
      description: `Please tell us about your financial goals.`,
    },
    factFind: {
      title: `Fact Find`,
      description1: `We require information about your personal and financial situation.`,
      description2: `This is necessary to ensure that our recommendations are tailored to you and to your specific circumstances.`,
    },
    riskTolerance: {
      title: `Risk tolerance assessment`,
      description1: `We need to assess your risk tolerance with respect to investing.`,
      description2: `This will help us to recommend suitable investments.`,
    },
    submit: {
      title: `Submit your Online Advice request`,
      description: `Your Adviser will review your information, and make a personal recommendation to help to invest for your future.`,
    },
  },
  reviewQuestionnaire: {
    financialPlanning: {
      title: `Financial Planning Goals`,
      description: `Please describe any new or changed financial planning goals that you’d like to discuss with your Adviser.`,
    },
    financialAudit: {
      title: `Personal Financial Audit`,
      description: `Complete this personal audit to help identify important tax-saving and financial planning opportunities.`,
    },
    taxHealthCheckAudit: {
      title: `Tax Health Check`,
      description: `Complete this Tax Health Check to help identify important tax-saving and financial planning opportunities.`,
    },
    financialPlanningHealthCheck: {
      title: `Personal Financial Planning Health Check`,
      description: `Complete this Personal Financial Planning Health Check to help identify important tax-saving and financial planning opportunities.`,
    },
    factFind: {
      title: `Fact Find`,
      description: `Update your most recent Fact Find to inform us about changes in your circumstances.`,
    },
    submit: {
      title: `Submit`,
      description: `Once submitted, your Adviser will review your responses and discuss with you during your upcoming meeting.`,
    },
    caseStatus: {
      [CaseState.Building]: {
        title: `Submit your questionnaire`,
        summary: `Thank you for completing this questionnaire.
            After you submit, your Adviser will consider your responses,
            and will discuss potential planning opportunities
            with you during your upcoming meeting.`,
      },
      [CaseState.Submitted]: {
        title: `Your questionnaire is being reviewed`,
        summary: `You have submitted your questionnaire,
            and it is being reviewed. If necessary, or if requested by our office,
            you can edit your answers below.`,
      },
      [CaseState.Accepted]: {
        title: `Thank you. Our office has analysed your questionnaire.`,
        summary: `This questionnaire has been reviewed by your adviser and is now completed.`,
      },
      [CaseState.Cancelled]: {
        title: `This questionnaire has been cancelled`,
        summary: `Please see the case audit trail for further details.`,
      },
      caseReview: {
        linkText: `I’d like to review my answers`,
      },
    },
  },
  recommendationPack: {
    description: `Important: This Recommendation Pack details a personal
      recommendation to you. Please ensure that you understand the recommendation
      before you accept. Should you need clarification on the recommendation,
      please contact your Adviser.`,
  },
  createCase: {
    modal: {
      [CaseType.MeetingRequestCase]: {
        title: `Your Adviser will contact you soon.`,
        description: `You may optionally review your request, and complete a Fact Find, in the ‘Recommendations and Advice Requests’, section in your dashboard.`,
      },
      [CaseType.OnlineAdviceRequest]: {
        title: `Your Online Advice request has been created.`,
        description: `Next to complete and submit your request please go to the ‘Recommendations and Advice Requests’ section in your dashboard.`,
      },
    },
  },
  documents: {
    title: `Case documents`,
    recommendationPack: `Recommendation Pack`,
    consent: `I confirm I have read and understood the recommendation and the terms of this service.`,
    accept: `I accept and am happy to proceed`,
    reject: `I don’t accept the recommendation(s)`,
    rejectModal: {
      text: `Are you sure you want to reject the recommendation(s)?`,
      yes: `Yes, reject`,
      no: `No, go back`,
    },
  },
  casePreparation: {
    riskTolerance: `Risk tolerance`,
    factFind: `Fact Find`,
    financialAudit: `Personal Financial Audit`,
    taxHealthCheckAudit: `Tax Health Check`,
    financialPlanningHealthCheck: `Personal Financial Planning Health Check`,
    financialPlanningGoals: `Financial Planning Goals`,
    submitAdviceCase: `Submit Advice Case`,
    submitQuestionnaireCase: `Submit questionnaire`,
  },
  hints: `Hints`,
  tourGuide: {
    [TOUR_GUIDE_CARD_AVATAR]: `Click here to view your Adviser’s contact details.`,
    [TOUR_GUIDE_CLIENT_DASHBOARD]: `Click on these ‘cards’ to access your financial planning resources.`,
    [TOUR_GUIDE_APP_SIDEBAR]: `Your dynamic sidebar changes depending on the page that you visit. It contains various links and resources to help you.`,
    [TOUR_GUIDE_APP_BREADCRUMB]: `You can use these ‘breadcrumbs’ to navigate within this site.`,
    [TOUR_GUIDE_NEW_INVESTMENT_BUTTON]: `If you require advice, please click here or contact your Adviser or our office directly.`,
    [TOUR_GUIDE_SIGN_OUT_BUTTON]: `When you are finished within your account, please sign out.`,
    [CMR_GUIDE_TEST_BUTTON]: `Before you join a meeting, we highly recommend that you carry out a short test on your computer’s setup. The test results will guide you on potential technical issues that might prevent you from successfully partaking in your meeting.`,
    [CMR_GUIDE_AGENDA]: `Here is the proposed agenda for your meeting.`,
    [CMR_GUIDE_DOCUMENTS]: `Ahead of your meeting, you can review any documents that have been added. You can also add your own documents that you would like to discuss during the meeting.`,
    [CMR_GUIDE_JOIN_BUTTON]: `Click here to join your meeting.`,
  },
  welcome: {
    title: `Welcome {clientName}`,
    setUpProfile: `Before you proceed, let’s take a moment to set up your profile.`,
    note: `Note: You can update this information at any time in your account settings.`,
    videoLink: `This video can only be played using certain browsers. If you have trouble playing the video, you can click <link>here</link> to download it and then watch the video directly on your device using a media player.`,
    defaultMessage: `Dear {firstName},

Thank you for confirming your new profile, and welcome to our secure client portal; we are delighted that you will be using this service going forwards.

We hope that you benefit from using the portal to help manage your financial affairs.

Thank you`,
    panel1: `1. Confirm your personal information`,
    panel2: `2. Create your signature`,
  },
};
