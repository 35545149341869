import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions } from 'api/types';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

import { documentPackDocumentsApi } from './documents';
import { documentPackDocumentApi } from './documents/document';
import { documentPackMarkAsSeenApi } from './markAsSeen';

export interface DocumentPackPatchOptions {
  coverNote: string;
}

export function documentPackApi(path: string) {
  type DocumentPackDocumentApi = ReturnType<typeof documentPackDocumentApi>;
  type DocumentPackDocumentsApi = ReturnType<typeof documentPackDocumentsApi>;

  function documents(): DocumentPackDocumentsApi;
  function documents(documentId: number): DocumentPackDocumentApi;
  function documents(documentId?: number): DocumentPackDocumentsApi | DocumentPackDocumentApi {
    const documentsApiPath = `${path}/documents`;

    return documentId
      ? documentPackDocumentApi(`${documentsApiPath}/${documentId}`)
      : documentPackDocumentsApi(documentsApiPath);
  }

  return {
    path,
    documents,
    markAsSeen: () => documentPackMarkAsSeenApi(`${path}/mark-as-seen`),
    GET: (params?: GetOptions) => api.get<JsonApiResponseSingle>(path, { params }),
    PATCH: (params: DocumentPackPatchOptions) =>
      api.patch<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      ),
  };
}
