import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, PaginationOptions, RequestConfig } from 'api/types';
import { mapFormData } from 'helpers/formData';

export type AuditTrailGetOptions = PaginationOptions;

export interface IdentityVerificationPatchOptions {
  coverNote?: string;
  status?: string;
}

export interface VerificationPatchOptions {
  clientConsent: string;
}

export function clientIdentityVerificationApi(path: string) {
  return {
    path,
    GET: (params?: GetOptions) => api.get<JsonApiResponseSingle>(path, { params }),

    PATCH: (props: IdentityVerificationPatchOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(props),
        },
      }),
    AUTHORIZATION: () => `${path}/initiate_authorization`,
    IAMSMART_DOCUMENT: () => api.get(`${path}/bill`, { responseType: 'blob' }),
    INITIATE_IAM_SMART_AUTH: (params?: GetOptions) =>
      api.get<JsonApiResponseSingle>(`${path}/initiate_iam_smart_authorization`, { params }),
    MYINFO_RESPONSE_DATA: (params?: GetOptions) =>
      api.get<JsonApiResponseSingle>(`${path}/idv_data`, { params }),
    VERIFICATION: (props: VerificationPatchOptions) =>
      api.patch<JsonApiResponseSingle>(`${path}/consent_decision`, mapFormData(props)),
    AUDIT_TRAIL: (params: AuditTrailGetOptions, config?: RequestConfig) => {
      const { pageSize, pageNumber, ...rest } = params;
      return api.get<JsonApiResponse>(`${path}/audit-trail`, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...rest,
        },
        ...config,
      });
    },
    SERVER_STATUS: (params?: GetOptions) =>
      api.get<JsonApiResponseSingle>(`${path}/singpass_server_status`, { params }),
  };
}
