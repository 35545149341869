import { includes } from 'lodash';

import { getRailsContextEnv } from 'helpers/railsContext';
import { getAppStore } from 'helpers/utils';
import { getPracticeSettings } from 'store/settings/selectors';

import { Env, normalizeEnvironment } from './env';
import { convertToBool } from './formData';

export enum ServerSideFeature {
  AdviceRoom = 'adviceRoom',
  BecomeClient = 'becomeClient',
  Blog = 'blog',
  BrochureLibrary = 'brochureLibrary',
  ClientActivityFeed = 'clientActivityFeed',
  ClientDashboardLinks = 'clientDashboardLinks',
  ClientSignInNotification = 'clientSignInNotification',
  CustomizableFaq = 'customizableFaq',
  DocumentsPack = 'documentsPack',
  DocumentVault = 'documentVault',
  Docusign = 'docusign',
  DwsLogo = 'dwsLogo',
  ESignature = 'eSignature',
  EditablePdf = 'editablePdf',
  F2FRequest = 'f2FRequest',
  FinancialStrategy = 'financialStrategy',
  GdprDataDownload = 'gdprDataDownload',
  InviteProspectiveClient = 'inviteProspectiveClient',
  MotivationCentre = 'motivationCentre',
  IdentityVerificationIAMSmart = 'identityVerificationIamSmart',
  IdentityVerificationSingPass = 'identityVerificationSingpass',
  MyIdentification = 'myIdentification',
  EstateSummary = 'estateSummary',
  Messages = 'notes',
  OnlineAdvice = 'onlineAdvice',
  OurReferralScheme = 'ourReferralScheme',
  PreApprovedBrochures = 'preApprovedBrochures',
  PreferredAdviser = 'preferredAdviser',
  RecommendationPack = 'recommendationPack',
  RequestAdvice = 'requestAdvice',
  ReviewQuestionnaire = 'reviewQuestionnaire',
  // eslint-disable-next-line max-len
  QuestionnairePersonalFinancialPlanningHealthCheck = 'questionnairePersonalFinancialPlanningHealthCheck',
  QuestionnaireTaxHealthCheck = 'questionnaireTaxHealthCheck',
  RiskTolerance = 'riskTolerance',
  Todos = 'todos',
  TopUp = 'topUp',
  TwoFactorAuthentication = 'twoFactorAuthentication',
  TypeToSign = 'typeToSign',
  WelcomeVideo = 'welcomeVideo',
  Outlook = 'outlook',
}

export enum ClientSideFeature {
  CookiesConsent = 'cookiesConsent',
  PageTourGuide = 'pageTourGuide',
  Sketchpad = 'sketchpad',
  Statistics = 'statistics',
}

export type ServerSideFeatureValue = 'true' | 'false' | undefined | null;
export type Feature = ServerSideFeature | ClientSideFeature;

export const clientSideFeatureValues: Record<ClientSideFeature, Env[]> = {
  [ClientSideFeature.CookiesConsent]: [Env.Development, Env.Staging, Env.Beta, Env.Production],
  [ClientSideFeature.PageTourGuide]: [Env.Development, Env.Staging, Env.Beta, Env.Production],
  [ClientSideFeature.Sketchpad]: [Env.Development, Env.Test, Env.Staging, Env.Beta, Env.Production],
  [ClientSideFeature.Statistics]: [Env.Development, Env.Test, Env.Staging],
};

export const TEST_DATA: Record<ServerSideFeature, ServerSideFeatureValue> = {
  [ServerSideFeature.AdviceRoom]: 'true',
  [ServerSideFeature.BecomeClient]: 'true',
  [ServerSideFeature.Blog]: 'true',
  [ServerSideFeature.BrochureLibrary]: 'true',
  [ServerSideFeature.ClientActivityFeed]: 'true',
  [ServerSideFeature.ClientDashboardLinks]: 'true',
  [ServerSideFeature.CustomizableFaq]: 'true',
  [ServerSideFeature.DocumentsPack]: 'true',
  [ServerSideFeature.DocumentVault]: 'true',
  [ServerSideFeature.Docusign]: 'true',
  [ServerSideFeature.DwsLogo]: 'true',
  [ServerSideFeature.ESignature]: 'true',
  [ServerSideFeature.EditablePdf]: 'true',
  [ServerSideFeature.F2FRequest]: 'true',
  [ServerSideFeature.FinancialStrategy]: 'true',
  [ServerSideFeature.GdprDataDownload]: 'true',
  [ServerSideFeature.InviteProspectiveClient]: 'true',
  [ServerSideFeature.Messages]: 'true',
  [ServerSideFeature.MotivationCentre]: 'true',
  [ServerSideFeature.IdentityVerificationIAMSmart]: 'true',
  [ServerSideFeature.IdentityVerificationSingPass]: 'true',
  [ServerSideFeature.MyIdentification]: 'true',
  [ServerSideFeature.OnlineAdvice]: 'true',
  [ServerSideFeature.OurReferralScheme]: 'true',
  [ServerSideFeature.Outlook]: 'true',
  [ServerSideFeature.PreApprovedBrochures]: 'true',
  [ServerSideFeature.PreferredAdviser]: 'true',
  [ServerSideFeature.RecommendationPack]: 'true',
  [ServerSideFeature.RequestAdvice]: 'true',
  [ServerSideFeature.ReviewQuestionnaire]: 'true',
  [ServerSideFeature.QuestionnairePersonalFinancialPlanningHealthCheck]: 'true',
  [ServerSideFeature.QuestionnaireTaxHealthCheck]: 'true',
  [ServerSideFeature.RiskTolerance]: 'true',
  [ServerSideFeature.Todos]: 'true',
  [ServerSideFeature.TopUp]: 'true',
  [ServerSideFeature.ClientSignInNotification]: 'true',
  [ServerSideFeature.TwoFactorAuthentication]: 'true',
  [ServerSideFeature.TypeToSign]: 'true',
  [ServerSideFeature.WelcomeVideo]: 'true',
  [ServerSideFeature.EstateSummary]: 'true',
};

function isClientSideFeature(feature: Feature): feature is ClientSideFeature {
  return Object.prototype.hasOwnProperty.call(clientSideFeatureValues, feature);
}

export function isEnabled(feature: Feature) {
  const practiceSettings = getPracticeSettings(getAppStore().getState());

  if (isClientSideFeature(feature)) {
    const normalizedEnv = normalizeEnvironment(getRailsContextEnv());

    return includes(clientSideFeatureValues[feature], normalizedEnv);
  }

  if (practiceSettings) {
    return convertToBool(practiceSettings.featuresSettings[feature]);
  }

  return false;
}

export enum FeatureTileName {
  Welcome = 'welcome',
  Blog = 'blog',
  FinancialStrategy = 'financial_strategy',
  Messages = 'notes',
  RecommendationsAndRequests = 'recommendations_and_requests',
  AdviceRooms = 'advice_rooms',
  Questionnaires = 'questionnaires',
  IdentityVerification = 'identification_verification',
  DocumentsPacks = 'documents_packs',
  DocumentVault = 'document_vault',
  MotivationCentre = 'motiviation_centre',
  MyIdentification = 'my_identification',
  AccountSettings = 'account_settings',
  BrochureLibrary = 'brochure_library',
  OurServiceCharter = 'our_service_charter',
  OurServicesAndCharges = 'our_services_and_charges',
  EstateSummary = 'estate_summary',
}
