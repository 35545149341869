import type { UserRoleName } from 'types/entities/user';

import advisers from './advisers';
import blog from './blog';
import brochures from './brochures';
import clientCases from './clientCases';
import clientMeetingRooms from './clientMeetingRooms';
import clients from './clients';
import estateSummary from './estateSummary';
import identityVerifications from './identityVerifications';
import invitations from './invitations';
import meetings from './meetings';
import messagesSummary from './messagesSummary';
import motivationCentre from './motivationCentre';
import practiceAdmins from './practiceAdmins';
import practiceBrochures from './practiceBrochures';
import practiceSettings from './practiceSettings';
import servicesAndCharges from './servicesAndCharges';
import technicalSupportForm from './technicalSupportForm';
import topUps from './topUps';
import users from './users';
import { prefixRoute, Root, route } from './utils';
import vault from './vault';

const routes = {
  root: route(Root),
  accountSettings: route(`${Root}account-settings`),
  actionRequired: route(`${Root}action-required`),
  ...advisers,
  ...blog,
  ...brochures,
  ...clientCases,
  ...clientMeetingRooms,
  ...technicalSupportForm,
  ...clients,
  cookies: route(`${Root}cookies`),
  documents: route(`${Root}documents`),
  ...estateSummary,
  faq: route(`${Root}faq`),
  financialStrategies: route(`${Root}financial-strategy`),
  financialStrategy: route<[number]>(`${Root}financial-strategy/:strategyId`),
  howItWorks: route(`${Root}how-it-works`),
  howWeCommunicate: route(`${Root}how-we-communicate`),
  ...invitations,
  ...meetings,
  ...identityVerifications,
  messages: route(`${Root}messages`),
  ...messagesSummary,
  ...motivationCentre,
  myAdviser: route(`${Root}my-adviser`),
  myIdentification: route(`${Root}my-identification`),
  newCase: route(`${Root}new-case`),
  newMeeting: route(`${Root}new-meeting`),
  newTopUp: route(`${Root}new-top-up`),
  ...practiceAdmins,
  ...practiceBrochures,
  ...practiceSettings,
  practiceStatistics: route(`${Root}practice-statistics`),
  questionnaires: route(`${Root}questionnaires`),
  reports: route(`${Root}reports`),
  securityStatement: route(`${Root}security-statement`),
  serviceCharter: route<[number]>(`${Root}our-service-charters/:charterId`),
  serviceCharters: route(`${Root}our-service-charters`),
  ...servicesAndCharges,
  signUp: route(`${Root}request-a-meeting`),
  support: route(`${Root}support`),
  team: route(`${Root}team`),
  terms: route(`${Root}terms`),
  todos: route(`${Root}todos`),
  ...topUps,
  usageReports: route(`${Root}usage-reports`),
  ...users,
  ...vault,
  welcome: route(`${Root}welcome`),
};

export default routes;

export const getDashboardRoute = (userRole: UserRoleName) =>
  prefixRoute(userRole, routes.root.path());
