export default {
  loginPage: {
    title: `Sign in to your account`,
    otpTitle: `We sent a one-time passcode to your email address. Please enter the one-time passcode below.`,
    emailLabel: `Email address`,
    otp: `One-time password`,
    signInButtonLabel: `Sign in`,
    forgotPassword: `Forgot your password?`,
    capsLockEnabled: `Caps lock is enabled`,
    passwordIssues: {
      issue1: `If you have recently changed your email address, please check your inbox to confirm the changes made.`,
      issue2: `If you are unsure of what your password is, please use the option to reset your password.`,
    },
    errors: {
      locked_user: `After repeated unsuccessful sign-in attempts, this account has been locked for {lock_period} minutes.`,
      invalid_email_or_password: `Incorrect e-mail or password.`,
      invalid_2fa_code: `Provided code for two factor authentication is not valid.`,
    },
  },
  logout: {
    title: `Sign out of your account`,
    signOutButtonLabel: `Sign out`,
  },
  forgotPasswordPage: {
    title: `I forgot my password`,
    summary: `Please provide your email address and you will receive a message with
      a link to create a new password`,
    label: `Email address`,
    submit: `Reset password`,
    backToLogin: `Back to login`,
    modalSuccessMsg: `
      <p>Password reset instructions will be sent to {submittedEmail} if the address was found to exist. Please note that the password must be reset within 6 hours.</p>
      <p>If you have not yet confirmed your account, an account confirmation email will be re-sent to {submittedEmail}.</p>
      <p>Note: Please check both your inbox and your spam/junk folder.</p>`,
  },
  changePasswordPage: {
    title: `Change your password`,
    labelPassword: `New password`,
    labelPasswordConfirmation: `Confirm new password`,
    submit: `Change my password`,
    backToLogin: `Back to login`,
  },
  validation: {
    headerPassword: `Password rules:`,
    charactersMin: `Minimum of 10 characters`,
    charactersMax: `Maximum of 32 characters`,
    equalCharacters: `No more than 2 identical consecutive characters`,
    smallLetter: `One small letter`,
    capitalLetter: `One capital letter`,
    number: `One number`,
    specialCharacter: `One special character`,
    specialCharacterTooltip: `At least one character from the following set: {characters}`,
    noWhitespace: `No whitespace`,
    invalidPassword: `Invalid password. Please refer to the password rules.`,
    invalidCurrentPassword: `Incorrect password`,
  },
};
