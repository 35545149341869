import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { first, map } from 'lodash';

import { BaseDropdown, Props as DropdownProps } from 'components/Dropdown/BaseDropdown';

interface Props extends Omit<DropdownProps, 'options'> {
  devices: MediaDeviceInfo[];
}

export function DevicesDropdown({ devices, value, ...dropdownProps }: Props) {
  const intl = useIntl();

  const mappedDevices = useMemo(
    () =>
      map(devices, ({ deviceId, label }, index) => ({
        value: deviceId,
        label:
          label ||
          intl.formatMessage(
            {
              id: 'video.settings.device',
            },
            {
              number: index + 1,
            }
          ),
      })),
    [intl, devices]
  );

  return (
    <BaseDropdown
      options={mappedDevices}
      // if no device is selected, assume the first one
      value={value || first(mappedDevices)?.value}
      {...dropdownProps}
    />
  );
}
