import React, { useMemo, useState } from 'react';
import { Cookies, CookiesProvider } from 'react-cookie';
import { IntlProvider } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';
import { Normalize } from 'styled-normalize';
import { MatomoTracking } from 'tracking/MatomoTracking';
import { getTrackerSettings } from 'tracking/trackerSettings';

import { ClientSideFeature, isEnabled } from 'helpers/features';
import { getRailsContextCookies } from 'helpers/railsContext';
import messages, { flattenMessages } from 'i18n';
import routes from 'routes';
import { useGetAdviceRoomConnectionState } from 'store/videoChat/selectors';
import { useGetCurrentRegion } from 'store/website/selectors';
import AppCookieInfo from 'components/AppCookieInfo/AppCookieInfo';
import BannersWrapper from 'components/Banner/BannersWrapper';
import CollapseGlobalStyles from 'components/GenericCollapse/GenericCollapseGlobalStyles';
import ModalGlobalStyles from 'components/GenericModal/GenericModalGlobalStyles';
import SessionExpirationBanner from 'components/SessionExpirationBanner/SessionExpirationBanner';
import ToastContainer from 'components/ToastContainer/ToastContainer';
import LoggedInUserVideoProvider from 'components/VideoChat/LoggedInUserVideoProvider';
import { RoomConnectionState } from 'components/VideoChat/RoomConnectionState';

import GlobalCoreStyles from '../stylesheets/settings/core';
import CustomThemeProvider from './CustomThemeProvider';

const GlobalStyles = React.memo(() => (
  <>
    <Normalize />
    <GlobalCoreStyles />
    <CollapseGlobalStyles />
    <ModalGlobalStyles />
  </>
));

const App: React.FC = ({ children }) => {
  const languageSpecificMessages = useMemo(() => flattenMessages(messages.en), []);
  const currentRegion = useGetCurrentRegion();
  const settings = getTrackerSettings(currentRegion);
  const [trackerInstance] = useState(settings ? createInstance(settings) : null);
  const adviceRoomState = useGetAdviceRoomConnectionState();

  const match = useRouteMatch({
    path: [
      routes.client.clientMeetingRoom.testSetup.routerPath(),
      routes.meetings.clientMeetingRoom.testSetup.routerPath(),
      routes.meetings.clientMeetingRoom.meeting.routerPath(),
      routes.client.clientMeetingRoom.meeting.routerPath(),
    ],
  });

  const content = (
    <CustomThemeProvider>
      <GlobalStyles />

      <BannersWrapper>
        <SessionExpirationBanner />
      </BannersWrapper>

      {isEnabled(ClientSideFeature.CookiesConsent) && <AppCookieInfo />}

      <ToastContainer />

      {adviceRoomState !== RoomConnectionState.Disconnected || match ? (
        <LoggedInUserVideoProvider>{children}</LoggedInUserVideoProvider>
      ) : (
        <>{children}</>
      )}
    </CustomThemeProvider>
  );

  return (
    <IntlProvider defaultLocale="en-GB" locale="en-GB" messages={languageSpecificMessages}>
      <CookiesProvider cookies={new Cookies(getRailsContextCookies())}>
        {trackerInstance ? (
          <MatomoProvider value={trackerInstance}>
            <MatomoTracking />

            {content}
          </MatomoProvider>
        ) : (
          content
        )}
      </CookiesProvider>
    </IntlProvider>
  );
};

export default App;
