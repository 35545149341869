export default {
  summary: `Prepared: 5th May 2022`,
  section1: {
    content1: `Digital Wealth Solutions (DWS) is committed to maintaining high standards in systems and processes to protect personal data in accordance with good industry practice and to comply with regulations including General Data Protection Regulation (GDPR). As security is crucial to our clients, and to the clients of our clients, below is a summary of our information security features and practices.`,
  },
  section2: {
    title: `Development and operational processes and standards`,
    content1: `DWS’s technology was built and is maintained by our specialist development partners, in accordance with the recommendations of the ISO27001 standard. Our current partner, Capital Numbers, have built an Information Security Management System (ISMS) to govern development. This ISMS’s compliance with ISO27001 has been confirmed several times by internal and external audits. Capital Numbers also follow the Secure Software Development Lifecycle (S-SDLC) to ensure the security of the Digital Wealth Solutions product. S-SDLC helps to analyse and minimise security risks at various stages of the development process.`,
    content2: `Capital Numbers also follow the OWASP Application Verification Standard as the basis for creating and managing technology products in line with best practices, and carry out frequent security reviews on the DWS product.`,
  },
  section3: {
    title: `Information security layers`,
    content1: `The DWS team and our development partners have implemented and maintain multiple layers of security, and follow industry best practices, as measures to protect our clients’ data from unauthorised or unlawful breach of security that leads to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of or access to information, and to preserve the security and confidentiality of the data.`,
    subtitle1: `Physical layer`,
    content2: `To avail of world-class information security, our application and database are hosted by Amazon Web Services (AWS) data centres. Details of AWS data centres’ security controls are available <link>here</link>.`,
    subtitle2: `Access control layer`,
    content3: `Access to DWS-powered websites and the DWS platform is controlled by a custom access control layer. Following user-type policies, this layer helps to ensure that only the correct individual users have access to the correct information.`,
    content4: `To further enhance access control, two-factor authentication is optionally available to our clients, and to their clients.`,
    subtitle3: `Application layer`,
    content5: `Our webservers and databases are developed, configured and managed according to an ISO270001-compliant ISMS.`,
    content6: `Our applications are developed and tested using various processes and tools to check for security vulnerabilities – including static security analysis, dependency analysis, automatic vulnerability assessment. Error reporting and monitoring systems also continuously monitor the DWS application and track important application metrics.`,
    content7: `We also regularly invite 3rd-party information security companies to carry out penetration tests on our applications and to identify security enhancements.`,
    subtitle4: `Network layer`,
    content8: `Our network-level security mechanisms include controls such as firewalls, intrusion detection systems, intrusion prevention systems, and anti-virus software. Data communications are protected via the encrypted HTTPS (Hyper Text Transfer Protocol Secure) protocol.`,
    subtitle5: `Data layer`,
    content9: `Our databases and file systems are all encrypted. Access to these data repositories is controlled using Identify and Access Management (IAM) standards and processes.`,
  },
  section4: {
    title: `Ongoing improvements`,
    content1: `DWS and our development partner continually maintain and monitor notifications, errors, logs and alerts on our services, and from all systems to identify and manage security threats. We also maintain information security policies, including incident response plans. We also regularly carry out internal and external audits of our security.`,
  },
  section5: {
    title: `User’s responsibilities`,
    content1: `Keeping data safe also depends on users ensuring that they preserve the security of their own account, systems and personal information. Over and above the aforementioned security precautions, it is imperative that users preserve the security of their account.`,
  },
};
