export enum FinancialPlanningTopic {
  InvestmentPlanning = 'investmentPlanning',
  RetirementPlanning = 'retirementPlanning',
  TrustAndEstatePlanning = 'trustAndEstatePlanning',
  LifeAssurance = 'lifeAssurance',
  LongTermCare = 'longTermCare',
  HealthInsurance = 'healthInsurance',
  Mortgages = 'mortgages',
  SpecialistLending = 'specialistLending',
  CorporateFinancialPlanning = 'corporateFinancialPlanning',
  CashSavings = 'cashSavings',
  GeneralInsurance = 'generalInsurance',
  DebtManagement = 'debtManagement',
  EducationPlanning = 'educationPlanning',
  DisabilityInsurance = 'disabilityInsurance',
  RefinancingMortgages = 'refinancingMortgages',
  GroupCorporateSchemes = 'groupCorporateSchemes',
  PensionReview = 'pensionReview',
  Repatriation = 'repatriation',
  IntergenerationalWealthPlanning = 'intergenerationalWealthPlanning',
  ExpatAndRepatriation = 'expatAndRepatriation',
}

export default [
  FinancialPlanningTopic.InvestmentPlanning,
  FinancialPlanningTopic.RetirementPlanning,
  FinancialPlanningTopic.TrustAndEstatePlanning,
  FinancialPlanningTopic.LifeAssurance,
  FinancialPlanningTopic.LongTermCare,
  FinancialPlanningTopic.HealthInsurance,
  FinancialPlanningTopic.Mortgages,
  FinancialPlanningTopic.SpecialistLending,
  FinancialPlanningTopic.CorporateFinancialPlanning,
  FinancialPlanningTopic.CashSavings,
  FinancialPlanningTopic.GeneralInsurance,
  FinancialPlanningTopic.DebtManagement,
  FinancialPlanningTopic.EducationPlanning,
  FinancialPlanningTopic.DisabilityInsurance,
  FinancialPlanningTopic.RefinancingMortgages,
  FinancialPlanningTopic.GroupCorporateSchemes,
  FinancialPlanningTopic.PensionReview,
  FinancialPlanningTopic.Repatriation,
  FinancialPlanningTopic.IntergenerationalWealthPlanning,
  FinancialPlanningTopic.ExpatAndRepatriation,
];
