import type { SectionCommon, Source } from './utils';

export enum AssetType {
  Pension = 'pension',
  Cash = 'cash',
  Isa = 'isa',
  UnitTrust = 'unitTrust',
  InvestmentBond = 'investmentBond',
  StockShares = 'stockShares',
  OtherInvestment = 'otherInvestment',
  Business = 'business',
  Property = 'property',
  OtherValuableAsset = 'otherValuableAsset',
}

export interface Asset {
  type: AssetType;
  amount: string;
  amount_currency: string;
  source: Source;
  own_share: number;
  policy_number?: string;
  policy_provider_name?: string;
  description?: string;
}

export enum LiabilityType {
  Mortgage = 'mortgage',
  PersonalLoan = 'personalLoan',
  CarLoan = 'carLoan',
  CreditCardDebt = 'creditCardDebt',
  StudentLoan = 'studentLoan',
  OtherDebt = 'otherDebt',
}

export interface Liability {
  type: LiabilityType;
  amount: string;
  amount_currency: string;
  source: Source;
  own_share: number;
  description?: string;
}

export interface AssetsLiabilities extends SectionCommon {
  assets: Asset[] | null;
  liabilities: Liability[] | null;
}
