import React from 'react';
import { isNumber } from 'lodash';
import { getValueAndUnit, rem, size as polishedSize } from 'polished';
import styled, { css } from 'styled-components';

import { rotate } from 'helpers/styles/animations';
import SvgIcon from 'components/Icons/SvgIcon';

const StyledLoader = styled.div<{ isInlineFlex?: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;

  ${({ isInlineFlex }) =>
    isInlineFlex &&
    css`
      display: inline-flex;
      width: auto;
    `}
`;

interface IconProps {
  size: number | string;
  color?: string;
}

const StyledIcon = styled(SvgIcon)<IconProps>`
  ${({ size }) => {
    if (isNumber(size)) {
      return polishedSize(rem(size));
    }

    const [value, unit] = getValueAndUnit(size);

    if (unit === 'px') {
      return polishedSize(rem(value));
    }

    return polishedSize(size);
  }};
  stroke: ${({ color, theme }) => color || theme.colors.accent};
  animation: ${rotate} 2s infinite linear;
`;

interface Props {
  className?: string;
  isVisible?: boolean;
  size?: number | string;
  color?: string;
  isInlineFlex?: boolean;
}

const GenericLoader = ({
  className = '',
  isVisible = false,
  size = 50,
  color,
  isInlineFlex,
}: Props) => {
  if (!isVisible) {
    return null;
  }

  return (
    <StyledLoader className={className} isInlineFlex={isInlineFlex} data-test="generic-loader">
      <StyledIcon size={size} color={color} viewBox="0 0 38 38">
        <g fill="none" fillRule="evenodd" strokeWidth="2">
          <circle strokeOpacity=".5" cx="19" cy="19" r="18" />
          <path d="M37 19A18 18 0 0 0 19 1" />
        </g>
      </StyledIcon>
    </StyledLoader>
  );
};

export default GenericLoader;
