import { useCallback, useEffect, useState } from 'react';
import Bowser from 'bowser';

import { DevicePermissions } from '../types';

interface Params {
  name: 'camera' | 'microphone';
}

const requestMediaPermissions = (constraints?: MediaStreamConstraints) => {
  return new Promise<DevicePermissions>(resolve => {
    navigator.mediaDevices
      .getUserMedia(constraints ?? { audio: true, video: true })
      .then((stream: MediaStream) => {
        stream.getTracks().forEach(t => {
          t.stop();
        });
        resolve(DevicePermissions.Granted);
      })
      .catch((err: any) => {
        const browser = Bowser.getParser(window.navigator.userAgent);
        const browserName = browser.getBrowserName();

        const errName = err.name;
        const errMessage = err.message;
        let errorType: DevicePermissions = DevicePermissions.Unknown;
        if (browserName === 'Chrome') {
          if (errName === 'NotAllowedError') {
            if (errMessage === 'Permission denied by system') {
              errorType = DevicePermissions.SystemDenied;
            } else if (errMessage === 'Permission denied') {
              errorType = DevicePermissions.Denied;
            }
          } else if (errName === 'NotReadableError') {
            errorType = DevicePermissions.Denied;
          }
        } else if (browserName === 'Safari') {
          if (errName === 'NotAllowedError') {
            errorType = DevicePermissions.Denied;
          }
        } else if (browserName === 'Microsoft Edge') {
          if (errName === 'NotAllowedError') {
            errorType = DevicePermissions.Denied;
          } else if (errName === 'NotReadableError') {
            errorType = DevicePermissions.Denied;
          }
        } else if (browserName === 'Firefox') {
          if (errName === 'NotFoundError') {
            errorType = DevicePermissions.SystemDenied;
          } else if (errName === 'NotReadableError') {
            errorType = DevicePermissions.SystemDenied;
          } else if (errName === 'NotAllowedError') {
            errorType = DevicePermissions.Denied;
          } else if (errName === 'AbortError') {
            errorType = DevicePermissions.Denied;
          }
        }

        resolve(errorType);
      });
  });
};

function useDevicePermissions({ name }: Params) {
  const [permissionStatus, setPermissionStatus] = useState<DevicePermissions>(
    DevicePermissions.Unknown
  );

  const updatePermissions = useCallback(() => {
    requestMediaPermissions({ audio: name === 'microphone', video: name === 'camera' })
      .then(status => {
        setPermissionStatus(status);
      })
      .catch(() => {
        setPermissionStatus(DevicePermissions.Denied);
      });
  }, [name]);

  useEffect(() => {
    updatePermissions();
  }, [updatePermissions]);

  useEffect(() => {
    if (navigator.mediaDevices?.addEventListener) {
      navigator.mediaDevices.addEventListener('devicechange', updatePermissions);
    }

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', updatePermissions);
    };
  }, [updatePermissions]);

  return {
    devicePermissions: permissionStatus,
  };
}

export function useMicrophonePermissions() {
  const { devicePermissions } = useDevicePermissions({ name: 'microphone' });

  return { microphonePermissions: devicePermissions };
}

export function useCameraPermissions() {
  const { devicePermissions } = useDevicePermissions({ name: 'camera' });

  return { cameraPermissions: devicePermissions };
}
