import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

export interface MotivationCentreCommentsPostOptions {
  content: string;
}

export function clientMotivationCentreCommentsApi(path: string) {
  return {
    path,
    POST: (params: MotivationCentreCommentsPostOptions) =>
      api.post<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      ),
  };
}
