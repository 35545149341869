import React, { isValidElement, PureComponent } from 'react';
import { isEmpty, isString } from 'lodash';
import { rem } from 'polished';
import scrollIntoView from 'scroll-into-view-if-needed';
import styled, { css } from 'styled-components';

import { isBrowser } from 'helpers/utils';

interface ErrorMsgProps {
  hasFullWidth?: boolean;
  hasNoMarginTop?: boolean;
  warning?: boolean;
}

const ErrorMsg = styled.div<ErrorMsgProps>`
  position: relative;
  order: 1;
  max-width: ${rem(300)};
  margin-top: ${rem(8)};
  line-height: 1.5;
  transition: opacity 0.3s ease-in-out;
  ${({ theme, warning }) => css`
    color: ${warning ? theme.colors.orangeWarning : theme.colors.crail};
    font-size: ${theme.fontSizes.dbSmall};
  `};

  ${({ hasFullWidth }) =>
    hasFullWidth &&
    css`
      width: 100%;
      max-width: 100%;
    `};

  ${({ hasNoMarginTop }) => hasNoMarginTop && 'margin-top: 0'};

  &[aria-hidden='true'] {
    opacity: 0;

    &:before {
      /* We need to render something to preserve space */
      content: '-';
      visibility: hidden;
    }
  }

  &[aria-hidden='false'] {
    opacity: 1;
  }
`;

// To ensure nice offset from top of the screen
const ScrollTarget = styled.div<{ className: string }>`
  position: absolute;
  top: ${rem(-120)};
`;

export const SCROLL_TARGET_CLASS = 'error-msg';

interface Props extends ErrorMsgProps {
  message: React.ReactNode;
  isVisible?: boolean;
  warning?: boolean;
}

export default class GenericErrorMsg extends PureComponent<Props> {
  public static scrollIntoFirstError = (): void => {
    if (!isBrowser()) {
      return;
    }

    const element = document.querySelector(`[aria-hidden="false"] .${SCROLL_TARGET_CLASS}`);

    if (element) {
      scrollIntoView(element, {
        behavior: 'smooth',
      });
    }
  };

  public render() {
    const { hasFullWidth, message, hasNoMarginTop, isVisible = true, warning } = this.props;

    if (isValidElement(message) || (isString(message) && !isEmpty(message))) {
      return (
        <ErrorMsg
          hasFullWidth={hasFullWidth}
          hasNoMarginTop={hasNoMarginTop}
          data-test="error-message"
          aria-hidden={!isVisible}
          warning={warning}
        >
          {message}
          <ScrollTarget className={SCROLL_TARGET_CLASS} />
        </ErrorMsg>
      );
    }

    return null;
  }
}
