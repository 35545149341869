import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

interface ClientCaseUpdatePrivateNotePatchOptions {
  privateNote?: string;
  sendToPracticeNotes?: boolean;
}

export function clientCaseUpdatePrivateNoteApi(path: string) {
  return {
    path,
    PATCH: (params: ClientCaseUpdatePrivateNotePatchOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
