import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions } from 'api/types';

export type ClientClientCaseGetResponse = JsonApiResponseSingle;

export function clientClientCaseApi(path: string) {
  return {
    path,
    GET: (params?: GetOptions) => api.get<ClientClientCaseGetResponse>(path, { params }),
  };
}
