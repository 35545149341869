import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';

export function clientFollowsUnfollowApi(path: string) {
  return {
    path,
    DELETE: () => api.delete<JsonApiResponseSingle>(path),
  };
}
