import { Root, route } from '../utils';

const motivationCentrePath = `${Root}motivation-centre`;

type MotivationCentreItemParams = [itemId: number];

export default {
  motivationCentre: {
    ...route(motivationCentrePath),
    new: route(`${motivationCentrePath}/new`),
    edit: {
      item: route<MotivationCentreItemParams>(`${motivationCentrePath}/edit/:itemId`),
    },
  },
};
