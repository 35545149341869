import { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import type { FormProps } from 'react-final-form';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { addYears } from 'date-fns';

import { ANALYTICS_CONSENT_COOKIE_NAME, COOKIE_NAME } from 'constants/cookies';

export interface Values {
  necessaryCookiesConsent: boolean;
  analyticsCookiesConsent: boolean;
}

export interface Props {
  isVisible?: boolean;
  onChanged?: () => void;
}

export interface RenderProps {
  onSubmit: FormProps<Values>['onSubmit'];
  initialValues: FormProps<Values>['initialValues'];
}

const AppCookieInfoContainer = ({
  isVisible,
  onChanged,
  render,
}: Props & ContainerProps<RenderProps>) => {
  const [cookies, setCookie] = useCookies([COOKIE_NAME, ANALYTICS_CONSENT_COOKIE_NAME]);

  const areNecessaryCookiesAccepted = cookies[COOKIE_NAME] as string | undefined;
  const areAnalyticsCookiesAccepted = cookies[ANALYTICS_CONSENT_COOKIE_NAME] as string | undefined;

  const { pushInstruction } = useMatomo();

  const onSubmit = useCallback<RenderProps['onSubmit']>(
    data => {
      if (!cookies) {
        return;
      }

      pushInstruction(
        data.analyticsCookiesConsent ? 'rememberCookieConsentGiven' : 'forgetCookieConsentGiven'
      );

      setCookie(ANALYTICS_CONSENT_COOKIE_NAME, data.analyticsCookiesConsent, {
        path: '/',
        expires: addYears(new Date(), 1),
      });

      setCookie(COOKIE_NAME, true, {
        path: '/',
        expires: addYears(new Date(), 1),
      });

      if (onChanged) {
        onChanged();
      }
    },
    [cookies, onChanged, pushInstruction, setCookie]
  );

  const renderProps = {
    onSubmit,
    initialValues: {
      necessaryCookiesConsent: true, // as being necessary, should always be selected
      analyticsCookiesConsent: areAnalyticsCookiesAccepted === 'true',
    },
  };

  if (isVisible) {
    return render(renderProps);
  }

  if (isVisible === false) {
    return null;
  }

  return areNecessaryCookiesAccepted != null && areAnalyticsCookiesAccepted != null
    ? null
    : render(renderProps);
};

export default AppCookieInfoContainer;
