import api from 'api/apiInstance';

export interface AdviserMetaAdviceRoomsGetResponse {
  meeting_room_today: boolean;
}

export function adviserMetaAdviceRoomsApi(path: string) {
  return {
    path,
    GET: () => api.get<AdviserMetaAdviceRoomsGetResponse>(path),
  };
}
