import React, { useCallback, useEffect, useRef } from 'react';
import { Field, Form, FormProps } from 'react-final-form';
import { useIntl } from 'react-intl';
import { map } from 'lodash';
import { darken, rem } from 'polished';
import styled, { css } from 'styled-components';

import { required } from 'helpers/validators';
import { Avatar, AvatarSize } from 'components/Avatar';
import DateTime from 'components/DateTime/DateTime';
import IconKite from 'components/Icons/IconKite';
import { useMessagesContext } from 'components/VideoChat/MessagesContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MessageList = styled.ul`
  flex-grow: 1;
  overflow: auto;
`;

const MessageItem = styled.li<{ isSender: boolean }>`
  display: flex;
  align-items: flex-start;

  ${({ isSender }) =>
    isSender &&
    css`
      flex-direction: row-reverse;
      text-align: right;
    `};

  & + & {
    margin-top: ${rem(10)};
  }

  & > * {
    margin: 0 ${rem(4)};
  }
`;

const MessageText = styled.div`
  display: inline-block;
  padding: ${rem(8)};
  text-align: left;
  background-color: ${({ theme }) => theme.colors.panelBg};
  color: ${({ theme }) => theme.colors.textDark};
  border-radius: ${rem(2)};
  word-break: break-word;
  word-wrap: break-word;
`;

const MessageData = styled.div`
  margin-top: ${rem(4)};
  font-size: ${({ theme }) => theme.fontSizes.dbSmallMd};
  color: ${({ theme }) => theme.colors.white};
`;

const StyledForm = styled.form`
  display: flex;
  flex-shrink: 0;
  margin-top: ${rem(10)};
`;

const Input = styled.input`
  flex-grow: 1;
  margin-right: ${rem(10)};
  padding: ${rem(8)};
`;

const IconSend = styled(IconKite)`
  position: relative;
  height: ${rem(24)};
  width: ${rem(24)};
  color: ${({ theme }) => theme.colors.white};
`;

const SendButton = styled.button`
  padding: 0;
  outline: none;

  &:not(:disabled) {
    &:focus,
    &:hover,
    &:active {
      ${IconSend} {
        color: ${({ theme }) => darken(0.3, theme.colors.white)};
      }
    }

    &:active {
      ${IconSend} {
        top: 1px;
      }
    }
  }

  :disabled {
    cursor: default;

    ${IconSend} {
      color: ${({ theme }) => darken(0.5, theme.colors.white)};
    }
  }
`;

interface Values {
  text: string;
}

function RoomMessages() {
  const intl = useIntl();
  const { messages, sendMessage, userEmail } = useMessagesContext();
  const listRef = useRef<HTMLUListElement>(null);

  const submit = useCallback<FormProps<Values>['onSubmit']>(
    (values, form) => {
      sendMessage(values.text);
      setTimeout(() => form.reset());
    },
    [sendMessage]
  );

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <Wrapper>
      <MessageList ref={listRef}>
        {map(messages, message => (
          <MessageItem key={message.id} isSender={message.senderEmail === userEmail}>
            <div>
              <Avatar alt={message.senderName} src={message.senderAvatar} size={AvatarSize.S} />
            </div>

            <div>
              <MessageText>{message.text}</MessageText>

              <MessageData>
                {message.senderName && <span>{message.senderName}, </span>}
                <DateTime value={message.date} />
              </MessageData>
            </div>
          </MessageItem>
        ))}
      </MessageList>

      <Form<Values>
        onSubmit={submit}
        render={({ handleSubmit, submitting, valid }) => (
          <StyledForm onSubmit={handleSubmit}>
            <Field<string> name="text" validate={required}>
              {({ input }) => (
                <Input
                  type="text"
                  {...input}
                  placeholder={intl.formatMessage({ id: 'adviceRooms.messageInputPlaceholder' })}
                />
              )}
            </Field>

            <SendButton type="submit" disabled={submitting || !valid}>
              <IconSend />
            </SendButton>
          </StyledForm>
        )}
      />
    </Wrapper>
  );
}

export default RoomMessages;
