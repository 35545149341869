import { isPlainObject, isString } from 'lodash';
import { margin, rem } from 'polished';
import type { Styles } from 'polished/lib/types/style';
import styled, { css } from 'styled-components';

import { breakpointNameArray, BreakpointValues } from 'helpers/responsiveness';
import { breakpoints } from 'helpers/styles/constants';

type BreakpointMarginValues = BreakpointValues<string | number>;

type MarginValue = BreakpointMarginValues | string | number;

function areBreakpoints(value: MarginValue): value is BreakpointMarginValues {
  return isPlainObject(value);
}

function prepareMarginValue(value: string | number): string {
  return isString(value) ? value : rem(value);
}

function breakpoint(values: MarginValue, callback: (preparedMarginValue: string) => Styles) {
  if (!areBreakpoints(values)) {
    return callback(prepareMarginValue(values));
  }

  return breakpointNameArray.map(size => {
    const marginValue = values[size];

    return marginValue != null
      ? css`
          @media ${breakpoints[size]} {
            ${callback(prepareMarginValue(marginValue))};
          }
        `
      : null;
  });
}

export interface Props {
  all?: MarginValue;
  horizontal?: MarginValue;
  vertical?: MarginValue;
  top?: MarginValue;
  right?: MarginValue;
  bottom?: MarginValue;
  left?: MarginValue;
}

const Margin = styled.div<Props>`
  ${({ all, horizontal, vertical, top, right, bottom, left }) =>
    css`
      ${all && breakpoint(all, marginValue => margin(marginValue))};
      ${horizontal && breakpoint(horizontal, marginValue => margin(null, marginValue))};
      ${vertical && breakpoint(vertical, marginValue => margin(marginValue, null))};
      ${top && breakpoint(top, marginValue => margin(marginValue, null, null, null))};
      ${right && breakpoint(right, marginValue => margin(null, marginValue, null, null))};
      ${bottom && breakpoint(bottom, marginValue => margin(null, null, marginValue, null))};
      ${left && breakpoint(left, marginValue => margin(null, null, null, marginValue))};
    `};
`;

export default Margin;
