import api from 'api/apiInstance';
import type { RecordingType } from 'components/VideoChat/RecordingContext';

export interface RecordingState {
  type: RecordingType;
  recording: boolean;
}
export interface UpdateRecordingStateParams {
  type: RecordingType;
  state: 'start' | 'stop';
}
export function adviceRoomUpdateRecordingsStateApi(path: string) {
  return {
    path,
    PATCH: (data: UpdateRecordingStateParams) =>
      api.patch(path, {
        data: {
          attributes: {
            ...data,
          },
        },
      }),
  };
}
