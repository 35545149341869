import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, PaginationOptions, RequestConfig } from 'api/types';

export type PracticeAdminClientsGetOptions = GetOptions & PaginationOptions;

export function practiceAdminClientsApi(path: string) {
  return {
    path,
    GET: (params: PracticeAdminClientsGetOptions = {}, config?: RequestConfig) => {
      const { pageSize, pageNumber, ...rest } = params;

      return api.get<JsonApiResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...rest,
        },
        ...config,
      });
    },
  };
}
