import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

export interface GuestTwilioTokenPostOptions {
  authToken: string;
}

export function guestTwilioTokenApi(path: string) {
  return {
    path,
    POST: (params: GuestTwilioTokenPostOptions) =>
      api.post<{ token: string }>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
