import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';

const apiPath = '/api/v1/maintenances';

function maintenancesApi(path: string) {
  return {
    path,
    GET: () => api.get<JsonApiResponseSingle>(path),
  };
}

function maintenances() {
  return maintenancesApi(apiPath);
}

export { maintenances };
