import { FinancialAuditQuestionName, FinancialAuditVersion } from 'types/entities/financialAudit';

const commonDescriptions = {
  [FinancialAuditQuestionName.IsaInvested]: `
    <p>
      Your client and/or (if relevant) their Partner have not (may have not) made (nor have planned to make arrangements for) the maximum contributions to their ISAs – £20,000.
    </p>
    <p>
      As an ISA delivers tax free interest and/or dividend income (and capital gains) it is an extremely tax effective investment wrapper for their deposits and investments. ISAs cannot however be assigned or made subject to trust for IHT planning.
    </p>
    <p>
      Ensure, subject to economic and investment considerations and the relative importance of IHT planning, that your client and if relevant, their spouse/civil partner maximises the amount of their investments/deposits held inside ISAs.
      If they make regular contributions ensure that the regular contribution amount is increased to ensure contributions reach the current £20,000 maximum total for the tax year.
    </p>
    <p>
      For couples it is permissible for one Partner to transfer funds to the other to facilitate an ISA investment. There is no CGT consequence to consider where the funds are cash and the transfer will be exempt, regardless of its amount if the
      transferor and transferee are married or registered civil partners.
    </p>
    <p>
      If the Client has children under the age of 18 then each child can have a Junior ISA into which £4,368 can be invested in total in the 2019/20 tax year.
    </p>
  `,
  [FinancialAuditQuestionName.ReceiveInInterest]: `
    <p>
      Your Client receives or may receive less than £1,000 per tax year (£500 for higher rate taxpayers based on a threshold of £50,000) in interest.
    </p>
    <p>
      Consider (subject to economic and investment considerations and other available cash) investing to produce interest that will fall within their appropriate “Personal Savings Allowance” of £1,000 (basic rate taxpayers) or £500 (higher rate taxpayers).
    </p>
    <p>
      Note that distributions from unit trusts, OEICs and offshore funds that are invested more than 60% in fixed interest securities are treated as interest for tax purposes as are chargeable gains on offshore bonds.
    </p>
    <p>
      The Personal Savings Allowance applies to all Savings Income i.e. interest, the interest element of annuity payments and gains under investment bonds. In relation to investment bonds, in practice this means offshore investment bonds. The PSA can be used to set against
      offshore bond gains if not used against other savings income.
    </p>
    <p>
      In addition to the Personal Savings Allowance an individual can have up to a further £5,000 of savings income (including gains under an offshore bond) taxed at 0%. This tax-free amount is reduced by £1 for every £1 of non-savings, non-dividend income
      (e.g. earnings, pensions income) that the Client receives in the tax year above their personal allowance.
    </p>
  `,
  [FinancialAuditQuestionName.ReceiveInDividends]: `
    <p>
      Your client receives/may receive less than the £2,000 tax free dividend allowance per tax year in dividends.
    </p>
    <p>
      Consider investing in dividend paying unit trusts (regardless of whether the income produced is taken or
      re-invested/accumulated). Where the dividends produced are within the investor’s annual £2,000 dividend
      allowance they will be tax free. Also remember that any reinvested dividend will be added to the acquisition price of the
      investment when calculating any capital gain on future realisation/disposal. Once the dividend allowance has been used your client should consider:
    </p>
    <ul>
      <li>
        - Maximising ISA investments (£20,000 per tax year).
      </li>
      <li>
        - Investing in non-income producing assets (growth unit trusts/UK or offshore insurance bonds).
      </li>
      <li>
        - Supplementing required income with capital (withdrawals/capital gains).
      </li>
      <li>
        - Subject to attitude to/appetite for risk, investing in VCT investments to produce tax free dividends.
      </li>
    </ul>
    <p>
      In making all or any of the above investment decisions it will, of course, be necessary to take full account of all factors including the relative
      importance of capital growth, the Client’s overall investment portfolio and attitude to risk and capacity for loss.
    </p>
  `,
  [FinancialAuditQuestionName.ExpectCapitalGains]: `
    <p>
      The realised capital gains less allowable losses are expected to be (or at least could be) less than £12,000 for our client in this tax year.
    </p>
    <p>
      The annual CGT exemption of £12,000 is available every year on a “use it or lose it/non-carry forward” basis. For a basic rate taxpayer, the tax saving
      from its use is £1,200 on most capital gains and for a higher or additional rate taxpayer it is £2,400.
    </p>
    <p>
      Consider looking to trigger gains of up to the annual CGT exemption to “re-set” base values within allowable rules i.e. no reacquisition of the same investments as
      those disposed of within 30 days of the disposal. Disposal and reacquisition by your client’s ISA or pension or, if relevant, spouse or civil partner might offer an
      allowable opportunity to use the annual exemption whilst retaining some form of ownership of the investment.
    </p>
    <p>
      Remember that the annual CGT exemption is available to each individual – including spouses, civil partners and children.
    </p>
    <p>
      Transfers between spouses/civil partners living together can be made on a tax neutral no gain/no loss basis, so, where relevant
    </p>
    <ul>
      <li>
        - Consider transfer of assets with gains that would be taxed if realised by one spouse/civil partner to a spouse/civil partner with an available annual CGT exemption.
      </li>
      <li>
        - Consider transfer of assets with losses from a spouse/civil partner with no gains to one with gains – care anti-avoidance.
      </li>
    </ul>
    <p>
      The annual CGT exemption of £12,000 is available for each family member. If not used in a tax year it cannot be carried forward.
    </p>
    <p>
      Grandparents/parents to consider transferring cash/investments to children/grandchildren or into a bare trust/ nomineeship (an investment, such as a unit trust, held by a parent
      or grandparent as nominee for the child) for them so that capital gains made on investments would be assessed on the child. Of course, the legal implications of a bare trust/nomineeship
      would need to be considered before this planning was executed. Also, if the parent of a minor child beneficiary is the settlor then any income produced in excess of £100 pa will be assessed
      on the parent. This will not be the case where the settlor is someone other than a parent e.g. grandparent.
    </p>
    <p>
      It may also be worth considering investing in a Junior ISA for the child.
    </p>
    <p>
      The transfer would be chargeable for CGT (unless it is cash) and would be a potentially exempt/exempt transfer for IHT. The former only if the transfer is made outright or into a bare
      trust/nomineeship and the latter dependant on the amount transferred. If the transfer is not outright or into a Bare Trust it is likely to be a chargeable transfer for IHT. Whether IHT is payable will depend on the availability of the exemptions and the amount of the transfer. Any capital gain arising as a result of a chargeable transfer can be held over (deferred).
    </p>
  `,
  [FinancialAuditQuestionName.CapitalGainExcess]: `
    <p>
      Subject to the Client’s attitude to and appetite for risk and the realisation occurring not more than 36 months ago, consideration could be given to investing in an appropriate EIS to defer the capital gains to the extent of the lower of excess capital gain and the amount invested.
    </p>
  `,
  [FinancialAuditQuestionName.PensionBenefits]: `
    <p>
      Your Client is (or could be) planning on taking benefits from their pension in the next 12 months.
    </p>
    <p>
      By accessing benefits ‘flexibly’, they may trigger the money purchase annual allowance (MPAA) which will significantly restrict their ability to pay pension contributions.
    </p>
    <p>
      A review of how and when your client needs or would like to access benefits should be conducted to establish if it is possible and/or necessary to meet their needs without triggering the MPAA.
    </p>
    <p>
      Death benefit options in the current schemes should also be reviewed to ensure that they meet your client’s requirements.
    </p>
  `,
  [FinancialAuditQuestionName.MadeGifts]: `
    <p>
      The annual exemption of £3,000 will not/may not be fully used. For any particular tax year, to the extent that the £3,000 exemption was not used in the immediately preceding tax year, a gift of up to that amount would also be exempt.
    </p>
    <p>
      Your Client could consider (subject to their own personal financial needs) making exempt gifts up to the amount of the available annual exemption (including any unused amount from the previous tax year) to donees other than their spouse/registered civil partner.
    </p>
    <p>
      It is also possible to make unlimited gifts as normal expenditure out of income. These are gifts made out of income regularly and without affecting the donor’s standard of living. The payment of regular contributions to a life insurance policy held in trust for
      those who will inherit your estate on death (or the estate of the survivor of you in the case of a couple where the first to die leaves assets to the survivor) would normally qualify as being exempt. The sum assured under such a policy would not usually be treated
      as part of your Client’s taxable estate and would be payable free of inheritance tax to the beneficiaries who could then use the money to pay the IHT arising on the death of the life or lives assured. It is reiterated that for a couple, only transfers between spouses and registered civil partners will be exempt.
    </p>
  `,
  [FinancialAuditQuestionName.BiggerAssets]: `
    <p>
      Your Client’s estate is/could be worth more than £325,000 (£475,000 including the value of their share in their residence).
    </p>
    <p>
      As well as transfers during lifetime or on death between spouses/registered civil partners (but not otherwise) being exempt without limit, each person is allowed to leave up to £325,000 (taking account of any such transfers made in the immediately preceding seven years) to anyone other than their spouse/civil partner free of IHT (taxed at a nil rate).
      To the extent any of the £325,000 nil rate band is not used on death it can be “inherited” by (i.e. transferred to) a surviving spouse/registered civil partner and, subject to a claim made within two years, used on the death of that surviving spouse/registered civil partner.
    </p>
    <p>
      The same is largely true (but with important limitations on who can inherit and how within this allowance) of the residence nil rate band of £150,000 for the 2019/20 tax year – rising to £175,000 by 2020/21, but subject to 50% taper for estates above £2m. This “residence nil rate band” is in addition to the £325,000 “any asset” nil rate
      band explained immediately above. To the extent that the taxable estate of an individual passing to other than a surviving spouse, registered civil partner or charity exceeds the available nil rate band(s) available to them IHT will be charged at 40%.
    </p>
    <p>
      An IHT/estate planning review could be worthwhile to ascertain what, if any, planning and provision may be possible.
    </p>
    <p>
      This is especially so because the £325,000 nil rate band will remain frozen until the end of 2020/21.
    </p>
    <p>
      Careful consideration should also be given to the main residence nil rate band and its use in planning and the fact that the residence nil rate band is reduced by £1 for every £2 that the deceased’s estate exceeds £2m.
    </p>
    <p>
      As part of an estate planning review it will be important to regularly assess whether your Client has:
    </p>
    <ul>
      <li>
        - an up to date will reflecting their current intentions in relation to the distribution of their estate on death and aligned to their overall estate planning strategy.
      </li>
      <li>
        - fully considered the potential benefit of the efficient and professional estate administration service that Kings Court Trust can deliver.
      </li>
      <li>
        - fully considered the potential future benefit of a Lasting Power of Attorney.
      </li>
      <li>
       - fully considered the benefits that protection policies written subject to an appropriate trust can deliver including; providing financial security for family and dependants, creating an inheritance and tax efficiently meeting the liability to inheritance tax on death.
      </li>
    </ul>
  `,
  [FinancialAuditQuestionName.Inheritances]: `
    Opportunities may exist for effective inheritance tax planning through the use of a deed of variation. Depending on the circumstances it may be possible to plan to maximise use of the residence nil rate band in this way and/or avoid consolidation of assets in the estate of a surviving spouse or civil partner while retaining access for them.
  `,
};

const commonCurrentDescriptions = {
  [FinancialAuditQuestionName.TaxableIncome]: {
    description: `
      <p>
        Your client's income is below the level of the personal allowance and is therefore tax
        free regardless of its source. To the extent that there is any allowance left unused, and
        subject to your client's overall financial objectives and circumstances, they should
        consider (if feasible and assets/employment or self-employment circumstances permit)
        generating earned or investment income to fall within their available personal allowance
        as it will be tax free.
      </p>
    `,
    description2: `
      <p>
        Your client's taxable income is between £100,001 and £125,140.
      </p>
      <p>
        Their personal allowance will be reduced by £1 for every £2 of income that exceeds £100,000.
        This means that income in the band £100,000 - £125,140 can bear an effective rate of income
        tax of 60%, or more (63% on non-savings, non-dividend income in Scotland).
      </p>
      <p>
        Your client is a higher rate taxpayer, so, subject to personal expenditure requirements,
        action to minimise tax at 40% may be worth considering. In Scotland a higher rate of 42%
        would apply on non-savings, non-dividend income.
      </p>
      <p>
        In practice, possible action to reduce taxation will depend substantially on whether the
        income is employment income or income from self-employment, the investments they own or
        could own taking account of the important need to align tax planning to their financial
        objectives/attitude to, appetite and capacity for, risk. Subject to the appropriate limits,
        ISAs and pensions will deliver tax efficiency, as will VCT and EIS investments, but with
        greater risk. Unit trusts, OEICs, and in some cases, investment bonds can also deliver
        elements of tax efficiency and tax deferment, but, as with ISAs, there is no "front end"
        tax relief.
      </p>
    `,
    description3: `
      <p>
        Your client’s taxable income for the tax year is over £125,140.
      </p>
      <p>
        Every £1 of taxable income above £125,140 will be taxed at the additional rate of 45%
        (in Scotland, a top rate of 47% would apply on non-savings, non-dividend income). Tax
        saving for these clients will be very worthwhile.
      </p>
      <p>
        Your client is an additional rate taxpayer, so, subject to personal expenditure requirements,
        action to minimise tax at 45% (47% on non-savings, non-dividend income in Scotland) may
        be worth considering. In practice, possible action to reduce taxation will depend substantially
        on whether the income is employment income or income from self-employment, the investments
        they own or could own taking account of the important need to align tax planning to their
        financial objectives/attitude to, appetite and capacity for, risk. Subject to the appropriate
        limits, ISAs and pensions will deliver tax efficiency, as will VCT and EIS investments,
        but with greater risk. Unit trusts, OEICs and, in some cases, investment bonds can also
        deliver elements of tax efficiency and tax deferment, but, as with ISAs, there is no
        "front end" tax relief.
      </p>
    `,
  },
  [FinancialAuditQuestionName.IsaInvested]: `
    <p>
      Your client has indicated that they have not made use/full use of their maximum ISA
      subscription allowance in the current tax year.
    </p>
    <p>
      As an ISA delivers tax free interest and/or dividend income (and capital gains) it is an
      extremely tax effective investment wrapper for their deposits and investments. ISAs cannot,
      however, be assigned or held within a trust for inheritance tax planning.
    </p>
    <p>
      Ensure, subject to economic and investment considerations and the relative importance of
      inheritance tax planning, that your client and if relevant, their spouse/partner maximises the
      amount of their investments/deposits held inside ISAs. If they make regular contributions,
      ensure that the regular contribution amount is increased to ensure contributions reach the
      current £20,000 maximum total for the tax year.
    </p>
  `,
  [FinancialAuditQuestionName.ReceiveInInterest]: {
    no: {
      description: `
        <p>
          As your client is a non-taxpayer, they will not pay tax on the savings element of their income
          to the extent that their total income for the tax year does not exceed £17,570. In addition,
          they will benefit from a PSA of £1,000. Consider (subject to economic and investment
          considerations and other available cash), investing to produce savings income to fully utilise
          their £1,000 PSA and their £5,000 0% starting rate band for savings. The PSA and 0% starting
          rate band for savings apply to all savings income, i.e. interest, the interest element of
          annuity payments, and, in certain circumstances, chargeable event gains on investment bonds.
          Note that distributions from unit trusts, OEICs and offshore funds that are invested more than
          60% in fixed interest securities are treated as interest distributions for tax purposes. Once
          these allowances have been fully used and (as always) subject to wider investment considerations,
          ISAs and then investment into other investments producing tax-efficient income and/or capital
          growth should be considered. For example, up to £1,000 of dividends can be received tax free
          in tax year 2023/24. However, note that the £1,000 dividend allowance is set to reduce to
          £500 a year from 6 April 2024.
        </p>
      `,
      description2: `
        <p>
          If your client’s total non-savings income is below £17,570, they will not pay tax on the
          savings element of their income to the extent that their total income for the tax year does
          not exceed £17,570. In addition, as your client is a basic rate taxpayer, they will benefit
          from a PSA of £1,000, regardless of the amount of their non-savings income. As your client
          has indicated that they (may) receive less than £1,000 a year in savings income, they should
          consider (subject to economic and investment considerations and other available cash) investing
          to produce savings income to fully utilise their £1,000 PSA and the balance (if any) of their
          0% starting rate band for savings. The PSA and 0% starting rate band for savings applies to
          all savings income, i.e. interest, the interest element of annuity payments, and, in certain
          circumstances, chargeable event gains on investment bonds. Note that distributions from unit
          trusts, OEICs and offshore funds that are invested more than 60% in fixed interest securities
          are treated as interest distributions for tax purposes. Once these allowances have been fully
          used and (as always) subject to wider investment considerations, ISAs and then investment into
          other investments producing tax-efficient income and/or capital growth should be considered.
          For example, up to £1,000 of dividends can be received tax free in tax year 2023/24. However,
          note that the £1,000 dividend allowance is set to reduce to £500 a year from 6 April 2024.
        </p>
      `,
      description3: `
        <p>
          Your client has indicated that they (may) receive less than £500 per tax year in savings
          income. Consider (subject to economic and investment considerations and other available cash)
          investing to produce savings income to fully utilise their £500 PSA. The PSA applies to all
          savings income, i.e. interest, the interest element of annuity payments, and, in certain
          circumstances, chargeable event gains on investment bonds. Note that distributions from unit
          trusts, OEICs and offshore funds that are invested more than 60% in fixed interest securities
          are treated as interest distributions for tax purposes. Once these allowances have been fully
          used and (as always) subject to wider investment considerations, ISAs and then investment into
          other investments producing tax-efficient income and/or capital growth should be considered.
          For example, up to £1,000 of dividends can be received tax free in tax year 2023/24. However,
          note that the £1,000 dividend allowance is set to reduce to £500 a year from 6 April 2024.
        </p>
      `,
      description4: `
        <p>
          As your client is an additional rate taxpayer, they will not benefit from a PSA, and all of
          their savings income will therefore be subject to tax. Subject to economic and investment
          considerations and other available cash, the client and, if relevant, their partner could
          consider investing so as to maximise their ISA contributions and/or to produce tax free
          dividends (within the dividend allowance) and tax effective gains (within the capital gains
          tax exemption) through unit trusts, OEICs and/or to achieve possible tax deferment through
          investment bonds.
        </p>
      `,
    },
    yes: {
      description: `
        <p>
          As your client is a non-taxpayer, they will benefit from a PSA of £1,000. In addition to
          the PSA an individual can have up to a further £5,000 of savings income taxed at 0%. This
          is known as the 0% starting rate band for savings. Consider (subject to economic and
          investment considerations and other available cash), investing to produce savings income
          to fully utilise both of these allowances. The PSA and 0% starting rate band for savings
          apply to all savings income, i.e. interest, the interest element of annuity payments,
          and, in certain circumstances, chargeable event gains on investment bonds. Note that
          distributions from unit trusts, OEICs and offshore funds that are invested more than 60%
          in fixed interest securities are treated as interest distributions for tax purposes. Once
          these allowances have been fully used and (as always) subject to wider investment considerations,
          ISAs and then investment into other investments producing tax-efficient income and/or
          capital growth should be considered. For example, up to £1,000 of dividends can be received
          tax free in tax year 2023/24. However, note that the £1,000 dividend allowance is set to
          reduce to £500 a year from 6 April 2024.
        </p>
      `,
      description2: `
        <p>
          Your client is fully utilising their PSA of £1,000. Assuming that their non-savings income
          exceeds £17,570 they should, subject to economic and investment considerations and other
          available cash, consider investing so as to maximise their ISA contributions and or to
          produce tax free dividends and tax effective gains through unit trusts, OEICs and possible
          tax deferment through investment bonds.
        </p>
        <p>
          If your client’s total non-savings income is below £17,570 per annum, they will not pay tax
          on the savings element of their income to the extent that their total income does not exceed
          £17,570. This is because an individual with non-savings income of less than £17,570 per
          annum can have up to a further £5,000 of savings income taxed at 0%. This tax free amount,
          known as the 0% starting rate band for savings, is reduced by £1 for every £1 of non-savings,
          non-dividend income (e.g. earnings, pensions income) that the client receives in the tax
          year above their personal allowance. In addition, the client will benefit from a PSA of
          £1,000 which they are not currently utilising. Consider (subject to economic and investment
          considerations and other available cash), investing to produce savings income to fully
          utilise their £1,000 PSA and the balance of their 0% starting rate band for savings. The
          PSA and 0% starting rate band for savings apply to all savings income, i.e. interest, the
          interest element of annuity payments, and, in certain circumstances, chargeable event gains
          on investment bonds. Note that distributions from unit trusts, OEICs and offshore funds that
          are invested more than 60% in fixed interest securities are treated as interest distributions
          for tax purposes. Once these allowances have been fully used and (as always) subject to wider
          investment considerations, ISAs and then investment into other investments producing
          tax-efficient income and/or capital growth should be considered. For example, up to £1,000
          of dividends can be received tax free in tax year 2023/24. However, note that the £1,000
          dividend allowance is set to reduce to £500 a year from 6 April 2024.
        </p>
      `,
      description3: `
        <p>
          Based on your client's income they are a higher rate taxpayer. As they are fully utilising
          their PSA of £500 per tax year, all of their savings income in excess of this will be
          subject to tax. Subject to economic and investment considerations and other available cash,
          the client could consider investing so as to maximise their ISA contributions and/or to
          produce tax free dividends (within the dividend allowance) and tax effective gains (within
          the capital gains tax exemption) through unit trusts, OEICs and/or to achieve possible tax
          deferment through investment bonds.
        </p>
      `,
      description4: `
        <p>
          Based on your client's income they are an additional rate taxpayer. As they do not benefit
          from a PSA, all of their savings income will be subject to tax. Subject to economic and
          investment considerations and other available cash, the client could consider investing so
          as to maximise their ISA contributions and/or to produce tax free dividends (within the
          dividend allowance) and tax effective gains (within the capital gains tax exemption) through
          unit trusts, OEICs and/or to achieve possible tax deferment through investment bonds.
        </p>
      `,
    },
  },
  [FinancialAuditQuestionName.ReceiveInDividends]: `
    <p>
      Your client is likely to receive less than the £1,000 tax free dividend allowance in 2023/24
      in dividends.
    </p>
    <p>
      Consider investing in dividend paying unit trusts (regardless of whether the income produced
      is taken or re-invested/accumulated). Where the dividends produced are within the investor's
      £1,000 dividend allowance they will be tax free. However, note that the £1,000 dividend
      allowance is set to reduce to £500 a year from 6 April 2024. Also remember that any reinvested
      dividend will be added to the acquisition price of the investment when calculating any capital
      gain on future realisation/disposal. Once the dividend allowance has been used your
      client should consider:
    </p>
    <ul>
      <li>
        - Maximising ISA investments (£20,000 per tax year).
      </li>
      <li>
        - Investing in non-income producing assets (growth unit trusts/OEICs, UK or offshore
        investment bonds).
      </li>
      <li>
        - Supplementing required income with capital (withdrawals/capital gains).
      </li>
      <li>
        - Subject to attitude to/appetite for risk, investing in VCT investments to produce tax free
        dividends.
      </li>
    </ul>
    <p>
      In making all or any of the above investment decisions it will, of course, be necessary to
      take full account of all factors including the relative importance of capital growth, the
      client's overall investment portfolio and attitude to risk and capacity for loss.
    </p>
  `,
  [FinancialAuditQuestionName.CapitalGainExcess]: `
    <p>
      Subject to the client's attitude to and appetite for risk and the realisation occurring not
      more than 36 months ago, consideration could be given to investing in an appropriate EIS to
      defer the capital gains to the extent of the lower of excess capital gain and the amount
      invested. EIS investments may also provide valuable income tax relief benefits for a higher
      rate taxpayer, as well as relief from inheritance tax once the investments have been
      held for two years.
    </p>
  `,
  [FinancialAuditQuestionName.ContributeToPension]: {
    description: `
      <p>
        Note that tax relief on individual pension contributions is limited to contributions not
        exceeding the greater of 100% of Relevant UK Earnings (broadly their earnings from employment
        or a trade or profession) and £3,600 gross (£2,880 net). No tax relief is available on any
        individual pension contributions for those aged 75 or over.
      </p>
      <p>
        Your client and their employer together are likely to (or may) contribute less than £60,000
        (in total) to your client’s pension this tax year.
      </p>
      <p>
        Assuming that neither the money purchase annual allowance nor tapering apply, then up to
        £60,000 per annum can be contributed (in total) to a pension arrangement each tax year. In
        addition, once the annual allowance for this tax year has been fully used, your client is
        entitled to use previously unused annual allowance for each of the immediately preceding
        three tax years in which they were a member of a registered pension scheme. This additional
        contribution representing carried forward relief can be made by the employer and/or employee.
      </p>
      <p>
        An assessment of unused relief should be carried out taking full account of the permitted
        maximum for each of the three preceding tax years and the amounts actually contributed to
        registered pensions for or on behalf of the member in each of those years.
      </p>
      <p>
        Note that, if they are making personal contributions, the client would need relevant UK
        earnings of at least the total contribution amount in the current tax year, to be eligible
        for tax relief on the full contributions. It is not possible to carry forward unused earnings,
        only the unused annual allowances.
      </p>
    `,
    description2: `
      <p>
        Note that tax relief on individual pension contributions is limited to contributions not
        exceeding the greater of 100% of Relevant UK Earnings (broadly their earnings from employment
        or a trade or profession) and £3,600 gross (£2,880 net). No tax relief is available on any
        individual pension contributions for those aged 75 or over.
      </p>
      <p>
        Your client and their employer are likely to (or may) contribute more than £60,000 (in total)
        to your client's pension this tax year.
      </p>
      <p>
        Once the annual allowance for this tax year has been fully used your client is entitled to
        use previously unused annual allowance for each of the immediately preceding three tax years
        in which they were a member of a registered UK pension scheme. This additional contribution
        representing carried forward relief can be made by the employer and/or employee.
      </p>
      <p>
        An assessment of unused relief should be carried out taking full account of the permitted
        maximum for each of the three preceding tax years and the amounts actually contributed to
        registered pensions for or on behalf of the member in each of those tax years.
      </p>
      <p>
        Note that, if they are making personal contributions, the client would need relevant UK earnings
        of at least the total contribution amount in the current tax year, to be eligible for tax relief
        on the full contributions. It is not possible to carry forward unused earnings, only the unused annual allowances.
      </p>
      <p>
        Once tax relievable investment in pensions has been maximised, subject to attitude to and
        appetite for risk, consideration could be given to all or some (as appropriate) of ISA
        investment, VCT, EIS, unit trusts, OEICs and investment bonds.
      </p>
    `,
    description3: `
      <p>
        Note that tax relief on individual pension contributions is limited to contributions not
        exceeding the greater of 100% of Relevant UK Earnings (broadly their earnings from employment
        or a trade or profession) and £3,600 gross (£2,880 net). No tax relief is available on any
        individual pension contributions for those aged 75 or over.
      </p>
      <p>
        Your client and their employer are likely to (or may) contribute more than £60,000 (in total)
        to your client's pension this tax year.
      </p>
      <p>
        Once the annual allowance for this tax year has been fully used your client is entitled to
        use previously unused annual allowance for each of the immediately preceding three tax years
        in which they were a member of a registered UK pension scheme. This additional contribution
        representing carried forward relief can be made by the employer and/or employee.
      </p>
      <p>
        An assessment of unused relief should be carried out taking full account of the permitted
        maximum for each of the three preceding tax years and the amounts actually contributed to
        registered pensions for or on behalf of the member in each of those tax years.
      </p>
      <p>
        Note that, if they are making personal contributions, the client would need relevant UK
        earnings of at least the total contribution amount in the current tax year, to be eligible
        for tax relief on the full contributions. It is not possible to carry forward unused earnings, only the unused annual allowances.
      </p>
      <p>
        Your client’s earned income is too low to support personal contributions over £60,000.
      </p>
      <p>
        Once tax relievable investment in pensions has been maximised, subject to attitude to and
        appetite for risk, consideration could be given to all or some (as appropriate) of ISA
        investment, VCT, EIS, unit trusts, OEICs and investment bonds.
      </p>
    `,
    description4: `
      <p>
        Note that tax relief on individual pension contributions is limited to contributions not
        exceeding the greater of 100% of Relevant UK Earnings (broadly their earnings from employment
        or a trade or profession) and £3,600 gross (£2,880 net). No tax relief is available on any
        individual pension contributions for those aged 75 or over.
      </p>
      <p>
        Your client and their employer are likely to (or may) contribute more than £60,000 (in total)
        to your client's pension this tax year.
      </p>
      <p>
        Once the annual allowance for this tax year has been fully used your client is entitled to use
        previously unused annual allowance for each of the immediately preceding three tax years in
        which they were a member of a registered UK pension scheme. This additional contribution
        representing carried forward relief can be made by the employer and/or employee.
      </p>
      <p>
        An assessment of unused relief should be carried out taking full account of the permitted
        maximum for each of the three preceding tax years and the amounts actually contributed to
        registered pensions for or on behalf of the member in each of those years.
      </p>
      <p>
        Note that, if they are making personal contributions, the client would need relevant UK earnings
        of at least the total contribution amount in the current tax year, to be eligible for tax relief on the full contributions. It is not possible to carry forward unused earnings, only the unused annual allowances.
      </p>
      <p>
        There may be retirement planning considerations where the client's income is in excess of
        £200,000.
      </p>
      <p>
        Your client may well be impacted by the tapered annual allowance which could reduce the level
        of tax-efficient pension savings they can make this tax year.
      </p>
      <p>
        An assessment of threshold income, and, if needed, adjusted income should be conducted to
        establish if the tapered annual allowance applies, and, if so, how much allowance is available.
        This can then be used to establish if there is any scope for further pension contributions.
      </p>
      <p>
        In addition, it may be possible to restore the full annual allowance by making additional
        personal contributions to a pension scheme or rearranging the structure of existing contributions.
      </p>
      <p>
        Once tax relievable investment in pensions has been maximised, subject to attitude to and
        appetite for risk, consideration could be given to all or some (as appropriate) of ISA investment,
        VCT, EIS, unit trusts, OEICs and investment bonds.
      </p>
    `,
  },
  [FinancialAuditQuestionName.MadeGifts]: `
    <p>
      The annual exemption of £3,000 will not/may not be fully used. For any particular tax year, to
      the extent that the £3,000 exemption was not used in the immediately preceding tax year, a gift
      of up to that amount would also be exempt.
    </p>
    <p>
      Your client could consider (subject to their own personal financial needs) making exempt gifts
      up to the amount of the available annual exemption (including any unused amount from the
      previous tax year).
    </p>
    <p>
      It is also possible to make unlimited gifts using the normal expenditure out of income
      exemption. These are gifts made regularly out of surplus income that do not affect the donor's
      standard of living. The payment of regular contributions to a life insurance policy held in
      trust for those who will inherit your client’s estate on death would normally qualify as being
      exempt. The sum assured under such a policy would not usually be treated as part of your
      client's taxable estate and would be payable free of inheritance tax to the beneficiaries who
      could then use the money to pay the inheritance tax arising on the death of the life or lives
      assured.
    </p>
  `,
  [FinancialAuditQuestionName.BiggerAssets]: `
    <p>
      Your client's estate is/could be worth more than £325,000 (£500,000 including the value of
      their share in their residence).
    </p>
    <p>
      As well as transfers during lifetime or on death between spouses/registered civil partners
      (but not otherwise) being exempt without limit, each person is allowed to leave up to
      £325,000 (taking account of any such transfers made in the immediately preceding seven years)
      to anyone other than their spouse/civil partner free of inheritance tax (taxed at a nil rate).
      To the extent any of the £325,000 nil rate band is not used on death it can be "inherited" by
      (i.e. transferred to) a surviving spouse/registered civil partner and, subject to a claim
      made within two years, used on the death of that surviving spouse/registered civil partner.
    </p>
    <p>
      The same is largely true (but with important limitations on who can inherit and how within this
      allowance) of the residence nil rate band of £175,000 for the 2023/24 tax year. This
      "residence nil rate band" is in addition to the £325,000 "any asset" nil rate band explained
      immediately above. To the extent that the taxable estate of an individual passing to other
      than a surviving spouse, registered civil partner or charity exceeds the available nil rate
      band(s) available to them inheritance tax will be charged at 40%.
    </p>
    <p>
      An inheritance tax/estate planning review could be worthwhile to ascertain what, if any,
      planning and provision may be possible.
    </p>
    <p>
      This is especially so because the £325,000 nil rate band will remain frozen until the end
      of 2027/28.
    </p>
    <p>
      Careful consideration should also be given to the residence nil rate band and its use in
      planning and the fact that the residence nil rate band is reduced by £1 for every £2 where
      the deceased’s estate exceeds £2m.
    </p>
    <p>
      As part of an estate planning review it will be important to regularly assess whether
      your client has:
    </p>
    <ul>
      <li>
        - an up to date will reflecting their current intentions in relation to the distribution of
        their estate on death and aligned to their overall estate planning strategy.
      </li>
      <li>
        - fully considered the potential benefit of the efficient and professional estate administration
        service that Kings Court Trust can deliver.
      </li>
      <li>
        - fully considered the potential future benefit of a Lasting Power of Attorney.
      </li>
      <li>
        - fully considered the benefits that protection policies written subject to an appropriate trust
        can deliver including: providing financial security for family and dependents, creating an
        inheritance and tax efficiently meeting the liability to inheritance tax on death.
      </li>
    </ul>
  `,
  [FinancialAuditQuestionName.Inheritances]: `
    <p>
      Opportunities may exist for effective inheritance tax planning through the use of a deed
      of variation. Depending on the circumstances it may be possible to plan to maximise use
      of the residence nil rate band in this way and/or avoid aggregation of the inheritance with
      the beneficiary's estate while retaining access for them.
    </p>
  `,
};

const currentFormTranslations = {
  [FinancialAuditQuestionName.CountryOfResidence]: {
    A: {
      text: `England, Wales and Northern Ireland`,
    },
    B: {
      text: `Scotland`,
    },
  },
  [FinancialAuditQuestionName.TaxableIncome]: {
    definitions: `
      <p>
        <strong>Taxable Income:</strong> You pay tax on various income types. Common taxable
        income types include salaries, wages, profits from self-employment, pension income
        (incl. state pensions, company pensions, personal pensions and retirement annuities),
        some state benefits, rental income, and income from a trust.
      </p>
      <p>
        <strong>Tax year:</strong>  This tax year begins on 6th April 2023 and ends on
        5th April 2024.
      </p>
    `,
    setEnglandOthers: {
      A: {
        text: `£0 – £12,570`,
        hint: `There may be scope to generate tax free income.`,
        description:
          commonCurrentDescriptions[FinancialAuditQuestionName.TaxableIncome].description,
      },
      B: {
        text: `£12,571 – £50,270`,
        hint: `Consideration, where appropriate could be given to tax efficient investment to reduce, defer and manage tax.`,
        description: `
          <p>
            Your client's taxable income for 2023/24 is above their personal allowance, £12,570, but
            below £50,270.
          </p>
          <p>
            Your client will be a basic rate taxpayer, so, subject to personal and commercial needs,
            action to minimise tax loss at 20% will be worth considering.
          </p>
          <p>
            In practice, possible action to reduce taxation will depend substantially on the source
            of the income (i.e. whether from employment/self-employment or investments), financial
            objectives and attitude to, appetite and capacity for, risk. Subject to the appropriate
            limits, ISAs and pensions will deliver tax efficiency, as will VCT and EIS investments,
            but with greater risk. Unit trusts, OEICs and, in some cases, investment bonds can also
            deliver elements of tax efficiency and tax deferment, but, as with ISAs, there is no
            "front end" tax relief.
          </p>
        `,
      },
      C: {
        text: `£50,271 – £100,000`,
        hint: `The scope for tax efficient investment may be worth considering.`,
        description: `
          <p>
            Your client's taxable income before deducting their personal allowance of £12,570 (which
            is assumed to be available in full) is above £50,270, but does not exceed £100,000. This
            implies they are a higher rate taxpayer, so, subject to personal expenditure requirements,
            action to minimise tax at 40% may be worth considering.
          </p>
          <p>
            In practice, possible action to reduce taxation will depend substantially on the source
            of the income (i.e. whether from employment/self-employment or investments), financial
            objectives and attitude to, appetite and capacity for, risk. Subject to the appropriate
            limits, ISAs and pensions will deliver tax efficiency, as will VCT and EIS investments,
            but with greater risk. Unit trusts, OEICs and, in some cases, investment bonds can also
            deliver elements of tax efficiency and tax deferment, but, as with ISAs, there is no
            "front end" tax relief.
          </p>
        `,
      },
      D: {
        text: `£100,001 - £125,140`,
        hint: `<p>The scope for tax efficient investment may be worth considering.</p>
            <p>A review of pension contribution capacity may well be worthwhile.</p>
          `,
        description:
          commonCurrentDescriptions[FinancialAuditQuestionName.TaxableIncome].description2,
      },
      E: {
        text: `Over £125,140`,
        hint: `The scope for tax efficient investment may be worth considering.`,
        description:
          commonCurrentDescriptions[FinancialAuditQuestionName.TaxableIncome].description3,
      },
    },
    setScotland: {
      A: {
        text: `£0 – £12,570`,
        hint: `There may be scope to generate tax free income.`,
        description:
          commonCurrentDescriptions[FinancialAuditQuestionName.TaxableIncome].description,
      },
      B: {
        text: `£12,571 – £43,662`,
        hint: `Consideration, where appropriate could be given to tax efficient investment to reduce, defer and manage tax.`,
        description: `
          <p>
            Your client's taxable income for 2023/24 is above their personal allowance, £12,570, but
            below £43,662 in Scotland.
          </p>
          <p>
            In Scotland, three marginal tax rates could apply on non-savings, non-dividend
            income: 19% (starter rate) up to £14,732; 20% (basic rate) £14,732 - £25,688; and 21%
            (intermediate rate) £25,688 - £43,662. (These tax bands assume the personal allowance of
            £12,570 is fully available.)
          </p>
          <p>
            In practice, possible action to reduce taxation will depend substantially on the source
            of the income (i.e. whether from employment/self-employment or investments), financial
            objectives and attitude to, appetite and capacity for, risk. Subject to the appropriate
            limits, ISAs and pensions will deliver tax efficiency, as will VCT and EIS investments,
            but with greater risk. Unit trusts, OEICs, and in some cases, investment bonds can also
            deliver elements of tax efficiency and tax deferment, but, as with ISAs, there is no
            "front end" tax relief.
          </p>
        `,
      },
      C: {
        text: `£43,663 - £100,000`,
        hint: `The scope for tax efficient investment may be worth considering.`,
        description: `
          <p>
            Your client's taxable income before deducting their personal allowance of £12,570 (which
            is assumed to be available in full) is above £43,662 in Scotland, but does not exceed
            £100,000. This implies they are a higher rate taxpayer, so, subject to personal expenditure
            requirements, action to minimise tax at 40% may be worth considering. In Scotland a higher
            rate of 42% would apply on non-savings, non-dividend income.
          </p>
          <p>
            In practice, possible action to reduce taxation will depend substantially on the source
            of the income (i.e. whether from employment/self-employment or investments), financial
            objectives and attitude to, appetite and capacity for risk. Subject to the appropriate
            limits, ISAs and pensions will deliver tax efficiency, as will VCT and EIS investments,
            but with greater risk. Unit trusts, OEICs, and in some cases, investment bonds can also
            deliver elements of tax efficiency and deferment, but, as with ISAs, there is no "front
            end" tax relief.
          </p>
        `,
      },
      D: {
        text: `£100,001 - £125,140`,
        hint: `<p>The scope for tax efficient investment may be worth considering.</p>
            <p>A review of pension contribution capacity may well be worthwhile.</p>
          `,
        description:
          commonCurrentDescriptions[FinancialAuditQuestionName.TaxableIncome].description2,
      },
      E: {
        text: `Over £125,140`,
        hint: `The scope for tax efficient investment may be worth considering.`,
        description:
          commonCurrentDescriptions[FinancialAuditQuestionName.TaxableIncome].description3,
      },
    },
  },
  [FinancialAuditQuestionName.IsaInvested]: {
    definitions: `
      <p>
        <strong>Individual Savings Account (ISA):</strong> An ISA provides a valuable, tax-efficient wrapper for cash and investments. The total ISA allowance for the 2023/24 tax year is £20,000.
        Individuals can invest into Cash ISAs or Stocks and Shares ISAs or other ISAs in any permissible combination, up to the total combined annual allowance.
      </p>
    `,
    A: {
      text: `Yes`,
      hint: `Tax efficient investment into products other than ISAs could be considered.`,
      description: `
        <p>
          No further ISA investment is possible.
        </p>
        <p>
          If further tax efficient investment is desired then consideration, subject to overall
          investment considerations and attitude to risk, could be given to pension, VCT and EIS
          investment and appropriate unit trusts, OEICs and investment bonds.
        </p>
        <p>
          Note: Any of the client’s children under the age of 18 can have a Junior ISA into which £9,000
          can be invested in total in the 2023/24 tax year. Grandparents or other relatives cannot
          set up a junior ISA (only parents can), but grandparents or others can contribute.
        </p>
      `,
    },
    B: {
      text: `No`,
      hint: `Maximising contributions to tax efficient ISAs may be worth considering.`,
      description: commonCurrentDescriptions[FinancialAuditQuestionName.IsaInvested],
    },
    C: {
      text: `Don’t know`,
      hint: `Maximising contributions to tax efficient ISAs may be worth considering.`,
      description: commonCurrentDescriptions[FinancialAuditQuestionName.IsaInvested],
    },
  },
  [FinancialAuditQuestionName.ReceiveInInterest]: {
    subText: `Non-taxpayers benefit from a Personal Savings Allowance of £1,000.`,
    subText2: `Basic rate taxpayers benefit from a Personal Savings Allowance of £1,000.`,
    subText3: `Higher rate taxpayers in this income band benefit from a Personal Savings Allowance of £500.`,
    subText4: `Higher rate taxpayers in this income band benefit from a Personal Savings Allowance of £500 but will suffer a restriction in their Personal Allowance.`,
    subText5: `Additional rate taxpayers do not benefit from any Personal Savings Allowance or Personal Allowance.`,
    definitions: `
      <p>
        Savings income, broadly speaking, includes interest (e.g. from deposits, government bonds
        and corporate bonds), the taxable part of any payments made from purchased life annuities
        (i.e. not pensions) and chargeable event gains made under investment bonds.
      </p>
    `,
    A: {
      text: `Yes`,
      hint: `It may still be  possible to receive a further amount of tax-free savings income due to the availability of other tax bands and allowances. If no further tax-free savings income can be received other investments could be considered.`,
      hint2: `As the tax-free PSA is being fully utilised, investments other than those which produce taxable savings income should be considered.`,
      ...commonCurrentDescriptions[FinancialAuditQuestionName.ReceiveInInterest].yes,
    },
    B: {
      text: `No`,
      hint: `It may be worth considering investments that generate savings income to make use of the tax-free PSA.`,
      ...commonCurrentDescriptions[FinancialAuditQuestionName.ReceiveInInterest].no,
    },
    C: {
      text: `Don't Know`,
      hint: `It may be worth considering investments that generate savings income to make use of the tax-free PSA.`,
      ...commonCurrentDescriptions[FinancialAuditQuestionName.ReceiveInInterest].no,
    },
  },
  [FinancialAuditQuestionName.ReceiveInDividends]: {
    definitions: `
      <p>
        <strong>Dividends:</strong> You may get a dividend payment if you own shares in a company.
      </p>
      <p>
        <strong>Tax year:</strong> This tax year begins on 6th April 2023 and ends on 5th April 2024.
      </p>
    `,
    A: {
      text: `Yes`,
      hint: `Tax efficient investments that produce a return other than through the production of taxable dividends may be worth considering.`,
      description: `
        <p>
          Your client is likely to receive more than the £1,000 tax free dividend allowance in
          2023/24 in dividends. The excess over £1,000 is taxable at their appropriate marginal rate
          (this is only 8.75% for a basic rate taxpayer). Consider:
        </p>
        <ul>
          <li>
            - Maximising ISA investments (£20,000 per tax year).
          </li>
          <li>
            - Investing in non-income producing assets (growth unit trusts/OEICs, UK or offshore
            investment bonds).
          </li>
          <li>
            - Supplementing required income with capital (withdrawals/capital gains).
          </li>
          <li>
            - Subject to attitude to/appetite for risk, investing in VCT investments to produce tax
            free dividends.
          </li>
        </ul>
        <p>
          In making all or any of the above investment decisions it will, of course, be necessary to
          take full account of all factors including the relative importance of capital growth, the
          client's overall investment portfolio and attitude to risk and capacity for loss.
        </p>
      `,
    },
    B: {
      text: `No`,
      hint: `Investment to receive tax free dividends may be possible`,
      description: commonCurrentDescriptions[FinancialAuditQuestionName.ReceiveInDividends],
    },
    C: {
      text: `Don’t know`,
      hint: `Investment to receive tax free dividends may be possible`,
      description: commonCurrentDescriptions[FinancialAuditQuestionName.ReceiveInDividends],
    },
  },
  [FinancialAuditQuestionName.ExpectCapitalGains]: {
    definitions: `
      <p>
        <strong>Capital gain:</strong> A capital gain is a profit earned when you sell, or ‘dispose of’, an asset that has increased in value.
      </p>
      <p>
        <strong>Annual Exempt Amount:</strong> Eligible individuals have an annual tax-free allowance for Capital Gains Tax known as the ‘Annual Exempt Amount’. The Annual Exempt Amount for the current tax year is £6,000.
      </p>
      <p>
        <strong>Tax year:</strong> This tax year begins on 6th April 2023 and ends on 5th April 2024.
      </p>
    `,
    A: {
      text: `Yes`,
      hint: `It may be possible to mitigate any liability to CGT.`,
      description: `
        <p>
          Your client is already using their annual capital gains tax exemption, but remember that
          the annual capital gains tax exemption is available to each individual - including spouses,
          civil partners and children.
        </p>
        <p>
          The capital gains tax exemption of £6,000 is available for each family member. If it is not
          used in the 2023/24 tax year it is effectively ‘lost’ as it cannot be carried forward.
        </p>
        <p>
          Subject to the client's attitude to and appetite for risk, consideration could also be
          given to investing all or part of any gain made in excess of the annual capital gains tax
          exemption in an appropriate EIS to defer the capital gain. EIS investments may also
          provide valuable income tax relief benefits for a higher rate taxpayer, as well as relief
          from inheritance tax once the investments have been held for two years.
        </p>
        <p>
          Note that where the gain being deferred is from a property disposal, e.g. a buy-to-let, the
          capital gains tax will be payable within 60 days of the sale, however the tax relief on the
          EIS investment will not be available until the end of the tax year in which the client
          makes the investment. The mismatch of the date of payment of capital gains tax and receipt
          of the EIS deferral relief may cause cash flow issues.
        </p>
      `,
    },
    B: {
      text: `No`,
      hint: `Planning to maximise use of the "use it or lose it" CGT annual exemption may be worth considering.`,
      description: `
        <p>
          The realised capital gains less allowable losses are expected to be (or at least could be)
          less than £6,000 for our client in this tax year.
        </p>
        <p>
          The capital gains tax exemption of £6,000 is available on a "use it or lose it/non-carry
          forward" basis. For a basic rate taxpayer, the tax saving from its use is £600 on most
          capital gains and for a higher or additional rate taxpayer it is £1,200. Note that the
          annual capital gains tax exemption will reduce from £6,000 to £3,000 on 6 April 2024 and
          then be frozen at £3,000 thereafter.
        </p>
        <p>
          Consider looking to trigger gains of up to the annual capital gains tax exemption to
          "re-set" base values within allowable rules, i.e. no reacquisition of the same investments
          as those disposed of within 30 days of the disposal. Disposal and reacquisition of similar,
          but not the same, investments or reacquisition of the same investments by your client’s ISA
          or pension or, if relevant, spouse or civil partner, might offer an allowable opportunity
          to use the annual exemption whilst retaining some form of ownership of the investment.
        </p>
        <p>
          Remember that the annual capital gains tax exemption is available to each individual -
          including spouses, civil partners and children.
        </p>
        <p>
          The capital gains tax exemption of £6,000 for 2023/24 is available for each family member.
          If it is not used in the 2023/24 tax year it is effectively ‘lost’ as it cannot be carried
          forward.
        </p>
      `,
    },
    C: {
      text: `Don’t know`,
      hint: `Planning to maximise use of the "use it or lose it" CGT annual exemption may be worth considering.`,
      description: `
        <p>
          Realised capital gains less allowable losses are expected to be (or could be) less than
          £6,000 for our client in this tax year.
        </p>
        <p>
          The capital gains tax exemption of £6,000 for 2023/24 is available on a "use it or lose
          it/non-carry forward" basis. For a basic rate taxpayer, the tax saving from its use is
          £600 on most capital gains and for a higher or additional rate taxpayer it is £1,200. Note
          that the annual capital gains tax exemption will reduce from £6,000 to £3,000 on 6 April
          2024 and then be frozen at £3,000 thereafter.
        </p>
        <p>
          Consider looking to trigger gains of up to the annual capital gains tax exemption to
          “re-set” base values within allowable rules, i.e. no reacquisition of the same investments
          as those disposed of within 30 days of the disposal. Disposal and reacquisition by your
          client’s ISA or pension or, if relevant, spouse or civil partner might offer an allowable
          opportunity to use the annual exemption whilst retaining some form of ownership of
          the investment.
        </p>
        <p>
          Remember that the annual capital gains tax exemption is available to each individual –
          including spouses, civil partners and children.
        </p>
        <p>
          The capital gains tax exemption of £6,000 for 2023/24 is available for each family member.
          If it is not used in the 2023/24 tax year it is effectively ‘lost’ as it cannot be carried
          forward.
        </p>
      `,
    },
  },
  [FinancialAuditQuestionName.CapitalGainExcess]: {
    definitions: `
      <p>
        <strong>Capital gain:</strong> A capital gain is a profit earned when you sell, or ‘dispose of’, an asset that has increased in value.
      </p>
      <p>
        <strong>Annual Exempt Amount:</strong> Eligible individuals have an annual tax-free allowance for Capital Gains Tax known as the ‘Annual Exempt Amount’. The Annual Exempt Amount for the current tax year is £6,000.
      </p>
      <p>
        <strong>Tax year:</strong> This tax year begins on 6th April 2023 and ends on 5th April 2024.
      </p>
    `,
    A: {
      text: `Yes`,
      hint: `It may be possible, through appropriate investment, to retrieve and then defer past capital gains tax paid or defer an outstanding liability to CGT.`,
      description: commonCurrentDescriptions[FinancialAuditQuestionName.CapitalGainExcess],
    },
    B: {
      text: `No`,
      description: `No action is necessary.`,
    },
    C: {
      text: `Don’t know`,
      hint: `It may be possible, through appropriate investment, to retrieve and then defer past capital gains tax paid or defer an outstanding liability to CGT.`,
      description: commonCurrentDescriptions[FinancialAuditQuestionName.CapitalGainExcess],
    },
  },
  [FinancialAuditQuestionName.ContributeToPension]: {
    definitions: `
      <p>
        <strong>Tax year:</strong> This tax year begins on 6th April 2023 and ends on 5th April 2024.
      </p>
      <p>
        Note that tax relief on individual pension contributions is limited to contributions not
        exceeding the greater of 100% of Relevant UK Earnings (broadly their earnings from employment
        or a trade or profession) and £3,600 gross (£2,880 net). No tax relief is available on any
        individual pension contributions for those aged 75 or over.
      </p>
    `,
    A: {
      text: `Don’t know`,
      hint: `There may be scope for contributions to tax efficient pension arrangements*.`,
      description:
        commonCurrentDescriptions[FinancialAuditQuestionName.ContributeToPension].description,
    },
    B: {
      text: `£60,000 or less`,
      description:
        commonCurrentDescriptions[FinancialAuditQuestionName.ContributeToPension].description,
    },
    C: {
      text: `More than £60,000`,
      description:
        commonCurrentDescriptions[FinancialAuditQuestionName.ContributeToPension].description2,
      description1:
        commonCurrentDescriptions[FinancialAuditQuestionName.ContributeToPension].description3,
      description2:
        commonCurrentDescriptions[FinancialAuditQuestionName.ContributeToPension].description4,
    },
  },
  [FinancialAuditQuestionName.PensionBenefits]: {
    A: {
      text: `Don't know`,
      hint: `A pension benefit review may be worthwhile. The general rule is that you can access your pension from age 55.`,
      description: `
        <p>
          Your client is undecided on when they will take their pension benefits.
        </p>
        <p>
          Keep the position under review so that appropriate planning can be carried out in good time.
        </p>
        <p>
          Death benefit options in the current schemes should also be reviewed to ensure that they meet
          your client's requirements.
        </p>
        <p>
          When considering whether and how to take benefits from the client's pension arrangements it
          is important that account is taken of all of the client's investments and their entitlement
          to the State Pension. The tax implications of drawing benefits and the tax treatment
          (including inheritance tax) of amounts that remain invested will be relevant in
          determining the most efficient and appropriate drawdown strategy.
        </p>
      `,
    },
    B: {
      text: `Within the next 12 months`,
      hint: `A pension benefit review should be carried out before any action is taken. The general rule is that you can access your pension from age 55.`,
      description: `
        <p>
          Your client is (or could be) planning on taking benefits from their pension in the next 12
          months.
        </p>
        <p>
          By accessing benefits 'flexibly' they may trigger the money purchase annual allowance (MPAA)
          which will significantly restrict their ability to pay pension contributions.
        </p>
        <p>
          A review of how and when your client needs or would like to access benefits should be
          conducted to establish if it is possible and/or necessary to meet their needs without
          triggering the MPAA.
        </p>
        <p>
          Death benefit options in the current schemes should also be reviewed to ensure that they
          meet your client's requirements.
        </p>
        <p>
          When considering whether and how to take benefits from the client's pension arrangements it
          is important that account is taken of all of the client's investments and their entitlement
          to the State Pension. The tax implications of drawing benefits and the tax treatment
          (including inheritance tax) of amounts that remain invested will be relevant in determining
          the most efficient and appropriate drawdown strategy.
        </p>
      `,
    },
    C: {
      text: `Within the next five years`,
      hint: `A pension benefit review should be carried out before any action is taken. The general rule is that you can access your pension from age 55.`,
      description: `
        <p>
          Your client is planning on taking benefits from their pension within the next five years.
        </p>
        <p>
          Old-style pension schemes may now not be suitable for taking benefits from. Transfers at or
          near retirement can be stressful and may delay payment of benefits, so reviewing well in
          advance is key.
        </p>
        <p>
          Review pension benefits with a plan to possibly consolidate and transfer to a pension under
          which the type of retirement benefits required are available.
        </p>
        <p>
          Death benefit options in the current schemes should also be reviewed to ensure that they
          meet your client's requirements.
        </p>
        <p>
          When considering whether and how to take benefits from the client's pension arrangements it
          is important that account is taken of all of the client's investments and their entitlement
          to the State Pension. The tax implications of drawing benefits and the tax treatment
          (including inheritance tax) of amounts that remain invested will be relevant in determining
          the most efficient and appropriate drawdown strategy.
        </p>
      `,
    },
    D: {
      text: `More than five years from now`,
      hint: `A pension benefit review should be carried out before any action is taken. The general rule is that you can access your pension from age 55.`,
      description: `
        <p>
          No benefit withdrawals are planned within the next five years.
        </p>
        <p>
          Keep the position under review so that appropriate planning can be carried out in good time.
          When considering whether and how to take benefits from the client's pension arrangements it
          is important that account is taken of all of the client's investments and their entitlement
          to the State Pension. The tax implications of drawing benefits and the tax treatment
          (including inheritance tax) of amounts that remain invested will be relevant in determining
          the most efficient and appropriate drawdown strategy.
        </p>
        <p>
          Death benefit options in the current schemes should also be reviewed to ensure that they
          meet your client's requirements.
        </p>
      `,
    },
    E: {
      text: `Already taking pension benefits`,
      hint: `A review of pension benefits may be worthwhile.`,
      description: `
        <p>
          Your client is already taking benefits from their pension plan. A review of their pension
          withdrawals may be worthwhile to ensure any benefits are being taken in the most tax
          efficient way.
        </p>
        <p>
          When considering whether and how to take benefits from the client's pension arrangements it
          is important that account is taken of all of the client's investments and their entitlement
          to the State Pension. The tax implications of drawing benefits and the tax treatment
          (including inheritance tax) of amounts that remain invested will be relevant in determining
          the most efficient and appropriate drawdown strategy.
        </p>
      `,
    },
  },
  [FinancialAuditQuestionName.MadeGifts]: {
    definitions: `
      <p>
        <strong>Gift:</strong> A gift can be anything that has a value, such as money, property, and
        possessions. Also, if for example, you sell a property to your child for less than it is worth,
        the difference in value counts as a gift.
      </p>
      <p>
        * Other than gifts to charities, gifts made as normal and regular expenditure out of your
        income and most wedding gifts.
      </p>
    `,
    A: {
      text: `Yes`,
      hint: `Consideration could be given to the use of exemptions other than the £3,000 annual exemption.`,
      description: `
        <p>
          In a tax year that the £3,000 exemption is fully used remember that any unused amount of
          the annual exemption from the immediately preceding tax year can be used but that "carry
          forward" is limited to one year only. Also keep the use of the exemption under review and
          also consider the use of the normal expenditure out of income exemption. These are gifts
          made out of income regularly and without affecting the donor's standard of living. The
          payment of regular contributions to a life insurance policy held in trust for those who
          will inherit your client’s estate on death would normally qualify as being exempt. The sum
          assured under such a policy would not usually be treated as part of your client's taxable
          estate and would be payable free of inheritance tax to the beneficiaries who could then
          use the money to pay the inheritance tax arising on the death of the life or lives assured.
        </p>
      `,
    },
    B: {
      text: `No`,
      hint: `Consideration could be given to how the £3,000 annual exemption and other reliefs and exemptions could be used in estate planning.`,
      description: commonCurrentDescriptions[FinancialAuditQuestionName.MadeGifts],
    },
    C: {
      text: `Don’t know`,
      hint: `Consideration could be given to how the £3,000 annual exemption and other reliefs and exemptions could be used in estate planning.`,
      description: commonCurrentDescriptions[FinancialAuditQuestionName.MadeGifts],
    },
  },
  [FinancialAuditQuestionName.BiggerAssets]: {
    definitions: `
      <p>
        <strong>Assets:</strong> Valuable assets often include cash, investments, vehicles, boats, property, business interests, jewellery and artwork.
      </p>
    `,
    A: {
      text: `Yes`,
      hint: `An inheritance tax review could be beneficial.`,
      description: commonCurrentDescriptions[FinancialAuditQuestionName.BiggerAssets],
    },
    B: {
      text: `No`,
      hint: `The inheritance tax position and estate planning generally should be kept under careful review.`,
      description: `
        <p>
          On the face of it, there is no potential liability to inheritance tax on the value of
          the client's individual estate. The value of the estates and potential liability to
          inheritance tax should be kept regularly under review. Account should also be taken of
          any assets that may be received from the client's spouse or partner by gift or inheritance
          that may cause the client’s estate to become subject to inheritance tax.
        </p>
        <p>
          An annual inheritance tax/estate planning review could be worthwhile to ascertain what,
          if any, future planning and provision may be possible taking account of future changes
          in value and circumstances.
        </p>
        <p>
          This is especially so because the £325,000 nil rate band will remain frozen until
          the end of 2027/28.
        </p>
        <p>
          As part of an estate planning review (and regardless of whether an inheritance tax
          liability is likely to arise on death) it will be important to regularly assess
          whether your client has:
        </p>
        <ul>
          <li>
            - an up to date will reflecting their current intentions in relation to the distribution
            of their estate on death and aligned to their overall estate planning strategy.
          </li>
          <li>
            - fully considered the potential benefit of the efficient and professional estate
            administration service that Kings Court Trust can deliver.
          </li>
          <li>
            - fully considered the potential future benefit of a Lasting Power of Attorney.
          </li>
          <li>
            - fully considered the benefits that protection policies written subject to an appropriate
            trust can deliver including: providing financial security for family and dependents,
            creating an inheritance and tax efficiently meeting the liability to inheritance tax on death.
          </li>
        </ul>
      `,
    },
    C: {
      text: `Don’t know`,
      hint: `An inheritance tax review could be beneficial.`,
      description: commonCurrentDescriptions[FinancialAuditQuestionName.BiggerAssets],
    },
  },
  [FinancialAuditQuestionName.Inheritances]: {
    definitions: `
      <p>
        <strong>Inheritances:</strong> An inheritance is a possession, a property, a right or an obligation that is passed to you after the death of an individual.
      </p>
    `,
    A: {
      text: `Yes`,
      hint: `There might be opportunities for tax efficient estate planning with inherited assets - without necessarily denying access to the assets for the inheritor`,
      description: commonCurrentDescriptions[FinancialAuditQuestionName.Inheritances],
    },
    B: {
      text: `No`,
      description: `No action is necessary.`,
    },
    C: {
      text: `Don’t know`,
      hint: `There might be opportunities for tax efficient estate planning with inherited assets - without necessarily denying access to the assets for the inheritor`,
      description: commonCurrentDescriptions[FinancialAuditQuestionName.Inheritances],
    },
  },
};

const newFormTranslations = {
  [FinancialAuditQuestionName.TaxableIncome]: {
    definitions: `
    <p>
      <strong>Taxable Income:</strong> You pay tax on various income types. Common taxable
      income types include salaries, wages, profits from self-employment, pension income
      (incl. state pensions, company pensions, personal pensions and retirement annuities),
      some state benefits, rental income, and income from a trust.
    </p>
    <p>
      <strong>Tax year:</strong>  This tax year begins on 6th April 2019 and ends on
      5th April 2020.
    </p>
  `,
    A: {
      text: `£0 – £12,500`,
      description: `
        <p>
          Your Client’s income is below the level of the personal allowance and is therefore tax-free
          regardless of its source. To the extent that there is any allowance left unused and subject
          to your Client’s overall financial objectives and circumstances, you should consider
          (if feasible and assets/employment or self-employment circumstances permit) generating
          earned or investment income to fall within your Client’s available personal allowance as
          it will be tax free.
        </p>
      `,
    },
    B: {
      text: `£12,501 – £50,000 (£43,430 in Scotland)`,
      description: `
        <p>
          Your Client’s taxable income for 2019/20 is above their Personal Allowance, £12,500, but below £50,000 (£43,430 in Scotland).
        </p>
        <p>
          Unless resident in Scotland, your Client is thus a basic rate taxpayer so, subject to personal and commercial needs, action to
          minimise tax loss at 20% will be worth considering. In Scotland three marginal tax rates could apply on non-savings, non-dividend
          income: 19% (starter rate) up to £14,550, 20% (basic rate) £14,551-£24,945 and 21% (intermediate rate) £24,946-£43,430.
        </p>
        <p>
          In practice, possible action to reduce taxation will depend substantially on whether the income is employment income or income
          from self-employment, the investments they own or could own taking account of the important need to align tax planning to their
          financial objectives/attitude to, appetite and capacity for risk. Subject to the appropriate limits, ISAs and Pensions will deliver
          tax efficiency as will VCT and EIS investments but with greater risk. Unit trusts, OEICs and in some cases, investment bonds can also
          deliver elements of tax efficiency and deferment but with no “front end” tax relief.
        </p>
        <strong>In addition (if relevant)</strong>
        <b>
          For Couples where the income of one is taxed at a higher rate than the income of the other, they
          could consider the following:
        </b>
        <p>
          Consideration could be given to the transfer of income-producing investments from the higher tax paying Partner to the lower tax
          paying Partner e.g. bank accounts, unit trusts, insurance bonds. This will be possible with no negative immediate IHT or CGT
          consequences if the transfer is made between a married couple or between registered civil partners. If a transfer is to be made
          to a partner who is not a spouse or civil partner, then the IHT and CGT consequences of such a transfer would need to be considered as the
          spouse exemption for IHT would not apply nor would the CGT “no gain/no loss” basis apply.
        </p>
        <p>Where relevant, the transferable marriage allowance could also be considered.</p>
        <b>Business owners could consider:</b>
        <ul>
          <li>
            - To the extent that immediate spendable income is not needed and capacity exists, making pension contributions. (<i>all business owners</i>)
          </li>
          <li>
            - Taking loan repayments instead of salary/dividends to the extent that their business owes them money.
            (<i>shareholding directors</i>)
          </li>
          <li>
            - For amounts withdrawn from the business in excess of the primary earnings threshold (£8,632 in 2019/20), shareholding directors could
            consider drawing dividends as opposed to salary to reduce the net cost of taking funds from the business as dividends are not subject to
            National Insurance. (<i>shareholding directors</i>)
          </li>
          <li>
            - Subject to spendable income requirements and pension contribution capacity, salary sacrifice and “matching” employer pension contributions
            may be possible for shareholding directors. (<i>shareholding directors</i>)
          </li>
        </ul>
        <p><i>If the client is married, in a civil partnership or otherwise in a long term relationship (as appropriate):</i></p>
        <ul>
          <li>
            - Employing a lower taxed Partner provided that the level of income paid can be justified by the work carried out. (<i>all business owners</i>)
          </li>
          <li>
            - if the Partner is already employed, increasing their current salary – provided that any increased payment can be justified by the work carried out. (<i>all business owners</i>)
          </li>
          <li>
            - If the business is a Partnership, and it is appropriate making the lower taxed Partner a business partner in the Partnership. (<i>partnership</i>)
          </li>
          <li>
            - If the business is a limited company, giving or issuing fully participating shares to the lower taxed Partner and declaring dividends. Any transfer of shares to someone other
            than a spouse or registered civil partner would need to take account of potential CGT/IHT as the spouse exemption would not apply (although IHT business relief may be available). (<i>shareholding directors</i>)
          </li>
        </ul>
      `,
    },
    C: {
      text: `£50,001 (£43,431 in Scotland) – £100,000`,
      description: `
        <p>
          Unless resident in Scotland, your Client’s taxable income before deducting their personal allowance of £12,500 (which is assumed to be available in full) is above £50,000 (£43,430 in Scotland) but does not exceed
          £100,000 implies they are a higher rate taxpayer so, subject to personal expenditure requirements, action to minimise tax loss at 40% may be worth considering. In Scotland a higher rate of 41% would apply on non-savings, non- dividend income.
        </p>
        <p>
          In practice, possible action to reduce taxation will depend substantially on whether the income is employment income or income from self-employment, the investments they own or could own taking account of the important need to align tax planning
          to their financial objectives/attitude to, appetite and capacity for risk. Subject to the appropriate limits, ISAs and Pensions will deliver tax efficiency as will VCT and EIS investments but with greater risk. Unit trusts, OEICs and in some
          cases, investment bonds, can also deliver elements of tax efficiency and deferment but with no “front end” tax relief.
        </p>
        <strong>In addition (if relevant)</strong>
        <b>For Couples where the income of one is taxed at a higher rate than the income of the other, they could consider the following:</b>
        <p>
          Consideration could be given to the transfer of income-producing investments from the higher tax paying Partner to the lower tax paying Partner e.g. bank accounts, unit trusts, insurance bonds. This will be possible with no negative immediate
          IHT or CGT consequences if the transfer is made between a married couple or between registered civil partners. If a transfer is to be made to a partner who is not a spouse or civil partner, then the IHT and CGT consequences of such a transfer
          would need to be considered as the spouse exemption for IHT would not apply nor would the CGT “no gain/no loss” basis apply.
        </p>
        <b>Business owners could consider:</b>
        <ul>
          <li>
            - To the extent that immediate spendable income is not needed and capacity exists, making pension contributions. (<i>all business owners</i>)
          </li>
          <li>
            - Taking loan repayments instead of salary/dividends to the extent that their business owes them money. (<i>shareholding directors</i>)
          </li>
          <li>
            - For amounts withdrawn from the business in excess of the primary earnings threshold (£8,632 in 2019/20), shareholding directors could consider drawing dividends as opposed to salary to reduce the net cost of taking funds from the business
            as dividends are not subject to National Insurance. (<i>shareholding directors</i>)
          </li>
          <li>
            - Subject to spendable income requirements and pension contribution capacity, salary sacrifice and “matching” employer pension contributions may be possible for shareholding directors. (<i>shareholding directors</i>)
          </li>
        </ul>
        <p><i>If the client is married, in a civil partnership or otherwise in a long term relationship (as appropriate):</i></p>
        <ul>
          <li>
            - Employing a lower taxed Partner provided that the level of income paid can be justified by the work carried out. (<i>all business owners</i>)
          </li>
          <li>
            - If the Partner is already employed, increasing their current salary – provided that any increased payment can be justified by the work carried out. (<i>all business owners</i>)
          </li>
          <li>
            - If the business is a Partnership, making the lower taxed Partner a business partner in the Partnership. (<i>partnership</i>)
          </li>
          <li>
            - If the business is a limited company, giving or issuing fully participating shares to the lower taxed Partner and declaring dividends. Any transfer of shares to someone other than a spouse or registered civil
            partner would need to take account of potential CGT/IHT as the spouse exemption would not apply (although IHT business relief may be available). (<i>shareholding directors</i>)
          </li>
        </ul>

      `,
    },
    D: {
      text: `£100,001 – £150,000`,
      description: `
        <p>
          Your Client’s taxable income is between £100,001 and £150,000.
        </p>
        <p>
          Their personal allowance will be reduced by £1 for every £2 of income that exceeds £100,000. This means that income in the band £100,000 – £125,000 bears an effective rate of income tax of up to 60%
          (61.5% on non- savings, non-dividend income in Scotland).
        </p>
        <p>
          Your Client is a higher rate taxpayer so, subject to personal expenditure requirements, action to minimise tax loss may be worth considering. In practice, possible action to reduce taxation will depend substantially
          on whether the income is employment income or income from self-employment, the investments they own or could own taking account of the important need to align tax planning to their financial objectives/attitude to,
          appetite and capacity for risk. Subject to the appropriate limits, ISAs and Pensions will deliver tax efficiency as will VCT and EIS investments but with greater risk. Unit trusts, OEICs and in some cases, investment
          bonds can also deliver elements of tax efficiency and deferment but with no “front end” tax relief.
        </p>
        <strong>In addition (if relevant)</strong>
        <b>For Couples where the income of one is taxed at a higher rate than the income of the other, they could consider the following:</b>
        <p>
          Consideration could be given to the transfer of income-producing investments from the higher tax paying Partner to the lower tax paying Partner e.g. bank accounts, unit trusts, insurance bonds. This will be possible with
          no negative immediate IHT or CGT consequences if the transfer is made between a married couple or between registered civil partners. If a transfer is to be made to a partner who is not a spouse or civil partner, then the
          IHT and CGT consequences of such a transfer would need to be considered as the spouse exemption for IHT would not apply nor would the CGT “no gain/no loss” basis apply.
        </p>
        <b>Business owners could consider:</b>
        <ul>
          <li>
            - To the extent that immediate spendable income is not needed and capacity exists, making pension contributions. (<i>all business owners</i>)
          </li>
          <li>
            - Taking loan repayments instead of salary/dividends to the extent that their business owes them money. (<i>shareholding directors</i>)
          </li>
          <li>
            - For amounts withdrawn from the business in excess of the primary earnings threshold (£8,632 in 2019/20), shareholding directors could consider drawing dividends as opposed to salary to reduce the net cost of taking
            funds from the business as dividends are not subject to National Insurance. (<i>shareholding directors</i>)
          </li>
          <li>
            - Subject to spendable income requirements and pension contribution capacity, salary sacrifice and “matching” employer pension contributions may be possible for shareholding directors. (<i>shareholding directors</i>)
          </li>
        </ul>
        <p><i>If the client is married, in a civil partnership or otherwise in a long term relationship (as appropriate):</i></p>
        <ul>
          <li>
            - Employing a lower taxed Partner provided that the income paid can be justified by the work carried out. (<i>all business owners</i>)
          </li>
          <li>
            - If the Partner is already employed, increasing their current salary – provided that any increased payment can be justified by the work carried out. (<i>all business owners</i>)
          </li>
          <li>
            - If the business is a Partnership, making the lower taxed Partner a business partner in the Partnership. (<i>partnership</i>)
          </li>
          <li>
            - If the business is a limited company, giving or issuing fully participating shares to the lower taxed Partner and declaring dividends. Any transfer of shares to someone other than a spouse or registered civil partner would
            need to take account of potential CGT/IHT as the spouse exemption would not apply (although IHT business relief may be available). (<i>shareholding directors</i>)
          </li>
        </ul>
        <b> And, in addition to the personal income tax planning possibilities outlined above, there may also be retirement planning considerations as the client earns in excess of £100,000.</b>
        <p>
          Your Client may well be impacted by the tapered annual allowance which could reduce the level of tax-efficient pension savings they can make this year.
        </p>
        <p>
          An assessment of threshold income and, if needed, adjusted income should be conducted to establish if the tapered annual allowance applies and, if so, how much allowance is available. This can then be used to establish if there is any scope for further pension contributions.
        </p>
        <p>
          In addition, it may be possible to restore the full annual allowance by making additional personal contributions to a pension scheme or rearranging the structure of existing contributions.
        </p>
      `,
    },
    E: {
      text: `£150,001+`,
      description: `
        <p>Your Client’s taxable income for the tax year is over £150,000.</p>
        <p><i>Every £1 of taxable income above £150,000 will be taxed at the additional rate of 45% (46% in Scotland). Tax saving for these clients will be very worthwhile.</i></p>
        <p>
          Your Client is an additional rate taxpayer so, subject to personal expenditure requirements, action to minimise tax loss at 45% (46% on non-savings, non-dividend income in Scotland) may be worth considering. In practice, possible action to reduce taxation will depend
          substantially on whether the income is employment income or income from self-employment, the investments they own or could own taking account of the important need to align tax planning to their financial objectives/attitude to, appetite and capacity for risk. Subject to the
          appropriate limits, ISAs and Pensions will deliver tax efficiency as will VCT and EIS investments but with greater risk. Unit trusts, OEICs and in some cases, investment bonds can also deliver elements of tax efficiency and deferment but with no “front end” tax relief.
        </p>
        <strong>In addition (if relevant)</strong>
        <b>For Couples where the income of one is taxed at a higher rate than the income of the other, they could consider the following:</b>
        <p>
          Consideration could be given to the transfer of income-producing investments from the higher tax paying Partner to the lower tax paying Partner e.g. bank accounts, unit trusts, insurance bonds. This will be possible with no negative immediate IHT or CGT consequences if the transfer
          is made between a married couple or between registered civil partners. If a transfer is to be made to a long-term partner who is not a spouse or civil partner, then the IHT and CGT consequences of such a transfer would need to be considered as the spouse exemption for IHT would not
          apply nor would the CGT “no gain/no loss” basis apply.
        </p>
        <b>Business owners could consider:</b>
        <ul>
          <li>
            - To the extent that immediate spendable income is not needed and capacity exists, making pension contributions. (<i>all business owners</i>)
          </li>
          <li>
            - Taking loan repayments instead of salary/dividends to the extent that their business owes them money. (<i>shareholding directors</i>)
          </li>
          <li>
            - For amounts withdrawn from the business in excess of the primary earnings threshold (£8,632 in 2019/20), shareholding directors could consider drawing dividends as opposed to salary to reduce the net cost of taking funds from the business as dividends are not subject to National Insurance. (<i>shareholding directors</i>)
          </li>
          <li>
            - Subject to spendable income requirements and pension contribution capacity, salary sacrifice and “matching” employer pension contributions may be possible for shareholding directors. (<i>shareholding directors</i>)
          </li>
        </ul>
        <p><i>If the client is married, in a civil partnership or otherwise in a long term relationship (as appropriate):</i></p>
        <ul>
          <li>
            - Employing a lower taxed Partner provided that the income paid can be justified by the work carried out. (<i>all business owners</i>)
          </li>
          <li>
            - If the Partner is already employed, increasing their current salary – provided that any increased payment can be justified by the work carried out. (<i>all business owners</i>)
          </li>
          <li>
            - If the business is a Partnership, making the lower taxed Partner a business partner in the Partnership. (<i>partnership</i>)
          </li>
          <li>
            - If the business is a limited company, giving or issuing fully participating shares to the lower taxed Partner and declaring dividends. Any transfer of shares to someone other than a spouse or registered civil partner would need to take account of potential CGT/IHT as the spouse exemption would not apply
            (although IHT business relief may be available). (<i>shareholding directors</i>)
          </li>
        </ul>
        <b>In addition to the personal income tax planning possibilities outlined above, there may also be retirement planning considerations as the client earns in excess of £150,000.</b>
        <p>
          Your Client may well be impacted by the tapered annual allowance which could reduce the level of tax-efficient pension savings they can make this year.
        </p>
        <p>
          An assessment of threshold income and adjusted income should be conducted to establish if the tapered annual allowance applies and, if so, how much allowance is available. This can then be used to establish if there is any scope for more contributions.
        </p>
        <p>
          In addition, it might be possible to restore the full annual allowance by making additional personal contributions to a pension scheme or rearranging the structure of existing contributions.
        </p>
      `,
    },
  },
  [FinancialAuditQuestionName.IsaInvested]: {
    definitions: `
      <p>
        <strong>Individual Savings Account (ISA):</strong> An ISA provides a valuable, tax-efficient wrapper for cash and investments. The total ISA allowance for the 2019/20 tax year is £20,000.
        Individuals can invest into Cash ISAs or Stocks and Shares ISAs or other ISAs in any permissible combination, up to the total combined annual allowance.
      </p>
      <p>
        The value of an ISA will be directly linked to the performance of the funds selected and may fall as well as rise. You may get back less than you invested.
      </p>
      <p>
        An investment in a Stocks and Shares ISA will not provide the same security of capital associated with a Cash ISA.
      </p>
      <p>
      The favourable tax treatment of ISAs may not be maintained in the future and is subject to changes in legislation.
      </p>
    `,
    A: {
      text: `Yes`,
      description: `
      <p>
        No further ISA investment is possible.
      </p>
      <p>
        If further tax efficient investment is desired then consideration, subject to overall investment considerations and attitude to risk, could be given to pension, VCT and EIS investment and appropriate unit trusts and single premium bonds.
      </p>
      <p>
        If the Client has children under the age of 18 then each child can have a Junior ISA into which £4,368 can be invested in total in the 2019/20 tax year.
      </p>
    `,
    },
    B: {
      text: `No`,
      description: commonDescriptions[FinancialAuditQuestionName.IsaInvested],
    },
    C: {
      text: `Don’t know`,
      description: commonDescriptions[FinancialAuditQuestionName.IsaInvested],
    },
  },
  [FinancialAuditQuestionName.ReceiveInInterest]: {
    definitions: `
      <p>
        <strong>Higher rate taxpayer:</strong> If you live in England, Wales or Northern Ireland and you have taxable income of more than £50,000, you are a higher rate taxpayer. If you live in Scotland you are a higher rate taxpayer if you have taxable income of more than £43,431.
      </p>
      <p>
        <strong>Tax year:</strong> This tax year begins on 6th April 2019 and ends on 5th April 2020.
      </p>
    `,
    A: {
      text: `Yes`,
      description: `
        <p>Your client (and, if relevant, their Partner) is fully using their Personal Savings Allowance.</p>
        <p>
          Subject to their overall investment objectives the client and, if relevant, their Partner could consider investing so as to maximise their ISA contributions and or to produce tax free dividends and tax effective gains through unit trusts, OEICs and possible tax deferment through investment bonds.
        </p>
      `,
    },
    B: {
      text: `No`,
      description: commonDescriptions[FinancialAuditQuestionName.ReceiveInInterest],
    },
    C: {
      text: `Don’t know`,
      description: commonDescriptions[FinancialAuditQuestionName.ReceiveInInterest],
    },
  },
  [FinancialAuditQuestionName.ReceiveInDividends]: {
    definitions: `
      <p>
        <strong>Dividends:</strong> You may get a dividend payment if you own shares in a company.
      </p>
      <p>
        <strong>Tax year:</strong> This tax year begins on 6th April 2019 and ends on 5th April 2020.
      </p>
    `,
    A: {
      text: `Yes`,
      description: `
        <p>
          Your client and/or (if relevant) their Partner receives more than the £2,000 per tax year in dividends.
          The excess over £2,000 is taxable at their appropriate marginal rate.
        </p>
        <strong>Consider: </strong>
        <ul>
          <li>
            - Maximising ISA investments (£20,000 per tax year).
          </li>
          <li>
            - Investing in non-income producing assets (growth unit trusts/UK or offshore insurance bonds).
          </li>
          <li>
            - Supplementing required income with capital (withdrawals/capital gains).
          </li>
          <li>
            - Subject to attitude to/appetite for risk, investing in VCT investments to produce tax free dividends.
          </li>
        </ul>
        <p>
          In making all or any of the above investment decisions it will, of course, be necessary to take full account
          of all factors including the relative importance of capital growth, the Client’s overall investment portfolio and attitude to risk and capacity for loss.
        </p>
      `,
    },
    B: {
      text: `No`,
      description: commonDescriptions[FinancialAuditQuestionName.ReceiveInDividends],
    },
    C: {
      text: `Don’t know`,
      description: commonDescriptions[FinancialAuditQuestionName.ReceiveInDividends],
    },
  },
  [FinancialAuditQuestionName.ExpectCapitalGains]: {
    definitions: `
      <p>
        <strong>Capital gain:</strong> A capital gain is a profit earned when you sell, or ‘dispose of’, an asset that has increased in value.
      </p>
      <p>
        <strong>Tax year:</strong> This tax year begins on 6th April 2019 and ends on 5th April 2020.
      </p>
    `,
    A: {
      text: `Yes`,
      description: `
        <p>
          There is no scope to use the annual CGT exemption but remember that the annual CGT exemption is available to each individual – including spouses, civil partners and children.
        </p>
        <p>
          Transfers between spouses/civil partners living together can be made on a tax neutral no gain/no loss basis, so, where relevant:
        </p>
        <ul>
          <li>
            - Consider transfer of assets with gains that would be taxed if realised by one spouse/civil partner to a spouse/civil partner with an available annual CGT exemption.
          </li>
          <li>
            - Consider transfer of assets with losses from a spouse/civil partner with no gains to one with gains – care anti-avoidance.
          </li>
        </ul>
        <p>
          The annual CGT exemption of £12,000 is available for each family member. If not used in a tax year it cannot be carried forward.
        </p>
        <p>
          Grandparents/parents to consider transferring cash/investments to children/grandchildren or into a bare trust/ nomineeship (an investment, such as a unit trust, held by a
          parent or grandparent as nominee for the child) for them so that capital gains made on investments would be assessed on the child. Of course, the legal implications of a bare
          trust/nomineeship would need to be considered before this planning was executed. Also, if the parent of a minor child beneficiary is the settlor then any income produced in
          excess of £100 pa will be assessed on the parent. This will not be the case where the settlor is someone other than a parent e.g. grandparent.
        </p>
        <p>
          It may also be worth considering investing in a Junior ISA for the child.
        </p>
        <p>
          The transfer would be chargeable for CGT (unless it is cash) and would be a potentially exempt/exempt transfer for IHT. The former only if the transfer is made outright or into
          a bare trust/nomineeship and the latter dependant on the amount transferred. If the transfer is not outright or into a Bare Trust it is likely to be a chargeable transfer for IHT.
          Whether IHT is payable will depend on the availability of the exemptions and the amount of the transfer. Any capital gain arising as a result of a chargeable transfer can be held over (deferred).
        </p>
      `,
    },
    B: {
      text: `No`,
      description: commonDescriptions[FinancialAuditQuestionName.ExpectCapitalGains],
    },
    C: {
      text: `Don’t know`,
      description: commonDescriptions[FinancialAuditQuestionName.ExpectCapitalGains],
    },
  },
  [FinancialAuditQuestionName.CapitalGainExcess]: {
    definitions: `
      <p>
        <strong>Capital gain:</strong> A capital gain is a profit earned when you sell, or ‘dispose of’, an asset that has increased in value.
      </p>
      <p>
        <strong>Annual Exempt Amount:</strong> Eligible individuals have an annual tax-free allowance for Capital Gains Tax known as the ‘Annual Exempt Amount’. The Annual Exempt Amount for the current tax year is £12,000.
      </p>
      <p>
        <strong>Tax year:</strong> This tax year begins on 6th April 2019 and ends on 5th April 2020.
      </p>
      <p>
        The levels and bases of taxation, and reliefs from taxation, can change at any time. The value of any tax relief depends on individual circumstances.
      </p>
    `,
    A: {
      text: `Yes`,
      description: commonDescriptions[FinancialAuditQuestionName.CapitalGainExcess],
    },
    B: {
      text: `No`,
      description: `No action neccessary`,
    },
    C: {
      text: `Don’t know`,
      description: commonDescriptions[FinancialAuditQuestionName.CapitalGainExcess],
    },
  },
  [FinancialAuditQuestionName.ContributeToPension]: {
    A: {
      text: `£40,000 or less`,
      description: `
        <p>
          Your Client and their employer together are likely to (or may) contribute less than £40,000 (in total) to your Client’s pension this tax year.
        </p>
        <p>
          Assuming that neither the money purchase annual allowance nor tapering apply, then up to £40,000 pa can be contributed (in total) to a pension arrangement each year. In addition,
          once the annual allowance for the year has been fully used, your client is entitled to utilise previously unused annual allowance for each of the immediately preceding three tax years
          in which they were a member of registered pension scheme. This additional contribution representing carried forward relief can be made by the employer and/or employee.
        </p>
        <p>
          An assessment of unused relief should be carried out taking full account of the permitted maximum for each of the three preceding tax years and the amounts actually contributed to registered pensions for or on behalf of the member in each of those years.
        </p>
      `,
    },
    B: {
      text: `More than £40,000`,
      description: `
        <p>
          Your Client and their employer are likely to (or may) contribute more than £40,000 (in total) to your Client’s pension this tax year.
        </p>
        <p>
          Once the annual allowance for the year has been fully used your client is entitled to utilise previously unused annual allowance for each of the immediately preceding three tax years in which they were a member of a registered UK pension scheme.
          This additional contribution representing carried forward relief can be made by the employer and/or employee.
        </p>
        <p>
          An assessment of unused relief should be carried out taking full account of the permitted maximum for each of the three preceding tax years and the amounts actually contributed to registered pensions for or on behalf of the member in each of those years.
        </p>
        <p>
          With further investment in pensions prohibited, subject to attitude to and appetite for risk, consideration could be given to all or some (as appropriate) of ISA investment, VCT, EIS, unit trusts, OEICs and investment bonds.
        </p>
      `,
    },
    C: {
      text: `Don’t know`,
      description: `<p>Please use your discretion. Perhaps discuss their pension contributions with your Client.</p>`,
    },
  },
  [FinancialAuditQuestionName.PensionBenefits]: {
    A: {
      text: `Within the next 12 months`,
      description: commonDescriptions[FinancialAuditQuestionName.PensionBenefits],
    },
    B: {
      text: `Within the next five years`,
      description: `
        <p>
          Your Client is planning on taking benefits from their pension within the next 5 years.
        </p>
        <p>
          Old-style pension schemes may now not be suitable for taking benefits from. Transfers at or near retirement can be stressful and may delay payment of benefits, so reviewing well in advance is key.
        </p>
        <p>
          Review pension benefits with a plan to possibly consolidate and transfer to a pension under which the type of retirement benefits required are available.
        </p>
        <p>
          Death benefit options in the current schemes should also be reviewed to ensure that they meet your Client’s requirements.
        </p>
      `,
    },
    C: {
      text: `More than five years from now`,
      description: `
        <p>
          No benefit withdrawals are planned within the next five years.
        </p>
        <p>
          Keep the position under review so that appropriate planning can be carried out in good time.
        </p>
      `,
    },
    D: {
      text: `Don’t know`,
      description: commonDescriptions[FinancialAuditQuestionName.PensionBenefits],
    },
  },
  [FinancialAuditQuestionName.MadeGifts]: {
    definitions: `
      <p>
        <strong>Gift:</strong> A gift can be anything that has a value, such as money, property, and possessions. Also, if for example, you sell a property to your child for less than it is worth, the difference in value counts as a gift.
      </p>
    `,
    A: {
      text: `Yes`,
      description: `
        <p>
          In a tax year that the £3,000 exemption is fully used, remember that any unused amount of the annual exemption from the immediately preceding tax year can be used but that “carry forward” is limited to one year only. Also keep the
          use of the exemption under review and also consider the use of the normal expenditure exemption. These are gifts made out of income regularly and without affecting the donor’s standard of living. The payment of regular contributions
          to a life insurance policy held in trust for those who will inherit your estate on death (or the estate of the survivor of you in the case of a couple leaving assets to each other on the death of the first of them to die) would
          normally qualify as being exempt. The sum assured under such a policy would not usually be treated as part of your Client’s taxable estate and would be payable free of inheritance tax to the beneficiaries who could then use the money to pay the IHT arising on the death of the life or lives assured.
        </p>
      `,
    },
    B: {
      text: `No`,
      description: commonDescriptions[FinancialAuditQuestionName.MadeGifts],
    },
    C: {
      text: `Don’t know`,
      description: commonDescriptions[FinancialAuditQuestionName.MadeGifts],
    },
  },
  [FinancialAuditQuestionName.BiggerAssets]: {
    definitions: `
      <p>
        <strong>Assets:</strong> Valuable assets often include cash, investments, vehicles, boats, property, business interests, jewellery and artwork.
      </p>
    `,
    A: {
      text: `Yes`,
      description: commonDescriptions[FinancialAuditQuestionName.BiggerAssets],
    },
    B: {
      text: `No`,
      description: `
        <p>
          On the face of it, there is no potential liability to inheritance tax based on the current value of the estates. The value of the estates and potential liability to inheritance tax should be kept regularly under review.
        </p>
        <p>
          An annual IHT/estate planning review could be worthwhile to ascertain what, if any, future planning and provision may be possible taking account of future changes in value and circumstances.
        </p>
        <p>
          This is especially so because the £325,000 nil rate band will remain frozen until the end of 2020/21.
        </p>
        <p>
          As part of an estate planning review (and regardless of whether an IHT liability is likely to arise on death) it will be important to regularly assess whether your Client has:
        </p>
        <ul>
          <li>
            - an up to date will reflecting their current intentions in relation to the distribution of their estate on death and aligned to their overall estate planning strategy.
          </li>
          <li>
            - fully considered the potential benefit of the efficient and professional estate administration service that Kings Court Trust can deliver.
          </li>
          <li>
            - fully considered the potential future benefit of a Lasting Power of Attorney.
          </li>
          <li>
            - fully considered the benefits that protection policies written subject to an appropriate trust can deliver including; providing financial security for family and dependants, creating an inheritance and tax efficiently meeting the liability to inheritance tax on death.
          </li>
        </ul>
      `,
    },
    C: {
      text: `Don’t know`,
      description: commonDescriptions[FinancialAuditQuestionName.BiggerAssets],
    },
  },
  [FinancialAuditQuestionName.Inheritances]: {
    definitions: `
      <p>
        <strong>Inheritances:</strong> An inheritance is a possession, a property, a right or an obligation that is passed to you after the death of an individual.
      </p>
    `,
    A: {
      text: `Yes`,
      description: commonDescriptions[FinancialAuditQuestionName.Inheritances],
    },
    B: {
      text: `No`,
      description: `No action neccessary`,
    },
    C: {
      text: `Don’t know`,
      description: commonDescriptions[FinancialAuditQuestionName.Inheritances],
    },
  },
};

export default {
  emptyForm: `There are no answers yet.`,
  clientAnswer: `Client’s answer was:`,
  previousAnswer: `Previous answer was:`,
  answersChanged: `Save changes?`,
  answersChangedDescription: `You have changed the client’s answers. Please confirm and save or cancel changes.`,
  withPartner: `With my partner`,
  alone: `Alone`,
  blocked: `This Personal Financial Audit relates to a previous tax year. The client did not complete it before the tax year ended. However, it is now marked as completed.`,
  newBlocked: `This Tax Health Check relates to a previous tax year. The client did not complete it before the tax year ended. However, it is now marked as completed.`,
  includePartner: `Complete the audit for you alone, or with your partner?`,
  newIncludePartner: `Will you complete this Tax Health Check alone, or with your partner?`,
  partnerNote: `Throughout this audit a spouse, registered civil partner and life partner will be referred to as a ‘partner’.`,
  notEditableText: `This Personal Financial Audit was created in a previous tax year. As such, it is no longer editable.`,
  newNotEditableText: `This Tax Health Check was created in a previous tax year. As such, it is no longer editable.`,
  notDoneOldVersion: `This Personal Financial Audit relates to a previous tax year, and as such is no longer relevant and cannot be completed. However, you can mark this audit as complete to progress this questionnaire.`,
  newNotDoneOldVersion: `This Tax Health Check relates to a previous tax year, and as such is no longer relevant and cannot be completed. However, you can mark this audit as complete to progress this questionnaire.`,
  markedOldVersion: `This Personal Financial Audit relates to a previous tax year. You didn't complete it before the tax year ended. However, you have marked it as completed.`,
  newMarkedOldVersion: `This Tax Health Check relates to a previous tax year. You didn't complete it before the tax year ended. However, you have marked it as completed.`,
  filled: {
    withPartner: `Note: Current Financial Audit has been fullfilled with partner included.`,
    alone: `Note: Current Financial Audit has been fullfilled without partner included.`,
    newWithPartner: `Note: The Tax Health Check has already been completed with your partner included.`,
    newAlone: `Note: The Tax Health Check has been fullfilled without partner included.`,
  },
  [FinancialAuditVersion.Current]: {
    stepBegin: {
      description0: `Paying unnecessary tax diminishes your wealth.`,
      description1: `There are many simple, permissible and effective ways to help reduce your tax bill.`,
      description2: `Answer the questions that follow to help us to identify simple but effective
        tax-saving opportunities that may be relevant to you. If you have a spouse or partner who
        would like to identify tax saving opportunities you could complete this Tax Health Check on
        their behalf. Please answer every question by ticking the relevant box(es).
      `,
      description3: `Dependent on the answers to the questions there may be an opportunity, or
        opportunities, to improve your financial well-being. Based on the answers given, it will be
        possible to ascertain which, if any, action(s) may be appropriate for you and, if relevant,
        your spouse or partner.
      `,
      description4: `The levels and bases of taxation and reliefs from taxation can change at any
        time. The value of any tax relief depends on individual circumstances.`,
      description5: `Note: Throughout this Tax Health Check a spouse, registered civil partner and
        life partner will be referred to as a “partner”.`,
      description6: `Note: This Tax Health Check is aimed at residents of the United Kingdom.`,
    },
    [FinancialAuditQuestionName.CountryOfResidence]: {
      question: `What is your country of residence?`,
      ...currentFormTranslations[FinancialAuditQuestionName.CountryOfResidence],
    },
    [FinancialAuditQuestionName.CountryOfResidencePartner]: {
      question: `What is your partner’s country of residence?`,
      ...currentFormTranslations[FinancialAuditQuestionName.CountryOfResidence],
    },
    [FinancialAuditQuestionName.TaxableIncome]: {
      question: `Which band does your taxable income for this tax year fall into?`,
      ...currentFormTranslations[FinancialAuditQuestionName.TaxableIncome],
    },
    [FinancialAuditQuestionName.TaxableIncomePartner]: {
      question: `Which band does your partner’s taxable income for this tax year fall into?`,
      ...currentFormTranslations[FinancialAuditQuestionName.TaxableIncome],
    },
    [FinancialAuditQuestionName.IsaInvested]: {
      question: `Do you invest the maximum (£20,000 per tax year) into ISAs?`,
      ...currentFormTranslations[FinancialAuditQuestionName.IsaInvested],
    },
    [FinancialAuditQuestionName.IsaInvestedPartner]: {
      question: `Does your partner invest the maximum (£20,000 per tax year) into ISAs?`,
      ...currentFormTranslations[FinancialAuditQuestionName.IsaInvested],
    },
    [FinancialAuditQuestionName.ReceiveInInterest]: {
      question: `Do you receive savings income in excess of your Personal Savings Allowance (PSA)?`,
      ...currentFormTranslations[FinancialAuditQuestionName.ReceiveInInterest],
    },
    [FinancialAuditQuestionName.ReceiveInInterestPartner]: {
      question: `Does your partner receive savings income in excess of their Personal Savings Allowance (PSA)?`,
      ...currentFormTranslations[FinancialAuditQuestionName.ReceiveInInterest],
    },
    [FinancialAuditQuestionName.ReceiveInDividends]: {
      question: `Are you likely to receive more than £1,000 in dividends in the current tax year?`,
      ...currentFormTranslations[FinancialAuditQuestionName.ReceiveInDividends],
    },
    [FinancialAuditQuestionName.ReceiveInDividendsPartner]: {
      question: `Is your partner likely to receive more than £1,000 in dividends in the current tax year?`,
      ...currentFormTranslations[FinancialAuditQuestionName.ReceiveInDividends],
    },
    [FinancialAuditQuestionName.ExpectCapitalGains]: {
      question: `Do you expect to realise capital gains (after deduction of allowable losses) of
        more than £6,000 in the current tax year?`,
      ...currentFormTranslations[FinancialAuditQuestionName.ExpectCapitalGains],
    },
    [FinancialAuditQuestionName.ExpectCapitalGainsPartner]: {
      question: `Does your partner expect to realise capital gains (after deduction of allowable losses)
        of more than £6,000 in the current tax year?`,
      ...currentFormTranslations[FinancialAuditQuestionName.ExpectCapitalGains],
    },
    [FinancialAuditQuestionName.CapitalGainExcess]: {
      question: `Did you realise a capital gain in excess of the annual exempt amount in any of the
        immediately preceding three tax years (£12,300 in 2022/23, £12,300 in 2021/22, £12,300 in
        2020/21)?`,
      ...currentFormTranslations[FinancialAuditQuestionName.CapitalGainExcess],
    },
    [FinancialAuditQuestionName.CapitalGainExcessPartner]: {
      question: `Did your partner realise a capital gain in excess of the annual exempt amount in
        any of the immediately preceding three tax years (£12,300 in 2022/23, £12,300 in 2021/22,
        £12,300 in 2020/21)?`,
      ...currentFormTranslations[FinancialAuditQuestionName.CapitalGainExcess],
    },
    [FinancialAuditQuestionName.ContributeToPension]: {
      question: `How much in total are you and (if relevant) your employer(s) likely to contribute to your pension in this tax year?`,
      ...currentFormTranslations[FinancialAuditQuestionName.ContributeToPension],
    },
    [FinancialAuditQuestionName.ContributeToPensionPartner]: {
      question: `How much in total is your partner and (if relevant) their employer(s) likely to contribute to your partner’s pension in this tax year?`,
      ...currentFormTranslations[FinancialAuditQuestionName.ContributeToPension],
    },
    [FinancialAuditQuestionName.PensionBenefits]: {
      question: `When are you planning on taking benefits from your pensions?`,
      ...currentFormTranslations[FinancialAuditQuestionName.PensionBenefits],
    },
    [FinancialAuditQuestionName.PensionBenefitsPartner]: {
      question: `When is your partner planning on taking benefits from their pensions?`,
      ...currentFormTranslations[FinancialAuditQuestionName.PensionBenefits],
    },
    [FinancialAuditQuestionName.MadeGifts]: {
      question: ` Do you typically make gifts of more than £3,000 in a tax year?`,
      ...currentFormTranslations[FinancialAuditQuestionName.MadeGifts],
    },
    [FinancialAuditQuestionName.MadeGiftsPartner]: {
      question: ` Does your partner typically make gifts of more than £3,000 in a tax year?`,
      ...currentFormTranslations[FinancialAuditQuestionName.MadeGifts],
    },
    [FinancialAuditQuestionName.BiggerAssets]: {
      question: `Do you own assets worth more than £325,000 in total or (if you have an interest in a home valued in excess of £175,000 that you plan to leave to children/grandchildren) more than £500,000 in total?`,
      ...currentFormTranslations[FinancialAuditQuestionName.BiggerAssets],
    },
    [FinancialAuditQuestionName.BiggerAssetsPartner]: {
      question: `Does your partner own assets worth more than £325,000 in total or (if they have an interest in a home valued in excess of £175,000 that they plan to leave to children/grandchildren) more than £500,000 in total?`,
      ...currentFormTranslations[FinancialAuditQuestionName.BiggerAssets],
    },
    [FinancialAuditQuestionName.Inheritances]: {
      question: `Have you received any inheritances within the last two years?`,
      ...currentFormTranslations[FinancialAuditQuestionName.Inheritances],
    },
    [FinancialAuditQuestionName.InheritancesPartner]: {
      question: `Has your partner received any inheritances within the last two years?`,
      ...currentFormTranslations[FinancialAuditQuestionName.Inheritances],
    },
  },
  [FinancialAuditVersion.New]: {
    stepBegin: {
      description0: `Paying unnecessary tax diminishes your wealth. However, there are many simple,
        permissible and effective ways to help reduce your tax bill.`,
      description1: `Answer the following questions to help us to identify tax-saving opportunities that
        may be relevant to you. Please answer every question by ticking the relevant box(es).
      `,
      description2: `Depending on the answers to the questions there may be an opportunity, or opportunities,
        to improve financial well-being. Based on the answers given it will be possible to ascertain which,
        if any, action(s) may be appropriate for you and, if relevant, your spouse or partner.
      `,
      description3: `The levels and bases of taxation and reliefs from taxation can change at any
        time. The value of any tax relief depends on individual circumstances.`,
      description4: `Throughout this audit a spouse, registered civil partner and life partner will
        be referred to as a ‘partner’.`,
    },
    [FinancialAuditQuestionName.TaxableIncome]: {
      question: `Which band does your taxable income for this tax year fall into?`,
      ...newFormTranslations[FinancialAuditQuestionName.TaxableIncome],
    },
    [FinancialAuditQuestionName.TaxableIncomePartner]: {
      question: `Which band does your partner’s taxable income for this tax year fall into?`,
      ...newFormTranslations[FinancialAuditQuestionName.TaxableIncome],
    },
    [FinancialAuditQuestionName.IsaInvested]: {
      question: `Do you invest the maximum (£20,000 per tax year) into ISAs?`,
      ...newFormTranslations[FinancialAuditQuestionName.IsaInvested],
    },
    [FinancialAuditQuestionName.IsaInvestedPartner]: {
      question: `Does your partner invest the maximum (£20,000 per tax year) into ISAs?`,
      ...newFormTranslations[FinancialAuditQuestionName.IsaInvested],
    },
    [FinancialAuditQuestionName.ReceiveInInterest]: {
      question: `Will you receive more than £1,000 (or £500 if a higher rate taxpayer) in interest in the current tax year? (This question is not relevant for additional rate taxpayers).`,
      ...newFormTranslations[FinancialAuditQuestionName.ReceiveInInterest],
    },
    [FinancialAuditQuestionName.ReceiveInInterestPartner]: {
      question: `Will your partner receive more than £1,000 (or £500 if a higher rate taxpayer) in interest in the current tax year? (This question is not relevant for additional rate taxpayers).`,
      ...newFormTranslations[FinancialAuditQuestionName.ReceiveInInterest],
    },
    [FinancialAuditQuestionName.ReceiveInDividends]: {
      question: `Are you likely to receive more than £2,000 in dividends in the current tax year?`,
      ...newFormTranslations[FinancialAuditQuestionName.ReceiveInDividends],
    },
    [FinancialAuditQuestionName.ReceiveInDividendsPartner]: {
      question: `Is your partner likely to receive more than £2,000 in dividends in the current tax year?`,
      ...newFormTranslations[FinancialAuditQuestionName.ReceiveInDividends],
    },
    [FinancialAuditQuestionName.ExpectCapitalGains]: {
      question: `Do you expect to realise capital gains (after deduction of allowable losses) of more
        than £12,000 in the current tax year?`,
      ...newFormTranslations[FinancialAuditQuestionName.ExpectCapitalGains],
    },
    [FinancialAuditQuestionName.ExpectCapitalGainsPartner]: {
      question: `Does your partner expect to realise capital gains (after deduction of allowable losses)
        of more than £12,000 in the current tax year?`,
      ...newFormTranslations[FinancialAuditQuestionName.ExpectCapitalGains],
    },
    [FinancialAuditQuestionName.CapitalGainExcess]: {
      question: `Did you realise a capital gain in excess of the annual exempt amount in any of the immediately
        preceding three tax years (£11,700 2018/19; £11,300 2017/18; £11,100 2016/17)?`,
      ...newFormTranslations[FinancialAuditQuestionName.CapitalGainExcess],
    },
    [FinancialAuditQuestionName.CapitalGainExcessPartner]: {
      question: `Did your partner realise a capital gain in excess of the annual exempt amount in any
        of the immediately preceding three tax years (£11,700 2018/19; £11,300 2017/18; £11,100 2016/17)?`,
      ...newFormTranslations[FinancialAuditQuestionName.CapitalGainExcess],
    },
    [FinancialAuditQuestionName.ContributeToPension]: {
      question: `How much in total are you and (if relevant) your employer(s) likely to contribute to your pension in this tax year?`,
      ...newFormTranslations[FinancialAuditQuestionName.ContributeToPension],
    },
    [FinancialAuditQuestionName.ContributeToPensionPartner]: {
      question: `How much in total are your partner’s and (if relevant) his/her employer(s) likely to contribute to your pension in this tax year?`,
      ...newFormTranslations[FinancialAuditQuestionName.ContributeToPension],
    },
    [FinancialAuditQuestionName.PensionBenefits]: {
      question: `When are you planning on taking benefits from your pensions?`,
      ...newFormTranslations[FinancialAuditQuestionName.PensionBenefits],
    },
    [FinancialAuditQuestionName.PensionBenefitsPartner]: {
      question: `When is your partner planning on taking benefits from their pensions?`,
      ...newFormTranslations[FinancialAuditQuestionName.PensionBenefits],
    },
    [FinancialAuditQuestionName.MadeGifts]: {
      question: ` Do you typically make gifts of more than £3,000 in a tax year?`,
      ...newFormTranslations[FinancialAuditQuestionName.MadeGifts],
    },
    [FinancialAuditQuestionName.MadeGiftsPartner]: {
      question: ` Does your partner typically make gifts of more than £3,000 in a tax year?`,
      ...newFormTranslations[FinancialAuditQuestionName.MadeGifts],
    },
    [FinancialAuditQuestionName.BiggerAssets]: {
      question: `Do you individually own assets, in total, worth more than £325,000 PLUS the value (up to £125,000) of your interest in your home?`,
      ...newFormTranslations[FinancialAuditQuestionName.BiggerAssets],
    },
    [FinancialAuditQuestionName.BiggerAssetsPartner]: {
      question: `Does your partner individually own assets, in total, worth more than £325,000 PLUS the value (up to £125,000) of your partner’s interest in your partner’s home?`,
      ...newFormTranslations[FinancialAuditQuestionName.BiggerAssets],
    },
    [FinancialAuditQuestionName.Inheritances]: {
      question: `Have you received any inheritances within the last two years?`,
      ...newFormTranslations[FinancialAuditQuestionName.Inheritances],
    },
    [FinancialAuditQuestionName.InheritancesPartner]: {
      question: `Has your partner received any inheritances within the last two years?`,
      ...newFormTranslations[FinancialAuditQuestionName.Inheritances],
    },
  },
  [FinancialAuditVersion.Old]: {
    stepBegin: {
      description0: `Paying unnecessary tax diminishes your wealth. However, there are many simple,
        permissible and effective ways to help reduce your tax bill.`,
      description1: `Answer the following questions to help us to identify tax-saving and financial
        planning opportunities that may be relevant to you. `,
      description2: `Please answer every question by ticking the relevant box(es).`,
      description3: `The levels and bases of taxation and reliefs from taxation can change at any
        time. The value of any tax relief depends on individual circumstances.`,
      description4: `Throughout this Audit a spouse, registered civil partner and long term partner
      will be referred to as a ‘partner’.`,
    },
    [FinancialAuditQuestionName.TaxableIncome]: {
      question: `Which band does your taxable income for this tax year fall into?`,
      definitions: `
          <p>
            <strong>Taxable Income:</strong> You pay tax on various income types. Common taxable
            income types include salaries, wages, profits from self-employment, pension income
            (incl. state pensions, company pensions, personal pensions and retirement annuities),
            some state benefits, rental income, and income from a trust.
          </p>
          <p>
            <strong>Tax year:</strong>  This tax year begins on 6th April 2018 and ends on
            5th April 2019.
          </p>
        `,
      A: {
        text: `£0 – £11,850`,
        description: `
          <p>
            Your Client’s income is within  the personal allowance and is therefore
            tax free. To the extent that there is any allowance left unused and subject to your
            Client’s overall financial objectives, you should consider generating earned or investment
            income to fall within your Client’s available personal allowance as it will be tax
            free.
          <p>`,
      },
      B: {
        text: `£11,851 – £46,350 (£43,430 in Scotland)`,
        description: `
          <p>
            Your Client’s taxable income for 2018/19 is above their Personal Allowance,
            £11,850, but below £46,350 (£43,430 in Scotland).
          </p>
          <strong>Action</strong>
          <p>
            Your Client is a basic rate taxpayer so, subject to personal and commercial needs,
            action to minimise tax loss  at 20% (19%  – 21%  in Scotland) will be worth considering.
            In practice, possible action to reduce taxation  will depend substantially on their source
            of any employment income, the investments they own or could own, aligned to their
            financial objectives/attitude to, appetite and capacity for risk. Subject to the
            appropriate limits ISAs and Pensions will deliver tax efficiency as will VCT and EIS
            investments but with greater risk. Unit trusts, OEICs and in some cases, investment
            bonds can also deliver elements of tax efficiency and deferment but with no “front end”
            tax relief.
          </p>
          <strong>In addition (if relevant)</strong>
          <b>All Business owners (regardless of the type of business) could consider:</b>
          <ul>
            <li>
              - To the extent that immediate spendable income is not needed and capacity exists,
              making pension contributions.
            </li>
          </ul>
          <b>Corporate business owners could consider:</b>
          <ul>
            <li>
              - Taking loan repayments instead of salary/dividends to the extent that a director’s
              loan exists.
            </li>
            <li>
              - For amounts withdrawn from the business in excess of the primary earnings threshold
              (£8,424 in 2018/19), drawing dividends as opposed to salary to reduce the net cost
              of taking funds from the business as dividends are not subject to National Insurance.
            </li>
            <li>
              - Subject to spendable income requirements and pension contribution capacity, salary
              sacrifice and “matching” employer pension contributions.
            </li>
          </ul>
        `,
      },
      C: {
        text: `£46,351 (£43,431 in Scotland) – £100,000`,
        description: `
          <p>
            Your Client’s taxable income before deducting their personal allowance of
            £11,850 (which is assumed to be available in full) is above £46,350 but does not exceed
            £100,000.
          </p>
          <strong>Action</strong>
          <p>
            Your Client is a higher rate taxpayer so, subject to personal expenditure requirements,
            action to minimise tax loss at 40% (41% in Scotland) may be worth considering. Possible
            action will depend substantially on their source of employment income and/or the
            investments they own or could own, aligned to their financial objectives/ attitude to and
            appetite for risk. Subject to the appropriate limits, ISAs and Pensions will deliver tax
            efficiency as will VCT and EIS investments but with greater risk. Unit trusts, OEICs and
            in some cases, investment bonds can also deliver elements of tax efficiency and deferment
            but with no “front end” tax relief.
          </p>
          <strong>In addition, if relevant, Business owners could consider:</strong>
          <b>All Business Owners (regardless of the type of business) could consider:</b>
          <ul>
            <li>
              - To the extent that immediate spendable income is not needed, making pension
              contributions.
            </li>
          </ul>
          <b>Corporate business owners could consider:</b>
          <ul>
            <li>
              - Deferring income to a time of anticipated lower taxation (e.g. in retirement).
            </li>
            <li>
              - Taking loan repayments instead of salary/dividends to the extent that a director’s
              loan exists.
            </li>
            <li>
              - Forr amounts withdrawn from the business in excess of the primary earnings threshold
              (£8,424 in 2018/19), drawing dividends as opposed to salary to reduce the net cost of
              taking funds from the business as dividends are not subject to National Insurance.
            </li>
            <li>
              - Subject to spendable income requirements and pension contribution capacity, salary
              sacrifice and “matching” employer pension contributions.
            </li>
          </ul>
          `,
      },
      D: {
        text: `£100,001 – £150,000`,
        description: `
          <p>Your Client’s taxable income is between £100,001 and £150,000.</p>
          <p>
            Their personal allowance will be reduced by £1 for every £2 of income that exceeds
            £100,000. This means that income in the band £100,000 – £123,700 bears an effective rate
            of income tax of up to 60% (61.5% in Scotland).
          </p>
          <strong>Action</strong>
          <p>
            Your Client is a higher rate taxpayer so, subject to personal expenditure requirements,
            action to minimise tax loss may be worth considering. Possible action will depend
            substantially on their source of employment income and/ or the investments they own or
            could own aligned to their financial objectives/attitude to and appetite for risk. Subject
            to the appropriate limits, ISAs and Pensions will deliver tax efficiency as will VCT
            and EIS investments but with greater risk. Unit trusts, OEICs and in some cases,
            investment bonds can also deliver elements of tax efficiency and deferment but with no
            “front end” tax relief.
          </p>
          <strong>In addition (if relevant)</strong>
          <b>All Business owners (regardless of the business type) could consider:</b>
          <ul>
            <li>
              - To the extent that immediate spendable income is not needed, making pension
              contributions.
            </li>
          </ul>
          <b>Corporate business owners could consider:</b>
          <ul>
            <li>
              - Deferring income to a time of anticipated lower taxation (e.g. in retirement).
            </li>
            <li>
              - Taking loan repayments instead of salary/dividends to the extent that a
              director’s loan exists.
            </li>
            <li>
              - For amounts withdrawn from the business in excess of the primary earnings threshold
              (£8,424 in 2018/19), taking dividends as opposed to salary to reduce the net cost of
              taking funds from the business as dividends are not subject to National Insurance.
            </li>
            <li>
              -Subject to spendable income requirements and pension contribution capacity and the
              relevant anti-avoidance provisions salary sacrifice and “matching” employer pension
              contribution.
            </li>
          </ul>
          <strong>
            And, in addition to the personal income tax planning possibilities outlined above
            there may also be retirement planning considerations as the client earns in excess of
            £100,000
          </strong>
          <p>
            Your Client may well be impacted by the tapered annual allowance which could reduce
            the level of tax-efficient pension savings they can make this year.
          </p>
          <p>
            An assessment of threshold income and, if needed, adjusted income should be conducted
            to establish if the tapered annual allowance applies and, if so, how much allowance is
            available. This can then be used to establish if there is any scope for further pension
            contributions.
          </p>
          <p>
            In addition, it may be possible to restore the full annual allowance by making
            additional personal contributions to a pension scheme or rearranging the structure of
            existing contributions.
          </p>
        `,
      },
      E: {
        text: `£150,001+`,
        description: `
        <p>Your Client’s taxable income for the tax year is over £150,000.</p>
        <p>
          Every £1 of taxable income above  £150,000 will be taxed at the additional rate of 45%
          (46% in Scotland).  Tax saving for these clients will be very worthwhile.
        </p>
        <strong>Action:</strong>
        <p>
          Your Client is an additional rate taxpayer so, subject to personal expenditure
          requirements, action to minimise tax loss at 45% (46% in Scotland) may be worth
          considering. Possible action will depend substantially on their source of employment
          income and/or the investments they own or could own aligned to their financial objectives/
          attitude to and appetite for risk. Subject to the appropriate limits, ISAs and Pensions will
          deliver tax efficiency as will VCT and EIS investments but with greater risk. Unit trusts,
          OEICs and in some cases, investment bonds can also deliver elements of tax efficiency and
          deferment but without “front end” tax relief.
        </p>
        <strong>In addition (if relevant)</strong>
        <b>All Business owners (regardless of the business type) could consider:</b>
        <ul>
          <li>
            - To the extent that immediate spendable income is not needed, making pension
            contributions.
          </li>
        </ul>
        <b>Corporate business owners could consider:</b>
        <ul>
          <li>
            - Deferring income to a time of anticipated lower taxation.
          </li>
          <li>
            - Taking loan repayments instead of salary/dividends to the extent that a director’s
            loan exists.
          </li>
          <li>
            - For amounts withdrawn from the business in excess of the primary earnings threshold
            (£8,424 in 2018/19), taking dividends as opposed to salary to reduce the net cost of
            taking funds from the business as dividends are not subject to National Insurance.
          </li>
          <li>
            - Subject to spendable income requirements, pension contribution capacity and the
            relevant anti-avoidance provisions salary sacrifice and “matching” employer pension
            contribution
          </li>
          <strong>
            And, in addition to the personal income tax planning possibilities outlined
            above there may also be retirement planning considerations as the client earns in excess
            of £150,000
          </strong>
          <p>
            Your Client may well be impacted by the tapered annual allowance which could reduce
            the level of tax-efficient pension savings they can make this year.
          </p>
          <p>
            An assessment of threshold income and adjusted income should be conducted to establish
            if the tapered annual allowance applies and, if so, how much allowance is available. This
            can then be used to establish if there is any scope for more contributions.
          </p>
        </ul>`,
      },
    },
    [FinancialAuditQuestionName.TaxableIncomePartner]: {
      question: `If relevant, which band does the taxable income of your partner
        fall into for the current tax year?`,
      definitions: `
        <p>
          <strong>Taxable Income:</strong> You pay tax on various income types. Common taxable
          income types include salaries, wages, profits from self-employment, pension income
          (incl. state pensions, company pensions, personal pensions and retirement annuities),
          some state benefits, rental income, and income from a trust.
        </p>
        <p>
          <strong>Tax year:</strong>  This tax year begins on 6th April 2018 and ends on
          5th April 2019.
        </p>
      `,
      A: {
        text: `£0 – £11,850`,
        description: `
          <p>
            Your Client’s Partners income is within the  the personal allowance
            and is therefore tax free. To the extent that there is any allowance left unused and
            subject to your client’s partners overall financial objectives, you should consider
            generating earned or investment income to fall within your client’s partners available
            personal allowance as it will be tax free.
          </p>
          <p>
            If one of either the Client or their Partner has a higher income than the other
            then the Partner with the lower income may be taxed at a lower rate than the Partner
            with the higher income. In this case investors should consider ensuring that, subject
            to non-tax considerations, tax is minimised. This is especially important where the
            tax payable by one of a couple is significantly higher than that of the other.</p>
            <p>In particular ,consideration could  be given to the transfer of income-producing
            investments to the lower tax paying Partner e.g. bank accounts, unit trusts, insurance
            bonds. This will be possible with no negative immediate IHT or CGT consequences if the
            transfer is made between a married couple or between registered civil partners. If a
            transfer is to be made to a long-term partner who is not a spouse or civil partner,
            then the IHT and CGT consequences would need to be considered as the spouse exemption
            for IHT would not apply nor would the CGT no gain/no loss basis apply.
          </p>
          <p>Where relevant, the transferable marriage allowance could be considered.</p>
          <b>Business owners should consider (as appropriate):</b>
          <ul>
            <li>
              - Employing a lower taxed Partner
            </li>
            <li>
              - If the Partner is already employed, increasing their current salary –
              provided that any increased payment can be justified by the work carried out
            </li>
            <li>
              - If the business is a Partnership, making the lower taxed Partner a business
              partner in the Partnership
            </li>
            <li>
              - If the business is a limited company, giving or issuing fully participating
              shares to the lower taxed Partner and declaring dividends. Any transfer of
              shares to other than a spouse or registered civil partner would need to take
              account of potential CGT/IHT as the spouse exemption would not apply (although
              IHT business relief may be available).
            </li>
          </ul>
        `,
      },
      B: {
        text: `£11,851 – £46,350 (£43,430 in Scotland)`,
        description: `
          <p>
            Your Client’s Partners taxable income for 2018/19 is above their Personal Allowance,
            £11,850, but below £46,350 (£43,430 in Scotland).
          </p>
          <strong>Action</strong>
          <p>
            Your Client’s Partner is a basic rate taxpayer so, subject to personal and commercial
            needs, action to minimise tax loss at 20% (19% – 21% in Scotland) will be worth
            considering. In practice, possible action to reduce taxation will depend substantially
            on their source of any employment income, the investments they own or could own,
            aligned to their financial objectives/attitude to, appetite and capacity for risk. Subject
            to the appropriate limits ISAs and Pensions will deliver tax efficiency as will VCT and
            EIS investments but with greater risk. Unit trusts, OEICs and in some cases, investment
            bonds can also deliver elements of tax efficiency and deferment but with no “front end”
            tax relief.
          </p>
          <strong>In addition (if relevant)</strong>
          <b>All Business owners (regardless of the type of business) could consider:</b>
          <ul>
            <li>
              - To the extent that immediate spendable income is not needed and capacity exists,
              making pension contributions.
            </li>
          </ul>
          <b>Corporate business owners could consider:</b>
          <ul>
            <li>
              Taking loan repayments instead of salary/dividends to the extent that a director’s
              loan exists.
            </li>
            <li>
              For amounts withdrawn from the business in excess of the primary earnings threshold
              (£8,424 in 2018/19), drawing dividends as opposed to salary to reduce the net cost of
              taking funds from the business as dividends are not subject to National Insurance.
            </li>
            <li>
              Subject to spendable income requirements and pension contribution capacity, salary
              sacrifice and “matching” employer pension contributions.
            </li>
            <p>
              If one of either the Client or their Partner has a higher income than the other then the
              Partner with the lower income may be taxed at a lower rate than the Partner with the
              higher income. In this case investors should considerensuring that, subject to non-tax
              considerations, tax is minimised. This is especially important where the tax payable by
              one of a couple is significantly higher than that of the other.
            </p>
            <p>
              In particular ,consideration could be given to the transfer of income-producing
              investments to the lower tax paying Partner e.g. bank accounts, unit trusts, insurance
              bonds. This will be possible with no negative immediate IHT or CGT consequences if the
              transfer is made between a married couple or between registered civil partners. If a
              transfer is to be made to a long-term partner who is not a spouse or civil partner,
              then the IHT and CGT consequences would need to be considered as the spouse exemption
              for IHT would not apply nor would the CGT no gain/no loss basis apply.
            </p>
            <p>Where relevant, the transferable marriage allowance could be considered.</p>
            <b>Business owners should consider (as appropriate):</b>
            <ul>
              <li>
                - Employing a lower taxed Partner
              </li>
              <li>
                - If the Partner is already employed, increasing their current salary – provided that
                any increased payment can be justified by the work carried out
              </li>
              <li>
                - If the business is a Partnership, making the lower taxed Partner a business partner
                in the Partnership
              </li>
              <li>
                - If the business is a limited company, giving or issuing fully participating shares
                to the lower taxed Partner and declaring dividends. Any transfer of shares to other
                than a spouse or registered civil partner would need to take account of potential
                CGT/IHT as the spouse exemption would not apply (although IHT business relief may be
                available).
              </li>
            </ul>
          </ul>
        `,
      },
      C: {
        text: `£46,351 (£43,431 in Scotland) – £100,000`,
        description: `
          <p>
            Your Client’s Partner’s taxable income before deducting their personal allowance of
            £11,850 (which is assumed to be available in full) is above £46,350 but does not
            exceed £100,000.
          </p>
          <strong>Action:</strong>
          <p>
            Your Client’s Partner is a higher rate taxpayer so, subject to personal expenditure
            requirements, action to minimise tax loss at 40% (41% in Scotland) may be worth
            considering. Possible action will depend substantially on their source of employment
            income and/or the investments they own or could own, aligned to their financial
            objectives/attitude to and appetite for  risk. Subject to the appropriate limits,
            ISAs and Pensions will deliver  tax efficiency as will VCT and EIS investments but
            with greater risk. Unit trusts, OEICs and in some cases, investment bonds can also
            deliver elements of tax efficiency and deferment but with no “front end” tax relief.
          </p>
          <strong>In addition, if relevant, Business owners could consider:</strong>
          <b>All Business owners (regardless of the type of business) could consider:</b>
          <ul>
            <li>
              - To the extent that immediate spendable income is not needed, making pension
              contributions.
            </li>
          </ul>
          <b>Corporate business owners could consider:</b>
          <ul>
            <li>
              - Deferring income to a time of anticipated lower taxation (e.g. in retirement).
            </li>
            <li>
              - Taking loan repayments instead of salary/dividends to the extent that a director’s
              loan exists.
            </li>
            <li>
              - For amounts withdrawn from the business in excess of the primary earnings threshold
              (£8,424 in 2018/19), drawing dividends as opposed to salary to reduce the net cost of
              taking funds from the business as dividends are not subject to National Insurance.
            </li>
            <li>
              - Subject to spendable income requirements and pension contribution capacity, salary
              sacrifice and “matching” employer pension contributions.
            </li>
          </ul>
          <p>
            If one of either the Client or their Partner has a higher income than the other then the
            Partner with the  lower income may be taxed at a lower rate than the Partner with the
            higher income. In this case investors should consider ensuring that, subject to non-tax
            considerations, tax is minimised. This is especially important where the tax payable by
            one of a couple is significantly higher than that of the other.
          </p>
          <p>
            In particular, consideration could be given to the transfer of income-producing
            investments to the lower tax paying Partner e.g. bank accounts, unit trusts, insurance
            bonds. This will be possible with no negative immediate IHT or CGT consequences if the
            transfer is made between a married couple or between registered civil partners. If a
            transfer is to be made to a long-term partner who is not a spouse or civil partner,
            then the IHT and CGT consequences would need to be considered as the spouse exemption for
            IHT would not apply nor would the CGT no gain/no loss basis apply.
          </p>
          <p>Where relevant, the transferable marriage allowance could be considered.</p>
          <b>Business owners should consider (as appropriate):</b>
          <ul>
            <li>
              - Employing a lower taxed Partner
            </li>
            <li>
              - If the Partner is already employed, increasing their current salary – provided that
              any increased payment can be justified by the work carried out
            </li>
            <li>
              - If the business is a Partnership, making the lower taxed Partner a business partner in
              the Partnership
            </li>
            <li>
              - If the business is a limited company, giving or issuing fully participating shares to
              the lower taxed Partner and declaring dividends. Any transfer of shares to other than a
              spouse or registered civil partner would need to take account of potential CGT/IHT as
              the spouse exemption would not apply (although IHT business relief may be available).
            </li>
          <ul>
        `,
      },
      D: {
        text: `£100,001 – £150,000`,
        description: `
          <p>
            Your Client’s Partners taxable income is between £100,001 and £150,000.
          </p>
          <p>
            Their personal allowance will be reduced by £1 for every £2 of income that exceeds
            £100,000. This means that income in the band £100,000 – £123,700 bears an effective rate
            of income tax of up to 60% (61.5% in Scotland).
          </p>
          <strong>Action:</strong>
          <p>
            Your Client’s Partner is a higher rate taxpayer so, subject to personal expenditure
            requirements, action to minimise tax loss may be worth considering. Possible action will
            depend substantially on their source of employment income and/or the investments they own
            or could own aligned to their financial objectives/attitude to and appetite for risk.
            Subject to the appropriate limits, ISAs and Pensions will deliver tax efficiency as will
            VCT and EIS investments but with greater risk. Unit trusts, OEICs and in some cases,
            investment bonds can also deliver elements of tax efficiency and deferment but with no
            “front end” tax relief.
          </p>
          <strong>In addition (if relevant)</strong>
          <b>All Business owners (regardless of the business type) could consider:</b>
          <ul>
            <li>
              - To the extent that immediate spendable income is not needed, making pension
              contributions.
            </li>
          </ul>
          <b>Corporate business owners could consider:</b>
          <ul>
            <li>
              - Deferring income to a time of anticipated lower taxation (e.g. in retirement).
            </li>
            <li>
              - Taking loan repayments instead of salary/dividends to the extent that a
              director’s loan exists.
            </li>
            <li>
              - For amounts withdrawn from the business in excess of the primary earnings
              threshold (£8,424 in 2018/19), taking dividends as opposed to salary to reduce the
              net cost of taking funds from the business as dividends are not subject to National
              Insurance.
            </li>
            <li>
              - Subject to spendable income requirements and pension contribution capacity and the
              relevant anti-avoidance provisions salary sacrifice and “matching” employer pension
              contribution.
            </li>
          </ul>
          <strong>
            And, in addition to the personal income tax planning possibilities outlined above there
            may also be retirement planning considerations as the client earns in excess of £100,000
          </strong>
          <p>
            Your Client may well be impacted by the tapered annual allowance which could reduce the
            level of tax-efficient pension savings they can make this year.
          </p>
          <p>
            An assessment of threshold income and, if needed, adjusted income should be conducted to
            establish if the tapered annual allowance applies and, if so, how much allowance is
            available. This can then be used to establish if there is any scope for further pension
            contributions.
          </p>
          <p>
            In addition, it may be possible to restore the full annual allowance by making additional
            personal contributions to a pension scheme or rearranging the structure of existing
            contributions.
          </p>
          <p>
            If one of either the Client or their Partner has a higher income than the other then the
            Partner with the lower income may be taxed at a lower rate than the Partner with the
            higher income. In this case investors should consider ensuring that, subject to non-tax
            considerations, tax is minimised. This is especially important where the tax payable by
            one of a couple is significantly higher than that of the other.
          </p>
          <p>
            In particular consideration should be given to the transfer of income-producing
            investments to the lower tax paying Partner e.g. bank accounts, unit trusts, insurance
            bonds. This will be possible with no negative immediate IHT or CGT consequences if the
            transfer is made between a married couple or between registered civil partners. If a
            transfer is to be made to a long-term partner who is not a spouse or civil partner,
            then the IHT and CGT consequences would need to be considered as the spouse exemption for
            IHT would not apply nor would the CGT no gain/no loss basis apply.
          </p>
          <p>Where relevant, the transferable marriage allowance could be considered.</p>
          <b>Business owners should consider (as appropriate):</b>
          <ul>
            <li>
              - Employing a lower taxed Partner
            </li>
            <li>
              - If the Partner is already employed, increasing their current salary – provided that
              any increased payment can be justified by the work carried out
            </li>
            <li>
              - If the business is a Partnership, making the lower taxed Partner a business partner in
              the Partnership
            </li>
            <li>
              - If the business is a limited company, giving or issuing fully participating shares to
              the lower taxed Partner and declaring dividends. Any transfer of shares to other than a
              spouse or registered civil partner would need to take account of potential CGT/IHT as
              the spouse exemption would not apply (although IHT business relief may be available).
            </li>
          </ul>
        `,
      },
      E: {
        text: `£150,001+`,
        description: `
          <p>Your Client’s Partner’s taxable income for the tax year is over £150,000.</p>
          <p>
            Every £1 of taxable income above  £150,000 will be taxed at the additional rate of 45%
            (46% in Scotland).  Tax saving for these Clients will be very worthwhile.
          </p>
          <strong>Action:</strong>
          <p>
            Your Client’s Partner is a higher rate taxpayer so, subject to personal expenditure
            requirements, action to minimise tax loss may be worth considering. Possible action will
            depend substantially on their source of employment income and/or the investments they
            own or could own aligned to their financial objectives/attitude to and appetite for risk.
            Subject to the appropriate limits, ISAs and Pensions will deliver tax efficiency as will
            VCT and EIS investments but with greater risk. Unit trusts, OEICs and in some cases,
            investment bonds can also deliver elements of tax efficiency and deferment but with no
            “front end” tax relief.
          </p>
          <strong>In addition (if relevant)</strong>
          <b>All Business owners (regardless of the business type) could consider:</b>
          <ul>
            <li>
              - To the extent that immediate spendable income is not needed, making pension
              contributions.
            </li>
          </ul>
          <b>Corporate business owners could consider:</b>
          <ul>
            <li>
              - Deferring income to a time of anticipated lower taxation.
            </li>
            <li>
              - Taking loan repayments instead of salary/dividends to the extent that a
              director’s loan exists.
            </li>
            <li>
              - For amounts withdrawn from the business in excess of the primary earnings
              threshold (£8,424 in 2018/19), taking dividends as opposed to salary to reduce
              the net cost of taking funds from the business as dividends are not subject to
              National Insurance.
            </li>
            <li>
              - Subject to spendable income requirements, pension contribution capacity and
              the relevant anti-avoidance provisions salary sacrifice and “matching” employer
              pension contribution.
            </li>
          </ul>
          <strong>
            And, in addition to the personal income tax planning possibilities outlined above
            there may also be retirement planning considerations as the client earns in excess
            of £150,000
          </strong>
          <p>
            Your Client may well be impacted by the tapered annual allowance which could reduce
            the level of tax-efficient pension savings they can make this year.
          </p>
          <p>
            An assessment of threshold income and adjusted income should be conducted to establish
            if the tapered annual allowance applies and, if so, how much allowance is available.
            This can then be used to establish if there is any scope for more contributions.
          </p>
          <p>
            In addition, it might be possible to restore the full annual allowance by making
            additional personal contributions to a pension scheme or rearranging the structure
            of existing contributions.
          </p>
          <p>
            If one of either the Client or their Partner has a higher income than the other then
            the Partner with the lower income may be taxed at a lower rate than the Partner with
            the higher income. In this case investors should consider ensuring that, subject to
            non-tax considerations, tax is minimised. This is especially important where the tax
            payable by one of a couple is significantly higher than that of the other.
          </p>
          <p>
            In particular consideration should be given to the transfer of income-producing
            investments to the lower tax paying Partner e.g. bank accounts, unit trusts, insurance
            bonds. This will be possible with no negative immediate IHT or CGT consequences if the
            transfer is made between a married couple or between registered civil partners. If a
            transfer is to be made to a long-term partner who is not a spouse or civil partner,
            then the IHT and CGT consequences would need to be considered as the spouse exemption
            for IHT would not apply nor would the CGT no gain/no loss basis apply.
          </p>
          <p>Where relevant, the transferable marriage allowance could be considered.</p>
          <b>Business owners should consider (as appropriate):</b>
          <ul>
            <li>
              - Employing a lower taxed Partner
            </li>
            <li>
              - If the Partner is already employed, increasing their current salary – provided
              that any increased payment can be justified by the work carried out
            </li>
            <li>
              - If the business is a Partnership, making the lower taxed Partner a business partner
              in the Partnership
            </li>
            <li>
              - If the business is a limited company, giving or issuing fully participating shares
              to the lower taxed Partner and declaring dividends. Any transfer of shares to other
              than a spouse or registered civil partner would need to take account of potential
              CGT/IHT as the spouse exemption would not apply (although IHT business relief may be
              available).
            </li>
          </ul>
        `,
      },
      F: {
        text: `Not relevant`,
        description: `<p>No action required</p>`,
      },
    },
    [FinancialAuditQuestionName.IsaInvested]: {
      question: `Do you (and/or, if relevant, your partner) invest less than the maximum
        (£20,000 per tax year) into ISAs?`,
      definitions: `
        <p>
          <strong>Individual Savings Account (ISA):</strong> An ISA provides a valuable,
          tax-efficient wrapper for cash and investments. The total ISA allowance for
          the 2018/19 tax year is £20,000. Individuals can invest into Cash ISAs or
          Stocks and Shares ISAs in any combination, up to the total combined annual
          allowance.
        </p>
        <p>
          The value of an ISA will be directly linked to the performance of the funds selected and
          may fall as well as rise. You may get back less than you invested.
        </p>
        <p>
          An investment in a Stocks and Shares ISA will not provide the same security of
          capital associated with a Cash ISA.
        </p>
        <p>
          The favourable tax treatment of ISAs may not be maintained in the future and
          is subject to changes in legislation.
      `,
      A: {
        text: `Yes`,
        description: `
          <p>
            Your Client and/or (if relevant) their Partner have not made (nor have planned to make
            arrangements for) the maximum contributions to their ISAs – £20,000.
          </p>
          <p>
            As ISAs deliver tax free interest and/or dividend income (and capital gains) it is an
            extremely tax effective investment wrapper for their deposits and investments.
          </p>
          <strong>Action:</strong>
          <p>
            Ensure, subject to economic and investment considerations, that your client and if
            relevant, their spouse/civil partner maximises the amount of their investments/deposits
            held inside ISAs. If they make regular contributions ensure that the regular
            contribution amount is increased to ensure contributions reach the current £20,000 maximum
            total for the tax year.
          </p>
        `,
      },
      B: {
        text: `No`,
        description: `
          <p>No further ISA investment is possible.</p>
          <strong>Action:</strong>
          <p>
            If further tax efficient investment is desired then consideration, subject to overall
            investment considerations and attitude to risk, could be given to pension, VCT and EIS
            investment and appropriate unit trusts and single premium bonds.
          </p>
        `,
      },
    },
    [FinancialAuditQuestionName.ReceiveInInterest]: {
      question: `Will you (and/or, if relevant, your partner) receive less than £1,000
        (or £500 if a higher rate taxpayer) in interest in the current tax year?`,
      definitions: `
        <p>
          <strong>Higher rate taxpayer:</strong> If you live in England, Wales or Northern Ireland
          and you have taxable income of more than £46,351, you are a higher rate taxpayer. If
          you live in Scotland you are a higher rate taxpayer if you have taxable income of more
          than £43,431.
        </p>
        <p>
          <strong>Tax year:</strong> This tax year begins on 6th April 2018 and ends on
          5th April 2019.
        </p>
      `,
      A: {
        text: `Yes`,
        description: `
          <p>
            Your Client and/or (if relevant) their Partner receive less than £1,000
            per tax year (£500 for higher rate taxpayers) in interest.
          </p>
          <strong>Action:</strong>
          <p>
            Consider (subject to economic and investment considerations and other
            available cash) investing to produce interest that will fall within their
            appropriate “Personal Savings Allowance” of £1,000 (basic rate taxpayers) or
            £500 (higher rate taxpayers). Note that distributions from unit trusts, OEICs
            and offshore funds that are invested more than 60% in fixed interest securities
            are treated as interest for tax purposes as are chargeable gains on offshore bonds.
          </p>
          <p>
            The Personal Savings Allowance applies to all Savings Income i.e. interest, the
            interest element of annuity payments and gains under investment bonds.
          </p>
          <p>
            In addition to the Personal Savings Allowance an individual can have up to a further
            £5,000 of savings income taxed at 0%, but this tax-free amount is reduced by £1  for
            every £1  of non-savings, non-dividend income  (e.g. earnings, pensions income) that
            the Client receives in the tax year above their personal allowance.
          </p>
          `,
      },
      B: {
        text: `No`,
        description: `
          <p>
            Your Client (and if relevant their Partner) is fully using their Personal savings
            allowance.
          </p>
          <strong>Action:</strong>
          <p>
            Subject to their overall investment objectives the client and if relevant their Partner
            could consider investing so as to maximise their ISA contributions and or to produce
            tax free dividends and tax effective gains through unit trusts, OEICs and possible tax
            deferment through investment bonds.
          </p>
        `,
      },
    },
    [FinancialAuditQuestionName.ReceiveInDividends]: {
      question: `Are you (and/or, if relevant, your partner) likely to receive less than
        £2,000 in dividends in the current tax year?`,
      definitions: `
      <p>
        <strong>Dividends:</strong> You may get a dividend payment if you own shares in a company.
      </p>
      <p>
        <strong>Tax year:</strong> This tax year begins on 6th April 2018 and ends on 5th April 2019.
      </p>
      `,
      A: {
        text: `Yes`,
        description: `
          <p>
            Your Client and/or (if relevant) their Partner receive less than £2,000 tax free dividend
            allowance per tax year in dividends.
          </p>
          <strong>Action:</strong>
          <p>
            Consider investing in dividend paying unit trusts or OEICs (regardless of whether the
            income produced is taken or re-invested/accumulated). Where the dividends produced are
            within the investor’s annual £2,000 dividend allowance they will be tax free.
          </p>
        `,
      },
      B: {
        text: `No`,
        description: `
          <p>
            Your Client and/or (if relevant) their Partner receives more than the £2,000 per tax year
            in dividends. The excess over £2,000 is taxable at their appropriate marginal rate.
          </p>
          <strong>Action:</strong>
          <b>Consider:</b>
          <ul>
            <li>
              - Maximising ISA investments (£20,000 per tax year).
            </li>
            <li>
              - Investing in non-income producing assets (growth unit trusts/UK or offshore
                insurance bonds).
            </li>
            <li>
              - Supplementing required income with capital (withdrawals/capital gains).
            </li>
            <li>
              - Subject to attitude to/appetite for risk, investing in VCT investments to
              produce tax free dividends
            </li>
          </ul>
          <p>
            In making all or any of the above investment decisions it will, of course, be necessary
            to take full account of all factors including the relative importance of capital growth,
            the Client’s overall investment portfolio and attitude to risk and capacity for loss.
          </p>
        `,
      },
    },
    [FinancialAuditQuestionName.ExpectCapitalGains]: {
      question: `Who of the following expects to realise capital gains of  £11,700 or less in the
        current tax year?`,
      definitions: `
        <p>
          <strong>Capital gain:</strong> A capital gain is a profit earned when you sell, or
          ‘dispose of’, an asset that has increased in value.
        </p>
        <p>
          <strong>Tax year:</strong> This tax year begins on 6th April 2018 and ends on 5th
          April 2019.
        </p>
      `,
      A: {
        text: `You`,
        description: `
          <p>
            Your Client expects their realised capital gains are unlikely to exceed £11,700 in
            the tax year.
          </p>
          <p>
            The annual CGT exemption of £11,700 is available every year on a “use it or lose
            it/non-carry forward” basis. For a basic rate taxpayer, the tax saving from its use is
            £1,170 on most capital gains and for a higher or additional rate taxpayer it is £2,340.
          </p>
          <strong>Action:</strong>
          <p>
            Consider looking to trigger gains of up to the annual CGT exemption to “re-set” base
            values within allowable rules i.e. no reacquisition of the same investments as those
            disposed of within 30 days of the disposal. Disposal and reacquisition by your client’s
            ISA or pension or, if relevant, spouse or civil partner might offer an allowable
            opportunity to use the annual exemption whilst retaining some form of ownership of the
            investment.
          </p>
        `,
      },
      B: {
        text: `Your partner (if relevant)`,
        description: `
          <p>
            The realised capital gains of your Client’s Partner  are unlikely to exceed £11,700 in
            the tax year. The annual CGT exemption of £11,700 is available for each family member.
            If not used in a tax year it cannot be carried forward.
          </p>
          <strong>Action:</strong>
          <p>
            Consider looking to trigger gains e.g. from unit trust disposals up to the annual CGT
            exemption to “re-set” base value within allowable rules i.e. no reacquisition of the
            same investments as those disposed of within 30 days  of the disposal. Disposal and
            reacquisition by your clients ISA or pension or, if relevant, Partner might offer an
            allowable opportunity to use the annual exemption whilst retaining some form of
            ownership of the investment.
          </p>
          <b>Plus:</b>
          <p>
            Transfers between spouses/civil partners living together can be made on a tax neutral
            no gain/no loss basis, so, where relevant:
          </p>
          <ul>
            <li>
              - Consider transfer of assets with gains that would be taxed if realised by one
              spouse/civil partner to a spouse/civil partner with an available annual CGT exemption.
            </li>
            <li>
              - Consider transfer of assets with losses from a spouse/civil partner with no gains
              to one with gains – care anti-avoidance.
            </li>
          </ul>
        `,
      },
      C: {
        text: `Your children (if relevant)`,
        description: `
          <p>
            The capital gains of the Client’s children are unlikely to exceed their annual CGT
            exemption.
          </p>
          <p>
            The annual CGT exemption of £11,700 is available for each family member. If not used
            in a tax year it cannot be carried forward.
          </p>
          <strong>Action:</strong>
          <p>
            Parents/grandparents  to consider transferring cash/investments to children/ grandchildren
            or into a bare trust/ nomineeship (an investment, such as a unit trust, held by a parent
            or grandparent as nominee for the child) for them so that capital gains made on
            investments would be assessed on the child. Of course, the legal implications of a bare
            trust/nomineeship would need to be considered before this planning was executed. It may
            also be worth considering investing in a Junior ISA for the child.
          </p>
          <p>
            The transfer would be chargeable for CGT (if not of cash) and would be a potentially
            exempt/exempt transfer for IHT. The former only if the transfer is made outright or into a
            bare trust/nomineeship and the latter dependant on the amount transferred. If the transfer
            is not outright or into a Bare Trust it is likely to be a chargeable transfer for IHT.
            Whether IHT is payable will depend on the availability of the exemptions and the amount of
            the transfer. Any capital gain arising as a result of a chargeable transfer can be held
            over (deferred).
          </p>
        `,
      },
      D: {
        text: `None of the above`,
        description: `<p>No action required as all gains will be tax free</p>`,
      },
    },
    [FinancialAuditQuestionName.CapitalGainExcess]: {
      question: `Have you (and/or, if relevant, your partner) realised a capital gain in excess of
        the annual exempt amount in the current tax year (£11,700) or any of the immediately
        preceding three tax years (2017/18 - £11,300; 2016/17 - £11,100; 2015/16 - £11,100)?`,
      definitions: `
        <p>
          <strong>Capital gain:</strong> A capital gain is a profit earned when you sell, or
          ‘dispose of‘, an asset that has increased in value.
        </p>
        <p>
          <strong>Annual Exempt Amount:</strong> Eligible individuals have an annual tax-free
          allowance for Capital Gains Tax known as the ‘Annual Exempt Amount’. The Annual
          Exempt Amount for the current tax year is £11,700.
        </p>
        <p>
          <strong>Tax year:</strong> This tax year begins on 6th April 2018 and ends on
          5th April 2019.
        </p>
        <p>
          The levels and bases of taxation, and reliefs from taxation, can change at any time.
          The value of any tax relief depends on individual circumstances.
        </p>
      `,
      A: {
        text: `Yes`,
        description: `
          <strong>Action:</strong>
          <p>
            Subject to the Client’s attitude to and appetite for risk, consideration could be given to
            investing in an appropriate EIS to defer the capital gains to the extent of the lower of
            excess capital gain and the amount invested.
          </p>
        `,
      },
      B: {
        text: `No`,
        description: `<p>No action is necessary.</p>`,
      },
    },
    [FinancialAuditQuestionName.ContributeToPension]: {
      question: `How much in total are you and (if relevant) your employer(s) likely to contribute
        to your pension (before any tax relief) in this tax year?`,
      A: {
        text: `Not more than £40,000`,
        description: `
          <p>
            Your Client and their employer are likely to contribute less than £40,000 (in total) to
            your Client’s pension this tax year.
          </p>
          <p>
            Assuming that neither the money purchase annual allowance nor tapering apply, then up to
            £40,000 pa can be contributed (in total) to a pension arrangement each year. In addition,
            once the annual allowance for the year has been fully used a member of a registered
            pension scheme is entitled to utilise previously unused annual allowance for each of the
            immediately preceding three tax years. This additional contribution representing carried
            forward relief can be made by the employer and/or employee.
          </p>
          <strong>Action:</strong>
          <p>
            An assessment of unused relief should be carried out taking full account of the permitted
            maximum for each of the three preceding tax years and the amounts actually contributed to
            registered pensions for or on behalf of the member in each of those years.
          </p>
        `,
      },
      B: {
        text: `More than £40,000`,
        description: `
          <p>
            Your Client and their employer are likely to contribute more than £40,000 (in total) to
            your Client’s pension this tax year.
          </p>
          <p>
            Once the annual allowance for the year has been fully used a member of a registered
            pension scheme is entitled to utilise previously unused annual allowance for each of the
            immediately preceding three tax years. This additional contribution representing carried
            forward relief can be made by the employer and/or employee.
          </p>
          <strong>Action:</strong>
          <p>
            An assessment of unused relief should be carried out taking full account of the permitted
            maximum for each of the three preceding tax years and the amounts actually contributed to
            registered pensions for or on behalf of the member in each of those years.
          </p>
          <p>
            With further investment in pensions prohibited, subject to attitude to and appetite for
            risk, consideration could be given to all or some (as appropriate) of ISA investment, VCT,
            EIS, unit trusts, OEICs and investment bonds.
          </p>
        `,
      },
    },
    [FinancialAuditQuestionName.ContributeToPensionPartner]: {
      question: `If relevant, how much in total is your partner and (if relevant) his/her
        employer(s) likely to contribute to your pension (before any tax relief) in this tax year?`,
      A: {
        text: `Not more than £40,000`,
        description: `
          <p>
            Your Client and their employer are likely to contribute less than £40,000 (in total) to
            your Client’s pension this tax year.
          </p>
          <p>
            Assuming that neither the money purchase annual allowance nor tapering apply, then up to
            £40,000 pa can be contributed (in total) to a pension arrangement each year. In addition,
            once the annual allowance for the year has been fully used a member of a registered
            pension scheme is entitled to utilise previously unused annual allowance for each of the
            immediately preceding three tax years. This additional contribution representing carried
            forward relief can be made by the employer and/or employee.
          </p>
          <strong>Action:</strong>
          <p>
            An assessment of unused relief should be carried out taking full account of the permitted
            maximum for each of the three preceding tax years and the amounts actually contributed to
            registered pensions for or on behalf of the member in each of those years.
          </p>
        `,
      },
      B: {
        text: `More than £40,000`,
        description: `
          <p>
            Your Client and their employer are likely to contribute more than £40,000 (in total) to
            your Client’s pension this tax year.
          </p>
          <p>
            Once the annual allowance for the year has been fully used a member of a registered
            pension scheme is entitled to utilise previously unused annual allowance for each of the
            immediately preceding three tax years. This additional contribution representing carried
            forward relief can be made by the employer and/or employee.
          </p>
          <strong>Action:</strong>
          <p>
            An assessment of unused relief should be carried out taking full account of the permitted
            maximum for each of the three preceding tax years and the amounts actually contributed to
            registered pensions for or on behalf of the member in each of those years.
          </p>
          <p>
            With further investment in pensions prohibited, subject to attitude to and appetite for
            risk, consideration could be given to all or some (as appropriate) of ISA investment, VCT,
            EIS, unit trusts, OEICs and investment bonds.
          </p>
        `,
      },
      C: {
        text: `Not relevant`,
        description: `<p>No action required</p>`,
      },
    },
    [FinancialAuditQuestionName.PensionBenefits]: {
      question: `When are you planning on taking benefits from your pensions?`,
      A: {
        text: `Within the next 12 months`,
        description: `
          <p>Your Client is planning on taking benefits from their pension in the next 12 months.</p>
          <p>
            By accessing benefits ‘flexibly’ they may trigger the money purchase annual allowance
            (MPAA) which will significantly restrict their ability to pay pension contributions.
          </p>
          <strong>Action:</strong>
          <p>
            A review of how and when they need to access benefits should be conducted to establish if
            it is possible and/or necessary to meet their needs without triggering the MPAA.
          </p>
          <p>
            Death benefit options in the current schemes should also be reviewed to ensure that they
            meet your client’s requirements once benefits start to be drawn.
          </p>
        `,
      },
      B: {
        text: `Within the next five years`,
        description: `
          <p>
            Your Client is planning on taking benefits from their pension within the next 5 years.
          </p>
          <p>
            Old-style pension schemes may now not be suitable for taking benefits from. Transfers at
            or near retirement can be stressful and may delay payment of benefits, so reviewing well
            in advance is key.
          </p>
          <strong>Action:</strong>
          <p>
            Review pension benefits with a plan to possibly consolidate and transfer to a pension
            under which the type of retirement benefits required are available.
          </p>
          <p>
            Death benefit options in the current schemes should also be reviewed to ensure that they
            meet your Client’s requirements once benefits start to be drawn.
          </p>
        `,
      },
      C: {
        text: `More than five years from now`,
        description: `
          <p>No benefit withdrawals are planned within the next five years.</p>
          <strong>Action:</strong>
          <p>
            Keep the position under review so that appropriate planning can be carried out in
            good time.
          </p>
        `,
      },
    },
    [FinancialAuditQuestionName.PensionBenefitsPartner]: {
      question: `If relevant, when is your partner planning on taking benefits from your pensions?`,
      A: {
        text: `Within the next 12 months`,
        description: `
          <p>Your Client is planning on taking benefits from their pension in the next 12 months.</p>
          <p>
            By accessing benefits ‘flexibly’ they may trigger the money purchase annual allowance
            (MPAA) which will significantly restrict their ability to pay pension contributions.
          </p>
          <strong>Action:</strong>
          <p>
            A review of how and when they need to access benefits should be conducted to establish if
            it is possible and/or necessary to meet their needs without triggering the MPAA.
          </p>
          <p>
            Death benefit options in the current schemes should also be reviewed to ensure that they
            meet your client’s requirements once benefits start to be drawn.
          </p>
        `,
      },
      B: {
        text: `Within the next five years`,
        description: `
          <p>
            Your Client is planning on taking benefits from their pension within the next 5 years.
          </p>
          <p>
            Old-style pension schemes may now not be suitable for taking benefits from. Transfers at
            or near retirement can be stressful and may delay payment of benefits, so reviewing well
            in advance is key.
          </p>
          <strong>Action:</strong>
          <p>
            Review pension benefits with a plan to possibly consolidate and transfer to a pension
            under which the type of retirement benefits required are available.
          </p>
          <p>
            Death benefit options in the current schemes should also be reviewed to ensure that they
            meet your Client’s requirements once benefits start to be drawn.
          </p>
        `,
      },
      C: {
        text: `More than five years from now`,
        description: `
          <p>No benefit withdrawals are planned within the next five years.</p>
          <strong>Action:</strong>
          <p>
            Keep the position under review so that appropriate planning can be carried out in
            good time.
          </p>
        `,
      },
      D: {
        text: `Not relevant`,
        description: `<p>No action required</p>`,
      },
    },
    [FinancialAuditQuestionName.BiggerAssets]: {
      question: `Do you (and/or, if relevant, your partner) individually own assets worth more than
        £325,000 (£450,000 including up to £125,000 of the value of your share in your home)?`,
      definitions: `
        <p>
          <strong>Assets:</strong> Valuable assets often include cash, investments, vehicles, boats,
          property, business interests, jewellery and artwork.
        </p>
      `,
      A: {
        text: `Yes`,
        description: `
          <p>
            Your Client’s estate is worth more than £325,000 (£450,000 including the value of
            their share in their residence).
          </p>
          <p>
            As well as gifts between spouses/registered civil partners ( but not otherwise) being
            exempt without limit, each person is allowed to leave up to £325,000 to anyone other than
            their spouse/civil partner free of IHT (taxed at a nil rate). To the extent any of the
            £325,000 nil rate band is not used on death it can be “inherited”. (i.e. transferred to) a
            surviving spouse/registered civil partner and, subject to a claim made within two years,
            used on the death of that surviving spouse/registered civil partner. The same is largely
            true (but with important limitations on who can inherit and how within this allowance) of
            the residence nil rate band of £125,000 for the 2018/19 tax year – rising to £175,000 by
            2020/21, but subject to 50% taper for estates above £2m. This “residence nil rate band”
            is in addition to the £325,000 “any asset” nil rate band explained immediately above.
            To the extent that the taxable estate of an individual passing to other than a surviving
            spouse, registered civil partner or charity exceeds the available nil rate band(s)
            available to them IHT will be charged at 40%.
          </p>
          <strong>Action:</strong>
          <p>
            An IHT/estate planning review could be worthwhile to ascertain what, if any, planning and
            provision may be possible.
          </p>
          <p>
            This is especially so because the £325,000 nil rate band will remain frozen until the end
            of 2020/21.
          </p>
          <p>
            Careful consideration should also be given to the main residence nil rate band and its use
            in planning and the fact that the residence nil rate band is reduced by £1 for every £2
            that the deceased’s estate exceeds £2m.
          </p>
        `,
      },
      B: {
        text: `No`,
        description: `
          <p>
            On the face of it there is no potential liability to inheritance tax based on  current
            value. The value of the estate and potential liability to inheritance tax should be kept
            regularly under review.
          </p>
        `,
      },
    },
    [FinancialAuditQuestionName.MadeGifts]: {
      question: ` Have you (and/or, if relevant, your partner) made gifts of less than £3,000 in this
        or the previous tax year?`,
      definitions: `
        <p>
          <strong>Gift:</strong> A gift can be anything that has a value, such as money, property,
          and possessions. Also, if for example, you sell a property to your child for less than it
          is worth, the difference in value counts as a gift.
        </p>
        <p>
          <strong>Tax year:</strong> This tax year begins on 6th April 2018 and ends on
          5th April 2019.
        </p>
      `,
      A: {
        text: `Yes`,
        description: `
          <p>
            The annual exemption of £3,000 has not been fully used. To the extent that the £3,000
            exemption was not use in the immediately preceding tax year a gift of up to that amount
            would also be exempt.
          </p>
          <strong>Action:</strong>
          <p>
            Your Client could consider (subject to their own personal financial needs) making exempt
            gifts up to the amount of the available annual exemption (including any unused amount from
            the previous tax year) to donees other than their spouse/registered civil partner.
          </p>
          <p>
            It is also possible to make unlimited gifts as normal expenditure out of income. These are
            gifts made out of income regularly and without affecting the donor’s standard of living.
            The payment of regular contributions to a life insurance policy held in trust for those
            who will inherit your estate on death (or the estate of the survivor of you in the case of
            a couple where the first to die leaves assets to the survivor) would normally qualify as
            being exempt. The sum assured under such a policy would not usually be treated as part of
            your Client’s taxable estate and would be payable free of inheritance tax to the
            beneficiaries who could then use the money to pay the IHT arising on the death of the life
            or lives assured. It is reiterated that for a couple, only transfers between spouses and
            registered civil partners will be exempt.
          </p>
        `,
      },
      B: {
        text: `No`,
        description: `
          <p>
            No action is necessary but keep the use of the exemption under review and also consider the
            use of the normal expenditure exemption. These are gifts made out of income regularly and
            without affecting the donor’s standard of living. The payment of regular contributions to
            a life insurance policy held in trust for those who will inherit your estate on death
            (or the estate of the survivor of you in the case of a couple leaving assets to each other
            on the death of the first of them to die) would normally qualify as being exempt. The
            sum assured under such a policy would not usually be treated as part of your Client’s
            taxable estate and would be payable free of inheritance tax to the beneficiaries who
            could then use the money to pay the IHT arising on the death of the life or lives assured.
          </p>
        `,
      },
    },
    [FinancialAuditQuestionName.MadeWill]: {
      question: `Within the last three years have you (and/or, if relevant, your partner) made a
        Will or reviewed your existing one?`,
      A: {
        text: `Yes`,
        description: `
          <strong>Action:</strong>
          <p>
            Your Client should keep their will under regular review and consider the efficient
            administration of an estate using the services of Kings Court Trust.
          </p>
        `,
      },
      B: {
        text: `No`,
        description: `
          <p>
            Your Client (and/or their Partner ) does not have a Will or their Will has not been
            reviewed recently.
          </p>
          <strong>Action:</strong>
          <p>
            Ensure your Client seeks and take advice to create a valid Will or reviews their existing
            Will to ensure it reflects current intentions and is tax efficient.
          </p>
          <p>
            They may also want to consider making arrangements for the efficient administration of
            their estate using the services of Kings Court Trust.
          </p>
        `,
      },
    },
    [FinancialAuditQuestionName.Inheritances]: {
      question: `Have you (and/or, if relevant, your partner) had any inheritances within the
        last two years?`,
      definitions: `
        <p>
          <strong>Inheritances:</strong> An inheritance is a possession, a property, a right
          or an obligation that is passed to you after the death of an individual.
        </p>
        <p>
          <strong>Tax year:</strong> This tax year begins on 6th April 2018 and ends on
          5th April 2019.
        </p>
      `,
      A: {
        text: `Yes`,
        description: `
          <strong>Action:</strong>
          <p>
            Opportunities may exist for effective inheritance tax planning through the use of a deed
            of variation. Depending on the circumstances it may be possible to plan to maximise use
            of the residence nil rate band in this way and/or avoid consolidation of assets in the
            estate of a surviving spouse or civil partner while retaining access for them.
          </p>
        `,
      },
      B: {
        text: `No`,
        description: `<p>No action is necessary.</p>`,
      },
    },
    [FinancialAuditQuestionName.CertainAfterDeath]: {
      question: `Are you certain that on your death (and/or, if relevant, your partner’s death) your
        surviving beneficiaries and/or financial dependants would have sufficient capital/income to
        enjoy the life that you would wish for them?`,
      definitions: `
        <p>
          <strong>Beneficiary:</strong> A beneficiary is an individual, or an entity, that you
          designate to receive your assets when you die. Beneficiaries often include your spouse,
          children, family, other loved ones, and trusts.
        </p>
        <p>
          <strong>Financial dependants:</strong> A financial dependant is any person who relies
          on you financially for their primary source of income. Financial dependants might
          include children, elderly parents, relatives, spouses or friends.
        </p>
      `,
      A: {
        text: `Yes`,
        description: `
          <strong>Action:</strong>
          <p>Keep these requirements and provision for them under regular review.</p>
        `,
      },
      B: {
        text: `No`,
        description: `
          <p>
            Your Client is not certain that their  family/dependants will be sufficiently financially
            provided for on their death.
          </p>
          <strong>Action:</strong>
          <p>
            Life assurance protection held in an appropriate trust would be worth serious
            consideration.
          </p>
        `,
      },
    },
    [FinancialAuditQuestionName.LooseMentalCapacity]: {
      question: `Have you (and/or, if relevant, your partner) made provision for your affairs to
        be looked after should you lose the mental capacity to do so yourself?`,
      definitions: `
        <p>
          <strong>Mental capacity:</strong> Mental capacity refers to the ability for an
          individual to to make their own decisions.
        </p>
      `,
      A: {
        text: `Yes`,
        description: `
          <p>
            It would seem that your Client has established a power of attorney. This should be kept
            under review.
          </p>
        `,
      },
      B: {
        text: `No`,
        description: `<p>Your Client should consider establishing lasting powers of attorney</p>`,
      },
    },
  },
};
