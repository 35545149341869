export default {
  title: `{entity} Interaction Reports`,
  titleShort: `Interaction Reports`,
  pageDescription: `Here you can generate and download {entity} interaction reports regarding {regarding}`,
  directorPageDescription: `Here you can view and download {entity} interaction reports regarding {regarding}`,
  idvDescription: `Here you can generate and download {entity} interaction reports`,
  generate: `Generate Interaction Report`,
  generate_summary: `Generate Summary Interaction Report`,
  remove: {
    title: `Remove Report`,
    confirmation: `Are you sure you want to remove selected Interaction Report?`,
  },
  notifications: {
    created: `Interaction Report generation has been scheduled successfully. Generated file should be available to download soon.`,
    removed: `Interaction Report has been removed successfully.`,
  },
  fields: {
    eventCount: `Events registered in the version`,
  },
  features: {
    summary: 'Summary',
    clientMeetingRoom: 'Client Meeting Room',
    documentPack: 'Document Pack',
    financialStrategy: 'Financial Strategy',
    questionnaire: 'Questionnaire',
    recommendationPack: 'Recommendation Pack',
    messages: 'Messages',
    identityVerification: 'Identity Check',
  },
  summary: {
    title: `Summary Interaction Reports`,
    generateDescription: `Generate a summary report that provides an overview of the major interactions between this Practice and {clientName} during a date range that you can specify below.`,
  },
};
