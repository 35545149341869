import React from 'react';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import { position, rem, size } from 'polished';
import styled, { css } from 'styled-components';

import { APP_ELEMENT_ID } from 'constants/layout';
import { breakpoints } from 'helpers/styles/constants';
import { flexCenter } from 'helpers/styles/mixins';

import { ModalButtons } from './components/ModalButtons';
import ModalContent from './components/ModalContent';
import ModalFooter from './components/ModalFooter';
import ModalForm from './components/ModalForm';
import ModalTitle from './components/ModalTitle';
import { modalPadding, modalPaddingSm } from './components/shared';
import ModalContext from './GenericModalContext';

const Modal = styled(ReactModal)`
  ${flexCenter()};
  ${size('100%')};
  padding: ${rem(10)};
  outline: none;
  pointer-events: none;

  @media ${breakpoints.smUp} {
    padding: ${rem(16)};
  }
`;

interface ModalInnerProps {
  isFullScreen?: boolean;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  innerWidth?: string;
  responsive?: boolean;
}

const ModalInner = styled.div<ModalInnerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  max-width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  pointer-events: auto;

  ${({ isFullScreen }) =>
    isFullScreen &&
    css`
      width: 100%;
    `};

  ${({ minWidth }) =>
    minWidth &&
    css`
      @media ${breakpoints.smUp} {
        min-width: ${minWidth};
      }
    `};

  ${({ responsive }) =>
    responsive &&
    css`
      width: 100%;
      @media ${breakpoints.smUp} {
        max-width: 70vw;
      }
      @media ${breakpoints.mdUp} {
        max-width: 60vw;
      }
      @media ${breakpoints.lgUp} {
        max-width: 45vw;
      }
    `};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
  ${({ innerWidth }) => innerWidth && `width: ${innerWidth}`};
  ${({ height }) => height && `height: ${height}`};
`;

const ModalCloseButton = styled.button.attrs({ type: 'button', 'data-test': 'basic-modal-close' })`
  ${size(rem(18))};
  position: absolute;
  top: ${rem(modalPaddingSm / 2)};
  right: ${rem(modalPaddingSm / 2)};
  z-index: 1;
  padding: 0;

  &::before,
  &::after {
    ${position('absolute', rem(-3), null, null, rem(8))};
    ${size(rem(22), rem(2))};
    content: '';
    background-color: ${({ theme }) => theme.colors.bermudaGray};
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  @media ${breakpoints.smUp} {
    top: ${rem(modalPadding / 2)};
    right: ${rem(modalPadding / 2)};
  }
`;

const contextValue = { isInsideModal: true };

interface Props extends Omit<ReactModalProps, 'isOpen'> {
  children?: React.ReactNode | React.ReactNode[];
  isCloseButtonHidden?: boolean;
  onClose?: () => void;
  isOpen?: boolean;
  width?: string;
  isFullScreen?: boolean;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  responsive?: boolean;
  'data-test'?: string;
}

const BasicModal = ({
  isCloseButtonHidden,
  children,
  onClose,
  isFullScreen,
  minWidth,
  maxWidth,
  height,
  width,
  responsive = false,
  'data-test': dataTest,
  isOpen = false,
  shouldCloseOnOverlayClick = true,
  ...restProps
}: Props) => {
  const appRootElement = document.getElementById(APP_ELEMENT_ID);

  return (
    <Modal
      overlayClassName="modal__overlay"
      {...restProps}
      onRequestClose={onClose}
      shouldCloseOnEsc={!isCloseButtonHidden && shouldCloseOnOverlayClick}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      isOpen={isOpen}
      ariaHideApp={Boolean(appRootElement)}
      appElement={appRootElement || document.body}
    >
      <ModalContext.Provider value={contextValue}>
        <ModalInner
          data-test={dataTest}
          isFullScreen={isFullScreen}
          minWidth={minWidth}
          maxWidth={maxWidth}
          innerWidth={width}
          height={height}
          responsive={responsive}
        >
          {!isCloseButtonHidden && <ModalCloseButton onClick={onClose} />}
          {children}
        </ModalInner>
      </ModalContext.Provider>
    </Modal>
  );
};

BasicModal.Title = ModalTitle;
BasicModal.Content = ModalContent;
BasicModal.Form = ModalForm;
BasicModal.Footer = ModalFooter;
BasicModal.ModalButtons = ModalButtons;

export default BasicModal;
