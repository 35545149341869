import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

export interface AdviserUpdatePreferencesPatchOptions {
  pendingSignaturesReminderFrequency: number;
  currentPassword: string;
}

export function adviserUpdatePreferencesApi(path: string) {
  return {
    path,
    PATCH: (params: AdviserUpdatePreferencesPatchOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
