import { Root, route } from '../utils';

const brochuresPath = `${Root}library`;

type BrochureParams = [brochureId: number];

export default {
  brochure: route<BrochureParams>(`${brochuresPath}/:itemId`),
  brochures: route(brochuresPath),
};
