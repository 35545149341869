import React from 'react';

import SvgIcon from '../SvgIcon';

const IconScreenSharing = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 18 16" {...props}>
    <path
      d="M16.05.01H2.29C1.53.01.91.66.91 1.45V11.5c0 .8.62 1.44 1.38 1.44h4.82c-.02.58-.12 1.5-.52 1.93a.66.66 0 0 1-.52.23.35.35 0 0 0-.34.36c0 .2.15.36.34.36h6.2c.18 0 .34-.16.34-.36 0-.2-.16-.36-.35-.36a.66.66 0 0 1-.52-.23c-.39-.43-.5-1.35-.5-1.93h4.8c.77 0 1.38-.64 1.38-1.44V1.45c0-.8-.61-1.44-1.37-1.44zM9.17 12.24a.7.7 0 0 1-.7-.73c0-.4.32-.72.7-.72a.7.7 0 0 1 .69.72.7.7 0 0 1-.7.73zm-6.88-2.16V1.45h13.76v8.63H2.29z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default IconScreenSharing;
