import { UserRoleName } from 'types/entities/user';

export default {
  title: `Account settings`,
  summary: `Here you can change your settings.`,
  basicInformationTitle: `Basic account information`,
  personalDetails: `Personal details`,
  changePasswordTitle: `Change password`,
  automaticEmailsSettingsTitle: `Manage email automation`,
  cmrVirtualBackground: `Client Meeting Room Background`,
  twoFactorAuthentication: {
    title: `Two-factor authentication`,
    on: `Two-factor authentication is enabled. When you attempt to sign in, you will be emailed a one-time passcode. This passcode will be required to complete your sign in.`,
    off: `Two-factor authentication is disabled.`,
    onOther: `Two-factor authentication is enabled for {userName}. When they attempt to sign in, they will be emailed a one-time passcode. This passcode will be required to complete their sign in.`,
    offOther: `Two-factor authentication is disabled for {userName}.`,
    employeeWarning: `Warning! There are security implication to changing two-factor authentication settings. Are you sure that you wish to proceed?`,
    clientWarning: `Warning! There are security implication to changing your client's two-factor authentication settings. Are you sure that you wish to proceed?`,
  },
  integrateDocuSign: {
    button: 'AUTHENTICATE',
    signout: 'Sign out',
    signoutTitle: 'Sign out of DocuSign',
    signoutSuccess: 'Signed out of DocuSign!',
    signoutWarning: `By continuing, you will break the link between your DWS account and this DocuSign account. This will likely result in previously-sent documents no longer being accessible by you, your team and/or clients in DWS.<p>Are you sure you wish to continue?</p>`,
    authenticateMessage: 'Authenticate with DocuSign',
    reAuthenticateMessage: 'DocuSign Re-authentication required',
    reAuthenticateButton: 'RE-AUTHENTICATE',
    message: `DocuSign Authenticated`,
    selectDocusignAccount: 'Select a DocuSign account',
    saveChanges: 'Save changes',
    cancel: 'Cancel',
    multipleAccount: 'Multiple DocuSign account exists, please select one',
    selectAccount: 'Select Account',
  },
  integrateOutlook: {
    button: 'AUTHENTICATE',
    signout: 'Sign out',
    signoutTitle: 'Sign out of Outlook',
    signoutSuccess: 'Signed out of Outlook!',
    signoutWarning: `By continuing, you will break the link between your DWS account and this Outlook account. <p>Are you sure you wish to continue?</p>`,
    authenticateMessage: 'Authenticate with Outlook',
    reAuthenticateMessage: 'Outlook Re-authentication required',
    reAuthenticateButton: 'RE-AUTHENTICATE',
    message: `Outlook Authenticated`,
    saveChanges: 'Save changes',
    cancel: 'Cancel',
  },
  automaticEmails: {
    clientsDocReminder: {
      title: `Pending documents reminders`,
      description: `Remind {userRole, select,
        ${UserRoleName.Adviser} {your}
        other {Adviser’s}
      } clients via email whenever there are documents or addenda that require signature or other update. They will receive a summary of items that require attention.`,
      placeholder: `Emails frequency in days`,
      off: `To disable the reminders entirely, save a zero in this field`,
    },
  },
  signatureTitle: `My signature`,
  setSignatureTitle: `Set your personal signature`,
  profileVisibilityTitle: `Profile visibility`,
  profileVisibilityDescription: `Make this profile visible on the landing page`,
  currentPasswordTitle: `Save account changes`,
  currentPasswordLabel: `Enter your current password to save`,
  passwordLabel: `new password`,
  passwordConfirmationLabel: `confirm new password`,
  optionalAppFeatures: `Optional application features`,
  saveButton: `Save changes`,
  editAdviser: `Edit Adviser`,
  editPracticeAdmin: `Edit Practice Admin`,
  details: `Contact details`,
  detailsDescription: `The contact information below is visible to your clients.
    All fields are required, except for your phone number.`,
  detailsDescription2: `Your biography will also be published on this website’s
    landing pages, and, hence, will be viewable by the public. Please ensure that
    the biography is appropriate and has been approved.`,
  biography: `Biography`,
  contactMethod: `Contact method`,
  preferredContactMethodTitle: `Your preferred contact method`,
  signaturePermission: {
    title: `Letter of Authority`,
    client: {
      description: `To assess the ongoing suitability of a policy, we shall contact your provider to
      request information on the policy. Providers typically request your signature before providing
      such information. Please provide this by signing your signature in the box below.`,
      permission: `I, {clientFullName}, authorise {practiceName} to use and store my electronic
      signature to generate letters of authority, and to request information on my policies from the provider(s).`,
      partnerPermission: `I, {partnerFullName}, authorise {practiceName} to use and store my electronic
      signature to generate letters of authority, and to request information on my policies from the provider(s).`,
      permissionSettings: `Allow my signature to be used to generate letters of authority`,
      signatureNeeded: `Add your signature to enable this option`,
      permissionGranted: `Permission to use your signature granted.`,
      permissionNotGranted: `Permission to use your signature not granted. See account settings.`,
      partnerPermissionGranted: `Permission to use your partner’s signature granted.`,
      partnerPermissionNotGranted: `Permission to use your partner’s signature not granted. See account settings.`,
    },
    adviser: {
      permissionGranted: `Permission to use client’s signature for LOA granted.`,
      permissionNotGranted: `Permission to use client’s signature for LOA not granted.`,
      partnerPermissionGranted: `Permission to use client’s partner’s signature for LOA granted.`,
      partnerPermissionNotGranted: `Permission to use client’s partner’s signature for LOA not granted.`,
    },
  },
  notification: {
    savedChanges: `All changes have been saved`,
    avatarUpdate: `Avatar has been updated`,
    avatarRemove: `Avatar has been removed`,
    savingError: `Something went wrong, profile has not been saved`,
    fileError: `An error occurred while processing the file.`,
  },
  avatar: {
    uploadNewPhoto: `Upload new photo`,
    removeExistingPhoto: `or remove existing photo`,
    removeModalTitle: `Remove existing photo`,
    removeModalWarning: `Are you sure that you wish to remove existing photo?`,
  },
  changeEmail: {
    unconfirmedEmailValidation: `You cannot enter the same email address for which an email change request is already placed.`,
    resendConfirmation: `Confirmation email has been resent.`,
    sent: `All changes saved and confirmation email sent.`,
    warning: `Confirmation email will be sent, you must confirm it by clicking the link from the email. Changes will be visible afterwards.`,
    otherUserWarning: `Confirmation email will be sent to the user, whom must confirm it by clicking the link from the email. Changes will be visible afterwards.`,
    changed: `Confirmation email has been sent to <b>{email}</b>. Please verify it for changes to reflect. <button>Resend Email</button>`,
    unconfirmed: `The email address was recently changed to <b>{email}</b>. Please be aware that any email change will not be visible until the {role} has confirmed the change. <button>Resend Email</button>`,
  },
  // TODO: reuse it for PD changing Adviser/PA email in SOV-2444
  changeClientEmail: {
    title: `Confirmation email will be send.`,
    confirmationSent: `Confirmation email has been sent.`,
    text: `Confirmation email will be sent to the user, who must confirm it by clicking the link from the email. Changes will be visible afterwards.`,
  },
  gdpr: {
    title: `Personal data`,
    description: `Here you can request transferable copy of your data. It will be sent to you by email.`,
    requestSuccess: `Request was successful.`,
  },
};
