import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import type { Room } from 'twilio-video';

import { adviceRooms, guests } from 'api';
import type {
  RecordingState,
  UpdateRecordingStateParams,
} from 'api/adviceRooms/adviceRoom/recordings/updateRecordingState';
import { getAdviceRoomSyncStream, MessageData, TwilioStreamMessage } from 'api/twilio/twilioSync';
import {
  ADVICE_ROOM_RECORDING_STATE_UPDATE_STARTED,
  ADVICE_ROOM_RECORDING_STATE_UPDATED,
  MESSAGE_PUBLISHED,
} from 'constants/twilio';
import { showApiErrorToast } from 'helpers/toasts';
import { useGetGuestAuthToken } from 'store/guests/selectors';
import {
  useGetIsCurrentUserAnAdviser,
  useGetIsCurrentUserAPracticeAdmin,
} from 'store/users/selectors';
import {
  AdviceRoomRecordingStateSyncMessage,
  AdviceRoomRecordingStateUpdateStartedMessage,
  sendAdviceRoomRecordingStateUpdatedMessage,
  sendAdviceRoomRecordingUpdateStartedMessage,
} from 'store/videoChat/actions';
import type { AdviceRoom } from 'types/entities/adviceRoom';

import {
  ContextValue as RecordingContextValue,
  RecordingContext,
  RecordingType,
} from './RecordingContext';

type RecordingStateUpdatedMessage = TwilioStreamMessage<
  MessageData<
    AdviceRoomRecordingStateSyncMessage['type'],
    AdviceRoomRecordingStateSyncMessage['payload']
  >
>;

type RecordingStateUpdateStartedMessage = TwilioStreamMessage<
  MessageData<
    AdviceRoomRecordingStateUpdateStartedMessage['type'],
    AdviceRoomRecordingStateUpdateStartedMessage['payload']
  >
>;
interface Props {
  children: JSX.Element;
  adviceRoom: AdviceRoom;
  guest?: boolean;
  room?: Room;
}
const RecordingProvider = ({ children, adviceRoom, room, guest }: Props) => {
  const dispatch = useDispatch();

  const isCurrentUserAPracticeAdmin = useGetIsCurrentUserAPracticeAdmin();
  const isCurrentUserAnAdviser = useGetIsCurrentUserAnAdviser();
  const [recordingType, setRecordingType] = useState<RecordingType>();
  const [loading, setLoading] = useState<boolean>(false);
  const guestAuthToken = useGetGuestAuthToken();

  const toggleLoading = useCallback(
    (isLoading: boolean) => {
      setLoading(isLoading);
      dispatch(
        sendAdviceRoomRecordingUpdateStartedMessage(adviceRoom.id, {
          isLoading,
        })
      );
    },
    [adviceRoom.id, dispatch]
  );

  const updateRecordingState = useCallback(
    async (params: UpdateRecordingStateParams) => {
      if (!(isCurrentUserAPracticeAdmin || isCurrentUserAnAdviser))
        throw new Error('Permission not granted');

      toggleLoading(true);

      try {
        const response = await adviceRooms(adviceRoom.id)
          .recordings()
          .updateRecordingState()
          .PATCH(params);

        if (response.data.recording && response.data.type) {
          setRecordingType(response.data.type);
        }

        dispatch(
          sendAdviceRoomRecordingStateUpdatedMessage(adviceRoom.id, {
            recordingType: response.data.type,
            recording: response.data.recording,
          })
        );

        return params.type;
      } catch (error) {
        showApiErrorToast(error);

        toggleLoading(false);
        return recordingType;
      }
    },
    [
      adviceRoom.id,
      dispatch,
      isCurrentUserAPracticeAdmin,
      isCurrentUserAnAdviser,
      recordingType,
      toggleLoading,
    ]
  );

  useEffect(() => {
    const syncRecordingState = async () => {
      setLoading(true);

      let response;
      try {
        if (guest && guestAuthToken) {
          response = await guests()
            .clientMeetingRooms()
            .currentRecordingState()
            .GET({ authToken: guestAuthToken });
        } else if (!guest) {
          response = await adviceRooms(adviceRoom.id).currentRecordingState().GET();
        } else {
          setLoading(false);
          return;
        }
        const recordingState = response.data as unknown as RecordingState;

        if (recordingState.recording && recordingState.type) {
          setRecordingType(recordingState.type);
        }
      } finally {
        setLoading(false);
      }
    };

    room?.on('participantDisconnected', syncRecordingState);
    if (room) syncRecordingState();
  }, [adviceRoom.id, room, guest, guestAuthToken]);

  useEffect(() => {
    const handleRecordingStateUpdate = ({
      message,
      isLocal,
    }: RecordingStateUpdatedMessage | RecordingStateUpdateStartedMessage) => {
      if (message.data.type === ADVICE_ROOM_RECORDING_STATE_UPDATED) {
        if (isLocal) {
          toggleLoading(false);
          return;
        }

        setRecordingType(message.data.payload.recordingType || recordingType);
      }

      if (isLocal) return;

      if (
        (isCurrentUserAPracticeAdmin || isCurrentUserAnAdviser) &&
        message.data.type === ADVICE_ROOM_RECORDING_STATE_UPDATE_STARTED
      ) {
        setLoading(message.data.payload.isLoading);
      }
    };

    getAdviceRoomSyncStream(adviceRoom.id).then(stream =>
      stream.addListener(MESSAGE_PUBLISHED, handleRecordingStateUpdate)
    );

    return () => {
      getAdviceRoomSyncStream(adviceRoom.id).then(stream =>
        stream.removeListener(MESSAGE_PUBLISHED, handleRecordingStateUpdate)
      );
    };
  }, [
    adviceRoom.id,
    isCurrentUserAPracticeAdmin,
    isCurrentUserAnAdviser,
    recordingType,
    toggleLoading,
  ]);

  const recordingContextValue = useMemo<RecordingContextValue>(() => {
    return {
      recordingType,
      updateRecordingState,
      loading,
    };
  }, [loading, recordingType, updateRecordingState]);

  return (
    <RecordingContext.Provider value={recordingContextValue}>{children}</RecordingContext.Provider>
  );
};

export default RecordingProvider;
