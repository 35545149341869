import React, { useEffect, useRef } from 'react';
import { rem, size } from 'polished';
import styled from 'styled-components';
import type { LocalVideoTrack } from 'twilio-video';

import { createVideoTrack } from 'api/twilio/twilioVideo';
import { breakpoints } from 'helpers/styles/constants';
import { circle, flexCenter } from 'helpers/styles/mixins';
import { showErrorToast } from 'helpers/toasts';
import IconExclamationMark from 'components/Icons/IconExclamationMark';
import { useCameraPermissions } from 'components/TestSetupTwilio/components/useDevicesPermissions';
import { DevicePermissions } from 'components/TestSetupTwilio/types';

const Wrapper = styled.div`
  position: relative;

  video {
    display: block;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: 100%;
    transform: scaleX(-1);
  }
`;

const NoPreview = styled.div`
  ${flexCenter()}
  flex-flow: column;
  max-width: 100%;
  max-height: 100%;
  min-height: 220px;
  font-size: 17px;
  color: white;
  background: grey;

  @media ${breakpoints.mdUp} {
    width: 640px;
    height: 324px;
    font-size: 20px;
  }
`;

const IconWrapper = styled.div`
  ${circle(rem(40))};
  ${flexCenter()};
  background-color: ${({ theme }) => {
    return theme.colors.badgeErrorBg;
  }};

  @media ${breakpoints.mdUp} {
    ${circle(rem(50))};
  }
`;

const Icon = styled.i`
  ${size('50%')};
  color: ${({ theme }) => theme.colors.white};
`;

interface Props {
  deviceId: string | undefined;
}

function VideoTrackPreview({ deviceId }: Props) {
  const mediaElement = useRef<HTMLDivElement>(null);
  const track = useRef<LocalVideoTrack | null>(null);
  const { cameraPermissions } = useCameraPermissions();

  useEffect(() => {
    let canceled = false;

    const createTrack = () => {
      if (!mediaElement.current) {
        return;
      }

      return createVideoTrack({ deviceId }).then(
        newTrack => {
          if (!canceled) {
            track.current = newTrack;
            const videoElement = newTrack.attach();
            mediaElement.current?.appendChild(videoElement);
          }
        },
        error => {
          if (error instanceof DOMException && error.name === 'NotFoundError') {
            showErrorToast('video.errors.deviceNotFoundError');
            return;
          }

          if (error instanceof DOMException && error.name === 'NotAllowedError') {
            return;
          }

          throw error;
        }
      );
    };

    createTrack();

    return () => {
      canceled = true;

      if (track.current) {
        track.current.stop();
        const mediaElements = track.current.detach();
        mediaElements.forEach(element => element.remove());
        track.current = null;
      }
    };
  }, [deviceId]);

  return cameraPermissions === DevicePermissions.Granted ? (
    <Wrapper ref={mediaElement} />
  ) : (
    <Wrapper ref={mediaElement}>
      <NoPreview>
        <IconWrapper style={{ marginBottom: 5 }}>
          <Icon as={IconExclamationMark} />
        </IconWrapper>
        <span>No preview available</span>
      </NoPreview>
    </Wrapper>
  );
}

export default VideoTrackPreview;
