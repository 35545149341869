import { clientFollowsFollowApi } from './follow';
import { clientFollowsUnfollowApi } from './unfollow';

export function clientFollowsApi(path: string) {
  return {
    path,
    follow: () => clientFollowsFollowApi(`${path}/follow`),
    unfollow: () => clientFollowsUnfollowApi(`${path}/unfollow`),
  };
}
