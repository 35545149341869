import React from 'react';
import type { LocalParticipant } from 'twilio-video';

import { AvatarSize } from 'components/Avatar';

import { useChatWindowContext } from '../ChatWindow/ChatWindowContext';
import AudioTrack from '../Track/AudioTrack';
import VideoTrack from '../Track/VideoTrack';
import { getParticipantTracks } from './helpers';
import { CameraDisabledIndicator } from './Indicators/CameraDisabledIndicator';
import { MicrophoneDisabledIndicator } from './Indicators/MicrophoneDisabledIndicator';
import { NetworkQuality } from './Indicators/NetworkQuality';
import { ParticipantWrapper } from './ParticipantWrapper';
import { BottomStatusBar, TopStatusBar } from './StatusBar';

interface Props {
  participant: LocalParticipant;
}

export function LocalParticipantComponent({ participant }: Props) {
  const { videoTrack, audioTrack } = getParticipantTracks(participant);
  const { isUISmall } = useChatWindowContext();

  return (
    <ParticipantWrapper>
      <TopStatusBar>
        <NetworkQuality participant={participant} isLocal />
      </TopStatusBar>

      <BottomStatusBar>
        <CameraDisabledIndicator videoTrack={videoTrack} />
        <MicrophoneDisabledIndicator audioTrack={audioTrack} />
      </BottomStatusBar>

      <VideoTrack
        participantIdentity={participant.identity}
        track={videoTrack?.track}
        avatarSize={isUISmall ? AvatarSize.S : AvatarSize.M}
      />
      <AudioTrack track={audioTrack?.track} />
    </ParticipantWrapper>
  );
}
