import api from 'api';
import { audio, images, pdf } from 'constants/files';
import { endsWithCaseInsensitive, endsWithCollection, getFileNameFromPath } from 'helpers/utils';

export const isSignatureRequiredForFileType = (fileNameOrUrl: string) =>
  endsWithCollection(getFileNameFromPath(fileNameOrUrl), [pdf]);

export const isImageFileType = (fileNameOrUrl: string) =>
  endsWithCollection(getFileNameFromPath(fileNameOrUrl), images);

export const isAudioFileType = (fileNameOrUrl: string) =>
  endsWithCollection(getFileNameFromPath(fileNameOrUrl), audio);

export const isPdf = (fileNameOrUrl: string) =>
  endsWithCaseInsensitive(getFileNameFromPath(fileNameOrUrl), pdf);

export function canShareFileType(url: string): boolean {
  return isSignatureRequiredForFileType(url) || isImageFileType(url);
}

// https://blog.logrocket.com/programmatic-file-downloads-in-the-browser-9a5186298d5c/
function downloadBlob(blob: Blob, filename: string) {
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.download = filename;
  a.href = url;

  function clickHandler(this: HTMLAnchorElement) {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      this.removeEventListener('click', clickHandler);
    }, 1);
  }

  a.addEventListener('click', clickHandler, false);

  document.body.appendChild(a);
  a.click();
  a.remove();
}

export function downloadFile(url: string, filename?: string) {
  return api.get(url, { responseType: 'blob' }).then(response => {
    let finalFilename = filename;

    const contentHeader = response.headers['content-disposition'] as string | undefined;
    if (contentHeader && contentHeader.indexOf('attachment') !== -1) {
      const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentHeader);
      if (matches != null && matches[1]) {
        finalFilename = matches[1].replace(/['"]/g, '');
      }
    }

    downloadBlob(new Blob([response.data]), finalFilename || getFileNameFromPath(url));
  });
}

// needed for IE and EDGE browsers as they do not implement File constructor
export function blobToFile(input: Blob, fileName: string): File {
  const blobAsFile = input as any;
  blobAsFile.name = fileName;
  blobAsFile.lastModifiedDate = new Date();

  return blobAsFile as File;
}
