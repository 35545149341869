export enum MaritalStatus {
  Single = 'single',
  Married = 'married',
  Divorced = 'divorced',
  Widowed = 'widowed',
  Separated = 'separated',
  NotDisclosed = 'notDisclosed',
}

export enum Realtionship {
  Child = 'child',
  Parent = 'parent',
  Grandparent = 'grandparent',
  Partner = 'partner',
  OtherRelative = 'otherRelative',
  Other = 'other',
}

export enum Gender {
  Male = 'male',
  Female = 'female',
  Other = 'other',
}

export interface PersonalDetails {
  title?: string;
  first_name?: string;
  last_name?: string;
  gender?: Gender;
  date_of_birth?: string;
  marital_status?: string;
  national_insurance_number?: string;
  phone?: string;
  partner_included?: boolean;
  line1?: string;
  line2?: string;
  city?: string;
  post_code?: string;
  country?: string;
}

export type PartnerPersonalDetails = Omit<PersonalDetails, 'partner_included' | 'marital_status'>;

export interface Dependant {
  first_name: string;
  last_name: string;
  type?: string;
  gender?: Gender;
  age?: number;
  until?: number;
}
