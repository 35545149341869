import { CaseType } from 'types/entities/clientCase';
import { HealthType, IncomeSourceType, ProtectionType } from 'components/FactFind/types';

export default {
  notUpdatedWarning: `This Fact Find was not updated by the client. Any contents shown were automatically pre-populated.`,
  saved: `Fact Find has been successfully saved`,
  noFactFind: `There is no Fact Find`,
  blockedLink: `Please, save changes first.`,
  connectedPeopleConsent: {
    title: `Consent to provide information on other individuals`,
    info1: `
      As part of this form, you may optionally provide information on other individuals such as a partner,
      spouse and/or dependants. It is assumed that you have those individuals' consent to provide this information.
    `,
    info2: `Where appropriate and relevant, we will contact the other individuals, if any, to:`,
    contactReason1: `confirm that the information provided within this form relating to them is accurate;`,
    contactReason2: `confirm that they consent to being advised by us;`,
    contactReason3: `confirm that they consent to us using their data for the purposes of advising them.`,
    granted: `Consent to provide information on other individuals has been granted`,
    confirmation: `Yes, I confirm that I have consent from other individuals (if any) to provide information about them and their circumstances`,
    dontShowAgain: `Don't show this notice again for this form`,
    note: `For further information, please see our <policy>Privacy Policy</policy>`,
  },
  personalDetails: {
    title: `Personal Details`,
    subtitle: `Information about you and your family.`,
    subsectionsTitles: {
      basics: `Basics`,
      yourContactInformation: `Your contact information`,
      aboutPartner: `About your partner`,
      dependants: `Children and other dependants`,
      dependantGroup: `Dependant {value}`,
    },
    labels: {
      title: `Title`,
      firstName: `First name`,
      lastName: `Last name`,
      gender: `Gender`,
      male: `Male`,
      female: `Female`,
      other: `Other`,
      genderPreferNotToSay: `prefer not to say`,
      birthDate: `Date of Birth`,
      maritalStatus: `Marital status`,
      nationalInsuranceNumber: `National ID Number`,
      street: `Street`,
      apartment: `Apartment, Door, etc.`,
      city: `Town/City`,
      postCode: `Postcode or area code`,
      country: `Country`,
      phoneNumber: `Phone number`,
      dependants: `Is anyone financially dependent on you?`,
      relationship: `Relationship with you (or your partner)`,
      age: `Age`,
      untilWhen: `Dependant until when?`,
      includePartner: `Consider my partner as part of this Fact Find`,
    },
    placeholders: {
      title: `e.g. Sir, Mr, Mrs, Ms, …`,
      firstName: `e.g. John`,
      lastName: `e.g. Doe`,
      day: `Day`,
      month: `Month`,
      year: `Year`,
      nationalInsuranceNumber: `e.g. QQ123456C`,
      street: `e.g. High Street`,
      apartment: `e.g. 20`,
      city: `e.g. Manchester`,
      postCode: `e.g. NG34 8PD`,
      phoneNumber: `e.g. 07911123456`,
    },
    tooltips: {
      dependants: `You can consider someone as financially dependent on you if they rely on you
        to provide them with a primary income source. Financial dependants may include minors,
        young adults in education, dependent adults, and elderly parents.`,
      untilWhen: `This is an estimate as to when you would envisage no longer having to financially
        support your dependant.`,
      includePartner: `Deselect this option if you would prefer for your partner and their
        circumstances to not be factored in to the recommendation from your adviser`,
    },
    maritalStatusOptions: {
      single: `Single`,
      married: `Married / Civil Partner`,
      divorced: `Divorced / Partner of a dissolved Civil Partnership`,
      widowed: `Widowed / Surviving Civil Partner`,
      separated: `Separated`,
      notDisclosed: `Not disclosed`,
    },
    relationshipOptions: {
      child: `Child`,
      parent: `Parent`,
      grandparent: `Grandparent`,
      partner: `Partner`,
      otherRelative: `Other Relative`,
      other: `Other`,
    },
  },
  occupationEarnings: {
    title: `Occupation & Earnings`,
    subtitle: `Details about your income and expenditure.`,
    subsectionsTitles: {
      occupation: `Occupation`,
      annualIncomes: `Gross Annual Income`,
      monthlyIncomes: `Monthly Disposable Income`,
      occupationGroup: `Occupation {value}`,
      annualIncomesGroup: `Annual income {value}`,
      monthlyIncomesGroup: `Total Net Monthly Income {value}`,
      monthlyOutcomesGroup: `Total Monthly Expenditures {value}`,
      totalGrossAnnIncome: `Total Gross Annual Income (before tax)`,
      totalDisposableIncome: `Total Disposable Income`,
    },
    labels: {
      earner: `Earner`,
      type: `Type`,
      occupation: `Occupation`,
      annualIncomesSource: `Source of Income`,
      monthlyIncomesSource: `Source of Monthly Income`,
      monthlyOutcomesSource: `Source of Expenditures`,
      incomeNotHalfShared: `The joint income is not 50/50 between me and my partner - {value}`,
      transfer: `I would like to review the ongoing suitability of this policy`,
      disposableTarget: `Where relevant, what do you use your disposable income for?`,
    },
    placeholders: {
      occupation: `e.g. Accountant`,
    },
    tooltips: {
      occupation: `How would you describe your ‘job’ or how you spend
        your working time? Our clients sometimes have multiple occupations.
        And their occupations are many and varied - ‘Engineer’, ‘Business Manager’,
        ‘Nurse’, ‘Company Director’, ‘Footballer’, ‘Entrepreneur’, ‘Lecturer’,
        ‘Sales Representative’, ‘Designer’ etc.`,
      annualIncomes: `This is your annual income before tax was deducted.`,
      annualIncomesSource: `Who ‘owns’ the income? Perhaps you own it exclusively
        (e.g. your salary). Or your partner owns the income (e.g. their salary).
        Or the income is jointly-owned (e.g. rental income from a shared rental property).`,
      monthlyIncomes: `Please estimate your typical monthly income after tax has been deducted.
        An example is your monthly ‘take home pay’ from an employment.`,
      monthlyIncomesSource: `If relevant, you can declare your net monthly pay for yourself,
        your partner or jointly.`,
      monthlyOutcomes: `This is an estimate of the total amount that is spent on items such as:
        a mortgage, Council Tax, utilities, broadband, groceries, clothing, leisure activities,
        and insurances.`,
      monthlyOutcomesSource: `If relevant, you can split your expenditures across yourself and your partner.`,
    },
    incomeOptions: {
      [IncomeSourceType.Employment]: `Employment income`,
      [IncomeSourceType.EmploymentBonus]: `Employment income (bonus)`,
      [IncomeSourceType.SelfEmployment]: `Self-employment income`,
      [IncomeSourceType.Investment]: `Investment income`,
      [IncomeSourceType.PrivatePension]: `Private Pension income`,
      [IncomeSourceType.StatePension]: `State Pension income or equivalent`,
      [IncomeSourceType.RentalIncome]: `Rental income`,
      [IncomeSourceType.OtherStateBenefits]: `Other State benefits`,
      [IncomeSourceType.OtherIncome]: `Other income`,
    },
  },
  assetsLiabilities: {
    title: `Assets & Liabilities`,
    subtitle: `Details of your financial assets, properties, other valuable assets and your debt.`,
    viewLOA: `View letter of authority`,
    totalWealth: `Net worth (est.): {value}`,
    subsectionsTitles: {
      assets: `Assets`,
      assetGroup: `Asset {value}`,
      liabilities: `Liabilities`,
      liabilityGroup: `Liability {value}`,
      assetsLessLiabilities: `Assets less liabilities`,
      totalWealth: `Your total wealth`,
      totalWealthWithPartner: `You and your partner’s total wealth`,
    },
    noAssets: `No assets declared`,
    noLiabilities: `No liabilities declared`,
    notes: {
      assets: `Including Cash, ISAs, Pensions, Unit Trusts, Stocks & Shares, other investments,
        businesses, property, and other valuable assets.`,
      liabilities: `Including mortgages, car loans, student loans, personal loans, credit card debt,
        and other forms of debt.`,
    },
    labels: {
      anyAssets: `Do you have any assets?`,
      type: `Type`,
      assetValue: `Value`,
      assetsSource: `Who is the beneficial owner of the asset?`,
      assetsNotHalfShared: `This jointly-owned asset is not held equally by me and my partner - {value}`,
      anyLiabilities: `Do you have any liabilities?`,
      liabilitiesSource: `Who holds the liability?`,
      liabilitiesNotHalfShared: `The liability is not held 50/50 between me and my partner - {value}`,
      transfer: `I would like to review the ongoing suitability of this policy`,
      description: `Description`,
      policyNumber: `Policy number`,
      policyProviderName: `Provider name`,
    },
    assetTypes: {
      cash: `Cash`,
      isa: `ISA (Individual Savings Account) (UK)`,
      pension: `Pension`,
      unitTrust: `Unit Trust`,
      stockShares: `Stocks & Shares`,
      otherInvestment: `Other investment`,
      business: `Business`,
      property: `Property`,
      otherValuableAsset: `Other valuable asset`,
      investmentBond: `Investment bond`,
    },
    liabilitiesTypes: {
      mortgage: `Mortgage`,
      personalLoan: `Personal loan`,
      carLoan: `Car loan`,
      creditCardDebt: `Credit card debt`,
      studentLoan: `Student loan`,
      otherDebt: `Other debt`,
    },
    tooltips: {
      assetsLessLiabilities: `You and your partner’s financial net worth.`,
      totalWealth: `This is a measure of your total financial net worth.`,
      totalWealthWithPartner: `This is a measure of you and your partner’s total financial net worth.`,
      assetDescription: `Optionally provide extra information about this asset that may be relevant.`,
      liabilitiesDescription: `Optionally provide extra information about this liability that may be relevant.`,
      policyNumber: `You should have received an enrolment letter or other paperwork from the provider that runs the pension scheme`,
      policyProviderName: `You should have received an enrolment letter or other paperwork from the provider that runs the pension scheme`,
    },
  },
  taxResidence: {
    title: `Tax & Residence`,
    subtitle: `For tax and legal purposes, we require details about your tax and residency status.`,
    subsectionsTitles: {
      descriptionTitle: `Personal Tax / Residence / Domicile Status`,
      client: `You`,
      partner: `Your Partner`,
    },
    labels: {
      clientChargeable: `Do you pay income tax in the United Kingdom?`,
      partnerChargeable: `Does your partner pay income tax in the United Kingdom?`,
      incomeTaxValue: `Income tax value`,
      clientDomiciled: `Are you domiciled in the United Kingdom for tax purposes?`,
      partnerDomiciled: `Is your partner domiciled in the United Kingdom for tax purposes?`,
      clientArrived: `When did you first arrive in the United Kingdom?`,
      partnerArrived: `When did your partner first arrive in the United Kingdom?`,
      rate: `Highest Rate`,
      clientBorn: `I was born in the United Kingdom`,
      partnerBorn: `My partner was born in the United Kingdom`,
      countryOfResidence: `In what country are you currently resident?`,
    },
    tooltips: {
      clientDomiciled: `Domicile is a legal concept that typically describes the country that you
        consider to be your home, or the country where you have your permanent home. If you are
        unsure of your domiciliary status, your adviser can assist you.`,
      partnerDomiciled: `Domicile is a legal concept that typically describes the country that your
        partner considers to be their home, or the country where they have their permanent home.
        If you are unsure of your partner’s domiciliary status, your adviser can assist you.`,
    },
    taxRateOptions: {
      uk45: `45%`,
      uk40: `40%`,
      uk20: `20%`,
      uk10: `10%`,
      sc46: `46% Scotland`,
      sc41: `41% Scotland`,
      sc21: `21% Scotland`,
      sc20: `20% Scotland`,
    },
  },
  investmentTaxPlan: {
    title: `Investment & Tax Planning`,
    subtitle: `Further details about your current situation and tax allowances that you have used
      this tax year.`,
    subsectionsTitles: {
      emergencyFunds: `Emergency Funds`,
      isaCapitalGainsTax: `ISA & Capital Gains Tax`,
      isaGroup: `ISA {value}`,
      willArrangements: `Will Arrangements`,
      advancedPlanning: `Advanced Planning`,
      investmentExperience: `Investment Experience`,
    },
    labels: {
      emergencyFunds: `Do you or your partner have immediate access to an emergency fund?`,
      emergencyFundsSingle: `Do you have immediate access to an emergency fund?`,
      extraEmergencyFunds: `Do you or your partner need additional emergency funds?`,
      extraEmergencyFundsSingle: `Do you need additional emergency funds?`,
      isaInvested: `Have you or your partner invested into an ISA this tax year?`,
      isaInvestedSingle: `Have you invested into an ISA this tax year?`,
      isaValue: `Amount invested`,
      isasType: `Type`,
      isaInvestor: `Investor`,
      regularCapitalGains: `Do you regularly use your Capital Gains Tax (CGT) allowance?`,
      everCapitalGains: `Have you used or intend to use your CGT allowance for this tax year?`,
      clientMadeWill: `Have you made a will?`,
      partnerMadeWill: `Has your partner made a will?`,
      advancedPlanning: `Would you consider higher risk tax efficient investment schemes?`,
      clientInvestmentExperience: `Have you ever invested or held a pension before?`,
      partnerInvestmentExperience: `Has your partner ever invested or held a pension before?`,
    },
    tooltips: {
      emergencyFunds: `An emergency fund is set aside for use if you have a personal financial
        dilemma, such as unexpected unemployment, an illness, or an unforeseen large expense.
        We recommend that your emergency fund covers 3 to 6 times your typical monthly outgoings.`,
      isaInvested: `A tax year is from 6th April in one year to 5th April the following year. For
        example, the 2018/19 tax year runs from 6th April 2018 to 5th April 2019.`,
      regularCapitalGains: `You have an annual allowance to reduce the tax that you might pay on
        your capital gains. A capital gain is a profit that results from a sale of a capital asset,
        such as shares, a business, or property.`,
      advancedPlanning: `Venture Capital Trusts (VCTs) and Enterprise Investment Schemes (EIS) offer
        Income Tax relief for individual investors as well as Capital Gains Tax exemptions.`,
    },
    isaTypes: {
      cash: `Cash`,
      stockShares: `Stock & Shares`,
    },
  },
  health: {
    title: `Health`,
    subtitle: `If relevant, please provide details about your general health and well-being.`,
    subsectionsTitles: {
      you: `You`,
      partner: `Your Partner`,
    },
    labels: {
      you: {
        [HealthType.Smoker]: `Are you a smoker?`,
        [HealthType.GoodHealth]: `Are you in good health?`,
        [HealthType.MedicalConditionSuffer]: `Do you suffer from any known medical condition?`,
        [HealthType.HazardousOccupationHobby]: `Is your occupation hazardous or are any of your hobbies hazardous?`,
      },
      partner: {
        [HealthType.Smoker]: `Is your partner a smoker?`,
        [HealthType.GoodHealth]: `Is your partner in good health?`,
        [HealthType.MedicalConditionSuffer]: `Does your partner suffer from any known medical condition?`,
        [HealthType.HazardousOccupationHobby]: `Is your partner's occupation hazardous or are any of your partner's hobbies hazardous?`,
      },
      comment: `Comment`,
    },
    tooltips: {
      comment: `Optionally provide extra information, if relevant.`,
    },
  },
  protection: {
    title: `Protection`,
    subtitle: `If relevant, please provide details about your existing protection provisions and potential further protection needs.`,
    subsectionsTitles: {
      existingProtection: `Existing protection`,
      existingProtectionGroup: `Protection {value}`,
      furtherProtectionNeeds: `Further protection needs`,
      death: `Death`,
      you: `You`,
      partner: `Partner`,
      illnessDisability: `Illness and/or disability`,
    },
    notes: {
      existingProtection: `Including life insurance, income protection, critical illness protection, private medical insurance, and permanent health insurance.`,
    },
    labels: {
      protectionType: `Type of protection plan`,
      owner: `Owner`,
      policyNumber: `Policy number`,
      provider: `Provider`,
      coverAmount: `Amount of cover`,
      regularPremium: `Regular premium`,
      protectionPriorities: `Please describe your protection priorities.`,
      anyExistingProtection: `Do you have any existing protection in place?`,
      death: {
        capital: {
          you: `What capital would your family and/or beneficiaries require in event of your death?`,
          partner: `What capital would their family and/or beneficiaries require in event of your partner's death?`,
        },
        incomeAmount: {
          you: `What annual income would your family and/or beneficiaries require in event of your death?`,
          partner: `What annual income would their family and/or beneficiaries require in event of your partner's death?`,
        },
        incomeTime: {
          you: `For how long would your family and/or beneficiaries require this income? (years)`,
          partner: `For how long would their family and/or beneficiaries require this income? (years)`,
        },
      },
      illnessDisabilities: {
        capital: {
          you: `What capital would you require in event of a critical illness?`,
          partner: `What capital would your partner require in event of a critical illness?`,
        },
        supplementaryShortTerm: {
          you: `What supplementary income would you require in event of a short or medium-term illness or disability?`,
          partner: `What supplementary income would your partner require in event of a short or medium-term illness or disability?`,
        },
        supplementaryLongTerm: {
          you: `What supplementary income would you require in event of a long-term illness or disability?`,
          partner: `What supplementary income would your partner require in event of a long-term illness or disability?`,
        },
      },
    },
    protectionTypes: {
      [ProtectionType.LifeInsurance]: `Life insurance`,
      [ProtectionType.CriticalIllnessProtection]: `Critical illness protection`,
      [ProtectionType.IncomeProtection]: `Income protection`,
      [ProtectionType.PrivateMedicalInsurance]: `Private medical insurance`,
      [ProtectionType.PermanentHealthInsurance]: `Permanent health insurance`,
    },
    tooltips: {
      description: `Optionally provide extra information regarding your policy.`,
    },
  },
  planningObjectives: {
    title: `Planning Objectives`,
    subtitle: `If relevant, please provide details about your planning objectives.`,
    overarchingFinancialPlanning: `What would you say is your one overarching financial planning objective?`,
    bigTicketItemsExp: `What big-ticket items of expenditure, if any, would you envisage in retirement? For example, a holiday property, a boat, or a world tour.`,
    financialIndependence: `How much net income, in today's money, would you want to receive each and every month for you to be financially independent? And, in an ideal world, when would you like such financial independence to begin?`,
    leavingLegacy: `Is leaving a legacy to your beneficiaries important to you? If so, is there an amount, in today's money and excluding any property that you might own, that you would like to pass onto your beneficiaries?`,
    risks: `What do you think are risks to your financial future?`,
  },
  recentLifeEvents: {
    title: `Recent Life Events`,
    subtitle: `Life events may affect how we financially plan. As such, it may be important for your Adviser to discuss your recent life events with you. Please let us know if any of the below relate to you.`,
    labels: {
      discussAllTopics: `Yes, I would like my Adviser to discuss the above with me.`,
    },
    definitions: {
      bereavement: `<strong>Bereavement</strong>. You may have recently lost a loved one or a close family member.`,
      divorce: `<strong>Divorce</strong>. You may have recently had a change in your marital or relationship status.`,
      financialDifficulty: `<strong>Financial difficulty</strong>. You may currently be experiencing, or have recently experienced, a financial difficulty.`,
      changeInHealth: `<strong>Change in health</strong>. You may have had a recent change in your health. Or a financial dependant may have recently had a change in their health.`,
      traumaticEvent: `<strong>Traumatic event</strong>. You may have recently suffered a significant traumatic event that affects your financial plans.`,
    },
  },
  additionalComments: {
    title: `Additional Comments`,
    subtitle: `Provide further information that may help us to further understand
        your financial situation.`,
    labels: {
      clientComment: `Comments`,
    },
  },
  documents: {
    title: `Documents`,
    tooltip: `Here you can upload important documents that may be relevant to
      your Advice Case. For example, share certificates, a copy of your Will,
      or a scan of your passport. When reviewing your case, your adviser may request that
      you provide supplementary information via this upload function.`,
    choose: `Choose files`,
  },
  months: {
    january: `January`,
    february: `February`,
    march: `March`,
    april: `April`,
    may: `May`,
    june: `June`,
    july: `July`,
    august: `August`,
    september: `September`,
    october: `October`,
    november: `November`,
    december: `December`,
  },
  choose: `Choose`,
  buttons: {
    saveNext: `Save and next`,
    saveContinue: `Save and continue`,
    save: `Save`,
    remove: `Remove`,
    edit: `Edit`,
    next: `Go to the next step`,
    discard: `Discard changes`,
    backCase: `Back to Case Details`,
    backDocuments: `Back to Case Documents`,
    addDependant: `Add other dependant`,
    addOccupation: `Add another occupation`,
    addIncomeAnnual: `Add another annual income`,
    addIncomeMonthly: `Add another Total Net Monthly Income`,
    addOutcomeMonthly: `Add another Total Monthly Expenditures`,
    addAsset: `Add another asset`,
    addLiability: `Add another liability`,
    addIsa: `Add another ISA`,
    addExistingProtection: `Add existing protection`,
  },
  toasts: {
    dependantsName: `To proceed, specify full names for all previous dependants.`,
    occupationName: `To proceed, specify all occupations’ names and sources`,
    occupationNameSingle: `To proceed, specify all occupations’ names`,
    annualIncomes: `To proceed, specify all gross incomes’ values, sources and types.`,
    annualIncomesSingle: `To proceed, specify all gross incomes’ values and types.`,
    monthlyIncomes: `To proceed, specify all monthly incomes’ values and sources`,
    monthlyIncomesSingle: `To proceed, specify all monthly incomes’ values`,
    monthlyOutcomes: `To proceed, specify all monthly expenditures’ values and sources`,
    monthlyOutcomesSingle: `To proceed, specify all monthly expenditures’ values`,
    assets: `To proceed, specify all assets’ required fields`,
    liabilities: `To proceed, specify all liabilities’ types, values and sources`,
    liabilitiesSingle: `To proceed, specify all liabilities’ types and values`,
    isa: `To proceed, specify all previous types, values and investors`,
    isaSingle: `To proceed, specify all previous types and values`,
    existingProtection: `To proceed, specify all existing protections' required fields`,
  },
  yes: `Yes`,
  no: `No`,
  sourceOptions: {
    you: `You`,
    partner: `Partner`,
    joint: `Joint`,
  },
  sourceGroupCommon: {
    numberPlaceholder: `e.g. 70`,
    numberLabel: `You own`,
  },
  savingInfo: `Don’t worry if you don’t have all the information available immediately. Save the form as you progress, and come back later.`,
  [CaseType.OnlineAdviceRequest]: `Advice Case: {value}`,
  document: `Documents`,
  amount: `Amount`,
  timePlaceholder: `e.g. 11`,
};
