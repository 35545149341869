import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';

import { brochureApi } from './brochure';

const apiPath = '/api/v1/brochures';

function brochuresApi(path: string) {
  return {
    path,
    GET: () => api.get<JsonApiResponse>(path),
  };
}

type BrochureApi = ReturnType<typeof brochureApi>;
type BrochuresApi = ReturnType<typeof brochuresApi>;

function brochures(): BrochuresApi;
function brochures(brochureId: number): BrochureApi;
function brochures(brochureId?: number): BrochuresApi | BrochureApi {
  return brochureId ? brochureApi(`${apiPath}/${brochureId}`) : brochuresApi(apiPath);
}

export { brochures };
