import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';

export interface UserUpdateEmailPatchOptions {
  email: string;
}

export function userUpdateEmailApi(path: string) {
  return {
    path,
    PATCH: ({ email }: UserUpdateEmailPatchOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: {
            email,
          },
        },
      }),
  };
}
