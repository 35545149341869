const screenUsMin = 0;
const screenUsMax = 479;
const screenXsMin = 480;
const screenXsMax = 767;
const screenSmMin = 768;
const screenSmMax = 1023;
const screenMdMin = 1024;
const screenMdMax = 1279;
const screenLgMin = 1280;
const screenLgMax = 1479;
const screenXlMin = 1480;

interface Breakpoints {
  usDown: string;
  usOnly: string;
  usUp: string;
  xsDown: string;
  xsOnly: string;
  xsUp: string;
  smDown: string;
  smOnly: string;
  smUp: string;
  mdDown: string;
  mdOnly: string;
  mdUp: string;
  lgDown: string;
  lgOnly: string;
  lgUp: string;
  xlUp: string;
  screenUsMin: number;
  screenUsMax: number;
  screenXsMin: number;
  screenXsMax: number;
  screenSmMin: number;
  screenSmMax: number;
  screenMdMin: number;
  screenMdMax: number;
  screenLgMin: number;
  screenLgMax: number;
  screenXlMin: number;
  [key: string]: string | number;
}

export const breakpoints: Breakpoints = {
  usDown: `(max-width: ${screenUsMax}px)`,
  usOnly: `(min-width: ${screenUsMin}px) and (max-width: ${screenUsMax}px)`,
  usUp: `(min-width: ${screenUsMin}px)`,
  xsDown: `(max-width: ${screenXsMax}px)`,
  xsOnly: `(min-width: ${screenXsMin}px) and (max-width: ${screenXsMax}px)`,
  xsUp: `(min-width: ${screenXsMin}px)`,
  smDown: `(max-width: ${screenSmMax}px)`,
  smOnly: `(min-width: ${screenSmMin}px) and (max-width: ${screenSmMax}px)`,
  smUp: `(min-width: ${screenSmMin}px)`,
  mdDown: `(max-width: ${screenMdMax}px)`,
  mdOnly: `(min-width: ${screenMdMin}px) and (max-width: ${screenMdMax}px)`,
  mdUp: `(min-width: ${screenMdMin}px)`,
  lgDown: `(max-width: ${screenLgMax}px)`,
  lgOnly: `(min-width: ${screenLgMin}px) and (max-width: ${screenLgMax}px)`,
  lgUp: `(min-width: ${screenLgMin}px)`,
  xlUp: `(min-width: ${screenXlMin}px)`,
  screenUsMin,
  screenUsMax,
  screenXsMin,
  screenXsMax,
  screenSmMin,
  screenSmMax,
  screenMdMin,
  screenMdMax,
  screenLgMin,
  screenLgMax,
  screenXlMin,
};

const sideBarWidth = 280;
const dashboardWidth = 1680;

export const misc = {
  zIndex1: 1000,
  zIndex2: 2000,
  zIndex3: 3000,
  zIndex4: 4000,
  zIndex5: 5000,
  zIndex6: 6000,
  zIndex7: 7000,
  zIndex8: 8000,
  zIndex9: 9000,
  dropdownMenuZIndex: 1999,
  pageTourGuideZIndex: 50000,
  videoChatZIndex: 100000,
  tooltipZIndex: 100001,
  modalZIndex: 999999991,
  onboardingFormWidth: 800,
  genericFormWidth: 600,
  siteMaxWidth: 1920,
  containerWidth: 1200,
  gutter: 15,
  gutterLg: 30,
  sideBarWidth,
  logoWidth: 195,
  logoHeight: 115,
  dashboardBtnWidthLg: 260,
  dashboardBtnWidthMd: 200,
  headerHeight: 80,
  headerHeightWithMargin: 90,
  dashboardPaddingSm: 10,
  dashboardPadding: 15,
  dashboardPaddingLg: 50,
  dashboardPaddingXl: 70,
  dashboardMargin: 40,
  dashboardMarginXl: 50,
  dashboardWidth,
  dashboardFooterHeight: 100,
};
