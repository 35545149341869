import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';

export interface UserMessagesUnreadApiResponse {
  unread_notes: boolean;
}

export function userMessagesUnreadApi(path: string) {
  return {
    path,
    GET: (config?: RequestConfig) => api.get<UserMessagesUnreadApiResponse>(path, config),
  };
}
