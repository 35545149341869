import React from 'react';

import { useStyledTheme } from 'hooks/useStyledTheme';

import SvgIcon from './SvgIcon';

const IconHamburger = (props: React.SVGAttributes<SVGSVGElement>) => {
  const theme = useStyledTheme();

  return (
    <SvgIcon viewBox="0 0 36 36" {...props}>
      <path fill={theme.colors.fountainBlue} opacity=".2" d="M0 0h36v36H0z" />
      <path fill={theme.colors.main} d="M7 10h22v2H7zm0 7h22v2H7zm0 7h11v2H7z" />
    </SvgIcon>
  );
};

export default IconHamburger;
