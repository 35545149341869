import { LandingPage } from 'constants/landingPages';
import { FeatureTileName } from 'helpers/features';

const featuresTiles: Record<FeatureTileName, string> = {
  [FeatureTileName.Blog]: `Blog`,
  [FeatureTileName.AccountSettings]: `Account settings`,
  [FeatureTileName.AdviceRooms]: `Client Meeting Rooms`,
  [FeatureTileName.BrochureLibrary]: `Brochure Library`,
  [FeatureTileName.DocumentVault]: `My Vault`,
  [FeatureTileName.DocumentsPacks]: `Documents`,
  [FeatureTileName.FinancialStrategy]: `Financial Strategy`,
  [FeatureTileName.MotivationCentre]: `Motivation Centre`,
  [FeatureTileName.MyIdentification]: `My Identification`,
  [FeatureTileName.Messages]: `Messages`,
  [FeatureTileName.OurServiceCharter]: `Our Service Charter`,
  [FeatureTileName.OurServicesAndCharges]: `Our Services and Charges`,
  [FeatureTileName.Questionnaires]: `Questionnaires`,
  [FeatureTileName.RecommendationsAndRequests]: `Recommendations and Advice Requests`,
  [FeatureTileName.Welcome]: `Welcome`,
  [FeatureTileName.EstateSummary]: `My Estate Summary`,
  [FeatureTileName.IdentityVerification]: `Identification Verification`,
};

export default {
  shared: {
    addAnother: `Add another`,
    emptyPhotoMessage: `Please, choose uploaded file`,
    sectionNotEffective: `Values below won't be visible on the page if Portal like landing page type is selected.`,
  },
  general: {
    practiceOverview: {
      title: `Practice Overview`,
      logosTooltip: `To help ensure the logo looks well on difference displays and at different resolutions, we recommend using an SVG file format.`,
      logo: {
        title: `Logo`,
        upload: `Upload logo`,
        hint: `After you save a new logo, it would be prudent to confirm that the logo
          looks well on your website. Please review your landing pages to confirm.`,
      },
      secondaryLogo: {
        title: `Secondary logo`,
        upload: `Upload secondary logo`,
      },
      favicon: {
        title: `Favicon`,
        upload: `Upload favicon`,
        rule1: `Favicon must be smaller than {value}.`,
        rule2: `A square image is recommended, and should have dimensions greater than 192 x 192.`,
        rule3: `Reload this page after saving changes to see the favicon in the browser tab.`,
      },
      name: {
        title: `Name and references`,
        representativeName: `Legal entity name`,
        tradingName: `Trading name`,
        tradingNameDescription: `This name would be used throughout the site.`,
      },
      mainWebsite: {
        title: `Main website URL`,
        tooltip: `You can optionally include a link - to another website - on your DWS landing page. For example, you could link to your Practice’s main website. Please specify the URL details below.`,
        urlTitle: `Title`,
        url: `URL`,
      },
    },
    colourSchemes: {
      title: `Colour Schemes`,
      accent: `Accent`,
      transparentBox: `Transparent box`,
      textDark: `Text dark`,
      textLight: `Text light`,
      background: `Background`,
      subHeader: `Second header`,
      revert: `Revert to defaults`,
      preview: {
        title: `Scheme preview`,
        message: `This is how colours will look like on your website.`,
        button: `Button`,
      },
    },
    addresses: {
      title: `Addresses`,
      registeredOfficeAddress: `Registered office address`,
      mailingAddress: `Mailing address`,
    },
    links: {
      title: `Links`,
      insights: {
        title: `Insights`,
        link: `Website link`,
      },
      other: {
        title: `Other webpage`,
        tooltip: `This should be a link to your existing Practice website.`,
        link: `Link`,
        linkTitle: `Title for link`,
      },
    },
    footer: {
      title: `Footer`,
      footerText: `Footer text`,
    },
    thirdPartyIntegration: {
      title: `Third party integration`,
      riskAssessmentTitle: `Risk profiling`,
      riskAssessment: `Enable risk tolerance assessment`,
    },
    emailFooter: {
      title: `Email`,
      footerText: `Footer text`,
      emailSender: `From`,
      emailSenderTooltip: `This is the email address that will be used to
      send automatic emails to clients and Practice staff`,
    },
    privacyPolicy: {
      title: `Privacy policy`,
      subtitle: `Please specify the URL of your privacy policy. This policy will be made available to users of this service.`,
      privacyPolicyUrl: `URL`,
    },
    webManifest: {
      title: `Web Manifest`,
      sectionTitle: `Users can optionally bookmark the website on their mobile device. This creates a link on their device’s homescreen. The settings below are used to make that link ‘feel’ like a native application.`,
      shortName: `Short name of website`,
      shortNameTooltip: `Enter a short name for the website. This will be shown on the user’s homescreen.`,
      metaDescription: `Description of website`,
      metaDescriptionTooltip: `Enter a brief description of the website.`,
      manifestBackgroundColor: `Background colour`,
      manifestBackgroundColorTooltip: `Choose a colour that will be shown in the background when the homescreen link is opened.`,
      manifestThemeColor: `Theme colour`,
      metaThemeColor: `Meta theme colour`,
      manifestThemeColorTooltip: `Choose a colour that the user’s browser may use to tint the web toolbar and other aspects of the user experience.`,
      mobileIcon: {
        title: `Mobile icon`,
        upload: `Upload mobile icon`,
        description: `The icon that will be used when user decide to pin the application website to their mobile devices home screen. If not set, favicon will be used instead.`,
        rule1: `Icon must be smaller than {value}.`,
        rule2: `A square image is recommended, and should have dimensions greater than 192 x 192.`,
      },
    },
    pdfSettings: {
      title: `Pdf settings`,
      font: `Font`,
      preview: `Preview`,
    },
  },
  clientMeetingRooms: {
    defaultMeetingServerLocation: {
      title: `Default Meeting Server Location`,
      subtitle: `Specify a default meeting server location that is likely optimal for most of your Practice’s client meetings. You should choose a location that is geographically close to your typical office. For example, if your offices are in Singapore, then ‘Hong Kong’ might be the best meeting server location. Choosing a geographically close meeting server should help to reduce network-related issues that may potentially arise during meetings; such as lag.{br}{br}If your attendees will likely join from multiple locations, you can allow the application to dynamically assign a country. Dynamic assignment is based on the first attendee to join. For example, if the first attendee client joins from the USA, the application will nominate a meeting server in the USA to host the meeting.{br}{br}Note: When a specific meeting is being organised, the default meeting server location can be overwritten.`,
    },
    agendaTemplate: {
      title: `Agenda Templates`,
    },
  },
  clientMedia: {
    clientSignInNotification: {
      title: `Client sign-in notification`,
      subtitle: `You can present clients with a dismissible notification when they sign in. 
        For example, you might notify them of upcoming Practice events, important Budget announcements,
        or you might present them with an approved marketing campaign message.`,
      notification: `Notification`,
      notificationDescription: `Upload the approved client notification that will be presented to clients when they next sign in.`,
      new: {
        warning: `The existing client notification will be clear so that you can create a new notification.`,
        oldDeleted: `Client sign-in notification has been successfully deleted`,
      },
      displayFrom: {
        title: `Display from`,
        text: `Display the notification from`,
      },
      displayUntil: {
        title: `Display until`,
        text: `Display the notification until`,
      },
    },
    clientDashboardLinks: {
      title: `Client dashboard links`,
      subtitle: `Create custom links in your clients’ dashboards. For example, you could include links to other websites that your clients will typically access.`,
      removeModalWarning: `Are you sure you want to remove this Client dashboard link?`,
      editLink: `Edit client dashboard link`,
      createLink: `Create new client dashboard link`,
      linkRemoved: `Client dashboard link has been successfully removed`,
      linkUpdated: `Client dashboard link has been successfully updated`,
      linkCreated: `Client dashboard link has been successfully created`,
      sortingMessage: `To change the order of links on clients' dashboards, enable sorting and then drag the links' rows to set the correct order.`,
      maxItems: `There can be maximum {value} links`,
    },
    clientDashboardTiles: {
      title: `Client dashboard tiles`,
      editTile: `Edit client dashboard tile`,
      subtitle: `Upload images for client dashboard tiles and set their order.`,
      sortingMessage: `To change the order of tiles on clients' dashboards, enable sorting and then drag the links' rows to set the correct order.`,
      revertWarning: `Are you sure you want to revert this tile image to default?`,
      tileUpdated: `Client dashboard tile has been successfully updated`,
      featuresTiles,
    },
  },
  requestingAdvice: {
    currencies: {
      title: `Currencies`,
      subtitle: `Your Practice might advise on investments across a range of currencies. Here you can specify which currencies you advise on. When a client completes a form – relating to an advice request (e.g. a top-up request) – they will be allowed to choose the relevant currency from the list of currencies that you specify below. You can drag the rows to change the order.`,
    },
    financialPlanningTopics: {
      title: `Financial planning topics`,
      subtitle: `When your client, or prospective client, requests advice via this site, they can
        select from the following ‘ticked’ topics. Please tick the topics that are relevant
        for your Practice.`,
    },
    financialPromotionsTerms: {
      title: `Financial promotion terms`,
      subtitle: `Here you can specify the necessary financial promotions terminology displayed to clients through this site.`,
      onlineAdviceRequest: {
        title: `Online Advice request`,
        tooltip: `A client can request ‘Online Advice’ a new investment. When the client initiates the request, this financial promotions text will be shown to them.`,
      },
      topUpRequest: {
        title: `Request advice on a top-up investment`,
        tooltip: `A client can request advice on a top-up investment. When the client initiates the request, this financial promotions text will be shown to them.`,
      },
      investmentProjections: {
        title: `Investment projections`,
        tooltip: `Through the site, investment projections may be shown to clients; so as to provide them with an indication on potential investment performance. This financial promotions text will be shown below these projections.`,
      },
    },
    disclosure: {
      title: `Disclosure`,
      autoDisclosure: {
        title: `Auto disclosure`,
        tooltip: `If you wish, a disclosure form can be automatically presented to your clients when they request a top-up investment or request online advice. For anonymous prospective clients, a disclosure form is automatically presented when they onboard and create an account on this website.`,
        enableOn: `Disclosure will be automatically presented to your clients`,
        enableOff: `Disclosure will not be automatically presented to your clients`,
      },
      services: {
        title: `‘Our services and charges’ client area`,
        tooltip: `If you wish, you can present all clients with details of your Practice’s services and charges.`,
        enableOn: `Area will be presented to your clients`,
        enableOff: `Area will not be presented to your clients`,
        add: `Add disclosure form`,
        sortingMessage: `To change the order of disclosure forms on clients' dashboards, enable sorting and then drag the forms' rows to set the correct order.`,
      },
      edit: `Edit disclosure form`,
      enabledLabel: `Enable`,
      upload: `Upload PDF`,
      newDisclosure: `New document will be uploaded: {fileName}`,
    },
  },
  prospectOnboarding: {
    general: {
      disableOnlineOnboarding: `Disable online prospective client onboarding`,
      disableOnlineOnboardingTooltip: `Enable or disable prospective clients ability to seek your advice (F2F or Online Advice) via your website. Depending on your decision, please ensure that your FAQ and general website copy is appropriate. For example, please don’t refer to online onboarding considerations in your FAQ if you have disabled this feature.`,
    },
    preferredAdvisers: {
      title: `Preferred Advisers`,
      subtitle: `When onboarding from the Practice’s home page, a prospective client may be able to choose their preferred Adviser.
        Please select which Advisers should be presented to the prospective client.`,
      note: `Note: Only active adviser accounts will be shown to prospective clients.`,
      warning: `Clients will be able to select preferred Adviser if you choose at least two active Advisers from the list.`,
      noAdvisers: `No Advisers to select from.`,
    },
  },
  landingPages: {
    enabledPages: {
      title: `Enabled pages`,
      descriptions: {
        [LandingPage.HomePage]: `This is your main landing page.`,
        [LandingPage.HowItWorks]: `This page informs prospective clients of the typical process for them to request a meeting with an adviser.{br}Note: ‘How it works’ page will be visible only if onboarding is enabled.`,
        [LandingPage.OurAdvisers]: `This page showcase your advice team.`,
        [LandingPage.Faq]: `A series of frequently asked question about this website.`,
        [LandingPage.Contact]: `Contact details for your Practice.`,
        [LandingPage.HowWeCommunicate]: `An explanation as to how your Practice will typically communicate with clients.`,
        [LandingPage.SecurityStatement]: `Provides information about security infrastructure and practices.`,
      },
    },
  },
  faq: {
    noSectionsAdded: `There are no FAQ sections added.`,
    addSection: `Add section`,
    editSection: `Edit section`,
    removeSection: `Remove section`,
    removeSectionConfirmation: `Are you sure you want to remove selected section?`,
    addQuestion: `Add new question`,
    editQuestion: `Edit question`,
    removeQuestion: `Remove question`,
    removeQuestionConfirmation: `Are you sure you want to remove selected question?`,
    termsTitle: `Terms and conditions text (optional)`,
  },
};
