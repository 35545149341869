import { rem } from 'polished';
import styled from 'styled-components';

import { breakpoints } from 'helpers/styles/constants';

import { modalPadding, modalPaddingSm } from './shared';

const ModalTitle = styled.div`
  flex-shrink: 0;
  padding: ${rem(modalPaddingSm)} ${rem(modalPaddingSm)} ${rem(modalPaddingSm / 2)};

  @media ${breakpoints.smUp} {
    padding: ${rem(modalPadding)} ${rem(modalPadding)} ${rem(modalPadding / 2)};
  }
`;

export default ModalTitle;
