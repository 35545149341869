import React from 'react';

import SvgIcon from '../SvgIcon';

export default function IconDominantSpeakerLayout(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 0 30 30" {...props}>
      <g stroke="currentColor" fill="none" strokeWidth="2">
        <rect x="2" y="2" rx="2" width="26" height="26" />
        <rect x="16" y="16" rx=".5" width="9" height="9" />
      </g>
    </SvgIcon>
  );
}
