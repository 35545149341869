import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { breakpoints, misc } from 'helpers/styles/constants';
import { isBlank } from 'helpers/utils';
import { BasicTooltip } from 'components/GenericTooltip';
import IconArrowLeft from 'components/Icons/IconArrowLeft';
import IconArrowRight from 'components/Icons/IconArrowRight';

import PdfCircleButton from './components/PdfCircleButton';
import { ButtonSize, IconStyled } from './components/styled';
import ToolbarSection from './ToolbarSection';

const InputMinWidth = 40;

const pageNumberStyles = css`
  min-width: ${rem(InputMinWidth)};
  text-align: center;
`;

const PageInput = styled.input.attrs({ type: 'number', min: 1 })`
  ${pageNumberStyles};
  width: 0;
  height: ${rem(ButtonSize)};
  appearance: textfield;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  border-bottom: 1px solid transparent;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.accent};
  }
`;

const PageNumber = styled.div`
  ${pageNumberStyles};
  font-style: italic;
`;

const PageNumberSeparator = styled.div`
  @media ${breakpoints.smUp} {
    margin: 0 ${rem(8)};
  }
`;

export interface Props {
  onChange: (page: number) => void;
  pageNumber: number;
  totalPages: number;
  isDisabled?: boolean;
}

type InputTypes = Required<React.InputHTMLAttributes<HTMLInputElement>>;

const Pagination = ({ onChange, pageNumber, totalPages, isDisabled }: Props) => {
  const [savedPageNumber, setSavedPageNumber] = useState<number | null>(pageNumber);

  useEffect(() => {
    setSavedPageNumber(pageNumber);
  }, [pageNumber]);

  const handleSubmitPageChange = useCallback(
    (page: number | null) => {
      let newPage = !page || page < 1 ? 1 : page;

      if (newPage > totalPages) {
        newPage = totalPages;
      }

      setSavedPageNumber(newPage);

      if (newPage === pageNumber) {
        return;
      }

      return onChange(newPage);
    },
    [onChange, totalPages, pageNumber]
  );

  const handlePageChange = useCallback<InputTypes['onChange']>(
    e => setSavedPageNumber(isBlank(e.target.value) ? null : Number(e.target.value)),
    []
  );

  const handleKeyPress = useCallback<InputTypes['onKeyPress']>(
    e => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        e.preventDefault();
        handleSubmitPageChange(savedPageNumber);
      }
    },
    [handleSubmitPageChange, savedPageNumber]
  );

  const handleBlur = useCallback<InputTypes['onBlur']>(
    () => handleSubmitPageChange(savedPageNumber),
    [handleSubmitPageChange, savedPageNumber]
  );

  return (
    <ToolbarSection
      topRow={
        <>
          <PageInput
            disabled={isDisabled}
            value={savedPageNumber == null ? '' : savedPageNumber}
            max={totalPages}
            onChange={handlePageChange}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
          />
          <PageNumberSeparator>/</PageNumberSeparator>
          <PageNumber>{totalPages}</PageNumber>
        </>
      }
      bottomRow={
        <>
          <BasicTooltip
            zIndex={misc.modalZIndex + 1}
            tooltipMessage={<FormattedMessage id="documentViewer.tooltips.prev" />}
          >
            <PdfCircleButton
              onClick={() => handleSubmitPageChange(pageNumber - 1)}
              disabled={isDisabled || pageNumber <= 1}
            >
              <IconStyled iconSize={14} as={IconArrowLeft} />
            </PdfCircleButton>
          </BasicTooltip>

          <BasicTooltip
            zIndex={misc.modalZIndex + 1}
            tooltipMessage={<FormattedMessage id="documentViewer.tooltips.next" />}
          >
            <PdfCircleButton
              onClick={() => handleSubmitPageChange(pageNumber + 1)}
              disabled={isDisabled || pageNumber >= totalPages}
            >
              <IconStyled iconSize={14} as={IconArrowRight} />
            </PdfCircleButton>
          </BasicTooltip>
        </>
      }
    />
  );
};

export default Pagination;
