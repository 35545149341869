import React from 'react';
import { FormattedMessage } from 'react-intl';
import { hideVisually } from 'polished';
import styled from 'styled-components';

import { isNotBlank } from 'helpers/utils';

const HiddenTextContainer = styled.span`
  ${hideVisually()};
`;

interface Props {
  text: string;
  isTranslated?: boolean;
}

const HiddenText = ({ text, isTranslated }: Props) => (
  <HiddenTextContainer>
    {isTranslated ? text : isNotBlank(text) && <FormattedMessage id={text} />}
  </HiddenTextContainer>
);

export default HiddenText;
