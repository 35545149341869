import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, RequestConfig } from 'api/types';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

import { clientMotivationCentreCommentsApi } from './motivationCentreComments';
import { clientMotivationCentreImagesApi } from './motivationCentreImages';
import { clientMotivationCentreImageApi } from './motivationCentreImages/motivationCentreImage';
import { clientMotivationCentreItemReorderApi } from './reorder';

export type MotivationCentreItemGetOptions = GetOptions;

export interface MotivationCentreItemPatchOptions {
  title?: string;
  description?: string;
  link?: string;
  file?: File;
  backgroundImage?: File;
  commentsIds?: number[];
}

export function clientMotivationCentreItemApi(path: string) {
  type ClientMotivationCentreImageApi = ReturnType<typeof clientMotivationCentreImageApi>;
  type ClientMotivationCentreImagesApi = ReturnType<typeof clientMotivationCentreImagesApi>;

  function motivationCentreImages(): ClientMotivationCentreImagesApi;
  function motivationCentreImages(imageId: number): ClientMotivationCentreImageApi;
  function motivationCentreImages(
    imageId?: number
  ): ClientMotivationCentreImagesApi | ClientMotivationCentreImageApi {
    const adviceRoomsApiPath = `${path}/motivation-centre-images`;

    return imageId
      ? clientMotivationCentreImageApi(`${adviceRoomsApiPath}/${imageId}`)
      : clientMotivationCentreImagesApi(adviceRoomsApiPath);
  }

  return {
    path,
    motivationCentreComments: () =>
      clientMotivationCentreCommentsApi(`${path}/motivation-centre-comments`),
    motivationCentreImages,
    reorder: () => clientMotivationCentreItemReorderApi(`${path}/reorder`),
    GET: (params?: MotivationCentreItemGetOptions, config?: RequestConfig) =>
      api.get<JsonApiResponseSingle>(path, { params, ...config }),
    PATCH: (params: MotivationCentreItemPatchOptions) =>
      api.patch<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      ),
    DELETE: () => api.delete<JsonApiResponse>(path),
  };
}
