import React from 'react';

import { useStyledTheme } from 'hooks/useStyledTheme';

import SvgIcon from './SvgIcon';

const IconQuote = (props: React.SVGAttributes<SVGSVGElement>) => {
  const theme = useStyledTheme();

  return (
    <SvgIcon viewBox="0 0 60 47" {...props}>
      <path
        d="M23.5 43.8C17.9 48.9 8.5 47.6 4 42c-5.3-6.5-6.3-18.7 2.9-29.7 4.5-5.5 10-9.6 16.4-12.4l3 4.8C17 9.2 10.2 15 9.2 25.1c4.2 0 7.6-.2 10.6.9a9.9 9.9 0 0 1 6.4 6.2c1.4 4 .7 8.2-2.8 11.5zm33 0C51 48.9 41.5 47.6 37 42c-5.2-6.5-6.3-18.7 2.9-29.7 4.5-5.5 10-9.6 16.4-12.4l3 4.8c-9.2 4.4-16 10.3-17 20.4 4.2 0 7.6-.2 10.6.9a9.9 9.9 0 0 1 6.4 6.2c1.4 4 .7 8.2-2.8 11.5z"
        fill={theme.colors.accent}
        opacity=".25"
      />
    </SvgIcon>
  );
};

export default IconQuote;
