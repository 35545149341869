import type { FormProps } from 'react-final-form';

export enum EmailTemplate {
  ExistingClient = 'existingClient',
  ProspectiveClient = 'prospectiveClient',
  ExistingClientIncClientPage = 'existingClientIncClientPage',
  ProspectiveClientIncClientPage = 'prospectiveClientIncClientPage',
}

export enum WelcomeMessageTemplate {
  ExistingAndProspectiveClient = 'existingAndProspectiveClient',
}

export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  emailMessage: string;
  welcomeMessage: string;
  welcomeVideo?: File;
  adviserId?: number;
}

export type OnSubmitForm = FormProps<FormValues>['onSubmit'];
