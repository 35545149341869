import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { first, get } from 'lodash';
import TwilioVideo, {
  LocalAudioTrack,
  LocalDataTrack,
  LocalTrack,
  LocalVideoTrack,
  RemoteTrack,
  Room,
  TwilioError,
} from 'twilio-video';
import { v4 as uuidv4 } from 'uuid';
import adapter from 'webrtc-adapter/dist/adapter_core5';

import { AUDIO_TRACK_NAME, VIDEO_TRACK_NAME } from 'api/twilio/twilioVideo';
import attendeeJoinSoundUrl from 'audio/attendeeJoin.mp3';
import attendeeLeaveSoundUrl from 'audio/attendeeLeave.mp3';
import incomingMessageSoundUrl from 'audio/incomingMessage.mp3';
import { playAudio } from 'helpers/audio';
import { convertMinToMs } from 'helpers/dates';
import { showErrorToast, showWarningToast } from 'helpers/toasts';
import { syncAdviceRoomConnectionState, syncConnectionState } from 'store/videoChat/actions';
import { useGetAdviceRoomConnectionState } from 'store/videoChat/selectors';

import { IdleTimerContext } from './IdleTimerContext';
import { MessagesContext } from './MessagesContext';
import { RoomConnectionState } from './RoomConnectionState';
import { JoinRoom, LeaveRoom, Message, RoomData, RoomType, SendMessage, Toggle } from './types';
import useAudioTracks from './useAudioTracks';
import { useSelectedAudioDevice, useSelectedVideoDevice } from './useDevices';
import useScreensharingTrack from './useScreensharingTrack';
import useVideoTracks from './useVideoTracks';
import { VideoContext } from './VideoContext';

if (adapter.browserDetails.browser === 'firefox') {
  adapter.browserShim.shimGetDisplayMedia(window, 'screen');
}

interface Props {
  children: React.ReactNode;
  userName: string;
  userEmail: string;
  userAvatar?: string;
}

export default function VideoProvider({ children, userName, userEmail, userAvatar }: Props) {
  const intl = useIntl();

  const connectionState = useGetAdviceRoomConnectionState();
  const dispatch = useDispatch();
  const idleTimerRef = useRef<IdleTimer>(null);
  const [room, setRoom] = useState<Room>();
  const [roomData, setRoomData] = useState<RoomData>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [unreadMessageCount, setUnreadMessageCount] = useState<number>(0);
  const [areMessagesVisible, setAreMessagesVisible] = useState<boolean>(false);
  const areMessagesVisibleRef = useRef<boolean>(areMessagesVisible);
  const [areMessagesEnabled, setAreMessagesEnabled] = useState<boolean>(false);
  const { isAudioEnabled, toggleAudioTracks } = useAudioTracks(room);
  const { selectedAudioDeviceId } = useSelectedAudioDevice();
  const {
    isVideoEnabled,
    toggleVideoTracks,
    isBackgroundBlurEnabled,
    enableBackgroundBlur,
    isRoomMonitorConsoleEnabled,
    roomMonitorConsole,
    isVirtualBackgroundEnabled,
    enableVirtualBackground,
    removeAllVideoEffect,
  } = useVideoTracks(room);
  const { selectedVideoDeviceId } = useSelectedVideoDevice();
  const { isScreensharingEnabled, toggleScreenTrack } = useScreensharingTrack(room);

  const toggleMessages = useCallback<Toggle>(shouldEnable => {
    setAreMessagesVisible(prevValue => (shouldEnable != null ? shouldEnable : !prevValue));
  }, []);

  useEffect(() => {
    areMessagesVisibleRef.current = areMessagesVisible;

    if (areMessagesVisible) {
      setUnreadMessageCount(0);
    }
  }, [areMessagesVisible]);

  useEffect(() => {
    if (connectionState !== RoomConnectionState.Connected) {
      setRoomData(undefined);
      setMessages([]);
      setAreMessagesVisible(false);
      setAreMessagesEnabled(false);
    }
  }, [connectionState]);

  useEffect(() => {
    dispatch(syncConnectionState(connectionState === RoomConnectionState.Connected));
  }, [connectionState, dispatch]);

  useEffect(() => {
    const listener = (event: PageTransitionEvent) => {
      if (!event.persisted) {
        room?.disconnect();
      }
    };

    setUnreadMessageCount(0);

    window.addEventListener('pagehide', listener, false);

    return () => {
      window.removeEventListener('pagehide', listener, false);
    };
  }, [room]);

  const joinRoom = useCallback<JoinRoom>(
    async (token, data, options = {}) => {
      const { onDisconnected } = options;

      try {
        dispatch(
          syncAdviceRoomConnectionState(
            RoomConnectionState.Connecting,
            data?.roomType === RoomType.AdviceRoom ? data.roomId : undefined
          )
        );

        let audioTrack: LocalAudioTrack | null = null;
        let videoTrack: LocalVideoTrack | null = null;
        const dataTrack: LocalDataTrack = new LocalDataTrack({ ordered: true });

        try {
          audioTrack = await TwilioVideo.createLocalAudioTrack({
            name: AUDIO_TRACK_NAME,
            deviceId: selectedAudioDeviceId,
          });
        } catch {
          if (!audioTrack) {
            showWarningToast('adviceRooms.adviceRoom.joiningMeetingWithoutAudioDevice');
          }
        }

        try {
          videoTrack = await TwilioVideo.createLocalVideoTrack({
            name: VIDEO_TRACK_NAME,
            deviceId: selectedVideoDeviceId,
          });
        } catch {
          if (!videoTrack) {
            showWarningToast('adviceRooms.adviceRoom.joiningMeetingWithoutVideoDevice');
          }
        }

        const joinedMeetingSound = new Audio(attendeeJoinSoundUrl);
        const leftMeetingSound = new Audio(attendeeLeaveSoundUrl);
        const incomingMessageSound = new Audio(incomingMessageSoundUrl);

        const connectedRoom = await TwilioVideo.connect(token, {
          preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
          audio: false,
          video: false,
          tracks: [audioTrack, videoTrack, dataTrack].filter(Boolean as unknown as ExcludeFalsy),
          dominantSpeaker: true,
          insights: false,
          networkQuality: {
            local: 1,
            remote: 1,
          },
          bandwidthProfile: {
            video: {
              mode: 'grid',
              dominantSpeakerPriority: 'high',
              trackSwitchOffMode: 'predicted',
            },
          },
        });
        setRoom(connectedRoom);
        setRoomData(data);
        dispatch(
          syncAdviceRoomConnectionState(
            RoomConnectionState.Connected,
            data?.roomType === RoomType.AdviceRoom ? data.roomId : undefined
          )
        );

        playAudio(joinedMeetingSound);

        connectedRoom.on('disconnected', (disconnectedRoom: Room, error?: TwilioError) => {
          if (error?.code === 53205) {
            showWarningToast('adviceRooms.adviceRoom.duplicatedIdentityWarning');
          }

          setRoom(undefined);
          dispatch(syncAdviceRoomConnectionState(RoomConnectionState.Disconnected, undefined));

          disconnectedRoom.localParticipant.audioTracks.forEach(({ track }) => track.stop());
          disconnectedRoom.localParticipant.videoTracks.forEach(({ track }) => track.stop());

          playAudio(leftMeetingSound);

          if (onDisconnected) {
            onDisconnected();
          }
        });

        connectedRoom.on('participantConnected', () => {
          playAudio(joinedMeetingSound);
        });

        connectedRoom.on('participantDisconnected', () => {
          playAudio(leftMeetingSound);
        });

        connectedRoom.on('trackSubscribed', (track: RemoteTrack) => {
          if (track.kind === 'data') {
            track.on('message', (message: string) => {
              const parsedMessage = JSON.parse(message);
              setMessages(prevMessages => [...prevMessages, parsedMessage]);

              if (parsedMessage.senderEmail !== userEmail && !areMessagesVisibleRef.current) {
                setUnreadMessageCount(prevCount => prevCount + 1);
                playAudio(incomingMessageSound);
              }
            });
          }
        });

        connectedRoom.localParticipant.on('trackPublished', (track: LocalTrack) => {
          if (track.kind === 'data') {
            setAreMessagesEnabled(true);
          }
        });
      } catch (error) {
        if (get(error, 'code') === 53105) {
          showErrorToast(
            intl.formatMessage({
              id: 'video.errors.maxParticipantsExceededError',
            }),
            { isTranslated: true }
          );
        }

        // Using name as codes are legacy
        // https://developer.mozilla.org/en-US/docs/Web/API/DOMException
        if (get(error, 'name') === 'NotSupportedError') {
          showErrorToast(
            intl.formatMessage({
              id: 'adviceRooms.adviceRoom.cannotConnectSecure',
            }),
            { isTranslated: true }
          );
        }

        window.Rollbar?.error('Error occurred while connecting to CMR', data, error);

        setRoom(undefined);
        dispatch(syncAdviceRoomConnectionState(RoomConnectionState.Disconnected, undefined));
      }
    },
    [dispatch, intl, selectedAudioDeviceId, selectedVideoDeviceId, userEmail]
  );

  const leaveRoom = useCallback<LeaveRoom>(() => {
    room?.disconnect();
  }, [room]);

  const sendMessage = useCallback<SendMessage>(
    messageText => {
      const dataTrack = first(Array.from(room?.localParticipant.dataTracks.values() || []));
      if (dataTrack) {
        const message = {
          id: uuidv4(),
          text: messageText,
          date: new Date().toISOString(),
          senderEmail: userEmail,
          senderName: userName,
          senderAvatar: userAvatar,
        };
        dataTrack.track.send(JSON.stringify(message));
        setMessages(prevMessages => [...prevMessages, message]);
      }
    },
    [room, userEmail, userName, userAvatar]
  );

  const videoContextValue = useMemo(
    () => ({
      room,
      roomData,
      connectionState,
      joinRoom,
      leaveRoom,
      toggleAudioTracks,
      toggleVideoTracks,
      enableBackgroundBlur,
      toggleScreenTrack,
      roomMonitorConsole,
      removeAllVideoEffect,
      isAudioEnabled,
      isVideoEnabled,
      isBackgroundBlurEnabled,
      isScreensharingEnabled,
      isRoomMonitorConsoleEnabled,
      enableVirtualBackground,
      isVirtualBackgroundEnabled,
    }),
    [
      room,
      roomData,
      connectionState,
      joinRoom,
      leaveRoom,
      toggleAudioTracks,
      toggleVideoTracks,
      enableBackgroundBlur,
      toggleScreenTrack,
      roomMonitorConsole,
      removeAllVideoEffect,
      isAudioEnabled,
      isVideoEnabled,
      isBackgroundBlurEnabled,
      isScreensharingEnabled,
      isRoomMonitorConsoleEnabled,
      enableVirtualBackground,
      isVirtualBackgroundEnabled,
    ]
  );

  const messagesContextValue = useMemo(
    () => ({
      sendMessage,
      toggleMessages,
      areMessagesVisible,
      areMessagesEnabled,
      messages,
      unreadMessageCount,
      userEmail,
    }),
    [
      areMessagesEnabled,
      areMessagesVisible,
      messages,
      sendMessage,
      toggleMessages,
      unreadMessageCount,
      userEmail,
    ]
  );

  return (
    <VideoContext.Provider value={videoContextValue}>
      {room && (
        <IdleTimer
          ref={idleTimerRef}
          element={document}
          onIdle={leaveRoom}
          debounce={250}
          timeout={convertMinToMs(60)}
        />
      )}

      <MessagesContext.Provider value={messagesContextValue}>
        <IdleTimerContext.Provider value={idleTimerRef}>{children}</IdleTimerContext.Provider>
      </MessagesContext.Provider>
    </VideoContext.Provider>
  );
}
