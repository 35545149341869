import { useEffect } from 'react';
import { clearInterval, setInterval } from 'safe-timers';

import { users } from 'api';
import { getSessionTimeout } from 'helpers/sessionTimeout';
import { useGetCurrentUser } from 'store/users/selectors';

import { RoomConnectionState } from './RoomConnectionState';
import { useVideoContext } from './VideoContext';

export function useSessionExtender() {
  const { connectionState } = useVideoContext();
  const currentUser = useGetCurrentUser();
  const isEnabled = connectionState === RoomConnectionState.Connected && Boolean(currentUser);

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    const sessionIntervalTimer = setInterval(() => {
      users().me().GET();
    }, (getSessionTimeout() / 2) * 1000 * 60);

    return () => {
      clearInterval(sessionIntervalTimer);
    };
  }, [isEnabled]);
}
