import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { FilterOptions, GetOptions, PaginationOptions, RequestConfig } from 'api/types';
import type { IdentityVerificationFilters } from 'constants/identityVerificationFilters';
import { mapFormData } from 'helpers/formData';

export interface IdentityVerificationPostOptions {
  authority: string;
  coverNote: string;
}

export interface IdentityVerificationPathOptions {
  clientId: number;
  requestId: number;
}

export type IdentityVerificationsGetOptions = GetOptions &
  PaginationOptions &
  FilterOptions<IdentityVerificationFilters>;

export function clientIdentityVerificationsApi(path: string) {
  return {
    path,
    GET: (params: IdentityVerificationsGetOptions = {}, config?: RequestConfig) => {
      const { pageSize, pageNumber, ...rest } = params;

      return api.get<JsonApiResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...rest,
        },
        ...config,
      });
    },
    POST: (props: IdentityVerificationPostOptions) =>
      api.post<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(props),
        },
      }),
  };
}
