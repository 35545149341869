import type { FinancialPlanningTopic } from 'constants/financialPlanningTopics';

import type { Client } from './client';
import type { CurrencyValue } from './currency';
import type { Document } from './document';
import type { DocumentPack } from './documentPack';

export enum CaseState {
  Draft = 'draft',
  Building = 'building',
  Requested = 'requested',
  Submitted = 'submitted',
  ReviewingPack = 'reviewing_pack',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Confirmed = 'confirmed',
  Abandoned = 'abandoned',
  Archived = 'archived',
  Cancelled = 'cancelled',
  Completed = 'completed',
}

export enum CaseType {
  OnlineAdviceRequest = 'online_advice_request',
  RecommendationPackCase = 'recommendation_pack_case',
  MeetingRequestCase = 'meeting_request',
  ReviewQuestionnaireCase = 'review_questionnaire_case',
  DocumentPackCase = 'document_pack_case',
}

export enum Tag {
  Banking = 'banking',
  Charges = 'charges',
  Communication = 'communication',
  FinancialJourney = 'financial_journey',
  IdentificationAndVerification = 'identification_and_verification',
  IncomeWithdrawal = 'income_withdrawal',
  Investment = 'investment',
  Meeting = 'meeting',
  MeetingSummary = 'meeting_summary',
  Mortgages = 'mortgages',
  Objectives = 'objectives',
  Other = 'other',
  Protection = 'protection',
  Retirement = 'retirement',
  ReviewMeeting = 'review_meeting',
  Risk = 'risk',
  TrustsAndEstate = 'trusts_and_estate',
}

export interface CaseWithInvestmentDetails {
  initialInvestment: CurrencyValue | null;
  monthlyContribution: CurrencyValue | null;
  investmentDuration: number | null;
}

export interface CaseWithDocumentPack {
  documentsPackId?: number;
  documentsPack?: DocumentPack;
}

interface ClientCaseBase<T> {
  id: number;
  state: CaseState;
  type: T;
  createdAt: string;
  updatedAt: string;
  documents: Document[];
  name: string;
  clientId: number;
  client?: Client;
  image?: string;
  privateNote?: string;
  lastSentPrivateNote?: string;
  financialAuditTaxYear: string;
  financialAuditTitle: string;
  stateUpdatedAt?: string | null;
  acceptedAt?: string | null;
  confirmedAt?: string | null;
  rejectedAt?: string | null;
  reviewedAt?: string | null;
  submittedAt?: string | null;
}

export interface OnlineAdviceRequestCase
  extends ClientCaseBase<CaseType.OnlineAdviceRequest>,
    CaseWithInvestmentDetails {
  investmentNote?: string;
}

export interface MeetingRequestCase
  extends ClientCaseBase<CaseType.MeetingRequestCase>,
    CaseWithInvestmentDetails {
  topics: FinancialPlanningTopic[];
  metadata?: {
    clientAdvice?: string;
    highInterestDebt?: boolean;
    cashReserve?: boolean;
  };
}

export interface RecommendationPackCase
  extends ClientCaseBase<CaseType.RecommendationPackCase>,
    CaseWithDocumentPack {
  addenda?: Document[];
  coverNote?: string;
}

export interface ReviewQuestionnaireCase extends ClientCaseBase<CaseType.ReviewQuestionnaireCase> {
  isCurrentTaxYear?: boolean;
  coverNote?: string;
  investmentNote?: string;
  metadata?: {
    includeFinancialPlanningGoals?: boolean;
    includePersonalFinancialAudit?: boolean;
    includePersonalFinancialPlanningHealthCheck?: boolean;
    includeFactFindRevision?: boolean;
  };
}

export interface DocumentPackCase
  extends ClientCaseBase<CaseType.DocumentPackCase>,
    CaseWithDocumentPack {
  coverNote?: string;
  tags: Tag[];
}

export type ClientCase =
  | OnlineAdviceRequestCase
  | MeetingRequestCase
  | RecommendationPackCase
  | ReviewQuestionnaireCase
  | DocumentPackCase;
