import React from 'react';

import SvgIcon from '../SvgIcon';

const IconVideoCamera = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 24 16" {...props}>
    <path
      d="M23.97 2.92v10.12a1 1 0 0 1-1.5.88L18.3 11.5v.7a3.75 3.75 0 0 1-3.71 3.77H3.72A3.75 3.75 0 0 1 0 12.18v-8.4A3.75 3.75 0 0 1 3.72.01H14.6a3.75 3.75 0 0 1 3.72 3.77v.7l4.15-2.44a1 1 0 0 1 1.5.88z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default IconVideoCamera;
