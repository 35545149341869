// Based on https://www.twilio.com/docs/video/build-js-video-application-recommendations-and-best-practices#testing-the-microphone-and-camera

import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { rem } from 'polished';
import styled from 'styled-components';
import type { LocalAudioTrack } from 'twilio-video';

import { createAudioTrack } from 'api/twilio/twilioVideo';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  margin-right: ${rem(10)};
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fontSizes.dbSmall};
  color: ${({ theme }) => theme.colors.textLight};
`;

const Meter = styled.div`
  position: relative;
  height: ${rem(12)};
  width: ${rem(100)};
  background-color: ${({ theme }) => theme.colors.greyLighten};
`;

const MeterBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: attr(data-value);
  background-color: ${({ theme }) => theme.colors.accent};
`;

interface Props {
  deviceId: string | undefined;
}

export function AudioSignal({ deviceId }: Props) {
  const [stream, setMediaStream] = useState<MediaStream>();
  const [value, setValue] = useState<number>(0);
  const track = useRef<LocalAudioTrack | null>(null);

  useEffect(() => {
    let canceled = false;

    const createTrack = async () => {
      const newTrack = await createAudioTrack({ deviceId });

      if (!canceled) {
        track.current = newTrack;
        setMediaStream(new MediaStream([newTrack.mediaStreamTrack]));
      }
    };

    createTrack();

    return () => {
      canceled = true;

      if (track.current) {
        track.current.stop();
        track.current = null;
      }
    };
  }, [deviceId]);

  useEffect(() => {
    if (!stream) {
      return;
    }

    // @ts-ignore webkitAudioContext is not known
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioContext = new AudioContext();

    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 1024;
    analyser.smoothingTimeConstant = 0.5;

    // Connect the LocalAudioTrack's media source to the analyser
    const source = audioContext.createMediaStreamSource(stream);
    source.connect(analyser);

    const samples = new Uint8Array(analyser.frequencyBinCount);

    // Periodically calculate the audio level from the captured samples
    let requestId = requestAnimationFrame(function checkLevel() {
      analyser.getByteFrequencyData(samples);

      const sum = samples.reduce((memo, sample) => memo + sample * sample, 0);
      const rms = Math.sqrt(sum / samples.length);
      const log2Rms = rms && Math.log2(rms);

      // Audio level ranges from 0 (silence) to 100 (loudest)
      const newLevel = Math.ceil((10 * log2Rms) / 8) * 10;
      setValue(newLevel);

      // Continue calculating the level only if the audio track is live
      if (track.current?.mediaStreamTrack.readyState === 'live') {
        requestId = requestAnimationFrame(checkLevel);
      } else {
        requestId = requestAnimationFrame(() => setValue(0));
      }
    });

    return () => {
      cancelAnimationFrame(requestId);
      audioContext.close();
    };
  }, [stream]);

  return (
    <Wrapper>
      <Label>
        <FormattedMessage id="video.audioSignal" />
      </Label>

      <div>
        <Meter>
          <MeterBar style={{ width: `${value}%` }} />
        </Meter>
      </div>
    </Wrapper>
  );
}
