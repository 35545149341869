import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, RequestConfig } from 'api/types';

import { adviserApi } from './adviser';
import { advisersExportReportApi } from './exportReport';
import { advisersPreferredApi } from './preferred';

const apiPath = '/api/v1/advisers';

export type AdvisersGetOptions = GetOptions;

function advisersApi(path: string) {
  return {
    path,
    exportReport: () => advisersExportReportApi(`${path}/export-report`),
    preferred: () => advisersPreferredApi(`${path}/preferred`),
    GET: (params?: AdvisersGetOptions, config?: RequestConfig) =>
      api.get<JsonApiResponse>(path, { params, ...config }),
  };
}

type AdviserApi = ReturnType<typeof adviserApi>;
type AdvisersApi = ReturnType<typeof advisersApi>;

function advisers(): AdvisersApi;
function advisers(adviserId: number): AdviserApi;
function advisers(adviserId?: number): AdvisersApi | AdviserApi {
  return adviserId ? adviserApi(`${apiPath}/${adviserId}`) : advisersApi(apiPath);
}

export { advisers };
