import React, { useMemo } from 'react';
import { Field as FieldBase, Form, FormProps } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { BrowserInfo, detect } from 'detect-browser';
import { isEmpty } from 'lodash';

import { useRwdQuery } from 'helpers/styles/useRwdQuery';
import { useGetAdviceRoomConnectionState } from 'store/videoChat/selectors';
import { DropdownWidth } from 'components/Dropdown/BaseDropdown';
import FormActionButtons from 'components/FormActionButtons/FormActionButtons';
import { Field, Label } from 'components/Forms';
import GenericLabel from 'components/GenericLabel/GenericLabel';
import GenericLoader from 'components/GenericLoader/GenericLoader';
import BasicModal from 'components/GenericModal/BasicModal';
import GenericText from 'components/GenericText/GenericText';
import { GenericTitle } from 'components/GenericTitle/GenericTitle';
import { Column, FieldSpacer, Grid, Row } from 'components/Layout';
import Margin from 'components/Margin/Margin';
import {
  useCameraPermissions,
  useMicrophonePermissions,
} from 'components/TestSetupTwilio/components/useDevicesPermissions';
import { RoomConnectionState } from 'components/VideoChat/RoomConnectionState';

import { useAudioDevices, useAudioOutputDevices, useVideoDevices } from '../../useDevices';
import VideoChatPermissionMessage from '../VideoChatPermissionMessage';
import { AudioSignal } from './AudioSignal';
import { DevicesDropdown } from './DevicesDropdown';
import VideoTrackPreview from './VideoTrackPreview';

export interface SettingsFormValues {
  audioDeviceId: string | undefined;
  audioOutputDeviceId: string | undefined;
  videoDeviceId: string | undefined;
}

interface Props {
  onClose: () => void;
  onSubmit: FormProps<SettingsFormValues>['onSubmit'];
  initialValues: SettingsFormValues;
}

function SettingsModal({ onClose, onSubmit, initialValues }: Props) {
  const { isMobile } = useRwdQuery();
  const browser = useMemo(() => detect() as BrowserInfo, []);
  const { videoDevices, isFetchingVideoDevices } = useVideoDevices();
  const { audioOutputDevices, isFetchingAudioOutputDevices } = useAudioOutputDevices();
  const { audioDevices, isFetchingAudioDevices } = useAudioDevices();
  const connectionState = useGetAdviceRoomConnectionState();

  const isLoading =
    isFetchingVideoDevices || isFetchingAudioDevices || isFetchingAudioOutputDevices;

  const { microphonePermissions } = useMicrophonePermissions();
  const { cameraPermissions } = useCameraPermissions();

  return (
    <BasicModal onClose={onClose} isFullScreen={isMobile} shouldCloseOnOverlayClick={false} isOpen>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting }) => (
          <BasicModal.Form onSubmit={handleSubmit}>
            <BasicModal.Title>
              <GenericTitle>
                <FormattedMessage id="video.settings.modalTitle" />
              </GenericTitle>
            </BasicModal.Title>

            <BasicModal.Content>
              <GenericLoader isVisible={isLoading} data-test="edit-modal-loader" />

              {!isLoading && (
                <Grid data-test="edit-modal-form-grid">
                  <Row>
                    <Column usUp={12} mdUp={6}>
                      {!isEmpty(audioOutputDevices) && (
                        <>
                          <Field<string | undefined>
                            name="audioOutputDeviceId"
                            renderLabel={({ input }) => (
                              <Label text="video.settings.audioOutput" elementId={input.id} />
                            )}
                            renderControl={({ input, meta }) => (
                              <DevicesDropdown
                                {...input}
                                hasError={meta.showError}
                                devices={audioOutputDevices}
                                dropdownWidth={DropdownWidth.Full}
                              />
                            )}
                          />

                          <FieldSpacer />
                        </>
                      )}
                      <Field<string | undefined>
                        name="audioDeviceId"
                        renderLabel={({ input }) => (
                          <Label text="video.settings.audioInput" elementId={input.id} />
                        )}
                        renderControl={({ input, meta }) => (
                          <>
                            <DevicesDropdown
                              {...input}
                              hasError={meta.showError}
                              devices={audioDevices}
                              dropdownWidth={DropdownWidth.Full}
                            />

                            {/* Do not show preview on Safari as it can only
                                handle single stream track at a time
                                https://webrtchacks.com/guide-to-safari-webrtc
                                https://bugs.webkit.org/show_bug.cgi?id=179363 */}
                            {!['ios', 'safari'].includes(browser.name) && (
                              <Margin top={10}>
                                <AudioSignal deviceId={input.value} />
                              </Margin>
                            )}

                            <Margin top={10}>
                              <VideoChatPermissionMessage
                                microphone={microphonePermissions}
                                isTestSetup
                              />
                            </Margin>
                          </>
                        )}
                      />
                      <FieldSpacer />
                      <Field<string | undefined>
                        name="videoDeviceId"
                        renderLabel={({ input }) => (
                          <Label text="video.settings.videoInput" elementId={input.id} />
                        )}
                        renderControl={({ input, meta }) => (
                          <>
                            <DevicesDropdown
                              {...input}
                              hasError={meta.showError}
                              devices={videoDevices}
                              dropdownWidth={DropdownWidth.Full}
                            />

                            <Margin top={10}>
                              <VideoChatPermissionMessage camera={cameraPermissions} isTestSetup />
                            </Margin>
                          </>
                        )}
                      />{' '}
                    </Column>

                    <Column usUp={12} mdUp={6}>
                      <Margin top={{ smDown: 20 }} />

                      <GenericLabel
                        text={<FormattedMessage id="shared.preview" />}
                        isSmall
                        isUpperCase
                      />

                      {/* Do not show preview on Safari as it can only
                        handle single stream track at a time
                        https://webrtchacks.com/guide-to-safari-webrtc
                        https://bugs.webkit.org/show_bug.cgi?id=179363 */}
                      {connectionState !== RoomConnectionState.Disconnected &&
                      ['ios', 'safari'].includes(browser.name) ? (
                        <GenericText>
                          <FormattedMessage id="video.settings.previewNotSupported" />
                        </GenericText>
                      ) : (
                        <FieldBase name="videoDeviceId">
                          {({ input }) => <VideoTrackPreview deviceId={input.value} />}
                        </FieldBase>
                      )}
                    </Column>
                  </Row>
                </Grid>
              )}
            </BasicModal.Content>

            <BasicModal.Footer>
              <FormActionButtons
                submitBtnContent={<FormattedMessage id="accountSettings.saveButton" />}
                labelBtnContent={<FormattedMessage id="shared.cancel" />}
                onLabelBtnClick={onClose}
                submitDisabled={submitting || isLoading}
                hasNoMarginTop
              />
            </BasicModal.Footer>
          </BasicModal.Form>
        )}
      />
    </BasicModal>
  );
}

export default SettingsModal;
