import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { FeatureTileName } from 'helpers/features';
import { mapFormData } from 'helpers/formData';

export interface PracticeSettingsFeatureTilesResetPatchOptions {
  name: FeatureTileName;
}

export function practiceSettingsFeatureTilesResetApi(path: string) {
  return {
    path,
    PATCH: (values: PracticeSettingsFeatureTilesResetPatchOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(values),
        },
      }),
  };
}
