import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { cssTransition, ToastContainer } from 'react-toastify';
import { rem } from 'polished';
import styled, { keyframes } from 'styled-components';

import { toast, toastText } from './classNames';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
  }
`;

const fadeInClassName = 'fade-in';
const fadeOutClassName = 'fade-out';
const toastTransitionTime = 500;

const StyledToastContainer = styled(ToastContainer).attrs({ suppressClassNameWarning: true })`
  && {
    z-index: 999999999;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: auto;
    max-width: ${rem(400)};
    padding: 0;
  }

  .${toast} {
    min-height: auto;
    max-height: none;
    margin-bottom: ${rem(10)};
    padding: ${rem(2)} ${rem(8)} ${rem(3)};
    font-family: ${({ theme }) => theme.fontFamilies.main};
    font-size: ${({ theme }) => theme.fontSizes.dbSmall};
    font-weight: ${({ theme }) => theme.fontWeights.mainMedium};
    border-radius: 0;

    &--info {
      background-color: ${({ theme }) => theme.colors.toastInfoBg};
      color: ${({ theme }) => theme.colors.textDark};
    }

    &--success {
      background-color: ${({ theme }) => theme.colors.toastSuccessBg};
    }

    &--error {
      background-color: ${({ theme }) => theme.colors.toastErrorBg};
    }

    .${toastText} {
      flex: 1 1 auto;
      line-height: 1.3;
    }
  }

  .${fadeInClassName} {
    animation-name: ${fadeIn};
    animation-duration: ${toastTransitionTime}ms;
  }

  .${fadeOutClassName} {
    animation-name: ${fadeOut};
  }
`;

const Slide = cssTransition({
  enter: fadeInClassName,
  exit: fadeOutClassName,
  duration: toastTransitionTime,
});

const ToastContainerWrapped = () => <StyledToastContainer transition={Slide} hideProgressBar />;

export default ToastContainerWrapped;
