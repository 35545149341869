import { userMessagesMarkAllAsSeenApi } from './markAllAsSeen';
import { userMessagesUnreadApi } from './unread';

export function userMessagesApi(path: string) {
  return {
    path,
    markAllAsSeen: () => userMessagesMarkAllAsSeenApi(`${path}/mark-all-as-seen`),
    unread: () => userMessagesUnreadApi(`${path}/unread`),
  };
}
