import api from 'api/apiInstance';
import type { FactFind, FactFindData } from 'components/FactFind/types';

import { clientCaseFactFindLetterOfAuthorityApi } from './letterOfAuthority';
import { clientCaseFactFindPartnerApi } from './partner';
import { clientCaseFactFindPrintApi } from './print';

interface FactFindPatchOptions {
  fact_find?: Partial<FactFind>;
}

export interface ClientCaseFactFindGetResponse {
  data: FactFindData;
}

export type ClientCaseFactFindPatchOptions = FactFindPatchOptions;
export type ClientCaseFactFindPatchResponse = FactFindData;

export function clientCaseFactFindApi(path: string) {
  return {
    path,
    letterOfAuthority: () => clientCaseFactFindLetterOfAuthorityApi(`${path}/letter-of-authority`),
    partner: () => clientCaseFactFindPartnerApi(`${path}/partner`),
    print: () => clientCaseFactFindPrintApi(`${path}/print`),
    GET: () => api.get<FactFindData>(path),
    PATCH: (params: ClientCaseFactFindPatchOptions) =>
      api.patch<ClientCaseFactFindPatchResponse>(path, params),
  };
}
