import { documentApi } from './document';

const apiPath = '/api/v1/documents';

function documentsApi(path: string) {
  return {
    path,
  };
}

type DocumentApi = ReturnType<typeof documentApi>;
type DocumentsApi = ReturnType<typeof documentsApi>;

function documents(): DocumentsApi;
function documents(documentId: number): DocumentApi;
function documents(documentId?: number): DocumentsApi | DocumentApi {
  return documentId ? documentApi(`${apiPath}/${documentId}`) : documentsApi(apiPath);
}

export { documents };
