import React from 'react';

import SvgIcon from './SvgIcon';

const IconZoomFit = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 129 129" {...props}>
    <path
      fill="currentColor"
      d="M51.6 96.7c11 0 21-3.9 28.8-10.5l35 35c.8.8 1.8 1.2 2.9 1.2s2.1-.4 2.9-1.2a4.1 4.1 0 0 0 0-5.8l-35-35c6.5-7.8 10.5-17.9 10.5-28.8 0-24.9-20.2-45.1-45.1-45.1-24.8 0-45.1 20.3-45.1 45.1 0 24.9 20.3 45.1 45.1 45.1zm0-82c20.4 0 36.9 16.6 36.9 36.9C88.5 72 72 88.5 51.6 88.5S14.7 71.9 14.7 51.6a37 37 0 0 1 36.9-36.9z"
    />
    <rect
      x="30"
      y="30"
      width="43"
      height="43"
      rx="4"
      fill="none"
      stroke="currentColor"
      strokeWidth="8"
    />
  </SvgIcon>
);

export default IconZoomFit;
