import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions } from 'api/types';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

export interface AdviceRoomDocumentsPostOptions {
  file: File;
  name: string;
  signatureRequired: boolean;
  shouldSendSignatureReminder?: boolean;
  autoEmailEnabled?: boolean;
  concealedFromClient?: boolean;
  noteForClient?: string;
}

export function adviceRoomDocumentsApi(path: string) {
  return {
    path,
    GET: (params?: GetOptions) => api.get<JsonApiResponse>(path, { params }),
    POST: (params: AdviceRoomDocumentsPostOptions) =>
      api.post<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      ),
  };
}
