import React from 'react';
import { size } from 'polished';
import styled from 'styled-components';

const Dots = styled.span`
  ${size('100%')};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

const Dot = styled.span`
  ${size('4px')};
  display: block;
  background-color: ${({ theme }) => theme.colors.main};
  border-radius: 2px;
`;

const DotsGroup = () => (
  <Dots>
    <Dot />
    <Dot />
    <Dot />
  </Dots>
);

export default DotsGroup;
