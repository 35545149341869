import TwilioVideo from 'twilio-video';

export function isVideoSupported() {
  return Boolean(TwilioVideo.isSupported);
}

export function isScreensharingSupported() {
  return Boolean(navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia);
}

export function areDevicesSupported() {
  return Boolean(navigator?.mediaDevices?.enumerateDevices);
}

export function areDevicesEventsSupported() {
  return Boolean(navigator?.mediaDevices?.addEventListener);
}
