import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import EntityPath from 'constants/entitiesPaths';
import { User, UserRoleName } from 'types/entities/user';

import { createEntitySelectors } from '../entities/selectors';
import type { AppState } from '../types';

const { getAll, getById } = createEntitySelectors<User>(EntityPath.Users);
export { getAll as getUsers, getById as getUser };

export const getCurrentUserId = (state: AppState) => state?.currentUser?.id || undefined;
export const useGetCurrentUserId = () => useSelector(getCurrentUserId);

export const getCurrentUser = (state: AppState) => getById(state, getCurrentUserId(state));

// to be used only if we're sure the user exists
// TODO: decide to remove or change the name to sth more meaningful
export const getCurrentUserUnsafe = (state: AppState) => {
  const currentUser = getCurrentUser(state);

  if (!currentUser) {
    throw new Error('currentUser cannot be determined');
  }

  return currentUser;
};

export const useGetCurrentUserUnsafe = () => useSelector(getCurrentUserUnsafe);
export const useGetCurrentUser = () => useSelector(getCurrentUser);

export const getCurrentUserRoleName = createSelector(
  getCurrentUser,
  currentUser => currentUser?.roleName
);

export const useGetCurrentUserRoleName = () => useSelector(getCurrentUserRoleName);

export const getIsCurrentUserAClient = createSelector(
  getCurrentUserRoleName,
  currentUserRoleName => currentUserRoleName === UserRoleName.Client
);

export const useGetIsCurrentUserAClient = () => useSelector(getIsCurrentUserAClient);

export const getIsCurrentUserAnAdviser = createSelector(
  getCurrentUserRoleName,
  currentUserRoleName => currentUserRoleName === UserRoleName.Adviser
);

export const useGetIsCurrentUserAnAdviser = () => useSelector(getIsCurrentUserAnAdviser);

export const getIsCurrentUserAPracticeAdmin = createSelector(
  getCurrentUserRoleName,
  currentUserRoleName => currentUserRoleName === UserRoleName.PracticeAdmin
);

export const useGetIsCurrentUserAPracticeAdmin = () => useSelector(getIsCurrentUserAPracticeAdmin);

export const getIsCurrentUserAPracticeDirector = createSelector(
  getCurrentUserRoleName,
  currentUserRoleName => currentUserRoleName === UserRoleName.PracticeDirector
);

export const useGetIsCurrentUserAPracticeDirector = () =>
  useSelector(getIsCurrentUserAPracticeDirector);
