import { UserRoleName } from 'types/entities/user';

import type { ParamArray, ReactPath, Route } from './types';

export const Root = '/';

function replaceSegments(path: string, args: (number | string)[] = []) {
  const argsCopy = [...args];

  if (!args.length) return path;

  return path
    .split(/\//)
    .reverse()
    .map(s => {
      const hasHash = s.slice(-1)[0] === '#';

      if (s.indexOf(':') === 0) {
        return argsCopy.pop() + (hasHash ? '#' : '');
      }

      return s;
    })
    .reverse()
    .join('/');
}

export function createReactPath(path: string): ReactPath {
  // eslint-disable-next-line no-new-wrappers
  const enhancedPath = new String(path);
  // @ts-ignore artificial property added for backward compatibility
  enhancedPath.isReactPath = true;
  return enhancedPath as ReactPath;
}

export function isReactPath(path: ReactPath | string): path is ReactPath {
  return Boolean(Object.prototype.hasOwnProperty.call(path, 'isReactPath'));
}

export function route<P extends ParamArray | undefined = undefined>(routerPath: string): Route<P> {
  return {
    path(...args: P extends undefined ? [] : P) {
      return replaceSegments(routerPath, [...args]);
    },
    reactPath(...args: P extends undefined ? [] : P) {
      // TODO: this function should be removed after the migration is over
      return createReactPath(replaceSegments(routerPath, [...args]));
    },
    routerPath() {
      return routerPath;
    },
  };
}

export function prefixRoute(userRole: UserRoleName, routeToPrefix: string) {
  switch (userRole) {
    case UserRoleName.Client:
      return `/client#${routeToPrefix}`;
    case UserRoleName.Adviser:
      return `/adviser#${routeToPrefix}`;
    case UserRoleName.PracticeAdmin:
      return `/admin#${routeToPrefix}`;
    case UserRoleName.PracticeDirector:
      return `/director#${routeToPrefix}`;
    case UserRoleName.SuperAdmin:
      return `/super_administrator#${routeToPrefix}`;
    case UserRoleName.SupportAdmin:
      return `/super_administrator#${routeToPrefix}`;
    default:
      throw new Error('userRole not supported');
  }
}

export const buildPath = (matchUrl: string, path: string | number) => {
  const url = `${matchUrl}/${path}`;
  return url.replace('//', '/');
};
