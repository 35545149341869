import React from 'react';
import { FormattedMessage } from 'react-intl';
import { rem, size } from 'polished';
import styled from 'styled-components';

import IconLock from 'components/Icons/IconLock';
import { PrivacyPolicyLink } from 'components/PrivacyPolicy/PrivacyPolicyLink';

const Footer = styled.div`
  position: relative;
  margin-top: ${rem(40)};
  font-size: ${({ theme }) => theme.fontSizes.dbSmall};
  line-height: 1.55;
  color: ${({ theme }) => theme.colors.textLight};

  a {
    color: ${({ theme }) => theme.colors.textDark};
  }

  ul {
    margin-top: ${rem(20)};
  }

  strong {
    font-weight: ${({ theme }) => theme.fontWeights.mainMedium};
  }
`;

const Icon = styled(IconLock)`
  ${size(rem(17), rem(14))};
  display: inline-block;
  margin-top: ${rem(1)};
  margin-right: ${rem(8)};
  vertical-align: top;
`;

const DashboardSecurityLabel = () => (
  <Footer>
    <Icon />
    <FormattedMessage
      id="onboarding.stepBegin.footer"
      values={{
        policy: (text: string) => (
          <strong>
            <PrivacyPolicyLink>{text}</PrivacyPolicyLink>
          </strong>
        ),
      }}
    />
  </Footer>
);

export default DashboardSecurityLabel;
