import { omit } from 'lodash';
import type { AnyAction } from 'redux';

import {
  DELETE_CLIENT_CASE_DOCUMENT,
  DELETE_CLIENT_CASE_DOCUMENT_FAIL,
  DELETE_CLIENT_CASE_DOCUMENT_SUCCESS,
} from './actionTypes';
import type { DocumentsActionTypes, DocumentsState } from './types';

export const initialState: DocumentsState = {
  isRemovingById: {},
};

export default (state = initialState, incomingAction: AnyAction): DocumentsState => {
  const action = incomingAction as DocumentsActionTypes;

  switch (action.type) {
    case DELETE_CLIENT_CASE_DOCUMENT:
      return {
        ...state,
        isRemovingById: {
          ...state.isRemovingById,
          [action.payload.documentId]: true,
        },
      };
    case DELETE_CLIENT_CASE_DOCUMENT_SUCCESS:
    case DELETE_CLIENT_CASE_DOCUMENT_FAIL:
      return {
        ...state,
        isRemovingById: omit(state.isRemovingById, action.payload.documentId),
      };
    default:
      return state;
  }
};
