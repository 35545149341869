import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';
import type { CaseState } from 'types/entities/clientCase';

import { clientCaseAcceptApi } from './accept';
import { clientCaseAddendaApi } from './addenda';
import { clientCaseAddendumApi } from './addenda/addendum';
import { clientCaseAuditTrailApi } from './auditTrail';
import { clientCaseCancelApi } from './cancel';
import { clientCaseConfirmApi } from './confirm';
import { clientCaseDocumentsApi } from './documents';
import { clientCaseDocumentApi } from './documents/document';
import { clientCaseFactFindApi } from './factFind';
import { clientCaseFinancialAuditsApi } from './financialAudits';
import { clientCaseFinancialPlanningGoalsApi } from './financialPlanningGoals';
import { clientCaseFinancialPlanningGoalApi } from './financialPlanningGoals/financialPlanningGoal';
import { clientCaseFinancialPlanningHealthChecksApi } from './financialPlanningHealthChecks';
import { clientCaseIBusinessPackApi } from './iBusinessPack';
import { clientCaseProgressApi } from './progress';
import { clientCaseRejectApi } from './reject';
import { clientCaseRelationsUpdateApi } from './relationsUpdate';
import { clientCaseReviewApi } from './review';
import { clientCaseRiskAssessmentsApi } from './riskAssessments';
import { clientCaseSubmitApi } from './submit';
import { clientCaseUpdatePrivateNoteApi } from './updatePrivateNote';
import { clientCaseUpdateTagsApi } from './updateTags';

export interface ClientCasePatchOptions {
  name?: string;
  state?: CaseState;
  investmentNote?: string;
  coverNote?: string;
  termsAccepted?: boolean;
  image?: File;
  existingImageId?: number;
  initialInvestment?: number;
  initialInvestmentCurrency?: string;
  investmentDuration?: number;
  monthlyContribution?: number;
  monthlyContributionCurrency?: string;
}

export type ClientCasePatchResponse = JsonApiResponseSingle;

export function clientCaseApi(path: string) {
  type ClientCaseAddendumApi = ReturnType<typeof clientCaseAddendumApi>;
  type ClientCaseAddendaApi = ReturnType<typeof clientCaseAddendaApi>;

  function addenda(): ClientCaseAddendaApi;
  function addenda(documentId: number): ClientCaseAddendumApi;
  function addenda(documentId?: number): ClientCaseAddendumApi | ClientCaseAddendaApi {
    const documentsApiPath = `${path}/addenda`;

    return documentId
      ? clientCaseAddendumApi(`${documentsApiPath}/${documentId}`)
      : clientCaseAddendaApi(documentsApiPath);
  }

  type ClientCaseDocumentApi = ReturnType<typeof clientCaseDocumentApi>;
  type ClientCaseDocumentsApi = ReturnType<typeof clientCaseDocumentsApi>;

  function documents(): ClientCaseDocumentsApi;
  function documents(documentId: number): ClientCaseDocumentApi;
  function documents(documentId?: number): ClientCaseDocumentsApi | ClientCaseDocumentApi {
    const documentsApiPath = `${path}/documents`;

    return documentId
      ? clientCaseDocumentApi(`${documentsApiPath}/${documentId}`)
      : clientCaseDocumentsApi(documentsApiPath);
  }

  type ClientCaseFinancialPlanningGoalApi = ReturnType<typeof clientCaseFinancialPlanningGoalApi>;
  type ClientCaseFinancialPlanningGoalsApi = ReturnType<typeof clientCaseFinancialPlanningGoalsApi>;

  function financialPlanningGoals(): ClientCaseFinancialPlanningGoalsApi;
  function financialPlanningGoals(goalId: number): ClientCaseFinancialPlanningGoalApi;
  function financialPlanningGoals(
    goalId?: number
  ): ClientCaseFinancialPlanningGoalsApi | ClientCaseFinancialPlanningGoalApi {
    const financialPlanningGoalsApiPath = `${path}/financial-planning-goals`;

    return goalId
      ? clientCaseFinancialPlanningGoalApi(`${financialPlanningGoalsApiPath}/${goalId}`)
      : clientCaseFinancialPlanningGoalsApi(financialPlanningGoalsApiPath);
  }

  return {
    path,
    accept: () => clientCaseAcceptApi(`${path}/accept`),
    addenda,
    auditTrail: () => clientCaseAuditTrailApi(`${path}/audit-trail`),
    cancel: () => clientCaseCancelApi(`${path}/cancel`),
    confirm: () => clientCaseConfirmApi(`${path}/confirm`),
    documents,
    factFind: () => clientCaseFactFindApi(`${path}/fact-find`),
    financialAudits: () => clientCaseFinancialAuditsApi(`${path}/financial-audits`),
    financialPlanningHealthChecks: () =>
      clientCaseFinancialPlanningHealthChecksApi(`${path}/financial-planning-health-checks`),
    financialPlanningGoals,
    iBusinessPack: () => clientCaseIBusinessPackApi(`${path}/ibusiness-pack`),
    progress: () => clientCaseProgressApi(`${path}/progress`),
    reject: () => clientCaseRejectApi(`${path}/reject`),
    relationsUpdate: () => clientCaseRelationsUpdateApi(`${path}/relations-update`),
    review: () => clientCaseReviewApi(`${path}/review`),
    riskAssessments: () => clientCaseRiskAssessmentsApi(`${path}/risk-assessments`),
    submit: () => clientCaseSubmitApi(`${path}/submit`),
    updatePrivateNote: () => clientCaseUpdatePrivateNoteApi(`${path}/update-private-note`),
    updateTags: () => clientCaseUpdateTagsApi(`${path}/update-tags`),
    PATCH: (params: ClientCasePatchOptions) =>
      api.patch<ClientCasePatchResponse>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      ),
  };
}
