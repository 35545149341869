import { usageReportsMeetingReportApi } from './meetingReport';
import { usageReportsRecommendationReportApi } from './recommendationReport';

const apiPath = '/api/v1/usage-reports';

export function usageReportsApi(path: string) {
  return {
    path,
    meetingReport: () => usageReportsMeetingReportApi(`${path}/meeting-report`),
    recommendationReport: () =>
      usageReportsRecommendationReportApi(`${path}/recommendation-report`),
  };
}

export function usageReports() {
  return usageReportsApi(apiPath);
}
