export enum SignatureActionType {
  Draw = 'draw',
  Type = 'type',
  UseSaved = 'useSaved',
  Upload = 'upload',
}

export type SignatureAction =
  | {
      type: SignatureActionType.Draw;
      content: React.ReactNode;
    }
  | {
      type: SignatureActionType.Type;
      content: React.ReactNode;
    }
  | {
      type: SignatureActionType.UseSaved;
      content: React.ReactNode;
      savedSignature: string;
    }
  | {
      type: SignatureActionType.Upload;
      content: React.ReactNode;
    };
