import type { SectionCommon, Source } from './utils';

export interface Fund {
  you: number;
  partner: number;
  joint: number;
}

export enum IsaType {
  Cash = 'cash',
  StockShares = 'stockShares',
}

export interface Isa {
  type: IsaType;
  amount: string;
  source: Source;
}

export interface InvestmentsAndInheritanceTaxes extends SectionCommon {
  emergency_funds: Fund;
  additional_emergency_funds: Fund;
  isa_invested: boolean;
  isas: Isa[] | null;
  using_cgt_allowance: boolean;
  cgt_allowance_use_intended: boolean;
  advanced_planning: boolean;
  will: {
    you: boolean;
    partner: boolean;
  };
  investment_experience: {
    you: boolean;
    partner: boolean;
  };
}
