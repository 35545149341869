import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';

export interface ClientMetaAdviceRoomsGetResponse {
  advice_room_today: boolean;
}

export function clientMetaAdviceRoomsApi(path: string) {
  return {
    path,
    GET: (config?: RequestConfig) => api.get<ClientMetaAdviceRoomsGetResponse>(path, config),
  };
}
