import { users } from 'api';

import { mergePayloadWithEntities } from '../entities/actions';
import type { ThunkAction } from '../types';
import {
  FETCH_CURRENT_USER,
  FETCH_CURRENT_USER_FAIL,
  FETCH_CURRENT_USER_SUCCESS,
  SET_CURRENT_USER,
} from './actionTypes';
import type {
  FetchCurrentUserFailAction,
  FetchCurrentUserRequestAction,
  FetchCurrentUserSuccessAction,
  SetCurrentUserAction,
} from './types';

const setCurrentUser = (id: number): SetCurrentUserAction => ({
  type: SET_CURRENT_USER,
  payload: { id },
});

const fetchCurrentUserRequest = (): FetchCurrentUserRequestAction => ({
  type: FETCH_CURRENT_USER,
});

const fetchCurrentUserSuccess = (): FetchCurrentUserSuccessAction => ({
  type: FETCH_CURRENT_USER_SUCCESS,
});

const fetchCurrentUserFail = (): FetchCurrentUserFailAction => ({
  type: FETCH_CURRENT_USER_FAIL,
});

export const fetchCurrentUser = (): ThunkAction<void> => async dispatch => {
  dispatch(fetchCurrentUserRequest());

  try {
    const response = await users().me().GET();

    if (response.data) {
      dispatch(mergePayloadWithEntities(response.data));
      dispatch(setCurrentUser(Number(response.data.data.id)));
    }

    dispatch(fetchCurrentUserSuccess());
  } catch (err) {
    dispatch(fetchCurrentUserFail());
  }
};

export type FetchCurrentUserRoleName = () => void;
