import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';

export function practiceAdminAdvisersApi(path: string) {
  return {
    path,
    GET: (config?: RequestConfig) => {
      return api.get<JsonApiResponse>(path, config);
    },
  };
}
