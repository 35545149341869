import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

import { practiceBrochureApi } from './practiceBrochure';

export interface PracticeBrochuresPostOptions {
  title: string;
  image: File;
  visibilityPeriodFinishedAt: string;
  file?: File;
  externalPdfFileUrl?: string;
  link?: string;
}

const apiPath = '/api/v1/practice-brochures';

export function practiceBrochuresApi(path: string) {
  return {
    path,
    GET: () => api.get<JsonApiResponse>(path),
    POST: (values: PracticeBrochuresPostOptions) =>
      api.post<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(values),
          },
        })
      ),
  };
}

type PracticeBrochureApi = ReturnType<typeof practiceBrochureApi>;
type PracticeBrochuresApi = ReturnType<typeof practiceBrochuresApi>;

function practiceBrochures(): PracticeBrochuresApi;
function practiceBrochures(brochureId: number): PracticeBrochureApi;
function practiceBrochures(brochureId?: number): PracticeBrochuresApi | PracticeBrochureApi {
  return brochureId
    ? practiceBrochureApi(`${apiPath}/${brochureId}`)
    : practiceBrochuresApi(apiPath);
}

export { practiceBrochures };
