import {
  TOUR_GUIDE_ADMIN_CLIENTS_TILE,
  TOUR_GUIDE_ADVISER_CLIENTS_TILE,
  TOUR_GUIDE_ADVISER_DASHBOARD,
  TOUR_GUIDE_APP_SIDEBAR_USER_NAV_AVATAR,
  TOUR_GUIDE_CLIENT_PAGE_BUTTON,
  TOUR_GUIDE_HELP_CENTRE_TILE,
  TOUR_GUIDE_HELP_GUIDE_BUTTON,
  TOUR_GUIDE_MY_SUPPORT_TILE,
  TOUR_GUIDE_PRACTICE_ADMIN_DASHBOARD,
  TOUR_GUIDE_REQUEST_TECHNICAL_SUPPORT_BUTTON,
  TOUR_GUIDE_SIGN_OUT_BUTTON,
} from 'constants/tourGuide';
import { CaseState, CaseType } from 'types/entities/clientCase';
import { CoverNoteTemplate } from 'components/Adviser/Pages/AdviserCreateReviewQuestionnaireCasePage/types';
import {
  EmailTemplate as NewClientEmailTemplate,
  WelcomeMessageTemplate,
} from 'components/CreateClientAccountPage/types';
import {
  EmailTemplate as NewInvitationEmailTemplate,
  OnboardingTemplate,
} from 'components/CreateInvitationPage/types';
import { DocumentType } from 'components/DocumentsFileManagers/components/Actions/MessageTeamAction';

const coverNoteTemplates: Record<CoverNoteTemplate, { title: string; text: string }> = {
  [CoverNoteTemplate.ReviewMeetingPreparation]: {
    title: `Review meeting preparation`,
    text: `Hi {firstName},

Ahead of your upcoming review meeting, I would appreciate it if you could answer the questionnaires below. Your responses will help us to identify planning opportunities for us to consider.

Hopefully the questionnaires are intuitive. If you cannot answer everything, that is okay.

Thank you,

{userName}`,
  },
  [CoverNoteTemplate.IntroductionMeetingPreparation]: {
    title: `Introduction meeting preparation`,
    text: `Hi {firstName},

Ahead of the upcoming introduction meeting, I would appreciate it if you could answer the questionnaires below. Your responses will help us to identify planning opportunities for us to consider.

Hopefully the questionnaires are intuitive. If you cannot answer everything, that is okay.

Thank you,

{userName}`,
  },
  [CoverNoteTemplate.General]: {
    title: `General`,
    text: `Hi {firstName},

I would appreciate it if you could answer the questionnaires below. Your responses will help us to identify planning opportunities for us to consider.

Hopefully the questionnaires are intuitive. If you cannot answer everything, that is okay.

Thank you,

{userName}`,
  },
};

const newClientEmailTemplates: Record<NewClientEmailTemplate, { title: string; text: string }> = {
  [NewClientEmailTemplate.ExistingClient]: {
    title: `Existing client`,
    text: `Dear {firstName},

We would like you to create your personal account on our {practiceName} client portal. Using your personal account you will be able to request advice at your convenience, manage many of your wealth documents, and meet with our team online in secure video meetings.

Please follow the provided instructions to confirm your account.

Thank you,

{userName}`,
  },
  [NewClientEmailTemplate.ProspectiveClient]: {
    title: `Prospective client`,
    text: `Dear {firstName},

We are delighted that you are considering becoming a client of {practiceName}. We would like you to create your personal account on our {practiceName} client portal. From your personal account you can request advice, manage many of your wealth documents, and meet with our team online in secure video meetings.

Please follow the provided instructions to confirm your account.

Thank you,

{userName}`,
  },
  [NewClientEmailTemplate.ExistingClientIncClientPage]: {
    title: `Existing client - incl. reference to client page`,
    text: `Dear {firstName},

We would like you to create your personal account on our {practiceName} client portal. Using your personal account you will be able to request advice at your convenience, manage many of your wealth documents, and meet with our team online in secure video meetings.

Please follow the provided instructions to confirm your account.

If you would like to find out more about the portal, you can visit this {clientLink}, which describes the portal, its features, and how you can benefit.

Thank you,

{userName}`,
  },
  [NewClientEmailTemplate.ProspectiveClientIncClientPage]: {
    title: 'Prospective client - incl. reference to client page',
    text: `Dear {firstName},

We are delighted that you are considering becoming a client of {practiceName}. We would like you to create your personal account on our {practiceName} client portal. From your personal account you can request advice, manage many of your wealth documents, and meet with our team online in secure video meetings.

Please follow the provided instructions to confirm your account.

If you would like to find out more about the portal, you can visit this {clientLink}, which describes the portal, its features, and how you can benefit.

Thank you,

{userName}`,
  },
};

const welcomeMessageTemplates: Record<WelcomeMessageTemplate, { title: string; text: string }> = {
  [WelcomeMessageTemplate.ExistingAndProspectiveClient]: {
    title: `Existing and prospective client`,
    text: `Dear {firstName},

Thank you for confirming your new profile, and welcome to our secure client portal; we are delighted that you will be using this service going forwards.

We hope that you benefit from using the portal to help manage your financial affairs.

Thank you,

{userName}`,
  },
};

const newInvitationEmailTemplates: Record<
  NewInvitationEmailTemplate,
  { title: string; text: string }
> = {
  [NewInvitationEmailTemplate.ProspectiveClient]: {
    title: `Prospective client`,
    text: `Dear {firstName},

We are delighted that you are considering becoming a client of {practiceName}. If you click the link below, you’ll be guided through a process whereby you specify your personal details, investment and planning needs. Assuming that all is in order, you’ll be offered a choice of advice services, which you can choose.

Hopefully it is intuitive. If not, please feel free to contact our office.

Thank you,

{userName}`,
  },
};

const onboardingTemplates: Record<OnboardingTemplate, { title: string; text: string }> = {
  [OnboardingTemplate.Welcome]: {
    title: `Welcome`,
    text: `Hi {firstName},

Thank you for your consideration in being advised by {adviserName}.

If you follow this guide you can specify your personal details, investment and planning needs. Assuming that all is in order, you’ll be offered a choice of advice services, which you can choose.

Hopefully it is intuitive. If not, please feel free to contact our office.

Thank you,

{userName}`,
  },
};

const initialTeamMessages: Record<DocumentType, string> = {
  [DocumentType.Addendum]: `Re. Recommendation Pack ‘{caseTitle}’ Addendum > ‘{title}’`,
  [DocumentType.AdviceRoomDocument]: `Re. Client Meeting Room ‘{roomTitle}’ on {date} > ‘{title}’`,
  [DocumentType.DocumentPackDocument]: `Re. Document Pack ‘{caseTitle}’ > ‘{title}’`,
  [DocumentType.RecommendationPackDocument]: `Re. Recommendation Pack ‘{caseTitle}’ > ‘{title}’`,
};

export default {
  createCase: `Create new`,
  client: {
    goBack: `Back to my dashboard`,
    cases: `Cases`,
    documentPacks: `Document Packs`,
    noDocumentsPack: `There are no document packs yet`,
    netWorth: `Net worth`,
    netWorthTooltip: `This is an estimate of net worth based on the last Fact Find that the client completed and submitted.`,
    gender: `Gender`,
    maritalStatus: `Marital status`,
    age: `Age`,
    email: `Email address`,
    phone: `Phone number`,
    address: `Address`,
    genderValue: {
      male: `Male`,
      female: `Female`,
      other: `Other`,
    },
    addendaTooltip: `Attach documents that are required to modify your recommendation.`,
    caseDocuments: `Case documents`,
    caseDocumentsTooltip: `These documents were provided by your client as part of their {caseType},
      in the Fact Find section. If necessary, you can ask your client to provide other documents
      to clarify their case.`,
    noDocuments: `There are no documents at the moment.`,
    title: `Title`,
    documents: `Documents`,
    documentsTooltip: `Attach all documents required to convey the personal recommendation. For example, the Suitability Letter, illustrations etc.`,
    documentsTooltipShort: `Attach all documents that you wish to send to your client.`,
    caseDetailsTooltip: {
      default: `These are the details that the client submitted to you.
        Your advice to the client should be based on these specific details.`,
      [CaseType.ReviewQuestionnaireCase]: `Here are the client’s responses to the questionnaire.`,
    },
    recommendationPack: `Recommendation Pack`,
    recommendationPackTooltip: `Your advice to your client is conveyed via this Recommendation Pack.
      Please include a cover note and attach the necessary documents.
      When you are satisfied with the contents of the Recommendation Pack,
      send the Pack to your client by clicking the button below.`,
    coverNote: `Cover note`,
    coverNoteTooltip: {
      [CaseType.DocumentPackCase]: `Enter a cover note for your client. In the cover note you could
        explain the context behind - and the purpose of - the documents that you wish to share
        with your client. Perhaps also include any actions that the client should take.`,
      [CaseType.RecommendationPackCase]: `Enter a cover note for your client. In this note you should explain
        the purpose of the Suitability Letter and any other documents that you attach.
        Also, clarify any next steps for your client, such as reviewing the recommendation,
        completing any payment(s), and submitting the necessary paperwork.`,
      FinancialStrategy: `Optionally add a cover note for your client, which could consist of a
        summary or comments on the report.`,
    },
    saveCoverNote: `Save cover note`,
    updated: `Updated`,
    status: `Status`,
    riskAssessmentTitle: `Risk tolerance assessment`,
    riskAssessmentNoResultsSummary: `There are no answers yet.`,
  },
  batchDocumentPackCreation: {
    title: `Create Document Pack for multiple clients`,
    description: `Warning: By following this process you can send a Document Pack to multiple clients. Once sent, <u>each client will receive a unique Document Pack</u>. Should you ever need to modify or cancel the Document Pack, you will have to modify / cancel <i>each</i> client’s unique Document Pack. This would be very time consuming, so please be confident of the accuracy of the Document Pack before you send it to multiple clients.`,
    coverNoteTooltip: `Enter a cover note that is relevant for multiple clients.`,
    documentsTooltip: `Attach all documents that you wish to send to the clients.`,
    successMessage: `Document Packs created.`,
  },
  newClient: {
    createClientAccount: `Create client`,
    page: {
      header: `Create client`,
      summary: `Here you can create accounts for existing clients, or for prospects for whom it would be appropriate to have an account.
        The individual will be sent an automatic email asking them to confirm their account, to create a password and to sign in.`,
      note: `Note: Your client is required to confirm their account within 3 months.`,
      spamNote: `Note: In a small number of cases, the email to clients is directed to their spam or junk folder. It is best to explain to the client that they should also check these folders for an email from {email}. Further information on potential client onboarding issues is available in the <a>Help Centre</a>.`,
    },
    form: {
      dropdownLabel: `Adviser`,
      selectedAdviser: `Selected Adviser`,
    },
    modal: {
      message: `{clientFirstName} {clientLastName}’s account has been created. However, it currently only contains basic information. You can now further personalise the client’s profile so that it fully ready for your client when they first sign in`,
    },
    noAdviserAssigned: `You do not have any Adviser assigned yet.`,
    emailMessageTooltip: `Please write a relatively formal message to your client, complete with an appropriate salutation and the reason why you are creating a new account for them. If you wish you can include a link to a page that describes the DWS platform to your client.`,
    welcomeMessage: `Welcome message`,
    welcomeMessageTooltip: `Please write a relatively formal welcome message that will greet your client when they first sign in to their new profile. You might explain how they could use their new profile immediately or in future. For example, you could ask them to review a recommendation.`,
    welcomeVideo: {
      title: `Welcome video`,
      tooltip: `You can optionally record or upload a short video message that will be presented to your client when they first sign in. This video is an opportunity to make the client’s sign-up experience feel very personal.`,
      note: `Note: Maximum file size upload limit is 25 MB and allowed extension is mp4. After you create the client’s account, the video cannot be re-recorded or deleted.`,
      record: `Record`,
      recordVideo: `Record video`,
      uploadVideoFile: `Upload video file`,
      stop: `Stop`,
      delete: `Delete video`,
      settings: `Audio/Video settings`,
      browserSupport: `It seems that your browser does not support video recording. Please consider using one of listed browsers:`,
    },
    emailTemplates: newClientEmailTemplates,
    welcomeMessageTemplates,
    enableWebcamPreview: `Enable webcam`,
    disableWebcamPreview: `Disable webcam`,
    blurBackgroundPreview: 'Blur background',
  },
  caseOverview: {
    header: {
      [CaseType.OnlineAdviceRequest]: `Online Advice request:`,
      [CaseType.MeetingRequestCase]: `Meeting request:`,
      [CaseType.ReviewQuestionnaireCase]: `Questionnaire:`,
      [CaseType.RecommendationPackCase]: `Recommendation Pack:`,
      [CaseType.DocumentPackCase]: `Document Pack:`,
    },
    summary: {
      header: `Summary`,
      initialContribution: `Initial contribution`,
      monthlyContribution: `Monthly contribution`,
      investmentDuration: `Investment duration`,
      yearCount: `{value} years`,
      tooltip: `This is the summary of the client’s investment need, as they declared
        submitting their {caseType} to you.`,
    },
    timeline: {
      header: `Timeline`,
      status: {
        submitted: `Advice Case submitted`,
        reviewed: `Recommendation Pack shared with client`,
        acceptedOrRejected: `Recommendation Pack accepted / rejected by client`,
        confirmed: `Case now complete`,
      },
    },
    caseStatusChange: {
      header: `Details`,
      tooltip: {
        [CaseType.OnlineAdviceRequest]: `Here are details on the current Online Advice request, as submitted by your client.`,
        [CaseType.MeetingRequestCase]: `These are the details for the F2F meeting request, as submitted by your client.`,
        [CaseType.ReviewQuestionnaireCase]: `Here is the status and the questionnaire options that were chosen.`,
      },
      lastUpdate: `Last update`,
      explanation: `Explanation`,
      yourActions: `Your actions`,
      financialBasics: `Financial basics`,
      hasDebt: `Client has high-interest debt`,
      hasNoDebt: `Client does not have high-interest debt`,
      hasCashReserves: `Client does have at least 3 months’ cash reserves`,
      hasNoCashReserves: `Client does not have at least 3 months’ cash reserves`,
      topics: `Topics`,
      chosenOptions: `Chosen options`,
      explanations: {
        [CaseType.OnlineAdviceRequest]: {
          [CaseState.Building]: `The client is preparing their Online Advice Request to submit to you.`,
          [CaseState.Submitted]: `Your client has submitted their Online Advice Request to you. Next, you must review the request and respond.`,
          [CaseState.Completed]: `The Online Advice Request is now complete.`,
          [CaseState.Cancelled]: `The Online Advice Request was cancelled.`,
        },
      },
      actions: {
        [CaseType.OnlineAdviceRequest]: {
          [CaseState.Building]: `
            <ul>
              <li>(Optional) If appropriate, you can contact the client to offer them support with their submission or their advice need.</li>
              <li>(Optional) If appropriate, you can assist by editing the Fact Find and/or financial planning goals (see ‘Documents’).</li>
            </ul>`,
          [CaseState.Submitted]: `
            <ul>
              <li>Review the Online Advice Request (see ‘Documents’).</li>
              <li>(Optional) If appropriate, you can edit the client’s Fact Find and financial planning goals.</li>
            </ul>`,
          [CaseState.Completed]: `
            <ul>
              <li>None required.</li>
            </ul>`,
          [CaseState.Cancelled]: `
            <ul>
              <li>None required.</li>
            </ul>`,
        },
      },
    },
    caseAdviserNote: {
      header: `Private note`,
      tooltip: `Here you can keep working notes. These notes will not be presented to the client.`,
    },
    caseClientAdvice: {
      title: `Why your client is seeking advice`,
    },
  },
  clientsListWithNotes: {
    noItems: `There are no unread messages.`,
  },
  clientsList: {
    header: `Clients`,
    columnHeaders: {
      client: `Client`,
      lastActivity: `Last activity`,
      totalCaseSize: `Est. total case size`,
      netWorth: `Net worth`,
      createdAt: `Date created`,
      invitedAt: `Date invited`,
      clientTags: `Client Tags`,
    },
    noItems: `No clients to display.`,
    resendEmailMessage: `Are you sure you want to resend confirmation email?`,
  },
  invitations: {
    invite: `Invite`,
    inviteClient: `Invite prospect`,
    invitationSent: `The invitation has been sent successfully.`,
    invitationNotSent: `The invitation has not been sent.`,
    invitationCancelled: `The invitation has been cancelled.`,
    cancelInvitationModal: {
      title: `Cancel invitation`,
      content: `Are you sure you want to cancel invitation?`,
    },
    list: {
      header: `Unconfirmed prospects`,
    },
    new: {
      summary: `Here you can invite a prospective client to join this online service and to seek advice.
        The individual will be sent an email. Should they respond, they’ll be guided through an onboarding process.`,
      note: `Note: The prospect is required to respond within 1 year.`,
      basicInformation: {
        header: `Basic information`,
        emailMessageTooltip: `Please write a relatively formal message to your prospective client,
          complete with an appropriate salutation and the reason why you are inviting them to
          join.`,
      },
      onboarding: {
        header: `Onboarding`,
        summary: `Please type a custom message that will appear at the start of the onboarding.`,
        onboardingMessage: `Onboarding welcome message`,
        onboardingMessageTooltip: `Please write a personalised message that will appear when your
          prospective client begins their onboarding process. Ensure that the message is relatively
          formal, with a salutation and a brief introduction to your online service.`,
      },
      modal: {
        message: `The invitation has been sent successfully. You can now go back to Dashboard or send another invitation.`,
        sendAnother: `Send another`,
      },
      emailTemplates: newInvitationEmailTemplates,
      onboardingTemplates,
    },
  },
  caseItems: {
    riskTolerance: `Risk tolerance assessment`,
    factFind: `Fact Find`,
    financialAudit: `Personal Financial Audit`,
    taxHealthCheckAudit: `Tax Health Check`,
    financialPlanningGoals: `Financial Planning Goals`,
    financialPlanningHealthCheck: `Personal Financial Planning Health Check`,
  },
  questionnaireOptionDisclaimer: {
    info: `Please contact support to enable inactive questionnaire option(s).`,
    inactive: `Due to inactive questionnaire option(s), few features won't work. Please contact support for access.`,
    inactiveClient: `Due to inactive questionnaire option(s), few features won't work. Please contact your adviser for support.`,
    futureInfo: `Please contact support to enable inactive option(s) for future questionnaire cases.`,
  },
  documents: {
    add: `Add document`,
    addAnother: `Add another document`,
    disclaimer: `DWS can not hold any responsibility of actions performed in DocuSign outside the DWS application. We strongly recommend that you do not alter the envelopes directly in DocuSign (e.g. signing in to DocuSign and deleting a DWS-created envelope).`,
    inactiveDocusignDisclaimer: `A DocuSign account previously used is not currently active: this {case} will not work as expected.`,
    send: {
      [CaseType.DocumentPackCase]: `Send Document Pack`,
      [CaseType.RecommendationPackCase]: `Send Recommendation Pack`,
    },
    newTab: `Open in new tab`,
    openDocuSignDoc: `Open in DocuSign`,
    signUsingDocusign: `Sign using DocuSign`,
    enableTwoFactorAuthentication: `Enable DocuSign two-factor authentication`,
    signed: `Signed`,
    uploading: `Uploading...`,
    remove: `Remove`,
    removeConfirmation: `Are you sure you wish to remove document ‘{name}’? It will be irreversibly removed.`,
    revert: `Revert to {wasSent, select,
      true {sent version}
      other {original}
    }`,
    revertConfirmation: `Are you sure you wish to revert document ‘{name}’ to the {wasSent, select,
      true {sent}
      other {original}
    } version? Any changes will be lost.`,
    messageTeam: `Message my team`,
    messageLabel: `Message`,
    messageSendButton: `Send Practice Message`,
    messageSent: `Practice Message was sent`,
    initialMessage: initialTeamMessages,
    edit: `Edit file info`,
    markupInDocusign: `Markup in Docusign`,
    displayAndShare: `Display and share`,
    addSection: `Place instructions`,
    documentName: `Document Name`,
    documentNamePlaceholder: `e.g. Suitability letter`,
    note: `Note`,
    noteForClient: `Note for Client`,
    uploadDocument: `Upload document`,
    updateDocument: `Update document`,
    discard: `Discard document`,
    discardChanges: `Discard changes`,
    confirmationModal: {
      text: {
        [CaseType.DocumentPackCase]: `Are you sure that you wish to send the Document Pack?`,
        [CaseType.RecommendationPackCase]: `Are you sure that you wish to send the Recommendation Pack?`,
      },
      yes: `Yes, send.`,
      submitting: `Sending...`,
    },
    signatureRequired: `Make PDF editable (incl. DWS electronic signature)`,
    signatureRequiredTooltip: `Tick here to make the PDF editable or/and if you wish to use the DWS electronic signature option.`,
    signatureRequiredDocusign: `Sign with DocuSign`,
    signatureRequiredTooltipDocusign: `Tick here if the document requires a signature to be added via DocuSign.`,
    intregationRequiredTooltipDocusign: `Please integrate with DocuSign from account settings if you wish to use DocuSign to capture the client's digital signature.`,
    accountNotSelectedTooltipDocusign: `DocuSign account is not selected. Please select DocuSign account from the account settings.`,
    shouldSendSignatureReminder: `Automatically send signature/update reminders`,
    signatureReminderTooltip: `Remind your client via email that this item requires a signature or an update`,
    documentAutoEmail: `Receive auto-email whenever this document is updated by client`,
    documentAutoEmailTooltip: `Tick here to receive an auto-email whenever your client updates this document. This should help you to manage outstanding documentation.`,
    concealedFromClient: `Conceal this sensitive document from the client`,
    concealedFromClientTooltip: `A concealed document is not accessible to your client until it is ‘Displayed and Shared’. Concealing a document may be useful if you wish to ensure that the client does not read the document ahead of it being formally presented during your meeting.`,
    concealedFromClientModal: `This is a sensitive document. Are you sure you wish to share it with your client? They will have subsequent access to the document.`,
    authenticateDocusign: `Authenticate now`,
  },
  caseStateChange: {
    confirmationModal: {
      description: `Are you sure you wish to change the status from ‘{oldStatus}’ to ‘{newStatus}’?`,
      title: 'Are you sure?',
      docusignWarning: `Proceeding with 'Back to prepare' will:
        <ul>
          <li>Void all unsigned DocuSign documents. Where necessary, these will have to be added again</li>
          <li>Already signed documents will remain</li>
        </ul>

        Are you sure you wish to continue?
      `,
      confirm: `Yes, proceed`,
    },
  },
  recommendationPackCase: {
    title: `Enter a Recommendation Pack title`,
    caseNameInputPlaceholder: `Case name`,
    createCaseButton: `Create case`,
    createRecommendationPackCaseButton: `Create Recommendation Pack`,
    supportingDocuments: `Supporting documents`,
    supportingDocumentsTooltip: `Optionally attach documents that don’t form part of your
      recommendation, but that are relevant to the case. For example, a scan/photo of a Fact Find
      taken during the F2F meeting, or a document given to you by your client.`,
    accept: `Accept Recommendation Pack`,
    acceptOnClientBehalfConfirmation: `Are you sure you wish to accept this recommendation on behalf of the client?`,
  },
  reviewQuestionnaireCase: {
    createTitle: `Create Questionnaire`,
    createSubtitle: `Complete the form below to create and send a questionnaire to your client.`,
    createCaseButton: `Create`,
    createReviewQuestionnaireCaseButton: `Create Questionnaire`,
    confirmation: `Are you sure you want to create questionnaire?`,
    form: {
      title: `Title`,
      titleTooltip: `Personalise the questionnaire with a title. For example, ‘2019/20 Annual Review’ or ‘Bi-Annual Review Meeting’`,
      note: `Cover note`,
      noteTooltip: `Ask your client to complete the questionnaire. Potentially ask
          specific questions about how their life, financial situation, or planning objectives have
          changed since your last meeting. Your client’s responses will inform your agenda for
          the review meeting.`,
      includeFinancialPlanningGoals: `Include Financial Planning Goals`,
      includePersonalFinancialAudit: `Include Personal Financial Audit (2019/20)`,
      includeTaxHealthCheckAudit: `Include Tax Health Check (UK {financialYear})`,
      includePersonalFinancialPlanningHealthCheck: `Include Personal Financial Planning Health Check (UK v1)`,
      includeFactFindRevision: `Include Fact Find revision`,
      includeError: `At least 1 option must be chosen.`,
    },
    coverNoteTemplates,
  },
  documentPackCase: {
    title: `Enter a Document Pack title`,
    note: `Note: If you wish to send a personal recommendation to your client, you may wish to use <a>Create Recommendation Pack</a> instead.`,
    createCaseButton: `Create case`,
    createDocumentPackCaseButton: `Create Document Pack`,
  },
  adviceRooms: {
    title: `Schedule new Client Meeting Room`,
    summary: `Complete the form below to set up an Client Meeting Room at the date and time agreed with your client.`,
    form: {
      agenda: `Meeting agenda`,
      recordingFees: `Fees to record the meeting`,
      recordingNote1: `If, during the meeting, you decide to record segments of the meeting, it is important to understand associated costs.`,
      recordingNote2: `Recording a meeting incurs a one-off fee, and a monthly fee for storage<sup>*</sup>.

As of 19th April 2021, the fees are:`,
      recordingNote3: `Therefore, a 2-person, 30-minute video will cost {totalCost} to record and compose.

<sup>*</sup>Storage costs can be minimised by downloading the recording locally, and removing the DWS-hosted recorded file.`,
      recording: `Recording`,
      recordingCost: `{cost} per minute per participant`,
      composition: `Composition`,
      compositionCost: `{cost} per composed minute`,
      storage: `Storage`,
      storageCost: `{cost} per GB per month`,
      duration: `Duration`,
      durationValueMins: `{duration} mins`,
      durationValueHrs: `{duration} hrs`,
      mediaRegion: `Meeting server location`,
      mediaRegionTooltip1: `To help you to manage the quality of your Client Meeting Room network connection, you can specify the country in which your host meeting server is located. In general, it would be best to use a server in a geographically-close country as this would reduce connection ‘lag’ and, therefore, improve the technical quality of the meeting.`,
      mediaRegionTooltip2: `For example, if all attendees are based in the United Kingdom, then using ‘Ireland’ may be optimal. Similarly, if the adviser is based in Hong Kong, but the client is based in Australia, then using ‘Singapore’ might be best. Alternatively, you can select ‘Automatically assigned’ to let the tech dynamically decide* on your behalf.`,
      mediaRegionTooltip3: `*Dynamic assignment is based on the first attendee to join. For example, if the first attendee client joins from the USA, the application will nominate a meeting server in the USA to host the meeting.`,
      timeZoneNote1: `Note: Please specify a date and time that are relevant to your local time zone, which is ‘{BROWSER_TIME_ZONE}’.`,
      timeZoneNote2: `The invitation email to attendees will detail the meeting’s date and time for the ‘{PRACTICE_TIME_ZONE}’ timezone, which is this Practice’s configured time zone. Where necessary, the invitation email’s .ics calendar file will convert the meeting date and time to each attendee’s local timezone.`,
      timeZoneNote3: `Note: The invitation email to attendees details the meeting’s date and time for the ‘{PRACTICE_TIME_ZONE}’ timezone, which is this Practice’s configured time zone. Where necessary, the invitation email’s .ics calendar file converts the meeting date and time to each attendee’s local timezone.`,
    },
    createAdviceRoomButton: `Create Client Meeting Room`,
  },
  motivationCentre: {
    createMotivationCentreItemButton: `Create Motivation`,
  },
  financialStrategy: {
    title: `Enter a Financial Strategy title`,
    editTitle: `Financial Strategy`,
    createFinancialStrategyButton: `Create Financial Strategy`,
    imageDescription: `Below is a preview of what your client will see on their dashboard for this financial strategy.
      You can optionally upload or choose a suitable image that will help to ‘personalise’ the strategy.`,
    editSummary: `Please use an appropriate Financial Strategy document, which details your client’s long-term financial plan.`,
    willBeShared: `Document will be visible to your client`,
    willNotBeShared: `Document will not be visible to your client`,
    practiceAdminWarning: `Cannot share the Financial Strategy? You do not have permission to do so currently.
      If necessary, please speak to {adviserName} or {practiceDirectorName} to organise appropriate permissions to send recommendations to {adviserName}’s clients.`,
    remove: `Remove Financial Strategy`,
    removeDescription: `Are you sure you want to remove this Financial Strategy? This cannot be undone.`,
  },
  businessPack: {
    generate: `Generate`,
    generatePack: `Generate business submission pack`,
    select: `Select the files to include in the business submission pack:`,
    financialPlanningGoals: `Financial Planning Goals`,
    factFind: `Fact Find`,
    riskTolerance: `Risk Tolerance report`,
  },
  support: {
    title: `My support`,
    tileDescription: `Manage your support team’s permissions.`,
    subTitle: `Here you can manage the permissions of the staff members who support you and your clients. If you need to add new staff members, please contact {value}.`,
    assignPracticeAdmins: `Supports me and my clients`,
    emailNotifications: `Receives email notifications about my clients’ cases`,
    recommendationPack: `Can send and accept personal recommendations, share financial strategies, manage additional advisers, deactivate and delete clients (subject to my permissions) on my behalf`,
    titlePD: `Support`,
    subTitlePD: `Here you can manage the permissions of the staff members who support this adviser.`,
    assignPracticeAdminsPD: `Supports the adviser and their clients`,
    emailNotificationsPD: `Receives email notifications about clients’ cases`,
    recommendationPackPD: `Can send and accept personal recommendations, share financial strategies, manage additional advisers, deactivate and delete clients (subject to adviser permissions) on the adviser’s behalf`,
  },
  team: {
    title: `My team`,
    tileDescription: `View the advisers that you support, and their teams.`,
    subTitle: `Here you can view the advisers, and their support teams, that you support. This may be useful when you need to understand who else has access to specific client accounts, and who can assist you with client work.`,
  },
  tourGuide: {
    [TOUR_GUIDE_HELP_CENTRE_TILE]: `Click here to begin your essential training for a successful onboarding experience.`,
    [TOUR_GUIDE_HELP_GUIDE_BUTTON]: `Here you will find relevant help guides on different features.`,
    [TOUR_GUIDE_APP_SIDEBAR_USER_NAV_AVATAR]: `Click here to manage your account settings.`,
    [TOUR_GUIDE_ADVISER_DASHBOARD]: `On your dashboard, you'll find all the tools you need to advise your clients.`,
    [TOUR_GUIDE_PRACTICE_ADMIN_DASHBOARD]: `On your dashboard, you'll find all the tools you need to support an adviser with their clients.`,
    [TOUR_GUIDE_ADVISER_CLIENTS_TILE]: `Click here to onboard and manage your clients.`,
    [TOUR_GUIDE_ADMIN_CLIENTS_TILE]: `Click here to onboard and manage clients accounts.`,
    [TOUR_GUIDE_MY_SUPPORT_TILE]: `Click here to manage your practice support.`,
    [TOUR_GUIDE_CLIENT_PAGE_BUTTON]: `Click here to visit our dedicated client page which you can optionally share with your client.`,
    [TOUR_GUIDE_REQUEST_TECHNICAL_SUPPORT_BUTTON]: `If you encounter any issues that require our support team's assistance, raise a ticket here.`,
    [TOUR_GUIDE_SIGN_OUT_BUTTON]: `When you are finished within your account, please sign out.`,
  },
};
