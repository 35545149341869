import api from 'api/apiInstance';
import { objectToURLParams } from 'helpers/utils';

interface ClientCaseFactFindLetterOfAuthorityPrintGetOptions {
  assetNumber: number;
}

export function clientCaseFactFindLetterOfAuthorityPrintApi(path: string) {
  const generatePath = (params: ClientCaseFactFindLetterOfAuthorityPrintGetOptions) => {
    const options = {
      'data[attributes][asset_number]': params.assetNumber,
    };

    return `${path}?${objectToURLParams(options)}`;
  };

  return {
    path,
    GET: (params: ClientCaseFactFindLetterOfAuthorityPrintGetOptions) =>
      api.get<File>(generatePath(params)),
    GETPath: (params: ClientCaseFactFindLetterOfAuthorityPrintGetOptions) => generatePath(params),
  };
}
