import React, { useContext } from 'react';
import type { Room } from 'twilio-video';

import type { RoomConnectionState } from './RoomConnectionState';
import type { JoinRoom, LeaveRoom, RoomData, Toggle } from './types';

export interface ContextValue {
  room: Room | undefined;
  roomData: RoomData | undefined;
  connectionState: RoomConnectionState;
  joinRoom: JoinRoom;
  leaveRoom: LeaveRoom;
  toggleAudioTracks: Toggle;
  toggleVideoTracks: Toggle;
  toggleScreenTrack: Toggle;
  roomMonitorConsole: Toggle;
  removeAllVideoEffect: () => void;
  enableBackgroundBlur: Toggle;
  enableVirtualBackground: Toggle;
  isVirtualBackgroundEnabled: boolean;
  isAudioEnabled: boolean;
  isVideoEnabled: boolean;
  isBackgroundBlurEnabled: boolean;
  isScreensharingEnabled: boolean;
  isRoomMonitorConsoleEnabled: boolean;
}

class VideoContextError extends Error {
  constructor() {
    super('You tried to use VideoContext outside the scope of the provider.');
  }
}

const defaultContextValue: ContextValue = {
  get room(): ContextValue['room'] {
    throw new VideoContextError();
  },
  get roomData(): ContextValue['roomData'] {
    throw new VideoContextError();
  },
  get connectionState(): ContextValue['connectionState'] {
    throw new VideoContextError();
  },
  joinRoom() {
    throw new VideoContextError();
  },
  leaveRoom() {
    throw new VideoContextError();
  },
  toggleAudioTracks() {
    throw new VideoContextError();
  },
  toggleVideoTracks() {
    throw new VideoContextError();
  },
  enableBackgroundBlur() {
    throw new VideoContextError();
  },
  roomMonitorConsole() {
    throw new VideoContextError();
  },
  toggleScreenTrack() {
    throw new VideoContextError();
  },
  enableVirtualBackground(): void {
    throw new VideoContextError();
  },
  removeAllVideoEffect(): void {
    throw new VideoContextError();
  },
  get isAudioEnabled(): ContextValue['isAudioEnabled'] {
    throw new VideoContextError();
  },
  get isVideoEnabled(): ContextValue['isVideoEnabled'] {
    throw new VideoContextError();
  },
  get isBackgroundBlurEnabled(): ContextValue['isBackgroundBlurEnabled'] {
    throw new VideoContextError();
  },
  get isRoomMonitorConsoleEnabled(): ContextValue['isRoomMonitorConsoleEnabled'] {
    throw new VideoContextError();
  },
  get isScreensharingEnabled(): ContextValue['isScreensharingEnabled'] {
    throw new VideoContextError();
  },
  get isVirtualBackgroundEnabled(): ContextValue['isVirtualBackgroundEnabled'] {
    throw new VideoContextError();
  },
};

export const VideoContext = React.createContext<ContextValue>(defaultContextValue);

export const useVideoContext = () => useContext(VideoContext);
