import type { AnyAction } from 'redux';

import {
  GET_FINANCIAL_AUDIT,
  GET_FINANCIAL_AUDIT_FAIL,
  GET_FINANCIAL_AUDIT_SUCCESS,
  SET_FINANCIAL_AUDIT,
  UNSET_FINANCIAL_AUDIT,
} from './actionTypes';
import type { FinancialAuditActionTypes, FinancialAuditState } from './types';

export const initialState: FinancialAuditState = {
  id: null,
  isLoading: false,
  isInitiallyFetched: false,
};

export default (state = initialState, incomingAction: AnyAction): FinancialAuditState => {
  const action = incomingAction as FinancialAuditActionTypes;

  switch (action.type) {
    case GET_FINANCIAL_AUDIT:
      return {
        ...state,
        isLoading: true,
        isInitiallyFetched: false,
      };
    case GET_FINANCIAL_AUDIT_SUCCESS:
    case GET_FINANCIAL_AUDIT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case SET_FINANCIAL_AUDIT:
      return {
        ...state,
        id: action.payload.id,
        isInitiallyFetched: true,
      };
    case UNSET_FINANCIAL_AUDIT:
      return {
        ...state,
        id: null,
      };
    default:
      return state;
  }
};
