import React from 'react';
import styled, { css } from 'styled-components';

import GenericText from 'components/GenericText/GenericText';

interface WrapperProps {
  isCentered?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;

  ${({ isCentered }) =>
    isCentered &&
    css`
      display: flex;
      justify-content: center;
    `};
`;

interface Props extends WrapperProps {
  text: React.ReactNode | React.ReactNode[];
}

export function EmptyListMsg({ text, isCentered }: Props) {
  return (
    <Wrapper isCentered={isCentered}>
      <GenericText data-test="empty-list-msg" isInlineBlock>
        {text}
      </GenericText>
    </Wrapper>
  );
}

export default EmptyListMsg;
