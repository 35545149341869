import React, { useContext } from 'react';

import type { Message, SendMessage, Toggle } from './types';

export interface ContextValue {
  sendMessage: SendMessage;
  toggleMessages: Toggle;
  areMessagesVisible: boolean;
  areMessagesEnabled: boolean;
  messages: Message[];
  unreadMessageCount: number;
  userEmail: string;
}

class MessagesContextError extends Error {
  constructor() {
    super('You tried to use MessagesContext outside the scope of the provider.');
  }
}

const defaultContextValue: ContextValue = {
  sendMessage() {
    throw new MessagesContextError();
  },
  toggleMessages() {
    throw new MessagesContextError();
  },
  get areMessagesVisible(): ContextValue['areMessagesVisible'] {
    throw new MessagesContextError();
  },
  get areMessagesEnabled(): ContextValue['areMessagesEnabled'] {
    throw new MessagesContextError();
  },
  get messages(): ContextValue['messages'] {
    throw new MessagesContextError();
  },
  get unreadMessageCount(): ContextValue['unreadMessageCount'] {
    throw new MessagesContextError();
  },
  get userEmail(): ContextValue['userEmail'] {
    throw new MessagesContextError();
  },
};

export const MessagesContext = React.createContext<ContextValue>(defaultContextValue);

export const useMessagesContext = () => useContext(MessagesContext);
