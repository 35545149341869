import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  setSelectedAudioDevice,
  setSelectedAudioOutputDevice,
  setSelectedVideoDevice,
} from 'api/twilio/twilioVideo';
import IconSettings from 'components/Icons/Nav/IconSettings';
import useModalState from 'components/ModalHandler/useModalState';
import {
  useSelectedAudioDevice,
  useSelectedAudioOutputDevice,
  useSelectedVideoDevice,
} from 'components/VideoChat/useDevices';

import SettingsModal from '../SettingsModal/SettingsModal';
import VideoChatToolbarButton from '../VideoChatToolbarButton/VideoChatToolbarButton';

function SettingsButton() {
  const [isModalOpen, openModal, closeModal] = useModalState();
  const { selectedAudioDeviceId } = useSelectedAudioDevice();
  const { selectedAudioOutputDeviceId } = useSelectedAudioOutputDevice();
  const { selectedVideoDeviceId } = useSelectedVideoDevice();
  return (
    <>
      <VideoChatToolbarButton
        onClick={openModal}
        icon={IconSettings}
        data-test="settings-button"
        tooltipText={<FormattedMessage id="video.tooltips.settings" />}
      />

      {isModalOpen && (
        <SettingsModal
          onClose={closeModal}
          onSubmit={options => {
            const { audioDeviceId, audioOutputDeviceId, videoDeviceId } = options;

            setSelectedAudioDevice(audioDeviceId);
            setSelectedAudioOutputDevice(audioOutputDeviceId);
            setSelectedVideoDevice(videoDeviceId);

            closeModal();
          }}
          initialValues={{
            audioDeviceId: selectedAudioDeviceId,
            audioOutputDeviceId: selectedAudioOutputDeviceId,
            videoDeviceId: selectedVideoDeviceId,
          }}
        />
      )}
    </>
  );
}

export default SettingsButton;
