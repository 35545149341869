import api from 'api/apiInstance';
import type { DocumentSet } from 'types/entities/documentSet';

import { adviceRoomDocumentSetItemApi } from './item';

export function adviceRoomDocumentSetsApi(path: string) {
  return {
    path,
    item: () => adviceRoomDocumentSetItemApi(`${path}/item`),
    GET: () => api.get<DocumentSet[]>(path),
  };
}
