import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  GaussianBlurBackgroundProcessor,
  VirtualBackgroundProcessor,
} from '@twilio/video-processors';
import { VideoRoomMonitor } from '@twilio/video-room-monitor';
import { BrowserInfo, detect } from 'detect-browser';
import TwilioVideo, { Room } from 'twilio-video';

import { VIDEO_TRACK_NAME } from 'api/twilio/twilioVideo';
import { getBlurBackgroundAsset } from 'helpers/blurBackgroundAsset';
import { useGetCurrentUser } from 'store/users/selectors';
import { useCameraPermissions } from 'components/TestSetupTwilio/components/useDevicesPermissions';
import { DevicePermissions } from 'components/TestSetupTwilio/types';

import type { Toggle } from './types';
import { useSelectedVideoDevice } from './useDevices';

export default function useVideoTracks(room: Room | undefined) {
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [isBackgroundBlurEnabled, setIsBackgroundBlurEnabled] = useState(false);
  const [isVirtualBackgroundEnabled, setIsVirtualBackgroundEnabled] = useState<boolean>(false);
  const [isRoomMonitorConsoleEnabled, setIsRoomMonitorConsoleEnabled] = useState(false);
  const { selectedVideoDeviceId } = useSelectedVideoDevice();
  const { cameraPermissions } = useCameraPermissions();
  const browser = useMemo(() => detect() as BrowserInfo, []);
  const prevPermissionsRef = useRef<DevicePermissions>(cameraPermissions);
  const isSafariIosBrowser = !['ios', 'safari'].includes(browser.name);

  const currentUser = useGetCurrentUser();

  useEffect(() => {
    if (!room) return;
    if (!selectedVideoDeviceId) {
      room.localParticipant.videoTracks.forEach(({ track, trackName }) => {
        if (trackName === VIDEO_TRACK_NAME) {
          track.disable();
        }
      });
      setIsVideoEnabled(false);
      return;
    }

    const hasPermissionsChanged =
      prevPermissionsRef.current !== DevicePermissions.Unknown &&
      cameraPermissions !== DevicePermissions.Unknown &&
      prevPermissionsRef.current !== cameraPermissions;

    prevPermissionsRef.current = cameraPermissions;

    if (
      cameraPermissions === DevicePermissions.SystemDenied ||
      cameraPermissions === DevicePermissions.Denied
    ) {
      setIsVideoEnabled(false);
    }

    let hasTrack = false;
    room.localParticipant.videoTracks.forEach(({ track, trackName }) => {
      if (trackName === VIDEO_TRACK_NAME) {
        hasTrack = true;
        const hasDeviceChanged =
          track.mediaStreamTrack.getSettings().deviceId !== selectedVideoDeviceId;

        if (hasDeviceChanged || hasPermissionsChanged) {
          track.restart({ deviceId: selectedVideoDeviceId });
        }
      }
    });

    if (!hasTrack) {
      TwilioVideo.createLocalVideoTrack({
        name: VIDEO_TRACK_NAME,
        deviceId: selectedVideoDeviceId,
      }).then(videoTrack => {
        videoTrack.disable();
        room.localParticipant.publishTracks([videoTrack]);
      });
    }
  }, [selectedVideoDeviceId, cameraPermissions, room]);

  useEffect(() => {
    room?.localParticipant.videoTracks.forEach(({ track, trackName }) => {
      if (trackName === VIDEO_TRACK_NAME) {
        track.enable(isVideoEnabled);
      }
    });
  }, [isVideoEnabled, room]);

  useEffect(() => {
    room?.localParticipant.videoTracks.forEach(({ trackName }) => {
      if (trackName === VIDEO_TRACK_NAME) {
        if (isRoomMonitorConsoleEnabled) {
          VideoRoomMonitor.registerVideoRoom(room);
          VideoRoomMonitor.openMonitor();
        } else {
          VideoRoomMonitor.closeMonitor();
        }
      }
    });
  }, [isRoomMonitorConsoleEnabled, room]);

  useEffect(() => {
    room?.localParticipant.videoTracks.forEach(({ track, trackName }) => {
      if (trackName === VIDEO_TRACK_NAME) {
        if (isBackgroundBlurEnabled) {
          if (track.processor) track.removeProcessor(track.processor);
          setIsVirtualBackgroundEnabled(false);

          const blurBackground = new GaussianBlurBackgroundProcessor({
            assetsPath: getBlurBackgroundAsset(),
            maskBlurRadius: 10,
            blurFilterRadius: 10,
          });

          blurBackground.loadModel();
          track.addProcessor(blurBackground);
        } else if (isVirtualBackgroundEnabled) {
          if (track.processor) track.removeProcessor(track.processor);
          setIsBackgroundBlurEnabled(false);

          if (currentUser?.virtualBackground) {
            fetch(currentUser.virtualBackground)
              .then(response => response.blob())
              .then(blob => {
                const img = new Image();
                img.crossOrigin = 'anonymous';
                img.src = URL.createObjectURL(blob);
                img.onload = () => {
                  const virtualBackgroundProcessor = new VirtualBackgroundProcessor({
                    assetsPath: getBlurBackgroundAsset(),
                    backgroundImage: img,
                  });
                  virtualBackgroundProcessor.loadModel();
                  track.addProcessor(virtualBackgroundProcessor);
                };
              });
          }
        } else if (track.processor && (!isBackgroundBlurEnabled || !isVirtualBackgroundEnabled)) {
          track.removeProcessor(track.processor);
        }
      }
    });
  }, [
    isBackgroundBlurEnabled,
    room,
    isSafariIosBrowser,
    isVirtualBackgroundEnabled,
    currentUser?.virtualBackground,
  ]);

  const toggleVideoTracks = useCallback<Toggle>(
    shouldEnable => {
      if (
        cameraPermissions === DevicePermissions.SystemDenied ||
        cameraPermissions === DevicePermissions.Denied
      ) {
        return;
      }

      setIsVideoEnabled(prevValue => (shouldEnable != null ? shouldEnable : !prevValue));
    },
    [cameraPermissions]
  );

  const removeAllVideoEffect = useCallback<Toggle>(() => {
    setIsBackgroundBlurEnabled(false);
    setIsVirtualBackgroundEnabled(false);
  }, []);

  const enableBackgroundBlur = useCallback<Toggle>(() => {
    setIsVirtualBackgroundEnabled(false);
    setIsBackgroundBlurEnabled(true);
  }, []);

  const enableVirtualBackground = useCallback<Toggle>(() => {
    setIsBackgroundBlurEnabled(false);
    setIsVirtualBackgroundEnabled(true);
  }, []);

  const roomMonitorConsole = useCallback(shouldEnableConsole => {
    setIsRoomMonitorConsoleEnabled(prevValue =>
      shouldEnableConsole != null ? shouldEnableConsole : !prevValue
    );
  }, []);

  return {
    isVideoEnabled,
    toggleVideoTracks,
    isBackgroundBlurEnabled,
    enableBackgroundBlur,
    enableVirtualBackground,
    isRoomMonitorConsoleEnabled,
    roomMonitorConsole,
    isVirtualBackgroundEnabled,
    removeAllVideoEffect,
  };
}
