import React from 'react';

import { useGetAllCurrencies } from '../hooks/useGetAllCurrencies';
import { CurrencyInput, Props } from './CurrencyInput';

export function AllCurrencyInput(props: Omit<Props, 'currencies'>) {
  const currencies = useGetAllCurrencies();

  return <CurrencyInput currencies={currencies} {...props} />;
}
