import { Browser } from 'constants/browser';
import { OperatingSystem } from 'constants/operatingSystem';
import { PlaceholderAction } from 'constants/placeholderAction';
import { SignatureActionType } from 'constants/signatureAction';
import { CaseState, CaseType } from 'types/entities/clientCase';
import { UserRoleName } from 'types/entities/user';
import { BrushRadius } from 'components/AdviceRooms/Sketchpad/types';

export default {
  ok: `Ok`,
  okay: `Okay`,
  logo: `Logo`,
  accept: `Accept`,
  step: `Step {num}`,
  set: `Set`,
  edit: `Edit`,
  save: `Save`,
  expandAll: `Expand all`,
  collapseAll: `Collapse all`,
  continue: `Continue`,
  saveContinue: `Save and continue`,
  create: `Create`,
  clientPage: `Client Page`,
  createAcount: `Create account`,
  allow: `Allow`,
  deny: `Deny`,
  join: `Join`,
  cancel: `Cancel`,
  delete: `Delete`,
  show: `Show`,
  hide: `Hide`,
  begin: `Begin`,
  submit: `Submit`,
  beginVerification: 'Begin Verification',
  submitRequest: `Submit Request`,
  revise: `Revise`,
  revertToDefault: `Revert to default`,
  success: `Success`,
  failure: `Failure`,
  notFound: `Not found`,
  date: `Date`,
  time: `Time`,
  timeTooltip: `If necessary, you can manually edit the time input field for desired time.`,
  now: `now`,
  dateCreated: `Date created`,
  dateCreatedWithValue: `Date created: {value}`,
  dateUpdated: `Date updated`,
  dateUpdatedWithValue: `Date updated: {value}`,
  createdByWithValue: `Created by: {value}`,
  dateWithAuthor: `{date} by {author}`,
  clearAndNew: `Clear and create new`,
  title: `Title`,
  description: `Description`,
  noOptions: `No options`,
  optional: `Optional`,
  yes: `Yes`,
  no: `No`,
  or: `or`,
  add: `Add`,
  addImage: `Add Image`,
  all: `All`,
  awaiting: `Awaiting`,
  awaitingProspectiveClients: `Awaiting prospective clients`,
  active: `Active`,
  upcoming: `Upcoming`,
  closed: `Closed`,
  close: `Close`,
  complete: `Complete`,
  download: `Download`,
  downloadCertificate: `Download Certificate`,
  downloadCombinedDocument: `Download Document & Certificate`,
  combinedDocumentTooltip: `This option will behave as expected only if the Envelope Delivery setting <b>Attach certificate of completion to envelope</b> is checked in your DocuSign account.`,
  accountAuditTrail: `Account audit trail`,
  next: `Next`,
  previous: `Previous`,
  done: `Done`,
  skip: `Skip`,
  actionRequired: `Action required`,
  createAnother: `Create another`,
  createAnotherClient: `Create another client`,
  personalise: `Personalise`,
  resend: `Resend confirmation email`,
  resendInvitations: `Resend invitations`,
  send: `Send`,
  generate: `Generate`,
  request: `Request`,
  warning: `Warning`,
  emptyList: `List is empty`,
  emptyNote: `Note is empty`,
  deactivate: `Deactivate`,
  resetPassword: `Initiate password reset`,
  activate: `Activate`,
  deactivateAccount: `Deactivate account`,
  activateAccount: `Activate account`,
  deactivated: `Deactivated`,
  unblock: `Unlock`,
  blocked: `Locked`,
  preferred: `Preferred`,
  clientUnblocked: `Client account has been unlocked successfully.`,
  upload: `Upload`,
  uploadFile: `Upload file`,
  attachFiles: `Attach files`,
  remove: `Remove`,
  homepage: `Homepage`,
  backToHomepage: `Back to homepage`,
  editProfile: `Edit profile`,
  editSupport: `Edit support`,
  addNewItem: `Add new item`,
  uploadDocument: `Upload document`,
  enableSorting: `Enable sorting`,
  disableSorting: `Disable sorting`,
  sortingInstructions: `Drag the document to the desired location to change its position.`,
  new: `New`,
  disclosure: `Disclosure`,
  caseImage: `Case image`,
  meetingImage: `Meeting image`,
  uploadImage: `Upload image`,
  changeImage: `Change image`,
  preview: `Preview`,
  compactView: `Compact view`,
  view: `View`,
  image: `Image`,
  outlookCalendar: `Add this CMR event to your Outlook calendar`,
  outlookCalendarTooltip: `Please integrate with Outlook from account settings if you wish to auto add the CMR event to the Outlook calendar.`,
  outlookEditTooltip: `Please mark this option as checked by editing the CMR if you wish to add the CMR to your Outlook calendar.`,
  noImages: `No images`,
  tileImage: `Tile image`,
  editTileImage: `Edit tile image`,
  tileImageNote: `Note: Because of the responsive nature of this site, images may appear to be cropped depending on the user’s device and/or browser window. Please consider this when choosing an image. If you wish to include an image of a logo (e.g.), please make sure that the image includes a buffer space around the core logo.`,
  tileImageRatioRecommendation: `We recommend an image with an aspect ratio of 3:2.`,
  hidden: `Hidden`,
  sorry: `Sorry`,
  tools: `Tools`,
  penColor: `Pen colour`,
  undo: `Undo`,
  pen: `Pen`,
  eraser: `Eraser`,
  highlighter: `Highlighter`,
  brushRadius: `Brush radius - {radius, select,
      ${BrushRadius.Small} {small}
      ${BrushRadius.Medium} {medium}
      ${BrushRadius.Large} {large}
      other {-}
    }`,
  selectExistingImage: `Select existing image`,
  sent: `Sent`,
  client: `Client`,
  attendees: `Attendees`,
  scheduled: `Scheduled: `,
  guests: `Guests: `,
  sentToClient: `Sent to client`,
  notSentToClient: `Not sent to client`,
  testimonialsPlaceholder: `Testimonials area`,
  testimonialsHint: `Please, fill in testimonials in practice settings to show them on the page.`,
  sendConfirmationEmail: `Send confirmation email`,
  dashboard: `Dashboard`,
  chooseMonth: `Choose month`,
  currency: `Currency`,
  chooseCurrency: `Choose currency`,
  currencyRequired: `At least one currency is required`,
  exportToPdf: `Export to PDF`,
  exportToPdfPartner: `Export spouse/partner details to PDF`,
  sharedWithClient: `Shared with client`,
  notSharedWithClient: `Not shared with client`,
  search: `Search`,
  searchClient: `Search for client`,
  searchAdviser: `Search for Adviser`,
  searchClientTags: `Search for client tags`,
  searchPracticeAdmin: `Search for Practice Admin`,
  post: `Post`,
  proceed: `Proceed`,
  generating: `Generating...`,
  passwordToProceed: `Enter password to proceed`,
  notActive: `Not active`,
  notConfirmed: `Not confirmed`,
  requiredNote: `Fields marked by * are required.`,
  open: `Open`,
  fullScreen: `Full Screen`,
  markAsCompleted: `Mark as completed`,
  unreadPack: `Unread pack`,
  unreadStrategy: `Unread Strategy`,
  unreadDocuments: `Unread File`,
  helpCentre: `Help Centre`,
  status: `Status`,
  statusWithValue: `Status: {value}`,
  actions: `Actions`,
  discardChanges: `Discard changes`,
  personalDetails: `Personal details`,
  infoVisibleToClients: `This information is visible to your clients.`,
  accountSettings: `Account settings`,
  refresh: `Refresh`,
  externalLink: `Opens an external site.`,
  drawHereToSign: `Draw here to sign`,
  typeHereToSign: `Type here to sign`,
  clickToSign: `Click to sign`,
  clickToType: `Click to type`,
  proceedConfirmation: `Are you sure you wish to proceed?`,
  cropInstructions: `Please select a portion of the image that you want to save as signature.`,
  today: `Today`,
  selectAll: `Select All`,
  selectAllClients: `Select all clients`,
  typeToSearchClients: `Type to search for clients`,
  noClientsFound: `No clients found`,
  sendMessage: `Send Message`,
  filesCount: `{value, plural,
      =0 {no files}
      one {# file}
      other {# files}
    }`,
  header: {
    menu: `Menu`,
  },
  noUsers: `No users to display`,
  operatingSystem: `Operating system`,
  requiredBrowserVersion: `version {number}+`,
  createSummaryInteractionReport: `Create Summary Interaction Report`,
  downloadClients: `Download clients`,
  browsers: {
    [Browser.IE]: `Internet Explorer`,
    [Browser.Edge]: `Edge`,
    [Browser.EdgeChromium]: `Edge (Chromium)`,
    [Browser.Chrome]: `Chrome`,
    [Browser.Firefox]: `Firefox`,
    [Browser.Safari]: `Safari`,
    [Browser.AndroidChrome]: `Chrome`,
  },
  os: {
    [OperatingSystem.Android]: `Android`,
    [OperatingSystem.IOS]: `iOS / iPadOS`,
    [OperatingSystem.Linux]: `Linux`,
    [OperatingSystem.MacOS]: `macOS`,
    [OperatingSystem.Windows]: `Windows`,
  },
  footer: {
    logo: `Homepage`,
    copy: `Copyright {authorizedRepresentativeName} © {year}`,
    referralScheme: `Our referral scheme`,
  },
  modal: {
    accept: `Accept and quit`,
    contactUs: `Contact us`,
    back: `Back`,
    continue: `Continue`,
    continueAnyway: `Continue anyway`,
    confirm: `Confirm`,
  },
  resetPasswordModal: {
    title: `Are you sure you wish to initiate a password reset?`,
    description: `{name} will be sent an email requesting them to reset their password.`,
    successMessage: `Password reset email has been sent.`,
  },
  client_nav: {
    dashboard: `My dashboard`,
    adviserProfile: `My Adviser`,
    signOut: `Sign out`,
  },
  navigation: {
    back: `Back`,
    overview: `Overview`,
    caseOverview: `Case overview`,
    documents: `Case documents`,
    financialPlanningGoals: `Financial planning goals`,
    factFind: `Fact Find`,
    riskAssessment: `Risk tolerance assessment`,
    auditTrail: `Audit trail`,
    factFindDocument: `Case Fact Find`,
    manageAdditionalAdvisers: 'Manage additional advisers',
  },
  iamsmart: {
    personalData: `Personal Data from iAM Smart`,
  },
  badge: {
    inactive: 'Inactive',
    inProgress: `In progress`,
    completed: `Completed`,
    submitted: `Submitted`,
    cancelled: `Cancelled`,
    accepted: `Accepted`,
    notAccepted: `Not accepted`,
    client: `Client`,
    actionRequired: `Action Required`,
    yourActionRequired: `Your action required`,
    clientsActionRequired: `Client action required`,
    required: `Required`,
    review: `Review`,
    signatureRequired: `Update required`,
    signed: `Signed`,
    updated: `Updated`,
    expired: `Expired`,
    expiresInDays: `Expires in {value} day(s)`,
    expiresToday: `Expires today`,
    updateWasRequired: `Update was required`,
    docusignSignWasRequired: `DocuSign signature was required`,
    concealed: `Concealed`,
  },
  documents: {
    title: `Documents`,
    sharedBy: `Shared by {name}`,
    update: `Update`,
    enterNumber: `Please enter client’s phone number`,
    mandatory: `Recipient Identity Verification is mandatory for every envelope with this DocuSign account. Please enable 2FA for this document so that it can be successfully uploaded.`,
    phoneDisabled: `‘Phone Authentication’ is not enabled for this DocuSign account. Please contact DocuSign support if you require it to be enabled.`,
    selectOption: `Please select an option`,
    changesNotSaved: `Changes have not been saved`,
    uploadedUsing: `Uploaded using {email} DocuSign account`,
    notEditable: `Previously-editable documents are no longer editable.`,
    notEditableDocuSign: `DocuSign signature is no longer needed.`,
    cannotDisplay: `Cannot display document ‘{file}’. However, you can download it by clicking button below.`,
  },
  goBackToDashboard: `Back to my dashboard`,
  goBackToCaseDetails: `Back to case details`,
  goBackToCaseOverview: `Back to case overview`,
  goBackToReviewQuestionnaireOverview: `Back to questionnaire overview`,
  goBackToOverview: `Back to overview`,
  noOptionsPlaceholder: `No other options`,
  pagination: {
    defaultLabel: `Pagination`,
    prevPage: `Previous page`,
    nextPage: `Next page`,
    prevPages: `Previous {value} pages`,
    nextPages: `Next {value} pages`,
    page: `Page {value}`,
    currentPage: `Current page, page {value}`,
  },
  question: `Question {currentQuestionNumber} of {questionsQty}`,
  signature: {
    sectionText: `Add instruction on how to complete the document`,
    personalSignature: `Personal signature`,
    primarySignature: `My signature`,
    noPrimarySignature: `No signature has been saved.`,
    uploadSignature: `Upload signature's image`,
    addText: `Add text`,
    addInstruction: `Add instruction`,
    addPlaceholder: `Add placeholder`,
    placeholderActions: {
      [PlaceholderAction.AddSignaturePlaceholder]: `Add signature placeholder`,
      [PlaceholderAction.AddTextPlaceholder]: `Add text placeholder`,
    },
    add: `Add signature`,
    save: `Save`,
    saveAndClose: `Save and close`,
    place: `Place`,
    clear: `Clear`,
    update: `Update`,
    saveQuestion: `Are you sure you wish to save?`,
    discardQuestion: `Are you sure you wish to close?`,
    discardQuestionLostChanges: `Are you sure you wish to close? Any unsaved changes will be lost.`,
    discardQuestionLostChangesConnectionClosed: `Are you sure you wish to close? Any unsaved changes will be lost and connection will be closed.`,
    sectionMarkAlt: `section mark`,
    signee: `Author: <b>{signee}</b>`,
    date: `Date: <b>{date}</b>`,
    ipAddress: `IP address: <b>{ipAddress}</b>`,
    howToSignShort: `How to sign?`,
    howToSignLong: `How to sign documents`,
    crop: `You can crop the signature image`,
    signActions: {
      [SignatureActionType.Draw]: `Draw to sign`,
      [SignatureActionType.Type]: `Type to sign`,
      [SignatureActionType.UseSaved]: `Place my saved signature`,
      [SignatureActionType.Upload]: `Upload a signature`,
    },
  },
  fields: {
    firstName: `First Name`,
    firstNamePlaceholder: `e.g. John`,
    lastName: `Last Name`,
    lastNamePlaceholder: `e.g. Doe`,
    name: `Name`,
    dateOfBirth: `Date of birth`,
    title: `Title`,
    roleTitle: `Role title`,
    linkedinUrl: `LinkedIn Profile`,
    facebookUrl: `Facebook Profile`,
    twitterUrl: `Twitter Profile`,
    email: `Email Address`,
    bio: `Bio`,
    emailPlaceholder: `e.g. john.doe@domain.co`,
    emailMessage: `Email Message`,
    bioPlaceholder: `Please add your bio`,
    phone: `Phone Number`,
    phonePlaceholder: `e.g. 0123 456 7899`,
    postCode: `Post code`,
    postCodePlaceholder: `e.g. NG34 8PD`,
    city: `City`,
    cityPlaceholder: `e.g. Manchester`,
    address: `Address`,
    description: `Description`,
    password: `Password`,
    passwordConfirmation: `Password Confirmation`,
    message: `Message`,
    link: `Link`,
    pdfUrl: `PDF URL`,
    image: `Image`,
    country: `Country`,
    countryPlaceholder: `e.g. England`,
    nationality: `Nationality`,
    file: `File`,
    startDate: `Start date`,
    endDate: `End date`,
    comment: `Comment`,
  },
  templates: {
    dropdownLabel: `Complete from template`,
    clientName: `<client_name>`,
    adviserName: `<adviser_name>`,
  },
  help: {
    articles: `Help articles`,
    article: `Article {number}`,
    footer: `Can’t find what you’re looking for? Visit the <a>Help Centre</a>.`,
    technicalSupportNote: `Suspect that there is a technical issue or bug? <a>Request technical support.</a>`,
    button: `Help`,
  },
  pdfError: `An error occurred while loading the document. Please try using a different browser.`,
  dataLoadError: `An error occurred while loading the data. You can click <button>here</button> to try again.`,
  sessionWillExpire: `Your session will expire soon. Click here to extend it.`,
  sessionExpiresIn: `Session expires in{br}{value}`,
  sessionExpiresInTooltip: `Click here to extend your session.`,
  sessionWillNotExpire: `Session will not time out{br}during your meeting`,
  upcomingMaintenanceStartDate: `Start date: {date}`,
  upcomingMaintenanceEndDate: `End date: {date}`,
  changesNotSaved: `Changes have not been saved.`,
  coverNoteChangesNotSaved: `Cover note changes have not been saved.`,
  additionalCountries: {
    england: `England`,
    scotland: `Scotland`,
    northernIreland: `Northern Ireland`,
    wales: `Wales`,
  },
  roles: {
    [UserRoleName.Client]: `Client`,
    [UserRoleName.Adviser]: `Adviser`,
    [UserRoleName.PracticeAdmin]: `Practice admin`,
    [UserRoleName.PracticeDirector]: `Practice director`,
    guest: `Guest`,
  },
  primaryAdviser: {
    title: `Primary adviser`,
  },
  additionalAdvisers: {
    title: `Additional Advisers`,
    manage: `Manage Additional Advisers`,
    auditTrailDescription: `Below are the major events that occurred for additional advisers management.`,
    selectedTitle: `SELECTED ADDITIONAL ADVISERS`,
    selectionTitle: `SELECT OR DESELECT ADDITIONAL ADVISERS`,
    limitError: `! Maximum limit reached. At max 10 additional advisers can be assigned.`,
    addWarning1: `Are you sure you wish to make the following changes?`,
    addNoteDirector: `Additional advisers will have full access to the client. You can remove them anytime in future.`,
    addNoteAdviser: `Additional advisers will have full access to your client. You can remove them anytime in future.`,
    adviserDescription: `Here you can add or remove additional advisers to support you and your client.`,
    directorDescription: `Here you can add or remove additional advisers to support the client.`,
    updateSuccess: `Additional advisers updated`,
    noAdvisers: `No advisers found.`,
    noSearchedAdvisers: `Sorry! No result found. We couldn't find any item matching your search "{searchTerm}".`,
  },
  statusActions: {
    cases: {
      [CaseType.OnlineAdviceRequest]: {
        [`${CaseState.Submitted}_${CaseState.Building}`]: {
          title: `Back to client`,
          description: `If you require the client to change their provided information, click here to change the status back to ‘{to}’.`,
        },
        [`${CaseState.Submitted}_${CaseState.Completed}`]: {
          title: `Complete request`,
          description: `Once satisfied with the request, click here to mark it as complete.`,
        },
        [`${CaseState.Completed}_${CaseState.Submitted}`]: {
          title: `Back to review`,
          description: `If you need to review the request again, click here to change the status back to ‘{to}’.`,
        },
      },
      [CaseType.MeetingRequestCase]: {
        [`${CaseState.Requested}_${CaseState.Completed}`]: {
          title: `Complete request`,
          description: `Once satisfied with the request, click here to change the status to ‘{to}’.`,
        },
        [`${CaseState.Completed}_${CaseState.Requested}`]: {
          title: `Back to review`,
          description: `If you need to review the request again, click here to change the status back to ‘{to}’.`,
        },
      },
      [CaseType.ReviewQuestionnaireCase]: {
        [`${CaseState.Draft}_${CaseState.Building}`]: {
          title: `Send to client`,
          description: `Questionnaire will be sent to client.`,
        },
        [`${CaseState.Building}_${CaseState.Submitted}`]: {
          title: `Submit`,
          description: `To submit this questionnaire on your client’s behalf, click here.`,
        },
        [`${CaseState.Submitted}_${CaseState.Building}`]: {
          title: `Back to client`,
          description: `Client here to send the Questionnaire back to your client for them to complete.`,
        },
        [`${CaseState.Submitted}_${CaseState.Accepted}`]: {
          title: `Review complete`,
          description: `Click here when you are satisfied that you have reviewed the Questionnaire responses.`,
        },
        [`${CaseState.Accepted}_${CaseState.Building}`]: {
          title: `Back to client`,
          description: `Client here to send the Questionnaire back to your client for them to complete.`,
        },
        [`${CaseState.Accepted}_${CaseState.Submitted}`]: {
          title: `Review again`,
          description: `Click here to change the status back to ‘{to}’.`,
        },
      },
      [CaseType.RecommendationPackCase]: {
        [`${CaseState.Submitted}_${CaseState.ReviewingPack}`]: {
          title: `Send Recommendation Pack`,
          description: `Send the recommendation to your client for their review.`,
        },
        [`${CaseState.Submitted}_${CaseState.Accepted}`]: {
          title: `Send already-agreed Recommendation Pack`,
          description: `Provide your client with a copy of an already-agreed recommendation.`,
        },
        [`${CaseState.Submitted}_${CaseState.Accepted}_disabled`]: {
          title: `Send already-agreed Recommendation Pack`,
          description: `Because a DocuSign envelope has been drafted, this Recommendation Pack cannot be sent as already agree. If relevant, please remove the envelope.`,
        },
        [`${CaseState.ReviewingPack}_${CaseState.Submitted}`]: {
          title: `Back to prepare`,
          description: `Click here to re-prepare / modify the recommendation.`,
        },
        [`${CaseState.ReviewingPack}_${CaseState.Accepted}`]: {
          title: `Accept Recommendation Pack`,
          description: `Accept the recommendation on behalf of your client.`,
        },
        [`${CaseState.Accepted}_${CaseState.Submitted}`]: {
          title: `Back to prepare`,
          description: `Click here to re-prepare / modify the recommendation.`,
        },
        [`${CaseState.Accepted}_${CaseState.ReviewingPack}`]: {
          title: `Re-send to the client`,
          description: `If you need to client to reconsider the recommendation, click here.`,
        },
        [`${CaseState.Accepted}_${CaseState.Rejected}`]: {
          title: `Reject the recommendation`,
          description: `Click here to reject the recommendation on your client’s behalf.`,
        },
        [`${CaseState.Accepted}_${CaseState.Confirmed}`]: {
          title: `Complete the case`,
          description: `Click here to mark the case as complete.`,
        },
        [`${CaseState.Rejected}_${CaseState.Submitted}`]: {
          title: `Back to prepare`,
          description: `Click here to change the status back to ‘{to}’.`,
        },
        [`${CaseState.Rejected}_${CaseState.ReviewingPack}`]: {
          title: `Back to review`,
          description: `Click here to change the status back to ‘{to}’.`,
        },
      },
    },
  },
};
