import api from 'api/apiInstance';

export type InvitationResendResponse = number;

export function invitationResendApi(path: string) {
  return {
    path,
    POST: () => api.post<InvitationResendResponse>(path),
  };
}
