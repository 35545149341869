import { documentPacksBulkCreateApi } from './bulkCreate';
import { documentPackApi } from './documentPack';

const apiPath = '/api/v1/documents-packs';

function documentPacksApi(path: string) {
  return {
    path,
    bulkCreate: () => documentPacksBulkCreateApi(`${path}/bulk-create`),
  };
}

type DocumentPackApi = ReturnType<typeof documentPackApi>;
type DocumentPacksApi = ReturnType<typeof documentPacksApi>;

function documentPacks(): DocumentPacksApi;
function documentPacks(documentPackId: number): DocumentPackApi;
function documentPacks(documentPackId?: number): DocumentPacksApi | DocumentPackApi {
  return documentPackId
    ? documentPackApi(`${apiPath}/${documentPackId}`)
    : documentPacksApi(apiPath);
}

export { documentPacks };
