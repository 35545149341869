import { useSelector } from 'react-redux';

import type { AppState } from '../types';
import { initialState } from './reducer';

const getStateSubtree = (state: AppState) => state.guests || initialState;

export const getGuestAuthToken = (state: AppState) => getStateSubtree(state).authToken || null;

export const useGetGuestAuthToken = () => useSelector(getGuestAuthToken);

export const getGuestId = (state: AppState) => getStateSubtree(state).id || null;

export const getPrefixedGuestId = (state: AppState) => {
  const id = getGuestId(state);
  return id ? `guest_${id}` : null;
};
