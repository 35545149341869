import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions } from 'api/types';

export interface ClientCaseFinancialPlanningGoalsPatchOptions {
  name: string;
  amount: number;
  amountCurrency: string;
  duration: number;
}
export type ClientCaseFinancialPlanningGoalsPatchResponse = JsonApiResponseSingle;

export function clientCaseFinancialPlanningGoalApi(path: string) {
  return {
    path,
    GET: (params?: GetOptions) => api.get<JsonApiResponseSingle>(path, { params }),
    PATCH: ({
      name,
      amount,
      amountCurrency,
      duration,
    }: ClientCaseFinancialPlanningGoalsPatchOptions) =>
      api.patch<ClientCaseFinancialPlanningGoalsPatchResponse>(path, {
        data: {
          attributes: {
            name,
            amount: Number(amount),
            amount_currency: amountCurrency,
            duration: Number(duration),
          },
        },
      }),
    DELETE: () => api.delete(path),
  };
}
