export default {
  title: `How it works`,
  summary: `Three simple steps to become a client and to plan for your future`,
  section1: {
    title: `Provide us with a basic overview of your financial planning needs and objectives.`,
  },
  section2: {
    title: `We connect you with an Adviser.`,
    subtitle: `The Adviser will meet you either in person, or in a video meeting, to discuss your financial planning needs and objectives.`,
    minimumSize: `Minimum investment size:`,
    initialInvestment: `initial investment`,
    monthlyInvestment: `monthly investment`,
    initialInvestmentInfo: `{price} {initialInvestment}`,
    monthlyInvestmentInfo: ` or {price} {monthlyInvestment}`,
  },
  section3: {
    title: `Plan for your future.`,
    subtitle: `After an initial meeting, we will help you to plan for your future and to implement our planning recommendations.`,
  },
};
