import React from 'react';

import SvgIcon from './SvgIcon';

const IconRoomMonitor = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="M17.9912 12.0076C17.9912 10.9148 18.8742 10.0039 19.9671 10.0039V10.0039C21.0599 10.0039 21.9488 10.8853 21.9488 11.9782V11.9782C21.9488 13.0711 21.0747 13.9701 19.9818 13.9701V13.9701C18.8889 13.9701 17.9912 13.1005 17.9912 12.0076V12.0076Z"
      fill="currentColor"
    />
    <rect
      x="17.9932"
      y="16.4531"
      width="3.94648"
      height="13.5786"
      rx="1.97324"
      fill="currentColor"
    />
    <circle cx="20" cy="20" r="18.5" stroke="currentColor" strokeWidth="3" />
  </SvgIcon>
);

export default IconRoomMonitor;
