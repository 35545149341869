import { showWarningToast } from './toasts';

let localStorageAvailable = true;

function handleError(error: Error) {
  if (error.name === 'SecurityError' && localStorageAvailable) {
    localStorageAvailable = false;
    showWarningToast('errors.localStorageNotAvailable');
    window.Rollbar?.warning(error);
  }
}

export function setLocalStorageValue<T>(key: string, value: T) {
  try {
    localStorage?.setItem(key, JSON.stringify(value));
  } catch (error) {
    handleError(error);
  }
}

export function getLocalStorageValue<T>(key: string): T | null {
  try {
    const value = localStorage?.getItem(key);

    return value ? JSON.parse(value) : null;
  } catch (error) {
    handleError(error);
  }

  return null;
}
