import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

import { breakpoints } from 'helpers/styles/constants';
import IconPoweredByDws from 'components/Icons/IconPoweredByDws';

const Image = styled(IconPoweredByDws)`
  width: ${rem(200)};
  height: ${rem(16)};
  vertical-align: middle;

  @media ${breakpoints.smUp} {
    width: ${rem(240)};
    height: ${rem(18)};
  }
`;

export function DwsLogo() {
  return (
    <a href="https://www.digitalwealthsolutions.co.uk/" target="_blank" rel="noopener noreferrer">
      <Image />
    </a>
  );
}
