import React, { PureComponent, ReactNode } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { throttle } from 'lodash';
import { position } from 'polished';
import styled from 'styled-components';

import { misc } from 'helpers/styles/constants';
import { isBrowser } from 'helpers/utils';

const ScrollShadowPanelWrapper = styled.div<{
  hasTopShadow: boolean;
  hasBottomShadow: boolean;
}>`
  position: relative;

  &::before,
  &::after {
    ${position('absolute', 0, 0, 0, 0)};
    content: '';
    z-index: ${misc.zIndex1};
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s linear;
    transform: translateZ(0);
  }

  &::before {
    box-shadow: inset 0 6px 10px -8px ${({ theme }) => theme.colors.black};

    ${({ hasTopShadow }) => hasTopShadow && { opacity: 1 }};
  }

  &::after {
    box-shadow: inset 0 -6px 10px -8px ${({ theme }) => theme.colors.black};

    ${({ hasBottomShadow }) => hasBottomShadow && { opacity: 1 }};
  }
`;

const ScrollShadowPanelWrapperInner = styled.div`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
`;

interface Props {
  className?: string;
  innerClassName?: string;
  children: ReactNode;
  onScrolledToBottom?: () => void;
  onScrolledToTop?: () => void;
}

interface State {
  hasTopShadow: boolean;
  hasBottomShadow: boolean;
}

class ScrollShadowPanel extends PureComponent<Props, State> {
  private contentRef = React.createRef<HTMLDivElement>();

  private checkScrollPositionThrottled = throttle(
    event => this.checkScrollPosition(event.target),
    200
  );

  public state = {
    hasTopShadow: false,
    hasBottomShadow: false,
  };

  public componentDidMount() {
    if (isBrowser() && this.contentRef.current) {
      this.contentRef.current.addEventListener('scroll', this.checkScrollPositionThrottled);
      this.checkScrollPosition(this.contentRef.current);
    }
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    const { children, onScrolledToTop, onScrolledToBottom } = this.props;
    const { hasTopShadow, hasBottomShadow } = this.state;

    if (isBrowser() && this.contentRef.current && prevProps.children !== children) {
      this.checkScrollPosition(this.contentRef.current);
    }

    if (onScrolledToTop && prevState.hasTopShadow && !hasTopShadow) {
      onScrolledToTop();
    }

    if (onScrolledToBottom && prevState.hasBottomShadow && !hasBottomShadow) {
      onScrolledToBottom();
    }
  }

  public componentWillUnmount() {
    if (isBrowser() && this.contentRef.current) {
      this.contentRef.current.removeEventListener('scroll', this.checkScrollPositionThrottled);
    }

    this.checkScrollPositionThrottled.cancel();
  }

  public get scrollTop() {
    return this.contentRef.current?.scrollTop || 0;
  }

  public get scrollHeight() {
    return this.contentRef.current?.scrollHeight || 0;
  }

  public get clientHeight() {
    return this.contentRef.current?.clientHeight || 0;
  }

  public get hasVerticalScrollbar() {
    return this.scrollHeight > this.clientHeight;
  }

  public get isAtTop() {
    if (!this.contentRef.current) {
      return false;
    }

    return this.contentRef.current.scrollTop === 0;
  }

  public get isAtBottom() {
    if (!this.contentRef.current) {
      return false;
    }

    return (
      this.contentRef.current.scrollTop >=
      this.contentRef.current.scrollHeight - this.contentRef.current.offsetHeight
    );
  }

  private checkScrollPosition = (element: HTMLDivElement) => {
    this.setState({
      hasTopShadow: element.scrollTop > 0,
      hasBottomShadow: element.scrollTop < element.scrollHeight - element.offsetHeight,
    });
  };

  public scrollToBottom = () => {
    const { onScrolledToBottom } = this.props;

    if (this.contentRef.current) {
      this.contentRef.current.scrollTop = this.contentRef.current.scrollHeight;
      this.checkScrollPosition(this.contentRef.current);
    }

    if (onScrolledToBottom) {
      onScrolledToBottom();
    }
  };

  public scrollTo = (value: number) => {
    if (this.contentRef.current) {
      this.contentRef.current.scrollTop = value;
      this.checkScrollPosition(this.contentRef.current);
    }
  };

  public render() {
    const { className, innerClassName, children } = this.props;
    const { hasTopShadow, hasBottomShadow } = this.state;

    return (
      <ScrollShadowPanelWrapper
        className={className}
        hasTopShadow={hasTopShadow}
        hasBottomShadow={hasBottomShadow}
      >
        <ScrollShadowPanelWrapperInner className={innerClassName} ref={this.contentRef}>
          <ReactResizeDetector
            onResize={() => {
              if (this.contentRef.current) {
                this.checkScrollPosition(this.contentRef.current);
              }
            }}
            refreshMode="throttle"
            refreshRate={150}
            refreshOptions={{ leading: false, trailing: true }}
            handleHeight
          />

          {children}
        </ScrollShadowPanelWrapperInner>
      </ScrollShadowPanelWrapper>
    );
  }
}

export default ScrollShadowPanel;
