import React, { useContext } from 'react';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { BreakpointName, breakpointNameArray, BreakpointValues } from 'helpers/responsiveness';
import { breakpoints } from 'helpers/styles/constants';

import { GridContext } from './Grid';
import { RowContext } from './Row';

type Breakpoints = BreakpointValues<number>;

const hasWidth = (widths: Breakpoints) =>
  (Object.keys(widths) as BreakpointName[]).reduce(
    (acc, cur) => acc || Boolean(widths[cur]),
    false
  );

const getWidth = (width: number | undefined, gridColumns: number) => {
  if (typeof width !== 'number') {
    return undefined;
  }

  const normalizedWidth = Math.max(0, Math.min(gridColumns, width));

  return `${(100 / gridColumns) * normalizedWidth}%`;
};

const breakpoint = (size: BreakpointName) => css`
  ${({ pull = {}, push = {}, columnWidth = {}, offset = {}, gridColumns }: WrapperProps) =>
    columnWidth[size] &&
    css`
      @media ${breakpoints[size]} {
        right: ${getWidth(pull[size], gridColumns) || 'auto'};
        left: ${getWidth(push[size], gridColumns) || 'auto'};
        flex-basis: ${getWidth(columnWidth[size], gridColumns) || '100%'};
        max-width: ${getWidth(columnWidth[size], gridColumns) || '100%'};
        margin-left: ${getWidth(offset[size], gridColumns) || 0};
      }
    `};
`;

interface WrapperProps {
  columnWidth: Breakpoints;
  gutterWidth: number;
  gridColumns: number;
  offset?: Breakpoints;
  push?: Breakpoints;
  pull?: Breakpoints;
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  padding-left: ${({ gutterWidth }) => rem(gutterWidth / 2)};
  padding-right: ${({ gutterWidth }) => rem(gutterWidth / 2)};
  width: 100%;

  ${({ columnWidth }) =>
    hasWidth(columnWidth)
      ? breakpointNameArray.map(size => breakpoint(size))
      : css`
          flex-basis: 0;
          flex-grow: 1;
        `};
`;

interface Props extends Breakpoints {
  children?: React.ReactNode | React.ReactNode[];
  offset?: Breakpoints;
  push?: Breakpoints;
  pull?: Breakpoints;
}

export function Column({ children, offset, push, pull, ...columnWidth }: Props) {
  const { columns, gutterWidth } = useContext(GridContext);
  const { noGutter } = useContext(RowContext);

  return (
    <Wrapper
      columnWidth={columnWidth}
      gutterWidth={noGutter ? 0 : gutterWidth}
      gridColumns={columns}
      offset={offset}
      push={push}
      pull={pull}
    >
      {children}
    </Wrapper>
  );
}
