import React from 'react';
import type { PDFAnnotationData } from 'pdfjs-dist/types/src/display/api';
import styled from 'styled-components';

const Link = styled.a`
  display: block;
  height: 100%;
  width: 100%;
`;

export interface Props {
  data: PDFAnnotationData;
}

export function LinkWidget({ data }: Props) {
  const { url } = data;

  if (!url) {
    return null;
  }

  return <Link href={url} target="_blank" rel="noopener noreferrer" />;
}
