import React, { useContext } from 'react';

import type { UpdateRecordingStateParams } from 'api/adviceRooms/adviceRoom/recordings/updateRecordingState';

export enum RecordingType {
  Video = 'video',
  Audio = 'audio',
}

class RecordingContextError extends Error {
  constructor() {
    super('You tried to use RecordingContext outside the scope of the provider.');
  }
}
export interface ContextValue {
  loading: boolean;
  recordingType: RecordingType | undefined;
  updateRecordingState?: (params: UpdateRecordingStateParams) => Promise<RecordingType | undefined>;
}

const defaultContextValue: ContextValue = {
  loading: false,
  recordingType: RecordingType.Audio,
  updateRecordingState: () => {
    throw new RecordingContextError();
  },
};

export const RecordingContext = React.createContext<ContextValue>(defaultContextValue);

export const useRecordingContext = () => useContext(RecordingContext);
