import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions } from 'api/types';

import { clientCaseFinancialPlanningHealthChecksMarkAsSeenApi } from './markAsCompleted';
import { clientCaseFinancialPlanningHealthChecksPrintApi } from './print';

export interface ClientCaseFinancialPlanningHealthChecksPostOptions {
  answers: Record<string, unknown>;
}

export interface ClientCaseFinancialPlanningHealthChecksPatchOptions {
  answers: Record<string, unknown>;
}

export function clientCaseFinancialPlanningHealthChecksApi(path: string) {
  return {
    path,
    print: () => clientCaseFinancialPlanningHealthChecksPrintApi(`${path}/print`),
    markAsCompleted: () =>
      clientCaseFinancialPlanningHealthChecksMarkAsSeenApi(`${path}/mark-as-completed`),
    GET: (params?: GetOptions) => api.get<JsonApiResponseSingle>(path, { params }),
    POST: ({ answers }: ClientCaseFinancialPlanningHealthChecksPostOptions) =>
      api.post<JsonApiResponseSingle>(path, {
        data: {
          attributes: {
            answers,
          },
        },
      }),
    PATCH: ({ answers }: ClientCaseFinancialPlanningHealthChecksPatchOptions) =>
      api.patch<JsonApiResponseSingle>(path, {
        data: {
          attributes: {
            answers,
          },
        },
      }),
  };
}
