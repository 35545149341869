import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

export interface UserUpdateAvatarPatchOptions {
  file: File;
}

export function userUpdateAvatarApi(path: string) {
  return {
    path,
    PATCH: ({ file }: UserUpdateAvatarPatchOptions) =>
      api.patch<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData({
              file,
            }),
          },
        })
      ),
  };
}
