import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, RequestConfig } from 'api/types';
import { mapFormData } from 'helpers/formData';

export type InvitationsClientsGetOptions = GetOptions & {
  search?: string;
};

export interface InvitationsClientsPostOptions {
  firstName: string;
  lastName: string;
  email: string;
  emailMessage: string;
  onboardingMessage: string;
  adviserId?: number;
}

export function invitationsClientsApi(path: string) {
  return {
    path,
    GET: (params?: InvitationsClientsGetOptions, config?: RequestConfig) =>
      api.get<JsonApiResponse>(path, { params, ...config }),
    POST: (params: InvitationsClientsPostOptions) =>
      api.post<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
