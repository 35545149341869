import api from 'api/apiInstance';

export interface ClientCaseProgressGetApiResponse {
  fact_find_done: boolean;
  financial_audit_done: boolean;
  financial_planning_health_check_done: boolean;
  financial_planning_goals_done: boolean;
  isa_done: boolean;
  portfolio_done: boolean;
  risk_assessment_done: boolean;
}

export function clientCaseProgressApi(path: string) {
  return {
    path,
    GET: () => api.get<ClientCaseProgressGetApiResponse>(path),
  };
}
