import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function UnpinIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 0 193.8 193.8" {...props}>
      <path
        fill="currentColor"
        d="M191.5 55.5l-54-54a5 5 0 00-7.1 0l-.2.2a17.4 17.4 0 00-3 20.9L70.7 71c-32.8 8.4-13.8 3.3-32.8 8.4l32.4 32.3-68.2 73.7a5 5 0 007 7L83 124.5l30.6 30.6 8.3-32.7 48.6-56.5a17.4 17.4 0 0020.9-3l.2-.2c2-2 2-5.1 0-7z"
      />
      <path stroke="currentColor" strokeWidth="16" strokeLinecap="round" d="M5 5l183.8 183.8" />
    </SvgIcon>
  );
}
