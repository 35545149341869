import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions } from 'api/types';

export function adviceRoomDocumentShareApi(path: string) {
  return {
    path,
    GET: (params?: GetOptions) => api.get<JsonApiResponse>(path, { params }),
  };
}
