import { technicalSupportSendRequestApi } from './sendRequest';

const apiPath = '/api/v1/technical-support';

export function technicalSupportApi(path: string) {
  return {
    path,
    sendRequest: () => technicalSupportSendRequestApi(`${path}/send-request`),
  };
}

export function technicalSupport() {
  return technicalSupportApi(apiPath);
}
