import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

export interface AdviceRoomGuestsPostOptions {
  firstName: string;
  lastName: string;
  email: string;
  trusted?: boolean;
  coverNote?: boolean;
}

export function adviceRoomGuestsApi(path: string) {
  return {
    path,
    POST: (params: AdviceRoomGuestsPostOptions) =>
      api.post<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
