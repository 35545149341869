import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

export interface AdviceRoomRecordingSharePatchOptions {
  sharedWithClient: boolean;
}

export function adviceRoomRecordingShareApi(path: string) {
  return {
    path,
    PATCH: (params: AdviceRoomRecordingSharePatchOptions) => {
      return api.patch(path, {
        data: {
          attributes: mapFormData(params),
        },
      });
    },
  };
}
