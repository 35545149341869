import api from 'api/apiInstance';
import { objectToURLParams } from 'helpers/utils';

export interface ClientCaseIBusinessPackDownloadSummaryZipGetOptions {
  documentPackDocuments: number[];
  documents: number[];
  addenda: number[];
  financialPlanningGoalsPdf: boolean;
  factFindPdf: boolean;
  riskAssessmentPdf: boolean;
  [key: string]: string | number | boolean | number[] | string[] | undefined;
}

export function clientCaseIBusinessPackDownloadSummaryZipApi(path: string) {
  const generatePath = (params: ClientCaseIBusinessPackDownloadSummaryZipGetOptions) => {
    const options = {
      'data[attributes][document_pack_documents]': params.documentPackDocuments,
      'data[attributes][documents]': params.documents,
      'data[attributes][addenda]': params.addenda,
      ...(params.financialPlanningGoalsPdf
        ? { 'data[attributes][financial_planning_goals_pdf]': true }
        : {}),
      ...(params.factFindPdf ? { 'data[attributes][fact_find_pdf]': true } : {}),
      ...(params.riskAssessmentPdf ? { 'data[attributes][risk_assessment_pdf]': true } : {}),
    };

    return `${path}?${objectToURLParams(options)}`;
  };

  return {
    path,
    GET: (params: ClientCaseIBusinessPackDownloadSummaryZipGetOptions) =>
      api.get(generatePath(params)),
    GETPath: (params: ClientCaseIBusinessPackDownloadSummaryZipGetOptions) => generatePath(params),
  };
}
