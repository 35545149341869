import 'react-phone-input-2/lib/style.css';

import { rem, size } from 'polished';
import { createGlobalStyle } from 'styled-components';

import { breakpoints, misc } from 'helpers/styles/constants';

const CoreStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 90%;

    @media ${breakpoints.lgUp} {
      font-size: 95%;
    }

    @media ${breakpoints.xlUp} {
      font-size: 100%;
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    width: 100%;
    padding: 0;
    line-height: 1;
    background-color: ${({ theme }) => theme.colors.bodyBg};
    color: ${({ theme }) => theme.colors.textDark};
    -webkit-font-smoothing: antialiased;
  }

  body,
  button,
  input,
  select,
  textarea {
    font-family: ${({ theme }) => theme.fontFamilies.main};
  }

  input,
  textarea {
    border-radius: 0;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      padding: 0;
    }
  }

  img {
    ${size('auto', '100%')};
    display: block;
  }

  input,
  select,
  textarea {
    outline: none;
    box-shadow: none;
  }

  input {
    border-width: 0 0 1px;

    &::placeholder {
      font-style: italic;
      opacity: 0.3;
      color: ${({ theme }) => theme.colors.textDark};
    }
  }

  button {
    border-style: none;
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;
  }

  .btn {
    display: inline-block;
    padding: ${rem(18)} ${rem(46)};
    vertical-align: top;
    font-family: ${({ theme }) => theme.fontFamilies.sec};
    font-size: ${({ theme }) => theme.fontSizes.btnSm};
    text-align: center;
    color: ${({ theme }) => theme.colors.btn};
    background-color: ${({ theme }) => theme.colors.accent};
    cursor: pointer;
    transition: background-color 0.3s ease, opacity 0.3s ease;

    @media ${breakpoints.smUp} {
      font-size: ${({ theme }) => theme.fontSizes.btn};
    }

    &:disabled {
      opacity: 0.4;
      cursor: default;
    }

    &:not(:disabled):hover {
      background-color: ${({ theme }) => theme.colors.accentHover};
    }

    &--default {
      color: ${({ theme }) => theme.colors.accent};
      background-color: transparent;
      border: ${rem(2)} solid ${({ theme }) => theme.colors.accent};

      &:not(:disabled):hover {
        color: ${({ theme }) => theme.colors.accentHover};
        background-color: transparent;
        border-color: ${({ theme }) => theme.colors.accentHover};
      }
    }

    &--main {
      min-width: ${rem(misc.dashboardBtnWidthLg)};
      font-family: ${({ theme }) => theme.fontFamilies.main};
      font-weight: ${({ theme }) => theme.fontWeights.mainMedium};
      font-size: ${({ theme }) => theme.fontSizes.dbNormal};

      &:disabled + .btn-label {
        opacity: 0.4;
      }
    }
  }

  .faq {
    &__u-list {
      margin: 10px 20px 0;
      list-style: circle;
    }

    &__link {
      position: relative;
      display: inline;
      color: ${({ theme }) => theme.colors.black};

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
  #TwilioVideoRoomMonitorContainer span + div div:last-child {
    display:none;
  }

  #TwilioVideoRoomMonitorContainer > div div:first-child > span {
    visibility: hidden;
  }

  fieldset {
    padding: 0;
    margin: 0;
    border-width: 0;
  }
`;

export default CoreStyles;
