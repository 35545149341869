import api from 'api/apiInstance';

import { invitationFollowsApi } from './follows';
import { invitationResendApi } from './resend';

export function invitationApi(path: string) {
  return {
    path,
    follows: () => invitationFollowsApi(`${path}/follows`),
    resend: () => invitationResendApi(`${path}/resend`),
    DELETE: () => api.delete(path),
  };
}
