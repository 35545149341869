import api from 'api/apiInstance';
import type { RequestConfig } from 'api/types';

const apiPath = '/api/v1/oauth_accounts';

function oauthAccountsSettingApi(path: string) {
  return {
    path,
    GET: (config?: RequestConfig) => api.get(path, config),
  };
}

function oauthAccountSettings(accountId: number) {
  return oauthAccountsSettingApi(`${apiPath}/${accountId}/settings`);
}

export { oauthAccountSettings };
