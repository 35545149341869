import { useMemo } from 'react';
import { map } from 'lodash';

import { useGetPracticeSettings } from 'store/settings/selectors';
import type { Currency } from 'types/entities/currency';

export function useGetAvailableCurrencies(): Currency[] {
  const { availableCurrencies } = useGetPracticeSettings();

  return useMemo(() => map(availableCurrencies, code => ({ code })), [availableCurrencies]);
}
