import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import { mapFormData } from 'helpers/formData';

export enum MessageType {
  PracticeMessage = 'practice_note',
  ClientMessage = 'client_note',
}

interface UserMessagesMarkAllAsSeenPatchOptions {
  messageType: MessageType;
}

export function userMessagesMarkAllAsSeenApi(path: string) {
  return {
    path,
    PATCH: (data: UserMessagesMarkAllAsSeenPatchOptions) =>
      api.patch<JsonApiResponse>(path, {
        data: {
          attributes: mapFormData(data),
        },
      }),
  };
}
