import { rem } from 'polished';
import styled from 'styled-components';

interface Props {
  isPulledTop?: boolean;
}

export const ErrorMessage = styled.div<Props>`
  margin-top: ${({ isPulledTop }) => rem(isPulledTop ? -50 : -10)};
  font-size: ${({ theme }) => theme.fontSizes.dbSmall};
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.crail};
`;
