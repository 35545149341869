import { useSelector } from 'react-redux';

import type { AppState } from '../types';

export const getIsExpirationSessionBannerVisible = (state: AppState) =>
  state.sessionExpiration.isBannerVisible;

export const getLastRequestTimestamp = (state: AppState) =>
  state.sessionExpiration.lastRequestTimestamp;

export const useGetLastRequestTimestamp = () => useSelector(getLastRequestTimestamp);
