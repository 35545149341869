import type { JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, RequestConfig } from 'api/types';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

export type AgendaTemplateGetOptions = GetOptions;

export interface AgendaTemplatePatchOptions {
  title?: string;
  practiceId: number;
  template?: string;
  default?: boolean;
  agendaType: string;
  active: boolean;
}

export function agendaTemplateApi(path: string) {
  return {
    path,
    GET: (params?: AgendaTemplateGetOptions, config?: RequestConfig) =>
      api.get<JsonApiResponseSingle>(path, { params, ...config }),
    PATCH: (params: AgendaTemplatePatchOptions) =>
      api.patch<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        })
      ),
    DELETE: () => api.delete(path),
  };
}
