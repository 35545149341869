import React from 'react';
import { useField } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { position, rem } from 'polished';
import styled, { css } from 'styled-components';

import { breakpoints, misc } from 'helpers/styles/constants';

const Wrapper = styled.div`
  margin-top: ${rem(20)};
  margin-bottom: ${rem(60)};

  @media ${breakpoints.xsUp} {
    display: flex;
  }
`;

const Button = styled.button.attrs({ type: 'button' })<{ isActive?: boolean }>`
  position: relative;
  width: 100%;
  padding: ${rem(15)} ${rem(20)};
  font-size: ${({ theme }) => theme.fontSizes.dbNormal};
  font-weight: ${({ theme }) => theme.fontWeights.mainMedium};
  border: 1px solid ${({ theme }) => theme.colors.mischka};
  outline: none;
  transition: border-color 0.3s ease, z-index 0.3s ease, background-color 0.3s ease;

  @media ${breakpoints.xsUp} {
    width: ${rem(misc.dashboardBtnWidthMd)};
  }

  @media ${breakpoints.mdUp} {
    width: ${rem(misc.dashboardBtnWidthLg)};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      z-index: ${misc.zIndex1};
      background: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.main};

      &::before {
        opacity: 1;
      }
    `};

  &::before {
    ${position('absolute', null, 0, 0, 0)};
    content: '';
    height: 1px;
    background-color: ${({ theme }) => theme.colors.main};
    opacity: 0;
    transition: 0.3s ease opacity;
  }

  &:first-child {
    margin-bottom: ${rem(10)};

    @media ${breakpoints.xsUp} {
      margin-bottom: 0;
    }
  }

  &:hover {
    z-index: ${misc.zIndex1};
    border-color: ${({ theme }) => theme.colors.bermudaGray};
  }

  & + & {
    position: relative;
    left: -1px;
  }
`;

export interface Props {
  name: string;
  yesButtonName?: string;
  noButtonName?: string;
  onClick?: (value: boolean) => void;
  isDisabled?: boolean;
}

const YesNoButtons = ({ name: fieldName, onClick, isDisabled = false }: Props) => {
  // https://final-form.org/docs/react-final-form/types/FieldProps#format
  const { input } = useField<boolean | undefined>(fieldName, { format: v => v });

  return (
    <Wrapper>
      {[
        { name: `${fieldName}-no`, text: 'onboarding.shared.no', value: false },
        { name: `${fieldName}-yes`, text: 'onboarding.shared.yes', value: true },
      ].map(({ value, text, name }) => (
        <Button
          key={name}
          name={name}
          isActive={value === input.value}
          onClick={() => {
            input.onChange(value);

            if (onClick) {
              onClick(value);
            }
          }}
          disabled={isDisabled}
        >
          <FormattedMessage id={text} />
        </Button>
      ))}
    </Wrapper>
  );
};

export default YesNoButtons;
