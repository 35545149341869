import { useCallback, useEffect, useRef, useState } from 'react';
import TwilioVideo, { Room } from 'twilio-video';

import { AUDIO_TRACK_NAME } from 'api/twilio/twilioVideo';
import { useMicrophonePermissions } from 'components/TestSetupTwilio/components/useDevicesPermissions';
import { DevicePermissions } from 'components/TestSetupTwilio/types';

import type { Toggle } from './types';
import { useSelectedAudioDevice } from './useDevices';

export default function useAudioTracks(room: Room | undefined) {
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);

  const { selectedAudioDeviceId } = useSelectedAudioDevice();
  const { microphonePermissions } = useMicrophonePermissions();

  const prevPermissionsRef = useRef<DevicePermissions>(microphonePermissions);

  useEffect(() => {
    if (!room) return;
    if (!selectedAudioDeviceId) {
      room.localParticipant.audioTracks.forEach(({ track, trackName }) => {
        if (trackName === AUDIO_TRACK_NAME) {
          track.disable();
        }
      });
      setIsAudioEnabled(false);
      return;
    }

    let hasDeviceChanged = false;
    const hasPermissionsChanged =
      prevPermissionsRef.current !== DevicePermissions.Unknown &&
      microphonePermissions !== DevicePermissions.Unknown &&
      prevPermissionsRef.current !== microphonePermissions;

    prevPermissionsRef.current = microphonePermissions;

    if (
      microphonePermissions === DevicePermissions.SystemDenied ||
      microphonePermissions === DevicePermissions.Denied
    ) {
      setIsAudioEnabled(false);
    }

    let hasTrack = false;
    room.localParticipant.audioTracks.forEach(({ track, trackName }) => {
      if (trackName === AUDIO_TRACK_NAME) {
        hasTrack = true;
        hasDeviceChanged = track.mediaStreamTrack.getSettings().deviceId !== selectedAudioDeviceId;

        if (hasDeviceChanged || hasPermissionsChanged) {
          track.restart({ deviceId: selectedAudioDeviceId });
        }
      }
    });

    if (!hasTrack) {
      TwilioVideo.createLocalAudioTrack({
        name: AUDIO_TRACK_NAME,
        deviceId: selectedAudioDeviceId,
      }).then(audioTrack => {
        audioTrack.disable();
        room.localParticipant.publishTracks([audioTrack]);
      });
    }
  }, [selectedAudioDeviceId, microphonePermissions, room]);

  useEffect(() => {
    room?.localParticipant.audioTracks.forEach(({ track, trackName }) => {
      if (trackName === AUDIO_TRACK_NAME) {
        track.enable(isAudioEnabled);
      }
    });
  }, [isAudioEnabled, room]);

  const toggleAudioTracks = useCallback<Toggle>(
    shouldEnable => {
      if (
        microphonePermissions === DevicePermissions.SystemDenied ||
        microphonePermissions === DevicePermissions.Denied
      ) {
        return;
      }

      setIsAudioEnabled(prevValue => (shouldEnable != null ? shouldEnable : !prevValue));
    },
    [microphonePermissions]
  );

  return { isAudioEnabled, toggleAudioTracks };
}
