import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { LocalAudioTrackPublication, RemoteAudioTrackPublication } from 'twilio-video';

import IconMicrophone from 'components/Icons/Chat/IconMicrophone';

import { DeviceDisabledIndicator } from './DeviceDisabledIndicator';

interface Props {
  audioTrack: LocalAudioTrackPublication | RemoteAudioTrackPublication | undefined;
}

export function MicrophoneDisabledIndicator({ audioTrack }: Props) {
  const isEnabled = audioTrack?.track?.isEnabled;

  if (isEnabled) {
    return null;
  }

  return (
    <DeviceDisabledIndicator
      tooltipMessage={<FormattedMessage id="adviceRooms.microphoneDisabled" />}
      icon={IconMicrophone}
    />
  );
}
