export const STEP_BEGIN = 'StepBegin';
export const STEP_QUESTIONNAIRE = 'StepQuestionnaire';
export const STEP_SUMMARY = 'StepSummary';

export const RISK_ASSESSMENT_OPTIONS = [
  'stronglyDisagree',
  'disagree',
  'neitherAgreeNorDisagree',
  'agree',
  'stronglyAgree',
];

export const RISK_ASSESSMENT_ALL_DOTS = 5;
