import type { Coordinates } from 'lazy-brush';
import type { ListItem } from 'twilio-sync';

export type Point = Coordinates;

export interface Line {
  points: Point[];
  brushColor: string;
  brushRadius: number;
  tool?: Tool;
}

export interface SketchpadSyncListItemData {
  data: string;
}

export type SketchpadSyncListItem = ListItem<SketchpadSyncListItemData>;

export enum Tool {
  Pen,
  Eraser,
  Highlighter,
}

export enum BrushRadius {
  Small = 1,
  Medium = 2,
  Large = 4,
}
