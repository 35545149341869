import React from 'react';
import styled from 'styled-components';

import { FormattedDate } from 'components/FormattedDateTime/FormattedDate';
import { FormattedTime } from 'components/FormattedDateTime/FormattedTime';

const Value = styled.span`
  white-space: nowrap;
`;

interface Props {
  value?: string | null;
}

const DateTime = ({ value }: Props) => (
  <>
    {value ? (
      <>
        <Value>
          <FormattedDate value={value} dateStyle="medium" />
        </Value>{' '}
        <Value>
          <FormattedTime value={value} timeStyle="short" />
        </Value>
      </>
    ) : (
      '-'
    )}
  </>
);

export default DateTime;
