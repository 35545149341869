import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions, PaginationOptions, RequestConfig } from 'api/types';
import { convertJsonToFormData, mapFormData } from 'helpers/formData';

import { clientClientMessagesMarkAllAsSeenApi } from './markAllAsSeen';
import { clientClientMessagesMarkAsSeenApi } from './markAsSeen';

export interface ClientClientMessagesPostOptions {
  content?: string;
  files?: File[] | null;
  sendEmail?: boolean;
}

export type ClientClientMessagesGetOptions = GetOptions &
  PaginationOptions & {
    cursor?: string;
  };
export type ClientClientMessagesGetResponse = JsonApiResponse & {
  meta: { unread_client_notes_count: number; unread_client_practice_notes_count: number };
};

export function clientClientMessagesApi(path: string) {
  return {
    path,
    markAllAsSeen: () => clientClientMessagesMarkAllAsSeenApi(`${path}/mark-all-as-seen`),
    markAsSeen: () => clientClientMessagesMarkAsSeenApi(`${path}/mark-as-seen`),
    GET: (
      { pageSize, pageNumber, ...rest }: ClientClientMessagesGetOptions,
      config?: RequestConfig
    ) =>
      api.get<ClientClientMessagesGetResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...rest,
        },
        ...config,
      }),
    POST: (params: ClientClientMessagesPostOptions) =>
      api.post<JsonApiResponseSingle>(
        path,
        convertJsonToFormData({
          data: {
            attributes: mapFormData(params),
          },
        }),
        {
          params: {
            include: 'creator',
          },
        }
      ),
  };
}
