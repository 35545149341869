import React, { forwardRef } from 'react';
import Reaptcha, { ReaptchaProps } from 'reaptcha';

import { getRequiredError } from 'helpers/validators';
import { useGetPracticeSettings } from 'store/settings/selectors';
import GenericErrorMsg from 'components/GenericErrorMsg/GenericErrorMsg';

export interface Props
  extends Pick<ReaptchaProps, 'onLoad' | 'onExpire' | 'onVerify' | 'children'> {
  hasError?: boolean;
}

export type RefType = Reaptcha;

const GenericRecaptcha = forwardRef<Reaptcha, Props>(
  ({ onLoad, onVerify, onExpire, hasError, children }, ref) => {
    const { recaptchaAuthToken } = useGetPracticeSettings();

    return (
      <div data-test="recaptcha">
        <Reaptcha
          ref={ref}
          sitekey={recaptchaAuthToken || ''}
          onLoad={onLoad}
          onVerify={onVerify}
          onExpire={onExpire}
          // eslint-disable-next-line react/no-children-prop
          children={children}
          explicit
        />

        {hasError && <GenericErrorMsg message={getRequiredError()} />}
      </div>
    );
  }
);

export default GenericRecaptcha;
