import type { AnyAction } from 'redux';

import { RoomConnectionState } from 'components/VideoChat/RoomConnectionState';

import { SYNC_ADVICE_ROOM_CONNECTION_STATE, SYNC_CONNECTION_STATE } from './actionTypes';
import type { VideoChatActionTypes, VideoChatState } from './types';

export const initialState: VideoChatState = {
  isConnectedToRoom: false,
  adviceRoomConnectionState: RoomConnectionState.Disconnected,
  joinedAdviceRoomId: undefined,
};

export default (state = initialState, incomingAction: AnyAction): VideoChatState => {
  const action = incomingAction as VideoChatActionTypes;

  switch (action.type) {
    case SYNC_CONNECTION_STATE:
      return {
        ...state,
        isConnectedToRoom: action.payload,
      };
    case SYNC_ADVICE_ROOM_CONNECTION_STATE:
      return {
        ...state,
        adviceRoomConnectionState: action.payload.adviceRoomConnectionState,
        joinedAdviceRoomId: action.payload.joinedAdviceRoomId,
      };
    default:
      return state;
  }
};
