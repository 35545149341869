import { DocumentVaultDocumentType } from 'types/entities/documentVaultItem';

const documentTypes: Record<DocumentVaultDocumentType, string> = {
  [DocumentVaultDocumentType.Will]: `Will`,
  [DocumentVaultDocumentType.TrustDocument]: `Trust document`,
  [DocumentVaultDocumentType.InsurancePolicy]: `Insurance policy`,
  [DocumentVaultDocumentType.BankStatements]: `Bank statements`,
  [DocumentVaultDocumentType.Other]: `Other document`,
  [DocumentVaultDocumentType.Folder]: `Folder`,
};

export default {
  nameShort: `Vault`,
  documentTypes,
  removeModal: {
    title: `Delete`,
    defaultDescription: `Are you sure you want to delete this {documentType, select,
        ${DocumentVaultDocumentType.Folder} {folder}
        other {document}
      }? This cannot be undone.`,
    yes: `Yes, delete`,
    no: `No, don’t delete`,
    success: `{documentType, select,
        ${DocumentVaultDocumentType.Folder} {Folder}
        other {Document}
      } has been successfully deleted`,
  },
  fields: {
    documentType: `Document type`,
    folder: `Folder`,
    shared: `Share with your Adviser?`,
    willBeShared: `Document will be shared with your Adviser`,
    willNotBeShared: `Document will not be shared with your Adviser`,
  },
  folderHeader: `Folder`,
  currentFolder: `Current folder`,
  addFolder: `Add folder`,
  editFolder: `Edit folder`,
  addDocument: `Add document`,
  editDocument: `Edit document`,
  notifications: {
    created: `{documentType, select,
        ${DocumentVaultDocumentType.Folder} {Folder}
        other {Document}
      } has been successfully created`,
    updated: `{documentType, select,
      ${DocumentVaultDocumentType.Folder} {Folder}
      other {Document}
    } has been successfully updated`,
    deleted: `{documentType, select,
      ${DocumentVaultDocumentType.Folder} {Folder}
      other {Document}
    } has been successfully deleted`,
  },
};
