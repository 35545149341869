import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { FilterOptions, GetOptions, PaginationOptions, RequestConfig } from 'api/types';
import type { RecommendationsFilters } from 'constants/recommendationsFilters';

export type ClientRecommendationsGetOptions = GetOptions &
  PaginationOptions &
  FilterOptions<RecommendationsFilters>;

export function clientRecommendationsApi(path: string) {
  return {
    path,
    GET: (params: ClientRecommendationsGetOptions = {}, config?: RequestConfig) => {
      const { pageSize, pageNumber, ...rest } = params;

      return api.get<JsonApiResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...rest,
        },
        ...config,
      });
    },
  };
}
