import React from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { FormattedMessage, useIntl } from 'react-intl';
import { includes, indexOf, map } from 'lodash';
import { position, rem, size } from 'polished';
import styled from 'styled-components';

import type { FinancialPlanningTopic } from 'constants/financialPlanningTopics';
import { breakpoints } from 'helpers/styles/constants';
import { flexCenter } from 'helpers/styles/mixins';
import { useGetAvailableFinancialPlanningTopics } from 'store/settings/selectors';
import { GenericTooltip, TooltipPlacement } from 'components/GenericTooltip';
import IconCheckmark from 'components/Icons/IconCheckmarkSec';

import { ErrorMessage } from './ErrorMessage';

const Wrapper = styled.div`
  flex: 0 1 100%;
  margin-bottom: ${rem(24)};

  @media ${breakpoints.mdOnly} {
    flex-basis: 50%;

    &:nth-child(even) {
      padding-left: ${rem(12)};
    }

    &:nth-child(odd) {
      padding-right: ${rem(12)};
    }
  }

  @media ${breakpoints.lgUp} {
    flex-basis: ${100 / 3}%;

    &:nth-child(3n + 1) {
      padding-right: ${rem(12)};
    }

    &:nth-child(3n + 2) {
      padding-right: ${rem(12)};
      padding-left: ${rem(12)};
    }

    &:nth-child(3n + 3) {
      padding-left: ${rem(12)};
    }
  }
`;

const WrapperInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(100)};
`;

const Tick = styled(IconCheckmark)`
  ${size('13px')};
`;

interface TickBoxProps {
  isChecked?: boolean;
}

const TickBox = styled.div<TickBoxProps>`
  ${flexCenter()};
  position: absolute;
  top: ${rem(5)};
  right: ${rem(5)};
  ${size(rem(30))};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.mischka};

  ${Tick} {
    opacity: ${({ isChecked }) => (isChecked ? 1 : 0)};
    transition: opacity 0.3s ease;
  }
`;

const Content = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 91%;
`;

const Title = styled.strong`
  font-size: ${rem(15)};
`;

const Border = styled.div`
  ${position('absolute', 0, 0, 0, 0)};
  border-bottom: 1px solid transparent;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.mischka};
  pointer-events: none;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
`;

const Tooltip = styled.div`
  position: absolute;
  top: 10px;
  right: 40px;
`;

const Checkbox = styled.input.attrs({
  'data-test': 'block-checkbox',
})`
  ${position('absolute', 0, 0, 0, 0)};
  ${size('100%')};
  cursor: pointer;
  opacity: 0.01;

  &:focus,
  &:hover {
    + ${Border} {
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.bermudaGray};
    }
  }
`;

const BlockCheckboxesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: ${rem(860)};
`;

interface Props {
  name: string;
  isDisabled?: boolean;
}

const FieldTopics = ({ name, isDisabled }: Props) => {
  const intl = useIntl();
  const topics = useGetAvailableFinancialPlanningTopics();
  const { fields, meta } = useFieldArray<FinancialPlanningTopic>(name);

  return (
    <>
      <BlockCheckboxesWrapper>
        {map(topics, (topic, index) => {
          const tooltipTranslation = `financialPlanningTopics.topics.${topic}.tooltip`;

          const tooltipText = intl.formatMessage({
            id: tooltipTranslation,
            defaultMessage: tooltipTranslation,
          });

          const hasTooltipTranslation = tooltipText !== tooltipTranslation;
          const isChecked = includes(fields.value, topic);

          return (
            <Wrapper key={topic}>
              <WrapperInner>
                <TickBox isChecked={isChecked}>
                  <Tick />
                </TickBox>

                <Checkbox
                  type="checkbox"
                  checked={isChecked}
                  value={topic}
                  onChange={e => {
                    const topicValue = e.target.value as FinancialPlanningTopic;

                    if (e.target.checked) {
                      fields.push(topicValue);
                    } else {
                      fields.remove(indexOf(fields.value, topic));
                    }
                  }}
                  disabled={isDisabled}
                />

                <Border />

                <Content>
                  <Title>
                    <FormattedMessage id={`financialPlanningTopics.topics.${topic}.title`} />

                    {hasTooltipTranslation && (
                      <Tooltip>
                        <GenericTooltip
                          text={tooltipText}
                          position={
                            (index + 1) % 3 === 0 && index !== 0
                              ? TooltipPlacement.Left
                              : TooltipPlacement.Top
                          }
                        />
                      </Tooltip>
                    )}
                  </Title>
                </Content>
              </WrapperInner>
            </Wrapper>
          );
        })}
      </BlockCheckboxesWrapper>

      {meta.touched && meta.error && (
        <ErrorMessage>
          <FormattedMessage id={meta.error} />
        </ErrorMessage>
      )}
    </>
  );
};

export default FieldTopics;
