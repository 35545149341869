import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { required } from 'helpers/validators';
import FormActionResponsiveButtons from 'components/FormActionButtons/FormActionResponsiveButtons';
import { Field } from 'components/Forms';
import GenericInput from 'components/GenericInput/GenericInput';
import { GenericTitle, TitleMargin } from 'components/GenericTitle/GenericTitle';
import { MaxWidthWrapper } from 'components/Wrappers';

import { Fields, StepProps } from '../../types';

const StepFirstName: FC<StepProps> = () => (
  <>
    <GenericTitle isMedium marginBottom={TitleMargin.Lg}>
      <FormattedMessage id="onboarding.stepFirstName.title" tagName="span" />
    </GenericTitle>

    <MaxWidthWrapper>
      <Field
        name={Fields.FirstName}
        validate={required}
        renderControl={({ input, meta }) => (
          <GenericInput
            {...input}
            placeholder="onboarding.stepFirstName.placeholder"
            hasError={meta.showError}
            hasNoBackground
            hasFullWidth
            autoFocus
          />
        )}
      />
    </MaxWidthWrapper>

    <FormActionResponsiveButtons hasUnderline />
  </>
);

export default StepFirstName;
