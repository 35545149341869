import type { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { GetOptions } from 'api/types';
import { mapFormData } from 'helpers/formData';

export interface InvitationsAdvisersPostOptions {
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  phone?: string;
}

export function invitationsAdvisersApi(path: string) {
  return {
    path,
    GET: (params?: GetOptions) => api.get<JsonApiResponse>(path, { params }),
    POST: (params: InvitationsAdvisersPostOptions) =>
      api.post<JsonApiResponseSingle>(path, {
        data: {
          attributes: mapFormData(params),
        },
      }),
  };
}
