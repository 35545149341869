import React from 'react';

import SvgIcon from './SvgIcon';

const IconKite = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 44 44" {...props}>
    <g fill="currentColor">
      <path d="M13 37.27l5.44-6.42 1.52 1.3L11 42.73V25.52l30.38-24.3 1.24 1.56L13 26.48z" />
      <path
        d="M33.33 40.25L40.66 3.6 4.02 18.27l29.31 21.98zM43.34.39l-8.67
          43.36-34.7-26.02L43.35.4z"
      />
    </g>
  </SvgIcon>
);

export default IconKite;
