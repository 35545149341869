import type { OAuthAccount } from './oauthAccount';

export enum UserRoleName {
  Client = 'client',
  Adviser = 'adviser',
  PracticeAdmin = 'admin',
  PracticeDirector = 'director',
  SuperAdmin = 'super_admin',
  SupportAdmin = 'support_admin',
}

export const userRoleNames = [
  UserRoleName.Client,
  UserRoleName.Adviser,
  UserRoleName.PracticeAdmin,
  UserRoleName.PracticeDirector,
  UserRoleName.SuperAdmin,
  UserRoleName.SupportAdmin,
];

export interface User {
  id: number;
  email: string;
  unconfirmedEmail?: string;
  firstName: string;
  lastName: string;
  fullName: string;
  formalName: string;
  roleName: UserRoleName;
  avatar?: string;
  phone?: string;
  title?: string;
  signature?: string;
  secondarySignature?: string;
  isConfirmed?: boolean;
  oauthAccounts?: OAuthAccount[];
  otpEnabled?: boolean;
  isFirstSignIn?: boolean;
  isTourGuideCompleted: boolean;
  docusignIntegrated?: boolean;
  docusignIntegratedEmail?: string;
  virtualBackground?: string;
}
