import { DEFAULT_CURRENCY } from 'constants/currency';
import { intl } from 'i18n';

function round(num: number, val: number, dec: number) {
  const decimal = 10 ** dec;
  return Math.floor((num / val) * decimal) / decimal;
}

export function roundHalf(num: number) {
  return Number((Math.round(num * 2) / 2).toFixed(1));
}

export function formatBigNumber(num: number) {
  if (num >= 1000000) {
    return `${round(num, 1000000, 1)}m`;
  }

  if (num >= 10000) {
    return `${round(num, 1000, 1)}k`;
  }

  return round(num, 1, 0);
}

export const formatPrice = (price = 0, currency = DEFAULT_CURRENCY) =>
  intl.formatNumber(price, {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

export const parsePrice = (value: string) => (value ? value.replace(/[^0-9.]/g, '') : '');

export const asPercents = (value: number) => `${value * 100}%`;
