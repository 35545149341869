import React, { useEffect, useState } from 'react';
import { map } from 'lodash';
import type { PDFPageProxy } from 'pdfjs-dist/types/src/display/api';

import Annotation, { Props as AnnotationProps } from './components/Annotation';
import useGenericPdfContext from './useGenericPdfContext';

export interface Props {
  pdfPage: PDFPageProxy;
  isReadOnly?: boolean;
}

// Implementation based on
// https://github.com/mozilla/pdf.js/blob/master/src/display/annotation_layer.js

const AnnotationLayer = ({ pdfPage, isReadOnly }: Props) => {
  const { rotation } = useGenericPdfContext();
  const [controls, setControls] = useState<AnnotationProps[]>();

  useEffect(() => {
    const getControls = async () => {
      const annotations = await pdfPage.getAnnotations();

      const controlsToRender = map(annotations, annotation => {
        const scale = 1;
        const viewport = pdfPage.getViewport({
          scale,
          rotation,
        });

        // using possibly rotated viewport to calculate position of a widget
        const positionBounds = viewport.convertToViewportRectangle(annotation.rect);

        // creating unrotated viewport to calc width and height
        // (widget will be rotated at later stage so we would have to switch dimensions manually
        // here if using rotated viewport)
        const sizeBounds = pdfPage
          .getViewport({ scale, rotation: 0 })
          .convertToViewportRectangle(annotation.rect);

        const left = Math.min(positionBounds[0], positionBounds[2]);
        const top = Math.min(positionBounds[1], positionBounds[3]);
        const width = Math.abs(sizeBounds[0] - sizeBounds[2]);
        const height = Math.abs(sizeBounds[1] - sizeBounds[3]);

        return {
          width: width / viewport.width,
          height: height / viewport.height,
          left: left / viewport.width,
          top: top / viewport.height,
          rotation: viewport.rotation,
          data: annotation,
        };
      });

      setControls(controlsToRender);
    };

    getControls();
  }, [pdfPage, rotation]);

  return controls ? (
    <div>
      {map(controls, controlData => (
        <Annotation key={controlData.data.id} isReadOnly={isReadOnly} {...controlData} />
      ))}
    </div>
  ) : null;
};

export default AnnotationLayer;
