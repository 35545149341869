import { Root, route } from '../utils';

const topUpsPath = `${Root}top-ups`;
const topUpPath = `${topUpsPath}/:topUpId`;

type TopUpParams = [topUpId: number];

export default {
  topUp: {
    ...route<TopUpParams>(topUpPath),
    auditTrail: route<TopUpParams>(`${topUpPath}/audit-trail`),
  },
};
