import React from 'react';
import { rem, size } from 'polished';
import styled from 'styled-components';

import { BasicTooltip } from 'components/GenericTooltip';

import { useChatWindowContext } from '../../ChatWindow/ChatWindowContext';
import StatusBackground from '../../StatusBackground/StatusBackground';

const StatusIcon = styled.i<{ iconSize: number }>`
  ${({ iconSize }) => size(rem(iconSize))};
  display: block;
  color: ${({ theme }) => theme.colors.greyDarken};
`;

const DiagonalLine = styled.div<{ isSmall: boolean }>`
  position: absolute;
  height: ${({ isSmall }) => `${isSmall ? 1 : 2}px`};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.greyDarken};
  transform: rotate(45deg);
`;

interface Props {
  tooltipMessage: React.ReactNode;
  icon: React.ComponentType;
}

export function DeviceDisabledIndicator({ tooltipMessage, icon }: Props) {
  const { isUISmall } = useChatWindowContext();

  return (
    <BasicTooltip tooltipMessage={tooltipMessage}>
      <StatusBackground isSmall={isUISmall}>
        <StatusIcon as={icon} iconSize={isUISmall ? 10 : 16} />
        <DiagonalLine isSmall={isUISmall} />
      </StatusBackground>
    </BasicTooltip>
  );
}
