import type { FormRenderProps } from 'react-final-form';
import type { AxiosResponse } from 'axios';
import { FORM_ERROR } from 'final-form';
import { get, isEmpty, size, toNumber } from 'lodash';

import { registrations } from 'api';
import { isEnabled, ServerSideFeature } from 'helpers/features';
import { showErrorToast, showSuccessToast } from 'helpers/toasts';
import { goToUrl } from 'helpers/utils';
import { usePreferredAdvisersQuery } from 'hooks/queries/usePreferredAdvisersQuery';
import routes from 'routes';
import { prefixRoute } from 'routes/utils';
import { useGetPracticeSettings } from 'store/settings/selectors';
import { UserRoleName } from 'types/entities/user';

import StepResendEmail from './components/account/StepResendEmail';
import StepCashReserves from './components/investment/StepCashReserves';
import StepContribution from './components/investment/StepContribution';
import StepHighInterestDebt from './components/investment/StepHighInterestDebt';
import StepPreferredAdviser from './components/investment/StepPreferredAdviser';
import StepConsents from './components/legalAgreements/StepConsents';
import StepContact from './components/ourServices/StepContact';
import StepDisclosure from './components/ourServices/StepDisclosure';
import StepOtherFinancialNeeds from './components/ourServices/StepOtherFinancialNeeds';
import StepAge from './components/personalDetails/StepAge';
import StepBegin from './components/personalDetails/StepBegin';
import StepBeginKnownClient from './components/personalDetails/StepBeginKnownClient';
import StepFirstName from './components/personalDetails/StepFirstName';
import StepLastName from './components/personalDetails/StepLastName';
import { isKnownClient, revertToInitialValue } from './helpers';
import { Fields, StepProps, Values } from './types';

export enum Step {
  Begin,
  BeginKnownClient,
  Consents,
  FirstName,
  LastName,
  Age,
  OtherFinancialNeeds,
  Contribution,
  HighInterestDebt,
  CashReserves,
  PreferredAdviser,
  Disclosure,
  Contact,
  ResendEmail,
}

type SetStepHandler = (step: Step) => void;
type StepChangeHandler = (
  values: Values,
  form: FormRenderProps<Values>['form'],
  setActiveStep: SetStepHandler
) => void;

interface StepInfo {
  component: React.ComponentType<StepProps>;
  onPrev?: StepChangeHandler;
  onNext?: StepChangeHandler;
}

interface Params {
  wasInvited: boolean;
}

export function useOnboardingSteps({ wasInvited }: Params) {
  const { autoDisclosureEnabled } = useGetPracticeSettings();

  const { data: preferredAdvisers } = usePreferredAdvisersQuery({
    staleTime: Infinity,
    enabled: isEnabled(ServerSideFeature.PreferredAdviser) && !wasInvited,
    refetchOnWindowFocus: false,
  });

  const isDisclosureStepEnabled = Boolean(autoDisclosureEnabled);
  const isPreferredAdviserStepEnabled = size(preferredAdvisers) > 1;

  const getFirstActiveStep = (steps: Step[]) => {
    return steps
      .map(step => {
        switch (step) {
          case Step.Disclosure:
            return isDisclosureStepEnabled ? step : null;
          case Step.PreferredAdviser:
            return isPreferredAdviserStepEnabled ? step : null;
          default:
            return step;
        }
      })
      .filter(Boolean as unknown as ExcludeFalsy)[0];
  };

  const onboardingSteps: { [key in Step]: StepInfo } = {
    [Step.Begin]: {
      component: StepBegin,
      onNext: (values, form, setActiveStep) => setActiveStep(Step.Consents),
    },
    [Step.BeginKnownClient]: {
      component: StepBeginKnownClient,
      onNext: (values, form, setActiveStep) => setActiveStep(Step.Consents),
    },
    [Step.Consents]: {
      component: StepConsents,
      onPrev: (values, form, setActiveStep) => {
        revertToInitialValue(form, Fields.PersonalDataConsent);
        if (isKnownClient(values)) {
          setActiveStep(Step.BeginKnownClient);
        } else {
          setActiveStep(Step.Begin);
        }
      },
      onNext: (values, form, setActiveStep) => {
        if (isKnownClient(values)) {
          setActiveStep(Step.Age);
        } else {
          setActiveStep(Step.FirstName);
        }
      },
    },
    [Step.FirstName]: {
      component: StepFirstName,
      onPrev: (values, form, setActiveStep) => {
        revertToInitialValue(form, Fields.FirstName);
        setActiveStep(Step.Consents);
      },
      onNext: (values, form, setActiveStep) => setActiveStep(Step.LastName),
    },
    [Step.LastName]: {
      component: StepLastName,
      onPrev: (values, form, setActiveStep) => {
        revertToInitialValue(form, Fields.LastName);
        setActiveStep(Step.FirstName);
      },
      onNext: (values, form, setActiveStep) => setActiveStep(Step.Age),
    },
    [Step.Age]: {
      component: StepAge,
      onPrev: (values, form, setActiveStep) => {
        revertToInitialValue(form, Fields.DateOfBirth);

        if (isKnownClient(values)) {
          setActiveStep(Step.Consents);
        } else {
          setActiveStep(Step.LastName);
        }
      },
      onNext: (values, form, setActiveStep) => setActiveStep(Step.OtherFinancialNeeds),
    },
    [Step.OtherFinancialNeeds]: {
      component: StepOtherFinancialNeeds,
      onPrev: (values, form, setActiveStep) => {
        revertToInitialValue(form, Fields.ClientAdvice, Fields.Topics);
        setActiveStep(Step.Age);
      },
      onNext: (values, form, setActiveStep) => setActiveStep(Step.Contribution),
    },
    [Step.Contribution]: {
      component: StepContribution,
      onPrev: (values, form, setActiveStep) => {
        revertToInitialValue(
          form,
          Fields.InitialInvestment,
          Fields.InvestmentDuration,
          Fields.MonthlyContribution,
          Fields.Currency
        );
        setActiveStep(Step.OtherFinancialNeeds);
      },
      onNext: (values, form, setActiveStep) => setActiveStep(Step.HighInterestDebt),
    },
    [Step.HighInterestDebt]: {
      component: StepHighInterestDebt,
      onPrev: (values, form, setActiveStep) => {
        revertToInitialValue(form, Fields.HighInterestDebt);
        setActiveStep(Step.Contribution);
      },
      onNext: (values, form, setActiveStep) => setActiveStep(Step.CashReserves),
    },
    [Step.CashReserves]: {
      component: StepCashReserves,
      onPrev: (values, form, setActiveStep) => {
        revertToInitialValue(form, Fields.CashReserve);
        setActiveStep(Step.HighInterestDebt);
      },
      onNext: (values, form, setActiveStep) =>
        setActiveStep(getFirstActiveStep([Step.PreferredAdviser, Step.Disclosure, Step.Contact])),
    },
    [Step.PreferredAdviser]: {
      component: StepPreferredAdviser,
      onPrev: (values, form, setActiveStep) => {
        revertToInitialValue(form, Fields.PreferredAdviserId);
        setActiveStep(Step.CashReserves);
      },
      onNext: (values, form, setActiveStep) =>
        setActiveStep(getFirstActiveStep([Step.Disclosure, Step.Contact])),
    },
    [Step.Disclosure]: {
      component: StepDisclosure,
      onPrev: (values, form, setActiveStep) => {
        revertToInitialValue(form, Fields.DisclosureChecked);
        setActiveStep(getFirstActiveStep([Step.PreferredAdviser, Step.CashReserves]));
      },
      onNext: (values, form, setActiveStep) => setActiveStep(Step.Contact),
    },
    [Step.Contact]: {
      component: StepContact,
      onPrev: (values, form, setActiveStep) => {
        revertToInitialValue(
          form,
          Fields.City,
          Fields.Email,
          Fields.Password,
          Fields.PasswordConfirmation,
          Fields.Phone,
          Fields.Recaptcha
        );
        setActiveStep(
          getFirstActiveStep([Step.Disclosure, Step.PreferredAdviser, Step.CashReserves])
        );
      },
      onNext: async (values, form, setActiveStep) => {
        const showError = (response: AxiosResponse) => {
          const errors = get(response, 'data.errors');

          if (!isEmpty(errors)) {
            showErrorToast(errors);
            // setting this to indicate a submission error
            // https://final-form.org/docs/final-form/types/Config#onsubmit
            return { [FORM_ERROR]: 'error' };
          }

          return undefined;
        };

        try {
          const response = await registrations().POST({
            user: {
              email: values[Fields.Email],
              password: values[Fields.Password],
              passwordConfirmation: values[Fields.PasswordConfirmation],
              firstName: values[Fields.FirstName],
              lastName: values[Fields.LastName],
              personalDataConsent: values[Fields.PersonalDataConsent],
              phone: values[Fields.Phone],
            },
            client: {
              dateOfBirth: values[Fields.DateOfBirth],
              city: values[Fields.City],
              preferredAdviserId: values[Fields.PreferredAdviserId],
            },
            case: {
              type: values[Fields.CaseType],
              topics: values[Fields.Topics],
              initialInvestment: toNumber(values[Fields.InitialInvestment]) || 0,
              initialInvestmentCurrency: values[Fields.Currency],
              monthlyContribution: toNumber(values[Fields.MonthlyContribution]) || 0,
              monthlyContributionCurrency: values[Fields.Currency],
              investmentDuration: toNumber(values[Fields.InvestmentDuration]) || null,
            },
            invitation: {
              uuid: values[Fields.InvitationUuid],
            },
            metadata: {
              recaptcha: values[Fields.Recaptcha],
              clientAdvice: values[Fields.ClientAdvice],
              highInterestDebt: values[Fields.HighInterestDebt],
              cashReserve: values[Fields.CashReserve],
            },
          });

          const formError = showError(response);
          if (formError) {
            return formError;
          }

          const { initialValues } = form.getState();

          // If client was invited and email was not changed, we can log in
          if (
            values[Fields.InvitationUuid] &&
            values[Fields.Email] === initialValues[Fields.Email]
          ) {
            return goToUrl(prefixRoute(UserRoleName.Client, routes.root.path()));
          }

          showSuccessToast('notification.messages.registrationEmailSuccess');
          setActiveStep(Step.ResendEmail);
        } catch (error) {
          return showError(get(error, 'response'));
        }
      },
    },
    [Step.ResendEmail]: {
      component: StepResendEmail,
    },
  };

  return {
    onboardingSteps,
    isPreferredAdviserStepEnabled,
    isDisclosureStepEnabled,
  };
}
