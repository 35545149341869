import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { htmlElements } from 'helpers/translations';
import { intl } from 'i18n';
import { useGetPracticeSettings } from 'store/settings/selectors';
import FormActionResponsiveButtons from 'components/FormActionButtons/FormActionResponsiveButtons';
import { Field } from 'components/Forms';
import GenericCheckbox from 'components/GenericCheckbox/GenericCheckbox';
import { GenericTitle, TitleMargin } from 'components/GenericTitle/GenericTitle';
import Margin from 'components/Margin/Margin';

import { Fields, StepProps } from '../../types';
import { Description } from '../shared/Description';

const required = (value: boolean) =>
  value
    ? undefined
    : intl.formatMessage({
        id: 'onboarding.stepConsents.personalData.required',
      });

const StepConsents: FC<StepProps> = () => {
  const { privacyPolicyUrl, authorizedRepresentativeName } = useGetPracticeSettings();

  return (
    <>
      <GenericTitle marginBottom={TitleMargin.Sm}>
        <FormattedMessage id="onboarding.stepConsents.personalData.title" />
      </GenericTitle>

      <Description>
        <FormattedMessage
          id="onboarding.stepConsents.personalData.description"
          values={{
            ...htmlElements,
            authorizedRepresentativeName,
          }}
        />
      </Description>

      {privacyPolicyUrl && (
        <>
          <Margin top={20} />

          <Description>
            <FormattedMessage
              id="onboarding.stepConsents.personalData.privacyPolicy"
              values={{
                ...htmlElements,
                privacypolicylink: (chunks: React.ReactNode) => (
                  <strong>
                    <a target="_blank" rel="noopener noreferrer" href={privacyPolicyUrl}>
                      {chunks}
                    </a>
                  </strong>
                ),
              }}
            />
          </Description>
        </>
      )}

      <Margin top={20} />

      <Field
        name={Fields.PersonalDataConsent}
        type="checkbox"
        validate={required}
        renderControl={({ input, meta }) => (
          <GenericCheckbox
            {...input}
            hasError={meta.showError}
            label={<FormattedMessage id="onboarding.stepConsents.personalData.checkboxLabel" />}
            hasNoMargin
          />
        )}
      />

      <FormActionResponsiveButtons hasUnderline />
    </>
  );
};

export default StepConsents;
