import React, { FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { goToUrl } from 'helpers/utils';
import { composeValidators, isPastDate, required } from 'helpers/validators';
import routes from 'routes';
import FormActionResponsiveButtons from 'components/FormActionButtons/FormActionResponsiveButtons';
import { Field } from 'components/Forms';
import { GenericDatepicker } from 'components/GenericDatepicker/GenericDatepicker';
import { GenericTitle, TitleMargin } from 'components/GenericTitle/GenericTitle';
import { GenericTooltip } from 'components/GenericTooltip';
import useModalState from 'components/ModalHandler/useModalState';
import { MaxWidthWrapper } from 'components/Wrappers';

import { getAge } from '../../helpers';
import { Fields, StepProps } from '../../types';
import { OnboardingModal } from '../OnboardingModal/OnboardingModal';

const minAge = 18;

const StepAge: FC<StepProps> = ({ values }) => {
  const handleOnSubmit = useCallback(() => goToUrl(routes.root.path()), []);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [isMinAgeModalOpen, openMinAgeModal, closeMinAgeModal] = useModalState();

  return (
    <>
      <GenericTitle isMedium marginBottom={TitleMargin.Lg}>
        <FormattedMessage
          id="onboarding.stepAge.title"
          values={{ value: values[Fields.FirstName] }}
          tagName="span"
        />

        <GenericTooltip text={<FormattedMessage id="onboarding.stepAge.tooltip" />} />
      </GenericTitle>

      <MaxWidthWrapper>
        <Field
          name={Fields.DateOfBirth}
          validate={composeValidators(required, isPastDate)}
          beforeSubmit={() => {
            const age = getAge(values);

            if (age < minAge) {
              setIsFormDisabled(true);
              openMinAgeModal();

              return false;
            }

            return undefined;
          }}
          renderControl={({ input, meta }) => (
            <GenericDatepicker
              {...input}
              disabled={isFormDisabled}
              placeholder="onboarding.stepAge.placeholder"
              hasError={meta.showError}
              maxDate={new Date()}
              showYearDropdown
              showMonthDropdown
              hasNoTimeSelect
              hasNoBackground
              hasFullWidth
            />
          )}
        />
      </MaxWidthWrapper>

      <FormActionResponsiveButtons hasUnderline submitDisabled={isFormDisabled} />

      <OnboardingModal
        onClose={() => {
          setIsFormDisabled(false);
          closeMinAgeModal();
        }}
        isOpen={isMinAgeModalOpen}
        onSubmit={handleOnSubmit}
        text={
          <div>
            <FormattedMessage id="onboarding.validation.min18age" tagName="p" />
          </div>
        }
        buttonContent={<FormattedMessage id="shared.modal.accept" />}
      />
    </>
  );
};

export default StepAge;
