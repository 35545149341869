import React from 'react';
import { isArray, map } from 'lodash';
import { rem } from 'polished';
import styled from 'styled-components';

import { flexCenter } from 'helpers/styles/mixins';

const IconWrapper = styled.span`
  align-self: flex-start;
  margin-right: ${rem(4)};
`;

const Wrapper = styled.div`
  ${flexCenter()};
`;

interface Props {
  text: React.ReactNode | React.ReactNode[];
  icon?: React.ReactElement;
}

const ToastMessage = ({ text, icon }: Props) => (
  <div>
    {isArray(text) ? (
      map(text, (t, i) => (
        <div>
          {icon && <IconWrapper>{icon}</IconWrapper>}
          <span key={i}>{t}</span>
        </div>
      ))
    ) : (
      <Wrapper>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <div>{text}</div>
      </Wrapper>
    )}
  </div>
);

export default ToastMessage;
