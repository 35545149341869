import React from 'react';

import SvgIcon from './SvgIcon';

const IconBlurbackground = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 40 40" {...props}>
    <path
      d="M20 40C31.0476 40 40 31.0476 40 20C40 8.95238 31.0476 0 20 0C8.95238 0 0 8.95238 0 20C0 31.0476 8.95238 40 20 40Z"
      fill="currentColor"
    />
    <mask id="mask0_1134_8848" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
      <path
        d="M39.5 20C39.5 30.7715 30.7715 39.5 20 39.5C9.22852 39.5 0.5 30.7715 0.5 20C0.5 9.22852 9.22852 0.5 20 0.5C30.7715 0.5 39.5 9.22852 39.5 20Z"
        fill="#7785A4"
        stroke="#8E8E8E"
      />
    </mask>
    <g mask="url(#mask0_1134_8848)">
      <line
        x1="-7.22628"
        y1="24.2198"
        x2="18.7593"
        y2="-8.60406"
        stroke="#4F5F78"
        strokeWidth="0.5"
      />
      <line
        x1="-4.03293"
        y1="27.4151"
        x2="21.9527"
        y2="-5.40875"
        stroke="#4F5F78"
        strokeWidth="0.5"
      />
      <line
        x1="-1.90525"
        y1="31.5095"
        x2="25.7519"
        y2="-2.22619"
        stroke="#4F5F78"
        strokeWidth="0.5"
      />
      <line
        x1="2.95829"
        y1="32.8761"
        x2="28.9439"
        y2="0.0521863"
        stroke="#4F5F78"
        strokeWidth="0.5"
      />
      <line
        x1="6.14676"
        y1="36.0831"
        x2="32.1323"
        y2="3.25922"
        stroke="#4F5F78"
        strokeWidth="0.5"
      />
      <line
        x1="8.27835"
        y1="40.1735"
        x2="35.9355"
        y2="6.43787"
        stroke="#4F5F78"
        strokeWidth="0.5"
      />
      <line
        x1="12.8323"
        y1="41.9972"
        x2="38.8179"
        y2="9.17328"
        stroke="#4F5F78"
        strokeWidth="0.5"
      />
      <line
        x1="16.0257"
        y1="45.2042"
        x2="42.0112"
        y2="12.3803"
        stroke="#4F5F78"
        strokeWidth="0.5"
      />
      <line
        x1="18.1494"
        y1="49.2868"
        x2="45.8066"
        y2="15.5512"
        stroke="#4F5F78"
        strokeWidth="0.5"
      />
    </g>
    <path
      d="M19.8202 31.9844C16.5401 31.9844 13.2736 31.9844 9.99355 31.9844C9.31304 31.9844 9 31.6713 9 31.0044C9 29.6978 9 28.4048 9 27.0983C9 23.6821 11.3546 20.9328 14.7163 20.3884C15.0702 20.3339 15.4377 20.3203 15.8052 20.3203C18.4864 20.3203 21.154 20.3203 23.8353 20.3203C27.2651 20.3203 30.0143 22.6477 30.5588 26.0366C30.6132 26.3769 30.6268 26.7308 30.6268 27.071C30.6404 28.3912 30.6268 29.7114 30.6268 31.0316C30.6268 31.6713 30.3002 31.9844 29.6605 31.9844C26.3804 31.998 23.1003 31.9844 19.8202 31.9844ZM28.9663 30.3103C28.9663 30.2286 28.9663 30.1606 28.9663 30.0925C28.9663 29.0581 28.9663 28.0238 28.9663 26.9894C28.9527 24.2129 26.7343 21.9944 23.9441 21.9808C21.1949 21.9672 18.432 21.9672 15.6827 21.9808C12.8926 21.9944 10.6741 24.2129 10.6605 27.0166C10.6605 27.8468 10.6605 28.6771 10.6605 29.5209C10.6605 29.7795 10.6605 30.0381 10.6605 30.3103C16.7715 30.3103 22.8553 30.3103 28.9663 30.3103Z"
      fill="#1F3961"
    />
    <path
      d="M13.9951 12.8252C13.9951 9.62679 16.5947 7 19.7931 7C23.0596 7 25.6456 9.59957 25.6456 12.9069C25.6456 16.0509 23.0052 18.6777 19.8339 18.6641C16.6083 18.6641 14.0087 16.0509 13.9951 12.8252ZM19.8067 8.67407C17.5474 8.67407 15.6692 10.5523 15.6692 12.8116C15.6692 15.1118 17.5202 16.99 19.7931 17.0036C22.1205 17.0036 23.9851 15.1526 23.9851 12.8388C23.9851 10.5387 22.1069 8.67407 19.8067 8.67407Z"
      fill="#1F3961"
    />
    <path
      d="M12.7744 21.9609C10.7744 21.9609 10.2744 27.7943 10.2744 30.7109H29.0244V24.4609L26.5244 21.9609H12.7744Z"
      fill="#1F3961"
    />
    <circle cx="20.2744" cy="13.2109" r="5" fill="#1F3961" />{' '}
  </SvgIcon>
);

export default IconBlurbackground;
