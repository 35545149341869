import { useQuery, UseQueryOptions } from 'react-query';
import type { AxiosError } from 'axios';

import { advisers } from 'api';
import EntityPath from 'constants/entitiesPaths';
import { normalizeAndSortData } from 'helpers/normalizers';
import type { Adviser } from 'types/entities/adviser';

import { createCancelablePromise } from './createCancelablePromise';

export const baseQueryKey = 'advisers-preferred';

export type QueryData = Adviser[];

export function getQueryKey() {
  return [baseQueryKey];
}

export function usePreferredAdvisersQuery(queryOptions?: UseQueryOptions<QueryData, AxiosError>) {
  return useQuery<Adviser[], AxiosError>(
    getQueryKey(),
    () =>
      createCancelablePromise(cancelToken =>
        advisers()
          .preferred()
          .GET({ cancelToken })
          .then(response => normalizeAndSortData<Adviser>(response.data, EntityPath.Advisers))
      ),
    queryOptions
  );
}
