import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { FilterOptions, GetOptions, PaginationOptions } from 'api/types';

export type ExistingImagesGetOptions = GetOptions & PaginationOptions & FilterOptions<string>;
export type ExistingImagesApiGetResponse = JsonApiResponse;

const apiPath = '/api/v1/existing-images';

function existingImagesApi(path: string) {
  return {
    path,
    GET: (params: ExistingImagesGetOptions = {}) => {
      const { pageSize, pageNumber, filter, ...rest } = params;

      return api.get<ExistingImagesApiGetResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...(filter ? { 'filter[tag]': filter } : undefined),
          ...rest,
        },
      });
    },
  };
}

function existingImages() {
  return existingImagesApi(apiPath);
}

export { existingImages };
