import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { GenericTitle, TitleMargin } from 'components/GenericTitle/GenericTitle';
import GenericTitleSummary from 'components/GenericTitleSummary/GenericTitleSummary';

import type { StepProps } from '../../types';
import StepBeginBase from './StepBeginBase';

const StepBegin: FC<StepProps> = props => (
  <StepBeginBase {...props}>
    <GenericTitle marginBottom={TitleMargin.Sm}>
      <FormattedMessage id="nav.signUp" tagName="span" />
    </GenericTitle>

    <GenericTitleSummary>
      <FormattedMessage tagName="div" id="onboarding.stepBegin.summary" />
    </GenericTitleSummary>
  </StepBeginBase>
);

export default StepBegin;
