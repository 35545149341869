import React from 'react';
import { FormattedMessage } from 'react-intl';
import { rem } from 'polished';
import styled from 'styled-components';

import { breakpoints } from 'helpers/styles/constants';
import GenericButton from 'components/GenericButton/GenericButton';

const Button = styled(GenericButton)`
  display: block;
  width: 100%;

  @media ${breakpoints.xsUp} {
    display: inline-block;
    width: auto;
    min-width: ${rem(440)};
  }

  @media ${breakpoints.smUp} {
    margin-top: ${rem(53)};
    margin-bottom: ${rem(45)};
  }
`;

interface Props {
  isDisabled?: boolean;
}

const BeginButton = ({ isDisabled }: Props) => (
  <Button hasMarginTopMd hasMarginBottomMd disabled={isDisabled}>
    <FormattedMessage id="onboarding.shared.beginButton" />
  </Button>
);

export default BeginButton;
