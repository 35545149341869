import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api/apiInstance';
import type { FilterOptions, PaginationOptions, RequestConfig } from 'api/types';
import type { NotificationFilters } from 'constants/notificationFilters';

import { usersNotificationsMarkAllAsSeenApi } from './markAllAsSeen';
import { usersNotificationsMarkAsSeenApi } from './markAsSeen';
import { usersNotificationsMetaApi } from './meta';

export type NotificationsGetOptions = PaginationOptions & FilterOptions<NotificationFilters>;

export type NotificationsGetResponse = JsonApiResponse & {
  meta: { unseen_notifications_count: number };
};

export function usersNotificationsApi(path: string) {
  return {
    path,
    markAllAsSeen: () => usersNotificationsMarkAllAsSeenApi(`${path}/mark-all-as-seen`),
    markAsSeen: () => usersNotificationsMarkAsSeenApi(`${path}/mark-as-seen`),
    meta: () => usersNotificationsMetaApi(`${path}/meta`),
    GET: ({ pageSize, pageNumber, ...rest }: NotificationsGetOptions, config?: RequestConfig) =>
      api.get<NotificationsGetResponse>(path, {
        params: {
          per_page: pageSize,
          page: pageNumber,
          ...rest,
        },
        ...config,
      }),
  };
}
