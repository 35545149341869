import type { FormatDateOptions, IntlShape } from 'react-intl';
import { parseISO, toDate } from 'date-fns';
import { isString, padStart } from 'lodash';

import { isNumber } from 'helpers/validators';

const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

const prefixZero = (value: number) => (value > 9 ? value : `0${value}`);

export const ensureDate = (date: Date | string | number) =>
  isString(date) ? parseISO(date) : toDate(date);

export const assemblyDate = (day?: number, month?: string, year?: number) => {
  if (!day || !month || !year) return undefined;

  const correctDay = prefixZero(day);
  const monthIndex = MONTHS.indexOf(month) + 1;
  const correctMonth = prefixZero(monthIndex);

  return `${correctDay}/${correctMonth}/${year}`;
};

export const getSplittedDate = (fetchedDate: string) => {
  const isProperFormat = fetchedDate && isNumber(Date.parse(fetchedDate));

  if (!isProperFormat) {
    return { day: null, month: null, year: null };
  }

  const date = new Date(fetchedDate);

  return {
    day: date.getDate(),
    month: MONTHS[Number(date.getMonth())],
    year: date.getFullYear(),
  };
};

export const getHourCycle = (intl: IntlShape) => (intl.locale === 'en-GB' ? 'h12' : undefined);

export const formatDate = (
  intl: IntlShape,
  date: Date | string | number,
  opts?: FormatDateOptions | undefined
) => intl.formatDate(date, { hourCycle: getHourCycle(intl), ...opts });

export const formatTime = (
  intl: IntlShape,
  date: Date | string | number,
  opts?: FormatDateOptions | undefined
) => intl.formatTime(date, { hourCycle: getHourCycle(intl), ...opts });

export const secondsToHms = (inputSeconds: number) => {
  const hours = Math.floor(inputSeconds / 3600);
  const minutes = Math.floor((inputSeconds % 3600) / 60);
  const seconds = Math.floor((inputSeconds % 3600) % 60);

  const withZeros = (value: number) => padStart(String(value), 2, '0');

  return `${withZeros(hours)}:${withZeros(minutes)}:${withZeros(seconds)}`;
};

export const convertMinToMs = (value: number) => value * 1000 * 60;
