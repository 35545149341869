import React, { ChangeEvent, forwardRef, RefObject } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { rem, rgba } from 'polished';
import styled from 'styled-components';

import { users } from 'api';
import { breakpoints } from 'helpers/styles/constants';
import BlurBackgroundImage from 'images/bg_effect_images/bg_blur_effect.png';
import CustomBackgroundImage from 'images/bg_effect_images/bg_custom_effect.png';
import NoEffectImage from 'images/bg_effect_images/bg_no_effect.png';
import { fetchCurrentUser } from 'store/currentUser/actions';
import { useGetCurrentUser } from 'store/users/selectors';

import { Filter } from './BackgroundFiltersButton';
import PreviewImage from './PreviewImage';
import VirtualBackgroundUploader from './VirtualBackgroundUploader';

const Title = styled.div`
  margin-bottom: 0.75em;
  text-align: left;
  font-weight: bold;
  font-size: 1.1rem;

  @media ${breakpoints.mdDown} {
    margin-bottom: 0.5em;
    font-size: 0.9rem;
  }
`;

const PopupContainer = styled.div`
  position: fixed;
  z-index: 99999999;
  padding: 1em;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 ${rem(15)} ${rgba(0, 0, 0, 0.3)};
  transform: translateY(-105%) translateX(-50%);
  @media ${breakpoints.mdDown} {
    padding: 0.75em;
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  width: 700px;

  @media ${breakpoints.mdDown} {
    width: 500px;
  }
`;

const Arrow = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid white;
  transform: translateY(100%) translateX(-50%);
`;

interface Props {
  selectHandler: (filter: Filter) => void;
  selectedFilter: Filter;
  buttonRef: RefObject<HTMLButtonElement>;
}

const BackgroundFilterPopup = forwardRef<HTMLDivElement, Props>(
  ({ selectedFilter, selectHandler, buttonRef }, ref) => {
    const dispatch = useDispatch();
    const currentUser = useGetCurrentUser();
    const buttonBoundingRects = buttonRef.current?.getBoundingClientRect();

    const onUpload = async (e: ChangeEvent<HTMLInputElement>) => {
      if (currentUser && e.target.files && e.target.files.length > 0) {
        await users(currentUser.id).updateVirtualBackground().PATCH({
          image: e.target.files[0],
        });

        dispatch(fetchCurrentUser());
      }
    };

    return createPortal(
      <PopupContainer
        ref={ref}
        data-test="camera-filters-popup"
        style={{
          top: buttonBoundingRects?.top,
          left: buttonBoundingRects
            ? buttonBoundingRects?.left + buttonBoundingRects?.width / 2
            : 0,
        }}
      >
        <Title>
          <FormattedMessage id="video.tooltips.backgroundFilters" />
        </Title>
        <PreviewContainer>
          <PreviewImage
            data-test="camera-remove-effects-button"
            src={NoEffectImage}
            alt="custom background"
            filter={Filter.None}
            selected={selectedFilter === Filter.None}
            onSelect={selectHandler}
          />
          <PreviewImage
            data-test="camera-remove-effects-button"
            src={BlurBackgroundImage}
            alt="custom background"
            filter={Filter.BackgroundBlur}
            selected={selectedFilter === Filter.BackgroundBlur}
            onSelect={selectHandler}
          />
          {currentUser?.virtualBackground ? (
            <PreviewImage
              data-test="camera-remove-effects-button"
              src={CustomBackgroundImage}
              alt="custom background"
              filter={Filter.VirtualBackground}
              selected={selectedFilter === Filter.VirtualBackground}
              onSelect={selectHandler}
            />
          ) : (
            <VirtualBackgroundUploader name="image" uploadImage={onUpload} />
          )}
        </PreviewContainer>
        <Arrow />
      </PopupContainer>,
      document.body
    );
  }
);

export default BackgroundFilterPopup;
