import React from 'react';
import type { RemoteParticipant } from 'twilio-video';

import { AvatarSize } from 'components/Avatar';

import VideoTrack from '../Track/VideoTrack';
import { getParticipantTracks } from './helpers';
import { CameraDisabledIndicator } from './Indicators/CameraDisabledIndicator';
import { MicrophoneDisabledIndicator } from './Indicators/MicrophoneDisabledIndicator';
import { NetworkQuality } from './Indicators/NetworkQuality';
import { ParticipantWrapper } from './ParticipantWrapper';
import { BottomStatusBar, TopStatusBar } from './StatusBar';

interface Props {
  participant: RemoteParticipant;
  avatarSize?: AvatarSize;
}

export function RemoteParticipantComponent({ participant, avatarSize = AvatarSize.S }: Props) {
  const { videoTrack, audioTrack } = getParticipantTracks(participant);

  return (
    <ParticipantWrapper>
      <TopStatusBar>
        <NetworkQuality participant={participant} />
      </TopStatusBar>

      <BottomStatusBar>
        <CameraDisabledIndicator videoTrack={videoTrack} />
        <MicrophoneDisabledIndicator audioTrack={audioTrack} />
      </BottomStatusBar>

      <VideoTrack
        participantIdentity={participant.identity}
        track={videoTrack?.track}
        avatarSize={avatarSize}
      />
    </ParticipantWrapper>
  );
}
