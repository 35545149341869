export default {
  settings: {
    modalTitle: `Settings`,
    videoInput: `Video input`,
    audioOutput: `Audio output`,
    audioInput: `Audio input`,
    device: `Device {number}`,
    blurBackground: `Blur background`,
    virtualBackground: `CMR background`,
    noEffect: 'No effect',
    previewNotSupported: `Preview is not supported on Safari browsers during meeting.`,
  },
  errors: {
    maxParticipantsExceededError: `Maximum number of participants exceeded`,
    deviceNotFoundError: `One of the devices cannot be found. Did you unplugged you microphone or camera?`,
  },
  audioSignal: `Audio signal`,
  recording: {
    title: `Please select a recording type`,
    subtitle: `By default "Audio only" is selected. If you wish please change the selection as per your choice.`,
  },
  tooltips: {
    settings: 'Settings',
    enableWebcam: 'Enable webcam',
    disableWebcam: 'Disable webcam',
    enableBlurBackground: 'Enable blur background',
    disableBlurBackground: 'Disable blur background',
    enableVirtualBackground: 'Enable virtual background',
    disableVirtualBackground: 'Disable virtual background',
    recordingDisabled:
      'Recording cannot be started as all participants have their microphone permission disabled. Please give microphone permission to enable the recording.',
    openRoomMonitor: 'Open room monitor',
    closeRoomMonitor: 'Close room monitor',
    muteMicrophone: 'Mute microphone',
    unmuteMicrophone: 'Unmute microphone',
    shareScreen: 'Share screen',
    messages: 'Messages',
    joinCall: 'Join the call',
    endCall: 'End the call',
    backgroundFilters: `Enable/disable effect`,
    startRecord: 'Record the call',
    stopRecord: 'Stop recording',
  },
};
