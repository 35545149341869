enum EntityPath {
  Addenda = 'addenda',
  AdviceRoomGuests = 'adviceRoomGuests',
  AdviceRooms = 'adviceRooms',
  AdviceRoomTasks = 'adviceRoomTasks',
  Advisers = 'advisers',
  AdviserPaManifest = 'adviserPaManifests',
  AuditTrails = 'events',
  BlogPostAuthors = 'blogPostAuthors',
  BlogPosts = 'blogPosts',
  AgendaTemplates = 'agendaTemplates',
  Brochures = 'brochures',
  ClientCases = 'clientCases',
  ClientDashboardLinks = 'clientDashboardLinks',
  ClientIdentifications = 'clientIdentifications',
  ClientNotes = 'clientMessages',
  ClientNotificiations = 'clientNotifications',
  ClientPracticeNotes = 'clientPracticeMessages',
  ClientTags = 'clientTags',
  Clients = 'clients',
  ClientsMessagesSummaries = 'clientsMessagesSummaries',
  Compositions = 'compositions',
  DisclosureForms = 'disclosureForms',
  DocumentPacks = 'documentsPack',
  Documents = 'documents',
  DocumentSetDocuments = 'documentSets',
  DocumentVaultItems = 'documentVaultItems',
  EmployeNotifications = 'employeeNotifications',
  EstateSummaries = 'estateSummaries',
  ExistingImages = 'existingImages',
  Events = 'events',
  Faq = 'faq',
  FeatureHelps = 'featureHelps',
  FinancialAudits = 'financialAudits',
  FinancialPlanningHealthChecks = 'financialPlanningHealthChecks',
  FinancialPlanningGoals = 'financialPlanningGoals',
  FinancialPlannings = 'financialPlannings',
  FinancialStrategies = 'financialStrategies',
  HelpLinks = 'helpLinks',
  GuestParticipants = 'guestParticipants',
  IdentificationDocuments = 'identificationDocuments',
  ClientIdentityVerifications = 'clientIdentityVerifications',
  IdvAuthorityData = 'idvAuthorityData',
  Invitations = 'invitations',
  Maintenances = 'maintenances',
  MessagesInteractionReports = 'messagesInteractionReports',
  SummaryInteractionReports = 'summaryInteractionReports',
  InteractionReports = 'interactionReports',
  MonthlyActivityUsers = 'monthlyActivityUsers',
  MotivationCentreComments = 'motivationCentreComments',
  MotivationCentreItems = 'motivationCentreItems',
  Notifications = 'notifications',
  OAuthAccounts = 'oauthAccounts',
  Permissions = 'permissions',
  PracticeAdmins = 'practiceAdmins',
  PracticeBrochures = 'practiceBrochures',
  PracticeDirectors = 'practiceDirectors',
  Practices = 'practices',
  PracticeSettings = 'practiceSettings',
  PracticeTypes = 'practiceTypes',
  RiskAssessments = 'riskAssessments',
  ServiceCharters = 'serviceCharters',
  Tasks = 'tasks',
  TopUps = 'topUps',
  Transcriptions = 'transcriptions',
  UserParticipants = 'userParticipants',
  Users = 'users',
  UserSearchResults = 'userSearchResults',
  MultipleDocusignAccount = 'multipleDocusignAccount',
}

export default EntityPath;
