import React, { useCallback, useEffect, useRef } from 'react';
import { useField, useFormState } from 'react-final-form';

import { required } from 'helpers/validators';
import GenericRecaptcha, { RefType } from 'components/GenericRecaptcha/GenericRecaptcha';

interface Props {
  name: string;
}

const FieldRecaptcha = ({ name }: Props) => {
  const ref = useRef<RefType>(null);
  const formState = useFormState();
  const { input, meta } = useField(name, { validate: required });

  useEffect(() => {
    if (formState.submitFailed && ref.current) {
      ref.current.reset();
      input.onChange(undefined);
    }
  }, [formState.submitFailed, ref]);

  const onRecaptchaLoad = useCallback(() => {
    if (ref.current) {
      ref.current.renderExplicitly();
    }
  }, [ref]);

  const onRecaptchaVerify = useCallback(
    (recaptchaResponse: string) => input.onChange(recaptchaResponse),
    [input]
  );

  const onRecaptchaExpire = useCallback(() => input.onChange(undefined), [input]);

  return (
    <GenericRecaptcha
      onLoad={onRecaptchaLoad}
      onVerify={onRecaptchaVerify}
      onExpire={onRecaptchaExpire}
      hasError={meta.touched && Boolean(meta.error)}
      ref={ref}
    />
  );
};

export default FieldRecaptcha;
