import type { AnyAction } from 'redux';

import { SET_CURRENT_CASE } from './actionTypes';
import type { CurrentCaseActionTypes, CurrentCaseState } from './types';

export const initialState: CurrentCaseState = {
  id: null,
};

export default (state = initialState, incomingAction: AnyAction): CurrentCaseState => {
  const action = incomingAction as CurrentCaseActionTypes;

  switch (action.type) {
    case SET_CURRENT_CASE:
      return {
        ...state,
        id: action.payload.id,
      };
    default:
      return state;
  }
};
