import { lighten, rem } from 'polished';
import { css, DefaultTheme } from 'styled-components';

export interface TextareaProps {
  minHorizontalSize?: number | null;
  minVerticalSize?: number | null;
  verticalSize?: number | null;
  maxVerticalSize?: number | null;
  disabled?: boolean;
  readOnly?: boolean;
  transparent?: boolean;
  hasBoldBorderTop?: boolean;
  hasVerticalResize?: boolean;
  hasAccent?: boolean;
  hasError?: boolean;
  hasNoPadding?: boolean;
  hasWarning?: boolean;
}

interface StyledTextareaProps extends TextareaProps {
  theme: DefaultTheme;
}

const getBorderColor = (
  { theme, disabled, hasAccent, hasError }: StyledTextareaProps,
  state?: 'hover' | 'focus'
) => {
  if (hasError) {
    return theme.colors.crail;
  }

  if (disabled || !state) {
    return theme.colors.mischka;
  }

  const colors = {
    hover: hasAccent ? theme.colors.bermudaGray : theme.colors.main,
    focus: hasAccent ? theme.colors.accent : theme.colors.main,
  };

  return colors[state];
};

export const textarea = css<TextareaProps>`
  ${({ hasNoPadding }) => !hasNoPadding && `padding: ${rem(16)} ${rem(15)};`};
  font-size: ${({ theme }) => theme.fontSizes.dbNormal};
  line-height: 1.5;
  ${({ transparent, disabled, theme }) => {
    if (transparent) {
      return;
    }

    return css`
      background-color: ${disabled ? lighten(0.4, theme.colors.bermudaGray) : theme.colors.white};
      border: 1px solid ${props => getBorderColor(props)};
    `;
  }};
  color: ${({ theme }) => theme.colors.black};
  resize: none;
  outline: none;
  transition: border-color 0.3s ease;
  ${({ hasBoldBorderTop }) => hasBoldBorderTop && 'border-top-width: 2px'};
  ${({ hasVerticalResize }) => hasVerticalResize && 'resize: vertical'};
  ${({ minVerticalSize }) => minVerticalSize && `min-height: ${rem(minVerticalSize)}`};
  ${({ minHorizontalSize }) =>
    minHorizontalSize ? `width: ${rem(minHorizontalSize)}` : `width: 100% `};
  ${({ verticalSize }) => verticalSize && `height: ${rem(verticalSize)}`};
  ${({ maxVerticalSize }) => maxVerticalSize && `max-height: ${rem(maxVerticalSize)}`};
  ${({ hasWarning, theme }) => hasWarning && `border: 1px solid  ${theme.colors.orangeWarning};`};

  &:focus {
    border-color: ${props => getBorderColor(props, 'focus')};
  }

  &:hover {
    border-color: ${props => getBorderColor(props, 'hover')};
  }
`;
