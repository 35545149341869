import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

import { breakpoints } from 'helpers/styles/constants';
import { flexCenter } from 'helpers/styles/mixins';

const Wrapper = styled.div`
  flex-shrink: 0;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.textLight};

  @media ${breakpoints.smUp} {
    flex-direction: row-reverse;
  }

  @media ${breakpoints.xsDown} {
    margin-right: ${rem(16)};
  }

  & + & {
    margin-right: ${rem(16)};

    @media ${breakpoints.smUp} {
      margin-left: ${rem(20)};
    }
  }
`;

const TopRow = styled.div`
  ${flexCenter()};
  flex-grow: 1;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.grey};

  @media ${breakpoints.smUp} {
    margin-left: ${rem(20)};
    font-size: ${({ theme }) => theme.fontSizes.smallLg};
  }
`;

const BottomRow = styled.div`
  ${flexCenter()};
  color: ${({ theme }) => theme.colors.accent};
`;

export interface Props {
  topRow?: React.ReactNode;
  bottomRow: React.ReactNode;
}

const ToolbarSection = ({ topRow, bottomRow }: Props) => (
  <Wrapper>
    {topRow && <TopRow>{topRow}</TopRow>}

    <BottomRow>{bottomRow}</BottomRow>
  </Wrapper>
);

export default ToolbarSection;
