import React from 'react';
import { find, some } from 'lodash';
import type { LocalVideoTrackPublication, RemoteVideoTrackPublication, Room } from 'twilio-video';

import { isScreenSharingTrack } from 'api/twilio/twilioVideo';
import { VideoLayout } from 'components/VideoChat/types';

import { useUpdateOnEvents } from '../../useUpdateOnEvents';
import { DominantSpeakerLayout } from './DominantSpeakerLayout';
import { GridLayout } from './GridLayout';

const EVENTS = [
  'disconnected',
  'dominantSpeakerChanged',
  'participantConnected',
  'participantDisconnected',
  'participantReconnected',
  'participantReconnecting',
  'reconnected',
  'reconnecting',
  'trackDimensionsChanged',
  'trackDisabled',
  'trackEnabled',
  'trackMessage',
  'trackPublished',
  'trackPublishPriorityChanged',
  'trackStarted',
  'trackSubscribed',
  'trackSwitchedOff',
  'trackSwitchedOn',
  'trackSubscriptionFailed',
  'trackUnpublished',
  'trackUnsubscribed',
];

const PARTICIPANT_EVENTS = [
  'disconnected',
  'reconnected',
  'reconnecting',
  'trackDimensionsChanged',
  'trackDisabled',
  'trackEnabled',
  'trackPublicationFailed',
  'trackPublished',
  'trackUnpublished',
  'trackStarted',
  'trackStopped',
];
interface Props {
  room: Room;
  layout: VideoLayout;
}

export function RoomComponent({ room, layout }: Props) {
  useUpdateOnEvents(room, EVENTS);
  useUpdateOnEvents(room.localParticipant, PARTICIPANT_EVENTS);

  const remoteParticipants = Array.from(room.participants.values());

  const screenSharingParticipant = find(
    [room.localParticipant, ...remoteParticipants],
    participant => {
      const tracks = Array.from(
        participant.videoTracks.values() as IterableIterator<
          LocalVideoTrackPublication | RemoteVideoTrackPublication
        >
      );

      return some(tracks, isScreenSharingTrack);
    }
  );

  return Boolean(screenSharingParticipant) || layout === VideoLayout.DominantSpeaker ? (
    <DominantSpeakerLayout room={room} screenSharingParticipant={screenSharingParticipant} />
  ) : (
    <GridLayout room={room} />
  );
}
