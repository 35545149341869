import styled from 'styled-components';

export const boundryName = 'draggable-boundry';

const DraggableBoundry = styled.div.attrs({ id: boundryName })`
  z-index: 99999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;

  * {
    pointer-events: all;
  }
`;

export default DraggableBoundry;
