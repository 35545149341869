import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { breakpoints } from 'helpers/styles/constants';
import GenericLoader from 'components/GenericLoader/GenericLoader';

export interface DocumentWrapperProps {
  minHeight?: string;
  maxHeight?: string;
}

export const DocumentWrapper = styled.div<DocumentWrapperProps>`
  position: relative;
  overflow-y: auto;
  flex-grow: 1;
  min-height: ${({ minHeight }) => minHeight || rem(200)};
  border: 1px solid ${({ theme }) => theme.colors.greyLighten};
  background-color: ${({ theme }) => theme.colors.greyLighten};

  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
    `};
`;

export const CenteredLoader = styled(GenericLoader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const PaginationWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: ${rem(10)};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.panelBorder};

  &:last-child {
    border-top: none;
  }

  &:not(:last-child) {
    border-bottom: none;
  }

  @media ${breakpoints.smUp} {
    padding: ${rem(18)} ${rem(40)};
  }
`;

export const PaginationWrapperRow = styled.div`
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;
`;
