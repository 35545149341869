import React, { forwardRef, RefObject, useState } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { rem, rgba } from 'polished';
import styled from 'styled-components';

import { breakpoints } from 'helpers/styles/constants';
import AudioImage from 'images/cmr/audio.png';
import VideoImage from 'images/cmr/audio_video.png';
import { RecordingType } from 'components/VideoChat/RecordingContext';

import RecordingOption from './RecordingOption';

const Title = styled.div`
  margin-bottom: 0.25em;
  text-align: left;
  font-weight: bold;
  font-size: 1.1rem;

  @media ${breakpoints.mdDown} {
    margin-bottom: 0.5em;
    font-size: 0.9rem;
  }
`;

const Subtitle = styled.div`
  margin-bottom: 0.75em;
  text-align: left;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.greyMid};

  @media ${breakpoints.mdDown} {
    margin-bottom: 0.5em;
    font-size: 0.75rem;
  }
`;

const PopupContainer = styled.div`
  position: fixed;
  z-index: 99999999;
  max-width: 650px;
  padding: 1.5em;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 ${rem(15)} ${rgba(0, 0, 0, 0.3)};
  transform: translateY(-105%) translateX(-50%);

  @media ${breakpoints.mdDown} {
    padding: 0.75em;
    min-width: 300px;
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  gap: 1em;
  margin: 1em 0em;
`;

const Arrow = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid white;
  transform: translateY(100%) translateX(-50%);
`;

const Button = styled.button`
  padding: ${rem(12)} ${rem(18)};
  font-size: ${({ theme }) => theme.fontSizes.dbNormalSm};
  background: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface Props {
  buttonRef: RefObject<HTMLButtonElement>;
  recordingType?: RecordingType;
  switchRecordingState: (type: RecordingType) => void;
}

const RecordingSwitcherPopup = forwardRef<HTMLDivElement, Props>(
  ({ buttonRef, switchRecordingState }, ref) => {
    const buttonBoundingRects = buttonRef.current?.getBoundingClientRect();
    const [selectedOption, setSelectedOption] = useState<RecordingType>(RecordingType.Audio);

    return createPortal(
      <PopupContainer
        ref={ref}
        data-test="recording-switcher-popup"
        style={{
          top: buttonBoundingRects?.top,
          left: buttonBoundingRects
            ? buttonBoundingRects?.left + buttonBoundingRects?.width / 2
            : 0,
        }}
      >
        <Title>
          <FormattedMessage id="video.recording.title" />
        </Title>
        <Subtitle>
          <FormattedMessage id="video.recording.subtitle" />
        </Subtitle>
        <PreviewContainer>
          <RecordingOption
            option={RecordingType.Audio}
            selected={selectedOption === RecordingType.Audio}
            onSelect={setSelectedOption}
            src={AudioImage}
            alt="select audio recording"
            title="Audio only"
          />
          <RecordingOption
            option={RecordingType.Video}
            selected={selectedOption === RecordingType.Video}
            onSelect={setSelectedOption}
            src={VideoImage}
            alt="select video recording"
            title="Audio and Video"
          />
        </PreviewContainer>
        <ButtonContainer>
          <Button onClick={() => switchRecordingState(selectedOption)}>Confirm</Button>
        </ButtonContainer>
        <Arrow />
      </PopupContainer>,
      document.body
    );
  }
);

export default RecordingSwitcherPopup;
