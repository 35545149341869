import { FINANCIAL_STRATEGY } from 'constants/caseTypes';
import { CaseState, CaseType } from 'types/entities/clientCase';

export default {
  caseSubmitted: `Case is already submitted`,
  newInvestment: {
    description: `You can request our advice on a top-up to an existing investment, or on a wholly new investment.`,
  },
  names: {
    [CaseType.OnlineAdviceRequest]: `Online Advice request`,
    [CaseType.RecommendationPackCase]: `Recommendation Pack`,
    [CaseType.MeetingRequestCase]: `Meeting request`,
    [CaseType.ReviewQuestionnaireCase]: `questionnaire`,
    [CaseType.DocumentPackCase]: `Document Pack`,
  },
  client: {
    [CaseType.OnlineAdviceRequest]: {
      [CaseState.Building]: `Prepare your Online Advice request`,
      [CaseState.Submitted]: `Online Advice request review`,
      [CaseState.Completed]: `Request complete`,
      [CaseState.Cancelled]: `Request cancelled`,
    },
    [CaseType.RecommendationPackCase]: {
      [CaseState.ReviewingPack]: `Your Recommendation Pack`,
      [CaseState.Accepted]: `Complete your recommendation`,
      [CaseState.Submitted]: `Preparing Recommendation Pack`,
      [CaseState.Rejected]: `Recommendation Pack rejected`,
      [CaseState.Confirmed]: `Case complete`,
      [CaseState.Abandoned]: `Case abandoned`,
      [CaseState.Archived]: `Case archived`,
      [CaseState.Cancelled]: `Case cancelled`,
    },
    [CaseType.MeetingRequestCase]: {
      [CaseState.Requested]: `Meeting requested`,
      [CaseState.Completed]: `Request complete`,
      [CaseState.Abandoned]: `Abandoned`,
      [CaseState.Archived]: `Archived`,
      [CaseState.Cancelled]: `Meeting cancelled`,
    },
    [CaseType.ReviewQuestionnaireCase]: {
      [CaseState.Building]: `Complete questionnaire`,
      [CaseState.Submitted]: `Submitted`,
      [CaseState.Accepted]: `Reviewed`,
      [CaseState.Cancelled]: `Cancelled`,
    },
  },
  adviser: {
    [CaseType.OnlineAdviceRequest]: {
      [CaseState.Building]: `Prepare Online Advice Request`,
      [CaseState.Submitted]: `Submitted`,
      [CaseState.Completed]: `Complete`,
      [CaseState.Cancelled]: `Cancelled`,
    },
    [CaseType.RecommendationPackCase]: {
      [CaseState.Submitted]: `Prepare Recommendation Pack`,
      [CaseState.ReviewingPack]: `Review Recommendation Pack`,
      [CaseState.Accepted]: `Complete the case`,
      [CaseState.Rejected]: `Recommendation Pack rejected`,
      [CaseState.Confirmed]: `Case now complete`,
      [CaseState.Abandoned]: `Case abandoned`,
      [CaseState.Archived]: `Case archived`,
      [CaseState.Cancelled]: `Case cancelled`,
    },
    [CaseType.MeetingRequestCase]: {
      [CaseState.Requested]: `Meeting requested`,
      [CaseState.Completed]: `Request completed`,
      [CaseState.Abandoned]: `Abandoned`,
      [CaseState.Archived]: `Archived`,
      [CaseState.Cancelled]: `Meeting cancelled`,
    },
    [CaseType.ReviewQuestionnaireCase]: {
      [CaseState.Draft]: `Draft`,
      [CaseState.Building]: `Complete questionnaire`,
      [CaseState.Submitted]: `Submitted`,
      [CaseState.Accepted]: `Reviewed`,
      [CaseState.Cancelled]: `Questionnaire Cancelled`,
    },
  },
  service: {
    [CaseType.OnlineAdviceRequest]: `Online Advice request`,
    [CaseType.RecommendationPackCase]: `Recommendation Pack`,
    [CaseType.MeetingRequestCase]: `Face-to-face advice`,
    [CaseType.ReviewQuestionnaireCase]: `Questionnaire`,
    [CaseType.DocumentPackCase]: `Document Pack`,
    [FINANCIAL_STRATEGY]: `Financial Strategy`,
    [`${CaseType.OnlineAdviceRequest}_tooltip`]: `A service in which your financial adviser advises you via our digital wealth platform.`,
    [`${CaseType.RecommendationPackCase}_tooltip`]: `This is a series of documents containing a personal recommendation for you.`,
    [`${CaseType.MeetingRequestCase}_tooltip`]: `A service in which your financial adviser meets you in person to discuss your financial planning and investment needs in depth.`,
    [`${CaseType.ReviewQuestionnaireCase}_tooltip`]: `This questionnaire will help us to assess your current financial situation and identify planning opportunities, ahead your upcoming adviser meeting.`,
  },
  label: {
    markPackAsComplete: `Mark this recommendation as complete. Tick here to provide your client with
      a copy of an already-agreed recommendation.`,
    details: {
      [CaseType.OnlineAdviceRequest]: `Online Advice request details`,
      [CaseType.MeetingRequestCase]: `Meeting Request details`,
      [CaseType.ReviewQuestionnaireCase]: `Questionnaire details`,
    },
    documents: {
      [CaseType.OnlineAdviceRequest]: `Online Advice request documents`,
      [CaseType.RecommendationPackCase]: `Supporting documents`,
      [CaseType.MeetingRequestCase]: `Meeting Request documents`,
      [CaseType.ReviewQuestionnaireCase]: `Questionnaire documents`,
    },
    privateNoteAlreadySent: `This private note has already been sent to practice messages`,
    sendNoteToPracticeNotes: `Send this private note to Practice messages`,
  },
};
